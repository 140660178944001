import { Component, OnInit, OnDestroy } from '@angular/core'
import { DataService } from 'src/app/core/services/data.service'
import { Router, NavigationExtras } from '@angular/router'
import { ReportsService } from '../../services/reports.service'
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"
import { ToastrService } from "ngx-toastr"
import { Subscription } from 'rxjs'
import { SelectViewModalComponent } from '../select-view-modal/select-view-modal.component'
import { CustomReportFilterModalComponent } from '../custom-report-filter-modal/custom-report-filter-modal.component'


@Component({
  selector: 'app-view-custom-report-modal',
  templateUrl: './view-custom-report-modal.component.html',
  styleUrls: ['./view-custom-report-modal.component.scss']
})
export class ViewCustomReportComponentModal implements OnInit, OnDestroy {
  accountData = this.dataService.accountData$
  isLoading: boolean = false
  isDownloading: boolean = false
  title: string = ''
  reportId: string = ''
  branchId: string = ''
  subscriptions = new Subscription()
  reportDetails: any
  reportContents: Array<any> = []
  customerDetails: any[] = []
  queueWorkspace: any[] = []
  queueDetails: any[] = []
  statistics: any[] = []
  teamMembers: any[] = []
  queueSource: any[] = []
  queueSourceFilter: any[] = []
  columnValues: any[] = []
  columnLabels: string[] = []
  customFormFields: any[] = []
  categories = [
    { name: 'Customer Details', contents: this.customerDetails },
    { name: 'Queue Workspace', contents: this.queueWorkspace },
    { name: 'Queue Details', contents: this.queueDetails },
    { name: 'Statistics', contents: this.statistics },
    { name: 'Team Members/Staff', contents: this.teamMembers },
    { name: 'Added via', contents: this.queueSource }
  ];

  constructor(
    private reportService: ReportsService,
    private dataService: DataService,
    private router: Router,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public bsModalRef2: BsModalRef,
    public bsModalRef3: BsModalRef,
    private toastr: ToastrService,
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.branchId && this.reportId) {
      await this.getReportDetails(this.branchId, this.reportId)
    }
    this.reportContents = JSON.parse(localStorage.getItem('customReportContents'))
  }

  async getReportDetails(branchId: string, reportId: string) {
    this.isLoading = true
    this.subscriptions.add(
      this.reportService.getCustomReportDetails(branchId, reportId).subscribe(res => {
        if (res && res.data) {
          this.reportDetails = res.data
          if (this.reportDetails && this.reportDetails.contents && this.reportDetails.contents.length > 0) {
            const contentDetails = this.reportDetails.contents
            const length = contentDetails.length
            for (var i = 0; i < length; ++i) {
              let contentValue = contentDetails[i]
              let contentIndex: number = this.findContentDetails(contentValue)
              if (contentIndex != -1) {
                let contentLabel: string = ''
                const contentObj = this.reportContents[contentIndex].filter(content => content?.value == contentValue)
                if (contentObj) {
                  contentLabel = contentObj[0].label
                  if (contentValue == 'business-portal' || contentValue == 'branch-site' || contentValue == 'kiosk') {
                    if (!this.columnLabels.includes('Added via')) {
                      this.columnLabels.push('Added via')
                    }
                    if (!this.columnValues.some((content) => content.value == 'source')) {
                      this.columnValues.push({ value: 'source', data: undefined })
                    }
                  } else if (contentValue == 'serviceName') {
                    this.columnValues.push({ value: 'serviceName', data: undefined })
                    this.columnLabels.push('Service')
                  } else if (contentValue == 'windowName') {
                    this.columnValues.push({ value: 'windowName', data: undefined })
                    this.columnLabels.push('Window')
                  } else if (contentValue == 'waitingTime') {
                    this.columnValues.push({ value: 'waitingTime', data: undefined })
                    this.columnLabels.push('Ave. Waiting Time')
                  } else if (contentValue == 'servingTime') {
                    this.columnValues.push({ value: 'servingTime', data: undefined })
                    this.columnLabels.push('Ave. Serving Time')
                  } else {
                    this.columnValues.push({ value: contentValue, data: undefined })
                    this.columnLabels.push(contentLabel)
                  }
                }
                switch (contentIndex) {
                  case 0:
                    this.customerDetails.push(contentLabel)
                    break
                  case 1:
                    this.queueWorkspace.push(contentLabel)
                    break
                  case 2:
                    this.queueDetails.push(contentLabel)
                    break
                  case 3:
                    this.statistics.push(contentLabel)
                    break
                  case 4:
                    this.teamMembers.push(contentLabel)
                    break
                  case 5:
                    this.queueSource.push(contentLabel)
                    this.queueSourceFilter.push(contentDetails[i])
                    break
                  default:
                    break
                }
              } else {
                const fieldObj = this.customFormFields.filter(content => content?.fieldName == contentValue)
                const label = fieldObj[0]?.label
                if (fieldObj && label) {
                  this.columnValues.push({ value: contentValue, data: undefined })
                  this.columnLabels.push(label)
                  this.customerDetails.push(label)
                }
              }
            }
          }
          this.isLoading = false
        }
      }, (error) => {
        console.log(error)
        this.toastr.error('Error on fetching custom report details', 'Error!')
      })
    )
  }

  findContentDetails(detail: string): number {
    const searchValue = detail
    const property = 'value'
    let index: number = -1

    index = this.reportContents
      .findIndex(array => array
        .some(obj => obj[property] === searchValue))
    return index
  }

  displayContents(contents: any[], index: number) {
    if (!contents || contents.length === 0) {
      return '-';
    }
  
    if (index === 0) {
      if (contents.length === this.reportContents[index].length + this.customFormFields.length) {
        return 'All'
      }
    } else {
      if (contents.length === this.reportContents[index].length) {
        return 'All'
      }
    }

    if (contents.length <= 2) {
      return contents.join(', ');
    }

    const remainingCount = contents.length - 2;
    return `${contents.slice(0, 2).join(', ')}${remainingCount > 0 ? ` + ${remainingCount} other${remainingCount > 1 ? 's' : ''}` : ''}`;
  }

  editCustomReport() {
    this.dataService.setSelectedReport(this.reportDetails)
    const queryParams: NavigationExtras = {
      queryParams: {
        type: 'edit'
      }
    }
    this.router.navigate(['/reports/custom-reports'], queryParams)
    this.bsModalRef.hide()
  }

  selectViewModal(filter?: string) {
    this.bsModalRef.hide()
    const initialState = {
      title: this.reportDetails.name,
      subtitle: 'Select if the generated data is by service or window',
      serviceText: 'Queue created under selected service/s',
      windowText: 'Queues served under selected window/s',
      customReport: true,
      filterType: filter
    }
    this.bsModalRef2 = this.modalService.show(SelectViewModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false
    })

    this.bsModalRef2.content.selectedView.subscribe(
      (data) => {
        if (data) {
          if (data == 'cancel') {
            const initialState = {
              title: 'View Custom Report',
              reportId: this.reportDetails._id,
              branchId: this.branchId
            }
            this.bsModalRef = this.modalService.show(ViewCustomReportComponentModal, {
              initialState: initialState,
              class: "modal-dialog-centered",
              ignoreBackdropClick: true,
              keyboard: false
            })
          } else if (data == 'close') {
            //
          } else {
            this.selectCustomReportFilter(data)
          }
        }
      },
      (err) => {
        console.log("error on select view", err)
        return false
      }
    )
  }

  selectCustomReportFilter(filter) {
    const initialState = {
      title: this.reportDetails.name,
      subtitle: 'Select filters to generate report',
      description: this.reportDetails.description,
      branchId: this.branchId,
      filterType: filter,
      sourceFilter: this.queueSourceFilter,
      reportId: this.reportDetails._id,
      columnLabels: this.columnLabels,
      columnValues: this.columnValues
    }
    this.bsModalRef3 = this.modalService.show(CustomReportFilterModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    })

    this.bsModalRef3.content.generate.subscribe(
      (data) => {
        if (data) {
          if (data.action == 'cancel') {
            this.selectViewModal(data.selected)
          }
        }
      },
      (err) => {
        console.log("error on generate custom report", err)
        return false
      }
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
