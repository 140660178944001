import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/core/auth/auth.service";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { ModalService } from "src/app/core/services/modal.service";


interface Option {
  name: string;
  enabled: boolean;
}

interface TagSet {
  setName: string;
  tags: Option[];
  selectAll: boolean; // Tracks "Select All" state for each set
  showTags: boolean;
}


@Component({
  selector: 'app-transactions-modal',
  templateUrl: './transactions-modal.component.html',
  styleUrls: ['./transactions-modal.component.scss']
})

export class TransactionsModalComponent extends ModalService implements OnInit {

  universalTags: any;

  data: TagSet[] = []
  filteredData: TagSet[] = [...this.data];

  searchText: string = '';

  queueCurrentTags: string[] = []

  @Output() selectedTags = new EventEmitter();
  @Output() closed = new EventEmitter();

  constructor(
    bsModalRef: BsModalRef,
    httpService: HttpService,
    dataService: DataService,
    toastr: ToastrService,
    authService: AuthService,
  ) {
    super(bsModalRef, httpService, dataService, toastr, authService);
  }

  ngOnInit(): void {
    // this.sanitizeCurrentTags()
    this.populateData()

  }

  sanitizeCurrentTags() {
    let tags = [];

    for (const tag of this.queueCurrentTags) {
      if (tag.includes("-")) {
        const output = tag.split("-")
        tags.push(output[output.length - 1])
      }
    }

    this.queueCurrentTags = tags;
  }

  populateData() {
    for (let set of this.universalTags) {

      for (let tag of set.tags) {
        tag.enabled = this.queueCurrentTags.includes(`${set.setName}-${tag.name}`)
      }

      this.data.push({
        setName: set.setName,
        selectAll: set.tags.every(tag => tag.enabled),
        tags: set.tags,
        showTags: true
      })
    }

    this.filteredData = [...this.data]
    console.log(this.filteredData)
  }

  // Toggle Select All for a specific set
  toggleSelectAll(set: TagSet) {
    set.tags.forEach(option => (option.enabled = set.selectAll));
  }

  // Update Select All based on individual option selection within a set
  onOptionChange(set: TagSet) {
    set.selectAll = set.tags.every(option => option.enabled);
  }

  toggleShowTags(set: TagSet) {
    set.showTags = !set.showTags;
  }

  // Filter data based on search text
  search() {
    if (!this.searchText) {
      this.filteredData = [...this.data];
    } else {
      this.filteredData = this.data.map(set => ({
        ...set,
        tags: set.tags.filter(option =>
          option.name.toLowerCase().includes(this.searchText.toLowerCase())
        ),
      })).filter(set => set.tags.length > 0); // Exclude sets without matching options
    }
  }

  clearSearch() {
    this.searchText = '';
    this.search();
  }

  close() {
    this.bsModalRef.hide()
    this.closed.emit()
  }

  confirm(event) {

    let tags = []
    for (const set of this.filteredData) {
      for (const tag of set.tags) {
        if (tag.enabled) {
          tags.push(`${set.setName}-${tag.name}`)
        }
      }
    }

    this.selectedTags.emit(tags)
    this.bsModalRef.hide()
  }

}
