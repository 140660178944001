import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { SettingsService } from '../../services/settings.service';
import { DataService } from 'src/app/core/services/data.service';
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { IUniversalTagForm } from '../../interfaces/universal-tags';

@Component({
  selector: 'app-universal-tags',
  templateUrl: './universal-tags.component.html',
  styleUrls: ['./universal-tags.component.scss']
})
export class UniversalTagsComponent implements OnInit, OnDestroy {
  tagSet: any = [];
  submitting: boolean = false;
  subscriptions = new Subscription();
  isDisabled: boolean = true;
  templateData: any = [];
  activeTag: Array<IUniversalTagForm> = [];
  tagLists: Array<any> = [];
  tagEnabled:boolean = true;
  changed: boolean = false;
  successData: boolean = false;
  isChanged: boolean;
  setTagForm: FormGroup;
  isLoading: boolean = false;
  isDeleted: boolean;
  deletedTagName: Array<IUniversalTagForm> = [];
  @Output() successEvent = new EventEmitter();

  constructor(
    public modalRef: BsModalRef,
    private settingsService: SettingsService,
    private dataService: DataService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) {
    this.setTagForm = this.formBuilder.group({
      setTagItemsArrayForm: this.formBuilder.array([]),
    });
   }

      
  ngOnInit(): void {
    this.getTagSetData();    
 }

  getTagSetData() : void { 
    const branchId = this.dataService.currentBranch$.branchId;
    this.settingsService.getUniversalTagSet(branchId).subscribe(
      (res) => {
        if(res && res.result.length > 0) {
         let allTagData = [];
          this.tagSet = res.result; 
         
          for(let tag of this.tagSet) {
            let result = tag.setName.replace(/^\s+|\s+$/gm,'')
            console.log(result);
            tag.setName = result;
            const filterTagName = tag.tags.map(tagData => tagData.name);
              tag.tags = filterTagName   
              allTagData.push(tag);

          }
    
          this.activeTag = allTagData;
          this.setTagItemsArrayForm.controls = []; 
          this.createdItems();
        }
      },
      (error) => {
        console.log(error)
      }
    )    
   }

 private createdItems(): void {
    if (this.activeTag && this.activeTag.length > 0) {
      this.activeTag.forEach((element, i) => {
        this.setTagItemsArrayForm.push(
          this.formBuilder.group(
            {
              setName: [element.setName, Validators.required],
              authorId: element.authorId,
              deleted: element.deleted,
              tags: this.formBuilder.array([...element.tags]),  
              _id: element._id
            }
          )
        );
      });
    }
  }

  private checkFields(): boolean {
    for (var i = 0; i < this.setTagItemsArrayForm.value.length; i++) {
      if (this.setTagItemsArrayForm.value[i].setName.trim() == '') {
        return true;
      }
    }
    return false;
  }
    
  get isSaveDisabled(): boolean {
    return !this.isChanged || this.checkFields()
  }

  get setTagItemsArrayForm() {
   return this.setTagForm.controls['setTagItemsArrayForm'] as FormArray
 }

  addTag($event, tag):void {
    this.isChanged = true;
    tag.value.tags.push($event);
  }

  removeTag($event,tag):void {
    this.isChanged = true;
    const tagIndex = tag.value.tags.indexOf(!$event.value ? $event : $event.value);
    tag.value.tags.splice(tagIndex, 1);
  }

  addTagSet(): void {
    const tagSetForm = this.formBuilder.group({
        setName: ['', Validators.required],
        tags: this.formBuilder.array([]),
        deleted: false,
    })
    tagSetForm.value.tags = this.activeTag
    this.setTagItemsArrayForm.push(tagSetForm);
  
  }

   checkDupes(setTagArrayForm):any{
    const setNameList = [];
    for(let setTagForm of setTagArrayForm.controls) {
      setNameList.push(setTagForm.get('setName').value.trim())
    }
    const checker = setNameList.some((value, index) => setNameList.indexOf(value) !== index);
    return checker
  }

  changeSetName(): void {
    this.isChanged = true;

  }

  async saveChanges(): Promise<void> {
    this.isLoading = true; 
    let newFormUpdateData = [];
    let createdSetTagForm = [];

    if(this.deletedTagName.length > 0) {
        this.deletedTagName.forEach(element =>{ 
          newFormUpdateData.push(element);
      })
      this.deletedTagName = [];
    }
    const hasDuplicateSetname = await this.checkDupes(this.setTagItemsArrayForm)

     if(hasDuplicateSetname) {
      this.toastr.error("Duplicate setname", "Error!");
      this.isLoading = false;
      this.isChanged = false;
      this.setTagItemsArrayForm.controls = [] 
      this.getTagSetData();
      throw new Error("duplicate set name")
    } else {
      const newSetTagList = [];

      this.setTagItemsArrayForm.value.forEach(settag => { 
      let newTags = settag.tags.map(tag => {
             return {name: tag.name === undefined ? tag : tag.name , enabled: true}
       })
       settag.tags = newTags;
  
       if(!settag.hasOwnProperty('_id')) {
        createdSetTagForm.push(settag);
       } else {
        newFormUpdateData.push(settag);
       }
      })

      const bodyUpdate = {
        branchId: this.dataService.currentBranch$.branchId,
        setTagBody: newFormUpdateData
      }
  
      const bodyCreate = {
        branchId: this.dataService.currentBranch$.branchId,
        setTagBody: createdSetTagForm
      }

      bodyUpdate.setTagBody.forEach(data => {
        if(data.deleted) {
          this.isDeleted = true;
        }
      })
 
      bodyUpdate.setTagBody.forEach(element => { 
          let newTags =  element.tags.map(tag => {
              return {name: tag.name === undefined ? tag : tag.name , enable: true}
          })
          element.tags = newTags
          newSetTagList.push(element);
      })

      bodyUpdate.setTagBody = newSetTagList;
      if(newFormUpdateData.length > 0) {
        await this.editTagSet(bodyUpdate);
      } 
       
      if(createdSetTagForm.length > 0) {
       await this.createTagSet(bodyCreate);
      }
}
}

 editTagSet(bodyUpdate) {
  this.subscriptions.add(
    this.settingsService.editUniversalTagSet(bodyUpdate).subscribe(
      res => {
        if(res) { 
        const allTagData = [];
        for(let tag of res.result) {
          const filterTagName = tag.tags.map(tagData => tagData.name);
          if(!tag.deleted) {
            tag.tags = filterTagName   
            allTagData.push(tag);
          }
        }

        this.isLoading = false;
        this.isChanged = false;
        this.toastr.success("tag set has been updated", "Success!"); 
        if(bodyUpdate.setTagBody.length === this.setTagItemsArrayForm.controls.length || this.isDeleted) {
          this.getTagSetData();  
          this.isDeleted = false;
        }
      }},
      error => {
        console.log("error", error);
        this.toastr.error("tag set has not been updated", "Error!");
        this.isLoading = false;
        this.isChanged = false;
      }
    )
  );
}

createTagSet(bodyCreate) {
  this.subscriptions.add(
    this.settingsService.createUniversalTagSet(bodyCreate).subscribe(
      res => {
        if(res) {
        this.isLoading = false;
        this.isChanged = false;
        this.toastr.success("tag set has been updated", "Success!");     
        this.getTagSetData();    

      }},
      error => {
        console.log("error", error);
        this.toastr.error("tag set has not been updated", "Error!");
        this.isLoading = false;
        this.isChanged = false;
      }
    )
  );
}

  deleteTagSet(index,tag):any {
    this.isChanged = false;
    tag.value.deleted = true;
    if(tag.value._id != ""){
      this.deletedTagName.push(tag.value);
      this.isChanged = true;
    }
    this.setTagItemsArrayForm.removeAt(index);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
