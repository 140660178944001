import { MapsAPILoader } from "@agm/core";
import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AuthService } from "src/app/core/auth/auth.service";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { JwtService } from "src/app/core/services/jwt.service";
import { BusinessService } from "../../services/business.service";
import { BusinessEnum } from "../../enums/business-enum.enum";

@Component({
  selector: "app-business-setup-page",
  templateUrl: "./business-setup-page.component.html",
  styleUrls: ["./business-setup-page.component.scss"],
  providers: [DatePipe],
})
export class BusinessSetupPageComponent extends JwtService implements OnInit {
  step: number = 1;

  businessForm = this.fb.group({
    email: ["", [Validators.pattern(/^\S+@\S+\.[a-zA-z]+$/)]],
    name: ["", [Validators.required]],
    branch: ["", [Validators.required]],
    mobileNo: ["", [Validators.minLength(10)]],
    address: [""],
  });
  inputFocus: any;

  latitude = 14.547956768247687;
  longitude = 121.05131113274915;
  zoom: number = 17;
  address: string = '';
  private geoCoder;
  selectedBusinessNature: any;
  selectedBusinessType: any;
  selectedQueueType: any = [];
  selectedNotifyType: any = [];

  industries: any = [];
  industryTypes: any = [];
  isSubmitting: boolean = false;

  @ViewChild("searchAddress") searchElementRef: ElementRef;
  validMobile: boolean = true;
  businessEnum = BusinessEnum;

  constructor(
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public businessService: BusinessService,
    public authService: AuthService,
    public http: HttpClient,
    public httpService: HttpService,
    public dataService: DataService,
  ) {
    super(authService, http, httpService, dataService, businessService);
  }

  get f() {
    return this.businessForm.controls;
  }

  async ngOnInit(): Promise<void> {
    if (!this.authService.accountId$) {
      await this.checkAccessToken();
    }
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();
    });
  }

  getIndustries() {
    this.businessService.getIndustries().subscribe(
      (data: any) => {
        if (data) {
          const natures = data.data.map((element) => {
            return {
              _id: element._id,
              name: element.name
            };
          }).filter(x => !x.suspended);
          this.industries = natures;
          if (this.industries.length != 0) {
            this.step++;
          }
        }
      },
      (error) => {
        console.log("industry", error);
      }
    );
  }

  getIndustryTypes(industryId: string) {
    this.businessService.getIndustryTypes(industryId).subscribe(
      (data: any) => {
        if (data) {
          const types = data.data.map((element) => {
            return {
              _id: element._id,
              name: element.name
            };
          }).filter(x => !x.suspended);
          this.industryTypes = types;
        }
      },
      (error) => {
        console.log("industry types", error);
      }
    )
  }

  markerDragEnd($event: any) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }

  proceedDetails() {
    let mobileNoPassed = false;
    const MobileNumberPattern = /^[\d -]{12}$/;
    // /^(9)+[0-9]{9}$/; 
    if(this.businessForm.value.mobileNo != ''){
      if(MobileNumberPattern.test(this.businessForm.value.mobileNo)){
        if((this.businessForm.value.mobileNo).charAt(0) == '9'){
          mobileNoPassed = true;
        }
      }
    } else {
      mobileNoPassed = true;
    }
    setTimeout(() => {
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.setComponentRestrictions({
        country: ["ph"],
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          const result = document.getElementsByClassName(
            "pac-container"
          ) as HTMLCollectionOf<HTMLElement>;
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return null;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.address = place.formatted_address;
          this.blur();
          this.zoom = 15;
        });
      });
    }, 500);
    
    this.validMobile = mobileNoPassed;
    if(this.step == 1 && !this.validMobile){
      // this.step++;
    } else {
      this.getIndustries();
    }
  }

  proceedAddress() {
    this.clearBusinessNature();
    this.clearBusinessType();
    this.step++;
  }

  proceedBusiness() {
    if (this.step == 3 && this.selectedBusinessNature == this.industries[3]._id) {
      this.selectIndustryType(this.industryTypes[0]._id);
      this.step = 5;
    } else {
      this.step++;
    }
  }

  back() {
    if (this.step == 5 && this.selectedBusinessNature == this.industries[3]._id) {
      this.clearBusinessType();
      this.step = 3;
    } else if (this.step == 4) {
      this.clearBusinessType();
      this.step--;
    } else {
      this.step--;
    }
  }

  proceedQueue() {
    this.step++;
  }

  submit() {
    this.isSubmitting = true;
    const body = {
      name: this.businessForm.value.name,
      branchName: this.businessForm.value.branch,
      email: this.businessForm.value.email,
      mobileNo: this.businessForm.value.mobileNo ? this.businessForm.value.mobileNo.replaceAll("-", "") : '',
      industryId: this.selectedBusinessNature,
      industryTypeId: this.selectedBusinessType,
      address: this.address,
      about: '',
      coordinates: {
        lat: this.latitude,
        lng: this.longitude,
      },
      queueNotificationTypes: this.selectedQueueType,
      queuePlatforms: this.selectedNotifyType
    };
    this.businessService.createBusiness(body).subscribe(
      (res) => {
        this.isSubmitting = false;
        this.step++;
      },
      (error) => {
        console.log("error", error);
        this.isSubmitting = false;
      }
    );
  }

  selectIndustry(industryId: string) {
    this.selectedBusinessNature = industryId ? industryId : '';
    if (this.selectedBusinessNature != '') {
      this.getIndustryTypes(this.selectedBusinessNature);
    }
  }

  selectIndustryType(industryTypeId: string) {
    this.selectedBusinessType = industryTypeId ? industryTypeId : '';
  }

  getIndustryName(industryId: string) {
    const industry = this.industries.filter(x => x._id === industryId);
    if (industry) {
      return industry[0].name;
    }
  }

  selectQueueType(id) {
    if (this.selectedQueueType.includes(id)) {
      const index = this.selectedQueueType.indexOf(id);
      if (index > -1) {
        this.selectedQueueType.splice(index, 1);
      }
    } else {
      this.selectedQueueType.push(id);
    }
  }

  selectNotifyType(id) {
    if (this.selectedNotifyType.includes(id)) {
      const index = this.selectedNotifyType.indexOf(id);
      if (index > -1) {
        this.selectedNotifyType.splice(index, 1);
      }
    } else {
      this.selectedNotifyType.push(id);
    }
  }

  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  blur() {
    const result = document.getElementsByClassName(
      "pac-container"
    ) as HTMLCollectionOf<HTMLElement>;
    result[0] ? (result[0].hidden = true) : "";
  }

  focus() {
    const result = document.getElementsByClassName(
      "pac-container"
    ) as HTMLCollectionOf<HTMLElement>;
    result[0] ? (result[0].hidden = false) : "";
  }

  goToDashboard(){
    window.location.href = '/dashboard';
  }

  goToWorkspace(){
    window.location.href = '/queue-workspace/select-window';
  }

  clearBusinessNature() {
    this.selectedBusinessNature = ''
  }

  clearBusinessType() {
    this.selectedBusinessType = ''
  }
}
