import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DragulaService } from "ng2-dragula";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { ProcedureService } from "../../services/procedure.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { DeleteProcedureModalComponent } from "../../component/delete-procedure-modal/delete-procedure-modal.component";
@Component({
  selector: "app-procedure-list-details-page",
  templateUrl: "./procedure-list-details-page.component.html",
  styleUrls: ["./procedure-list-details-page.component.scss"],
})
export class ProcedureListDetailsPageComponent implements OnInit, OnDestroy {
  pageDetails = [
    { type: "main", name: "Service Procedures", routerLink: null },
    { type: "current", name: "Edit Procedure", routerLink: null },
  ];
  branchId: string;
  procedureTemplateId: string;
  serviceLists: any;
  subscriptions = new Subscription();
  isSubmitting = false;
  assignAnotherProcedure = false;
  assignedProcedures = [];
  procedureTemplateData: any;
  type: string;
  disableButton = true;

  serviceListsSearch: any[] = [];
  searchInput: string = '';

  constructor(
    private router: Router,
    private httpService: HttpService,
    private dataService: DataService,
    private dragulaService: DragulaService,
    private activatedRoute: ActivatedRoute,
    private procedureService: ProcedureService,
    private toastr: ToastrService,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
  ) {}

  ngOnInit(): void {
    this.procedureTemplateId = this.activatedRoute.snapshot.paramMap.get(
      "procedureTemplateId"
    );
    this.pageDetails[0].routerLink = ['/apps/service-procedure']
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params && params.type) {
        this.type = params.type;
      }
    });

    this.subscriptions.add(
      this.dataService.branchChange$.subscribe(async (res) => {
        if (this.branchId && this.branchId != res._id) {
          this.router.navigate(["/apps/service-procedure"]);
        } else {
          await this.handleBranchChange(res);
          await this.getServiceLists();
          await this.checkDrag();
        }
      })
    );

  }

  async handleBranchChange(res: any): Promise<void> {
    this.branchId = res ? res._id : this.dataService.currentBranch$.branchId;
  }

  async checkDrag() {
    this.subscriptions.add(
      this.dragulaService
        .dropModel("procedureModel")
        .subscribe(({ targetModel }) => {
          this.assignedProcedures = targetModel
            .map((element, i) => {
              return {
                ...element,
                index: i,
              };
            })
            .sort((a, b) => a.index - b.index);
          this.checkIfButtonDisabled();
        })
    );
  }

  checkIfStepError(index) {
    let x = false;

    if (this.assignedProcedures.length > 1) {
      if (this.assignedProcedures.length > index + 1) {
        const v1 = this.assignedProcedures[index];
        const v2 = this.assignedProcedures[index + 1];
        if (v1.id === v2.id) {
          x = true;
        }
      }

      if (index > 0) {
        const v1 = this.assignedProcedures[index];
        const v2 = this.assignedProcedures[index - 1];
        if (v1.id === v2.id) {
          x = true;
        }
      }
    }

    return x;
  }

  checkIfButtonDisabled() {
    this.disableButton = false;
    this.assignedProcedures.forEach((e, i) => {
      if (this.assignedProcedures.length > i + 1) {
        const v1 = this.assignedProcedures[i];
        const v2 = this.assignedProcedures[i + 1];
        if (v1.id === v2.id) {
          this.disableButton =
            true && this.procedureTemplateData.procedureType !== "flexible";
        }
      }

      if (i > 0) {
        const v1 = this.assignedProcedures[i];
        const v2 = this.assignedProcedures[i - 1];
        if (v1.id === v2.id) {
          this.disableButton =
            true && this.procedureTemplateData.procedureType !== "flexible";
        }
      }
    });
  }

  public getServiceName(id) {
    if (id && this.serviceLists) {
      const s = this.serviceLists.find((x) => x._id === id);
      if (s) {
        return s.displayName;
      }
    }
  }

  public async getProcedureDetails() {
    this.subscriptions.add(
      this.procedureService
      .getProcedureTemplateDetails(this.branchId, this.procedureTemplateId)
      .subscribe(
        (res) => {
          this.procedureTemplateData = res.result;
          this.assignedProcedures = [];
          if (res.result) {
            this.assignAnotherProcedure = res.result.modules.includes(
              "assign-new-procedure"
            );
            res.result.procedures.forEach((service) => {
                  this.assignedProcedures.push({
                    id: service.serviceId,
                    label: this.getServiceName(service.serviceId),
                    optional: service.optional,
                  });
            });
          } else {
            this.router.navigate(["/apps/service-procedure/"]);
          }
        },
        (error) => {
          console.log("error", error);
        }
      )
    )
  }

  public async getServiceLists() {
    this.subscriptions.add(
      this.httpService
      .get$(`services/${this.branchId}?limit=999`)
      .subscribe(async (data) => {
        this.serviceLists = data.data;
        this.serviceListsSearch = this.serviceLists;
        await this.getProcedureDetails();
      })
    )
  }

  select(service) {
    this.assignedProcedures.push({
      id: service._id,
      label: service.displayName,
      optional: false,
    });

    this.checkIfButtonDisabled();
  }
  
  public deleteProcedure() {
    const initialState = {
      branchId: this.branchId,
      templateDataId: this.procedureTemplateId,
      title: "Delete Service Procedure?",
      serviceProcedureName: this.procedureTemplateData?.displayName
    };

    this.modalRef = this.modalService.show(DeleteProcedureModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    }); 
  }

  confirm() {
    const selectedProcedures = this.assignedProcedures.map((x) => {
      return {
        serviceId: x.id,
        optional: x.optional,
      };
    });
    const body = {
      modules: this.assignAnotherProcedure ? ["assign-new-procedure"] : [],
      procedures: selectedProcedures,
    };

    this.subscriptions.add(
      this.procedureService
        .updateProcedureServices(this.branchId, this.procedureTemplateId, body)
        .subscribe(
          (res) => {
            this.isSubmitting = false;
            const toastMsg =
              this.type === "add"
                ? "New Procedure has been added"
                : "Service Procedure has been updated!";
            this.toastr.success(toastMsg, "Success!");
            this.router.navigate(["/apps/service-procedure/"]);
          },
          (error) => {
            console.log("error", error);
            this.isSubmitting = false;
          }
        )
    );
  }

  cancel() {
    this.router.navigate(["/apps/service-procedure"]);
  }

  optionalProcedure(ev, id) {
    this.assignedProcedures[id].optional = ev;
    this.checkIfButtonDisabled();
  }

  setModule(ev) {
    this.assignAnotherProcedure = ev;
    this.checkIfButtonDisabled();
  }

  remove(i) {
    this.assignedProcedures.splice(i, 1);
    this.checkIfButtonDisabled();
  }

  searchInputUpdated($event) {
    if (this.searchInput) {
      const pattern = new RegExp(`${this.searchInput}`, 'i');
      this.serviceListsSearch = this.serviceLists.filter(service => service.displayName.search(pattern) != -1)
    } else {
      this.serviceListsSearch = this.serviceLists;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe(); // Unsubscribe from all subscriptions
  }
}
