import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BranchManagementService } from 'src/app/modules/branch-management/services/branch-management.service';

@Component({
  selector: 'app-subscription-limit-modal',
  templateUrl: './subscription-limit-modal.component.html',
  styleUrls: ['./subscription-limit-modal.component.scss']
})
export class SubscriptionLimitModalComponent implements OnInit {
  @Input() title: any;
  @Input() subTitle1: any;
  @Input() subTitle2: any;
  @Input() subTitle3: any;
  selectedBranch = this.branchService.getSelectedBranch();

  constructor(public modalRef: BsModalRef, private router: Router, private branchService: BranchManagementService) { }

  ngOnInit(): void {
  }

  upgradePlan(){
    this.modalRef.hide();

    this.router.navigate(['/subscription']);
  }
}
