import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpService } from 'src/app/core/http/http.service';
import { DataService } from 'src/app/core/services/data.service';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent extends ModalService implements OnInit {
  confirmAction: string;
  enableRemarks: boolean;
  remarks: string;
  requireRemarks: boolean;

  constructor(
    bsModalRef: BsModalRef,
    httpService: HttpService,
    dataService: DataService,
    toastr: ToastrService,
    authService: AuthService
  ) { 
    super(bsModalRef, httpService, dataService, toastr, authService)
  }

  confirm($event) {
    this.bsModalRef.hide();
    this.dataService.publishCloseModalReason$({action: this.confirmAction, remarks: this.remarks});
  }

  ngOnInit(): void {
  }

}
