import { Component, OnInit, OnDestroy } from "@angular/core";
import { DatePipe, TitleCasePipe } from "@angular/common";
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";

import { QueueCountFilterModalComponent } from "../../component/queue-count-filter-modal/queue-count-filter-modal.component";
import { ReportFilterModalComponent } from "../../component/report-filter-modal/report-filter-modal.component";
import { SelectViewModalComponent } from "../../component/select-view-modal/select-view-modal.component";

import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { ReportsService } from "../../services/reports.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { QueueHistoryService } from "src/app/modules/queue-history/services/queue-history.service";
import { TeamManagementService } from "src/app/modules/team-management/services/team-management.service";

import { Subscription, throwError } from "rxjs";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import { TimePipe } from "src/app/shared/pipes/time.pipe";
import * as moment from "moment";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import * as fs from "file-saver";
import { defaultAvatar } from 'src/app/core/services/global';
import { ViewCustomReportComponentModal } from "../../component/view-custom-report-modal/view-custom-report-modal.component";
import { QueueHistoryFilterComponent } from "../../component/queue-history-filter/queue-history-filter.component";
import { SettingsService } from "src/app/modules/settings/services/settings.service";
import { catchError, map } from "rxjs/operators";
import { ICustomReports, ICustomReportsBase } from "../../interfaces/custom-reports";

@Component({
  selector: "app-reports-page",
  templateUrl: "./reports-page.component.html",
  styleUrls: ["./reports-page.component.scss"],
  providers: [TitleCasePipe, DatePipe],
})
export class ReportsPageComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  defaultAvatar: string = defaultAvatar;
  // branchId = this.dataService.currentBranch$?.branchId;
  branchId: string = '';
  title: string = '';
  subtitle: string = '';
  serviceText: string = '';
  windowText: string = '';
  reportType: string = '';
  serviceOrWindow: string = '';
  serviceOrWindowCopy: string = '';
  accountData = this.dataService.accountData$;
  customReports: any;
  subscriptions = new Subscription();
  isDownloading: boolean = false;
  accountList: Array<any> = [];
  serviceLists: Array<any> = [];
  selectedCard: string = ''
  selectedCustomReport: number = -1;
  resetView: boolean = true;
  customFormFields: any [] = [];

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private dataService: DataService,
    private reportService: ReportsService,
    private titleCasePipe: TitleCasePipe,
    private datePipe: DatePipe,
    private loaderService: LoaderService,
    private router: Router,
    private queueHistoryService: QueueHistoryService,
    private teamService: TeamManagementService,
    private httpService: HttpService,
    private settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe(async (res) => {
        if (res) {
          await this.handleBranchChange(res);
          await this.getAccounts();
          await this.getServiceLists();
          await this.getCustomReportsLists();
          await this.fetchCustomFormFields();

          if (!localStorage.getItem('customReportContents')) {
            this.getCustomReportContents();
          } else {
            this.isLoading = false;
          }
        }
      })
    );
  }

  async handleBranchChange(res: any): Promise<void> {
    this.branchId = res ? res._id : this.dataService.currentBranch$.branchId;
  }

  getCustomReportContents() {
    this.subscriptions.add(
      this.reportService.getCustomReportContents(this.branchId).subscribe(res => {
        if (res && res.data) {
          this.dataService.setCustomReportData(res.data);
          this.isLoading = false;
        }
      }, (error) => {
        console.log('error on fetching custom report contents', error)
        this.isLoading = false;
      })
    )
  }

  async getAccounts() {
    this.subscriptions.add(
      this.teamService.getTeamList("", this.branchId).subscribe((res) => {
        this.accountList = res.data;
      }, (error) => {
        console.log('error on fetching account lists', error)
        this.isLoading = false;
      })
    );
  }

  async getServiceLists() {
    this.subscriptions.add(
      this.httpService
      .get$(`services/${this.branchId}?limit=999`)
      .subscribe((data) => {
        this.serviceLists = data.data.map((element) => {
          return {
            label: element.displayName,
            id: element._id,
          };
        });
      }, (error) => {
        console.log('error on fetching service lists', error)
        this.isLoading = false;
      })
    );
  }

  async getCustomReportsLists() {
    this.subscriptions.add(
      this.reportService.customReports('GET', this.branchId).subscribe(res => {
        if (res && res.data) {
          this.customReports = res.data;
        }
      }, (error) => {
        console.log('error on fetching custom reports list', error)
      })
    );
  }

  async fetchCustomFormFields() {
    this.subscriptions.add(
      this.settingsService.customSmartForms('GET', this.branchId).pipe(
        map((res) => res.result?.fields),
        catchError((error) => {
          console.error("Error on fetching custom form fields", error);
          return throwError(error);
        })
      ).subscribe((data) => {
        if (data && data.length > 0) {
          this.customFormFields = data;
        }
      })
    );
  }

  selectViewModal(reportType) {
    if (this.selectedCustomReport > -1) {
      this.selectedCustomReport = -1
    }
    this.reportType = reportType;
    this.selectedCard = this.reportType;
    this.title = "Queue Count Report";
    this.subtitle = "Displays count of served, removed and cancelled queues";
    this.serviceText = "Generate Queue Count per service";
    this.windowText = "Generate Queue Count per Window";
    if (this.reportType === "arrivalTrend") {
      this.title = "Customer Arrival Trend";
      this.subtitle = "Displays count based on agreed service time per queue group or all queue groups";
      this.serviceText = "Generate Arrival Trend per service";
      this.windowText = "Generate Arrival Trend per Window";
    } else if (this.reportType === "waitingTime") {
      this.title = "Average Waiting Time";
      this.subtitle = "Displays the average waiting time per service or per window";
      this.serviceText = "Generate Ave. Waiting Time per service";
      this.windowText = "Generate Ave. Waiting Time per window";
    } else if (this.reportType === "servingTime") {
      this.title = "Serving Waiting Time";
      this.subtitle = "Displays the average serving time per service or per window";
      this.serviceText = "Generate Ave. Serving Time per service";
      this.windowText = "Generate Ave. Serving Time per window";
    }


    const initialState = {
      title: this.title,
      subtitle: this.subtitle,
      serviceText: this.serviceText,
      windowText: this.windowText,
      filterType: this.serviceOrWindow
    };
    this.modalRef = this.modalService.show(SelectViewModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.selectedView.subscribe(
      (data) => {
        if (data) {
          if (data == 'cancel' || data == 'close') {
            this.serviceOrWindow = '';
          } else {
            this.serviceOrWindow = data;
            this.serviceOrWindowCopy = this.serviceOrWindow;
            if (this.reportType === "queueCount") {
              this.selectQueueCountFilter(data);
            } else {
              this.selectFilter(data);
            }
          }
        }
      },
      (err) => {
        console.log("selected view", err);
        return false;
      }
    );
  }

  selectQueueCountFilter(view) {
    const initialState = {
      title: this.title,
      subtitle: this.subtitle,
      branchId: this.branchId,
      filterType: view
    };
    this.modalRef = this.modalService.show(QueueCountFilterModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.generate.subscribe(
      (data) => {
        if (data) {
          if (data.action == 'cancel') {
            this.selectViewModal(this.reportType);
          } else if (data == 'close') {
            this.serviceOrWindow = '';
            this.serviceOrWindowCopy = '';
          }
        }
      },
      (err) => {
        console.log("generate", err);
        return false;
      }
    );
  }

  selectFilter(view) {
    const initialState = {
      title: this.title,
      subtitle: this.subtitle,
      branchId: this.branchId,
      filterType: view,
      reportType: this.reportType
    };
    this.modalRef = this.modalService.show(ReportFilterModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.generate.subscribe(
      (data) => {
        if (data) {
          if (data.action == 'cancel') {
            this.selectViewModal(this.reportType);
          } else if (data == 'close') {
            this.serviceOrWindow = '';
            this.serviceOrWindowCopy = '';
          }
        }
      },
      (err) => {
        console.log("generate", err);
        return false;
      }
    );
  }

  generateReport(params) {
    this.loaderService.isLoading.next(true);
    const paramsObject = params.keys().reduce((object, key) => {
      object[key] = params.get(key);
      return object;
    }, {});

    this.getDatePeriod(paramsObject);

    if (this.reportType === "arrivalTrend") {
      this.subscriptions.add(
        this.reportService
        .getCustomerArrival(params, this.branchId)
        .subscribe((res) => {
          this.generateCustomerArrival(res.data);
          this.loaderService.isLoading.next(false);
        })
      );
    } else if (this.reportType === "waitingTime") {
      this.subscriptions.add(
        this.reportService
        .getAvgWaitingTime(params, this.branchId)
        .subscribe((res) => {
          this.generateAvgWaiting(res.data);
          this.loaderService.isLoading.next(false);
        })
      );
    } else {
      this.subscriptions.add(
        this.reportService
        .getQueueCounts(params, this.branchId)
        .subscribe((res) => {
          this.generateQueueCountReport(res.data);
          this.loaderService.isLoading.next(false);
        })
      );
    }
  }

  datePeriod: any;
  getDatePeriod(data) {
    const curr = new Date(); // get current date
    const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    const firstday = new Date(curr.setDate(first)).toUTCString();

    let date: any;

    switch (data.filterBy) {
      case "today":
        this.datePeriod = this.datePipe.transform(new Date(), "MMMM dd, yyyy");
        break;
      case "yesterday":
        date = new Date().setDate(new Date().getDate() - 1);
        this.datePeriod = this.datePipe.transform(date, "MMMM dd, yyyy");
        break;
      case "this-week":
        date = new Date(firstday).setDate(new Date(firstday).getDate());
        const date2 = new Date(firstday).setDate(
          new Date(firstday).getDate() + 6
        );
        this.datePeriod =
          this.datePipe.transform(date, "MMMM dd, yyyy") +
          " - " +
          this.datePipe.transform(date2, "MMMM dd, yyyy");
        break;
      case "this-month":
        this.datePeriod = this.datePipe.transform(curr, "MMMM yyyy");
        break;
      case "this-year":
        this.datePeriod = this.datePipe.transform(curr, "yyyy");
        break;
      case "custom":
        const start = new Date(parseInt(data.startDate));
        const end = new Date(parseInt(data.endDate));
        this.datePeriod =
          this.datePipe.transform(start, "MMMM dd, yyyy") +
          " - " +
          this.datePipe.transform(end, "MMMM dd, yyyy");
        break;
    }
  }

  formatDate1(date) {
    const d = new Date(date);
    return `${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`;
  }

  generateQueueCountReport(allData) {
    const title = `${this.titleCasePipe.transform(this.serviceOrWindowCopy)} Name`;
    var header = [
      "Date",
      title,
      "No. of Served Queues",
      "No. of Completed Queues",
      "No. of Removed Queues",
      "No. of Cancelled Queues",
      "Total No. of Queues",
    ];

    // Create workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Queue count report");

    // Add Row and formatting
    const titleRow = worksheet.addRow(["QUEUE COUNT REPORT"]);
    titleRow.font = { name: "Arial", family: 4, size: 14 };
    const subTitleRow = worksheet.addRow([
      "Date & Time Generated: " +
        this.datePipe.transform(new Date(), "EEEE, MMMM dd, yyyy hh:mm a"),
    ]);
    const subTitleRow2 = worksheet.addRow([
      `Generated By: ${this.accountData.data.firstName} ${this.accountData.data.lastName}`,
    ]);
    const subTitleRow4 = worksheet.addRow([
      "Period Covered: " + this.datePeriod,
    ]);

    // Blank Row
    worksheet.addRow([]);

    // Add Header Row
    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.font = { name: "Arial", bold: true };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // Add Data and Conditional Formatting
    allData.forEach((d) => {
      const total =
        d.counts.totalServedQueues +
        d.counts.totalRemovedQueues +
        d.counts.totalCancelledQueues;
      const arrayData = [
        this.formatDate1(d.range.date),
        this.serviceOrWindowCopy === "service" ? d.serviceName : d.windowName,
        d.counts.totalServedQueues,
        d.counts.totalCompletedQueues,
        d.counts.totalRemovedQueues,
        d.counts.totalCancelledQueues,
        total,
      ];
      const row = worksheet.addRow(arrayData);
      const number1 = row.getCell(3);
      const number2 = row.getCell(4);
      const number3 = row.getCell(5);
      const number4 = row.getCell(6);
      const number5 = row.getCell(7);
      number1.alignment = { vertical: "middle", horizontal: "center" };
      number2.alignment = { vertical: "middle", horizontal: "center" };
      number3.alignment = { vertical: "middle", horizontal: "center" };
      number4.alignment = { vertical: "middle", horizontal: "center" };
      number5.alignment = { vertical: "middle", horizontal: "center" };
    });

    // width
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      fs.saveAs(blob, "Queue Counts Report.xlsx");
    });
  }

  generateAvgWaiting(allData) {
    // Excel Title, Header, Data

    // Create workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(
      "Average Queue Waiting Time report"
    );

    // Add Row and formatting
    const titleRow = worksheet.addRow(["Average Waiting Time Report"]);
    titleRow.font = { name: "Arial", family: 4, size: 14 };
    const subTitleRow = worksheet.addRow([
      "Date & Time Generated: " +
        this.datePipe.transform(new Date(), "EEEE, MMMM dd, yyyy hh:mm a"),
    ]);
    const subTitleRow2 = worksheet.addRow([
      `Generated By: ${this.accountData.data.firstName} ${this.accountData.data.lastName}`,
    ]);
    const subTitleRow4 = worksheet.addRow([
      "Period Covered: " + this.datePeriod,
    ]);

    // const subTitleRow = worksheet.addRow(["Date & Time Generated: "]);
    // const subTitleRow2 = worksheet.addRow(["Generated By: "]);
    // subTitleRow.font = { name: "Arial", bold: true };
    // subTitleRow2.font = { name: "Arial", bold: true };

    worksheet.addRow([]);
    // const preheadRow = worksheet.addRow(["Queue Group: "]);
    // const preheadRow2 = worksheet.addRow(["Period Covered: "]);

    const title = `${this.titleCasePipe.transform(this.serviceOrWindowCopy)} Name`;
    const header = ["Date", title, "Average Queue Waiting Time"];

    // Add Header Row
    const headerRow = worksheet.addRow(header);

    headerRow.eachCell((cell, number) => {
      cell.font = { name: "Arial", bold: true };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // Add Data and Conditional Formatting
    allData.forEach((d) => {
      const arrayData = [this.formatDate1(d.range.date), this.serviceOrWindowCopy === "service" ? d.serviceName : d.windowName, (d.counts.avgWaitingTime ? d.counts.avgWaitingTime : 0)];
      const row = worksheet.addRow(arrayData);
      const number2 = row.getCell(2);
      number2.alignment = { vertical: "middle", horizontal: "center" };

      const number3 = row.getCell(3);
      number3.alignment = { vertical: "middle", horizontal: "right" };
    });

    // width
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 35;
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      fs.saveAs(blob, "Average Queue Waiting Time Report.xlsx");
    });
  }

  generateCustomerArrival(allData) {
    // Excel Title, Header, Data

    // Create workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Customer Arrival report");

    // Add Row and formatting
    const titleRow = worksheet.addRow(["Customer Arrival Report"]);
    titleRow.font = { name: "Arial", family: 4, size: 14 };
    const subTitleRow = worksheet.addRow([
      "Date & Time Generated: " +
        this.datePipe.transform(new Date(), "EEEE, MMMM dd, yyyy hh:mm a"),
    ]);
    const subTitleRow2 = worksheet.addRow([
      `Generated By: ${this.accountData.data.firstName} ${this.accountData.data.lastName}`,
    ]);
    const subTitleRow3 = worksheet.addRow([
      "Period Covered: " + this.datePeriod,
    ]);

    worksheet.addRow([]);

    // const preheadRow = worksheet.addRow(["Queue Group: "]);
    // const preheadRow2 = worksheet.addRow(["Period Covered: "]);
    // preheadRow.font = { name: "Arial", bold: true };
    // preheadRow2.font = { name: "Arial", bold: true };

    const title = `${this.titleCasePipe.transform(this.serviceOrWindowCopy)} Name`;
    let header = ["Date", title];

    allData.headers.forEach((x) => {
      header.push(x.time);
    });

    header.push("TOTAL");

    // Add Header Row
    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.font = { name: "Arial", bold: true };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    allData.counts.forEach((d) => {
      const total = d.counts.reduce((a, b) => a + b, 0);
      let arrayData = [
        this.formatDate1(d.range.date),
        this.serviceOrWindowCopy === "service" ? d.serviceName : d.windowName,
      ];

      d.counts.forEach((x) => {
        arrayData.push(x);
      });
      arrayData.push(total);

      const row = worksheet.addRow(arrayData);

      for (let index = 2; index < header.length; index++) {
        const element = index + 1;
        row.getCell(element).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
      }
    });

    for (let index = 0; index < header.length; index++) {
      const element = index + 1;
      worksheet.getColumn(element).width = 30;
    }

    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      fs.saveAs(blob, "Customer Arrival Report.xlsx");
    });
  }

  createCustomReports() {
    const queryParams: NavigationExtras = {
      queryParams: {
        type: 'create'
      }
    };
    this.router.navigate(['/reports/custom-reports'], queryParams);
  }

  customReportsDetails(reportId: string, index: number) {
    if (this.selectedCard != '') {
      this.selectedCard = '';
    }
    this.selectedCustomReport = index;
    const initialState = {
      title: 'View Custom Report',
      reportId: reportId,
      branchId: this.branchId,
      customFormFields: this.customFormFields
    }
    this.modalRef = this.modalService.show(ViewCustomReportComponentModal, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false
    })
  }

  downloadQueueHistory() {
    if (this.selectedCustomReport > -1) {
      this.selectedCustomReport = -1;
    }
    this.selectedCard = 'download-history';
    const initialState = {
      title: 'Queue History',
      subtitle: 'Generate the list of previous queues or transactions on your branch',
      branchId: this.branchId
    }
    this.modalRef = this.modalService.show(QueueHistoryFilterComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false
    })
  }

  exportQueueHistoryCSV(list: any) {
    const arrayData = list.map((element) => {
      return {
        queueNo: element.queueNo,
        servedBy: this.getAccountName(element.servedBy),
        serviceName: element.serviceName ? element.serviceName : this.getServiceName(element.serviceId),
        dateAndTimeQueued: moment(new Date(element.createdAt)).format("MM-DD-yyyy - HH:mm"),
        formattedWaitingTime: element.waitingTime ? this.millisecondsToTime(element.waitingTime) : this.getTimeAgo2(element.createdAt),
        formattedServingTime: element.servingTime ? this.millisecondsToTime(element.servingTime) : '',
        formattedStatus: this.titleCasePipe.transform(element.status),
      };
    });

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: [
        "Queue Number",
        "Served By",
        "Service",
        "Date and Time Queued",
        "Waiting Time",
        "Serving Time",
        "Status",
      ],
    };
    // tslint:disable-next-line: no-unused-expression
    new AngularCsv(arrayData, "Queue History", options);
  }

  getServiceName(serviceId: string) {
    let name = "";
    if (serviceId && this.serviceLists) {
      const s = this.serviceLists.find((x) => x.id === serviceId);
      if (s) {
        name = s.label;
      }
    }

    return name;
  }

  getAccountName(accountId: string) {
    let name = "";
    if (accountId && this.accountList) {
      const account = this.accountList.find((x) => x._id === accountId);
      if (account) {
        name = account.firstName + " " + account.lastName;
      }
    }

    return name != '' ? this.titleCasePipe.transform(name) : '';
  }

  getAccountAvatar(accountId: string) {
    let avatar = "";
    if (accountId && this.accountList) {
      const account = this.accountList.find((x) => x._id === accountId);
      if (account) {
        avatar = account.avatarUrl;
      }
    }
    return avatar;
  }

  millisecondsToTime(milliseconds: number) {
    let seconds = Math.floor((milliseconds / 1000) % 60);
    let minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    let hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
    let days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
  
    let time = '';

    if (days == 1) { time += days + ' day '; }
    if (days > 1) { time += days + ' days '; }
    if (hours == 1) { time += hours + ' hour '; }
    if (hours > 1) { time += hours + ' hours '; }
    if (minutes == 1) { time += minutes + ' minute '; }
    if (minutes > 1) { time += minutes + ' minutes '; }
    if (seconds == 1) { time += seconds + ' second '; }
    if (seconds > 1) { time += seconds + ' seconds '; }
    if (time === '') { time = ''; }
  
    return time;
  }

  getTimeAgo(data: number) {
    if (data) {
      const timePipe = new TimePipe();
      return timePipe.transform(data);
    }
  }

  getTimeAgo2(data: number){
    if(data){
      const y = new Date(data).getFullYear();
      const m = new Date(data).getMonth();
      const d = new Date(data).getDate();
      const h = new Date(data).getHours();
      const mm = new Date(data).getMinutes();
      const s = new Date(data).getSeconds();
      const time = moment([y, m, d, h, mm, s]).fromNow(true);

      return time === 'a day' ? '1 day' : time;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe(); // Unsubscribe from all subscriptions
  }
}
