import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sortBy",
})
export class SortPipe implements PipeTransform {
  transform(value: any[], order = "", column: string = ""): any[] {
    if (order === "desc") {
      return value.sort((a, b) => b.label.localeCompare(a.label));
    }
    return value.sort((a, b) => a.label.localeCompare(b.label));
  }
}
