import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { AuthService } from "src/app/core/auth/auth.service";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { HttpClient } from "@angular/common/http";
import { TeamManagementService } from "src/app/modules/team-management/services/team-management.service";
import { ModalWarningImageComponent } from "src/app/shared/components/modal-warning-image/modal-warning-image.component";
import { ModalWarningInputComponent } from "src/app/shared/components/modal-warning-input/modal-warning-input.component";
import { ServiceWindowAddFormComponent } from "../../components/service-window-add-form/service-window-add-form.component";
import { WindowAssignStaffFormComponent } from "../../components/window-assign-staff-form/window-assign-staff-form.component";
import { ServiceWindowService } from "../../services/service-window.service";
import { DeleteWindowModalComponent } from "../../components/delete-window-modal-component/delete-window-modal.component";

@Component({
  selector: "app-window-page",
  templateUrl: "./window-page.component.html",
  styleUrls: ["./window-page.component.scss"],
})
export class WindowPageComponent extends ServiceWindowService
  implements OnInit, OnDestroy {
  pageDetails = [
    { type: "main", name: "Branches", routerLink: ["/branch-management"] },
    { type: "sub", name: "", routerLink: null },
    { type: "current", name: "Windows", routerLink: null },
  ];
  title = "Windows";
  subtitle = "Categorize and manage the windows or counters in the branch";
  windowLists: Array<any>;
  windowNameLists: Array<any>;
  windowId: any;
  windowData: any;
  modifiedWindowData: any;
  selectedWindowIndex: number;
  tagsLists = ["walk-in", "promo"];
  newWindowLists = [];
  toggleSubject$ = new BehaviorSubject<any>(null);
  subscriptions = new Subscription();
  branchId: string;
  accountsList: any;
  assignedEmployees: any;
  branchData: any;
  allowMultiple: boolean = false;

  constructor(
    toastr: ToastrService,
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    httpService: HttpService,
    httpClient: HttpClient,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private teamService: TeamManagementService,
    private authService: AuthService,
    private router: Router,
  ) {
    super(httpClient, httpService, toastr);
  }

  async getWindowList() {
    await this.httpService
      .get$(`services/windows/${this.branchId}?limit=999`)
      .toPromise()
      .then((data) => {
        this.windowLists = data.data;
        // this.windowLists = this.windowLists.reverse();
        this.windowNameLists = this.windowLists.map((element) => {
          return {
            name: element.displayName,
            greyed: element.suspended,
            _id: element._id,
          };
        });
      });
  }

  async getBranchDetails() {
    await this.httpService
      .get$(`branches/${this.branchId}`)
      .toPromise()
      .then((data) => {
        this.branchData = data.data;
        this.pageDetails[1].name = this.branchData.name;
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  get canBeDeleted(): boolean {
    return this.windowLists?.length > 1;
  }

  async selectWindow($event) {
    this.selectedWindowIndex = $event;
    this.windowId = this.windowLists[this.selectedWindowIndex]?._id;
    await this.getWindowData(this.windowId);
  }

  async getWindowData(windowId) {
    if (windowId) {
      this.httpService
        .get$(`services/windows/${this.branchId}/${this.windowId}`)
        .subscribe((data) => {
          this.windowData = data.data;
          const { assignedServices, queueType, enabledFeatures, ...form } =
            this.windowData;
          const filteredAssignedServices: any = [];
          assignedServices.map((service) => {
            service.assignedWindows.map((window) => {
              if (window.windowId === this.windowData._id) {
                filteredAssignedServices.push(service);
              }
            });
          });
          this.modifiedWindowData = {
            ...form,
            isAutoTag: enabledFeatures.includes("auto-tag"),
            assignedServices: filteredAssignedServices.map(
              (element) => element.displayName,
            ),
          };

          this.allowMultiple = data.data.allowMultipleEmployee ?? false;

          this.getAssignedEmployees();
        });
    } else {
      this.windowData = null;
    }
  }

  getAssignedEmployees() {
    this.assignedEmployees = [];
    const isAllowAllStaff = this.windowData?.enabledFeatures.includes(
      "allowed-staff-to-serve-and-complete-queue",
    );
    if (isAllowAllStaff) {
      this.assignedEmployees = this.accountsList;
    } else {
      this.windowData.assignedEmployees.forEach((x) => {
        const acct = this.accountsList.find((a) => a._id === x);
        acct ? this.assignedEmployees.push(acct) : "";
      });
    }
  }

  uploadPhoto($event) {
    const param =
      `services/windows/${this.branchId}/${this.windowId}/presign-image-url`;
    this.httpService
      .uploadPhoto(param, $event)
      .then(async (data) => {
        this.toastr.success("Changes has been saved", "Success!");
        await this.refreshPage();
      })
      .catch(async (error) => {
        this.toastr.error("Changes has not been saved", "Error!");
        await this.refreshPage();
      });
  }

  addWindow() {
    const initialState = {
      title: "Add Window",
      type: "Window",
      subTitle:
        "Just enter the window name on the field below (and we’ll do the magic 😎)",
      branchId: this.branchId,
    };
    this.modalRef = this.modalService.show(ServiceWindowAddFormComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  saveWindowName($event) {
    const bodyForm = { displayName: $event };
    this.patchForm(bodyForm);
  }

  async checkToggleValue() {
    this.subscriptions.add(
      this.createDebounce(this.toggleSubject$).subscribe((res) => {
        if (res !== null) {
          this.patchForm(res);
        }
      }),
    );
  }

  createDebounce($event) {
    return $event.pipe(debounceTime(1000));
  }

  async assignStaff($event) {
    const initialState = {
      title: "Assign Staff",
      subTitle:
        `The staff in this window are responsible for serving and completing queues. Unassigned staff won’t have access on this window.`,
      // To Edit
      assignedStaffStringLists: ["id1", "id2"],
      windowId: this.windowId,
      windowData: this.windowData,
      confirmText: $event === "Add Window" ? "Confirm" : "Save Changes",
      cancelText: $event === "Add Window" ? "Skip for Now" : "Cancel",
      branchId: this.branchId,
      accountsList: this.accountsList,
    };
    this.modalRef = this.modalService.show(WindowAssignStaffFormComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  autoTag($event) {
    const feature = "auto-tag";
    const dataArray = this.windowData.enabledFeatures;
    const value = $event;
    const featuresArray = this.addDeleteFeature(dataArray, feature, value);
    const bodyForm = { enabledFeatures: featuresArray };
    this.toggleSubject$.next(bodyForm);
  }

  addTag($event) {
    this.httpService
      .addTag(
        "window",
        $event,
        this.modifiedWindowData.tags,
        this.windowId,
        null,
      )
      .then(async () => {
        await this.refreshPage();
      })
      .catch(async () => {
        await this.refreshPage();
      });
  }

  removeTag($event) {
    this.httpService
      .removeTag(
        "window",
        $event,
        this.modifiedWindowData.tags,
        this.windowId,
        null,
      )
      .then(async () => {
        await this.refreshPage();
      })
      .catch(async () => {
        await this.refreshPage();
      });
  }

  deleteWindowModal() {
    const initialState = {
      branchId: this.branchId,
      windowId: this.windowId,
      title: "Delete Window ?",
      windowName: this.windowData.displayName,
    };

    this.modalRef = this.modalService.show(DeleteWindowModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  disableWindow() {
    const initialState = {
      title: "Disable this window?",
      subTitle:
        `Visitors and staff will not be able to access or use “${this.windowData.displayName}” when disabled. Dont’ worry, you can enable it anytime.`,
      param: `services/windows/${this.branchId}/${this.windowId}/suspend`,
      bodyForm: { status: true },
      isConfirmDisabled: false,
      value1: this.windowData.displayName,
    };

    this.modalRef = this.modalService.show(ModalWarningInputComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  enableWindow() {
    const param = `services/windows/${this.branchId}/${this.windowId}/suspend`;
    const bodyForm = { status: false };
    this.submitPatchForm(param, bodyForm)
      .then(async (data) => {
        this.toastr.success(
          `${this.windowData.displayName} has been enabled`,
          "Window Enabled",
        );
        await this.refreshPage();
      })
      .catch(async (error) => {
        console.log("error", error);
        this.toastr.error("Changes has not been saved", "Error!");
        await this.refreshPage();
      });
  }

  archive() {
    // If One Left
    const initialState = {
      title: "Oops, This service cannot be archived",
      subTitle: `You cannot archive a service if there is only one remaining. 
      If you wish to archive this, create a new service or just edit its details.`,
      mainButtonText: "Okay, got it!",
    };
    this.modalRef = this.modalService.show(ModalWarningImageComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  patchForm(bodyForm) {
    const param = `services/windows/${this.branchId}/${this.windowId}`;
    this.submitPatchForm(param, bodyForm)
      .then(async (data) => {
        this.toastr.success("Changes has been saved", "Success!");
        await this.refreshPage();
      })
      .catch(async (error) => {
        console.log("error", error);
        this.toastr.error("Changes has not been saved", "Error!");
        await this.refreshPage();
      });
  }

  async refreshPage() {
    await this.getWindowList();
    await this.selectWindow(this.selectedWindowIndex);
  }

  async checkDataChange() {
    this.subscriptions.add(
      this.dataService.dataChange$.subscribe(async (res) => {
        if (res) {
          if (res.action === "Add Window") {
            await this.getWindowList();
            this.selectedWindowIndex = this.windowLists.length - 1;
            this.windowId = res.data.data._id;
            this.newWindowLists.push(this.windowId);
            await this.selectWindow(this.selectedWindowIndex);
            await this.assignStaff(res.action); // add back if staff are available
          } else if (res.action === "Delete window") {
            await this.getWindowList();
            await this.selectWindow(0);
          } else {
            await this.getWindowList();
            await this.selectWindow(this.selectedWindowIndex);
          }
        }
      }),
    );
  }

  async ngOnInit() {
    this.branchId = this.activatedRoute.snapshot.paramMap.get("branchId");
    this.selectedWindowIndex = 0;
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params && params.type === "setup") {
        this.addWindow();
        this.authService.updateIntroLevel({ level: 3.2 }).subscribe((res) => {
          this.dataService.publishIntroLevel$(
            res.result.completedIntroductionLevels,
          );
        });
      }
    });
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe(async (res) => {
        if (res) {
          if (res && this.branchId !== res?._id) {
            this.router.navigateByUrl("/", { skipLocationChange: true }).then(
              () => {
                this.router.navigateByUrl(`/window/${res?._id}`);
              },
            );
          }
          await this.handleBranchChange(res);
          await this.getAccounts();
          await this.getBranchDetails();
          await this.getWindowList();
          // initialize data for the right side
          await this.selectWindow(0);
          await this.checkDataChange();
          await this.checkToggleValue();
        }
      }),
    );
  }

  async handleBranchChange(res: any): Promise<void> {
    this.branchId = res ? res._id : this.dataService.currentBranch$.branchId;
    this.pageDetails[1].routerLink = ["/branch-management", this.branchId];
  }

  async getAccounts() {
    this.subscriptions.add(
      this.teamService.getTeamList("", this.branchId).subscribe((res) => {
        this.accountsList = res.data;
      }),
    );
  }

  toggleMultipleEmployee($event) {
    this.allowMultiple = $event;

    this.patchForm({ allowMultipleEmployee: $event });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
