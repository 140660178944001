import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/core/auth/auth.service";
import { DataService } from "src/app/core/services/data.service";
import { environment } from "src/environments/environment";
import { CustomSmartFormResponse } from "../interfaces/custom-form-fields";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  apiUrl = environment.urlAPI;
  accountId = this.authService.accountId$;

  constructor(
    private http: HttpClient,
    public authService: AuthService,
    private dataService: DataService
  ) { }

  private get httpHeader(): any {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return {
      headers,
    };
  }

  public getSmsSettings(businessId: string, branchId: string): Observable<any> {
    const url = `${this.apiUrl}/sms/settings?branchId=${branchId}&businessId=${businessId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public enableSmsSetting(data: any): Observable<any> {
    const url = `${this.apiUrl}/sms/settings`;
    return this.http.post(url, data).pipe(map((response) => response));
  }

  public updateSmsSettings(data: any): Observable<any> {
    const url = `${this.apiUrl}/sms/settings`;
    return this.http.patch(url, data).pipe(map((response) => response));
  }

  public getBusinessData(businessId: any): Observable<any> {
    const url = `${this.apiUrl}/business/${businessId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getBranchQueueSettings(branchId: any): Observable<any> {
    const url = `${this.apiUrl}/queues/queue-settings/${branchId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public editBranchQueueSettings(branchId: any, data: any): Observable<any> {
    const url = `${this.apiUrl}/queues/queue-settings/${branchId}`;
    return this.http.patch(url, data).pipe(map((response) => response));
  }

  public getIndustries(): Observable<any> {
    const url = `${this.apiUrl}/industries`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public editBusinessSettings(businessId: any, data: any): Observable<any> {
    const url = `${this.apiUrl}/business/${businessId}`;
    return this.http.patch(url, data).pipe(map((response) => response));
  }

  public getSmartFormLists(branchId: any): Observable<any> {
    const url = `${this.apiUrl}/services/smart-forms/${branchId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public createSmartForm(branchId: any, data: any): Observable<any> {
    const url = `${this.apiUrl}/services/smart-forms/${branchId}`;
    return this.http.post(url, data).pipe(map((response) => response));
  }

  public getUniversalTagSet(branchId: string): Observable<any>{
    const url = `${this.apiUrl}/queues/universal-tag-set/${branchId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public createUniversalTagSet(data:any): Observable<any> {
    const url = `${this.apiUrl}/queues/universal-tag-set/${data.branchId}`;
    return this.http.post(url, data.setTagBody).pipe(map((response) => response));
  }
  
  public editUniversalTagSet(data:any): Observable<any> {
    const url = `${this.apiUrl}/queues/universal-tag-set/${data.branchId}`;
    console.log("url", url);
    return this.http.patch(url, data.setTagBody).pipe(map((response) => response));
  }

  public editSmartForm(branchId: any, smartFormId: any, data: any): Observable<any> {
    const url = `${this.apiUrl}/services/smart-forms/${branchId}/${smartFormId}`;
    return this.http.patch(url, data).pipe(map((response) => response));
  }

  public getSmartFormDetails(branchId: any, smartFormId: any): Observable<any> {
    const url = `${this.apiUrl}/services/smart-forms/${branchId}/${smartFormId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public customSmartForms(action: string, branchId: string, data?: any, customSmartFormId?: string): Observable<CustomSmartFormResponse> {
    let url: string = `${this.apiUrl}/services/custom-smart-forms/${branchId}`;
    if (action == 'PATCH') {
      url += `/${customSmartFormId}`;
    }

    console.log("this is the data I received")
    console.log(data);

    const options = {
      headers: this.httpHeader,
      responseType: 'json' as const
    };

    switch (action) {
      case 'POST':
        return this.http.post<CustomSmartFormResponse>(url, data, options);
      case 'PATCH':
        return this.http.patch<CustomSmartFormResponse>(url, data, options);
      default: // for GET
        return this.http.get<CustomSmartFormResponse>(url, options);
    }
  }
}
