import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-no-attending-staff-modal",
  templateUrl: "./no-attending-staff-modal.component.html",
  styleUrls: ["./no-attending-staff-modal.component.scss"],
})
export class NoAttendingStaffModalComponent implements OnInit {
  title: string;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {}
}
