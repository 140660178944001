import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { DataService } from 'src/app/core/services/data.service';
import { Subscription } from "rxjs";
import * as moment from 'moment';
import { AppointmentService } from '../../services/appointment.service';
import { ToastrService } from "ngx-toastr";
import { SMART_FORM_NAMES } from '../../enum/smart_form';
import { IAccountServe } from '../../interface/appointment.interface';

@Component({
  selector: 'app-appointment-modal',
  templateUrl: './appointment-modal.component.html',
  styleUrls: ['./appointment-modal.component.scss']
})
export class AppointmentModalComponent implements OnInit, OnDestroy {
  @Input() title: any;
  @Input() subTitle: string;
  @Input() data: any;
  @Input() selectView: string;
  @Output() successEvent = new EventEmitter();
  appointmentDate: string;
  appointmentBookDate: string;
  newFormattedDate: string;
  isCollapsed: boolean = false;
  subscriptions = new Subscription();
  selectedCurrentBranch: any = this.dataService.currentBranch$;
  selectedAccountData: IAccountServe;

  constructor(
    private modalRef: BsModalRef,
    private dataService: DataService,
    private appointmentService: AppointmentService,
    private toastr: ToastrService,
    private datePipe: DatePipe
  ) { }
  
  private formatDateWithourHours(dateCreated: number): string {
    const date = new Date(dateCreated);
    const formattedDate = this.datePipe.transform(date, 'EEE, MM/dd/yyyy');
    return formattedDate;
  }

  private formatDate(dateCreated: number): string {
    const date = new Date(dateCreated);
    const formattedDate = this.datePipe.transform(date, 'EEE, MM/dd/yyyy - h:mm a');
    return formattedDate;
  }

  clsoeAppointmentModal() {
    this.successEvent.emit('done');
  }

  setDateLabel() {
    this.newFormattedDate = moment(this.data.formattedDate).format("dddd, MMMM Do YYYY")
  }

  addQueue() {
    this.successEvent.emit('add-queue');
  }

  accountList() {
    this.subscriptions.add(
      this.appointmentService
        .getTeamList("", this.selectedCurrentBranch.branchId)
        .subscribe(
          res => {
            for(let account of res.data) {
              if(account?._id === this.dataService.accountData$.data.accountId) {
                this.selectedAccountData = {
                  accountId: account._id,
                  email: account.email,
                  avatarUrl: account.avatarUrl,
                  roleLevel: account.roleLevel,
                  fullname: account.firstName + " " + account.lastName,
                } 
              }
            }
          },
          error => {
            console.log("error data");
            console.log(error);
          }
        )
    );
  }

  finishProcess(appointmentData) {
    const branchId = this.dataService.currentBranch$.branchId;

    const requestForm = {
      status: 'completed',
      completedBy: this.selectedAccountData ?? {},
      remarks: "",
      queueGroupId: ""
    };

    this.appointmentService.moveAppointment(branchId,requestForm, appointmentData._id ).subscribe(
      (res) => {
        this.successEvent.emit('move-history');
        this.modalRef.hide();
        this.toastr.success(`Appointment completed`, "Success!",{
          toastClass: "ngx-toastr toast-success-custom",
        });
      },
      (error) => {
        console.log(error)
        this.toastr.error("Error encountered upon submitting ", "Error!");
      }
    ) 
  }

  closeModal(): void {
    this.modalRef.hide();
  }

  ngOnInit(): void {
    this.setDateLabel();
    this.accountList()
    this.appointmentDate = this.formatDateWithourHours(this.data.timeslot);
    this.appointmentBookDate = this.formatDate(this.data.createdAt);
    for (const smartForm of this.data.visitorDetails) {
      switch (smartForm.fieldName) {
        case SMART_FORM_NAMES.NAME:
          this.data.firstname = smartForm.value;
          break;
        case SMART_FORM_NAMES.MOBILENO:
          this.data.mobileNumber = smartForm.value;
          break;
        case SMART_FORM_NAMES.PRIORITY:
         this.data.priority = smartForm.value ? "Yes" : "No";
          break;
        case SMART_FORM_NAMES.VISITORS:
          this.data.visitors = smartForm.value;
          break;
        default:
          break;
      }
    } 
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


}
