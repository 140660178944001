import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { HttpClient } from '@angular/common/http';
import { HttpService } from 'src/app/core/http/http.service';
import { ServiceWindowService } from "../../services/service-window.service"
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-delete-service-modal',
  templateUrl: './delete-service-modal.component.html',
  styleUrls: ['./delete-service-modal.component.scss']
})
export class DeleteServiceModalComponent extends ServiceWindowService implements OnInit  {
  @Input() title: any;
  @Input() branchId: string;
  @Input() serviceData: any;
  isSubmitting: boolean = false;
  isActiveService: boolean = false;

  constructor(
    public modalRef: BsModalRef,
    private dataService: DataService,
    public toastr: ToastrService,
    public httpService: HttpService,
    public httpClient: HttpClient,
  ) {
    super(httpClient, httpService, toastr);
   }

  confirm() {
    this.isSubmitting = true;
    this.deleteService(this.branchId, this.serviceData._id).subscribe(
      (data) => {
        if(data) {
          this.modalRef.hide();
          this.toastr.error(
            "Service has been deleted",
            "Deleted"
          );
          
          this.dataService.publishDataChange$({
            action: 'Delete Service',
            data: data
          });
        }      
   
      },
      (err) => {
        console.log(err);
        this.title = "Oops, Wait";
        this.isActiveService = true;
        this.isSubmitting = false;
      }
  );
    
  }
  
  ngOnInit(): void {
  }

}
