import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/auth/auth.service";
import { HttpService } from "src/app/core/http/http.service";
import { SocketService } from "src/app/core/http/socket.service";
import { DataService } from "src/app/core/services/data.service";
import { JwtService } from "src/app/core/services/jwt.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { BusinessService } from "src/app/modules/auth/services/business.service";
import { BranchManagementService } from "src/app/modules/branch-management/services/branch-management.service";
import { SettingsService } from "src/app/modules/settings/services/settings.service";
import { MultipleLoginModalComponent } from "src/app/modules/team-management/components/multiple-login-modal/multiple-login-modal.component";
import { ModalResetPasswordComponent } from "../../components/modal-reset-password/modal-reset-password.component";
import { Store } from "@ngrx/store";
import { checkNotificationState, checkMarkAllAsReadState } from "src/app/modules/notifications/store/selectors/notifications";
import { TeamManagementService } from "src/app/modules/team-management/services/team-management.service";
import { setHasNotification } from "src/app/modules/notifications/store/actions/notifications";

export const ROUTES: any[] = [
  {
    path: "/dashboards",
    title: "Dashboards",
    type: "sub",
    icontype: "ni-shop text-primary-a2",
    isCollapsed: true,
    children: [
      { path: "dashboard", title: "Dashboard", type: "link" },
      { path: "alternative", title: "Alternative", type: "link" },
    ],
  },
];

@Component({
  selector: "app-loggedin-layout",
  templateUrl: "./loggedin-layout.component.html",
  styleUrls: ["./loggedin-layout.component.scss"],
  providers: [DatePipe],
})
export class LoggedinLayoutComponent extends JwtService implements OnInit {
  isMobileResolution: boolean;
  accountData: any;
  public appLoading = false;

  loaderSubtitle = "";

  public menuItems: any[];
  public isCollapsed = true;
  public isCollapsed2 = true;
  public isCollapsed3 = true;
  public isCollapsed4 = true;
  public isCollapsed5 = true;
  public isCollapsed6 = true;

  isDashboard: boolean;
  showGettingStarted: boolean;
  subscriptions = new Subscription();
  branchId: string;
  introLevel = [];
  introSettings = [];
  branchData = this.dataService.currentBranch$;
  passwordSocket: BehaviorSubject<any>;
  notificationState$: Observable<boolean>
  isNotificationOpen: boolean;
  markAllAsReadState$: Observable<boolean>
  isMarkedAllAsRead: boolean;
  private destroyHasNotificationState$ = new Subject<void>();

  constructor(
    public authService: AuthService,
    public http: HttpClient,
    public httpService: HttpService,
    public dataService: DataService,
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private branchService: BranchManagementService,
    private settingsService: SettingsService,
    private wsService: SocketService,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    public datePipe: DatePipe,
    public businessService: BusinessService,
    private store: Store<{ notificationState: boolean }>,
    private teamManagementService: TeamManagementService,
  ) {
    super(authService, http, httpService, dataService, businessService);
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
    this.loaderService.isLoading.subscribe((v) => {
      this.loaderSubtitle = "";
      if (this.router.url.indexOf("queue-workspace") > -1) {
        this.loaderSubtitle = "Preparing your workspace";
      }

      if (this.router.url.indexOf("tv") > -1) {
        this.loaderSubtitle = "Generating login code";
      }

      if (this.router.url.indexOf("report") > -1) {
        this.loaderSubtitle = "Generating your report";
      }

      if (this.router.url.indexOf("queue-workspace") > -1) {
        this.isDashboard = false;
      } else {
        this.isDashboard = true;
      }

      this.appLoading = v;
    });

    this.store
      .select(checkMarkAllAsReadState)
      .pipe(takeUntil(this.destroyHasNotificationState$))
      .subscribe((state) => {
        if (state) {
          this.store.dispatch(setHasNotification({hasNotification: state}))
        }
      });

    this.store
      .select(checkNotificationState)
      .pipe(takeUntil(this.destroyHasNotificationState$))
      .subscribe((state) => {
        this.isNotificationOpen = state;
      });
  }
  @HostListener("window:resize", ["$event"])
  isMobile(event) {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  async ngOnInit() {
    if (!this.authService.accountId$) {
      await this.checkAccessToken();
    }
    await this.getAccountData();
    this.accountData = this.dataService.accountData$;

    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });

    this.subscriptions.add(
      this.dataService.branchChange$.subscribe((res) => {
        this.branchId = res
          ? res._id
          : this.dataService.currentBranch$?.branchId;
      })
    );

    this.introLevel = this.dataService.getIntroLevel();

    this.subscriptions.add(
      this.dataService.introLevel$.subscribe((res) => {
        this.introLevel = res ? res : this.dataService.getIntroLevel();
        this.checkIntroLevel();
      })
    );
    this.checkIntroLevel();
    this.getBusinessSettings();
    if (!this.branchData) {
      this.branchData = this.branchService.getSelectedBranch();
    }
    this.resetPasswordSocket();
    this.multipleLoginSocket();
  }

  resetPasswordSocket() {
    this.passwordSocket = this.wsService
      .connect(`v4::account::reset-password::${this.accountId}`)
      .pipe(
        map((response: any): any => {
          return response;
        })
      ) as BehaviorSubject<any>;
    this.subscriptions.add(
      this.passwordSocket.subscribe((msg) => {
        if (msg) {
          this.authService.logout().subscribe((res) => {
            if (res.success) {
              this.bsModalRef.hide();
              setTimeout(() => {
                this.bsModalRef = this.modalService.show(
                  ModalResetPasswordComponent,
                  {
                    class: "modal-dialog-centered modal-md",
                    ignoreBackdropClick: true,
                    keyboard: false,
                  }
                );
              });
            }
          });
        }
      })
    );
  }

  multipleLoginSocket() {
    this.passwordSocket = this.wsService
      .connect(`account::multiple-login::${this.accountId}::business-portal`)
      .pipe(
        map((response: any): any => {
          return response;
        })
      ) as BehaviorSubject<any>;

    this.subscriptions.add(
      this.passwordSocket.subscribe((msg) => {
        if (msg) {
          this.authService.logout().subscribe((res) => {
            if (res.success) {
              this.bsModalRef.hide();
              setTimeout(() => {
                this.bsModalRef = this.modalService.show(
                  MultipleLoginModalComponent,
                  {
                    class: "modal-dialog-centered modal-md",
                    ignoreBackdropClick: true,
                    keyboard: false,
                  }
                );
              });
            }
          });
        }
      })
    );
  }

  getBusinessSettings() {
    this.settingsService
      .getBranchQueueSettings(this.branchId)
      .subscribe((res) => {
        const data = [];
        if (res.data.notificationTypes.includes("online-queue")) {
          data.push(4.1);
        }
        // if (res.data.notificationTypes.includes("kiosk")) {
        //   data.push(4.2);
        // }
        if (res.data.platforms.includes("sms")) {
          data.push(5.1);
        }
        if (res.data.platforms.includes("tv-display")) {
          data.push(5.2);
        }
        this.dataService.setIntroSettings(data);
        this.introSettings = data;
        if (this.introLevel.length === this.introSettings.length + 3) {
          this.isCollapsed = true;
          this.isCollapsed2 = true;
          this.isCollapsed3 = true;
          this.isCollapsed4 = true;
          this.isCollapsed5 = true;
          this.isCollapsed6 = false;
        }
      });
  }

  get isCompleted() {
    return this.introLevel.length === this.introSettings.length + 3;
  }

  get isNotificationComplete() {
    if (((this.introSettings.includes(5.1) && !this.introSettings.includes(5.2)) && this.introLevel.includes(5.1))
      || ((this.introSettings.includes(5.2) && !this.introSettings.includes(5.1)) && this.introLevel.includes(5.2))
      || ((this.introSettings.includes(5.1) && this.introSettings.includes(5.2)) && (this.introLevel.includes(5.1) && this.introLevel.includes(5.2)))) {
      return true;
    } else if ((this.introSettings.includes(5.1) && !this.introLevel.includes(5.1))
      || (this.introSettings.includes(5.2) && !this.introLevel.includes(5.2))) {
      return false;
    }
  }

  checkIntroLevel() {
    this.isCollapsed = true;
    this.isCollapsed2 = true;
    this.isCollapsed3 = true;
    this.isCollapsed4 = true;
    this.isCollapsed5 = true;
    this.isCollapsed6 = true;

    if (this.introLevel.length === this.introSettings.length + 3) {
      this.isCollapsed6 = false;
    } else {
      if (!this.introLevel.includes(2)) {
        this.isCollapsed2 = false;
      } else if (this.introLevel.includes(2)) {
        this.isCollapsed3 = false;
      } else if (this.introLevel.includes(3.1) || this.introLevel.includes(3.2)) {
        this.isCollapsed3 = false;
      } else if (this.introLevel.includes(4.1)) {
        this.isCollapsed5 = false;
      }
    }
  }

  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }

  inviteMembers() {
    this.router.navigate(["/team-management"], {
      queryParams: { type: "setup" },
    });
  }

  setupService() {
    this.router.navigate(["/service", this.branchId], {
      queryParams: { type: "setup" },
    });
  }
  setupWindow() {
    this.router.navigate(["/window", this.branchId], {
      queryParams: { type: "setup" },
    });
  }

  setupBranch() {
    this.router.navigate(["/branch-management/setup", this.branchId], {
      queryParams: { type: "setup" },
    });
  }

  setupTvDisplay() {
    this.router.navigate(["/branch-management/tv", this.branchId], {
      queryParams: { type: "setup" },
    });
  }

  gotoSmsSettings() {
    this.router.navigate(["/settings"], {
      queryParams: { type: "sms-setup" },
    });
  }

  operationHours() {
    this.router.navigate(
      ["/branch-management/operation-hours", this.branchId],
      {
        queryParams: { type: "setup" },
      }
    );
  }

  allSet() {
    this.authService.updateIntroLevel({ level: 6 }).subscribe((res) => {
      this.dataService.publishIntroLevel$(
        res.result.completedIntroductionLevels
      );
      this.introLevel = res.result.completedIntroductionLevels;
    });
  }

  removeGettingStarted() { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.wsService.disconnect();
  }
}
