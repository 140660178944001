import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  apiUrl = environment.urlAPI;

  constructor(private http: HttpClient) { }
  
  private get httpHeader(): any {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return {
      headers,
    };
  }

  makePayment(data: any): Observable<any>{
    const url = "http://localhost:5000/create-checkout-session/"
 
    return this.http.post<any>(url,data)
  }

  getPriceList(): Observable<any>{
    const url = `${this.apiUrl}/billing/product/list`;
    return this.http.get<any>(url, this.httpHeader);
  }

  createCheckOutSession(businessId, data): Observable<any>{
    const url = `${this.apiUrl}/billing/subscription/${businessId}/create-checkout-session`;
    return this.http.post(url, data).pipe(map((response) => response));
  }
}
