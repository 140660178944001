import { Component, Input, Output, OnInit, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppointmentSettingsService } from '../../services/appointment-settings.service';
import { BranchManagementService } from 'src/app/modules/branch-management/services/branch-management.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-appointment-service-settings',
  templateUrl: './appointment-service-settings.component.html',
  styleUrls: ['./appointment-service-settings.component.scss']
})
export class AppointmentServiceSettingsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() selectedService: any;
  @Output() updatedSelectedService = new EventEmitter<any>();

  isLoading: boolean = false;
  isSubmitting: boolean = false;

  branchDetails: any = this.branchService.getSelectedBranch();
  enableAppointment: boolean = false;
  subscriptions = new Subscription();

  serviceDetailsForm = this.fb.group({
    displayName: ["", Validators.required],
    description: ""
  });

  get f() {
    return this.serviceDetailsForm.controls;
  }

  constructor(
    private appointmentSettingsService: AppointmentSettingsService,
    private branchService: BranchManagementService,
    private fb: FormBuilder,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.setInitialValues();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.selectedService){
      this.setInitialValues();
    }
  }

  setInitialValues(){
    this.enableAppointment = this.selectedService.enableAppointment;
    this.f['displayName'].setValue(this.selectedService.displayName);
    this.f['description'].setValue(this.selectedService.description ? this.selectedService.description : "");
    this.isLoading = false;
  }

  changeInput(event, field){
    if(field == 'enable-appointment'){
      this.enableAppointment = !this.enableAppointment;
    } else {
      this.f[field].setValue(event.value);
    }
  }

  saveChanges(){
    this.isSubmitting = true;

    let data = {
      // displayName: this.f['displayName'].value,
      description: this.f['description'].value,
      enableAppointment: this.enableAppointment,
    };


    this.subscriptions.add(
      this.appointmentSettingsService.updateService(this.branchDetails._id, this.selectedService._id, data)
      .subscribe((res)=>{
        this.toastr.success("Service's Settings have been updated", "Success!");
        this.updatedSelectedService.emit(res.data);
        this.setInitialValues();
        this.isSubmitting = false;
      })
    )
  }
}
