import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { PaymentService } from "../../services/payment.service";

@Component({
  selector: "app-payment-modal",
  templateUrl: "./payment-modal.component.html",
  styleUrls: ["./payment-modal.component.scss"],
})
export class PaymentModalComponent implements OnInit {
  @Output() successEvent = new EventEmitter();
  @Input() productId: any = 'price_1Lc0o4AsY853cIuSrpNJSUTv';
  paymentElementForm = this.fb.group({
    name: ["John doe", [Validators.required]],
    email: ["support@ngx-stripe.dev", [Validators.required]],
    mobileNo: ["", Validators.minLength(10)],
    address: [""],
    zipcode: [""],
    city: [""],
    amount: [2500, [Validators.required, Validators.pattern(/d+/)]],
  });
  subscription: any;
  get f() {
    return this.paymentElementForm.controls;
  }

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private router: Router,
    private paymentService: PaymentService
  ) {}

  ngOnInit(): void {
    this.getProductList();
  }

  changeInput(ev) {
    this.paymentElementForm.value.mobileNo = ev.value;
    this.paymentElementForm.markAsDirty();
  }

  goToApp() {
    this.router.navigate(["/billing"]);
    this.bsModalRef.hide();
    setTimeout(() => {
      window.location.reload();
    });
  }

  getProductList() {
    this.paymentService.getPriceList().subscribe(
      (res) => {
        if (res && res.data) {
          const tier = res.data.find(
            (x) => x.pricingList.find(p => p.id === this.productId)
          );


          const price = tier.pricingList.find(
            (x) => x.id === this.productId
          );
      
          this.subscription = {
            id: tier.id,
            tier: tier.name,
            description: tier.description,
            price: price?.unit_amount_decimal.slice(0, -2),
            features: tier.metadata.feature_list
              ? tier.metadata.feature_list.split(";")
              : [],
          };
        }
      },
      (error) => {
        console.log("PRODUCT LIST ERROR", error);
      }
    );
  }
}
