import { Component, OnInit } from "@angular/core";
import { IAccordion } from "src/app/shared/components/accordion/interfaces/accordion";

@Component({
  selector: "app-support-help-center-sub-quick-setup",
  templateUrl: "./support-help-center-sub-quick-setup.component.html",
  styleUrls: ["./support-help-center-sub-quick-setup.component.scss"],
})
export class SupportHelpCenterSubQuickSetupComponent implements OnInit {
 

  constructor() { }

  ngOnInit(): void { }
}
