import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {

  dateToday = new Date();

  _queuesCount: any;
  @Input() public set queuesCount(count){
    this._queuesCount = count;
  }
  public get queuesCount(){
    return this._queuesCount
  }

  _activeQueuesCount: any;
  @Input() public set activeQueuesCount(active){
    this._activeQueuesCount = active;
  }
  public get activeQueuesCount(){
    return this._activeQueuesCount
  }

  _currentQueuesCount: any;
  @Input() public set currentQueuesCount(current){
    this._currentQueuesCount = current;
  }
  public get currentQueuesCount(){
    return this._currentQueuesCount
    
  }

  _servicesCount: any;
  @Input() public set servicesCount(service){
    if (service) {
      const enabledServices = service.filter(x => !x.suspended);
      this._servicesCount = enabledServices;
    }
  }
  public get servicesCount(){
    return this._servicesCount
  }

  @Input() accountList: any;

  constructor() { }

  ngOnInit(): void {
    // console.log("queues count", this.queuesCount)
    // console.log("active queues count", this.activeQueuesCount)
    // console.log("current queues count", this.currentQueuesCount)
  }

}
