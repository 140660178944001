import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ProcedureService } from "../../services/procedure.service";

@Component({
  selector: 'app-delete-procedure-modal',
  templateUrl: './delete-procedure-modal.component.html',
  styleUrls: ['./delete-procedure-modal.component.scss']
})
export class DeleteProcedureModalComponent implements OnInit {
  @Input() title: any;
  @Input() branchId: string;
  @Input() templateDataId: any;
  @Input() serviceProcedureName: string;
  isSubmitting: boolean = false;
  isActiveServiceProcedure: boolean = false;

  constructor(
    public modalRef: BsModalRef,
    private router: Router,
    private toastr: ToastrService,
    private procedureService: ProcedureService,
  ) { }
   
  cancel() {
    this.modalRef.hide();
    this.router.navigate([`/apps/service-procedure/${this.templateDataId}`]);
  } 
   
  confirm() {
    this.isSubmitting = true;

    this.procedureService.deleteServiceProcedureTemplate(this.branchId, this.templateDataId).subscribe(
      (data) => {
        if(data) {
          this.modalRef.hide();
          this.toastr.error(
            "Procedure has been deleted",
            "Deleted"
          );
          this.router.navigate(["/apps/service-procedure"]);  
          this.isSubmitting = false;
        }      
      },
      (err) => {
        this.title = "Oops, Wait";
        this.isActiveServiceProcedure = true;
        this.isSubmitting = false;
      }
    );
  }


  ngOnInit(): void {
    
  }

}
