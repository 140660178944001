import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { AddEditDetailsModalComponent } from "../../component/add-edit-details-modal/add-edit-details-modal.component";
import { ProcedureService } from "../../services/procedure.service";

var misc: any = {
  sidebar_mini_active: true,
};

@Component({
  selector: "app-service-procedure-page",
  templateUrl: "./service-procedure-page.component.html",
  styleUrls: ["./service-procedure-page.component.scss"],
})
export class ServiceProcedurePageComponent implements OnInit, OnDestroy {
  branchId: string;
  subscriptions = new Subscription();
  serviceLists: any;
  activate = false;
  procedureTemplates: any;
  
  constructor(
    private dataService: DataService,
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    public httpService: HttpService,
    private procedureService: ProcedureService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe((res) => {
        this.branchId = res
          ? res._id
          : this.dataService.currentBranch$?.branchId;

          this.getServiceLists();
          this.getProcedureTemplateList();
      })
    );

    this.minimizeSidebar();
    // this.getServiceLists();
    // this.getProcedureTemplateList();
  }

  public viewTemplate(id) {
    this.router.navigate(["/apps/service-procedure/", id], {
      queryParams: { type: "edit" },
    });
  }

  public getServiceName(id) {
    if (id && this.serviceLists) {
      const s = this.serviceLists.find((x) => x.value === id);
      if (s) {
        return s.label;
      }
    }
  }

  public getProcedureTemplateList() {
    this.procedureService.getProcedureTemplates(this.branchId).subscribe(
      (res) => {
        console.log("procedure templates", res);

        this.procedureTemplates = res.result.data;
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  public getServiceLists() {
    this.httpService
      .get$(`services/${this.branchId}?limit=999`)
      .subscribe((data) => {
        this.serviceLists = data.data.map((element) => {
          return {
            label: element.displayName,
            value: element._id,
          };
        });
        console.log("service lists", this.serviceLists);
      });
  }

  public addProcedure() {
    console.log(this.branchId)
    const initialState = {
      branchId: this.branchId,
      serviceLists: this.serviceLists,
      title: "New Procedure",
      type: "add",
    };
    this.modalRef = this.modalService.show(AddEditDetailsModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        console.log(data);
        this.getProcedureTemplateList();
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }

  public activateProcedure(ev, procedureTemplateId) {
    this.activate = ev;

    this.procedureService
      .activateStatus(this.branchId, procedureTemplateId, {
        status: ev,
      })
      .subscribe(
        (res) => {
          if (this.activate) {
            this.toastr.success(
              "Service Procedure has been Activated",
              "Activated!"
            );
          } else {
            this.toastr.warning(
              "Service Procedure has been Deactivated",
              "Deactivated!"
            );
          }

          this.getProcedureTemplateList();
        },
        (error) => {
          console.log("error", error);
        }
      );
  }

  public editDetails(templateData) {
    const initialState = {
      branchId: this.branchId,
      templateData: templateData,
      serviceLists: this.serviceLists,
      title: "Edit Procedure Details",
      type: "edit",
    };
    this.modalRef = this.modalService.show(AddEditDetailsModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });

  }
  
  minimizeSidebar() {
    const sidenavToggler =
      document.getElementsByClassName("sidenav-toggler")[0];
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("g-sidenav-pinned")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active) {
      body.classList.remove("g-sidenav-pinned");
      body.classList.add("g-sidenav-hidden");
      sidenavToggler.classList.remove("active");
      misc.sidebar_mini_active = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe(); // Unsubscribe from all subscriptions
  }
}
