import { Component, OnInit } from '@angular/core';
import { FeedbackDataService } from '../../services/feedback-data.service';
import { Route, Router } from '@angular/router';
import { IFeedback } from '../../interfaces';
import { FEEBACK_SETTING_RANGE_VALUES } from '../../enums';
import { FeedbackBase } from '../../feedback-base';

@Component({
  selector: 'app-feedback-details',
  templateUrl: './feedback-details.component.html',
  styleUrls: ['./feedback-details.component.scss']
})
export class FeedbackDetailsComponent extends FeedbackBase implements OnInit {

  feedbackData: IFeedback;
  name: string;
  mobileNo: string;

  constructor(private feedbackDataService: FeedbackDataService,
    private router: Router) {

    super();

    console.log(this.feedbackDataService.feedbackData)
    if (!this.feedbackDataService.feedbackData) {
      this.router.navigateByUrl("/apps/feedback-dashboard")
    } else {
      this.feedbackData = this.feedbackDataService.feedbackData;
    }

  }

  ngOnInit(): void {
  }

}
