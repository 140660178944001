import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { GenericService } from '../services/generic.service';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { DataService } from '../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  urlAPI = environment.urlAPI;
  tokenPayload: any;
  currentUserSubject: BehaviorSubject<any>;
  currentUser: Observable<any>;
  loggedUser: string;
  accessToken$: any = null;
  accountId$: any = null;
  accountData$: any = null;
  assignedBranches$: any = null;
  authPromise: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    // private modalService: ModalModule,
    private genericService: GenericService,
    private dataService: DataService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(this.tokenPayload);
  }

  get currentUserValue(): any {
    if (this.tokenPayload) {
      return this.currentUserSubject.value.user;
    }
    return null;
  }

  login(user: {email: string, password: string}, returnUrl?: string) {
    return this.http.post<any>(`${this.urlAPI}/authenticate/login`, user)
      .pipe(
        tap(tokens => this.doLoginUser(user.email, tokens)),
        map(user2 => {
          if (user2) {
            this.storeTokens(user2);
            if (user2.data.assignedBranches && user2.data.assignedBranches.length > 0) {
              this.router.navigateByUrl(returnUrl || "/dashboard");
            } else {
              this.router.navigate(["/business-setup"]);
            }
          }
          return user2;
        })
      );
  }

  

  register(data) {
    
    
    return this.http.post<any>(`${this.urlAPI}/register`, data);
  }

  forgotPassword(data) {
    return this.http.post<any>(`${this.urlAPI}/authenticate/forgot-password/`, data);
  }

  logout() {
    localStorage.clear();
    const branchId = this.dataService.currentBranch$?.branchId;
    return this.http.patch<any>(`${this.urlAPI}/authenticate/logout/${this.accountId$}`, {branchId: branchId}).pipe(
        tap(() => this.doLogoutUser()),
        map(user => {
          return user;
        }));
  }

  branchLogout(loginBranchId: string){
    const branchId = this.dataService.currentBranch$?.branchId;
    return this.http.patch<any>(`${this.urlAPI}/accounts/${branchId}/logout/${this.accountId$}`, {loginBranchId});
  }

  doLoginUser(username: string, tokens) {
    this.loggedUser = username;
    this.storeTokens(tokens);
  }

  doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
  }

  storeAccessToken(accessToken) {
    this.accessToken$ = accessToken;
  }

  storeAccountId(accountId) {
    this.accountId$ = accountId;
  }
  storeAccountData(account) {
    this.accountData$ = account;
  }
  storeBranches(branches) {
    this.assignedBranches$ = branches;
    this.dataService.currentBranch$ = branches[0];
  }

  storeRefreshToken(refreshToken) {
    this.genericService.setCookie('REFRESH_TOKEN', refreshToken , 365);
  }

  storeTokens(tokens) {
    this.storeRefreshToken(tokens.refreshToken);
    this.storeAccessToken(tokens.accessToken);
    this.storeAccountId(tokens.data.account._id);
    this.storeAccountData(tokens.data.account);

    if(tokens.data.assignedBranches && tokens.data.assignedBranches.length > 0){
      this.storeBranches(tokens.data.assignedBranches);
    }
    
  }

  removeTokens() {
    this.genericService.deleteAllCookies();
    localStorage.clear();
    this.accessToken$ = null;
  }

  getAccessToken() {
    return this.accessToken$;
  }

  getRefreshToken() {
    return this.genericService.getCookie('REFRESH_TOKEN');
  }

  isLoggedIn() {
    if (this.accessToken$) {
      return true;
    } else {
      return false;
    }
  }

  refreshToken() {
    return this.http.post<any>(`${this.urlAPI}/authenticate/refresh-token`, {
      refreshToken: this.getRefreshToken()
    }).pipe(
      retry(1),
      tap((tokens) => {
        if (tokens.data.accessToken) {
          this.storeAccessToken(tokens.data.accessToken);
        }
      }),
      catchError(
          (error: HttpErrorResponse): Observable<any> => {
            console.log('error in auth service', error);
            // Logout if refreshtoken has error
            this.logout();
            // other errors we don't know how to handle and throw them further.
            return throwError(error);
          }
      )
    )    
  }

  businessSetup(data) {
    return this.http.post<any>(`${this.urlAPI}/business`, data);
  }

  getIndustries() {
    return this.http.get<any>(`${this.urlAPI}/industries`);
  }
  
  public updateIntroLevel(data): Observable<any> {
    const url = `${this.urlAPI}/accounts/${this.accountId$}/introduction`;
    return this.http.patch(url, data).pipe(map((response) => response));
  }

}