import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnDestroy, ViewChild, ElementRef,  ChangeDetectorRef} from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/core/http/http.service';
import { DatePipe } from '@angular/common';
import { DataService } from 'src/app/core/services/data.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppointmentService } from 'src/app/modules/appointment/services/appointment.service';
import { debounceTime, distinctUntilChanged, finalize, map, takeUntil, tap, catchError} from 'rxjs/operators';
import { AppointmentDetailsModalComponent } from 'src/app/modules/appointment/component/appointment-details-modal/appointment-details-modal.component';
import { EditAppointmentModalComponent } from 'src/app/modules/appointment/component/edit-appointment-modal/edit-appointment-modal.component';
import { RemoveAppointmentModalComponent } from 'src/app/modules/appointment/component/remove-appointment-modal/remove-appointment-modal.component';
import { AppointmentSettingsService } from 'src/app/modules/appointment/services/appointment-settings.service';
import { Subject, Subscription, throwError } from 'rxjs';
import { SMART_FORM_NAMES } from 'src/app/modules/appointment/enum/smart_form';
import { IAppointmentPage, IFilterData, ICheckboxList, ICheckboxData, IFilterDataTimeslot } from 'src/app/modules/appointment/interface/appointment.interface';
@Component({
  selector: 'app-upcomming-appointments',
  templateUrl: './upcomming-appointments.component.html',
  styleUrls: ['./upcomming-appointments.component.scss']
})
export class UpcommingAppointmentsComponent implements OnInit, OnDestroy {
  @Input() isViewingFromEmail: boolean;
  @Input() emailAppointmentData: any;
  @Input() appointmentTab: {value: string; label: string};
  @Output() isViewed: EventEmitter<boolean> = new EventEmitter();
  dropdownsVisible: { [key: string]: boolean } = {};
  @ViewChild("serviceDropdownRef", { static: false })
  serviceDropdown: ElementRef<HTMLDivElement>;
  @ViewChild("timeslotDropdownRef", { static: false })
  timeslotDropdown: ElementRef<HTMLDivElement>;
  @ViewChild("dateDropdownRef", { static: false })
  dateDropdown: ElementRef<HTMLDivElement>;
  dateOfTommorow: string;
  dateOfMonday: string;
  dateOfSunday: string;
  dateOfMonth: string;
  startOfWeekMilliseconds: number;
  endOfWeekMilliseconds: number;
  appointmentWeekCount: number;
  firstDayOfMonth : number;
  lastDayOfMonth : number;
  millisecondsStartOfTomorrow: number;
  millisecondsEndOfTomorrow: number;
  appointmentMonthCount:number
  appointmentTomorrowCount: number
  appointmentDate: string;
  upcomingAppoitnment: IAppointmentPage;
  selectedTimeslotValue: number;
  settingsLateTime: number;
  selectedDateValue: number;
  isFocused: boolean = false;
  isFocusedTimeslot: boolean = false;
  isFocusedDate: boolean = false;
  isSelectClicked: boolean = false;
  isSelectClickedDate : boolean = false;
  isSelectClickedTimeslot: boolean = false;
  isButtonClicked: boolean = false;
  isDisableCompleteBtn: boolean = true;
  selectedTimeslotLabel: string = 'Show all timeslot';
  selectedDateLabel: string = 'Show all dates';
  itemsPerPage = 10;
  currentPage = 1;
  selectedServices: number = 0;
  selectedService: Array<any> = [];
  noOfPages: number[] = [];
  noOfEntries: number[] = [10, 20, 30];
  appointmentLists: any = [] ;
  servicesList: Array<any> = [];
  serviceCheckboxLists: Array<ICheckboxList> = [];
  filterUpcomingAppointmentSubject: Subject<any> = new Subject();
  isLoading: boolean = false;
  subscriptions = new Subscription();
  viewAppointmentModal: boolean = true;
  appointmentListsForm: FormGroup = this.formBuild.group({
    dateFilter: null,
    startDate: null,
    endDate: null,
    search: null,
    status: null,
    timeslot: null,
    selectedTimeslot: null,
    formattedDate: null
  });
  appointmentListData = {
    pages: 1,
    success: true,
    total: 1,
    data: [],
  };
  searchString: string | null;
  branchId: string = this.dataService.currentBranch$.branchId;
  searchSubject: Subject<string> = new Subject();
  dateFilterList: Array<IFilterData> = [];
  timeslotFilter: Array<IFilterData> = [];

  private appointmentListSubjectAPI$ = new Subject<void>();
  constructor(
    private httpService: HttpService,
    private dataService: DataService,
    private modalService: BsModalService,
    private modalRef: BsModalRef,
    private appointmentService: AppointmentService,
    private appointmentSettingService: AppointmentSettingsService,
    private datePipe: DatePipe,
    private formBuild: FormBuilder,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) { 
    this.searchSubject
    .pipe(debounceTime(300), distinctUntilChanged())
    .subscribe((searchValue) => {
        this.readAppointmentList();
      }
    )
  }

  ngOnInit() {
    this.dataService.branchChange$.subscribe((res) => {  
      if(res){
        this.branchId = res.branchId ?? this.branchId;
        this.getUpcomingCounts();
        this.getUpcomingDate()
        this.readSearchAppointment();
        this.readAddedAppointment();
        this.getServiceLists();
        this.getSettingsData();
      }
    })
  }

  ngAfterViewInit(): void {
    if (this.serviceDropdown) {
      this.serviceDropdown.nativeElement;
    }
  }

  readAddedAppointment() {
    this.subscriptions.add(
      this.appointmentService.modalEvent.subscribe(async (isSuccessful: boolean) => {
        if (isSuccessful) {
          // Perform logic in the child component when the modal event is successful
          this.getSettingsData(); 
          this.getUpcomingCounts();
          this.getServiceLists();
        }
      })
    );
  }

  readSearchAppointment() {
    this.filterUpcomingAppointmentSubject
    .pipe(debounceTime(300), distinctUntilChanged())
    .subscribe((filterValue) => {
      this.getSettingsData(); 
    });

    if(this.isViewingFromEmail && this.emailAppointmentData){
      this.isViewed.emit(true);
      this.viewAppointment(this.emailAppointmentData);
    }
  }

  getUpcomingCounts() {
    this.subscriptions.add(
      this.appointmentService.getCountAppointment(this.branchId).subscribe(
        res => {
          if(res) {
             const appointmentCounts = res.data[0];
             this.appointmentTomorrowCount = appointmentCounts.tomorrowCount;
             this.appointmentWeekCount = appointmentCounts.weekCount;
             this.appointmentMonthCount = appointmentCounts.monthCount;
          }
        },
        error => {
          console.log(error);
        }
      )
    );
  }

  getUpcomingDate() {
    const today = new Date();
    const currentDay = today.getDay();
    const tommorow = new Date();
    tommorow.setDate(today.getDate() + 1);

    const formattedTommorow = this.datePipe.transform(tommorow, 'EEE, MM/dd/yyyy');
    const diff = today.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // adjust when today is Sunday
    
    const monday = new Date(today.setDate(diff));
    const sunday = new Date(today.setDate(diff + 6));


    const formattedTommorowByMonth = this.datePipe.transform(tommorow, 'MMM y');
    const formattedMonday = this.datePipe.transform(monday, 'MMM d');
    const formattedSunday = this.datePipe.transform(sunday, 'MMM d');
    
    this.dateOfTommorow = formattedTommorow;
    this.dateOfMonday = formattedMonday;
    this.dateOfSunday = formattedSunday;
    this.dateOfMonth = formattedTommorowByMonth;
  }

  selectedTimeslotFilter() {
    this.isSelectClickedTimeslot = !this.isSelectClickedTimeslot;
    this.isFocusedTimeslot = !this.isFocusedTimeslot;
  }

  selectedDateFilter(){
    this.isSelectClickedDate = !this.isSelectClickedDate;
    this.isFocusedDate = !this.isFocusedDate;
  }

  private formatDateWithourHours(dateCreated: number): string {
    const date = new Date(dateCreated);
    const formattedDate = this.datePipe.transform(date, ' MM/dd/yyyy, EEE');
    return formattedDate;
  }

  timeMili(): number {
    const hours = 0;
    const millisecondsInHour = 60 * 60 * 1000;
    return hours * millisecondsInHour;
  }

  readAppointmentList() {
    this.isLoading = true;
    const httpQueryParams = this.httpService.convertFormGroupToParams(this.appointmentListsForm);
    const params = "dateFilter=upcoming"+"&"+this.appointmentUpcomingParams+'&'+httpQueryParams;
    this.subscriptions.add(
      this.appointmentService
        .getAppointmentList(this.branchId, params)
        .pipe(
          takeUntil(this.appointmentListSubjectAPI$),
          map((res) => res),
          tap((data) =>  {
            this.appointmentListLogic(data);
          }), 
          catchError((error) => {
            console.log("error on fetching queue history lists", error);
            return throwError(error);
         }),
         finalize(() => {
          //
        })
      ).subscribe()
    )
  }

  appointmentListLogic(appointmentList) {
    let dataAppointment = [];

    appointmentList.data.forEach( appointment => {
      const settingTimeLate = !this.settingsLateTime || isNaN(this.settingsLateTime) ? 0 : this.settingsLateTime; 
      const date = new Date((appointment.timeslot+this.timeMili()) - settingTimeLate);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;

      // Use Angular's date pipe to format minutes
      const formattedMinutes = ('0' + minutes).slice(-2);


      let formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`; 
      appointment.formattedDate =  this.formatDateWithourHours(appointment.timeslot) 
      appointment.formattedTimeslot = formattedTime; 

      for (const smartForm of appointment.visitorDetails) {
        switch (smartForm.fieldName) {
          case SMART_FORM_NAMES.NAME:
            appointment.firstname = smartForm.value;
            
            break;
          case SMART_FORM_NAMES.MOBILENO:
            appointment.mobileNumber = smartForm.value;
            break;
          case SMART_FORM_NAMES.PRIORITY:
          appointment.priority = smartForm.value;
            break;
          case SMART_FORM_NAMES.VISITORS:
            appointment.visitors = smartForm.value;
            break;
  
          default:
            break;
        }
      }     

      dataAppointment.push(appointment);
    });

    this.appointmentLists = dataAppointment;
    
    this.upcomingAppoitnment = appointmentList;

    const pages = appointmentList.pages;
    
    if (pages == 0) {
      this.noOfPages.push(1);
    } else {
      this.noOfPages = Array.from({ length: pages }, (_, i) => i + 1);
    }

    this.isLoading = false;
  }

  readUpcomingTimeslotList() {
    const params = `dateFilter=upcoming`;
    this.subscriptions.add(
      this.appointmentService
        .getAppointmentListData(this.branchId, params)
        .pipe(
          takeUntil(this.appointmentListSubjectAPI$),
          map((res) => res),
          tap((data) =>  {
            this.upcomingTimeslotListLogic(data);
          }), 
          catchError((error) => {
            console.log("error on fetching queue history lists", error);
            return throwError(error);
         }),
         finalize(() => {
          //
        })
      ).subscribe()
    )
  }

  upcomingTimeslotListLogic(upcomingTimeslotList) {
    this.isLoading = false;
    let dataAppointment = [];

    upcomingTimeslotList.data.forEach( appointment => {
      const settingTimeLate = !this.settingsLateTime || isNaN(this.settingsLateTime) ? 0 : this.settingsLateTime; 
      const date = new Date((appointment.timeslot+this.timeMili()) - settingTimeLate);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;

      // Use Angular's date pipe to format minutes
      const formattedMinutes = ('0' + minutes).slice(-2);

      let formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`; 

      appointment.formattedTimeslot = formattedTime; 
      dataAppointment.push(appointment);
    });

    this.getTimeslotFilter(dataAppointment);
    this.getDateFilter(dataAppointment)
  }

  getSettingsData() {
     this.subscriptions.add(
      this.appointmentSettingService.getSettings(this.branchId).subscribe(
        (res) => {
          if(res) {
             res.data;
            this.settingsLateTime = res.data.lateAppointmentTimeRange.milliseconds ? res.data.lateAppointmentTimeRange.milliseconds : 0 ;
            this.readAppointmentList();
            this.readUpcomingTimeslotList()
          }
        },
        (error) => {
          console.log(error)
        }
      )
    );
  }

  getDateFilter(appointmentList) {
    const appointmentListFilter = appointmentList.map(appointment => {
       return {
           label: appointment.formattedDate,
           value: appointment.formattedDate  
       }
     })
     this.removeDuplicatesDate(appointmentListFilter)
   }

   removeDuplicatesDate(appointmentListFilter): void {
    const uniqueTimeslotList = appointmentListFilter.filter(
      (value, index, self) =>
        self.findIndex((item) => item.value === value.value && item.label === value.label) === index
    );
      
    this.dateFilterList = this.sortArrayByTime(uniqueTimeslotList);
    this.dateFilterList.push({ value: null, label: "Show all dates"});
  }
  
  getTimeslotFilter(appointmentList) {
   const appointmentListFilter = appointmentList.map(appointment => {
      return {
          label: appointment.formattedTimeslot,
          value: appointment.selectedTimeslot  
      }
    })
   this.removeDuplicatesTimeslot(appointmentListFilter)
  }

  removeDuplicatesTimeslot(appointmentListFilter): void {
    const uniqueTimeslotList = appointmentListFilter.filter(
      (value, index, self) =>
        self.findIndex((item) => item.value === value.value && item.label === value.label) === index
    );
      
    this.timeslotFilter = this.sortArrayByTime(uniqueTimeslotList);
    this.timeslotFilter.push({ value: null, label: "Show all timeslot"});
  }

  sortArrayByTime(array: any[]): any[] {
    return array.sort((a, b) => {
      return a.value - b.value;
    });
  }


  selectTimeslotDropdown(appointmentData) {
    this.selectedTimeslotValue = appointmentData.value;
    this.selectedTimeslotLabel = appointmentData.label
    this.appointmentListsForm.patchValue({
      selectedTimeslot: this.selectedTimeslotValue,
    }) 
    this.isFocusedTimeslot = false;
    this.getSettingsData(); 
  }

  selectDateDropdown(appointmentData) {
    this.selectedDateValue = appointmentData.value;
    this.selectedDateLabel = appointmentData.label;
    this.appointmentListsForm.patchValue({
      formattedDate : this.selectedDateValue
    }) 
    this.isFocusedDate = false;
    this.getSettingsData(); 
  }

  get appointmentUpcomingParams() {
    let params = new HttpParams();
    params = params.append("limit", this.itemsPerPage.toString());

    const offset = this.currentPage * this.itemsPerPage - this.itemsPerPage;
    params = params.append("offset", offset.toString());
    
    if (this.searchString) {
      params = params.append("search", this.searchString);
    }
    
    if (this.selectedService.length > 0) {
      params = params.append("serviceId", this.selectedService.join(","));
    }

    return params;
  }

  async getServiceLists() {
    this.subscriptions.add(
      this.httpService
        .get$(`services/${this.branchId}?limit=999`)
        .pipe(
          takeUntil(this.appointmentListSubjectAPI$),
          map((res) => res.data), // for extraction of data
          tap((data) => {
            this.servicesList = data.map((element) => ({
              _id: element._id,
              name: element.displayName,
            }));
            this.setServiceData(this.servicesList);
          }),
          catchError((error) => {
            console.log("Error on fetching branch details", error);
            return throwError(error);
          }),
          finalize(() => {
          })
        )
        .subscribe()
    );
  }

  setServiceData(serviceData: any) {
    const serviceLists = serviceData.map((element) => {
      return {
        value: element._id,
        label: element.name,
      };
    });
    this.serviceCheckboxLists = this.addCheckedProperty(serviceLists);
  }

  addCheckedProperty(contents: Array<ICheckboxList>) {
    if (contents) {
      const newContents = contents.map((content) => ({
        ...content,
        checked: false, // for checkbox
      }));
      return newContents;
    }
  }

  public changeCheckboxList($event: ICheckboxData) {
    if ($event) {
      const key = $event?.key;
      const selected = $event.checkedArray;
      switch (key) {
        case "service":
          this.selectedServices = selected.length;
          this.selectedService = [];
          if (this.selectedServices > 0) {
            selected.forEach((element) => {
              this.selectedService.push(this.servicesList[element]._id);
            });
          }
          this.filterUpcomingAppointmentSubject.next(this.selectedServices);
          break;
        default:
          break;
      }
 /*      this.listOfAppointment(); */
    }
  }

  toggleDropdown(dropdownId: string): void {
    // Close all other dropdowns before opening the current dropdown
    Object.keys(this.dropdownsVisible).forEach((id) => {
      if (id !== dropdownId) {
        this.closeDropdown(id);
      }
    });

    if (this.isDropdownVisible(dropdownId)) {
      this.closeDropdown(dropdownId);
    } else {
      this.openDropdown(dropdownId);
    }
    this.isFocused = false;
    this.isButtonClicked = false;
    this.isSelectClicked = false;
    this.isFocusedTimeslot = false;
    this.isFocusedDate = false;
    this.isSelectClickedTimeslot = false;
    this.isSelectClickedDate = false;
  }

  isDropdownVisible(dropdownId: string): boolean {
    return this.dropdownsVisible[dropdownId] ?? false; // Return the visibility state for the given dropdown ID
  }

  openDropdown(dropdownId: string): void {
    // Close all other dropdowns before opening the current dropdown
    Object.keys(this.dropdownsVisible).forEach((id) => {
      if (id !== dropdownId) {
        this.closeDropdown(id);
      }
    });

    this.dropdownsVisible[dropdownId] = true;
  }

  closeDropdown(dropdownId: string): void {
    this.dropdownsVisible[dropdownId] = false; // Set the visibility state for the given dropdown ID to false
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent): void {
    const clickedElement = event.target as HTMLElement;
    const dropdownDiv =
      this.elementRef.nativeElement.querySelector(".date-dropdown");

      const timeslotDropDownDiv =
      this.elementRef.nativeElement.querySelector(".timeslot-dropdown");  
    if (!dropdownDiv?.contains(clickedElement)) {
      if (this.isFocusedDate === true) {
        this.isFocusedDate = false;
      }
    }

    if (!timeslotDropDownDiv?.contains(clickedElement)) {
      if (this.isFocusedTimeslot === true) {
        this.isFocusedTimeslot = false;
      }
    }
    for (const dropdownId in this.dropdownsVisible) {
      if (this.dropdownsVisible.hasOwnProperty(dropdownId)) {
        const dropdownRef = this.elementRef.nativeElement.querySelector(
          `#${dropdownId}`
        );
        const dropdownMenuRef = this.elementRef.nativeElement.querySelector(
          `#${dropdownId}-menu`
        );
        if (dropdownRef && dropdownRef.contains(clickedElement)) {
          return;
        }
        if (dropdownMenuRef && dropdownMenuRef.contains(clickedElement)) {
          return;
        }
      }
    }
    this.dropdownsVisible = {};
  }

  public pageChanged($event) {
    this.currentPage = $event.page;
    this.filterUpcomingAppointmentSubject.next(this.currentPage);
    this.readAppointmentList();
  }


  jumpToPage(page: string) {
    this.currentPage = parseInt(page);
    this.filterUpcomingAppointmentSubject.next(this.currentPage);
    this.readAppointmentList();
    this.cdr.detectChanges();
  }

  getNumberArray(num: number, offset: number = 0): number[] {
    if (num > 0) {
        return Array(num).fill(0).map((x, i) => i + offset);
    } else {
        return [1]
    }
}

public viewAppointment(appointmentData) {
  if(!this.viewAppointmentModal) {
    /* change view appointment modal to true */
    this.viewAppointmentModal = true;
  } else {
    const initialState = {
      title: "View Appointment Details",
      isDisableCompleteBtn: this.isDisableCompleteBtn,
      data: appointmentData
    }

    this.modalRef =  this.modalService.show(AppointmentDetailsModalComponent,{
        initialState: initialState,
        class: "modal-dialog-centered modal-md",
        ignoreBackdropClick: true,
        keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
        (data) => {
          
          if(data === 'delete-detail-view'){
            this.readAppointmentList();
            this.getUpcomingCounts();
          } 

          if(data === 'edit-detail-modal') {
            this.readAppointmentList();
            this.getUpcomingCounts();
          }

          if(data === 'move-history') {
            this.readAppointmentList();
            this.getUpcomingCounts();
          }
          
        },
        (err) => {
          console.log("edit account", err);
          return false;
        }
    );
  }
}


edit(appointmentData) {
  /* change view appointment modal to false */
  this.viewAppointmentModal = false;

  const initialState = {
    title: "Edit Appointment",
    data: appointmentData
  }

  this.modalRef = this.modalService.show(EditAppointmentModalComponent,{
    initialState: initialState,
    class: "modal-dialog-centered modal-sm",
    ignoreBackdropClick: true,
    keyboard: false,
  });

   this.modalRef.content.successEvent.subscribe(
    (data) => {
      if(data === 'edit'){
        this.readAppointmentList();
      }else {
        this.readAppointmentList();
      }
    },(err) => {
      return false;
    }
  ); 
}

delete(appointmentData) {
  /* change view appointment modal to false */
  this.viewAppointmentModal = false;
  
  const initialState = {
    title: "Cancel appointment?",
    data: appointmentData
  }

  this.modalRef = this.modalService.show(RemoveAppointmentModalComponent,{
    initialState: initialState,
    class: "modal-dialog-centered modal-md",
    ignoreBackdropClick: true,
    keyboard: false,
  });

  this.modalRef.content.successEvent.subscribe(
    (data) => {
      if(data === 'deleted-move-history'){
         this.getSettingsData();
         this.getUpcomingCounts();
      }
    },
    (err) => {
      return false;
    }
  );
}

  public entriesChanged(page) {
    this.itemsPerPage = page;
    this.filterUpcomingAppointmentSubject.next(this.itemsPerPage);
    this.getSettingsData();
  }

  public searchAppointment() {
    this.searchSubject.next(this.searchString);
  }

  ngOnDestroy(): void {
    this.appointmentListSubjectAPI$.next();
    this.appointmentListSubjectAPI$.complete();
    this.subscriptions.unsubscribe();
  }
}


