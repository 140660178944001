export enum SMART_FORM_NAMES {
    NAME = "name",
    MOBILENO = "mobileNo",
    VISITORS = "visitors",
    PRIORITY = "priority"
}

export enum SETTING_LEAD_TIME {
    MINUTES = 'minutes',
    HOURS = 'hours'
}

export enum APPOINTMENT_SETTINGS {
    MOVE_APPOINTMENT_TO_QUEUE_LIST = 'move-appointment-to-queue-list',
    ENABLE_CUSTOMER_REMARKS = 'enable-customer-remarks',
    SYNC_TO_QUEUE_LIST = 'sync-to-queue',
    MOVE_APPOINTMEN = 'move-appointment',
    SUPRESS_NOTIFICATION = 'supress-notification',
    CANCEL_APPOINTMENT = 'cancel-appointment',
    CANCEL_REMARKS = 'cancel-remarks',
    REQUIRE_REMARKS = 'require-remarks',
    EXPIRED_APPOINTMENT = 'expired-appointment',
    ENABLE_ONLINE_BOOKING = 'enable-online-booking',
    APPOINTMENT_APPROVAL = 'appointment-approval',
    HOLD_SPOT = 'hold-spot',
    ENABLE_APPOINTMENT = 'enable-appointment'
}