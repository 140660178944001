import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/core/auth/auth.service";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { AssignProcedureModalComponent } from "src/app/modules/service-procedure/component/assign-procedure-modal/assign-procedure-modal.component";
import { CompleteQueueModalComponent } from "src/app/modules/service-procedure/component/complete-queue-modal/complete-queue-modal.component";
import { SelectNextProcedureModalComponent } from "src/app/modules/service-procedure/component/select-next-procedure-modal/select-next-procedure-modal.component";
import { ProcedureService } from "src/app/modules/service-procedure/services/procedure.service";
import { ServiceWindowService } from "src/app/modules/service-window/services/service-window.service";
import { SettingsService } from "src/app/modules/settings/services/settings.service";
import { TeamManagementService } from "src/app/modules/team-management/services/team-management.service";
import { ModalConfirmComponent } from "src/app/shared/components/modal-confirm/modal-confirm.component";
import { ModalWarningImageComponent } from "src/app/shared/components/modal-warning-image/modal-warning-image.component";
import { QueueAddFormComponent } from "../../components/queue-add-form/queue-add-form.component";
import { QueueWindowLeaveModalComponent } from "../../components/queue-window-leave-modal/queue-window-leave-modal.component";
import { QueueWorkspaceService } from "../../services/queue-workspace.service";
import { TitleCasePipe } from "@angular/common";
import { ServiceCategoryServiceV2 } from "src/app/modules/service-category-v2/services/service-category.service";

@Component({
  selector: "app-queue-workspace-page",
  templateUrl: "./queue-workspace-page.component.html",
  styleUrls: ["./queue-workspace-page.component.scss"],
  providers: [TitleCasePipe]
})
export class QueueWorkspacePageComponent
  extends QueueWorkspaceService
  implements OnInit, OnDestroy {
  activeTab: number;
  tabLists = [
    {
      label: "Current Serving",
      value: "served",
    },
    {
      label: "On-going Queue",
      value: "pending",
    },
  ];
  serviceWithCountLists: Array<any>;
  filterParam: any;
  filterParamArray: Array<any>;
  filterTabs: Array<any>;
  assignedCurrentQueueLists: Array<any>;
  sortFilterArray = [
    {
      pending: [
        {
          sort: [
            {
              label: "Sort By Waiting Time",
              value: [
                {
                  label: "Shortest (New)",
                  value: "shortest",
                },
                {
                  label: "Longest (Old)",
                  value: "longest",
                },
              ],
            },
          ],
        },
        {
          filter: [
            {
              label: "Tags",
              value: "tags",
            },
          ],
        },
      ],
    },
    {
      served: [
        {
          sort: [
            {
              label: "Sort By Serving Time",
              value: [
                {
                  label: "Shortest (New)",
                  value: "shortest",
                },
                {
                  label: "Longest (Old)",
                  value: "longest",
                },
              ],
            },
          ],
        },
        {
          filter: [
            {
              label: "Served On Window",
              value: "windowId",
            },
            {
              label: "Tags",
              value: "tags",
            },
          ],
        },
      ],
    },
  ];
  isQueueListsInit: boolean;
  selectedStaffFilter: any;
  assignedBranches = this.authService.assignedBranches$;
  branchId: string;
  allQueues: any;
  selectedAllServices: string;
  queueList: any;
  isServingDisabled: boolean;
  accountsList: any;
  showProcedureList = true;
  categoryLists: any;
  isServiceEmpty: boolean = false;
  showQueues: boolean = false;

  constructor(
    public httpService: HttpService,
    public dataService: DataService,
    public authService: AuthService,
    public toastr: ToastrService,
    public router: Router,
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    private serviceWindowService: ServiceWindowService,
    private settingsService: SettingsService,
    private teamService: TeamManagementService,
    private procedureService: ProcedureService,
    public titleCasePipe: TitleCasePipe,
    private categoryService: ServiceCategoryServiceV2,
    private cdr: ChangeDetectorRef
  ) {
    super(
      authService,
      dataService,
      httpService,
      toastr,
      router,
      modalService,
      modalRef
    );
  }

  ngOnInit() {
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe((res) => {
        if (res && res._id !== this.branchId) {
          this.confirmLeaveWindow();
        }
        this.branchId = res
          ? res._id
          : this.dataService.currentBranch$.branchId;
      })
    );
    this.getCurrentWindow();
    this.initFilter();
    this.initFilterTabs();
    this.getAccounts();
    this.activeTab = 1;
    this.chooseTab(this.activeTab);
    this.checkSortFilter();
    this.checkModalEvents();
    this.getQueueSettings();
    if (!this.currentWindow) {
      this.router.navigate([`/queue-workspace`]);
    }
    this.checkDataChange();
  }

  checkDataChange() {
    this.subscriptions.add(
      this.dataService.dataChange$.subscribe((res) => {
        if (res) {
          if (res?.action === "tagUpdate") {
            if (this.activeQueue) {
              this.activeQueue.timeline = res.queue?.timeline; // for re-fetching of updated tags data
            }
          }
        }
      })
    );
  }

  getServiceName(id) {
    let name = "";
    if (id && this.AllServiceLists) {
      const s = this.AllServiceLists.find((x) => x._id === id);
      if (s) {
        name = s.displayName;
      }
    }
    return name;
  }

  getAccounts() {
    this.subscriptions.add(
      this.teamService.getTeamList("", this.branchId).subscribe((res) => {
        this.accountsList = res.data;
      })
    )
  }

  checkServingTime(date) {
    const now = moment();
    const servedAt = moment(new Date(date));

    const duration = moment.duration(now.diff(servedAt));
    const minutes = duration.asMinutes();

    return minutes > 30;
  }

  getQueuelist(ev) {
    this.queueList = ev;
  }

  getQueueSettings() {
    this.settingsService
      .getBranchQueueSettings(this.branchId)
      .subscribe((settings) => {
        this.queueSettings = settings.data;
      });
  }

  async getCurrentWindow() {
    this.httpService
      .get$(`services/windows/${this.branchId}?limit=999`)
      .subscribe(async (data) => {
        if (data.data.length > 0) {
          this.windowList = data.data;
          if (!this.currentWindow) {
            await this.getAccountDetails();
          } else {
            await this.getWindowData();
          }
        } else {
          this.goBackToWindowSelect();
        }
        // this.showQueues = true;
      });
  }

  async getWindowData() {
    if (this.currentWindow) {
      const window = this.windowList.find((x) => x._id === this.currentWindow);
      if (window) {
        this.windowData = window;
        if (this.windowData?.assignedServices) {
          await this.getServiceLists();
        }
        if (this.windowData && this.windowData.suspended) {
          this.confirmLeaveWindow();
        }
      } else {
        this.goBackToWindowSelect();
      }
    }
  }

  async getServiceLists() {
    this.httpService
      .get$(
        `services/${this.branchId}?include_current_serving=1&include_current_pending=1&include_avg_waiting_time=1&limit=999`
      )
      .subscribe(
        (data) => {
          this.AllServiceLists = data.data.filter(
            (service) => !service.suspended
          );
          for (const service of this.AllServiceLists) {
            for (const window of service.assignedWindows) {
              if (window._id === this.windowData._id) {
                this.serviceLists.push(service)
              }
            }
          }
          this.serviceLists.sort((a, b) => a.displayName.localeCompare(b.displayName));
          this.serviceWithCountLists = this.serviceLists.map((element) => {
            return {
              label: element.displayName,
              value: element._id,
            };
          });
          this.getProcedureTemplateList();
          this.getServiceCategoryList();
          if (this.serviceLists?.length <= 0) {
            this.openModalServicesDisabled();
            this.allButtonsDisabled = true;
          }
          
          this.showQueues = true;
        },
        (error) => {
          console.log("error", error);
        }
      );
  }

  getServiceCategoryList() {
    this.subscriptions.add(
      this.categoryService.getServiceCategories(this.branchId).subscribe(data => {
        this.categoryLists = data.data;
      })
    )
  }

  openModalServicesDisabled() {
    const initialState = {
      title: "All services are currently disabled",
      subTitle: `There are no services available for this window at the moment. <br>You can wait for your manager to enable the services.`,
      confirmClass: "btn-danger",
      caption: "Please contact your manager for more information.",
      mainButtonText: "Okay, got it!",
      secondaryButtonText: "Leave this Window",
      secondaryAction: "confirmLeaveWindow",
      windowName: this.windowData.displayName
    };
    this.modalRef = this.modalService.show(ModalWarningImageComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.isServiceEmpty = true;
  }

  chooseTab(i) {
    this.activeTab = i;
    const selectedValue = this.tabLists[i].value; 
    this.selectedAllServices =
      "AllServices " + "(" + this.serviceLists?.length + ")";
    this.selectedSortFilter = Object.values(
      this.sortFilterArray.find((element) => element[selectedValue])
    )[0];
    if (i === 0) {
      this.filterParam["state"] = "served";
    } else {
      this.filterParam["state"] = "pending";
    }
  }

  selectFilterTab($event) {
    this.filterParam["servedBy"] = $event;
  }

  selectService($event) {
    this.selectedServiceId = $event;
    this.filterParam["serviceIds"] = $event;
    if ($event !== "all") {
      this.getServiceData();
    }
  }

  getServiceData() {
    this.httpService
      .get$(`services/${this.branchId}/${this.selectedServiceId}`)
      .subscribe((data) => {
        this.serviceData = data.data;
      });
  }

  getQueueData($event) {
    this.selectedQueueId = $event;
    this.activeQueue = null;

    if (this.selectedQueueId) {
      this.httpService
        .get$(`queues/${this.branchId}/${this.selectedQueueId}`)
        .subscribe(
          (data) => {
            this.activeQueue = data.data;

            const queueService = this.serviceLists.find(
              (x) => x._id === this.activeQueue.serviceId
            );
            const userCurrentQueue = this.queueList?.currentLists.filter(
              (x) => x.servedBy === this.accountId && !x.skipped
            );

            if (
              queueService?.queueType === "single-serving" &&
              userCurrentQueue?.length > 0
            ) {
              this.isServingDisabled = true;
            } else {
              this.isServingDisabled = false;
            }
          },
          (error) => {
            this.activeQueue = null;
            // Show blank state
          }
        );
    }
  }

  getQueues() {
    this.subscriptions.add(
      this.httpService.get$(`queues/${this.branchId}?limit=200`).subscribe(
        (data) => {
          this.allQueues = data;
        },
        (error) => console.log(error)
      )
    )
  }

  addQueue() {
    const initialState = {
      title: "Add Queue",
      subTitle:
        "Enter the visitor’s info. All fields are required unless stated as optional.",
      selectedServiceId: this.selectedServiceId,
      serviceLists: this.serviceLists,
      categoryLists: this.categoryLists,
      branchId: this.branchId,
      type: "Add",
    };
    this.modalRef = this.modalService.show(QueueAddFormComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  autoServe($event) {
    const feature = "auto-serve";
    const dataArray = this.windowData.enabledFeatures;
    const value = $event;
    const featuresArray = this.serviceWindowService.addDeleteFeature(
      dataArray,
      feature,
      value
    );
    const featureBodyForm = { enabledFeatures: featuresArray };
    // Post
    const param = `services/windows/${this.branchId}/${this.windowData?._id}`;
    const bodyForm = {
      ...featureBodyForm,
    };
    this.serviceWindowService
      .submitPatchForm(param, bodyForm)
      .then((data) => {
        this.dataService.publishDataChange$("auto serve");
        this.toastr.success(
          `Auto-serve has been ${$event ? "enabled" : "disabled"}`,
          "Success!", {
            positionClass: 'toast-bottom-right-custom'
          }
        );
      })
      .catch((error) => {
        this.toastr.error(`Something went wrong`, "Failed!", {
          positionClass: 'toast-bottom-right-custom'
        });
      });
  }

  // Actions
  doQueueAction($event) {
    const fn = "this." + $event + "Queue()";
    eval(fn);
  }

  public getProcedureTemplateList() {
    // const serviceIds = this.serviceLists.map((s) => s._id);
    let params = new HttpParams();
    // params = params.append("origin_services", serviceIds);
    params = params.append("activated", "1");
    this.procedureService
      .getProcedureTemplates(this.branchId, params)
      .subscribe(
        (res) => {
          this.procedureTemplates = res.result.data;
        },
        (error) => {
          console.log("error", error);
        }
      );
  }

  completeQueue() {
    if (this.activeTab === 0) {
      // Current Serving
      const procedureTemplate = this.procedureTemplates.filter((s) =>
        s.originServices.includes(this.activeQueue.serviceId)
      );
      if (this.activeQueue.procedureQueue) {
        const nextProcedure = this.activeQueue.procedureQueue.procedures.find(
          (p) =>
            p.serviceId !== this.activeQueue.serviceId &&
            p.state === "pending"
        );
        if (this.activeQueue.procedureQueue.procedureType === "flexible") {
          if (nextProcedure) {
            this.selectNextProcedure();
          } else {
            this.proceedToNextProcedure(this.activeQueue.procedureQueue, true);
          }
        } else {
          const procedures = [];
          if (nextProcedure && nextProcedure.optional) {
            const nextProcedures =
              this.activeQueue.procedureQueue.procedures.filter(
                (p) =>
                  p.serviceId !== this.activeQueue.serviceId &&
                  p.state === "pending"
              );

            for (let index = 0; index < nextProcedures.length; index++) {
              if (nextProcedures[index].optional) {
                procedures.push(nextProcedures[index]);
              } else {
                procedures.push(nextProcedures[index]);
                index = nextProcedures.length;
              }
            }
            this.selectNextProcedure(procedures);
          } else {
            this.proceedToNextProcedure(this.activeQueue.procedureQueue);
          }
        }
      } else if(procedureTemplate.length === 1 && procedureTemplate[0].procedures.length === 1){
        this.processProcedureWithOneTemplate(procedureTemplate[0]);
      } else if (procedureTemplate && procedureTemplate.length > 0) {
        this.assignProcedure(procedureTemplate);
      } else {
        this.doneQueue();
      }
    } else {
      // Ongoing
      this.serveQueue();
    }
  }

  editQueue() {
    const initialState = {
      title: "Edit Queue",
      subTitle:
        "Enter the visitor’s info. All fields are required unless stated as optional.",
      selectedServiceId: this.activeQueue.serviceId,
      serviceLists: this.serviceLists,
      categoryLists: this.categoryLists, //add category lists to edit component
      activeQueue: this.activeQueue,
      branchId: this.branchId,
      type: "Edit",
    };
    this.modalRef = this.modalService.show(QueueAddFormComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        this.getQueueData(data);
      },
      (err) => {
        console.log("edit account", err);
        return false;
      }
    );
  }

  async checkAssignedCurrentQueues() {
    await this.httpService
      .get$(
        `queues/${this.branchId}/list?state=served&windowIds=${this.currentWindow}&servedBy=${this.accountId}`
      )
      .toPromise()
      .then((data) => {
        this.assignedCurrentQueueLists = data.data;
      });
  }

  async leaveWindow() {
    await this.checkAssignedCurrentQueues();
    // If Has Current Queue Assigned to User
    if (this.assignedCurrentQueueLists.length > 0) {
      await this.openModalLeaveHasCurrentServing();
    } else {
      this.openModalLeave();
    }
  }

  getNumberOfAssignedWindows() {
    // const a = this.assignedWindowLists.filter((x) => !x.suspended);
    // if (a && a?.length > 1) {
    //   return false;
    // }
    // return true;

    return false;
  }

  openModalLeave() {
    const initialState = {
      title: "Leave Window",
      subTitle: `${this.windowData?.displayName} will be closed until the next staff enters this window.`,
      confirmClass: "btn-danger",
      confirmAction: "confirmLeaveWindow",
    };
    this.modalRef = this.modalService.show(ModalConfirmComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  async openModalLeaveHasCurrentServing() {
    const initialState = {
      title: "Oh wait! Before you leave...",
      subTitle:
        "You still have current serving queues. You need to complete the queues or transfer them to another window before you leave.",
      windowId: this.currentWindow,
      windowData: this.windowData,
      branchId: this.branchId,
    };
    this.modalRef = this.modalService.show(QueueWindowLeaveModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  searchQueue($event) {
    this.filterParam["search"] = $event;
  }

  checkSortFilter() {
    this.subscriptions.add(
      this.dataService.sortFilters$.subscribe((res) => {
        this.filterParamArray = res?.filter((element) => element.value);
        res?.forEach((element) => {
          this.filterParam[element.param] = element.value;
        });
      })
    );
  }

  getTimeAgo(data) {
    if (data) {
      const y = new Date(data).getFullYear();
      const m = new Date(data).getMonth();
      const d = new Date(data).getDate();
      const h = new Date(data).getHours();
      const mm = new Date(data).getMinutes();
      const s = new Date(data).getSeconds();
      const time = moment([y, m, d, h, mm, s]).fromNow();

      return time === "a day" ? "1 day" : time;
    }
  }

  getDuration(end: number, start: number) {
    var timeDiff = moment(end).diff(moment(start), 'seconds');
    var secondsDiff = timeDiff / 60 - Math.floor(timeDiff / 60);
    var seconds = 60 * secondsDiff;
    var minutesDiff = (Math.floor(timeDiff / 60) / 60);
    var minutes = 60 * (minutesDiff - Math.floor(minutesDiff));
    var duration = Math.round(minutesDiff).toString().padStart(2, "0") + ':' + Math.round(minutes).toString().padStart(2, "0") + ':' + Math.round(seconds).toString().padStart(2, "0");
    return duration;
  }

  initFilter() {
    this.filterParam = {
      search: null,
      serviceIds: null,
      tags: null,
      sort: null,
      state: null,
      windowId: null,
      attendee: null,
    };
  }

  initFilterTabs() {
    this.filterTabs = [
      {
        label: "Me",
        value: this.accountId,
        icon: "ic_person",
      },
      {
        label: "All",
        value: "",
        icon: "ic_people",
      },
    ];
  }

  stringify(param) {
    return JSON.stringify(param);
  }

  confirmLeaveWindow() {
    const bodyForm = {
      accountId: this.accountId,
    };
    this.httpService
      .patch$(
        `services/windows/${this.branchId}/${this.currentWindow}/attending-staff/leave`,
        bodyForm
      )
      .subscribe(
        (data) => {
          this.modalRef.hide();
          this.goBackToWindowSelect();
        },
        (error) => { }
      );
  }

  async goBackToWindowSelect() {
    this.accountData.data.attendingWindow = null;
    this.currentWindow = null;
    this.dataService.currentWindow$ = null;
    this.dataService.assignedWindowLists$ = null;
    this.router.navigate([`/queue-workspace`]);
  }

  checkModalEvents() {
    this.subscriptions.add(
      this.dataService.closeModalReason$.subscribe((reason) => {
        if (reason) {
          const fn = this[reason.action ? reason.action : reason](reason.remarks);
          eval(fn);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.dataService.publishSortFilters$([]);
    this.subscriptions.unsubscribe();
    this.dataService.publishCloseModalReason$(null);
  }
}
