import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DataService } from 'src/app/core/services/data.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceCategoryService {
  apiUrl = environment.urlAPI;
  accountId = this.authService.accountId$;
  accountData = this.dataService.accountData$
  branchId = this.accountData.data.branchId;
  emptyData = {};
  categoryLists = [];

  constructor(private http: HttpClient, public authService: AuthService, private dataService: DataService) { }

  private get httpHeader(): any {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return {
      headers,
    };
  }

  public addServiceCategory(branchId, details): Observable<any> {
    const url = `${this.apiUrl}/service-category/${branchId}`;
    return this.http.post(url, details).pipe(map((response) => response));
  }

  public getServiceCategories(branchId): Observable<any> {
    const url = `${this.apiUrl}/service-category/${branchId}/list`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getServiceCategoryById(branchId, id): Observable<any> {
    const url = `${this.apiUrl}/service-category/${branchId}/${id}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public updateServiceCategory(branchId, id, details): Observable<any> {
    const url = `${this.apiUrl}/service-category/${branchId}/${id}`;
    return this.http.patch(url, details).pipe(map((response) => response));
  }

}
