import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";


@Component({
  selector: 'app-remove-special-shedule',
  templateUrl: './remove-special-shedule.component.html',
  styleUrls: ['./remove-special-shedule.component.scss']
})
export class RemoveSpecialSheduleComponent implements OnInit {
  displayName: any;
  @Output() successEvent = new EventEmitter;

  constructor(
    public modalRef: BsModalRef,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {

  }

  delete() {
    this.modalRef.hide();
    this.successEvent.emit(this.displayName);
  }

  cancel() {
    this.modalRef.hide();
    this.successEvent.emit('cancel');
  }
}
