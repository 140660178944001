import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/core/services/data.service';
import { AppointmentService } from '../../services/appointment.service';
import { HttpService } from "src/app/core/http/http.service";
import { GenericService } from "src/app/core/services/generic.service";
import { ToastrService } from "ngx-toastr";
import { SubscriptionLimitModalComponent } from 'src/app/modules/billing/components/subscription-limit-modal/subscription-limit-modal.component'; 
import { QueueLimitModalComponent } from 'src/app/modules/queue-workspace/components/queue-limit-modal/queue-limit-modal.component';
import { Subscription } from "rxjs";
import { IAccountServe } from '../../interface/appointment.interface';

@Component({
  selector: 'app-add-queue-modal',
  templateUrl: './add-queue-modal.component.html',
  styleUrls: ['./add-queue-modal.component.scss']
})
export class AddQueueModalComponent implements OnInit {
  @Input() title: any;
  @Input() subTitle: string;
  @Input() data: any;
  @Output() successEvent = new EventEmitter();
  subscriptions = new Subscription();
  serviceLists: Array<any> = [];
  validFields: Array<any> = [];
  tagLists: Array<any> = [];
  categoryLists: Array<any>;
  modifiedCategoryLists: Array<any>;
  modifiedServiceLists: Array<any>;
  enabledFieldLists: Array<any>;
  selectedBranchId: string;
  selectedServiceId: string;
  selectedServiceCategoryId: string;
  branchId: string;
  serviceData: any;
  requiredValidators: any;
  noNumberValidator: any;
  queue: any;
  mobileValidators: any;
  numberOfPerson: number = 1;
  isSelected: boolean = false;
  isSubmitting = false;
  isActiveService: boolean = false;
  isDirty = false;
  isConfirmDisabled: boolean;
  enableAppointmentService:boolean =  true;
  personnelAccountId: string = this.dataService.accountData$.data.accountId
  inputForm: FormGroup;
  selectedAccountData: IAccountServe;

  
  constructor(
    private modalRef: BsModalRef,
    private appointmentService: AppointmentService,
    private dataService: DataService,
    public httpService: HttpService,
    private genericService: GenericService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) { 
    this.inputForm = this.formBuilder.group({
      timeslot: ["", Validators.required]
  })
  }

  modifyServiceLists() {
    this.modifiedServiceLists = this.serviceLists.map((element) => {
      if(element.enableAppointment) {
          return {
                label: element.displayName,
                value: element._id,
              };
      }
    }).filter(element => element !== undefined);
  }
  
  async initServiceData() {
    if (this.selectedServiceId) {
      await this.selectService(this.selectedServiceId);
    }
  }
  
  serviceList() {
    const branchId = this.dataService.currentBranch$.branchId;
    this.selectedBranchId = branchId;
    this.selectedServiceId = this.data.serviceId;
    this.appointmentService.getServiceList(branchId).subscribe(
      (res) => {
        if(res) {
            this.serviceLists = res.data;
            if(this.serviceLists) {
              this.modifyServiceLists();   
            }        
            this.getServicecategoryList();    
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  async getServiceData() {
    this.branchId = this.dataService.currentBranch$.branchId;
    if (
      this.selectedServiceId &&
      this.selectedServiceId !== "Please Select Service"
    ) {
      this.httpService
        .get$(`services/${this.branchId}/${this.selectedServiceId}`)
        .toPromise()
        .then(
          (data) => {
            this.serviceData = data.data;
            this.enabledFieldLists = this.serviceData?.queueForm
       
              ? this.serviceData.queueForm.fields
                .filter((element) => element.enabled)
                .map((element, i) => {
                  const validatorArr = [];
                  element.required
                    ? validatorArr.push(this.requiredValidators)
                    : null;
                  element.fieldName == "name"
                    ? validatorArr.push(this.noNumberValidator)
                    : null; // added validator for name field 
                  // element.required && //remove for optional mobileNo
                  ["mobileNo", "mobile-number"].includes(element.fieldType)
                    ? validatorArr.push(this.mobileValidators)
                    : null;
                  return {
                    ...element,
                    label: element.label,
                    value: this.data.serviceId !== this.selectedServiceId ?  "" : this.data.visitorDetails[i]?.value ,
                    validators: validatorArr,
                  };
                })
              : null;
              this.validFields = this.enabledFieldLists
              ? this.enabledFieldLists.map((element, i) => {
                return {
                  ...element,
                  i: i,
                  valid: !element.required
                    ? true
                    : element.value
                      ? true
                      : false,
                  value: element?.value ? element?.value : "",
                  fieldType: element?.fieldType,
                };
              })
              : null;
            this.isConfirmDisabled = this.validFields
              ? this.validFields.filter((element) => !element.valid).length !==
              0
              : false;
            if (
              this.serviceData &&
              this.serviceData.enabledFeatures.includes("auto-tag")
            ) {
              this.serviceData.tags.forEach((t) => {
                if (!this.tagLists.includes(t)) {
                  this.tagLists.push(t);
                }
              });
            }
          },
          (error) => {
            console.log("error", error);
          }
        );
      this.isSelected = true;
    }
  }


  changeInput(index, $event) {
    const isToggleSwitch = typeof $event === 'boolean';

    if ($event.valid) {
      this.validFields = this.validFields.map((element, i) =>
        i === index
          ? {
            ...element,
            valid: true,
            value: $event.value,
          }
          : {
            ...element,
            valid: element.valid,
            value: element.value,
          }
      );
    } else {
      this.validFields = this.validFields.map((element, i) =>
        i === index
          ? {
            ...element,
            valid: false,
            value: $event.value,
          }
          : {
            ...element,
            valid: element.valid,
            value: element.value,
          }
      );
    }

    if (isToggleSwitch) {
      this.validFields = this.validFields.map((element, i) =>
        i === index
          ? {
            ...element,
            valid: true,
            value: $event,
          }
          : {
            ...element,
            valid: element.valid,
            value: element.value,
          }
      );
    }

    const valid = this.validFields.filter((element) => element.valid);
    this.isConfirmDisabled = valid.length !== this.enabledFieldLists.length;
  }

  initValidators() {
    this.requiredValidators = {
      type: "required",
      value: Validators.required,
      errormsg: "This field is required",
    };
    this.mobileValidators = {
      // type: "minlength",
      // value: Validators.minLength(12),
      // errormsg: "Minimum length is 12",
      type: "pattern",
      value: Validators.pattern(/^9[0-9]{2}[-]?[0-9]{3}[-]?[0-9]{4}$/),
      errormsg: "Invalid mobile number format",
      //enhance regex for pattern
    };
    this.noNumberValidator = {
      type: "pattern",
      value: Validators.pattern(/^[A-Za-z- ñÑ.]*$/),
      errormsg: "Invalid name format",
      //enhance regex for pattern (name)
    }
  }

  checkCategory() {
    this.categoryLists = this.categoryLists.filter(x => !x.subCategoryId);
    const serviceCategories = this.categoryLists.filter(
      (s) =>
        s.assignedServices.includes(this.selectedServiceId) &&
        (!s.parentCategoryId || s.parentCategoryId)
    );

    let categoryChoices = [];
    if (serviceCategories) {
      serviceCategories.forEach((c1) => {
        let categoryLabel: any;
        if (c1.parentCategoryId) {
          const categoryLevel1 = this.categoryLists.find(
            (c) => c._id === c1.parentCategoryId
          );
          if (!categoryLevel1) {
            return [];
          }
          if (categoryLevel1.parentCategoryId) {
            const categoryLevel2 = this.categoryLists.find(
              (c) => c._id === categoryLevel1.parentCategoryId
            );
            if (!categoryLevel2) {
              return [];
            }
            categoryLabel = {
              label:
                categoryLevel2.displayName +
                " > " +
                categoryLevel1.displayName +
                " > " +
                c1.displayName,
              value: c1._id,
            };
          } else {
            categoryLabel = {
              label: (categoryLabel =
                categoryLevel1.displayName + " > " + c1.displayName),
              value: c1._id,
            };
          }
        } else {
          categoryLabel = {
            label: (categoryLabel = c1.displayName),
            value: c1._id,
          };
        }

        categoryChoices.unshift(categoryLabel);
      });

      return categoryChoices;
    }
  }

  
getServicecategoryList() {
  const branchId = this.dataService.currentBranch$.branchId;
    this.subscriptions.add(
      this.appointmentService.getServiceCategories(branchId).subscribe(
        async (res) => {
          if(res) {
              this.categoryLists = res.data;
            }
          },
          (error) => {
           console.log(error)
        }
      )
  )
}    


  async selectService($event) {
    this.serviceData = null;
    this.selectedServiceId = $event;
    this.modifiedCategoryLists = [];
    this.modifiedCategoryLists = this.checkCategory(); 
    if (!this.modifiedCategoryLists || this.modifiedCategoryLists.length <= 0) {
      this.isActiveService = false;
    } else {
      this.isConfirmDisabled = true;
      this.isSelected = false;
    }
    this.isDirty = true; 
    this.isActiveService = true;
    this.getServiceData();

  }  

  accountList() {
    this.subscriptions.add(
      this.appointmentService
        .getTeamList("", this.data.branchId)
        .subscribe(
          res => {
            for(let account of res.data) {
              if(account?._id === this.personnelAccountId) {
                this.selectedAccountData = {
                  accountId: account._id,
                  email: account.email,
                  avatarUrl: account.avatarUrl,
                  roleLevel: account.roleLevel,
                  fullname: account.firstName + " " + account.lastName,
                } 
              }
            }
          },
          error => {
            console.log("error data");
            console.log(error);
          }
        )
    );
  }

  
  confirm($event): void {
    this.isSubmitting = true;
    let tagLists: Array<string> = [];
    const prio = this.validFields.find(field => field.fieldName === "priority")?.value;
    tagLists.push('APPOINTMENT');     
    this.tagLists.forEach((tag:any) => {
      tagLists.push(tag)
    })

    const bodyForm = {
      fields: this.validFields.map((element) => {
        return {
          ...element,
          value: ["mobileNo", "mobile-number"].includes(element?.fieldType)
            ? element?.value === null || element?.value?.length < 12
              ? null
              : element?.value.replaceAll("-", "")
            : element?.value,
        };
      }),
      priority: prio ? prio : false,
      tags: tagLists,
      serviceId: this.data.serviceId,
      serviceCategoryId: null,
    };

    const requestForm = {
      status: "completed",
      completedBy: this.selectedAccountData ?? {},
      remarks: "",
      queueGroupId: ""
    }
      this.editQueueAppointment(bodyForm, requestForm);
      this.isSubmitting = false;
  }

  addQueue(bodyForm) {
    this.isSubmitting = true;
    this.httpService
      .post$(`queues/${this.branchId}/${this.selectedServiceId}`, bodyForm)
      .subscribe(
        (data) => {
          this.modalRef.hide();
          this.successEvent.emit('add-queue-sucessfully');
          this.toastr.success("Queue has been added", "Success!", {
            positionClass: 'toast-bottom-right-custom'
          });
          this.dataService.publishDataChange$({
            action: "added",
            queue: data?.data?._id,
          });
          setTimeout(() => {
            this.isSubmitting = false;
          }, 500);
        },
        (error) => {
          console.log("error", error);
          this.modalRef.hide();
          const errorMsg = error.error.errors[0];
          if (errorMsg) {
            if (errorMsg.statusCode === 20101) {
              this.showQueueLimitModal(errorMsg);
            } else if (errorMsg.statusCode === 20102) {
              this.showQueueSubsLimitModal(errorMsg.limit);
            } else {
              this.toastr.error("Queue has not been added", "Failed!", {
                positionClass: 'toast-bottom-right-custom'
              });
            }
          } else {
            this.toastr.error("Queue has not been added", "Failed!", {
              positionClass: 'toast-bottom-right-custom'
            });
          }
          const x = {
            success: false,
            errors: [
              {
                source: "queue",
                statusCode: 20102,
                error: "CREATE_QUEUE_FAILED",
                msg: "Queue transaction limit reached.",
              },
            ],
          };
        }
      );
  }

  editQueueAppointment(bodyForm, requestForm) {
    this.isSubmitting = true;
    this.validFields.map(data => delete data._id);
    const body = {
      branchId: this.dataService.currentBranch$.branchId,
      serviceId: this.selectedServiceId,
      appointmentId: this.data._id,
      contactNo:  this.data.contactNo,
      customerRemarks: '',
      tags: this.data.tagList,
      selectedTimeslot: this.data.selectedTimeSlotValue,
      timeslot: this.data.timeslot,
      visitorDetails: this.validFields
    }

    this.moveAppointmentHistory(requestForm)
     this.subscriptions.add(
      this.appointmentService.editAppointment(body).subscribe(
        (res) => {
          if(res) {
            this.isSubmitting = false;
            this.successEvent.emit("edit-modal")
            this.modalRef.hide(); 
            this.addQueue(bodyForm);
          }
        },
        (error) => {
          console.log(error.error.message);
          this.toastr.error("Error encountered upon submitting ", "Error!");
          this.isSubmitting = false;  
        }
      )
    ) 
  }


  moveAppointmentHistory(requestForm) {

    this.appointmentService.moveAppointment(this.branchId,requestForm, this.data._id ).subscribe(
      (res) => {
        this.successEvent.emit('move-history');
        this.modalRef.hide();
        this.toastr.success("Appointment completed", "Success!");
      },
      (error) => {
        console.log(error)
        this.toastr.error("Error encountered upon submitting ", "Error!");
      }
    )
  }
  
  showQueueLimitModal(error) {
    const queueLimit = error.msg.match(/\d+/);
    const initialState = {
      queueLimit: queueLimit,
      serviceId: this.selectedServiceId,
      branchId: this.branchId,
    };
    this.modalRef = this.modalService.show(QueueLimitModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  showQueueSubsLimitModal(limit: number = 1): void {
    const slots = `${limit} ${limit > 1 ? 'queues' : 'queue'} per month`
    const initialState = {
      title: "Your business is doing so great",
      subTitle1: `that you’ve reached the maximum number of queues on your branch!`,
      subTitle2: `You have <b>${slots}</b> on your current plan`,
      subTitle3: `Upgrade your plan if you wish to add more queues 👍`,
    };

    this.modalRef = this.modalService.show(SubscriptionLimitModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  addTag(data):void {

  }

  removeTag(data):void {
    
  }

  async ngOnInit() {
    this.successEvent.emit('addQueue');
    await this.serviceList();
    this.accountList();
    if (this.data) {
      await this.getServiceData();
      this.tagLists = this.data.tags;
      this.selectedServiceCategoryId = this.queue?.serviceCategoryId;
    } else {
      await this.initServiceData();
    }
    await this.initValidators();
    
  }

  closeModal(): void {
    this.modalRef.hide();
  }

  backModal(): void {
    this.successEvent.emit('add-queue-close');
    this.modalRef.hide();
  }

  ngOndestroy() {

  }
}
