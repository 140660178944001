import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-complete-queue-modal',
  templateUrl: './complete-queue-modal.component.html',
  styleUrls: ['./complete-queue-modal.component.scss']
})
export class CompleteQueueModalComponent implements OnInit {
  @Output() successEvent = new EventEmitter();
  @Input() queue: any;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  confirm(){
    this.bsModalRef.hide();
    this.successEvent.emit(true);
  }

}
