import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";
import { AuthService } from "src/app/core/auth/auth.service";

@Component({
  selector: "app-register-page",
  templateUrl: "./register-page.component.html",
  styleUrls: ["./register-page.component.scss"],
})
export class RegisterPageComponent implements OnInit {
  focus: boolean;
  focus1: boolean;
  passwordShown: boolean;
  @ViewChild("password", { static: false }) password: any;
  isSubmitting = false;
  errMsg: string;
  errParam: string;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {}

  registerForm = this.fb.group({
    firstName: [
      "",
      [Validators.required, Validators.pattern(/^[A-Za-z- ñÑ.]*$/)],
    ],
    lastName: [
      "",
      [Validators.required, Validators.minLength(2), Validators.pattern(/^[A-Za-z- ñÑ.]*$/)],
    ],
    email: [
      "",
      [Validators.required, Validators.pattern(/^\S+@\S+\.[a-zA-z]+$/)],
    ],
    password: ["", Validators.required],
  });

  get f() {
    return this.registerForm.controls;
  }

  showPassword() {
    this.passwordShown = !this.passwordShown;
  }

  public speech = speechSynthesis;
  public selectedVoice: SpeechSynthesisVoice | null;
  public voices: SpeechSynthesisVoice[];
  submitForm(f: any) {
    const body = f.value;
    this.errMsg = null;
    this.errParam = null;
    this.isSubmitting = true;
    this.authService.register(body).subscribe(
      (data: any) => {
        if (data) {
          const user = {
            email: body.email,
            password: body.password,
          };
          this.authService
            .login(user)
            .pipe(first())
            .subscribe(
              (data: any) => {
                this.isSubmitting = false;
              },
              (error) => {
                console.log("error", error);
                this.isSubmitting = false;
              }
            );
        } else {
          this.isSubmitting = false;
        }
      },
      (error) => {
        this.isSubmitting = false;
        console.log("error", error);
        this.errParam = error.error.errors[0].param;
        this.errMsg = error.error.errors[0].msg;
        if (this.errMsg == 'Something went wrong.') {
          this.errMsg = error.error.errors[0].error.msg;
        }
        switch(this.errParam) {
          case 'email':
            if (this.errMsg == 'Invalid value') {
              this.errMsg = 'Invalid email.';
            }
            break;
          case 'password':
            if (this.errMsg == 'minimum length is 6') {
              this.errMsg = this.errParam.charAt(0).toUpperCase() + this.errParam.slice(1) + ' ' + this.errMsg + '.';
            }
          default:
            break;
        }
      }
    );
  }

  focusBlurPassword(isFocused) {
    this.focus1 = isFocused;
    this.password.nativeElement.className =
      "input-group input-group-merge mr-2";
    if (isFocused) {
      this.password.nativeElement.className =
        "input-group input-group-merge mr-2 focused";
    }

    if (this.f["email"]?.touched && this.f["password"]?.errors?.required) {
    }
  }

  change(){
    this.errMsg = null;
  }
}
