import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
@Component({
  selector: "app-move-queue-service-modal",
  templateUrl: "./move-queue-service-modal.component.html",
  styleUrls: ["./move-queue-service-modal.component.scss"],
})
export class MoveQueueServiceModalComponent implements OnInit {
  title: string;
  subTitle: string;
  searchSubject: Subject<string> = new Subject();
  searchString: string;
  serviceLists: any;
  enableRequiredRemarks: boolean = true;
  isBtnDisabled: boolean = true;
  serviceList: any;
  selectedService: any;
  isSubmitting = false;

  @Output() submitEvent = new EventEmitter();
  enableRemarks: boolean;

  remarks: string = "";
  
  constructor(public bsModalRef: BsModalRef,public httpService: HttpService, private dataService: DataService) {}

  ngOnInit(): void {
    this.getQueueSettings();
    this.searchSubject
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((searchTextValue) => {
        // console.log("search string", searchTextValue);
      });

    this.serviceList = this.serviceLists.map((element) => {
      return {
        avatarUrl: "",
        caption: "",
        isDisabled: false,
        label: element.displayName,
        rightLabel: null,
        value: element._id,
        counts: element.counts,
      };
    });
    this.selectedService = this.serviceList[0]?.value;
  }

  public searchService() {
    this.searchSubject.next(this.searchString);
  }

  public selectService(ev) {
    console.log("selectService", ev);
    this.selectedService = ev;
  }

  checkTextBoxValue() {
    let isDisabled: boolean = false;
    if (this.remarks === '') {
        isDisabled = true;
    } else{
        isDisabled = false;
    }
    this.isBtnDisabled = isDisabled;
  } 

  private getQueueSettings() {
    const branchId = this.dataService.accountData$.data.branchId;
    this.httpService
    .get$(`queues/queue-settings/${branchId}`)
    .subscribe(
      (data) => {
        const transferQueue = 'require-all-remarks-fields';
        this.enableRequiredRemarks = data.data.featuresEnabled.includes(transferQueue);
      },
      (error) => {
        console.log(error);
        throw new Error(error);
      }
    );
  } 

  submit(){
    this.isSubmitting = true;
    const data = {
        destinationServiceId: this.selectedService,
        remarks: this.remarks,
    };

    this.submitEvent.emit(data)
    setTimeout(() => {
      this.isSubmitting = false;
      this.bsModalRef.hide();
    }, 500);
  }
}
