import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { TeamManagementService } from "../../services/team-management.service";

@Component({
  selector: "app-remove-account-modal",
  templateUrl: "./remove-account-modal.component.html",
  styleUrls: ["./remove-account-modal.component.scss"],
})
export class RemoveAccountModalComponent implements OnInit {
  title: string;
  profileData: any;
  @Output() successEvent = new EventEmitter();
  isSubmitting = false;
  branchId: string;

  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private teamService: TeamManagementService
  ) {}

  ngOnInit(): void {}

  removeAccount() {
    this.isSubmitting = true;

    this.teamService.deleteAccount(this.profileData._id, this.branchId).subscribe(
      (res) => {
        console.log("RESET PASSWORD SUCCESS", res);
        this.isSubmitting = false;
        this.successEvent.emit(res)
        this.bsModalRef.hide();
        this.toastr.error(
          `${this.profileData.firstName} ${this.profileData.lastName} has been removed to this branch`,
          "Removed!"
        );
      },
      (error) => {
        console.log("error", error);
        this.isSubmitting = false;
      }
    );
  }
}
