import { Component, Input, OnInit } from '@angular/core';
import { GenericService } from 'src/app/core/services/generic.service';
import * as moment from 'moment';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  _timelineLists: any;
  @Input() public set timelineLists(timeline) {
    if (timeline) {
      this._timelineLists = timeline.reverse();
    }
  }

  public get timelineLists() {
    return this._timelineLists;
  }

  constructor(
    private genericService: GenericService
  ) { }

  getQueueAction(action) {
    return this.genericService.getQueueAction(action);
  }

  getFormattedSummary(summary: string) {

    if (summary.indexOf('on') > -1) {
      let service = summary.substring(summary.indexOf('on') + 3);
      service = service.substring(0, service.indexOf('by') - 1)
      const server = summary.substring(summary.indexOf('by') - 1);
      const status = summary.substring(0, summary.indexOf(service));
      // PAL REQUEST
      return `${status}<b>${service}</b>${server}`;

    } else {
      // PAL REQUEST
      return summary;
    }

  }

  getTimeAgo(data) {
    if (data) {
      const y = new Date(data).getFullYear();
      const m = new Date(data).getMonth();
      const d = new Date(data).getDate();
      const h = new Date(data).getHours();
      const mm = new Date(data).getMinutes();
      const s = new Date(data).getSeconds();
      const time = moment([y, m, d, h, mm, s]).fromNow();

      return time === 'a day' ? '1 day' : time;
    }
  }

  ngOnInit(): void {


  }

}
