import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/notifications';
import { INotificationState } from '../../interfaces';

const initialState: INotificationState = {
    isOpen: false,
    isMarkAllAsRead: false,
    hasNotification: false
  };
  
  export const notificationReducer = createReducer(
    initialState,
    on(actions.open, (state, { isOpen }) => ({ ...state, isOpen })),
    on(actions.close, (state, { isOpen }) => ({ ...state, isOpen })),
    on(actions.markAllAsRead, (state, { isMarkAllAsRead }) => ({
      ...state,
      isMarkAllAsRead,
    })),
    on(actions.setHasNotification, (state, { hasNotification }) => ({
      ...state,
      hasNotification,
    }))
  );