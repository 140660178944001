import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { HttpService } from "src/app/core/http/http.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { StatusFilterWithObject } from "../../interfaces/support.interface";
import { ToastrService } from "ngx-toastr";
import { SupportService } from "../../services/support.service";
import { DataService } from 'src/app/core/services/data.service';
import { Subscription } from "rxjs";

@Component({
  selector: "app-support-report-issue-modal",
  templateUrl: "./support-report-issue-modal.component.html",
  styleUrls: ["./support-report-issue-modal.component.scss"],
})
export class SupportReportIssueModalComponent implements OnInit, OnDestroy {
  inputForm: FormGroup;
  title: string = "";
  subTitle: string = "";
  profileData: any;
  isSelectClicked: boolean;
  isSubmitting: boolean = false;
  isLoading: boolean = false;
  fileList: File [] = [];
  urlList: object [] = [];
  fileSelected:any = null;
  selectedPhoto: any;
  selectedStatus: any;
  @Input() templateData: any;
  selectedIssueType: string = "";
  disableBtn: boolean = true;
  reportIssues: Array<StatusFilterWithObject> = [
    {
      label: "Help with using the product",
      value: {name: "Help with using the product", enquiryValue: 0 },
    },
    {
      label: "Report an issue",
      value: {name: "Report an issue", enquiryValue: 1 },
    },
    {
      label: "Improvement request",
      value: {name: "Improvement request", enquiryValue: 2 },
    },
    {
      label: "Other",
      value: {name: "Other", enquiryValue: 3 }
    }
  ];

  subscriptions = new Subscription()

  ngOnInit(): void {
    this.selectedIssueType = this.selectedIssueType;
  }

  constructor(
      private modalRef: BsModalRef, 
      private formBuilder: FormBuilder, 
      private supportService: SupportService,  
      private dataService: DataService,   
      private httpService: HttpService,
      private toastr: ToastrService,
      ) 
  {
    this.inputForm = this.formBuilder.group({
      reportIssueMessage: [null, [Validators.required]],
    });
  }

  closeModal() {
    this.modalRef.hide();
  }


  selectIssueType(ev) {
    if (ev !== "Please Select Issue Type") {
      this.selectedIssueType = ev;
      this.disableBtn = false;
    } else {
      this.disableBtn = true;
    }
  }

  onFilesSelected(files: File[]): void {   

    let _validFileExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    
    for (const file of files) {
      // check if the file extension is allowed if not it will send error validation
      if(!_validFileExtensions.exec(file.name)){
        this.toastr.error("invalid file type", "Error!");
        return
      } {

        const fileMBSize = parseFloat((file.size / 1048576).toFixed(2)) // covert byte size to MB size
        // if the file is greater than 10 MB send error
        if(fileMBSize > 10) {
          this.toastr.error("File size must lower than 10 MB", "Error!");
          return
        } else {
          this.fileList.push(file);
            
          const allFileSize =   this.fileList.reduce((n, {size}) => n + size, 0);
          const totalFileSize = parseFloat((allFileSize / 1048576).toFixed(2)) 
          
          // if the file name is existed reject the attachfile name
          if(this.fileList.filter( filterFile => file.name === filterFile.name).length > 1) {
            this.fileList.pop();
            this.toastr.error("Duplicated file name", "Error!");
            return
          }
          
          // if the total size is greater than 10MB send error
          if(totalFileSize > 10) {   
            this.fileList.pop();
            this.toastr.error("File size must lower than 10 MB", "Error!");
            return
          } 
        }
      }
      
    }
  }

 bytesToSize(bytes) {
    let sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    for (var i = 0; i < sizes.length; i++) {
      if (parseFloat(bytes) <= 1024) {
        return bytes + ' ' + sizes[i];
      } else {
        bytes = (parseFloat(bytes) / 1024).toFixed(2)
      }
    }
    return bytes + ' P'; 
  } 


  deleteFile(value: string) {
   this.fileList =  this.fileList.filter((data: File) => data.name  !== value)
  
  }

  submitPhoto() {
    const body = {
      branchId: this.dataService.accountData$.data.branchId, 
      issueType:  this.selectedIssueType,
      reportIssueMessage: this.inputForm.value.reportIssueMessage,
      mediaUrl: this.urlList,
    };

    console.log("report an issue type")
    console.log(body.issueType);

    this.subscriptions.add(
      /* Send the data of image details and report issue fields data */
      this.supportService.createReportIssue(body).subscribe(
        (res) => {
          this.modalRef.hide();
          this.toastr.success("Issue has been reported", "Success!");
        },
          (error) => {
            console.log("error", error);
            this.toastr.error("Error encountered upon reporting an issue", "Error!");
            this.isSubmitting = false;
          }
      )
    )
  }  


  /*  Upload image in AWS S3 Bucket  */
  sendReportIssue = async () => {

    const param = `support/${this.dataService.accountData$.data.branchId}/presign-image`;
    try {

      this.isSubmitting = true;

      let dataFile;

      for(let file of this.fileList){
              
       dataFile = await this.httpService.uploadPhoto(param, file)
      
       this.urlList.push({path: dataFile.data.fields.key, fileName:  file.name, fileType: file.type})
      }

      this.submitPhoto();
    }catch(e) {
      console.log("Error encountered upon reporting an issuer ", e)
      this.toastr.error("Error encountered upon reporting an issue", "Error!");
      this.isSubmitting = false;
    }
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
