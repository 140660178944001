import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-cards',
  templateUrl: './button-cards.component.html',
  styleUrls: ['./button-cards.component.scss']
})
export class ButtonCardsComponent implements OnInit {
  @Input() buttonLists: Array<any>;
  @Output() emittedAction = new EventEmitter();

  constructor() { }

  choose(title) {
    this.emittedAction.emit(title);
  }

  ngOnInit(): void {
  }

}
