import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/core/auth/auth.service";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { ModalService } from "src/app/core/services/modal.service";
import { TeamManagementService } from "src/app/modules/team-management/services/team-management.service";

@Component({
  selector: "app-queue-transfer-window-modal",
  templateUrl: "./queue-transfer-window-modal.component.html",
  styleUrls: ["./queue-transfer-window-modal.component.scss"],
})
export class QueueTransferWindowModalComponent
  extends ModalService
  implements OnInit {
  assignedWindowLists: Array<any> = this.dataService.assignedWindowLists$;
  selectedWindow: string;
  branchId: string;
  type: string;

  title: string;
  subTitle: string;
  caption: string;
  allWindows: any;

  currentWindow: string;
  selectedQueue: any;
  selectedQueueId: string;

  windowLists: any;

  enableRemarks: boolean;
  remarks = "";
  requireRemarks: boolean; 
  accountList: any;

  constructor(
    bsModalRef: BsModalRef,
    httpService: HttpService,
    dataService: DataService,
    toastr: ToastrService,
    authService: AuthService,
    private teamService: TeamManagementService
  ) {
    super(bsModalRef, httpService, dataService, toastr, authService);
  }

  getAccountName(id) {
    const a = this.accountList.find((x) => x._id === id);
    if (a) {
      return a;
    }
    return null;
  }

  processWindowList() {
    this.windowLists = this.allWindows.map((element) => {
      const acct = this.getAccountName(element.attendingEmployee) as any;
      const windowServiceIds = element.assignedServices.map(x => { return x._id });
      const isServiceInWindow = windowServiceIds.includes(this.selectedQueue.serviceId)
      return {
        label: element.displayName,
        value: element._id,
        rightLabel: !isServiceInWindow ? '' : (acct ? acct.firstName + " " + acct.lastName : acct),
        imageUrl: !isServiceInWindow ? '' : (acct ? acct.avatarUrl : acct),
        isDisabled: element.suspended || !acct || !isServiceInWindow,
        disabledText: element.suspended
          ? "This window is disabled"
          : !isServiceInWindow ? "Service is not assigned" : "No Attending Staff",
      };
    });

    this.windowLists = this.windowLists.filter(
      (x) => x.value !== this.currentWindow
    );

    const selected = this.windowLists.filter((x) => !x.isDisabled);

    this.selectedWindow = selected ? selected[0]?.value : null;
  }

  selectWindow($event) {
    this.selectedWindow = $event;
  }

  confirm() {
    if (this.type === "single") {
      const body = {
        windowId: this.currentWindow,
        destinationWindowId: this.selectedWindow,
        remarks: this.remarks,
      };
      this.httpService
        .patch$(
          `queues/${this.branchId}/${this.selectedQueueId}/transfer-window`,
          body
        )
        .subscribe(
          (data) => {
            const queueNo = data.data.queueNo;
            const windowName = this.windowLists.find(
              (x) => x.value === this.selectedWindow
            )?.label;
            this.toastr.success(
              `${queueNo} has been transferred to ${windowName}`,
              "Transferred!", {
                positionClass: 'toast-bottom-right-custom'
              }
            );
            this.dataService.publishDataChange$({
              action: "completed",
              queue: queueNo,
            });
            this.bsModalRef.hide();
          },
          (error) => {
            const transferError = error.error.errors[0];
            let defaultMessage = "Queue has not been transferred";

            if (transferError.msg === "Service does not exist on destination window.") {
              defaultMessage = "Service does not exist on destination window."
            }

            this.toastr.error(defaultMessage, "Failed!", {
              positionClass: 'toast-bottom-right-custom'
            });
          }
        );
    } else {
      const body = {
        destinationWindowId: this.selectedWindow,
      };
      this.httpService
        .patch$(
          `queues/${this.branchId}/bulk-transfer/${this.currentWindow}`,
          body
        )
        .subscribe(
          (data) => {
            this.bsModalRef.hide();
            this.dataService.publishCloseModalReason$({
              action: "confirmLeaveWindow",
              remarks: "",
            });
          },
          (error) => { }
        );
    }
  }

  async ngOnInit() {
    this.teamService.getTeamList("", this.branchId).subscribe(async (res) => {
      this.accountList = res.data;
      await this.processWindowList();
    });
  }
}
