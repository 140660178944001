import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { DataService } from "src/app/core/services/data.service";
import { Subscription } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SettingsService } from "../../services/settings.service";
import { ToastrService } from "ngx-toastr";
import { HttpService } from "src/app/core/http/http.service";
import { BranchManagementService } from "src/app/modules/branch-management/services/branch-management.service";
import { EditPaymentMethodComponent } from '../../components/edit-payment-method/edit-payment-method.component';
import { EditBillingAddressComponent } from '../../components/edit-billing-address/edit-billing-address.component';
import { EditBillingContactComponent } from '../../components/edit-billing-contact/edit-billing-contact.component';
import { EditBillingCurrencyComponent } from '../../components/edit-billing-currency/edit-billing-currency.component';

@Component({
  selector: 'app-billing-settings',
  templateUrl: './billing-settings.component.html',
  styleUrls: ['./billing-settings.component.scss']
})
export class BillingSettingsComponent implements OnInit {
  isLoading = false;
  subscriptions = new Subscription();
  selectedBranch = this.branchService.getSelectedBranch();
  billingData: any = [
    {
      payment: {
        type: 'Mastercard',
        value: '•••• •••• •••• 1302'
      },
      address: {
        value: '3rd floor Uptown Mall, Bonifacio Global City, Taguig, NCR, Metro Manila 1630, Philippines'
      },
      invoiceEmail: {
        value: 'johndoe@petzania.com'
      },
      currency: {
        value: '(₱) Philippine Pesos'
      }
    }
  ]

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private settingService: SettingsService,
    private branchService: BranchManagementService,
    private httpService: HttpService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    public modalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    if(this.billingData){
      this.isLoading = false;
    }
  }

  revertChanges() {

  }

  saveChanges() {
    
  }

  editPaymentMethod() {
    const initialState = {
      title: "Change Payment Method",
      subTitle: "Fill out the following details to change your payment method. Payment information is kept secure and isn't stored anywhere on the site."
    };

    this.modalRef = this.modalService.show(EditPaymentMethodComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  editBillingAdddress() {
    const initialState = {
      title: "Change Billing Address",
      subTitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    };

    this.modalRef = this.modalService.show(EditBillingAddressComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  editBillingContact() {
    const initialState = {
      title: "Change Billing Contact",
      subTitle: "Monthly or annual invoices will be sent to this email."
    };

    this.modalRef = this.modalService.show(EditBillingContactComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  editBillingCurrency() {
    const initialState = {
      title: "Change Currency",
      subTitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    };

    this.modalRef = this.modalService.show(EditBillingCurrencyComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

}
