import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TitleCasePipe } from "@angular/common";

@Component({
  selector: 'app-toggle-checkbox-list',
  templateUrl: './toggle-checkbox-list.component.html',
  styleUrls: ['./toggle-checkbox-list.component.scss'],
  providers: [TitleCasePipe]
})
export class ToggleCheckboxListComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() title1: string;
  @Input() title2: string;
  @Input() mainLists: any;
  @Input() isCheckboxDisabled: boolean;
  @Output() changedItem = new EventEmitter();
  toggleCheckboxForm: FormGroup;
  filteredCheckbox: Array<any>;
  patchedLists: Array<any>;
  isConfirmDisabled: boolean;
  priorityIndex: number = -1;

  constructor(
    private fb: FormBuilder,
    private titleCasePipe: TitleCasePipe
  ) { }

  initForm() {
    this.toggleCheckboxForm = this.fb.group({
      itemArray: this.fb.array([])
    });
    this.createPerItem();
  }

  createPerItem() {
    this.mainLists.forEach((element, i) => {
      this.itemArray.push(
        this.fb.group({
          enabled: element.enabled,
          fieldName: element.fieldName,
          required: element.required,
          smartFormFieldId: element._id
        }));
    });
  }

  get f() { return this.toggleCheckboxForm.controls; }
  get itemArray() { return this.toggleCheckboxForm.get('itemArray') as FormArray; }

  change(index) {
    this.changedItem.emit({
      formValue: this.toggleCheckboxForm.value,
      isConfirmDisabled: false
    });
    if (index != this.priorityIndex) {
      this.mainLists[index].enabled = this.toggleCheckboxForm.value.itemArray[index].enabled;
    }
    
    if(!this.toggleCheckboxForm.value.itemArray[index].enabled){
      // this.toggleCheckboxForm.value.itemArray[index].required = false;
      // this.itemArray.controls[index].patchValue({
      //   required: false,
      // })
    }
  }

  checkDisableCheckbox() {
    this.isCheckboxDisabled ? this.toggleCheckboxForm.disable() : this.toggleCheckboxForm.enable();
  }

  ngOnInit(): void {
    this.initForm(); 
    if (this.mainLists) {
        this.priorityIndex = this.mainLists.findIndex(list => list.fieldName === 'priority');
        if(this.priorityIndex > -1) {
          this.mainLists[this.priorityIndex].enabled = false;
        }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.toggleCheckboxForm) {
      this.checkDisableCheckbox();
    }
  }

  textTransform(text) {
    let newText = '';
    newText = text.replace(/_+/g, ' ');
    newText = this.titleCasePipe.transform(newText);
    return newText;
  }

}