import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  apiUrl = environment.urlAPI;

  constructor(private http: HttpClient) { }

  private get httpHeader(): any {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return {
      headers,
    };
  }

  public getQueuesCount(branchId): Observable<any> {
    const url = `${this.apiUrl}/queues/${branchId}/counts`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getActiveQueuesCount(branchId): Observable<any> {
    const url = `${this.apiUrl}/queues/${branchId}/counts/active`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getCurrentQueuesCount(branchId): Observable<any> {
    const url = `${this.apiUrl}/queues/${branchId}/counts/current-served`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getWindowStatus(branchId): Observable<any> {
    const url = `${this.apiUrl}/services/windows/${branchId}/dashboard/`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getServiceStatus(branchId): Observable<any> {
    const url = `${this.apiUrl}/services/${branchId}/dashboard/list?limit=999`;
    return this.http.get<any>(url, this.httpHeader);
  }
}
