import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-web-admin-landing-page',
  templateUrl: './web-admin-landing-page.component.html',
  styleUrls: ['./web-admin-landing-page.component.scss']
})
export class WebAdminLandingPageComponent implements OnInit {

  constructor(private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.authService.removeTokens();

    this.activatedRoute.queryParams.subscribe(params => {
      this.authService.storeRefreshToken(params['ref'])
      this.authService.storeAccessToken(params['jwt'])
      this.router.navigateByUrl("").then(() => {
        window.location.reload();
      })
    })
  }

}
