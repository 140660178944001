import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BranchManagementService } from "../../services/branch-management.service";
import { DeleteTvModalComponent } from "../delete-tv-modal/delete-tv-modal.component";
import { EditTvModalComponent } from "../edit-tv-modal/edit-tv-modal.component";

@Component({
  selector: "app-view-tv-details-modal",
  templateUrl: "./view-tv-details-modal.component.html",
  styleUrls: ["./view-tv-details-modal.component.scss"],
})
export class ViewTvDetailsModalComponent implements OnInit {
  title: string;
  tvId: string;
  branchId: string;
  tvDetails: any;
  @Output() removeTvEvent = new EventEmitter();


  constructor(
    public bsModalRef: BsModalRef, private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    console.log("DEVICE DETAILS", this.tvDetails);

    // this.branchService
    //   .getDeviceDetails(this.branchId, this.tvId)
    //   .subscribe((res) => {
    //     this.tvDetails = res.data;

    //     console.log("DEVICE DETAILS", res);
    //   });
  }

  getTimeAgo(data){
    if(data){
      const y = new Date(data).getFullYear();
      const m = new Date(data).getMonth();
      const d = new Date(data).getDate();
      const h = new Date(data).getHours();
      const mm = new Date(data).getMinutes();
      const s = new Date(data).getSeconds();
      const time = moment([y, m, d, h, mm, s]).fromNow();

      return time === 'a day' ? '1 day' : time;
    }
  }
  
  removeTV() {
    this.bsModalRef.hide();

    const initialState = {
      title: "Remove TV?",
      tvId: this.tvId,
      branchId: this.branchId,
      tvName: this.tvDetails.deviceName
    };
    this.bsModalRef = this.modalService.show(DeleteTvModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false
    });

    this.bsModalRef.content.successEvent.subscribe(
      (data) => {
        console.log("", data);
        this.removeTvEvent.emit(data)
      },
      (err) => {
        console.log("", err);
        return false;
      }
    );
  }

  editTv(){
    this.bsModalRef.hide();
    const initialState = {
      title: "TV Settings",
      subTitle: "Changes will be reflected in real-time. ",
      tvId: this.tvId,
      branchId: this.branchId,
      tvName: this.tvDetails.deviceName
    };
    this.bsModalRef = this.modalService.show(EditTvModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false
    });

    this.bsModalRef.content.successEvent.subscribe(
      (data) => {
        console.log("", data);
        this.removeTvEvent.emit(data)
      },
      (err) => {
        console.log("", err);
        return false;
      }
    );
  }

}
