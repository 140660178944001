import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/core/auth/auth.service";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { GenericService } from "src/app/core/services/generic.service";
import { ModalService } from "src/app/core/services/modal.service";
import { SubscriptionLimitModalComponent } from "src/app/modules/billing/components/subscription-limit-modal/subscription-limit-modal.component";
import { TeamManagementService } from "src/app/modules/team-management/services/team-management.service";
import { SUBSCRIPTION_PLAN } from "src/app/shared/const/plan";
import { QueueLimitModalComponent } from "../queue-limit-modal/queue-limit-modal.component";
import { MaxVisitorsModalComponent } from "../max-visitors-modal/max-visitors-modal.component";
import { TransactionsModalComponent } from "../transactions-modal/transactions-modal.component";

@Component({
  selector: "app-queue-add-form",
  templateUrl: "./queue-add-form.component.html",
  styleUrls: ["./queue-add-form.component.scss"],
})
export class QueueAddFormComponent extends ModalService implements OnInit {
  selectedServiceId: string;
  selectedServiceCategoryId: string;
  selectedServiceCategoryName: string;
  serviceLists: Array<any>;
  modifiedServiceLists: Array<any>;
  categoryLists: Array<any>;
  modifiedCategoryLists: Array<any>;
  serviceData: any;
  enabledFieldLists: Array<any>;
  requiredValidators: any;
  mobileValidators: any;
  validFields: Array<any> = [];
  tagLists: Array<String> = [];
  activeQueue: any;
  queue: any;
  type: string;
  branchId: string;
  isDirty = false;
  submitting = false;
  featureLimits = SUBSCRIPTION_PLAN.professional;
  placeholder = "Enter Visitor Name";
  universalTags: any = [];
  isSelected: boolean = false;
  canProceed: boolean = false;
  noNumberValidator: any;
  @Output()
  successEvent = new EventEmitter();
  priorityIndex: number = 0 | -1;

  specialFields = ["party_size", "tier_level", "guest_type"];
  guestType: any;
  guestTypeList = [
    { label: "Senior Citizen", value: "Senior Citizen" },
    { label: "Person with Disability", value: "Person with Disability" },
    { label: "Pregnant", value: "Pregnant" },
  ];
  partySizeList = [
    { label: "1-2 Persons", value: "1-2 Persons" },
    { label: "3-4 Persons", value: "3-4 Persons" },
    { label: "5-6 Persons", value: "5-6 Persons" },
    { label: "7+ Persons", value: "7+ Persons" },
  ];
  tierLevelList = [
    { label: "Silver", value: "Silver" },
    { label: "Gold", value: "Gold" },
    { label: "Platinum", value: "Platinum" },
    { label: "Emerald", value: "Emerald" },
    { label: "Ruby", value: "Ruby" },
    { label: "Diamond", value: "Diamond" },
  ];
  showIdNumber: boolean = false;
  idNumber: string = "";
  isSpecialGuest: boolean = false;
  visitorMinMax: any = { min: 1, max: 100 };

  isHidden: boolean = false;
  openSecondModal: () => void; // This will be assigned from the parent

  constructor(
    bsModalRef: BsModalRef,
    httpService: HttpService,
    dataService: DataService,
    toastr: ToastrService,
    authService: AuthService,
    private genericService: GenericService,
    private modalService: BsModalService,
    private bsModalRef2: BsModalRef,
  ) {
    super(bsModalRef, httpService, dataService, toastr, authService);
  }

  modifyServiceLists() {
    this.modifiedServiceLists = this.serviceLists.map((element) => {
      return {
        label: element.displayName,
        value: element._id,
      };
    });
  }

  async initServiceData() {
    if (this.selectedServiceId) {
      await this.selectService(this.selectedServiceId);
    }
  }

  checkCategory() {
    this.categoryLists = this.categoryLists.filter((x) => !x.subCategoryId);
    const serviceCategories = this.categoryLists.filter(
      (s) =>
        s.assignedServices.includes(this.selectedServiceId) &&
        (!s.parentCategoryId || s.parentCategoryId),
    );

    let categoryChoices = [];
    if (serviceCategories) {
      serviceCategories.forEach((c1) => {
        let categoryLabel: any;
        if (c1.parentCategoryId) {
          const categoryLevel1 = this.categoryLists.find(
            (c) => c._id === c1.parentCategoryId,
          );
          if (!categoryLevel1) {
            return [];
          }
          if (categoryLevel1.parentCategoryId) {
            const categoryLevel2 = this.categoryLists.find(
              (c) => c._id === categoryLevel1.parentCategoryId,
            );
            if (!categoryLevel2) {
              return [];
            }
            categoryLabel = {
              label: categoryLevel2.displayName +
                " > " +
                categoryLevel1.displayName +
                " > " +
                c1.displayName,
              value: c1._id,
            };
          } else {
            categoryLabel = {
              label: (categoryLabel = categoryLevel1.displayName + " > " +
                c1.displayName),
              value: c1._id,
            };
          }
        } else {
          categoryLabel = {
            label: (categoryLabel = c1.displayName),
            value: c1._id,
          };
        }

        categoryChoices.unshift(categoryLabel);
      });

      return categoryChoices;
    }
  }

  async selectService($event) {
    this.serviceData = null;
    this.selectedServiceId = $event;
    this.modifiedCategoryLists = [];
    this.modifiedCategoryLists = this.checkCategory();

    if (!this.modifiedCategoryLists || this.modifiedCategoryLists.length <= 0) {
      await this.getServiceData();
    } else {
      this.isConfirmDisabled = true;
      this.isSelected = false;
    }

    this.isDirty = true;
  }

  async selectServiceCategory($event) {
    this.selectedServiceCategoryId = $event;
    if (
      this.selectedServiceCategoryId &&
      this.selectedServiceCategoryId !== "Please Select Service Category"
    ) {
      this.selectedServiceCategoryName = this.categoryLists.find((category) =>
        category._id == $event
      )?.displayName;

      if (this.selectedServiceCategoryName) {
        switch (
          this.selectedServiceCategoryName.split(" ").join("").toLowerCase()
        ) {
          case "1-2people":
            this.visitorMinMax = { min: 1, max: 2 };
            break;
          case "3-4people":
            this.visitorMinMax = { min: 3, max: 4 };
            break;
          case "5-6people":
            this.visitorMinMax = { min: 5, max: 6 };
            break;
          case "7+people":
            this.visitorMinMax = { min: 7, max: 100 };
            break;
          default:
            this.visitorMinMax = { min: 1, max: 100 };
            break;
        }
      }

      await this.getServiceData();
    }

    this.isDirty = true;
  }

  async getServiceData() {
    if (
      this.selectedServiceId &&
      this.selectedServiceId !== "Please Select Service"
    ) {
      this.showIdNumber = false;
      this.httpService
        .get$(`services/${this.branchId}/${this.selectedServiceId}`)
        .toPromise()
        .then(
          (data) => {
            this.serviceData = data.data;
            this.enabledFieldLists = this.serviceData?.queueForm
              ? this.serviceData.queueForm.fields
                .filter((element) => element.enabled)
                .map((element, i) => {
                  const validatorArr = [];
                  element.required
                    ? validatorArr.push(this.requiredValidators)
                    : null;
                  element.fieldName == "name"
                    ? validatorArr.push(this.noNumberValidator)
                    : null; // added validator for name field
                  // element.required && //remove for optional mobileNo
                  ["mobileNo", "mobile-number"].includes(element.fieldType)
                    ? validatorArr.push(this.mobileValidators)
                    : null;
                  return {
                    ...element,
                    label: element.label,
                    value: this.queue ? this.queue.fields[i]?.value : "",
                    validators: validatorArr,
                  };
                })
              : null;
            this.validFields = this.enabledFieldLists
              ? this.enabledFieldLists.map((element, i) => {
                return {
                  ...element,
                  i: i,
                  valid: !element.required
                    ? true
                    : element.value
                    ? true
                    : false,
                  value: element?.value ? element?.value : "",
                  fieldType: element?.fieldType,
                };
              })
              : null;
            this.isConfirmDisabled = this.validFields
              ? this.validFields.filter((element) => !element.valid).length !==
                0
              : false;
            if (
              this.serviceData &&
              this.serviceData.enabledFeatures.includes("auto-tag")
            ) {
              this.serviceData.tags.forEach((t) => {
                if (!this.tagLists.includes(t)) {
                  this.tagLists.push(t);
                }
              });
            }
            if (this.queue) {
              this.enabledFieldLists = this.queue.fields.map((element, i) => {
                if (this.serviceData && this.serviceData.queueForm.fields) {
                  element = this.serviceData.queueForm.fields.find((field) =>
                    field.fieldName == element.fieldName
                  ) || element;
                }
                const validatorArr = [];
                element.required
                  ? validatorArr.push(this.requiredValidators)
                  : null;
                element.fieldName == "name"
                  ? validatorArr.push(this.noNumberValidator)
                  : null; // added validator for name field
                // element.required && //remove for optional mobileNo
                ["mobileNo", "mobile-number"].includes(element.fieldType)
                  ? validatorArr.push(this.mobileValidators)
                  : null;
                return {
                  ...element,
                  label: element.label,
                  value: this.queue ? this.queue.fields[i]?.value : "",
                  validators: validatorArr,
                  fieldType: element.fieldType || "text",
                  enabled: true,
                  required: element.required || false,
                  valid: true,
                };
              });
              this.validFields = this.enabledFieldLists;
              this.patchValue();
            }
          },
          (error) => {
            console.log("error", error);
          },
        );
      this.isSelected = true;
    }
  }

  async patchValue() {
    const { fields, ...queue } = this.queue;
    this.queue = {
      ...queue,
      fields: fields.map((element) =>
        ["mobileNo", "mobile-number"].includes(element.fieldName)
          ? {
            ...element,
            value: this.genericService.patchMobile(element.value),
          }
          : element
      ),
    };

    // for solaire
    this.guestType =
      this.queue.fields.find((field) =>
        ["guest_type"].includes(field.fieldName)
      )?.value || null;
    const guestTypeField =
      this.validFields.find((field) =>
        ["guest_type"].includes(field.fieldName)
      ) || null;

    if (this.guestType) {
      this.idNumber = this.guestType.split(` - `)[1] || null;
      this.guestType = this.guestType.split(" - ")[0] || null;
      if (this.idNumber) {
        this.idNumber = this.idNumber.split(` - `)[0].split(" ").join("") ||
          null;
        if (guestTypeField) {
          guestTypeField.value = `${this.guestType} - ${this.idNumber}`;
        }
      }
      this.showIdNumber = ["Senior Citizen", "Person with Disability"].includes(
        this.guestType,
      );
      this.queue.fields.find((field) =>
        ["guest_type"].includes(field.fieldName)
      ).value = this.guestType || null;
    }
    // for solaire

    this.tagLists = this.queue.tags;
  }

  changeInput(index, $event) {
    const isToggleSwitch = typeof $event === "boolean";
    if ($event.valid) {
      this.validFields = this.validFields.map((element, i) =>
        i === index
          ? {
            ...element,
            valid: true,
            value: $event.value,
          }
          : {
            ...element,
            valid: element.valid,
            value: element.value,
          }
      );
    } else {
      this.validFields = this.validFields.map((element, i) =>
        i === index
          ? {
            ...element,
            valid: false,
            value: $event.value,
          }
          : {
            ...element,
            valid: element.valid,
            value: element.value,
          }
      );
    }

    if (isToggleSwitch) {
      this.validFields = this.validFields.map((element, i) =>
        i === index
          ? {
            ...element,
            valid: true,
            value: $event,
          }
          : {
            ...element,
            valid: element.valid,
            value: element.value,
          }
      );
    }

    const valid = this.validFields.filter((element) => element.valid);
    this.isConfirmDisabled = valid.length !== this.enabledFieldLists.length;
    this.isDirty = true;
  }

  // for hard coded fields
  selectSpecialField(value, index) {
    if (
      [...this.guestTypeList, ...this.partySizeList, ...this.tierLevelList].map(
        (list) => list.label,
      ).includes(value)
    ) {
      if (this.validFields[index].fieldName == "guest_type") {
        this.guestType = value;
        this.validFields[index].value = value;
        this.validFields[index].valid = true;
        if (["Senior Citizen", "Person with Disability"].includes(value)) {
          this.showIdNumber = true;
        } else {
          this.showIdNumber = false;
          this.idNumber = null;
        }
      } else {
        this.validFields[index].value = value;
        this.validFields[index].valid = true;
      }
    } else {
      this.validFields[index].value = null;
      this.validFields[index].valid = this.validFields[index].required
        ? false
        : true;
    }
    const valid = this.validFields.filter((element) => element.valid);
    this.isConfirmDisabled = valid.length !== this.enabledFieldLists.length;
    this.isDirty = true;
  }

  changeIdNumber(value: any, index: number) {
    this.idNumber = value.value;
    if (this.idNumber) {
      this.validFields[index].value = `${this.guestType} - ${
        this.idNumber.split(" ").join("").toUpperCase()
      }`;
    }
    this.isDirty = true;
  }

  addTag($event) {
    // PAL REQUEST
    if (typeof $event === "object") {
      this.tagLists = [...$event];
    } else {
      this.tagLists.push($event);
    }

    // this.tagLists.push($event);
    this.isDirty = true;
  }

  removeTag($event) {
    const tag = $event.value;
    const tagIndex = this.tagLists.indexOf(tag);
    this.tagLists.splice(tagIndex, 1);
    this.isDirty = true;
  }

  confirm($event) {
    let validForm: boolean = true;

    if (
      this.selectedServiceCategoryName &&
      this.selectedServiceCategoryName.split(" ").join("").toLowerCase() ===
        "7+people"
    ) {
      const visitors = this.validFields.find((field) =>
        field.fieldName == "visitors"
      );
      if (visitors && visitors.value > 11) {
        validForm = false;
        visitors.valid = false;
        this.bsModalRef2 = this.modalService.show(MaxVisitorsModalComponent, {
          class: "modal-dialog-centered",
          ignoreBackdropClick: true,
          keyboard: false,
        });
      }
    }

    if (validForm) {
      this.submitting = true;
      let prio = this.validFields.find((field) =>
        field.fieldName === "priority"
      )?.value;

      if (
        this.validFields.find((field) =>
          ["guest_type", "tier_level"].includes(field.fieldName)
        )
      ) {
        prio = true;
      }

      const bodyForm = {
        fields: this.validFields.map((element) => {
          return {
            ...element,
            value: ["mobileNo", "mobile-number"].includes(element?.fieldType)
              ? element?.value === null || element?.value?.length < 12
                ? null
                : element?.value.replaceAll("-", "")
              : element?.value,
          };
        }),
        priority: prio ? prio : false,
        tags: this.tagLists,
        serviceId: this.selectedServiceId,
        serviceCategoryId: this.selectedServiceCategoryId,
      };

      if (this.queue) {
        // Edit Queue
        this.editQueue(bodyForm);
      } else {
        // Add Queue
        console.log(JSON.stringify(bodyForm));
        this.addQueue(bodyForm);
      }
    } else {
      this.submitting = false;
    }
  }

  addQueue(bodyForm) {
    this.httpService
      .post$(`queues/${this.branchId}/${this.selectedServiceId}`, bodyForm)
      .subscribe(
        (data) => {
          this.bsModalRef.hide();
          this.toastr.success("Queue has been added", "Success!", {
            positionClass: "toast-bottom-right-custom",
          });
          this.dataService.publishDataChange$({
            action: "added",
            queue: data?.data?._id,
          });
          setTimeout(() => {
            this.submitting = false;
          }, 500);
        },
        (error) => {
          console.log("error", error);
          this.bsModalRef.hide();
          const errorMsg = error.error.errors[0];
          if (errorMsg) {
            if (errorMsg.statusCode === 20101) {
              this.showQueueLimitModal(errorMsg);
            } else if (errorMsg.statusCode === 20102) {
              this.showQueueSubsLimitModal(errorMsg.limit);
            } else {
              this.toastr.error("Queue has not been added", "Failed!", {
                positionClass: "toast-bottom-right-custom",
              });
            }
          } else {
            this.toastr.error("Queue has not been added", "Failed!", {
              positionClass: "toast-bottom-right-custom",
            });
          }
          const x = {
            success: false,
            errors: [
              {
                source: "queue",
                statusCode: 20102,
                error: "CREATE_QUEUE_FAILED",
                msg: "Queue transaction limit reached.",
              },
            ],
          };
        },
      );
  }

  showQueueSubsLimitModal(limit: number = 1): void {
    const slots = `${limit} ${limit > 1 ? "queues" : "queue"} per month`;
    const initialState = {
      title: "Your business is doing so great",
      subTitle1:
        `that you’ve reached the maximum number of queues on your branch!`,
      subTitle2: `You have <b>${slots}</b> on your current plan`,
      subTitle3: `Upgrade your plan if you wish to add more queues 👍`,
    };

    this.bsModalRef = this.modalService.show(SubscriptionLimitModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  showQueueLimitModal(error) {
    const queueLimit = error.msg.match(/\d+/);
    const initialState = {
      queueLimit: queueLimit,
      serviceId: this.selectedServiceId,
      branchId: this.branchId,
    };
    this.bsModalRef = this.modalService.show(QueueLimitModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  editQueue(bodyForm) {
    this.httpService
      .patch$(`queues/${this.branchId}/${this.queue._id}`, bodyForm)
      .subscribe(
        (data) => {
          this.bsModalRef.hide();
          this.toastr.success("Queue has been edited", "Success!", {
            positionClass: "toast-bottom-right-custom",
          });
          this.successEvent.emit(data.data._id);
          this.dataService.publishDataChange$({
            action: "updated",
            queue: data?.data?._id,
          });
          setTimeout(() => {
            this.submitting = false;
          }, 500);
        },
        (error) => {
          console.log("error", error);
          this.toastr.error("Queue has not been added", "Failed!", {
            positionClass: "toast-bottom-right-custom",
          });
        },
      );
  }

  initValidators() {
    this.requiredValidators = {
      type: "required",
      value: Validators.required,
      errormsg: "This field is required",
    };
    this.mobileValidators = {
      // type: "minlength",
      // value: Validators.minLength(12),
      // errormsg: "Minimum length is 12",
      type: "pattern",
      value: Validators.pattern(/^9[0-9]{2}[-]?[0-9]{3}[-]?[0-9]{4}$/),
      errormsg: "Invalid mobile number format",
      //enhance regex for pattern
    };
    this.noNumberValidator = {
      type: "pattern",
      value: Validators.pattern(/^[A-Za-z- ñÑ.]*$/),
      errormsg: "Invalid name format",
      //enhance regex for pattern (name)
    };
  }

  getUniversalTag() {
    this.httpService
      .get$(`queues/universal-tag-set/${this.branchId}`)
      .toPromise()
      .then(
        (data) => {
          const dataFound = data.result.filter((tag) => !tag.deleted);
          this.universalTags = dataFound;
        },
        (error) => {
          console.log("error", error);
        },
      );
    this.isSelected = true;
  }

  async ngOnInit() {
    if (this.serviceLists) {
      await this.modifyServiceLists();
      this.getUniversalTag();
    }
    if (this.activeQueue) {
      this.queue = JSON.parse(JSON.stringify(this.activeQueue));

      await this.getServiceData();
      this.modifiedCategoryLists = [];
      this.getUniversalTag();
      this.modifiedCategoryLists = this.checkCategory();
      this.selectedServiceCategoryId = this.queue?.serviceCategoryId;
    } else {
      await this.initServiceData();
    }
    await this.initValidators();
    if (this.type === "Edit") {
      // for solaire

      this.selectedServiceCategoryName = this.categoryLists.find((category) =>
        category._id == this.selectedServiceCategoryId
      )?.displayName;

      if (this.selectedServiceCategoryName) {
        switch (
          this.selectedServiceCategoryName.split(" ").join("").toLowerCase()
        ) {
          case "1-2people":
            this.visitorMinMax = { min: 1, max: 2 };
            break;
          case "3-4people":
            this.visitorMinMax = { min: 3, max: 4 };
            break;
          case "5-6people":
            this.visitorMinMax = { min: 5, max: 6 };
            break;
          case "7+people":
            this.visitorMinMax = { min: 7, max: 100 };
            break;
          default:
            this.visitorMinMax = { min: 1, max: 100 };
            break;
        }
      }

      this.priorityIndex = this.queue?.fields.findIndex((x) =>
        x.fieldName == "priority"
      );
      if (this.priorityIndex > -1) {
        const prio = this.queue.fields[this.priorityIndex];
        if (prio.value == "true") {
          prio.value = true;
        } else {
          prio.value = false;
        }
      }
      this.canProceed = true;
    }
  }

  // PAL REQUEST
  openNextModal() {
    if (this.openSecondModal) {
      this.openSecondModal(); // Calls the parent's method to open the second modal
    }
  }

  // PAL REQUEST
  tagsInputFocused(event: any) {
    this.openTransactionsModal();
    this.isHidden = true;
  }

  // PAL REQUEST
  openTransactionsModal() {
    const initialState = {
      universalTags: this.universalTags,
      queueCurrentTags: this.tagLists,
    };

    this.bsModalRef2 = this.modalService.show(TransactionsModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
      backdrop: true,
      animated: false,
    });

    this.bsModalRef2.content.selectedTags.subscribe((data) => {
      console.log(data);
      this.isHidden = false;
      this.addTag(data);
    });

    this.bsModalRef2.content.closed.subscribe((data) => {
      this.isHidden = false;
    });
  }
}
