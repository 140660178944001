import { Component, OnInit , ElementRef, ViewChild, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent implements OnInit {

  private _isDragging: boolean;
  /*   result: any = []; */
    constructor() { }
  
    @ViewChild('fileSelector') public fileSelector: ElementRef;
    @Output() public selectedFiles = new EventEmitter<File[]>();
  
    public get isDragging(): boolean { return this._isDragging; }
  
    public onDrop(event: any): void {
      // Stop browser opening the file
      event.preventDefault();
      this._isDragging = false;
  
      const result =[];
      if (event.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (const item of event.dataTransfer.items) {
          if (item.kind !== 'file') {
            return;
          }
          result.push(item.getAsFile());
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        for (const file of event.dataTransfer.files) {
          result.push(file);
        }
      }
      this.emitFiles(result);
    }
  
    public onFilesSelected(event?: any): void {
  
      let fileData = event.target.files;
  
      if (fileData == undefined || fileData.length === 0) {
        return;
      }
  
      const result = [];
      for (const file of fileData) {
        result.push(file);
      }
  
      this.fileSelector.nativeElement.value = ''; // required to trigger (change) if user immediately uploads same named file
  
      this.emitFiles(result);
    }
  
    public onDragOver(event: any): void {
      // Stop browser opening the file
      event.preventDefault();
      this._isDragging = true;
    }
  
    public stopDrag(event: any): void {

      this._isDragging = false;
      event.preventDefault();
      event.stopPropagation();
  
    }

    private emitFiles(files: File[]): void {
      this.selectedFiles.emit(files);
    }

    ngOnInit(): void {
     
    }
}
