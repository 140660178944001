import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Injectable, EventEmitter } from '@angular/core';
import { AuthService } from "src/app/core/auth/auth.service";
import { DataService } from "src/app/core/services/data.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  modalEvent = new EventEmitter<boolean>();


  apiUrl = environment.urlAPI;
  accountData = this.dataService.accountData$;

  constructor(
    private http: HttpClient,
    public authService: AuthService,
    private dataService: DataService
  ) { }
  
  private get httpHeader(): any {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return {
      headers,
    };
  };

  /* get Appointment List*/
  public getAppointmentList(branchId: string, params: any): Observable<any> {
    const url = `${this.apiUrl}/appointment/${branchId}/list?approved=true&${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getAppointmentListData(branchId: string, params: any): Observable<any> {
    const url = `${this.apiUrl}/appointment/${branchId}/list?approved=true&limit=9999&${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getAppointmentListUpcoming(branchId: string, params: any): Observable<any> {
    const url = `${this.apiUrl}/appointment/${branchId}/filterlist?approved=true&limit=9999&${params}`;
    return this.http.get<any>(url, this.httpHeader);
  } 
  
  public getCountAppointment(branchId: string): Observable<any> {
    const url = `${this.apiUrl}/appointment/${branchId}/count-upcoming/appointment`;
    return this.http.get<any>(url, this.httpHeader);
  } 

  /* get team List*/ 
  public getTeamList(params, branchId): Observable<any> {
    const url = `${this.apiUrl}/accounts/${branchId}/list?${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }

    /* get Appointment List*/
  public getAppointmentListTomorrow(branchId: string, params: any): Observable<any> {
    const url = `${this.apiUrl}/appointment/${branchId}/list?approved=true&${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  /* get Appointment List*/
  public getAppointmentListWeek(branchId: string, params: any): Observable<any> {
    const url = `${this.apiUrl}/appointment/${branchId}/list?approved=true&${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  /* get Appointment List*/
  public getAppointmentListMonth(branchId: string, params: any): Observable<any> {
    const url = `${this.apiUrl}/appointment/${branchId}/list?approved=true&${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }
  
  /* get approved Appointment List*/
  public getForApprovedAppointmentList(branchId: string, params?: any): Observable<any> {
    const url = `${this.apiUrl}/appointment/${branchId}/list?approved=false&${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getForApprovedTimeslotList(branchId: string): Observable<any> {
    const url = `${this.apiUrl}/appointment/${branchId}/list?approved=false&limit=9999`;
    return this.http.get<any>(url, this.httpHeader);
  }

    /* get approved Appointment List*/
  public getForApprovedAppointmentListData(branchId: string): Observable<any> {
      const url = `${this.apiUrl}/appointment/${branchId}/list?approved=false&limit=9999`;
      return this.http.get<any>(url, this.httpHeader);
 }

 public getCountApprovalAppointment(branchId: string): Observable<any> {
  const url = `${this.apiUrl}/appointment/${branchId}/counts/for-approval`;
  return this.http.get<any>(url, this.httpHeader);
}

  /* get history Appointment List*/
  public getHistoryAppointmentList(branchId: string, params: any): Observable<any> {
      const url = `${this.apiUrl}/appointment/${branchId}/history/list?${params}`;
      return this.http.get<any>(url, this.httpHeader);
  }

  /* get history Appointment List*/
  public getHistoryAppointmentListData(branchId: string): Observable<any> {
      const url = `${this.apiUrl}/appointment/${branchId}/history/list?limit=9999`;
      return this.http.get<any>(url, this.httpHeader);
  }

  public getHistoryAppointmentDetails(branchId: string, appointmentId: string): Observable<any> {
    const url = `${this.apiUrl}/appointment/${branchId}/history/${appointmentId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  /* get service list */
  public getServiceList(branchId: string): Observable<any> {
    const url = `${this.apiUrl}/services/${branchId}/filter-list`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getServiceCategories(branchId): Observable<any> {
    const url = `${this.apiUrl}/service-category/${branchId}/list`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getServiceDetails(branchId, serviceId): Observable<any>{
    const url = `${this.apiUrl}/appointment/${branchId}/service-schedule/${serviceId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  /* get appointment counts */
  public getAppointmentCounts(branchId: string,params: any): Observable<any> {
    const url = `${this.apiUrl}/appointment/${branchId}/counts?filterBy=${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  /* get timeslot data counts */
  public getAppointmentTimeSlotData(data: any): Observable<any> {
      const url = `${this.apiUrl}/appointment/${data.branchId}/operating-days/counts/${data.categoryId}/${data.time}`;
      return this.http.get<any>(url, this.httpHeader);
  }

  /* get appointment category details */
  public getAppointmentCategoryDetails(branchId: string, serviceId: string): Observable<any> {
    const url = `${this.apiUrl}/appointment/${branchId}/service-schedule/${serviceId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  /* add appointment */
  public addAppointment(data: any): Observable<any> {
    const url = `${this.apiUrl}/appointment/${data.branchId}`;
    this.modalEvent.emit(true);
    return this.http.post<any>(url, data);
  }

  /* move the appointment to history */
  public editAppointment(data: any): Observable<any> {
    const url = `${this.apiUrl}/appointment/${data.branchId}/${data.appointmentId}`;
    return this.http.patch<any>(url, data);
  }

  /* move the appointment to history */
  public moveAppointment(branchId: string,data: any, appointmentId: string): Observable<any> {
    const url = `${this.apiUrl}/appointment/${branchId}/history/move/${appointmentId}`;
    return this.http.post<any>(url, data);
  }

  public approveDeclineAppointment(branchId,data): Observable<any> {
    const url = `${this.apiUrl}/appointment/${branchId}/pending-approval/${data.appointmentId}/${data.approved}`;
    return this.http.patch<any>(url, data);
  }

  public getAppointment(branchId, appointmentId): Observable<any>{
    const url = `${this.apiUrl}/appointment/${branchId}/${appointmentId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

}
