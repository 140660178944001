import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss']
})
export class InputTextareaComponent implements OnInit {
  @Input() label: string;
  @Input() value: string;
  @Input() validators: Array<any>;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() maxLength: string | number;
  @Input() inputGroupIcon: string;
  @Input() isDisabled: boolean;
  @Input() min: string | number;
  @Input() max: string | number;
  @Output() changedInput = new EventEmitter();
  inputEditForm: FormGroup;
  errorMsg: string;

  inputFocus: any;

  constructor(
    private fb: FormBuilder
  ) { }

  initForm(){
    this.inputEditForm = this.fb.group({
      inputName: [this.value, this.validators ? this.validators.map(element=>element.value): null]
    });

    this.placeholder = this.placeholder ? this.placeholder : 'Enter a Value';
  }

  get f(){
    return this.inputEditForm.controls;
  }

  change(){
    this.changedInput.emit({
      value: this.f['inputName'].value,
      valid: this.f['inputName'].valid
    });
    const errors = this.f['inputName'].errors;
    if(this.f['inputName'].errors){
      const keys = Object.keys(errors).toString();
      const find = this.validators.find(element=>element.type.includes(keys));
      this.errorMsg = find.errorMsg;
    }
  }

  blur(){
    this.inputFocus = undefined;
    this.change();
  }

  ngOnInit(): void {
    this.initForm();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.value){
      if(this.inputEditForm){
        this.inputEditForm.patchValue({
          inputName: this.value
        })
      }
    }
  }

}
