import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BranchManagementService } from 'src/app/modules/branch-management/services/branch-management.service';

@Component({
  selector: 'app-queue-limit-modal',
  templateUrl: './queue-limit-modal.component.html',
  styleUrls: ['./queue-limit-modal.component.scss']
})
export class QueueLimitModalComponent implements OnInit {

  queueLimit: string;
  serviceId: string;
  userRole: string;
  branchId: string;

  constructor(public bsModalRef: BsModalRef, private branchService: BranchManagementService, private router: Router) { }

  ngOnInit(): void {
    const b = this.branchService.getSelectedBranch();
    this.userRole = b.roleLevel;
  }

  goToService(){
    this.bsModalRef.hide();
    this.router.navigate(["service", this.branchId], {
      queryParams: { selectedServiceId: this.serviceId },
    });
  }
}
