import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataService } from '../services/data.service';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  urlAPI = environment.urlAPI;
  branchId = this.dataService.currentBranch$?.branchId;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private dataService: DataService
  ) { }

  private prepareHeader(headers: HttpHeaders | null): any {
    headers = headers || new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return {
      headers
    };
  }

  get$(param: string, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.get(this.urlAPI + '/' + param, expandedHeaders)
      .pipe(
        map(response => response)
      );
  }

  /*
  * @param route: string (route to the API)
  * @param params: HttpParams (query params)
  * */

  getV2$(route: string, params?: HttpParams, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.get(this.urlAPI + '/' + route, { params, headers: expandedHeaders.headers })
      .pipe(
        map(response => response)
      );
  }

  post$(param: string, data: any): Observable<any> {
    return this.http.post(this.urlAPI + '/' + param, data)
      .pipe(
        map(response => response)
      );
  }

  post2$(param: string, data: any): Observable<any> {
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Referrer-Policy': 'no-referrer-when-downgrade'
    });
    return this.http.post(param, data, {
      headers: headers
    })
      .pipe(
        map(response => response)
      );
  }

  put$(param: string, data: any): Observable<any> {
    return this.http.put(this.urlAPI + '/' + param, data)
      .pipe(
        map(response => response)
      );
  }

  patch$(param: string, data: any): Observable<any> {
    return this.http.patch(this.urlAPI + '/' + param, data)
      .pipe(
        map(response => response)
      );
  }

  delete$(param: string): Observable<any> {
    return this.http.delete(this.urlAPI + '/' + param)
      .pipe(
        map(response => response)
      );
  }

  uploadPhoto(param, imageFile) {
    return this.post$(param, '').toPromise().then(async (data) => {
      await this.uploadImageFile(imageFile, data);
      return data;
    }).catch(error => {
      console.log('error', error);
    });
  }

  uploadImageFile(imageFile, data) {
    const formData: any = new FormData();
    const photoData = data.data ? data.data : data.result;
    const param = photoData.url;
    for (const [key, value] of Object.entries(photoData.fields)) {
      formData.append(key, value);
    }
    formData.append('file', imageFile);
    return this.post2$(param, formData).toPromise().then(async (data) => {
      return data;
    }).catch(error => {
      console.log('error', error);
    });
  }

  addTag(type, singleTag, existingTags, serviceWindowId, queueId) {
    const tags = existingTags;
    tags.push(singleTag);
    const bodyForm = { tags: tags };
    return this.submitPatchForm(type, serviceWindowId, bodyForm, queueId).then(async () => {
      this.toastr.success('Tag has been added', 'Success!');
    }).catch(() => {
      this.toastr.error('Tag has not been added', 'Failed!');
    });
  }

  removeTag(type, singleTag, existingTags, serviceWindowId, queueId) {
    const tags = existingTags.filter(element => element !== singleTag);
    console.log('removed tags', tags);
    const bodyForm = { tags: tags };
    return this.submitPatchForm(type, serviceWindowId, bodyForm, queueId).then(async () => {
      this.toastr.error('Tag has been removed', 'Success!');
    }).catch(() => {
      this.toastr.error('Tag has not been removed', 'Failed!');
    });
  }

  submitPatchForm(type, serviceWindowId, bodyForm, queueId) {
    this.branchId = this.dataService.currentBranch$?.branchId;
    const param = type === 'service' ? `services/${this.branchId}/${serviceWindowId}` : `services/windows/${this.branchId}/${serviceWindowId}`;
    return this.patch$(param, bodyForm).toPromise().then(data => {
      console.log('submitPatchForm', data);
    }).catch(error => {
      console.log('error', error);
    });
  }

  getParam(type, serviceWindowId, queueId) {
    this.branchId = this.dataService.currentBranch$?.branchId;
    let param;
    switch (type) {
      case 'service': param = `services/${this.branchId}/${serviceWindowId}`;
        break;
      case 'window': param = `services/windows/${this.branchId}/${serviceWindowId}`;
        break;
      case 'window': param = `services/windows/${this.branchId}/${serviceWindowId}`;
        break;
    }
  }

  convertFormGroupToParams(formGroup: FormGroup): HttpParams {
    let params = new HttpParams();

    // Loop through each control in the form group
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);

      if (control.value !== null && control.value !== undefined) {
        // Add the control's value to the HttpParams
        params = params.append(key, control.value.toString());
      }

    });

    return params;
  }
}