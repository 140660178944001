import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-icons',
  templateUrl: './svg-icons.component.html',
  styleUrls: ['./svg-icons.component.scss']
})
export class SvgIconsComponent implements OnInit {

  @Input() iconStyle: string;
  @Input() addClass: string;
  @Input() color1: string;
  @Input() color2: string;
  @Input() size = 24;

  constructor() { }

  ngOnInit(): void {
  }

}
