import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-support-help-center-sub-sms",
  templateUrl: "./support-help-center-sub-sms.component.html",
  styleUrls: ["./support-help-center-sub-sms.component.scss"],
})
export class SupportHelpCenterSubSMSComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {}
}
