import { MapsAPILoader } from "@agm/core";
import {
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BranchManagementService } from "../../services/branch-management.service";
import { SubscriptionLimitModalComponent } from "src/app/modules/billing/components/subscription-limit-modal/subscription-limit-modal.component";
import { DataService } from "src/app/core/services/data.service";

@Component({
  selector: "app-add-branch-modal",
  templateUrl: "./add-branch-modal.component.html",
  styleUrls: ["./add-branch-modal.component.scss"],
})
export class AddBranchModalComponent implements OnInit {
  step = 1;

  branchForm = this.fb.group({
    email: ["", [Validators.pattern(/^\S+@\S+\.[a-zA-z]+$/)]],
    branch: ["", [Validators.required]],
    mobileNo: ["", [Validators.minLength(10)]],
    address: [""],
  });

  address: string;
  latitude = 14.547956768247687;
  longitude = 121.05131113274915;
  zoom = 17;

  branchData: any;

  @Output() successEvent = new EventEmitter();

  get f() {
    return this.branchForm.controls;
  }

  // @ViewChild("searchAddress", { static: false }) searchElementRef: any;

  @ViewChild("searchAddress") searchElementRef: ElementRef;

  private geoCoder;

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private branchService: BranchManagementService,
    private bsModalService: BsModalService
  ) {}

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();
    });
    console.log(this.branchData)
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: any) {
    console.log($event);

    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  blur() {
    const result = document.getElementsByClassName(
      "pac-container"
    ) as HTMLCollectionOf<HTMLElement>;
    result[0] ? (result[0].hidden = true) : "";
  }

  focus() {
    const result = document.getElementsByClassName(
      "pac-container"
    ) as HTMLCollectionOf<HTMLElement>;
    result[0] ? (result[0].hidden = false) : "";
  }

  proceedDetails() {
    console.log(this.branchForm.value);
    setTimeout(() => {
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );

      autocomplete.setComponentRestrictions({
        country: ["ph"],
      });

      console.log(this.searchElementRef.nativeElement);
      console.log(autocomplete);

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          const result = document.getElementsByClassName(
            "pac-container"
          ) as HTMLCollectionOf<HTMLElement>;
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return null;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.address = place.formatted_address;
          this.blur();

          this.zoom = 15;
        });
      });
    }, 500);

    this.step++;
  }

  submit(data) {
    const body = {
      name: this.branchForm.value.branch,
      about: "",
      email: this.branchForm.value.email,
      mobileNo: this.branchForm.value.mobileNo ? this.branchForm.value.mobileNo.replaceAll("-", "") : '',
      address: this.address,
      industryTypeId: this.branchData.industryTypeId,
      coordinates: {
        lat: this.latitude,
        lng: this.longitude
      }
    };

    this.blur();

    this.branchService.addBranch(body, this.branchData.businessId).subscribe(
      (res) => {
        console.log("ADD BRANCH SUCCESS", res);
        this.successEvent.emit(res);
        this.bsModalRef.hide();
      },
      (error) => {
        console.log("ADD BRANCH error", error);
        this.bsModalRef.hide();
        this.showLimitModal(error.error.errors.limit)
      }
    );
  }

  showLimitModal(limit: number = 1): void{
    const slots = `${limit} branch ${limit > 1 ? 'slots' : 'slot'}`
    const initialState = {
      title: "Your business is doing so great",
      subTitle1: `that you’ve reached the maximum number of branch on your business!`,
      subTitle2: `You have <b>${slots}</b> on your current plan`,
      subTitle3: `Upgrade your plan if you wish to add more branch 👍`
    };
    this.bsModalRef = this.bsModalService.show(SubscriptionLimitModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }
}
