import { TitleCasePipe } from "@angular/common";

const titleCasePipe = new TitleCasePipe();

export interface AccountLists {
  _id: string,
  name: string,
  avatarUrl?: string|null
}

export function getAccountName(accountLists: Array<AccountLists>, accountId: string) {
  let name = "";
  if (accountId && accountLists) {
    const account = accountLists.find((x) => x._id === accountId);
    if (account) {
      name = account.name;
      return titleCasePipe.transform(name);
    } else {
      return (name = "-");
    }
  }
}

export function getAccountAvatar(accountLists: Array<AccountLists>, accountId: string) {
  let avatar = "";
  if (accountId && accountLists) {
    const account = accountLists.find((x) => x._id === accountId);
    if (account) {
      avatar = account.avatarUrl;
    }
  }
  return avatar;
}
