import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { defaultAvatar } from "src/app/core/services/global";
import { BranchManagementService } from "../../services/branch-management.service";

@Component({
  selector: "app-edit-tv-modal",
  templateUrl: "./edit-tv-modal.component.html",
  styleUrls: ["./edit-tv-modal.component.scss"],
})
export class EditTvModalComponent implements OnInit {
  title: string;
  tvId: string;
  tvName: string;
  branchId: string;
  subTitle: string;
  @Output() successEvent = new EventEmitter();
  languages = [{
    value: 1,
    label: 'English',
    imageUrl: '/src/assets/kyoo/flag.png'
  }]

  defaultAvatar: string = defaultAvatar;
  isFocused: boolean;
  selectedLanguage = this.languages[0].label;
  isSelectClicked: boolean;
  isButtonClicked: boolean;
  focus = false;
  @ViewChild("language", { static: false }) language: any;
  
  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private branchService: BranchManagementService
  ) {}

  ngOnInit(): void {}

  submit() {
    const data = {
      deviceName: this.tvName
    };

    this.branchService
      .editTvDisplay(data, this.branchId, this.tvId)
      .subscribe((res) => {
        console.log("EDIT TV", res);
        this.successEvent.emit(res);
        this.bsModalRef.hide();
        this.toastr.success(`${this.tvName} has been updated`, "Success!");
      });
  }

  select() {
    this.isSelectClicked = !this.isSelectClicked;
    this.isFocused = !this.isFocused;
    // if (!this.isFocused) {
    //   this.isSelectClicked = false;
    // }
  }

  @HostListener("document:click")
  deselect() {
    if (!this.isSelectClicked || this.isButtonClicked) {
      this.isFocused = false;
    }
    this.isButtonClicked = false;
    this.isSelectClicked = false;
  }

  selectDropdown(list) {
    this.selectedLanguage = list.label;

    this.isButtonClicked = true;
    if (!this.isFocused) {
      this.isButtonClicked = false;
    }
  }

  focusBlurPassword(isFocused) {
    this.focus = isFocused;
    this.language.nativeElement.className =
      "input-group input-group-merge mr-2";
    if (isFocused) {
      this.language.nativeElement.className =
        "input-group input-group-merge mr-2 focused";
    }
  }
}
