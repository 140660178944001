import { HttpParams } from "@angular/common/http";
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  HostListener,
  ChangeDetectorRef,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { UIChart } from "primeng/chart";
import {
  DateFilters,
  IMonitoringCardData,
  MonitoringDataTypes,
} from "src/app/modules/monitoring/interfaces/monitoring.interface";
import { TeamManagementService } from "src/app/modules/team-management/services/team-management.service";
import { QueueHistoryService } from "src/app/modules/queue-history/services/queue-history.service";
import { Subject, Subscription, throwError } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  takeUntil,
  tap,
} from "rxjs/operators";
import {
  convertMillisecondsToTime,
  getDuration,
} from "src/app/shared/helpers/time.helpers";
import { calculateQueuePercentage } from "src/app/shared/helpers/percentage.helpers";
import { MonitoringService } from "src/app/modules/monitoring/services/monitoring.service";
import { getAccountName } from "src/app/shared/helpers/team-management.helpers";
import { HttpService } from "src/app/core/http/http.service";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { ProcedureService } from "src/app/modules/service-procedure/services/procedure.service";
import { DatePipe } from "@angular/common";
import { defaultWeeklyLabel } from "src/app/modules/monitoring/constants/monitoring.constants";

interface CheckboxList {
  value: string;
  label: string;
  checked?: boolean;
}

interface CheckboxData {
  key: string;
  checkedArray: number[];
}

interface LabelData {
  label: string;
  value?: number;
  percentage?: string;
  caption?: string;
  color: string;
}

const defaultMonitoringCardData: IMonitoringCardData = {
  leftDetails: {},
  rightDetails: {},
};

@Component({
  selector: "app-visitor-volumes-tab-page",
  templateUrl: "./visitor-volumes-tab-page.component.html",
  styleUrls: ["./visitor-volumes-tab-page.component.scss"],
})
export class VisitorVolumesTabPageComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @Input() branchId: string = "";
  @Input() selectedValue: string = "";
  @Input() selectedLabel: string = "";
  @Input() customDate1: string = "";
  @Input() customDate2: string = "";
  @Input() selectedServiceId: string = "";
  @ViewChild("queueSourceChart") chart: UIChart;
  isLoading: boolean = false;
  accountLists: Array<any> = [];
  serviceLists: Array<any> = [];
  queueData: {
    onGoing: IMonitoringCardData;
    currentServing: IMonitoringCardData;
    served: IMonitoringCardData;
    visitorArrivalTrend: IMonitoringCardData;
  } = {
    onGoing: { ...defaultMonitoringCardData },
    currentServing: { ...defaultMonitoringCardData },
    served: { ...defaultMonitoringCardData },
    visitorArrivalTrend: { ...defaultMonitoringCardData },
  };
  doughnutData: {} = {};
  doughnutOptions: {} = {};
  totalQueuesChartData: {} = {};
  totalQueuesChartOptions: {} = {};
  totalQueuesChartLabel: string[] = [];
  queueSourceData: Array<LabelData> = [
    {
      label: "Added via Branch Site (Online)",
      value: 0,
      percentage: "",
      color: "#1791CF",
    },
    {
      label: "Added via Kiosk",
      value: 0,
      percentage: "",
      color: "#FE7401",
    },
    {
      label: "Added by Staff",
      value: 0,
      percentage: "",
      color: "#FFD11A",
    },
  ];
  totalQueuesData: Array<LabelData> = [
    {
      label: "Completed",
      color: "#26D926",
    },
    {
      label: "Ongoing",
      color: "#FFD11A",
    },
    {
      label: "Removed",
      color: "#FF3B30",
    },
    {
      label: "Cancelled",
      color: "#FFCDCD",
    },
  ];
  doughnutBgColor: string[] = [];
  doughnutHoverBgColor: string[] = [];
  subscriptions = new Subscription();
  queueHistory = {
    pages: 1,
    success: true,
    total: 1,
    data: [],
  };
  itemsPerPage = 10;
  currentPage = 1;
  items = 100;
  searchSubject: Subject<string> = new Subject();
  filterSubject: Subject<any> = new Subject();
  searchString: string | null;
  noOfEntries: number[] = [10, 20, 30];
  noOfPages: number[] = [];
  selectedStaffs: Array<any> = [];
  selectedService: Array<any> = [];
  selectedStatus: Array<any> = [];
  selectAllStaffs: boolean = false;
  accountCheckboxLists: Array<CheckboxList> = [];
  serviceCheckboxLists: Array<CheckboxList> = [];
  statusCheckboxLists: Array<CheckboxList> = [];
  selectedAccounts: number = 0;
  selectedServices: number = 0;
  selectedStatuses: number = 0;
  statusLists = [
    { value: "completed", label: "Completed" },
    { value: "removed", label: "No Show (Removed)" },
    { value: "cancelled", label: "Cancelled" },
  ];
  private visitorVolumesSubjectAPI$ = new Subject<void>();
  dateLabel: string = "";
  procedureTemplates: number = 0;
  currentMonth: string = "";
  isSingleDate: boolean = false;

  constructor(
    private httpService: HttpService,
    private monitoringService: MonitoringService,
    private queueHistoryService: QueueHistoryService,
    private teamService: TeamManagementService,
    private procedureService: ProcedureService,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private datePipe: DatePipe
  ) {
    this.currentMonth = this.getCurrentMonth();
    this.initializeChart();
    this.supplyCardDetails();
    this.setStatusData(this.statusLists);
  }

  getCurrentMonth(): string {
    const currentDate = new Date();
    const currentMonth = this.datePipe
      .transform(currentDate, "MMMM")
      .toUpperCase();
    return currentMonth;
  }

  initializeChart() {
    this.totalQueuesChartData = {};
    this.totalQueuesChartOptions = {
      responsive: true,
      legend: {
        display: false,
      },
      datasets: {
        bar: {
          barPercentage: 0.1,
          barThickness: 8,
          min: 0,
        },
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              fontSize: 12,
              fontColor: "#191444",
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              fontSize: 12,
              fontColor: "#191444",
              min: 0,
              stepSize: 5,
              suggestedMax: 60,
            },
          },
        ],
      },
    };

    this.queueSourceData.forEach((item) => {
      item.value = 0;
      item.percentage = "0%";
    });

    this.doughnutData = {};
    this.doughnutOptions = {
      cutoutPercentage: 75,
      responsive: true,
      legend: {
        display: false,
      },
    };
  }

  supplyCardDetails() {
    this.queueData.onGoing.leftDetails = {
      ...this.queueData.onGoing.leftDetails,
      value: 0,
      formattedValue: 0,
    };

    this.queueData.currentServing.leftDetails = {
      ...this.queueData.currentServing.leftDetails,
      value: 0,
      formattedValue: 0,
    };

    this.queueData.served.leftDetails = {
      ...this.queueData.served.leftDetails,
      value: 0,
      percentage: null,
      counts: { today: 0, yesterday: 0 },
    };

    this.queueData.visitorArrivalTrend.leftDetails = {
      ...this.queueData.visitorArrivalTrend.leftDetails,
      value: 0,
      percentage: null,
      counts: { today: 0, yesterday: 0 },
    };

    this.queueData.onGoing.rightDetails = {
      isSvg: false,
      style: "ic_timer",
      size: 24,
      color: "#886D00",
      bgColor: "#FFF3CD",
      addClass: "",
    };
    this.queueData.currentServing.rightDetails = {
      isSvg: true,
      style: "ic_message-circle",
      size: 24,
      color: "#1791CF",
      bgColor: "#CDEEFF",
      addClass: "mt-1",
    };
    this.queueData.served.rightDetails = {
      isSvg: false,
      style: "ic_people",
      size: 24,
      color: "#104A19",
      bgColor: "#EFFBF1",
      addClass: "",
    };
    this.queueData.visitorArrivalTrend.rightDetails = {
      isSvg: false,
      style: "ic_trending-up",
      size: 28,
      color: "#191444",
      bgColor: "#F9FBFD",
      addClass: "ml-1 mt-3",
    };
  }

  get historyParams() {
    let params = new HttpParams();
    params = params.append("limit", this.itemsPerPage.toString());
    const offset = this.currentPage * this.itemsPerPage - this.itemsPerPage;
    params = params.append("offset", offset.toString());
    if (this.searchString) {
      params = params.append("search", this.searchString);
    }
    if (this.selectedStaffs.length > 0) {
      params = params.append("servedBy", this.selectedStaffs.join(","));
    }
    if (this.selectedStatus.length > 0) {
      params = params.append("status", this.selectedStatus.join(","));
    }
    if (this.selectedService.length > 0) {
      params = params.append("serviceId", this.selectedService.join(","));
    }
    if (this.selectedValue === "custom") {
      params = params.append(
        "startDate",
        new Date(this.customDate1).setHours(0, 0, 0).toString()
      );
      params = params.append(
        "endDate",
        new Date(this.customDate2).setHours(23, 59, 59).toString()
      );
    } else {
      params = params.append("filterBy", this.selectedValue);
    }
    return params;
  }

  get isSearch() {
    if (this.searchString) {
      return true;
    }
    if (this.selectedStaffs.length > 0) {
      return true;
    }
    if (this.selectedStatus.length > 0) {
      return true;
    }
    if (this.selectedService.length > 0) {
      return true;
    }
  }

  ngOnInit(): void {
    this.visitorVolumesData();
    this.getAccounts(this.branchId);
    this.getServiceLists(this.branchId);
    this.getServiceProcedureTemplateLists(this.branchId);
    this.getQueueHistory(this.branchId);
    this.searchSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.getQueueHistory(this.branchId);
      });

    this.filterSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.getQueueHistory(this.branchId);
      });
  }

  visitorVolumesData() {
    this.isLoading = true;

    this.isSingleDate =
      (this.selectedValue === "custom" && 
        ((this.customDate1 && this.customDate2) &&
        this.customDate1 === this.customDate2));

    let dateFilter: DateFilters | string;
    if (this.selectedValue === "custom") {
      const startDate = new Date(this.customDate1).setHours(0, 0, 0);
      const endDate = new Date(this.customDate2).setHours(23, 59, 59);
      dateFilter = { startDate, endDate };
    } else {
      dateFilter = this.selectedValue;
    }
    const datatypes: MonitoringDataTypes = {
      branchId: this.branchId,
      dateFilter: dateFilter,
      selectedServiceId: this.selectedServiceId,
    };
    this.subscriptions.add(
      this.monitoringService
        .getVisitorVolumesData(datatypes, this.isSingleDate)
        .pipe(
          takeUntil(this.visitorVolumesSubjectAPI$),
          map((res) => res.data),
          tap((data) => {
            this.setQueueDetails(data);
          }),
          catchError((error) => {
            console.log("error on fetching visitor volumes data", error);
            return throwError(error);
          }),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe()
    );
  }

  getAccounts(branchId: string) {
    this.subscriptions.add(
      this.teamService
        .getTeamList("", branchId)
        .pipe(
          takeUntil(this.visitorVolumesSubjectAPI$),
          map((res) => res.data),
          tap((data) => {
            this.accountLists = data.map((element) => {
              return {
                _id: element._id,
                name: element.firstName + " " + element.lastName,
                avatarUrl: element.avatarUrl,
              };
            });
            this.setAccountData(this.accountLists);
          }),
          catchError((error) => {
            console.log("error on fetching account lists", error);
            this.isLoading = false;
            return throwError(error);
          }),
        )
        .subscribe()
    );
  }

  getServiceLists(branchId: string) {
    this.subscriptions.add(
      this.httpService
        .get$(`services/${branchId}?limit=999`)
        .pipe(
          takeUntil(this.visitorVolumesSubjectAPI$),
          map((res) => res.data), // for extraction of data
          tap((data) => {
            this.serviceLists = data.map((element) => ({
              _id: element._id,
              name: element.displayName,
            }));
            this.setServiceData(this.serviceLists);
          }),
          catchError((error) => {
            console.log("Error on fetching branch details", error);
            this.isLoading = false;
            return throwError(error);
          })
        )
        .subscribe()
    );
  }

  getServiceProcedureTemplateLists(branchId: string) {
    this.subscriptions.add(
      this.procedureService
        .getProcedureTemplates(branchId)
        .pipe(
          takeUntil(this.visitorVolumesSubjectAPI$),
          map((res) => res.data.data), // for extraction of data
          tap((data) => {
            this.procedureTemplates = data?.length;
          }),
          catchError((error) => {
            console.log("Error on fetching service procedure data", error);
            this.isLoading = false;
            return throwError(error);
          })
        )
        .subscribe()
    );
  }

  getQueueHistory(branchId: string) {
    this.subscriptions.add(
      this.queueHistoryService
        .getQueueHistory(this.historyParams, branchId)
        .pipe(
          takeUntil(this.visitorVolumesSubjectAPI$),
          map((res) => res),
          tap((data) => {
            this.queueHistory = data;
            const pages = data.pages;
            if (pages == 0) {
              this.noOfPages.push(1);
            } else {
              this.noOfPages = Array.from({ length: pages }, (_, i) => i + 1);
            }
          }),
          catchError((error) => {
            console.log("error on fetching queue history lists", error);
            return throwError(error);
          }),
        )
        .subscribe()
    );
  }

  public searchHistory() {
    this.searchSubject.next(this.searchString);
    // this.getQueueHistory(this.branchId);
  }

  pageChange(event: PageChangedEvent) {
    this.currentPage = event.page;
    this.filterSubject.next(this.currentPage);
    this.getQueueHistory(this.branchId);
  }

  jumpToPage(page: string) {
    this.currentPage = parseInt(page);
    this.filterSubject.next(this.currentPage);
    this.getQueueHistory(this.branchId);
    this.cdr.detectChanges();
  }

  entriesChange(a) {
    this.itemsPerPage = a;
    this.filterSubject.next(this.itemsPerPage);
    this.getQueueHistory(this.branchId);
  }

  setQueueDetails(data: any) {
    this.setChartData(data.queueSource, data.totalQueues.totalCountsGraph);

    this.queueData.onGoing.leftDetails = {
      ...this.queueData.onGoing.leftDetails,
      value: data.onGoing ? data.onGoing.counts : 0,
      formattedValue:
        data.onGoing && data.onGoing.avgWaitingTime > 0
          ? this.convertMillisecondsToTime(data.onGoing.avgWaitingTime)
          : 0,
    };

    this.queueData.currentServing.leftDetails = {
      ...this.queueData.currentServing.leftDetails,
      value: data.currentServing ? data.currentServing.counts : 0,
      formattedValue:
        data.currentServing && data.currentServing.avgServingTime > 0
          ? this.convertMillisecondsToTime(data.currentServing.avgServingTime)
          : 0,
    };

    this.queueData.served.leftDetails = {
      ...this.queueData.served.leftDetails,
      value: data.served ? data.served.current : 0,
      percentage: calculateQueuePercentage(
        data.served.current,
        data.served.previous
      ),
      counts: {
        today: data.served.current,
        yesterday: data.served.previous,
      },
    };

    this.queueData.visitorArrivalTrend.leftDetails = {
      ...this.queueData.visitorArrivalTrend.leftDetails,
      value: data.visitorArrivalTrend ? data.visitorArrivalTrend.current : 0,
      percentage: calculateQueuePercentage(
        data.visitorArrivalTrend.current,
        data.visitorArrivalTrend.previous
      ),
      counts: {
        today: data.visitorArrivalTrend.current,
        yesterday: data.visitorArrivalTrend.previous,
      },
    };
    this.setDateLabel();
    this.cdr.detectChanges();
  }

  setChartData(queueSource: Array<any>, totalQueues: Array<any>) {
    this.setQueueSourceData("label", queueSource);
    this.doughnutData = {
      labels: this.queueSourceData.map((item) => item.label),
      datasets: [
        {
          data: queueSource ? this.setQueueSourceData("data", queueSource) : [],
          backgroundColor: this.setQueueSourceBgColor("bgColor"),
          hoverBackgroundColor: this.setQueueSourceBgColor("hoverBgColor"),
          borderWidth: 0,
          borderAlign: "inner",
        },
      ],
    };

    this.totalQueuesChartData = {
      labels: totalQueues ? this.loopData(totalQueues, 0) : [],
      datasets: [
        {
          label: this.totalQueuesData[0].label,
          backgroundColor: this.totalQueuesData[0].color,
          data: totalQueues ? this.loopData(totalQueues, 1) : [], //completed
          barPercentage: 0.1,
          barThickness: 8,
        },
        {
          label: this.totalQueuesData[1].label,
          backgroundColor: this.totalQueuesData[1].color,
          data: totalQueues ? this.loopData(totalQueues, 2) : [], //onGoing
          barPercentage: 0.1,
          barThickness: 8,
        },
        {
          label: this.totalQueuesData[2].label,
          backgroundColor: this.totalQueuesData[2].color,
          data: totalQueues ? this.loopData(totalQueues, 3) : [], //removed
          barPercentage: 0.1,
          barThickness: 8,
        },
        {
          label: this.totalQueuesData[3].label,
          backgroundColor: this.totalQueuesData[3].color,
          data: totalQueues ? this.loopData(totalQueues, 4) : [], //Cancelled
          barPercentage: 0.1,
          barThickness: 8,
        },
      ],
    };
  }

  setQueueSourceData(purpose: string, data: any): string[] | void {
    const { bizPortal, branchSite, kiosk } = data;
    const queueSource = [
      { count: branchSite.count, percentage: branchSite.percentage },
      { count: kiosk.count, percentage: kiosk.percentage },
      { count: bizPortal.count, percentage: bizPortal.percentage },
    ];
    if (purpose == "label") {
      this.queueSourceData.forEach((item, index) => {
        item.value = queueSource[index].count;
        if (queueSource[index].percentage !== null) {
          item.percentage =
            queueSource[index].percentage % 1 === 0
              ? queueSource[index].percentage.toFixed(0)
              : queueSource[index].percentage.toFixed(2);
          item.percentage = item.percentage + "%";
        } else {
          item.percentage = "0%";
        }
      });
    } else {
      const result = queueSource.map((item) => item.count);
      return result;
    }
  }

  setQueueSourceBgColor(purpose: string) {
    let result: string[] = [];
    if (purpose == "bgColor") {
      result = this.queueSourceData.map((item) => item.color);
    } else {
      result = ["#CDEEFF", "#FFF2E7", "#FFF3CD"];
    }
    return result;
  }

  loopData(data: any, label: number) {
    let newData = [];
    data.forEach((element, index) => {
      switch (label) {
        case 0: //label
          if (
            this.selectedValue == "today" ||
            this.selectedValue == "yesterday"
          ) {
            // newData.push(this.formatTime2(element.time));
            newData.push(element.time);
          } else if (this.selectedValue == "this-week") {
            newData = defaultWeeklyLabel;
          } else if (this.selectedValue == "this-month") {
            newData.push(index + 1);
          } else if (this.selectedValue == "custom") {
            if (this.isSingleDate) {
              newData.push(element.time);
            } else {
              let idx: number = element.date.lastIndexOf(",");
              let date1 = element.date.substring(idx, idx - 3);
              let date2 = date1.replace(/\D/g, "");
              newData.push(date2.trim());
            }
          }
          break;
        case 1: //completed
          newData.push(element.completed);
          break;
        case 2: //onGoing
          newData.push(element.onGoing);
          break;
        case 3: //removed
          newData.push(element.removed);
          break;
        case 4: //cancelled
          newData.push(element.cancelled);
          break;
        default:
          break;
      }
    });

    if (newData.length > 0) {
      if (label == 0 && this.selectedValue == "custom" && !this.isSingleDate) {
        newData.shift();
      }
      if (label == 1) {
        if (this.selectedValue == "custom" && !this.isSingleDate) {
          newData.shift();
        }
        //  else if (this.selectedValue == "this-week") {
        //   let fillWeekly = newData.concat(
        //     Array.from({ length: 7 - newData.length }).fill(0)
        //   );
        //   newData = fillWeekly.slice(1).concat(fillWeekly[0]);
        // }
      }
    }

    return newData;
  }


  formatTime2(time: string): string {
    const [hour, period] = time?.split(/(?<=\d)(am|pm)/);
    return `${hour}:00 ${period}`.toUpperCase();
  }

  convertMillisToMins(milliseconds: number) {
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const seconds = Math.floor((milliseconds / 1000) % 60);
    return (minutes + seconds / 60).toFixed(2);
  }

  get queueSourceTotalValue(): number {
    let sum: number = 0;
    sum = this.queueSourceData.reduce((acc, data) => acc + data.value, 0);
    return sum;
  }

  setMonthDays() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const numberOfDays = new Date(currentYear, currentMonth, 0).getDate();
    return Array.from({ length: numberOfDays }, (_, i) => (i + 1).toString());
  }

  getAccountName(accountId: string) {
    return getAccountName(this.accountLists, accountId);
  }

  convertMillisecondsToTime(milliseconds: number): string | number {
    return convertMillisecondsToTime(milliseconds);
  }

  getDuration(milliseconds: number) {
    return getDuration(milliseconds);
  }

  setAccountData(accountData: any) {
    const accountsLists = accountData.map((element) => {
      return {
        value: element._id,
        label: element.name,
      };
    });
    this.accountCheckboxLists = this.addCheckedProperty(accountsLists);
  }

  setServiceData(serviceData: any) {
    const serviceLists = serviceData.map((element) => {
      return {
        value: element._id,
        label: element.name,
      };
    });
    this.serviceCheckboxLists = this.addCheckedProperty(serviceLists);
  }

  setStatusData(statusData: any) {
    this.statusCheckboxLists = this.addCheckedProperty(statusData);
  }

  addCheckedProperty(contents: Array<CheckboxList>) {
    if (contents) {
      const newContents = contents.map((content) => ({
        ...content,
        checked: false, // for checkbox
      }));
      return newContents;
    }
  }

  changeCheckboxList($event: CheckboxData) {
    if ($event) {
      const key = $event?.key;
      const selected = $event.checkedArray;
      switch (key) {
        case "staff":
          this.selectedAccounts = selected.length;
          this.selectedStaffs = [];
          if (this.selectedAccounts > 0) {
            selected.forEach((element) => {
              this.selectedStaffs.push(this.accountLists[element]._id);
            });
          }
          this.filterSubject.next(this.selectedStaffs);
          break;
        case "service":
          this.selectedServices = selected.length;
          this.selectedService = [];
          if (this.selectedServices > 0) {
            selected.forEach((element) => {
              this.selectedService.push(this.serviceLists[element]._id);
            });
          }
          this.filterSubject.next(this.selectedService);
          break;
        case "status":
          this.selectedStatuses = selected.length;
          this.selectedStatus = [];
          if (this.selectedStatuses > 0) {
            selected.forEach((element) => {
              this.selectedStatus.push(this.statusLists[element].value);
            });
          }
          this.filterSubject.next(this.selectedStatus);
          break;
        default:
          break;
      }
      this.getQueueHistory(this.branchId);
    }
  }

  setDateLabel() {
    switch (this.selectedValue) {
      case "today":
        this.dateLabel = "yesterday";
        break;
      case "yesterday":
        this.dateLabel = "two days ago";
        break;
      case "this-week":
        this.dateLabel = "last week";
        break;
      case "this-month":
        this.dateLabel = "last month";
        break;
      default:
        this.dateLabel = "";
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const branchIdChange = changes.branchId;
    const selectedValueChange = changes.selectedValue;
    const customDate1Change = changes.customDate1;
    const customDate2Change = changes.customDate2;
    const selectedServiceIdChange = changes.selectedServiceId;
  
    if (
      (branchIdChange && this.isValueChanged(branchIdChange.previousValue, branchIdChange.currentValue)) ||
      (selectedValueChange && this.isValueChanged(selectedValueChange.previousValue, selectedValueChange.currentValue)) ||
      (customDate1Change && this.isValueChanged(customDate1Change.previousValue, customDate1Change.currentValue)) ||
      (customDate2Change && this.isValueChanged(customDate2Change.previousValue, customDate2Change.currentValue)) ||
      (selectedServiceIdChange && this.isValueChanged(selectedServiceIdChange.previousValue, selectedServiceIdChange.currentValue))
    ) {
      this.visitorVolumesData();
      this.getQueueHistory(this.branchId);
    }
  }

  private isValueChanged(previousValue: any, currentValue: any): boolean {
    return previousValue !== undefined && previousValue !== currentValue;
  }

  public staffDropdownVisible: boolean = false;
  dropdownsVisible: { [key: string]: boolean } = {}; // Object to track dropdown visibility
  @ViewChild("staffDropdownRef", { static: false })
  staffDropdown: ElementRef<HTMLDivElement>;
  @ViewChild("serviceDropdownRef", { static: false })
  serviceDropdown: ElementRef<HTMLDivElement>;
  @ViewChild("statusDropdownRef", { static: false })
  statusDropdown: ElementRef<HTMLDivElement>;

  ngAfterViewInit(): void {
    if (this.staffDropdown) {
      this.staffDropdown.nativeElement;
    }
    if (this.serviceDropdown) {
      this.serviceDropdown.nativeElement;
    }
    if (this.statusDropdown) {
      this.statusDropdown.nativeElement;
    }
  }

  toggleDropdown(dropdownId: string): void {
    // Close all other dropdowns before opening the current dropdown
    Object.keys(this.dropdownsVisible).forEach((id) => {
      if (id !== dropdownId) {
        this.closeDropdown(id);
      }
    });

    if (this.isDropdownVisible(dropdownId)) {
      this.closeDropdown(dropdownId);
    } else {
      this.openDropdown(dropdownId);
    }
  }

  isDropdownVisible(dropdownId: string): boolean {
    return this.dropdownsVisible[dropdownId] ?? false; // Return the visibility state for the given dropdown ID
  }

  openDropdown(dropdownId: string): void {
    // Close all other dropdowns before opening the current dropdown
    Object.keys(this.dropdownsVisible).forEach((id) => {
      if (id !== dropdownId) {
        this.closeDropdown(id);
      }
    });

    this.dropdownsVisible[dropdownId] = true;
  }

  closeDropdown(dropdownId: string): void {
    this.dropdownsVisible[dropdownId] = false; // Set the visibility state for the given dropdown ID to false
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent): void {
    const clickedElement = event.target as HTMLElement;

    for (const dropdownId in this.dropdownsVisible) {
      if (this.dropdownsVisible.hasOwnProperty(dropdownId)) {
        const dropdownRef = this.elementRef.nativeElement.querySelector(
          `#${dropdownId}`
        );
        const dropdownMenuRef = this.elementRef.nativeElement.querySelector(
          `#${dropdownId}-menu`
        );

        if (dropdownRef && dropdownRef.contains(clickedElement)) {
          return;
        }

        if (dropdownMenuRef && dropdownMenuRef.contains(clickedElement)) {
          return;
        }
      }
    }
    this.dropdownsVisible = {};
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.visitorVolumesSubjectAPI$.next();
    this.visitorVolumesSubjectAPI$.complete();
    document.removeEventListener("click", this.onDocumentClick.bind(this));
  }
}
