import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/core/auth/auth.service";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { ModalService } from "src/app/core/services/modal.service";

@Component({
  selector: "app-modal-filter",
  templateUrl: "./modal-filter.component.html",
  styleUrls: ["./modal-filter.component.scss"],
})
export class ModalFilterComponent extends ModalService implements OnInit {
  selectedSortFilter: any;
  dropdownSort: any;
  dropdownFilter: any;
  filterParamArray: Array<any>;
  filterParam: any;
  isClearValue: boolean;
  branchId: any;

  constructor(
    bsModalRef: BsModalRef,
    httpService: HttpService,
    dataService: DataService,
    toastr: ToastrService,
    authService: AuthService
  ) {
    super(bsModalRef, httpService, dataService, toastr, authService);
  }

  initFilters() {
    this.dropdownSort = Object.values(
      this.selectedSortFilter.find(
        (element) => Object.keys(element)[0] === "sort"
      )
    )[0];
    this.dropdownFilter = Object.values(
      this.selectedSortFilter.find(
        (element) => Object.keys(element)[0] === "filter"
      )
    )[0];

    if (
      !this.filterParamArray ||
      (this.filterParamArray && this.filterParamArray.length === 0)
    ) {
      let tags = [];
      let sort = "";
      if (this.filterParam && this.filterParam.tags) {
        tags = this.filterParam.tags;
      }
      if (this.filterParam && this.filterParam.sort) {
        sort = this.filterParam.sort;
      }
      this.filterParamArray = [
        {
          param: "tags",
          value: tags,
        },
        {
          param: "sort",
          value: sort,
        },
      ];
    }
  }

  applyFilter() {
    this.dataService.publishSortFilters$(this.filterParamArray);
    this.bsModalRef.hide();
  }

  clearFilter() {
    this.isClearValue = !this.isClearValue;
    this.filterParamArray = [
      {
        param: "tags",
        value: "",
      },
      {
        param: "sort",
        value: "",
      },
    ];
  }

  selectSort($event) {
    if (this.filterParamArray) {
      const waitingTime = this.filterParamArray.find((x) => x.param === "sort");
      if (waitingTime) {
        if ($event.includes("Shortest")) {
          waitingTime.value = "waitingTime:asc";
        } else {
          waitingTime.value = "waitingTime:desc";
        }
      }
    }
  }

  selectFilter(filter, $event) {
    const selectedFilterValues = $event.map((element) => element.value);
    this.filterParamArray = this.filterParamArray.map((element) => {
      return {
        ...element,
        value:
          element.param === filter?.value
            ? selectedFilterValues
            : element?.value,
      };
    });
  }

  ngOnInit(): void {
    this.initFilters();
  }
}
