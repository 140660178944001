import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../services/settings.service';
import { Subject, Subscription, throwError } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import * as _ from 'lodash';
import { catchError, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { TitleCasePipe } from '@angular/common';
import { ICustomFormFields } from '../../interfaces/custom-form-fields';

@Component({
  selector: 'app-custom-form-fields',
  templateUrl: './custom-form-fields.component.html',
  styleUrls: ['./custom-form-fields.component.scss'],
  providers: [TitleCasePipe]
})
export class CustomFormFieldsComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  isLoading: boolean = false;
  mainLists: Array<any>;
  isCheckboxDisabled: boolean;
  changedItem = new EventEmitter();
  toggleCheckboxForm: FormGroup;
  filteredCheckbox: Array<any>;
  patchedLists: Array<any>;
  isConfirmDisabled: boolean;
  branchId: any;
  initDefaultSmartForm: any;
  defaultSmartForm: any;
  smartFormId: any;
  isChanged: boolean;
  fieldData: any;
  customServiceSmartFormId: string = '';
  hiddenFields: any = [
    'name',
    'mobileNo',
    // 'mobile-number',
    'visitors',
    'priority'
  ];
  private customFormFieldSubPageSubjectAPI$ = new Subject<void>();
  hasError: boolean = false;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private settingsService: SettingsService,
    private dataService: DataService,
    private titleCasePipe: TitleCasePipe
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.subscribeToBranchChanges();
    this.fetchCustomSmartForm();
    this.initForm();
  }

  private subscribeToBranchChanges(): void {
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe((res) => {
        this.branchId = res ? res._id : this.dataService.currentBranch$?.branchId;
      })
    );
  }

  private fetchCustomSmartForm(): void {
    this.subscriptions.add(
      this.settingsService
        .customSmartForms('GET', this.branchId)
        .pipe(
          takeUntil(this.customFormFieldSubPageSubjectAPI$),
          map((res) => res.result),
          tap((data) => {
            if (data) {
              this.customServiceSmartFormId = data._id;
              this.mainLists = data.fields;
            }
          }),
          catchError((error) => {
            console.error("Error on fetching custom form fields", error);
            return throwError(error);
          }),
          finalize(() => {
            this.initForm();
            this.isLoading = false;
          })
        )
        .subscribe()
    );
  }

  initForm(): void {
    this.toggleCheckboxForm = this.fb.group({
      itemArray: this.fb.array([])
    });
    this.createPerItem();
  }

  createPerItem(): void {
    if (this.mainLists && this.mainLists.length > 0) {
      this.mainLists.forEach((element, i) => {
        this.itemArray.push(
          this.fb.group(
            {
              enabled: element.enabled,
              label: [element.label, Validators.required],
              caption: element.caption,
              fieldName: element.fieldName,
              fieldType: element.fieldType,
              _id: element._id
            }
          )
        );
      });
    }
  }

  addItem(): void {
    this.isChanged = true;
    this.itemArray.push(
      this.fb.group(
        {
          enabled: true,
          label: ['', Validators.required],
          caption: '',
          fieldName: ''
          // _id: undefined
        }
      )
    );
  }

  removeItem(index: number): void {
    this.isChanged = true;
    this.itemArray.removeAt(index);
  }

  get f() { return this.toggleCheckboxForm.controls; }
  // get itemArray() { return this.toggleCheckboxForm.get('itemArray') as FormArray; }
  get itemArray() { //for null checker
    if (this.toggleCheckboxForm) {
      const control = this.toggleCheckboxForm.get('itemArray');

      if (control instanceof FormArray) {
        return control;
      }
    }

    return new FormArray([]);
  }

  toggleSwitch(): void {
    this.isChanged = true;
    this.emitChangedItem();
  }

  emitChangedItem(): void {
    this.changedItem.emit({
      formValue: this.toggleCheckboxForm.value,
      isConfirmDisabled: false
    });
  }

  changeFieldName(): void {
    this.isChanged = true;
  }

  changeCaption(): void {
    this.isChanged = true;
  }

  checkDisableCheckbox(): void {
    this.isCheckboxDisabled ? this.toggleCheckboxForm.disable() : this.toggleCheckboxForm.enable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.toggleCheckboxForm) {
      this.checkDisableCheckbox();
    }
  }

  get isSaveDisabled(): boolean {
    return this.isLoading || this.checkFields() || !this.isChanged
  }

  checkFields(): boolean {
    for (var i = 0; i < this.itemArray.value.length; i++) {
      if (this.itemArray.value[i].label.trim() == '') {
        return true;
      }
    }
    return false;
  }

  async preSave(): Promise<void> {
    const formData = this.itemArray.value;
    const formFields: { fields: Array<ICustomFormFields> } = { fields: this.transformFormData(formData) };
    await this.saveChanges(formFields);
  }

  transformFormData(formData: ICustomFormFields[]): ICustomFormFields[] {
    return formData.map(element => ({
      label: this.titleCasePipe.transform(element.label.trim() || ''),
      caption: element?.caption?.trim() || '',
      required: element.required || false,
      enabled: element.enabled || false,
      fieldName: element.label?.trim().replace(/\s+/g, '_').toLowerCase() || '',
      fieldType: element.fieldType || 'text',
    }));
  }

  async checkDupes(array: ICustomFormFields[]) {
    let arr = [];
    let checker = false;

    if (array && array.length > 0) {
      array.map((element) => {
        arr.push(element.fieldName);
      });

      const s = new Set(arr);

      if (arr.length !== s.size) {
        checker = true;
        arr = [];
      }
    }

    return checker;
  }

  async saveChanges(formFields: { fields: Array<ICustomFormFields> }): Promise<void> {
    this.isLoading = true;
    this.hasError = false;
    if (!this.customServiceSmartFormId) {
      this.fetchCustomSmartForm();
    }
    const action = this.customServiceSmartFormId === '' ? 'POST' : 'PATCH';
    const hasDuplicateFieldname = await this.checkDupes(formFields.fields);
    let errMsg = `Field name value(s) has duplicate in custom queue form field list`;

    if (hasDuplicateFieldname) {
      this.hasError = true;
      this.toastr.error(errMsg, "Error!");
      this.isLoading = false;
    } else {
      this.subscriptions.add(
        this.settingsService
          .customSmartForms(action, this.branchId, formFields, this.customServiceSmartFormId)
          .subscribe(
            (response) => {
              const fields = response?.result.fields;
              if (fields) {
                this.mainLists = fields;
                this.toastr.success("Custom form fields has been updated", "Success!");
                this.initForm();
              }
            },
            (error) => {
              console.log(error)
              this.hasError = true;
              errMsg = error?.error?.errors[0]?.msg;
              this.toastr.error(errMsg, "Error!");
              this.isLoading = false;
            },
            () => {
              if(!this.hasError) {
                this.isLoading = false;
                this.isChanged = false;
              }
            }
          )
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.customFormFieldSubPageSubjectAPI$.next();
    this.customFormFieldSubPageSubjectAPI$.complete();
  }
}
