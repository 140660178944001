import { IFeedback } from "./interfaces";
import { FEEBACK_SETTING_RANGE_VALUES } from "./enums"

export class FeedbackBase {

    getNumberArray(num: number, offset: number = 0): number[] {
        if (num > 0) {
            console.log("heree ", Array(num).fill(0).map((x, i) => i + offset))
            return Array(num).fill(0).map((x, i) => i + offset);
        } else {
            return [1]
        }
    }

    numberToWord(feedback: IFeedback): string {
        let experience = "";

        if (feedback.rateRangeValue == FEEBACK_SETTING_RANGE_VALUES.ONE_TO_FIVE) {
            switch (feedback.rate) {
                case 1: experience = "Poor"
                    break;
                case 2: experience = "Bad"
                    break;
                case 3: experience = "Okay"
                    break;
                case 4: experience = "Good"
                    break;
                case 5: experience = "Great!"
                    break;
                default:
                    break;
            }
        }

        if (feedback.rateRangeValue == FEEBACK_SETTING_RANGE_VALUES.ONE_TO_THREE) {
            switch (feedback.rate) {
                case 1: experience = "Poor"
                    break;
                case 2: experience = "Okay"
                    break;
                case 3: experience = "Great!"
                    break;
                default:
                    break;
            }
        }

        return experience;
    }

    numberToEmotion(feedback: IFeedback): string {
        let emotion = "";
        if (feedback.rateRangeValue == FEEBACK_SETTING_RANGE_VALUES.ONE_TO_FIVE) {
            switch (feedback.rate) {
                case 1: emotion = "😢"
                    break;
                case 2: emotion = "😕"
                    break;
                case 3: emotion = "😐"
                    break;
                case 4: emotion = "🙂"
                    break;
                case 5: emotion = "😁"
                    break;
                default:
                    break;
            }
        }

        if (feedback.rateRangeValue == FEEBACK_SETTING_RANGE_VALUES.ONE_TO_THREE) {
            switch (feedback.rate) {
                case 1: emotion = "😢"
                    break;
                case 2: emotion = "😐"
                    break;
                case 3: emotion = "😁"
                    break;
                default:
                    break;
            }
        }
        return emotion;
    }
}