import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: "app-toggle-switch",
  templateUrl: "./toggle-switch.component.html",
  styleUrls: ["./toggle-switch.component.scss"],
})
export class ToggleSwitchComponent implements OnInit, OnChanges {
  @Input()
  title: string;
  @Input()
  subTitle: string;
  @Input()
  value: boolean;
  @Input()
  isDisabled: boolean;
  @Input()
  icon: string;
  @Input()
  iconColor: string;
  @Output()
  changedToggle = new EventEmitter();
  @Input()
  externalData: any;

  isChecked: boolean;

  constructor() {}

  toggle($event) {
    setTimeout(() => {
      this.isChecked = !this.isChecked;
      this.changedToggle.emit($event);
    }, 100);
  }

  ngOnChanges(): void {
    this.isChecked = this.value;
  }

  ngOnInit(): void {
  }
}
