import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import * as moment from "moment";
import { Subject, Subscription, throwError } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { defaultAvatar } from "src/app/core/services/global";
import { DashboardService } from "src/app/modules/dashboard/services/dashboard.service";
import { MonitoringService } from "src/app/modules/monitoring/services/monitoring.service";
import {
  getAccountName,
  getAccountAvatar,
} from "src/app/shared/helpers/team-management.helpers";
import { convertMillisecondsToTime, getDuration, getDuration2 } from "src/app/shared/helpers/time.helpers";
import { DatePipe, TitleCasePipe } from "@angular/common";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  takeUntil,
  tap,
} from "rxjs/operators";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { HttpParams } from "@angular/common/http";
import { HttpService } from "src/app/core/http/http.service";
import { ServiceCategoryServiceV2 } from "src/app/modules/service-category-v2/services/service-category.service";

@Component({
  selector: "app-team-performance-tab-page",
  templateUrl: "./team-performance-tab-page.component.html",
  styleUrls: ["./team-performance-tab-page.component.scss"],
  providers: [TitleCasePipe],
})
export class TeamPerformanceTabPageComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() branchId: string = "";
  @Input() selectedValue: string = "";
  @Input() selectedLabel: string = "";
  @Input() customDate1: string = "";
  @Input() customDate2: string = "";
  @Input() selectedServiceId: string = "";
  defaultAvatar: string = defaultAvatar;
  subscriptions = new Subscription();
  isLoading: boolean = false;
  queueData: Array<any>;
  serviceLists: Array<any>;
  serviceCategories: Array<any>;
  windowsCount: any;
  teamList = {
    pages: 1,
    success: true,
    total: 1,
    data: [],
  };
  itemsPerPage = 10;
  currentPage = 1;
  items = 100;
  searchSubject: Subject<string> = new Subject();
  filterSubject: Subject<any> = new Subject();
  searchString: string | null;
  noOfEntries: number[] = [10, 20, 30];
  noOfPages: number[] = [];
  dateNow = Date.now();
  currentMonth: string = "";
  private teamPerformanceSubjectAPI$ = new Subject<void>();
  ONE_HOUR: number = 3600000;
  isSingleDate: boolean = false;

  constructor(
    public titleCasePipe: TitleCasePipe,
    private httpService: HttpService,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private monitoringService: MonitoringService,
    private categoryService: ServiceCategoryServiceV2,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef
  ) {
    this.currentMonth = this.getCurrentMonth();
  }

  getCurrentMonth(): string {
    const currentDate = new Date();
    const currentMonth = this.datePipe
      .transform(currentDate, "MMMM")
      .toUpperCase();
    return currentMonth;
  }

  get accountsParams() {
    let params = new HttpParams();
    params = params.append("limit", this.itemsPerPage.toString());
    const offset = this.currentPage * this.itemsPerPage - this.itemsPerPage;
    params = params.append("offset", offset.toString());
    if (this.searchString) {
      params = params.append("search", this.searchString);
    }

    this.isSingleDate =
      (this.selectedValue === "custom" && 
        ((this.customDate1 && this.customDate2) &&
        this.customDate1 === this.customDate2));

    if (this.selectedValue === "custom") {
      params = params.append("filterBy", this.isSingleDate ? 'singledate' : this.selectedValue);
      params = params.append(
        "startDate",
        new Date(this.customDate1).setHours(0, 0, 0).toString()
      );
      params = params.append(
        "endDate",
        new Date(this.customDate2).setHours(23, 59, 59).toString()
      );
    } else {
      params = params.append("filterBy", this.selectedValue);
    }
    return params;
  }

  get isSearch() {
    if (this.searchString) {
      return true;
    }
  }

  ngOnInit(): void {
    if (this.branchId == undefined) {
      this.branchId = this.dataService.currentBranch$?._id;
    }
    // this.teamList.data = this.dataService.getAccountsList()
    this.getServiceLists(this.branchId);
    this.getWindowsCount(this.branchId);
    this.getAccounts(this.branchId);
    this.searchSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.getAccounts(this.branchId);
      });

    this.filterSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.getAccounts(this.branchId);
      });
  }

  async getAccounts(branchId: string) {
    this.isLoading = true;
    this.subscriptions.add(
      this.monitoringService
        .getStaffPerformance(branchId, this.accountsParams)
        .pipe(
          takeUntil(this.teamPerformanceSubjectAPI$),
          map((res) => res),
          tap((data) => {
            this.teamList = data;
            const pages = this.teamList.pages;
            if (pages == 0) {
              this.noOfPages.push(1);
            } else {
              this.noOfPages = Array.from({ length: pages }, (_, i) => i + 1);
            }
          }),
          catchError((error) => {
            console.log("error on fetching team lists", error);
            return throwError(error);
          }),
          finalize(() => {
            if (this.teamList.data && this.teamList.data.length) {
              this.teamList.data = this.teamList.data.map((account) => ({
                ...account,
                name: account.firstName + " " + account.lastName,
              }));
            }
            this.isLoading = false;
          })
        )
        .subscribe()
    );
  }

  getWindowsCount(branchId: string) {
    this.subscriptions.add(
      this.dashboardService.getWindowStatus(branchId).subscribe(
        (res) => {
          this.windowsCount = res.data;
          // console.log(this.windowsCount);
        },
        (error) => {
          console.log("error on fetching window counts", error);
        }
      )
    );
  }

  public searchAccounts() {
    this.searchSubject.next(this.searchString);
    // this.getQueueHistory(this.branchId);
  }

  pageChange(event: PageChangedEvent) {
    this.currentPage = event.page;
    this.filterSubject.next(this.currentPage);
    this.getAccounts(this.branchId);
  }

  jumpToPage(page: string) {
    this.currentPage = parseInt(page);
    this.filterSubject.next(this.currentPage);
    this.getAccounts(this.branchId);
    this.cdr.detectChanges();
  }

  entriesChange(a) {
    this.itemsPerPage = a;
    this.filterSubject.next(this.itemsPerPage);
    this.getAccounts(this.branchId);
  }

  getAccountAvatar(accountId: string) {
    return getAccountAvatar(this.teamList.data, accountId);
  }

  getAccountName(accountId: string) {
    return getAccountName(this.teamList.data, accountId);
  }

  getAccountDetails(accountId: string) {
    return this.teamList.data?.find((x: { _id: string; }) => x._id === accountId) || {};
  }
  
  getAccountActivityStatus(accountId: string): string {
    const account = this.getAccountDetails(accountId);
    return account.activityStatus || '';
  }

  getDuration(milliseconds: number): string {
    return milliseconds > 0 ? getDuration(milliseconds) : '';
  }

  isOccupied(occupied: any): boolean {
    return !!occupied.queue;
  }

  isOneHourOrMoreAgo(updatedAt: number): boolean {
    return getDuration2(this.dateNow - updatedAt).isOneHourOrMore;
  }

  getServingTime(servedAt: number): string {
    return servedAt > 0 ? this.getDuration(this.dateNow - servedAt) : '--:--';
  }

  convertMillisecondsToTime(milliseconds: number) {
    return convertMillisecondsToTime(milliseconds);
  }

  formatDuration(duration: number, isSeconds?: boolean): string {
    let seconds: number;
    if (isSeconds) {
      seconds = duration;
    } else {
      seconds = Math.floor(duration / 1000);
    }

    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    const roundedSeconds = Math.round(seconds % 60);

    const formattedDuration = `${hours.toString().padStart(2, "0")}:${(
      minutes % 60
    )
      .toString()
      .padStart(2, "0")}:${roundedSeconds.toString().padStart(2, "0")}`;

    return formattedDuration;
  }

  getTimeAgo(data) {
    if (data) {
      const y = new Date(data).getFullYear();
      const m = new Date(data).getMonth();
      const d = new Date(data).getDate();
      const h = new Date(data).getHours();
      const mm = new Date(data).getMinutes();
      const s = new Date(data).getSeconds();
      const time = moment([y, m, d, h, mm, s]).fromNow();

      return time === "a day" ? "1 day" : time;
    }
  }

  getServiceCategoryList(branchId: string) {
    this.subscriptions.add(
      this.categoryService
        .getServiceCategories(branchId)
        .pipe(
          takeUntil(this.teamPerformanceSubjectAPI$),
          map((res) => res.data), // for extraction of data
          tap((data) => {
            this.serviceCategories = data;
          }),
          catchError((error) => {
            console.log("Error on fetching service category lists", error);
            return throwError(error);
          }),
        )
        .subscribe()
    );
  }

  getServiceLists(branchId: string) {
    this.subscriptions.add(
      this.httpService
        .get$(`services/${branchId}?limit=999`)
        .pipe(
          takeUntil(this.teamPerformanceSubjectAPI$),
          map((res) => res.data), // for extraction of data
          tap((data) => {
            this.serviceLists = data.map((element) => ({
              _id: element._id,
              name: element.displayName,
            }));
          }),
          catchError((error) => {
            console.log("Error on fetching branch details", error);
            return throwError(error);
          }),
          finalize(() => {
            if (this.serviceLists.length > 0) {
              this.getServiceCategoryList(branchId);
            }
          })
        )
        .subscribe()
    );
  }

  getServiceCategoryName(id: string, categoryId: string) {
    let serviceName: string[] = [];
    if (id && this.serviceLists) {
      const service = this.serviceLists.find((x: { _id: string; }) => x._id === id);
      if (service) {
        serviceName = [service.name];
      }
    }

    let serviceCategoryName: string[] = [];
    if (categoryId && this.serviceCategories) {
      const category = this.serviceCategories.find((x: { _id: string; }) => x._id === categoryId);
      if (category?.parentCategoryId) {
        const parentCategory = this.serviceCategories.find((x) => x._id === category?.parentCategoryId);
        if (parentCategory?.parentCategoryId) {
          const grandParentCategory = this.serviceCategories.find((x) => x._id === parentCategory?.parentCategoryId);
          serviceCategoryName = [
            grandParentCategory?.displayName,
            parentCategory?.displayName,
            category?.displayName,
          ];
        } else {
          serviceCategoryName = [parentCategory?.displayName, category?.displayName];
        }
      } else {
        serviceCategoryName = [category?.displayName];
      }
    }

    return {
      serviceCategory: serviceCategoryName,
      serviceName: serviceName,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const branchIdChange = changes.branchId;
    const selectedValueChange = changes.selectedValue;
    const customDate1Change = changes.customDate1;
    const customDate2Change = changes.customDate2;
  
    if (
      (branchIdChange && this.isValueChanged(branchIdChange.previousValue, branchIdChange.currentValue)) ||
      (selectedValueChange && this.isValueChanged(selectedValueChange.previousValue, selectedValueChange.currentValue)) ||
      (customDate1Change && this.isValueChanged(customDate1Change.previousValue, customDate1Change.currentValue)) ||
      (customDate2Change && this.isValueChanged(customDate2Change.previousValue, customDate2Change.currentValue))
    ) {
      this.getAccounts(this.branchId);
    }
  }
  
  private isValueChanged(previousValue: any, currentValue: any): boolean {
    return previousValue !== undefined && previousValue !== currentValue;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.teamPerformanceSubjectAPI$.next();
    this.teamPerformanceSubjectAPI$.complete();
  }
}
