import { Component, OnInit } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
//import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { DataService } from "src/app/core/services/data.service";
import { SupportReportIssueModalComponent } from "../../components/support-report-issue-modal/support-report-issue-modal.component";
import { SupportConnectSalesModalComponent } from "../../components/support-connect-sales-modal/support-connect-sales-modal.component";
import { SupportRequestFeatureModalComponent } from "../../components/support-request-feature-modal/support-request-feature-modal.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-support-page",
  templateUrl: "./support-page.component.html",
  styleUrls: ["./support-page.component.scss"],
})
export class SupportPageComponent implements OnInit {
  // customerFeedbackurl: SafeUrl;
  // constructor(private sanitizer: DomSanitizer) { }
  subscription:string = "";

  constructor(
    private router: Router,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private dataService: DataService,
  ) {}

  helpCenter(): void {
    this.router.navigate(["/support/help-center"]);
  }

  userManual(): void {
    this.router.navigate(["/support/user-manual"]);
  }

  onBoarding(): void {
    this.router.navigate(["/support/onboarding"]);
  }

  reportIssue(): void {
    const initialState = {
      title: "Report an Issue",
      subTitle:
        "Enter as much detail as you can and we'll get right back to you.",
    };
    this.modalRef = this.modalService.show(SupportReportIssueModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  requestFeature(): void {
    const initialState = {
      title: "Request a feature",
      subTitle: "We'd love to hear your suggestions for Kyoo.",
    };
    this.modalRef = this.modalService.show(SupportRequestFeatureModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  connectSales(): void {
    const initialState = {
      title: "Connect to Sales",
      subTitle:
        "Need help with a business need? Get in touch with our sales team",
    };
    this.modalRef = this.modalService.show(SupportConnectSalesModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  closeModal() {
    this.modalRef.hide();
  }

  ngOnInit(): void {
    this.subscription = this.dataService.getSubscriptionPlan().plan
  
  }
}
