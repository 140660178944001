import { Component, OnInit, HostListener, Output, EventEmitter } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { BranchManagementService } from "../../services/branch-management.service";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { DatePickerModalComponent } from "src/app/shared/components/date-picker-modal/date-picker-modal.component";
import { DatePipe } from "@angular/common";
import * as moment from 'moment';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-edit-special-schedule',
  templateUrl: './edit-special-schedule.component.html',
  styleUrls: ['./edit-special-schedule.component.scss']
})
export class EditSpecialScheduleComponent implements OnInit {
  pipe = new DatePipe('en-US'); // Use your own locale
  isConfirmDisabled: boolean = true;
  branchId: any;
  data: any;
  title: any;
  subTitle: any;
  hoursLength = 1;
  dropdownList = [
    { label: "7:00 AM", value: this.getMilliseconds(7, 0) },
    { label: "8:00 AM", value: this.getMilliseconds(8, 0) },
    { label: "9:00 AM", value: this.getMilliseconds(9, 0) },
    { label: "10:00 AM", value: this.getMilliseconds(10, 0) },
    { label: "11:00 AM", value: this.getMilliseconds(11, 0) },
    { label: "12:00 PM", value: this.getMilliseconds(12, 0) },
    { label: "1:00 PM", value: this.getMilliseconds(13, 0) },
    { label: "2:00 PM", value: this.getMilliseconds(14, 0) },
    { label: "3:00 PM", value: this.getMilliseconds(15, 0) },
    { label: "4:00 PM", value: this.getMilliseconds(16, 0) },
    { label: "5:00 PM", value: this.getMilliseconds(17, 0) },
    { label: "6:00 PM", value: this.getMilliseconds(18, 0) },
    { label: "7:00 PM", value: this.getMilliseconds(19, 0) },
    { label: "8:00 PM", value: this.getMilliseconds(20, 0) },
    { label: "9:00 PM", value: this.getMilliseconds(21, 0) },
    { label: "10:00 PM", value: this.getMilliseconds(22, 0) },
    { label: "11:00 PM", value: this.getMilliseconds(23, 0) },
    { label: "12:00 AM", value: this.getMilliseconds(24, 0) },
    { label: "1:00 AM", value: this.getMilliseconds(1, 0) },
    { label: "2:00 AM", value: this.getMilliseconds(2, 0) },
    { label: "3:00 AM", value: this.getMilliseconds(3, 0) },
    { label: "4:00 AM", value: this.getMilliseconds(4, 0) },
    { label: "5:00 AM", value: this.getMilliseconds(5, 0) },
    { label: "6:00 AM", value: this.getMilliseconds(6, 0) },
  ];
  selectedValue: string;
  isSelectClicked: boolean;
  isButtonClicked: boolean;
  isFocused: boolean;
  startDateLabel: any ;
  endDateLabel: any ;
  // moment().format('M/D/YYYY') ex 7-5-2022

  startDate: any;
  endDate: any;
  operationHours: any;
  isDateRange = false;
  @Output() successEvent = new EventEmitter();
  dataArr: any;
  public specialScheduleForm = this.fb.group({
    name: ["", [Validators.required]],
    // startDate: ["", [Validators.required]],
    // endDate: [""],
    description: [""],
    businessHours: this.fb.array([])
  });

  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    public modalRef2: BsModalRef,
    private branchManagementService: BranchManagementService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.dataArr = this.data;
  }

  ngOnInit(): void {
    console.log(this.data);
    this.isDateRange = this.data.endDate != '' && !(moment(this.startDate).isSame(this.endDate));
    const data = {
      name: this.data.displayName,
      description: this.data.description,
    };
    this.startDate = moment(this.data.startDate).format('L'),
    this.endDate = this.data.endDate ? moment(this.data.endDate).format('L') : '',
    this.specialScheduleForm.patchValue(data);
    this.patchTimeRange(this.data.operationHours);
    this.hoursLength = this.hoursArray.length;
  }

  patchTimeRange(data) {
    if(data){
      data.forEach((element, i) => {
        this.hoursArray.push(
          this.fb.group({
            openingTime: element.openingTime,
            closingTime: element.closingTime
          })
        );
      });
    }
  }

  addDateRange() {
    this.isDateRange = !this.isDateRange;
  }

  addHoursGroup(): FormGroup {
    return this.fb.group({
      openingTime: "",
      closingTime: "",
    }); 
  }

  //Add Fields
  addHours(): void {
    this.hoursArray.push(this.addHoursGroup());
    this.hoursLength += 1;
  }

  //Remove Fields
  removeHours(index: number): void {
    this.hoursArray.removeAt(index);
    this.hoursLength -= 1;
  }

  get hoursArray(): FormArray {
    return this.specialScheduleForm.get("businessHours") as FormArray;
  }

  get f() { return this.specialScheduleForm.controls; }

  checkHours() {
    const index = this.hoursArray.value.findIndex(hour => hour.openingTime === '' || hour.closingTime === '');
    if(index > -1){
      return true;
    }
    if(!(moment(this.startDate).isSame(this.endDate))){
      if(moment(this.startDate).isAfter(this.endDate)){
        return true;
      }
    } 

    return false;
  }

  onSubmit() {
    const formValue = this.specialScheduleForm.value;
    const body = {
      day: 0,
      displayName: formValue.name,
      startDate: moment.utc(this.startDate).valueOf(),
      endDate: this.isDateRange ? moment.utc(this.endDate).valueOf() : moment.utc(this.endDate).valueOf(),
      operationHours: formValue.businessHours,
      description: formValue.description,
      authorId: ""
    };

    console.log(body);
    console.log(body.startDate, '', body.endDate);
    this.branchManagementService
      .editSpecialSchedule(this.branchId, this.data._id, body)
      .subscribe(
        (res) => {
          console.log(res);
          this.successEvent.emit(res)
          this.modalRef.hide();
          this.toastr.success("Special schedule has been updated", "Success!");
        },
        (error) => {
          console.log(error);
          let errMsg = error.error.errors[0].msg;
          this.toastr.error(errMsg, "Error!");
        }
      );
  }

  getMilliseconds(hour?: number, minutes?: number) {
    var result = (60 * 1000 * 60 * hour ?? 1) + (60 * 1000 * minutes ?? 1);
    // console.log(result);
    return result;
  }

  select() {
    this.isSelectClicked = !this.isSelectClicked;
    this.isFocused = !this.isFocused;
  }

  @HostListener("document:click")
  deselect() {
    if (!this.isSelectClicked || this.isButtonClicked) {
      this.isFocused = false;
    }
    this.isButtonClicked = false;
    this.isSelectClicked = false;
  }

  confirm() {}

  cancel() {}

  selectDate(type) {
    const initialState = {
      title: "Select start date",
      datePickerType: "single",
      type: type,
      hasMinDate: true
    };
    this.modalRef2 = this.modalService.show(DatePickerModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef2.content.selectedDateRange.subscribe(
      (data) => {
        if (type === "start") {
          this.startDateLabel = (data as Date).toLocaleDateString("en-US");
          this.startDate = moment(data).format('L');
        }
        if (type === "end") {
          this.endDateLabel = (data as Date).toLocaleDateString("en-US");
          this.endDate = moment(data).format('L');
        }
      },
      (err) => {
        return false;
      }
    );
  }
}
