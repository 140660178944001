import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { DataService } from 'src/app/core/services/data.service';
import { Router } from '@angular/router';
import { IFeedback, IFeedbackList, CheckboxData, CheckboxList } from './interfaces';
import { FeedbackDataService } from './services/feedback-data.service';
import { DATE_FILTER } from './enums/date_filter';
import { FeedbackBase } from './feedback-base';
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize, map, takeUntil, tap, catchError} from 'rxjs/operators';
import { Subject, throwError } from "rxjs";


@Component({
  selector: 'app-feedback-dashboard',
  templateUrl: './feedback-dashboard.component.html',
  styleUrls: ['./feedback-dashboard.component.scss']
})
export class FeedbackDashboardComponent extends FeedbackBase implements OnInit {

  feedbackList: IFeedbackList = {
    data: [],
    total: 0,
    pages: 0
  };

  selectedBranch = this.dataService.currentBranch$;

  totalRatings: number = 0;
  totalLowRatings: number = 0;
  averageSatisfactionRate: number = 0;
  selectedServices: number = 0;
  servicesData: Array<any> = [];
  selectedService: Array<any> = [];
  serviceLists: Array<any> = [];
  serviceCheckboxLists: Array<CheckboxList> = [];
  dropdownsVisible: { [key: string]: boolean } = {}; 
  isFocused: boolean = false;
  isSelectClicked: boolean = false;
  isButtonClicked: boolean = false;
  isLoading: boolean = false;
 

  itemsPerPage = 10;
  currentPage = 1;
  noOfEntries: number[] = [10, 20, 30];

  feedbackListForm: FormGroup = this.formBuild.group({
    dateFilter: null,
    startDate: null,
    endDate: null,
    search: null,
    offset: 0,
    limit: 10
  });

  serviceDropdown: ElementRef<HTMLDivElement>;
  @ViewChild("statusDropdownRef", { static: false })
  
  private feedbackListData = new Subject<void>();
  constructor(private httpService: HttpService,
    private router: Router,
    private dataService: DataService,
    private feedbackDataService: FeedbackDataService,
    private formBuild: FormBuilder,
    private elementRef: ElementRef
    ) {

    super();

    this.feedbackListForm.controls['search']
      .valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(
        (value: string) => {
          console.log("search value", value)
          this.feedbackListForm.patchValue({
            search: value
          })
          this.getFeedbackList()
        }
      )

  }

  ngOnInit(): void {
    // this.getFeedbackList()
    this.getServiceLists(this.selectedBranch.branchId)
  }

  getFeedbackList() {

    const httpQueryParams = this.httpService.convertFormGroupToParams(this.feedbackListForm)
    this.httpService.getV2$(`feedback/${this.selectedBranch.branchId}/queues/`, httpQueryParams).subscribe(
      data => {
        const myDataFilter: any = []
        if(this.selectedService.length > 0) {
          data.result.data.map(feedback => {
          this.selectedService.filter(myService => {
            if(feedback.referenceObject.serviceId.toString() === myService.toString() ) {
              myDataFilter.push(feedback)
            }
          })
        });
          data.result.data = [...myDataFilter]
          this.feedbackList = data.result  
        } else {
          this.feedbackList = data.result;
        }  
      },
      error => console.log(error)
    );
  }

  selectFeedback(feedback: IFeedback) {
    this.feedbackDataService.setData(feedback)
    this.router.navigateByUrl("/apps/feedback-dashboard/details");
  }

  selectedDateChanged($event: any) {

    if ($event instanceof Array) {
      this.feedbackListForm.patchValue({
        startDate: $event[0],
        endDate: $event[1],
        dateFilter: DATE_FILTER.CUSTOM
      })
    } else {
      this.feedbackListForm.patchValue({
        startDate: null,
        endDate: null,
        dateFilter: $event
      })
    }

    // TODO : make an API call that will return the total ratings, total low ratings, average satisfaction rate, and feedback list
    this.getTotalRatings()
    this.getTotalLowRatings()
    this.getAverageSatisfactionRate()
    this.getFeedbackList()
  }

  getTotalRatings() {
    const params = this.httpService.convertFormGroupToParams(this.feedbackListForm)
    this.httpService.getV2$(`feedback/${this.selectedBranch.branchId}/queues/total-counts/`, params).subscribe(
      data => {
        this.totalRatings = data.result;
        // console.log("total ratings", this.totalRatings)
      }
    )
  }

  getTotalLowRatings() {
    const params = this.httpService.convertFormGroupToParams(this.feedbackListForm)
    this.httpService.getV2$(`feedback/${this.selectedBranch.branchId}/queues/total-low-ratings-counts/`, params).subscribe(
      data => {
        this.totalLowRatings = data.result;
        // console.log("total low ratings", this.totalLowRatings)
      }
    )
  }

  getAverageSatisfactionRate() {
    const params = this.httpService.convertFormGroupToParams(this.feedbackListForm)
    this.httpService.getV2$(`feedback/${this.selectedBranch.branchId}/queues/avg-satisfaction-rate/`, params).subscribe(
      data => {
        this.averageSatisfactionRate = data.result;
        // console.log("average satisfaction rate", this.averageSatisfactionRate)
      }
    )
  }

  getServiceLists(branchId: string) {
      this.httpService
        .get$(`services/${branchId}?limit=999`)
        .pipe(
          takeUntil(this.feedbackListData),
          map((res) => res.data), // for extraction of data
          tap((data) => {
            this.serviceLists = data.map((element) => ({
              _id: element._id,
              name: element.displayName,
            }));
            this.setServiceData(this.serviceLists);
          }),
          catchError((error) => {
            console.log("Error on fetching branch details", error);
            return throwError(error);
          }),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe()
  }

  setServiceData(serviceData: any) {
      const serviceLists = serviceData.map((element) => {
      return {
        value: element._id,
        label: element.name,
      };
    });
    this.serviceCheckboxLists = this.addCheckedProperty(serviceLists);
  }

  addCheckedProperty(contents: Array<CheckboxList>) {
    if (contents) {
      const newContents = contents.map((content) => ({
        ...content,
        checked: false, // for checkbox
      }));
      return newContents;
    }
  }

  toggleDropdown(dropdownId: string): void { 
    Object.keys(this.dropdownsVisible).forEach((id) => {
      if (id !== dropdownId) {
        this.closeDropdown(id);
      }
    });

    if (this.isDropdownVisible(dropdownId)) {
      this.closeDropdown(dropdownId);
    } else {
      this.openDropdown(dropdownId);
    }

    this.isFocused = false;
    this.isButtonClicked = false;
    this.isSelectClicked = false;
  }

  changeCheckboxList($event: CheckboxData) {
    if ($event) {
      const key = $event?.key;
      const selected = $event.checkedArray;
      this.getFeedbackList()
      switch (key) {
        case "service":
          this.selectedServices = selected.length;
          this.selectedService = [];
          if (this.selectedServices > 0) {
            selected.forEach((element) => {
              this.selectedService.push(this.serviceLists[element]._id);
            });
          }
          break;
        default:
          break;
      }
    }
  }

  isDropdownVisible(dropdownId: string): boolean {
    return this.dropdownsVisible[dropdownId] ?? false; 
  }

  openDropdown(dropdownId: string): void {
    Object.keys(this.dropdownsVisible).forEach((id) => {
      if (id !== dropdownId) {
        this.closeDropdown(id);
      }
    });

    this.dropdownsVisible[dropdownId] = true;
  }

  closeDropdown(dropdownId: string): void {
    this.dropdownsVisible[dropdownId] = false; 
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent): void {
    const clickedElement = event.target as HTMLElement;
    const dropdownDiv =
      this.elementRef.nativeElement.querySelector(".date-dropdown");
    if (!dropdownDiv?.contains(clickedElement)) {
      if (this.isFocused === true) {
        this.isFocused = false;
      }
    }
    for (const dropdownId in this.dropdownsVisible) {
      if (this.dropdownsVisible.hasOwnProperty(dropdownId)) {
        const dropdownRef = this.elementRef.nativeElement.querySelector(
          `#${dropdownId}`
        );
        const dropdownMenuRef = this.elementRef.nativeElement.querySelector(
          `#${dropdownId}-menu`
        );
        if (dropdownRef && dropdownRef.contains(clickedElement)) {
          return;
        }
        if (dropdownMenuRef && dropdownMenuRef.contains(clickedElement)) {
          return;
        }
      }
    }
    this.dropdownsVisible = {};
  }

  pageChanged(event: PageChangedEvent) {
    this.feedbackListForm.patchValue({
      offset: (event.page - 1) * this.itemsPerPage,
    })
    this.getFeedbackList()
  }

  jumpToPage(event) {
    console.log("jump to page ", event)
  }

  entriesChanged(event) {

    this.feedbackListForm.patchValue({
      limit: event
    });

    this.getFeedbackList();
  }

}
