import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-input-select-overlay',
  templateUrl: './input-select-overlay.component.html',
  styleUrls: ['./input-select-overlay.component.scss']
})
export class InputSelectOverlayComponent implements OnInit {
  @Input() side: string;
  @Input() allItem: string;
  @Output() selectedDropdown = new EventEmitter();
  isFocused: boolean;
  @Input() selectedValue: string;
  isSelectClicked: boolean;
  isButtonClicked: boolean;

  constructor() { }

  _dropdownList: any;
  @Input() public set dropdownList(list){
    this._dropdownList = list;
  }

  public get dropdownList(){
    return this._dropdownList
  }

  modifyDropdownList() {
    if (this.allItem) {
      this.dropdownList.unshift({
        label: this.allItem,
        value: 'all'
      });
    }
    this.selectedValue = this.dropdownList[0].label;
  }
  
  select() {
    this.isSelectClicked = true;
    this.isFocused = true;
    if (!this.isFocused) {
      this.isSelectClicked = false;
    }
  }  

  @HostListener('document:click')
  deselect() {    
    if (!this.isSelectClicked || this.isButtonClicked) {
      this.isFocused = false;      
    } 
    this.isButtonClicked = false;
    this.isSelectClicked = false;    
  }

  selectDropdown(list) {
    this.selectedValue = list.label;
    this.selectedDropdown.emit(list.value);
    this.isButtonClicked = true;    
    if (!this.isFocused) {
      this.isButtonClicked = false;
    }
  }

  ngOnInit(): void {
    if (this.dropdownList) {
      this.modifyDropdownList();
    }
  }

}
