import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-move-queue-modal',
  templateUrl: './move-queue-modal.component.html',
  styleUrls: ['./move-queue-modal.component.scss']
})
export class MoveQueueModalComponent implements OnInit {

  title: string;
  subTitle: string;

  @Output() actionEvent = new EventEmitter();

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  onSelection(action){
    this.actionEvent.emit(action);
    this.bsModalRef.hide();
  }

}
