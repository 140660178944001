import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-multiple-login-modal',
  templateUrl: './multiple-login-modal.component.html',
  styleUrls: ['./multiple-login-modal.component.scss']
})
export class MultipleLoginModalComponent implements OnInit {

  constructor(private authService: AuthService, private modalRef: BsModalRef, private router: Router) { }

  ngOnInit(): void {
  }

  logout() {
    this.modalRef.hide();
    this.router.navigate(["/login"]);

    // this.authService.logout().subscribe((res) => {
    //   if (res.success) {
    //     this.modalRef.hide();
    //     setTimeout(() => {
    //       this.router.navigate(["/login"]);
    //     }, 500);
    //   }
    // });
  }
}
