import { Component, OnInit } from '@angular/core';
import { AuthService } from "src/app/core/auth/auth.service";
import { DataService } from "src/app/core/services/data.service";
import { Subscription } from "rxjs";
import { SettingsService } from '../../services/settings.service';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/core/http/http.service';

interface BranchFeedbackSettings {
  featuresEnabled: string[];
  createdAt: number;
  enabled: boolean;
  expiredAt: number;
  branchId: string;
  updatedAt: number;
}

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit {
  isLoading = false;
  subscriptions = new Subscription();
  accountData = this.dataService.accountData$;
  selectedBranch = this.dataService.currentBranch$;
  selectedNav: String = '';
  settingsNav: any = {
    business: false,
    billing: false,
    queue: false,
    sms_settings: false,
    event: false,
    custom_form_fields: false,
    feedback_settings: false,
    universal_tags: false,
  };

  branchFeedbackSettings: BranchFeedbackSettings | null = null;

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private settingsService: SettingsService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    console.log(this.dataService.currentBranch$)
    console.log(this.dataService.currentBranch$.branchId)
    const feedbackSettingsSubs = this.checkFeedbackSettings(this.dataService.currentBranch$.branchId);
    this.subscriptions.add(feedbackSettingsSubs)

    this.isLoading = true;
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe((res) => {
        if (res) {
          console.log(res);
          if (res.roleLevel === 'owner') {
            this.gotoNav('business');
          } else {
            this.gotoNav('queue');
          }
        }
        this.selectedBranch = res ? res : this.selectedBranch;
        this.isLoading = false;
      })
    );

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params && params.type === "branch") {
        this.gotoNav('queue');
      } else if (params && params.type === "billing") {
        this.gotoNav('billing');
      } else if (params && params.type === "sms-setup") {
        this.gotoNav('sms_settings');
        this.authService.updateIntroLevel({ level: 5.1 }).subscribe((res) => {
          this.dataService.publishIntroLevel$(
            res.result.completedIntroductionLevels
          );
        });
      } else {
        this.gotoNav('business');
      }
    });

  }

  gotoNav(nav: any) {
    this.selectedNav = nav;
    Object.keys(this.settingsNav).forEach(key => {
      this.settingsNav[key] = false;
    });
    this.settingsNav[nav] = true;
  }

  checkFeedbackSettings(branchId: string) {
    this.httpService.get$(`feedback/${branchId}/branch-settings`).subscribe((res: any) => {
      this.branchFeedbackSettings = res.result;
      console.log(res)
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

