import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/core/services/data.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BranchManagementService {
  apiUrl = environment.urlAPI;
  urlTVAPI = environment.urlTVAPI;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private router: Router,
  ) { }

  private get httpHeader(): any {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return {
      headers,
    };
  }

  public branches = new BehaviorSubject<any>(null);
  branches$ = this.branches.asObservable();
  publishBranches$(data) {
    this.branches.next(data);
  }

  public assignedBranches = new BehaviorSubject<any>(null);
  assignedBranches$ = this.assignedBranches.asObservable();
  publishAssignedBranches$(data) {
    this.assignedBranches.next(data);
  }


  public addBranch(branchData, businessId): Observable<any> {
    const url = `${this.apiUrl}/business/${businessId}/branches`;
    return this.http.post(url, branchData).pipe(map((response) => response));
  }

  public getOwnersBusinessBranches(businessId: string, ownerId: string, branchId: string): Observable<any> {
    console.log(ownerId, branchId)
    const url = `${this.apiUrl}/business/${businessId}/branches/owners-business-branches?ownerId=${ownerId}&branchId=${branchId}`
    return this.http.get<any>(url, this.httpHeader);
  }

  public getBranchList(): Observable<any> {
    const url = `${this.apiUrl}/branches/?limit=999`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getBranchDetails(branchId): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getBranchDetailCounts(branchId: string): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/details/counts`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getBranchSetup(branchId): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/settings`;
    console.log(url);
    return this.http.get<any>(url, this.httpHeader);
  }

  public updateBranchSetup(branchId, data): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/settings`;
    return this.http.patch(url, data).pipe(map((response) => response));
  }

  public editBranchDetails(branchData, branchId): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/details`;
    return this.http.patch(url, branchData).pipe(map((response) => response));
  }

  public editBranchAddress(branchData, branchId): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/details`;
    return this.http.patch(url, branchData).pipe(map((response) => response));
  }

  public deleteBranch(branchId): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}`;
    return this.http.delete(url).pipe(map((response) => response));
  }

  public getBranchWindowsCount(branchId): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/dashboard/window-counts`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getBranchServicesCount(branchId): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/dashboard/service-counts`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getBranchTeamsCount(branchId): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/dashboard/account-counts`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public setSelectedBranch(data) {
    localStorage.setItem('selectedBranch', JSON.stringify(data));
    const selectedBranch = JSON.parse(localStorage.getItem('selectedBranch'));
    if (this.dataService.accountData$ && this.dataService.accountData$.data && selectedBranch) {
      this.dataService.accountData$.data.roleLevel = selectedBranch.roleLevel;
      if (this.dataService.accountData$.data.roleLevel === "staff") {
        this.router.navigate(['/']);
      }
    }
  }

  public getSelectedBranch() {
    return JSON.parse(localStorage.getItem('selectedBranch'));
  }

  dataURItoBlob(dataURI) {
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  public getDeviceCode(branchId): Observable<any> {
    const url = `${this.apiUrl}/accounts/authenticate/code/${branchId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getDeviceList(branchId): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/connected-devices`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getDeviceDetails(branchId, id): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/connected-devices/${id}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public removeTv(branchId, id): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/connected-devices/${id}`;
    return this.http.delete(url).pipe(map((response) => response));
  }

  public editTvDisplay(data, branchId, id): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/connected-devices/${id}`;
    return this.http.patch(url, data).pipe(map((response) => response));
  }

  public getOperationDays(branchId): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/operation-days/`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public setOperationHours(branchId, data): Observable<any> {
    console.log(branchId, data);
    const url = `${this.apiUrl}/branches/${branchId}/operation-days`;
    return this.http.patch(url, data).pipe(map((response) => response));
  }

  public setOperationHoursByDay(branchId, data): Observable<any> {
    console.log(branchId, data);
    const url = `${this.apiUrl}/branches/${branchId}/operation-days`;
    return this.http.patch(url, data).pipe(map((response) => response));
  }

  public getOperationHoursByday(branchId, day): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/operation-days/${day}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public addSpecialSchedule(branchId, data): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/operation-days/special-schedule`;
    return this.http.post(url, data).pipe(map((response) => response));
  }

  public getSpecialSchedule(branchId): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/operation-days/special-schedule`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getSpecialScheduleById(branchId, scheduleId): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/operation-days/special-schedule/${scheduleId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public removeSpecialSchedule(branchId, scheduleId): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/operation-days/special-schedule/${scheduleId}`;
    return this.http.delete(url).pipe(map((response) => response));
  }

  public editSpecialSchedule(branchId, scheduleId, data): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/operation-days/special-schedule/${scheduleId}`;
    return this.http.patch(url, data).pipe(map((response) => response));
  }
}
