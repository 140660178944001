import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { AppointmentService } from '../../services/appointment.service';
import { AppointmentSettingsService } from '../../services/appointment-settings.service';
import { DataService } from 'src/app/core/services/data.service';
import { ToastrService } from "ngx-toastr";
import { SMART_FORM_NAMES, APPOINTMENT_SETTINGS } from '../../enum/smart_form';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-remove-appointment-modal',
  templateUrl: './remove-appointment-modal.component.html',
  styleUrls: ['./remove-appointment-modal.component.scss']
})

export class RemoveAppointmentModalComponent implements OnInit {
  @Input() title: any;
  @Input() subTitle: string;
  @Input() data: any;
  inputForm: FormGroup;
  isDisabled: boolean;
  isCollapsed: boolean = false;
  isSubmitting: boolean = false;
  dateTimeslotFormatted: string;
  requiredRemarks: boolean;
  newFormattedDate: string;
  formattedDate: string;
  @Output() successEvent = new EventEmitter();

  constructor(
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private dataService: DataService,
    private appointmentService: AppointmentService,
    private appointmentServiceSettings: AppointmentSettingsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
  ) {
    this.inputForm = this.formBuilder.group({
      remarks: [null, [Validators.required]]
    })
   }

  ngOnInit(): void {
    this.setDateLabel();
    this.appointmentSetting(); 
    this.dateTimeslotFormatted = this.formatDateWithourHours(this.data.timeslot)
    this.formattedDate = this.formatDate(this.data.createdAt);
    for (const smartForm of this.data.visitorDetails) {

      switch (smartForm.fieldName) {
        case SMART_FORM_NAMES.NAME:
          this.data.firstname = smartForm.value;
          
          break;
        case SMART_FORM_NAMES.MOBILENO:
          this.data.mobileNumber = smartForm.value;
          break;
        case SMART_FORM_NAMES.PRIORITY:
         this.data.priority = smartForm.value;
          break;
        case SMART_FORM_NAMES.VISITORS:
          this.data.visitors = smartForm.value;
          break;

        default:
          break;
      }
    } 
  }

  private formatDate(dateCreated: number): string {
    const date = new Date(dateCreated);
    const formattedDate = this.datePipe.transform(date, 'EEE, MM/dd/yyyy - h:mm a');
    return formattedDate;
  }

  private formatDateWithourHours(dateCreated: number): string {
    const date = new Date(dateCreated);
    const formattedDate = this.datePipe.transform(date, 'EEE, MM/dd/yyyy');
    return formattedDate;
  }

  public appointmentSetting() {
    const branchId = this.dataService.currentBranch$.branchId;
    this.appointmentServiceSettings.getSettings(branchId).subscribe(
      (res) => {
        this.requiredRemarks = res.data.featuresEnabled.includes(APPOINTMENT_SETTINGS.REQUIRE_REMARKS);
      },
      (error) => {
        console.log(error)
        this.toastr.error("Error encountered upon submitting ", "Error!");
      }
    )
  }

  public confirm(appointmentData) {
    this.isSubmitting = true;
    let statusValue: string;
    let toastData = {
      header:'',
      message: ''
    }
    let referenceNo = this.data.referenceNo;
    const branchId = this.dataService.currentBranch$.branchId;
    const fullname = this.dataService.currentBranch$.name; 
    const accountId = this.dataService.currentBranch$.ownerId;
    const roleLevel = this.dataService.currentBranch$.roleLevel;
    const avatarUrl = this.dataService.currentBranch$.avatarUrl;

    if(this.data.approved) {
       statusValue = this.data.status === 'LATE' ?  "no-show" : "canceled-by-staff"
    } else {
       statusValue = 'rejected';
    } 

    switch(this.data.status) {
      case 'UPCOMING':
        statusValue = 'canceled-by-staff';
        toastData = {
          header:'Appointment Canceled',
          message: `${referenceNo} has been removed on the list`
        }
        break;
      case 'PENDING':
        toastData = {
          header:'Appointment Rejected',
          message: `${referenceNo} has been removed on the list`
        }
        break;
      default: 
        toastData = {
          header:'Appointment Canceled',
          message: `${referenceNo} has been marked as “${statusValue}”`
        }
        break;     
    }

    const requestForm = {
      status: statusValue,
      remarks: this.inputForm.value.remarks,
      completedBy: {fullname: fullname, accountId: accountId, roleLevel: roleLevel, avatarUrl: avatarUrl },
      queueGroupId: "",
    }

    this.appointmentService.moveAppointment(branchId,requestForm, appointmentData._id ).subscribe(
      (res) => {
        this.isSubmitting = false;
        this.successEvent.emit('deleted-move-history');
        this.modalRef.hide();
        this.toastr.error( toastData.message, toastData.header,{
          toastClass: "ngx-toastr toast-error-custom",
        });
      },
      (error) => {
        console.log(error)
        this.toastr.error("Error encountered upon submitting ", "Error!");
      }
    ) 
  }

  setDateLabel() {
    this.newFormattedDate = moment(this.data.formattedDate).format("dddd, MMMM Do YYYY")
  }

  checkValidator(): boolean {
    
    return 
  }

}
