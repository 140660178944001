import { Component, OnDestroy, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ServiceWindowAddFormComponent } from "../../components/service-window-add-form/service-window-add-form.component";
import { ModalWarningImageComponent } from "src/app/shared/components/modal-warning-image/modal-warning-image.component";
import { ModalWarningInputComponent } from "src/app/shared/components/modal-warning-input/modal-warning-input.component";
import { ServiceSmartFormComponent } from "../../components/service-smart-form/service-smart-form.component";
import { ServiceAssignWindowsFormComponent } from "../../components/service-assign-windows-form/service-assign-windows-form.component";
import { HttpService } from "src/app/core/http/http.service";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject, Subscription, throwError } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { ServiceWindowService } from "../../services/service-window.service";
import { catchError, debounceTime, map, takeUntil, tap } from "rxjs/operators";
import { GenericService } from "src/app/core/services/generic.service";
import { AuthService } from "src/app/core/auth/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DeleteServiceModalComponent } from "../../components/delete-service-modal/delete-service-modal.component";
import { SettingsService } from "src/app/modules/settings/services/settings.service";

@Component({
  selector: "app-service-page",
  templateUrl: "./service-page.component.html",
  styleUrls: ["./service-page.component.scss"],
})
export class ServicePageComponent
  extends ServiceWindowService
  implements OnInit, OnDestroy
{
  pageDetails = [
    { type: "main", name: "Branches", routerLink: ["/branch-management"] },
    { type: "sub", name: "", routerLink: null },
    { type: "current", name: "Services", routerLink: null },
  ];
  title = "Services";
  subtitle = "Categorize and manage the services provided by the branch";
  serviceLists: Array<any>;
  branchData: any;
  serviceNameLists: Array<any>;
  smartFormFieldLists: Array<any>;
  serviceId: any;
  serviceData: any;
  modifiedServiceData: any;
  selectedServiceIndex: number;
  newServiceLists = [];
  toggleSubject$ = new BehaviorSubject<any>(null);
  subscriptions = new Subscription();
  branchId: string;
  private customFormFieldSubjectAPI$ = new Subject<void>();

  constructor(
    public toastr: ToastrService,
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    public httpService: HttpService,
    public httpClient: HttpClient,
    private dataService: DataService,
    private genericService: GenericService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
  ) {
    super( httpClient, httpService, toastr);
  }

  saveServicePrefix(ev) {
    if (ev !== this.modifiedServiceData.queueNoPrefix) {
      const formBody = {
        queueNoPrefix: ev,
      };
      this.httpService
        .patch$(`services/${this.branchId}/prefix/${this.serviceId}`, formBody)
        .subscribe(
          async (data) => {
            await this.refreshPage();
            this.toastr.success("Changes has been saved", "Success!");
          },
          async (error) => {
            await this.refreshPage();
            console.log("error", error);
            this.toastr.error(error.error.errors[0].error, "Failed!");
          }
        );
    }
  }

  async getServiceList(serviceId?) {
    await this.httpService
      .get$(`services/${this.branchId}?limit=999`)
      .toPromise()
      .then((data) => {
        this.serviceLists = data.data;
        // this.serviceLists = this.serviceLists.reverse();
        this.serviceNameLists = this.serviceLists.map((element) => {
          return {
            name: element.displayName,
            greyed: element.suspended,
            _id: element._id,
          };
        });

        if (serviceId) {
          this.selectedServiceIndex = this.serviceNameLists.findIndex(
            (x) => x._id === serviceId
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  get canBeDeleted(): boolean {
    return this.serviceLists?.length > 1
  }

  async getBranchDetails() {
    await this.httpService
      .get$(`branches/${this.branchId}`)
      .toPromise()
      .then((data) => {
        this.branchData = data.data;
        this.pageDetails[1].name = this.branchData.name;
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  async selectService($event, serviceId?) {
    this.selectedServiceIndex = $event;
    this.serviceId = serviceId
      ? serviceId
      : this.serviceLists[this.selectedServiceIndex]?._id;
    if (this.serviceId) {
      await this.getServiceData();
    }
  }

  async getServiceData() {
    this.httpService
      .get$(`services/${this.branchId}/${this.serviceId}`)
      .subscribe((data) => {
        this.serviceData = data.data;
        const {
          queueForm,
          queueNoPrefix,
          queueNoCounter,
          assignedWindows,
          queueType,
          enabledFeatures,
          ...form
        } = this.serviceData;
        this.modifiedServiceData = {
          ...form,
          isHideKiosk: enabledFeatures.includes("hide-kiosk-and-site"),
          isAutoTag: enabledFeatures.includes("auto-tag"),
          isMultipleServing: queueType === "multiple-serving" ? true : false,
          assignedWindows: assignedWindows.map(
            (element) => element.displayName
          ),
          currentNumber:
            queueNoPrefix + String(queueNoCounter).padStart(3, "00"),
          queueNoPrefix: queueNoPrefix,
          fields: queueForm ? queueForm.fields : [],
        };
        this.smartFormFieldLists = this.modifiedServiceData.fields
          .filter((element) => element.enabled)
          .map((element) => {
            return `${element.label} - ${
              element.required ? "Required" : "Optional"
            }`;
          });
      });
  }

  uploadPhoto($event) {
    const param = `services/${this.branchId}/${this.serviceId}/presign-image-url`;
    this.httpService
      .uploadPhoto(param, $event)
      .then(async (data) => {
        this.toastr.success("Changes has been saved", "Success!");
        await this.refreshPage();
      })
      .catch(async (error) => {
        this.toastr.error("Changes has not been saved", "Error!");
        await this.refreshPage();
      });
  }

  addService() {
    const initialState = {
      title: "Add Service",
      type: "Service",
      subTitle:
        "Just enter the service name on the field below (and we’ll do the magic 😎)",
      branchId: this.branchId,
    };
    this.modalRef = this.modalService.show(ServiceWindowAddFormComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  saveServiceName($event) {
    const bodyForm = { displayName: $event };
    this.patchForm(bodyForm);
  }

  hideKiosk($event) {
    const feature = "hide-kiosk-and-site";
    const dataArray = this.serviceData.enabledFeatures;
    const value = $event;
    const featuresArray = this.addDeleteFeature(dataArray, feature, value);
    const bodyForm = { enabledFeatures: featuresArray };
    this.toggleSubject$.next(bodyForm);
  }

  async checkToggleValue() {
    this.subscriptions.add(
      this.createDebounce(this.toggleSubject$).subscribe((res) => {
        if (res !== null) {
          this.patchForm(res);
        }
      })
    );
  }

  createDebounce($event) {
    return $event.pipe(debounceTime(1000));
  }

  switchToMultipleServing($event) {
    const queueType = $event ? "multiple-serving" : "single-serving";
    const bodyForm = { queueType: queueType };
    this.toggleSubject$.next(bodyForm);
  }

  limitServedQueues($event) {
    const bodyForm = { queueServedLimit: { status: $event } };
    this.toggleSubject$.next(bodyForm);
  }

  setMaxLimitServedQueues($event) {
    const bodyForm = { queueServedLimit: { value: $event.value } };
    this.toggleSubject$.next(bodyForm);
  }

  limitQueuesPerDay($event) {
    const bodyForm = { queueLimit: { status: $event } };
    this.toggleSubject$.next(bodyForm);
  }

  setMaxLimitQueuesPerDay($event) {
    const bodyForm = { queueLimit: { value: $event.value } };
    this.toggleSubject$.next(bodyForm);
  }

  resetQueueNumber($event) {
    const resetNumber = String(1).padStart(
      4,
      this.serviceData.queueNoPrefix + "00"
    );
    const initialState = {
      title: "Reset Queue Number",
      subTitle: `Your rolling queue number for this service will be reset back to “${resetNumber}”. <b>You can’t undo this action.</b>`,
      param: `/services/${this.branchId}/${this.serviceId}/reset-queue-number`,
      isConfirmDisabled: false,
    };
    this.modalRef = this.modalService.show(ModalWarningInputComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  editSmartForm($event) {
    this.modifiedServiceData.fields[0].fieldName = "Name";
    const initialState = {
      title: "Queue Form",
      subTitle: `Visitors will fill out fields depending on form content settings below.`,
      fieldLists: this.modifiedServiceData.fields,
      serviceId: this.serviceId,
      branchId: this.branchId,
    };
    this.modalRef = this.modalService.show(ServiceSmartFormComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  async assignWindows($event) {
    const initialState = {
      title: "Assign Windows",
      subTitle: `This service can be managed through a specific window or through multiple windows. Select a window from the list below:`,
      assignedWindowStringLists: this.serviceData?.assignedWindows.map(
        (element) => element._id
      ),
      allWindows: this.serviceData.allWindows,
      serviceId: this.serviceId,
      serviceData: this.serviceData,
      branchId: this.branchId,
      confirmText: $event === "Add Service" ? "Confirm" : "Save Changes",
      cancelText: $event === "Add Service" ? "Skip for Now" : "Cancel",
    };

    this.modalRef = this.modalService.show(ServiceAssignWindowsFormComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  autoTag($event) {
    const feature = "auto-tag";
    const dataArray = this.serviceData.enabledFeatures;
    const value = $event;
    const featuresArray = this.addDeleteFeature(dataArray, feature, value);
    const bodyForm = { enabledFeatures: featuresArray };
    this.toggleSubject$.next(bodyForm);
  }

  addTag($event) {
    this.httpService
      .addTag(
        "service",
        $event,
        this.modifiedServiceData.tags,
        this.serviceId,
        null
      )
      .then(async () => {
        await this.refreshPage();
      })
      .catch(async () => {
        await this.refreshPage();
      });
  }

  removeTag($event) {
    this.httpService
      .removeTag(
        "service",
        $event,
        this.modifiedServiceData.tags,
        this.serviceId,
        null
      )
      .then(async () => {
        await this.refreshPage();
      })
      .catch(async () => {
        await this.refreshPage();
      });
  }

  deleteServiceModal() {
    const initialState = {
      title: "Delete Service?",
      branchId: this.branchId,
      serviceData: this.serviceData
    };

   this.modalRef = this.modalService.show(DeleteServiceModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });  
  }

  disableService() {
    const initialState = {
      title: "Disable this service?",
      subTitle: `Visitors and staff will not be able to access or use “${this.serviceData.displayName}” when disabled. Dont’ worry, you can enable it anytime.`,
      param: `services/${this.branchId}/${this.serviceId}/suspend`,
      bodyForm: { status: true },
      isConfirmDisabled: false,
      value1: this.serviceData.displayName,
    };
    this.modalRef = this.modalService.show(ModalWarningInputComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  enableService() {
    const param = `services/${this.branchId}/${this.serviceId}/suspend`;
    const bodyForm = { status: false };
    this.submitPatchForm(param, bodyForm)
      .then(async (data) => {
        this.toastr.success(
          `${this.serviceData.displayName} has been enabled`,
          "Service Enabled"
        );
        await this.refreshPage();
      })
      .catch(async (error) => {
        console.log("error", error);
        this.toastr.error("Changes has not been saved", "Error!");
        await this.refreshPage();
      });
  }

  archive() {
    // If One Left
    const initialState = {
      title: "Oops, This service cannot be archived",
      subTitle: `You cannot archive a service if there is only one remaining. 
      If you wish to archive this, create a new service or just edit its details.`,
      mainButtonText: "Okay, got it!",
    };
    this.modalRef = this.modalService.show(ModalWarningImageComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  patchForm(bodyForm) {
    const param = `services/${this.branchId}/${this.serviceId}`;
    this.submitPatchForm(param, bodyForm)
      .then(async (data) => {
        this.toastr.success("Changes has been saved", "Success!");
        await this.refreshPage();
      })
      .catch(async (error) => {
        console.log("error", error);
        this.toastr.error(
          "Changes has not been saved. Service name already in use.",
          "Error!"
        );
        await this.refreshPage();
      });
  }

  async refreshPage() {
    await this.getServiceList();
    await this.selectService(this.selectedServiceIndex);
  }

  patchFormObservable(bodyForm) {
    const param = `services/${this.branchId}/${this.serviceId}`;
    this.subscriptions.add(
      this.httpService.patch$(param, bodyForm).subscribe((data) => {
        // console.log("data", data);
      })
    )
  }

  async checkDataChange() {
    this.subscriptions.add(
      this.dataService.dataChange$.subscribe(async (res) => {
        if (res) {
          if (res.action === "Add Service") {
            await this.getServiceList();
            this.selectedServiceIndex = this.serviceLists.length - 1;
            this.serviceId = res.data.data._id;
            this.newServiceLists.push(this.serviceId);
            await this.selectService(this.selectedServiceIndex);
            await this.assignWindows(res.action);
          } else if (res.action === 'Delete Service') {
            await this.getServiceList();
            await this.selectService(0);
          }
          if (res.action === "assigned windows") {
            this.getServiceData();
          } else {
            await this.getServiceList();
            await this.selectService(this.selectedServiceIndex);
          }
        }
      })
    );
  }

  async ngOnInit() {
    this.branchId = this.activatedRoute.snapshot.paramMap.get("branchId");
    this.selectedServiceIndex = 0;
    let serviceId = null;
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params && params.type === "setup") {
        this.addService();
        this.authService.updateIntroLevel({ level: 3.1 }).subscribe((res) => {
          this.dataService.publishIntroLevel$(
            res.result.completedIntroductionLevels
          );
        });
      }
      if (params && params.selectedServiceId) {
        serviceId = params.selectedServiceId;
      }
    });
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe(async (res) => {
        if(res) {
          if (res && this.branchId !== res?._id) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigateByUrl(`/service/${res?._id}`);
            });
          }
          await this.handleBranchChange(res);
          await this.getBranchDetails();
          await this.getServiceList(serviceId);
          await this.selectService(this.selectedServiceIndex, serviceId);
          await this.checkDataChange();
          await this.checkToggleValue();
        }
      })
    );
  }

  async handleBranchChange(res: any): Promise<void> {
    this.branchId = res ? res._id : this.dataService.currentBranch$.branchId;
    this.pageDetails[1].routerLink = ["/branch-management", this.branchId];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.customFormFieldSubjectAPI$.next();
    this.customFormFieldSubjectAPI$.complete();
  }
}
