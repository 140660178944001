import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BusinessService } from "src/app/modules/auth/services/business.service";
import { FreeTrialExpiredComponent } from "src/app/modules/billing/components/free-trial-expired/free-trial-expired.component";
import { AuthService } from "../auth/auth.service";
import { HttpService } from "../http/http.service";
import { DataService } from "./data.service";

@Injectable()
export abstract class JwtService {
  accessToken = this.authService.accessToken$;
  accountId = this.authService.accountId$;
  assignedBranches = this.authService.assignedBranches$;

  constructor(
    public authService: AuthService,
    public http: HttpClient,
    public httpService: HttpService,
    public dataService: DataService,
    public businessService: BusinessService
  ) {}

  async checkAccessToken() {
    if (!this.authService.accessToken$) {
      await this.http
        .post<any>(`${this.authService.urlAPI}/authenticate/refresh-token`, {
          refreshToken: this.authService.getRefreshToken(),
        })
        .toPromise()
        .then(async (tokens) => {
          this.accessToken = tokens.accessToken;
          this.accountId = tokens.data.account._id;
          this.assignedBranches = tokens.data.assignedBranches;
          this.dataService.assignedBranches$ = tokens.data.assignedBranches;
          this.dataService.setIntroLevel(
            tokens.data.account.completedIntroductionLevels
              ? tokens.data.account.completedIntroductionLevels
              : []
          );

          const billingSettings = tokens.billingSettings
            ? tokens.billingSettings
            : tokens.data.billingSettings;
          let subscriptionPlan: any;
          let subscribed = false;
          if (billingSettings.subscription) {
            const address = billingSettings.customer.address;
            const billingSubscription = billingSettings.subscription;
            subscriptionPlan = {
              paymentMethod: billingSettings.paymentMethod ? billingSettings.paymentMethod : null,
              plan: billingSubscription.plan.metadata.plan,
              amount: billingSubscription.plan.amount,
              startDate:
              billingSubscription.current_period_start * 1000,
              endDate: billingSubscription.ended_at ? (billingSubscription.ended_at * 1000) : (billingSubscription.current_period_end * 1000),
              customerId: billingSettings.customerId,
              subscriptionId: billingSettings.subscriptionId,
              billingAddress: `${address.line1 ? address.line1 : ""} ${
                address.line2 ? address.line2 : ""
              } ${address.city ? address.city : ""} ${
                address.state ? address.state : ""
              } ${address.country ? address.country : ""}`,
              interval: billingSubscription.plan.interval,
              productId: billingSubscription.plan.product,
              cancelled: (billingSubscription.cancel_at || billingSubscription.canceled_at) ? true : false
            };

            subscribed = true;
          } else {
            subscriptionPlan = {
              paymentMethod: null,
              plan: billingSettings.subscriptionPlan,
              amount: 0,
              startDate: billingSettings.createdAt,
              endDate: billingSettings.expiredAt,
              customerId: "",
              subscriptionId: "",
              billingAddress: "",
              interval: "",
              productId: "",
              cancelled: false,
            };
          }

          this.dataService.setSubscriptionPlan(subscriptionPlan);

          // setTimeout(() => {
          //   this.businessService.checkSubscriptionExpiry(
          //     tokens.data.assignedBranches[0].roleLevel,
          //     true, subscriptionPlan
          //   );
          // });

          this.authService.storeAccessToken(this.accessToken);
          this.authService.storeAccountId(this.accountId);
          this.authService.storeBranches(this.assignedBranches);
          this.dataService.currentBranch$ = JSON.parse(localStorage.getItem('selectedBranch')) ?? this.assignedBranches[0];
          if (this.assignedBranches && this.assignedBranches.length > 0) {
            await this.getAccountData();
          }
        })
        .catch((error) => {
          console.log("error in jwt", error);
          this.authService.removeTokens();
        });
    }
  }

  async getAccountData() {
    const branchId = this.dataService.currentBranch$.branchId ?? this.assignedBranches[0].branchId;
    await this.httpService
      .get$(`accounts/${branchId}/${this.accountId}`)
      .toPromise()
      .then(async (data) => {
        this.dataService.accountData$ = data;
        // Current Window and Assigned Windows
        this.dataService.currentWindow$ = data?.attendingWindow;
        this.dataService.assignedWindowLists$ = data?.assignedWindows;
      });
  }
}
