import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnonymousSubject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket;
  test;
  time;

  constructor() { }

  connect(event): Subject<MessageEvent> {
    if (!this.socket) {
      this.socket = io(`${environment.socketAPI}`, {
        path: environment.socketPath
      });
    }

    const observable = new Observable(observer2 => {
      this.test = observer2;
      this.socket.on('connect', () => {
      });
      this.socket.on(event, (data) => {
        observer2.next(data);
      });
      this.socket.on('disconnect', () => {
        this.time = setTimeout(() => this.socket.connect(), 500);
      });
      return observable;
    });

    const observer = {
      next: (data: object) => {
        this.socket.emit('message', JSON.stringify(data));
      }
    };

    return Subject.create(observer, observable);
  }

  disconnect(){
    this.socket.disconnect();
  }
}
