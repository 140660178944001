import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { AppointmentService } from '../../services/appointment.service';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-appointment-email-page',
  templateUrl: './appointment-email-page.component.html',
  styleUrls: ['./appointment-email-page.component.scss'],
  providers: [TitleCasePipe]
})
export class AppointmentEmailPageComponent implements OnInit, OnDestroy {

  subscriptions = new Subscription();
  isLoading: boolean;
  branchId: string;
  appointmentId: string;
  status: string;
  message: string;
  fullName: string;
  accountId: string;
  roleLevel: string;

  constructor(
    private dataService: DataService,
    private appointmentService: AppointmentService,
    private activatedRoute: ActivatedRoute,
    private titleCasePipe: TitleCasePipe
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.branchId = this.dataService.accountData$.data.branchId;
    this.fullName = this.dataService.accountData$.data.firstName + ' ' + this.dataService.accountData$.data.lastName; 
    this.accountId = this.dataService.accountData$.data.accountId;
    this.roleLevel = this.dataService.accountData$.data.roleLevel;

    this.initValues();

    if(this.status === "approved") {
      this.approveAppointment();
    } else if(this.status === "rejected") {
      this.rejectAppointment();
    }
  }

  public initValues(){
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params)=>{
        this.appointmentId = params.appointmentId;
        this.status = params.status;
      })
    )
  }

  public approveAppointment(){
    const body = {
      approved: true,
      appointmentId: this.appointmentId
    }

    this.subscriptions.add(
      this.appointmentService.approveDeclineAppointment(this.branchId, body).subscribe(
        (res) => {
          this.message = 'approved';
          this.isLoading = false;
        },
        (error) => {
          this.message = "error encountered";
          this.isLoading = false;
        }
      )
    )
  }

  public rejectAppointment(){
    const body = {
      status: 'rejected',
      remarks: `Rejected by ${this.fullName}`,
      completedBy: {fullname: this.fullName, accountId: this.accountId, roleLevel: this.roleLevel },
      queueGroupId: "",
    }

    this.subscriptions.add(
      this.appointmentService.moveAppointment(this.branchId, body, this.appointmentId).subscribe(
        (res) => {
          this.message = 'rejected';
          this.isLoading = false;
        },
        (error) => {
          this.message = "error encountered";
          this.isLoading = false;
        }
      )
    )
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
