import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-max-visitors-modal',
  templateUrl: './max-visitors-modal.component.html',
  styleUrls: ['./max-visitors-modal.component.scss']
})
export class MaxVisitorsModalComponent implements OnInit {

  constructor(
    public modalRef: BsModalRef,
  ) { }

  close() {
    this.modalRef.hide();
  }

  ngOnInit(): void {
  }

}
