export enum BusinessEnum {
    foodAndBeverage = "Food and Beverage",
    foodAndBeverages = "Food and Beverages",
    healthcare = "Healthcare",
    healthAndWellness = "Health and Wellness",
    utilities = "Utilities",
    restaurant = "Restaurant",
    foodStand = "Food Stand",
    healthClinic = "Health Clinic",
    spaSalon = "Spa / Salon",
    veterinary = "Veterinary",
    serviceProviders = "Service Providers",
    billsAndPayments = "Bills / Payments",
    manpower = "Manpower",
    others = "Others",
    virtualQueueing = "Virtual Queueing",
    selfServiceKiosk = "Self-service Kiosk",
    greetInPerson = "Greet in Person",
    textMessaging = "Text Messaging",
    tvDisplay = "TV Display",
    callInPerson = "Call in Person",
    retail = "Retail",
    bankingAndFinance = "Banking and Finance"
}
