import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subject, Subscription, throwError } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { DeleteBranchModalComponent } from "../../component/delete-branch-modal/delete-branch-modal.component";
import { EditAddressModalComponent } from "../../component/edit-address-modal/edit-address-modal.component";
import { EditBranchModalComponent } from "../../component/edit-branch-modal/edit-branch-modal.component";
import { BranchManagementService } from "../../services/branch-management.service";
import { catchError, finalize, map, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "app-branch-details-page",
  templateUrl: "./branch-details-page.component.html",
  styleUrls: ["./branch-details-page.component.scss"],
  providers: [DatePipe],
})
export class BranchDetailsPageComponent implements OnInit, OnDestroy {
  pageDetails = [
    { type: "main", name: "Branches", routerLink: ["/branch-management"] },
    { type: "current", name: "", routerLink: null },
  ];
  isLoading = false;
  branchId: string;
  branchData: any;
  serviceCount: any;
  teamCount: any;
  windowCount: any;
  operationDays: any;

  currentBranch = this.branchService.getSelectedBranch();

  branches: any;
  assignedBranches: any;
  // testData = {
  //   branchName: 'Petzania Animal Clinic',
  //   branchEmail: 'petzaniaclinic@gmail.com',
  //   mobileNo: '9102388789'
  // }
  subscriptions = new Subscription();
  isDeleteAvailable = false;
  tvList: any;

  weekDates = [];
  dateToday = new Date(); // get current date
  dateTodayIndex = this.dateToday.getDay(); // get current date
  dayList = [
    { label: "Sunday", value: 0 },
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
  ];
  openToday = false;
  // branchesList: [] = [];
  private branchPageSubjectAPI$ = new Subject<void>();
  isBranchLoaded: boolean = false;
  isBranchExists: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    private branchService: BranchManagementService,
    private router: Router,
    private dataService: DataService,
    private datePipe: DatePipe
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    this.branchId = this.activatedRoute.snapshot.paramMap.get("branchId");
    const branchData = await this.getBranches();
    if (branchData.isBranchLoaded) {
      if (branchData.isBranchExists) {
        this.subscriptions.add(
          this.dataService.branchChange$.subscribe(async (res) => {
            if (res && this.branchId !== res?._id) {
              this.router
                .navigateByUrl("/", { skipLocationChange: true })
                .then(() => {
                  this.router.navigateByUrl(`/branch-management/${res?._id}`);
                });
            }
            await this.handleBranchChange(res);
          })
        );
        this.getWeek();
        await this.checkDeleteButton();
        this.getBranchDetails();
        this.getDeviceList();
      } else {
        this.router.navigate(["/branch-management"]);
      }
    }
    this.isLoading = false;
  }

  async getBranches(): Promise<{
    isBranchLoaded: boolean;
    isBranchExists: boolean;
  }> {
    return new Promise<{ isBranchLoaded: boolean; isBranchExists: boolean }>(
      (resolve) => {
        this.subscriptions.add(
          this.branchService
            .getBranchList()
            .pipe(
              takeUntil(this.branchPageSubjectAPI$),
              map((res) => res.data),
              tap((data) => {
                this.isBranchExists = data.some(
                  (item: { _id: string; }) => item._id === this.branchId
                );
                this.isBranchLoaded = true;
              }),
              catchError((error) => {
                console.log(
                  "Error on fetching business monitoring dashboard data",
                  error
                );
                return throwError(error);
              }),
              finalize(() => {
                resolve({
                  isBranchLoaded: this.isBranchLoaded,
                  isBranchExists: this.isBranchExists,
                });
              })
            )
            .subscribe()
        );
      }
    );
  }

  async handleBranchChange(res: any): Promise<void> {
    this.branchId = res ? res._id : this.dataService.currentBranch$.branchId;
  }

  getDeviceList() {
    this.branchService.getDeviceList(this.branchId).subscribe(
      (res) => {
        this.tvList = [];
        if (res.data) {
          this.tvList = res.data.filter((x) => x.status === "active");
        }
      },
      (error) => {
        console.log("DEVICE LIST", error);
      }
    );
  }

  async checkDeleteButton() {
    this.subscriptions.add(
      this.branchService.branches$.subscribe((res) => {
        this.branches = res;
      })
    );

    this.subscriptions.add(
      this.branchService.assignedBranches$.subscribe((res) => {
        this.assignedBranches = res;
      })
    );
    console.log(this.assignedBranches)
    console.log(this.branches)
    if (this.assignedBranches && this.branches) {
      const role = this.assignedBranches.find(
        (x) => x.branchId === this.branchId
      )?.roleLevel;
      if (role == "owner" && this.branches.length > 1 && this.branchId !== this.assignedBranches[0].branchId) {
        this.isDeleteAvailable = true;
      }
    }
  }

  setupBranch() {
    this.router.navigate(["/branch-management/setup", this.branchId]);
  }

  getBranchDetails() {
    this.branchService.getBranchDetails(this.branchId).subscribe((res) => {
      this.branchData = res.data;
      this.pageDetails[1].name = this.branchData.name;
      this.dataService.publishNewBranch$(this.branchData);
    });

    this.branchService.getBranchWindowsCount(this.branchId).subscribe((res) => {
      this.windowCount = res.data;
    });

    this.branchService
      .getBranchServicesCount(this.branchId)
      .subscribe((res) => {
        this.serviceCount = res.data;
      });

    this.branchService.getBranchTeamsCount(this.branchId).subscribe((res) => {
      this.teamCount = res.data;
    });

    this.branchService.getOperationDays(this.branchId).subscribe((res) => {
      this.operationDays = res.data;
      if (this.operationDays[this.dateTodayIndex].operationHours.length > 0) {
        this.openToday = true;
      }
    });
  }

  private getWeek() {
    const curr = new Date(); // get current date
    const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    const firstday = new Date(curr.setDate(first)).toUTCString();

    this.weekDates = [];
    this.weekDates.push(this.datePipe.transform(firstday, "MM/dd/yyyy"));

    for (let index = 0; index < 6; index++) {
      const day = new Date(firstday).setDate(
        new Date(firstday).getDate() + (index + 1)
      );
      this.weekDates.push(this.datePipe.transform(day, "MM/dd/yyyy"));
    }
  }

  deleteBranch() {
    let modalSizeClass = "";
    const canDelete = true;

    if (canDelete) {
      modalSizeClass = "modal-sm";
    }

    const initialState = {
      title: "Delete this branch?",
      canDelete: canDelete,
      branchId: this.branchId,
      branchName: this.branchData.name,
    };

    this.modalRef = this.modalService.show(DeleteBranchModalComponent, {
      initialState: initialState,
      class: `modal-dialog-centered ${modalSizeClass}`,
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  branchSettings() {
    this.router.navigate(["/settings"], {
      queryParams: { type: "branch" },
    });
  }

  editBranch() {
    const initialState = {
      title: "Edit Branch",
      branchData: this.branchData,
    };

    this.modalRef = this.modalService.show(EditBranchModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-xl m-0",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        this.getBranchDetails();
      },
      (err) => {
        console.log("edit branch", err);
        return false;
      }
    );
  }

  editBranchAddress() {
    const initialState = {
      title: "Edit Branch",
      branchData: this.branchData,
    };

    this.modalRef = this.modalService.show(EditAddressModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-xl m-0",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        this.getBranchDetails();
      },
      (err) => {
        console.log("edit address", err);
        return false;
      }
    );
  }

  goToWindows() {
    // this.router.navigate(['window']);
    this.router.navigate(["window", this.branchId]);
  }

  goToServices() {
    this.router.navigate(["service", this.branchId]);
  }

  goToTeams() {
    this.router.navigate(["team-management"]);
  }

  setupTvDisplay() {
    this.router.navigate(["/branch-management/tv", this.branchId]);
  }

  operationHoursDetails() {
    this.router.navigate(["/branch-management/operation-hours", this.branchId]);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
