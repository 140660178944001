import { Injectable } from '@angular/core';
import { IFeedback } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class FeedbackDataService {

  constructor() { }

  private _feedbackData: IFeedback;

  setData(data: IFeedback): void {
    this._feedbackData = data;
  }

  public get feedbackData(): IFeedback {
    return this._feedbackData;
  }

}
