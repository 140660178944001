import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-cancel-confirm',
  templateUrl: './button-cancel-confirm.component.html',
  styleUrls: ['./button-cancel-confirm.component.scss']
})
export class ButtonCancelConfirmComponent implements OnInit {
  @Input() cancelText: string;
  @Input() confirmText: string;
  @Input() confirmClass: string;
  @Input() isConfirmDisabled: boolean;
  @Input() isCancelDisabled: boolean;
  @Output() cancelled = new EventEmitter();
  @Output() confirmed = new EventEmitter();

  @Input() isSubmitting: string;
  @Input() submittingText: string;

  constructor() { }

  cancel() {
    this.cancelled.emit('cancel');
  }

  confirm() {
    this.confirmed.emit('confirm');
  }

  ngOnInit(): void {
  }

}
