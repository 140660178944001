import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-edit-billing-contact',
  templateUrl: './edit-billing-contact.component.html',
  styleUrls: ['./edit-billing-contact.component.scss']
})
export class EditBillingContactComponent implements OnInit {
  title: any;
  subTitle: any;
  emailAddress = '';

  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    if(!this.emailAddress){
      this.emailAddress = 'robert@petzania.com';
    }
  }

  updateBillingContact() {
    this.toastr.success("Billing contact has been updated", "Success!");
    this.modalRef.hide();
    console.log(this.emailAddress);
  }

}
