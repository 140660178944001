import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-select-view-modal',
  templateUrl: './select-view-modal.component.html',
  styleUrls: ['./select-view-modal.component.scss']
})
export class SelectViewModalComponent implements OnInit {
  title: string;
  subtitle: string;
  filterType: string = '';
  serviceText: string;
  windowText: string;
  selectedValue: string = '';
  customReport: boolean = false;
  @Output() selectedView = new EventEmitter();

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    if (this.filterType != '') {
      this.selectView(this.filterType);
    }
  }

  selectView(value){
    this.selectedValue = value;
  }

  cancel() {
    this.selectedView.emit('cancel');
    this.bsModalRef.hide();
  }

  closeModal() {
    this.selectedView.emit('close');
    this.bsModalRef.hide();
  }

  proceed() {
    this.selectedView.emit(this.selectedValue);
    this.bsModalRef.hide();
  }
}
