import { HttpParams } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { AuthService } from "src/app/core/auth/auth.service";
import { SocketService } from "src/app/core/http/socket.service";
import { DataService } from "src/app/core/services/data.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { CodeLoginModalComponent } from "../../component/code-login-modal/code-login-modal.component";
import { DeleteTvModalComponent } from "../../component/delete-tv-modal/delete-tv-modal.component";
import { EditTvModalComponent } from "../../component/edit-tv-modal/edit-tv-modal.component";
import { ViewTvDetailsModalComponent } from "../../component/view-tv-details-modal/view-tv-details-modal.component";
import { BranchManagementService } from "../../services/branch-management.service";

@Component({
  selector: "app-branch-tv-setup-page",
  templateUrl: "./branch-tv-setup-page.component.html",
  styleUrls: ["./branch-tv-setup-page.component.scss"],
})
export class BranchTvSetupPageComponent implements OnInit, OnDestroy {
  pageDetails = [
    { type: "main", name: "Branches", routerLink: ["/branch-management"] },
    { type: "sub", name: "", routerLink: null },
    { type: "current", name: "TV Display", routerLink: null },
  ];
  searchString: string;
  searchSubject: Subject<string> = new Subject();
  tvList: any;
  branchData: any;
  selectedRole = [];
  branchId: string;
  tvSocket: BehaviorSubject<any>;
  subscriptions = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute,
    private branchService: BranchManagementService,
    private loaderService: LoaderService,
    private wsService: SocketService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private authService: AuthService,
    private dataService: DataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.branchId = this.activatedRoute.snapshot.paramMap.get("branchId");
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe(async (res) => {
        if (res && this.branchId !== res?._id) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(`/branch-management/tv/${res?._id}`);
          });
        }
        await this.handleBranchChange(res);

        this.activatedRoute.queryParams.subscribe(async (params) => {
          if (params && params.type === "setup") {
            this.authService
              .updateIntroLevel({ level: 5.2 })
              .subscribe((res) => {
                this.dataService.publishIntroLevel$(
                  res.result.completedIntroductionLevels
                );
              });
            await this.addTv();
          }
        });

        await this.getBranchDetails();
        await this.getDeviceList();

        this.tvSocket = this.wsService
          .connect(`v4::tv-display::login-via-code::${this.branchId}`)
          .pipe(
            map((response: any): any => {
              return response;
            })
          ) as BehaviorSubject<any>;
        this.subscriptions.add(
          this.tvSocket.subscribe(async (msg) => {
            if (msg) {
              this.modalRef.hide();
              await this.getDeviceList();
              this.toastr.success(
                `${msg.payload.deviceName} has been added`,
                "Success!"
              );
            }
          })
        );
      })
    );

    this.searchSubject
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((searchTextValue) => {
        this.itemsPerPage = 10;
        if (searchTextValue) {
        } else {
        }
      });
  }

  async handleBranchChange(res: any): Promise<void> {
    this.branchId = res ? res._id : this.dataService.currentBranch$.branchId;
    this.pageDetails[1].routerLink = ["/branch-management", this.branchId];
  }

  async getBranchDetails(): Promise<void> {
    const res = await this.branchService
      .getBranchDetails(this.branchId)
      .toPromise();
    this.branchData = res.data;
    this.pageDetails[1].name = this.branchData.name;
  }

  async addTv() {
    this.loaderService.isLoading.next(true);
    this.subscriptions.add(
      this.branchService.getDeviceCode(this.branchId).subscribe(
        (res) => {
          this.loaderService.isLoading.next(false);
          this.showCodeModal(res.result.code);
        },
        (error) => {
          console.log("BRANCH CODE", error);
          this.loaderService.isLoading.next(false);
        }
      )
    );
  }

  showCodeModal(code) {
    const initialState = {
      code: code,
      title: "Link TV Display",
    };

    this.modalRef = this.modalService.show(CodeLoginModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.generateNewCode.subscribe(
      () => {
        this.addTv();
      },
      (err) => {
        console.log("generate new code", err);
        return false;
      }
    );
  }

  async getDeviceList() {
    this.subscriptions.add(
      this.branchService.getDeviceList(this.branchId).subscribe(
        (res) => {
          this.tvList = res;
        },
        (error) => {
          console.log("DEVICE LIST", error);
        }
      )
    );
  }

  displaySettings(data) {
    // console.log("tv settings", data);
  }

  viewTvDetails(id, data) {
    const initialState = {
      title: "View TV Details",
      tvId: id,
      branchId: this.branchId,
      tvDetails: data,
    };
    this.modalRef = this.modalService.show(ViewTvDetailsModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.removeTvEvent.subscribe(
      (data) => {
        this.getDeviceList();
      },
      (err) => {
        console.log("", err);
        return false;
      }
    );
  }

  deleteTv(data) {
    const initialState = {
      title: "Remove TV?",
      tvId: data._id,
      branchId: this.branchId,
      tvName: data.deviceName,
    };
    this.modalRef = this.modalService.show(DeleteTvModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        this.getDeviceList();
      },
      (err) => {
        console.log("", err);
        return false;
      }
    );
  }

  editTv(data) {
    const initialState = {
      title: "TV Settings",
      subTitle: "Changes will be reflected in real-time. ",
      tvId: data._id,
      branchId: this.branchId,
      tvName: data.deviceName,
    };
    this.modalRef = this.modalService.show(EditTvModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        this.getDeviceList();
      },
      (err) => {
        console.log("", err);
        return false;
      }
    );
  }

  public searchTv() {
    this.searchSubject.next(this.searchString);
  }

  items = 100;
  pageChange(page) {
    this.selectedPage = page.page;
  }

  itemsPerPage = 10;
  selectedPage = 1;
  entriesChange(a) {
    this.itemsPerPage = a;
  }

  get params() {
    let params = new HttpParams();
    params = params.append("limit", this.itemsPerPage.toString());
    const offset = this.selectedPage * this.itemsPerPage - this.itemsPerPage;
    params = params.append("offset", offset.toString());

    return params;
  }

  clearStatusFilter() {
    this.selectedRole = [];
    const usersCheckbox = document.getElementsByName("status-checkbox") as any;
    const allUsersCheckbox = document.getElementsByName(
      "all-status-checkbox"
    ) as any;
    allUsersCheckbox[0].checked = false;
    usersCheckbox.forEach((element) => {
      element.checked = false;
    });
  }

  selectAllStatusFilter(isChecked) {
    const usersCheckbox = document.getElementsByName("status-checkbox") as any;
    this.selectedRole = [];

    if (isChecked) {
      this.selectedRole.push("active");
      this.selectedRole.push("offline");
    }

    usersCheckbox.forEach((element) => {
      element.checked = isChecked;
    });
  }

  selectStatusFilter(id) {
    if (this.selectedRole.includes(id)) {
      const index = this.selectedRole.indexOf(id);
      if (index > -1) {
        this.selectedRole.splice(index, 1);
      }
    } else {
      this.selectedRole.push(id);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
