import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { AppointmentSettingsService } from '../../services/appointment-settings.service';
import { ISettingsFields } from '../../interfaces/appointment-settings';
import { getTimeInMilliseconds } from '../../constants/appointment';

@Component({
  selector: 'app-appointment-settings',
  templateUrl: './appointment-settings.component.html',
  styleUrls: ['./appointment-settings.component.scss']
})
export class AppointmentSettingsComponent implements OnInit, OnDestroy {
  isLoading = false;
  isChanged = false;
  subscriptions = new Subscription();
  selectedBranch = this.dataService.currentBranch$;
  selectedBranchId = this.selectedBranch.branchId;
  operatingHours: Array<any> = [];
  days: Array<any> = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  currentSettings: any;
  currentSettingsId: string;
  appointmentSettingsFeaturesEnabled: any = [];
  featuresStatus: any = {};
  bookAppointmentToday: ISettingsFields = {status: false, value: 0};
  earlyAppointmentRange: ISettingsFields = {status: false, value: 0};
  lateAppointmentRange: ISettingsFields = {status: false, value: 0, milliseconds: 0};
  defaultInputFields: any = {
    timeslotInterval: {value: 30, type: "minutes"},
    visitorPerTimeslot:  1,
    leadTime: {value: 0, type: "minutes"},
    scheduleRange: {value: 1, type: "months"},
    lateAppointmentTimeRange: {value: 1, type: "minutes"},
    appointmentExpireAfter: {value: 1, type: "days"}
  };
  inputFields: any = this.defaultInputFields;
  setLateAppointment = [];
  setExpiredAppointment = [];

  appointmentHours = [
    { value: "Same as Operation Hours", label: "Same as Operation Hours" }
  ]
  timeList = [
    { value: "minutes", label: "Minute(s)" },
    { value: "hours", label: "Hour(s)" },
    { value: "days", label: "Day(s)" },
    { value: "weeks", label: "Week(s)" },
    { value: "months", label: "Month(s)" },
  ];
  timeList1 = this.timeList.slice(0,2);
  timeList2 = this.timeList.slice(3,5);
  timeList3 = this.timeList.slice(2,5);
  setLateAppointmentAs = [
    { value: "no action", label: "No action" }
  ]
  setExpiredAppointmentAs = [
    { value: "no show", label: "No Show (Remove from appointments list)" }
  ]

  constructor(
    private dataService: DataService,
    private appointmentSettingsService: AppointmentSettingsService,
    public toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe((res) => {
        if(res) {
        this.selectedBranchId = res.branchId ?? this.selectedBranchId;    
        this.featuresStatus = {};
        this.getAppointmentSetting();
        this.getOperatingHours();
        this.isLoading = false;
      }
    })) 
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getAppointmentSetting() {
    this.subscriptions.add(
      this.appointmentSettingsService.getSettings(this.selectedBranchId)
        .subscribe((res)=>{
          this.setInitialValues(res);
      })
    )
  }

  getOperatingHours(){
    let operationHours = "";
    this.operatingHours = [];
    this.subscriptions.add(
      this.appointmentSettingsService.getOperationHours(this.selectedBranchId)
        .subscribe((res)=>{
          res.data.forEach(element => {
            if(element.operationHours.length > 0){
              const opening = this.getFormattedTime(element.operationHours[0].openingTime);
              const closing = this.getFormattedTime(element.operationHours[0].closingTime);
              operationHours = opening + " - " + closing;
            }else{
              operationHours = "Closed";
            }

            this.operatingHours.push({day: this.days[element.day], time: operationHours});
          });
        })
    )
  }

  getFormattedTime(milliseconds: number){
    let hours = Math.floor(milliseconds/(1000 * 60 * 60));
    let minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const ampm = `${hours < 12 ? "AM" : "PM"}`;
    if(hours>12){
        hours = hours - 12;
    }
    if(minutes < 10){
      return `${hours}:0${minutes} ${ampm}`;
    }
    return `${hours}:${minutes} ${ampm}`;
  }

  setInitialValues(res) {
    this.currentSettings = res.data;
    this.currentSettingsId = this.currentSettings._id;
    this.inputFields = {
      timeslotInterval: this.currentSettings.timeslotInterval,
      visitorPerTimeslot: this.currentSettings.visitorPerTimeslot,
      leadTime: this.currentSettings.leadTime,
      scheduleRange: this.currentSettings.scheduleRange,
      lateAppointmentTimeRange: this.currentSettings.lateAppointmentTimeRange,
      appointmentExpireAfter: this.currentSettings.appointmentExpireAfter
    };
    this.setLateAppointment = this.currentSettings.setLateAppointment[0];
    this.setExpiredAppointment = this.currentSettings.setExpiredAppointment[0];
    this.appointmentSettingsFeaturesEnabled = this.currentSettings.featuresEnabled ? this.currentSettings.featuresEnabled : [];
    // toggle switches
    this.appointmentSettingsFeaturesEnabled.forEach(feature=>{
      this.featuresStatus[feature] = true;
    });
    this.isLoading = false;
  }
  
  changeInput(field, $event, dropdown?, other?){
    if(other){
      if(field === 'setLateAppointment'){
        this.setLateAppointment = [$event];
      }
      if(field === 'setExpiredAppointment'){
        this.setExpiredAppointment = [$event];
      }
      if(field === 'visitorPerTimeslot'){
        this.inputFields[field] = $event.value;
      }
    }
    else{
      if(dropdown){
        this.inputFields[field].type = $event;
        if(this.inputFields[field].type === this.defaultInputFields[field].type){
          this.inputFields[field].value = this.defaultInputFields[field].value;
        } else{
          this.inputFields[field].value = 1;
        }
      } else{
        this.inputFields[field].value = $event.value;
      }
    }
    this.isChanged = true;
  }

  getMaxTime(type: string){
    switch(type){
      case "minutes":
        return 59;
      case "hours":
        return 23;
      case "days":
        return 6;
      case "weeks":
        return 4;
      case "months":
        return 12;
    }
  }

  changeToggle(feature: string){
    if(this.featuresStatus[feature]){
      delete this.featuresStatus[feature];
    } else{
      this.featuresStatus[feature] = true;
    }
    this.isChanged = true;
  }

  revertChanges() {
    this.isChanged = false;
    this.ngOnInit();
    // this.setInitialValues();
  }

  saveChanges(){
    this.isLoading = true;
    const appointmentSettings = {
      featuresEnabled: Object.keys(this.featuresStatus),
      setLateAppointment: this.setLateAppointment,
      setExpiredAppointment: this.setExpiredAppointment
    }

    for (let key in this.inputFields){
      const field = this.inputFields[key];
      if(field.type){
        if(key === 'lateAppointmentTimeRange'){
          appointmentSettings[key] = {type: field.type, value: field.value, milliseconds: getTimeInMilliseconds({type: field.type, value: field.value})};
        } else {
          appointmentSettings[key] = {type: field.type, value: field.value};
        }
      } else{
        if(key === 'visitorPerTimeslot'){
          appointmentSettings[key] = field;
        } else{
          appointmentSettings[key] = field.value;
        }
      }
    }

    this.subscriptions.add(
      this.appointmentSettingsService.updateSettings(this.selectedBranchId, this.currentSettingsId, appointmentSettings)
        .subscribe((res)=>{
          this.toastr.success("Appointment Settings has been updated", "Success!");
          this.setInitialValues(res);
        })
    )
  }
}
