import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { BranchManagementService } from "../../services/branch-management.service";
import { DataService } from "src/app/core/services/data.service";

@Component({
  selector: "app-delete-branch-modal",
  templateUrl: "./delete-branch-modal.component.html",
  styleUrls: ["./delete-branch-modal.component.scss"],
})
export class DeleteBranchModalComponent implements OnInit {
  title: string;
  confirmDelete = "";
  isSubmitting = false;
  canDelete: boolean;
  branchId: string;
  branchName: string;

  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private router: Router,
    private branchService: BranchManagementService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {}

  deleteBranch() {
    this.isSubmitting = true;

    this.branchService.deleteBranch(this.branchId).subscribe(
      (res) => {
        this.isSubmitting = false;
        this.bsModalRef.hide();
        this.toastr.error(
          `${this.branchName} branch has been deleted`,
          "Deleted!"
        );

        this.router.navigate(["/branch-management"]);
      },
      (error) => {
        console.log("error", error);
        this.isSubmitting = false;
      }
    );
  }

  get isDeleteDisabled() {
    return this.confirmDelete.toLowerCase() != "delete branch";
  }
}
