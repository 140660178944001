import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DataService } from 'src/app/core/services/data.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProcedureService {
  apiUrl = environment.urlAPI;
  accountId = this.authService.accountId$;
  accountData = this.dataService.accountData$
  branchId = this.accountData.data.branchId;
  
  constructor(private http: HttpClient, public authService: AuthService, private dataService: DataService) { }

  private get httpHeader(): any {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return {
      headers,
    };
  }

  public getProcedureTemplates(branchId, params?): Observable<any> {
    const url = `${this.apiUrl}/queues/procedure-templates/${branchId}?limit=999&${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getProcedureTemplateDetails(branchId, procedureTemplateId, params?): Observable<any> {
    const url = `${this.apiUrl}/queues/procedure-templates/${branchId}/${procedureTemplateId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public addProcedureDetails(branchId, details): Observable<any> {
    const url = `${this.apiUrl}/queues/procedure-templates/${branchId}`;
    return this.http.post(url, details).pipe(map((response) => response));
  }

  public updateProcedureServices(branchId, procedureTemplateId, details): Observable<any> {
    const url = `${this.apiUrl}/queues/procedure-templates/${branchId}/${procedureTemplateId}/procedures`;
    return this.http.patch(url, details).pipe(map((response) => response));
  }

  public updateProcedureDetails(branchId, procedureTemplateId, details): Observable<any> {
    const url = `${this.apiUrl}/queues/procedure-templates/${branchId}/${procedureTemplateId}`;
    return this.http.patch(url, details).pipe(map((response) => response));
  }

  public activateStatus(branchId, procedureTemplateId, details): Observable<any> {
    const url = `${this.apiUrl}/queues/procedure-templates/${branchId}/${procedureTemplateId}/activated`;
    return this.http.patch(url, details).pipe(map((response) => response));
  }

  public deleteServiceProcedureTemplate(branchId: string, procedureTemplateId: string): Observable<any> {
    const url = `${this.apiUrl}/queues/procedure-templates/${branchId}/${procedureTemplateId}`;
    return this.http.delete(url).pipe(map((response) => response));
  }
  
}
