import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-proceed-next-procedure-modal',
  templateUrl: './proceed-next-procedure-modal.component.html',
  styleUrls: ['./proceed-next-procedure-modal.component.scss']
})
export class ProceedNextProcedureModalComponent implements OnInit {

  @Output() actionEvent = new EventEmitter();

  title: any;
  subtitle: any;

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }

  onSelection(action){
    this.actionEvent.emit(action);
    this.bsModalRef.hide();
  }
}
