import { Component, OnInit } from "@angular/core";
import { UserManualCategory } from "../../../interfaces/support.interface";

@Component({
  selector: "app-support-user-manual-page",
  templateUrl: "./support-user-manual-page.component.html",
  styleUrls: ["./support-user-manual-page.component.scss"],
})
export class SupportUserManualPageComponent implements OnInit {
  helpCenterCategories: string = "";
  displayHelpCategories: boolean = true;
  searchText: string = "";
  dataArray: any;
  searchCategoryArray: Array<UserManualCategory>;
  pageDetails = [
    { type: "main", name: "Support", routerLink: ["/support"] },
    { type: "current", name: "User Manual", routerLink: null },
];
  userManualCategory: Array<UserManualCategory> = [
    {
      id: 0,
      filename: "User Manual - Biz App.pdf",
      category: "quick-setup",
      title: "Quick Setup For New Users",
      description: "Setup your business portal and devices using this guide.",
      linkData: "./assets/pdf/tutorial/User Manual - Biz App.pdf",
    },
    {
      id: 1,
      filename: "User Manual - Manager.pdf",
      category: "quick-setup-managers",
      title: "Quick Setup For Managers",
      description: "Learn how to make the most of Queue workspace",
      linkData: "./assets/pdf/tutorial/User Manual - Manager.pdf",
    },
    {
      id: 2,
      filename: "User Manual - Staff.pdf",
      category: "quick-setup-staff",
      title: "Quick Setup For Staff",
      description: "Learn how to add and manage new branches",
      linkData: "./assets/pdf/tutorial/User Manual - Staff.pdf",
    },
/*     {
      id: 3,
      path: "",
      category: "teams",
      title: "Teams",
      description: "Learn how to add and manage teams",
      linkData: "#",
    },
    {
      id: 4,
      path: "",
      category: "kyoo-applications",
      title: "Kyoo Applications",
      description:
        "Discover other features that might be useful to your business",
      linkData: "#",
    },
    {
      id: 5,
      path: "",
      category: "settings",
      title: "Settings",
      description: "Find out how you can use settings more effectively",
      linkData: "#",
    }, */
  ];

  helpCenterBtn(): void {
    this.displayHelpCategories = true;
    this.helpCenterCategories = "";
  }

  constructor() {}

  searchKey(inputText: string) {
    this.searchText = inputText;
    console.log(this.searchText);
    let searchCategory = this.userManualCategory.filter((data) =>
      data.title.toLowerCase().includes(this.searchText.toLowerCase())
    );
    console.log(searchCategory + " my data ::");

    this.dataArray = searchCategory;
  }

  ngOnInit(): void {
    this.dataArray = this.userManualCategory;
  }
}
