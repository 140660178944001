import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BranchManagementService } from 'src/app/modules/branch-management/services/branch-management.service';

@Component({
  selector: 'app-modal-reset-password',
  templateUrl: './modal-reset-password.component.html',
  styleUrls: ['./modal-reset-password.component.scss']
})
export class ModalResetPasswordComponent implements OnInit {
  selectedBranch = this.branchService.getSelectedBranch();

  constructor(private router: Router, private bsModalRef: BsModalRef, private branchService: BranchManagementService) { }

  ngOnInit(): void {
  }

  confirm(){
    this.router.navigate(['login']);
    this.bsModalRef.hide();
  }

}
