import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BranchManagementService } from "../../services/branch-management.service";
import { ClipboardService } from "ngx-clipboard";
import html2canvas from "html2canvas";
import { DataService } from "src/app/core/services/data.service";
import { AuthService } from "src/app/core/auth/auth.service";
import { ToastrService } from "ngx-toastr";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { EditBranchNoteModalComponent } from "../../component/edit-branch-note-modal/edit-branch-note-modal.component";
import { environment } from "src/environments/environment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-branch-setup-page",
  templateUrl: "./branch-setup-page.component.html",
  styleUrls: ["./branch-setup-page.component.scss"],
})
export class BranchSetupPageComponent implements OnInit, OnDestroy {
  pageDetails = [
    { type: "main", name: "Branches", routerLink: ["/branch-management"] },
    { type: "sub", name: "", routerLink: null },
    { type: "current", name: "Branch Site", routerLink: null },
  ];
  branchId: string;
  branchData: any;
  currentBranch = this.branchService.getSelectedBranch();
  isOpen = false;
  siteUrl = `http://www.kyoo.com/site/petzania-animal-clinic/bgc-taguig`;
  branchSiteUrl = environment.branchSiteUrl;

  branchSetupData: any;
  businessSlugName: any;
  branchSlugName: any;
  subscriptions = new Subscription();
  @ViewChild("pop", { static: false }) pop: any;

  @ViewChild("screen") screen: any;
  @ViewChild("canvas") canvas: ElementRef;
  @ViewChild("downloadLink") downloadLink: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private branchService: BranchManagementService,
    private authService: AuthService,
    private clipboardApi: ClipboardService,
    private dataService: DataService,
    private toastr: ToastrService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.branchId = this.activatedRoute.snapshot.paramMap.get("branchId");
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params && params.type === "setup") {
        this.authService.updateIntroLevel({ level: 4.1 }).subscribe((res) => {
          this.dataService.publishIntroLevel$(
            res.result.completedIntroductionLevels
          );
        });
      }
    });

    this.subscriptions.add(
      this.dataService.branchChange$.subscribe(async (res) => {
        if (this.currentBranch && this.currentBranch.roleLevel === "staff") {
          this.router.navigateByUrl('/', { skipLocationChange: true });
        } else {
          if (res && this.branchId !== res?._id) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigateByUrl(`/branch-management/setup/${res?._id}`);
            });
          }
        }
        await this.handleBranchChange(res);
        await this.getBranchDetails();
        await this.getBranchSetup();
      })
    );
  }

  async handleBranchChange(res: any): Promise<void> {
    this.branchId = res ? res._id : this.dataService.currentBranch$.branchId;
    this.pageDetails[1].routerLink = ["/branch-management", this.branchId];
  }

  async getBranchDetails() {
    this.subscriptions.add(
      this.branchService.getBranchDetails(this.branchId).subscribe((res) => {
        this.branchData = res.data;
        this.pageDetails[1].name = this.branchData.name;
        this.businessSlugName = this.branchData.business.slugName;
        this.branchSlugName = this.branchData.slugName;
        this.siteUrl = `${this.branchSiteUrl}/${this.businessSlugName}/${this.branchSlugName}`;
      })
    );
  }

  async getBranchSetup() {
    this.subscriptions.add(
      this.branchService.getBranchSetup(this.branchId).subscribe((res) => {
        this.branchSetupData = res.data.website;
        // this.siteUrl = this.branchSetupData?.url;
      })
    );
  }

  openBranchSite() {
    window.open(this.siteUrl, "_blank");
  }

  copyToClipboard() {
    this.isOpen = !this.isOpen;
    setTimeout(() => {
      this.isOpen = !this.isOpen;
    }, 1500);
    this.clipboardApi.copyFromContent(this.siteUrl);
  }

  saveAsImage() {
    html2canvas(this.screen.qrcElement.nativeElement).then((canvas) => {
      let pdf = canvas.toDataURL();
      this.canvas.nativeElement.src = pdf;
      this.downloadLink.nativeElement.href = pdf;
      this.downloadLink.nativeElement.download = `${this.currentBranch.name}-${this.branchData.name}-QR.jpeg`;

      this.downloadLink.nativeElement.click();
    });
  }

  updateBranchSetup(toggle, value) {
    const features = this.branchSetupData.features;
    if (toggle && !features.includes(value)) {
      features.push(value);
    } else {
      if (features.includes(value)) {
        const index = features.indexOf(value);
        if (index > -1) {
          features.splice(index, 1);
        }
      }
    }
    const body = {
      website: {
        memo: this.branchSetupData.memo ? this.branchSetupData.memo : "",
        features: features,
      },
    };

    this.subscriptions.add(
      this.branchService
        .updateBranchSetup(this.branchId, body)
        .subscribe((res) => {
          this.branchSetupData = res.data.website;
          // this.siteUrl = this.branchSetupData?.url;
          this.toastr.success("Changes has been saved", "Success!");
        })
    );
  }

  editBranchNote() {
    const initialState = {
      title: "Edit Visiting Notes",
      branchNote: this.branchSetupData.memo,
    };

    this.modalRef = this.modalService.show(EditBranchNoteModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        const body = {
          website: {
            memo: data,
            features: this.branchSetupData.features,
          },
        };

        this.subscriptions.add(
          this.branchService
            .updateBranchSetup(this.branchId, body)
            .subscribe((res) => {
              this.branchSetupData = res.data.website;
              // this.siteUrl = this.branchSetupData?.url;
              this.toastr.success("Changes has been saved", "Success!");
            })
        );
      },
      (err) => {
        console.log("edit note", err);
        return false;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
