import { HostListener, Injectable } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TeamManagementService } from 'src/app/modules/team-management/services/team-management.service';
import { AuthService } from '../auth/auth.service';
import { HttpService } from '../http/http.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  title: any;
  subTitle: any;
  caption: string;
  confirmClass: string;
  isConfirmDisabled: boolean;
  validators: Array<any>;
  param: string;
  bodyForm: any;
  value1: string;
  accountId = this.authService.accountId$;

  constructor(
    public bsModalRef: BsModalRef,
    public httpService: HttpService,
    public dataService: DataService,
    public toastr: ToastrService,
    public authService: AuthService,
  ) { }

  changeRadio($event) {}

  changeInputWarning($event) {
    this.isConfirmDisabled = !$event.valid;
  }

  showToast(title) {
    switch (title) {
      case 'Reset Queue Number': {
        this.toastr.info('Queue number has been reset', 'Done!');
      }
      break;
      case 'Disable this service?': {
        this.toastr.error(`${this.value1} has been disabled`, 'Service Disabled');
      }
      break;
      case 'Disable this window?': {
        this.toastr.error(`${this.value1} has been disabled`, 'Window Disabled');
      }
      break;
      default: {
        this.toastr.success('Changes has been saved', 'Success!');
      }
      break;
    }
  }

  showToastError(msg) {
    switch (this.title) {
      case 'Reset Queue Number': {
        this.toastr.info('Queue number has been reset', 'Done!');
      }
      break;
      case 'Disable this service?': {
        this.toastr.error(`${msg}`, 'Error!');
      }
      break;
      case 'Disable this window?': {
        this.toastr.error(`${msg}`, 'Error!');
      }
      break;
    }
  }

  cancel() {
    this.bsModalRef.hide();
  }

  cancelWarning() {
    this.bsModalRef.hide();
  }

  @HostListener('document:keydown.enter')
  confirmWarning($event) {

    if (!this.isConfirmDisabled && this.param) {
      this.httpService.patch$(this.param, this.bodyForm ? this.bodyForm : '').subscribe(
        data => {
          this.dataService.publishDataChange$(this.title);
          this.bsModalRef.hide();
          setTimeout(() => {
            this.showToast(this.title);
          }, 500);
        }, error => {
          this.bsModalRef.hide();
          this.showToastError(error.error.message ? error.error.message : error.error.errors ? error.error.errors : 'Please try again!');
          console.log('error', error);
        }
      )
    }    
  }
  
}
