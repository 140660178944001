import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { GenericService } from "src/app/core/services/generic.service";
import { BranchManagementService } from "../../services/branch-management.service";

@Component({
  selector: "app-edit-branch-modal",
  templateUrl: "./edit-branch-modal.component.html",
  styleUrls: ["./edit-branch-modal.component.scss"],
})
export class EditBranchModalComponent implements OnInit {
  title: string;
  @Output() successEvent = new EventEmitter();

  branchForm = this.fb.group({
    branchName: ["", Validators.required],
    email: ["", [Validators.pattern(/^\S+@\S+\.[a-zA-z]+$/)]],
    mobileNo: ["", Validators.minLength(10)],
  });

  inputFocus: string;
  isSubmitting = false;

  branchData: any;

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private branchService: BranchManagementService,
    private toastr: ToastrService,
    private genericService: GenericService
  ) {}

  ngOnInit() {
    console.log(this.branchData);
    if (this.branchData) {
      this.branchForm.controls.branchName.patchValue(this.branchData.name);
      this.branchForm.controls.email.patchValue(this.branchData.email);
      this.branchForm.controls.mobileNo.patchValue(
        this.genericService.patchMobile(this.branchData.mobileNo)
      );
    }
  }

  changeInput(ev){
    this.branchForm.value.mobileNo = ev.value;
    this.branchForm.markAsDirty();
  }

  get f() {
    return this.branchForm.controls;
  }

  saveChanges() {
    const formValue = this.branchForm.value;

    const formData = {
      name: this.branchForm.value.branchName,
      email: this.branchForm.value.email,
      mobileNo: this.branchForm.value.mobileNo ? this.branchForm.value.mobileNo.replaceAll("-", "") : '',
      address: this.branchData.address,
      coordinates: {
        lat: this.branchData.location.coordinates[0],
        lng: this.branchData.location.coordinates[1],
      },
    };

    this.branchService
      .editBranchDetails(formData, this.branchData._id)
      .subscribe(
        (res) => {
          console.log("EDIT BRANCH SUCCESS", res);
          this.successEvent.emit(res.data);
          this.isSubmitting = false;
          this.bsModalRef.hide();
          this.toastr.success("Branch has been updated", "Success!");
        },
        (error) => {
          console.log("error", error);
          this.isSubmitting = false;
        }
      );
  }
}
