import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  // Account Details
  accountData$: any;
  branchData$: any;
  currentBranch$: any;
  assignedBranches$: any;
  

  // Attending/Current Window
  currentWindow$: string;
  assignedWindowLists$: Array<any>;

  // For Custom Report Contents
  private contentKey: string = 'customReportContents';
  public contents: any[];

  setCustomReportData(data: any) {
    this.contents = data;
    localStorage.setItem(this.contentKey, JSON.stringify(data));
  }

  getCustomReportData() {
    const dataString = localStorage.getItem(this.contentKey);
    if (dataString) {
      this.contents = JSON.parse(dataString);
    }
    return this.contents;
  }

  // For saving the selected contents
  private selectedReportKey: string = 'selectedReport';
  public selectedReport: any[];

  setSelectedReport(data: any) {
    this.selectedReport = data;
    localStorage.setItem(this.selectedReportKey, JSON.stringify(data));
  }

  getSelectedReport() {
    const dataString = localStorage.getItem(this.selectedReportKey);
    if (dataString) {
      this.selectedReport = JSON.parse(dataString);
    }
    return this.selectedReport;
  }

  // After Submit API, Publish data
  public dataChange = new BehaviorSubject<any>(null);
  dataChange$ = this.dataChange.asObservable();
  publishDataChange$(data) {
    this.dataChange.next(data);
  }

  public branchChange = new BehaviorSubject<any>(null);
  branchChange$ = this.branchChange.asObservable();
  publishBranchChange$(data) {
    this.branchChange.next(data);
  }

  public newBranch = new BehaviorSubject<any>(null);
  newBranch$ = this.newBranch.asObservable();
  publishNewBranch$(data) {
    this.newBranch.next(data);
  }

  // Publish Sort and Filter
  public sortFilters = new BehaviorSubject<any>(null);
  sortFilters$ = this.sortFilters.asObservable();
  publishSortFilters$(data) {
    this.sortFilters.next(data);
  }

  // Close Modal Reasons
  public closeModalReason = new BehaviorSubject<any>(null);
  closeModalReason$ = this.closeModalReason.asObservable();
  publishCloseModalReason$(data) {
    this.closeModalReason.next(data);
  }

  public setSubscriptionPlan(data){
    localStorage.setItem('subscriptionPlan', JSON.stringify(data));
  }

  public getSubscriptionPlan(){
    return JSON.parse(localStorage.getItem('subscriptionPlan'));
  }

  public setIntroLevel(data){
    localStorage.setItem('introLevel', JSON.stringify(data));
  }

  public getIntroLevel(){
    return JSON.parse(localStorage.getItem('introLevel'));
  }

  public setIntroSettings(data){
    localStorage.setItem('introSettings', JSON.stringify(data));
  }

  public getIntroSettings(){
    return JSON.parse(localStorage.getItem('introSettings'));
  }

  public introLevel = new BehaviorSubject<any>(null);
  introLevel$ = this.introLevel.asObservable();
  publishIntroLevel$(data) {
    this.introLevel.next(data);
    this.setIntroLevel(data)
  }

  public businessData = new BehaviorSubject<any>(null);
  businessData$ = this.businessData.asObservable();
  publishBusinessData$(data) {
    this.businessData.next(data);
  }


}
