import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpService } from 'src/app/core/http/http.service';
import { DataService } from 'src/app/core/services/data.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { TeamManagementService } from 'src/app/modules/team-management/services/team-management.service';

@Component({
  selector: 'app-modal-warning-input',
  templateUrl: './modal-warning-input.component.html',
  styleUrls: ['./modal-warning-input.component.scss']
})
export class ModalWarningInputComponent extends ModalService implements OnInit {
  title: string;
  subTitle: string;
  inputText: string;
  placeholder: string;
  validators: Array<any>;
  displayName: string;

  constructor(
    bsModalRef: BsModalRef,
    httpService: HttpService,
    dataService: DataService,
    toastr: ToastrService,
    authService: AuthService,
  ) {
    super(bsModalRef, httpService, dataService, toastr, authService)
  }

  ngOnInit(): void {
    this.validators = [{
      type: 'required',
      value: Validators.required,
      errormsg: 'This field is required'
    }, {
      type: 'pattern',
      value: Validators.pattern(this.inputText),
      errormsg: 'Type the correct pattern'
    }]
  }

}
