import { Component, OnInit } from '@angular/core';
import { DataService } from "src/app/core/services/data.service";
import { Subscription } from "rxjs";
import { SettingsService } from '../../services/settings.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-queue-settings',
  templateUrl: './queue-settings.component.html',
  styleUrls: ['./queue-settings.component.scss']
})
export class QueueSettingsComponent implements OnInit {
  isLoading = false;
  subscriptions = new Subscription();
  selectedBranch = this.dataService.currentBranch$;
  currentSettings: any;
  queueServing: any = {
    enableHoldQueue: false,
    enableReturnQueue: false,
    enableVisitorCancellation: false
  }
  queueMovement: any = {
    enableGenerateRandomQueueNumber: false,
    enableQueueMovement: false
  }
  requireAllRemarks = false;
  // requireAllRemarksDisabled = true;
  remarks: any = {
    enableRemoveQueueRemarks: false,
    enableTransferQueueRemarks: false,
    enableSkipQueueRemarks: false,
    enableQueueMovementRemarks: false,
    enableHoldQueueRemarks: false,
    enableReturnQueueRemarks: false
  }
  automateQueue: any = {
    enableAutoResetQueueNumber: false,
    enableAutoCompleteQueues: false,
    autoCompleteTime: null,
    timeUnit: 'hour'
  }
  waitingAndServing: any = {
    enableWaitingAndServingTimeWarning: false,
    setTimeWarning: null,
    timeUnit: '',
    enableNotifyManagersOnOverdueQueues: false,
  }
  autoCompleteTimeList: any[] = [
    { interval: 0, timeUnit: "hour", name: "12:00 MN" },
    { interval: 1, timeUnit: "hour", name: "1 hour" },
    { interval: 2, timeUnit: "hour", name: "2 hours" }
  ];
  setTimeWarningList: any[] = [
    { interval: 15, timeUnit: "minutes", name: "15 mins" },
    { interval: 30, timeUnit: "minutes", name: "30 mins" },
    { interval: 45, timeUnit: "minutes", name: "45 mins" },
    { interval: 1, timeUnit: "hour", name: "1 hour" }
  ];
  isChanged = false;
  queueSettingsFeaturesEnabled: any = [];
  waitingAndServingTimeWarningFeauturesEnabled: any [];
  branchId: null;

  constructor(
    private dataService: DataService,
    private settingsService: SettingsService,
    public toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe((res) => {
        this.selectedBranch = res ? res : this.selectedBranch;
        this.branchId = this.selectedBranch._id;
        if(this.branchId){
          this.settingsService.getBranchQueueSettings(this.branchId).subscribe((settings) => {
            this.currentSettings = settings.data;
            console.log(this.currentSettings);
            this.queueSettingsFeaturesEnabled = this.currentSettings.featuresEnabled ? this.currentSettings.featuresEnabled : [];
            this.waitingAndServingTimeWarningFeauturesEnabled = this.currentSettings.waitingAndServingTimeWarning.featuresEnabled ? this.currentSettings.waitingAndServingTimeWarning.featuresEnabled : [];
            this.setInitialValues();
          })
        }
      })
    );
  }

  setInitialValues() {
    //queue serving
    this.queueServing.enableHoldQueue = this.queueSettingsFeaturesEnabled.includes('hold-queue') ? true : false;
    this.queueServing.enableReturnQueue = this.queueSettingsFeaturesEnabled.includes('return-queue') ? true : false;
    this.queueServing.enableVisitorCancellation = this.queueSettingsFeaturesEnabled.includes('visitor-cancellation') ? true : false;
    //queue movement
    this.queueMovement.enableGenerateRandomQueueNumber = this.queueSettingsFeaturesEnabled.includes('generate-random-queue-number') ? true : false;
    this.queueMovement.enableQueueMovement = this.queueSettingsFeaturesEnabled.includes('move-queue') ? true : false;
    //remarks
    this.requireAllRemarks = this.queueSettingsFeaturesEnabled.includes('require-all-remarks-fields') ? true : false;
    this.remarks.enableRemoveQueueRemarks = this.queueSettingsFeaturesEnabled.includes('remarks-on-remove-queue') ? true : false;
    this.remarks.enableTransferQueueRemarks = this.queueSettingsFeaturesEnabled.includes('remarks-on-transfer-queue') ? true : false;
    this.remarks.enableSkipQueueRemarks = this.queueSettingsFeaturesEnabled.includes('remarks-on-skip-queue') ? true : false;
    this.remarks.enableQueueMovementRemarks = this.queueSettingsFeaturesEnabled.includes('remarks-on-move-queue') ? true : false;
    this.remarks.enableHoldQueueRemarks = this.queueSettingsFeaturesEnabled.includes('remarks-on-hold-queue') ? true : false;
    this.remarks.enableReturnQueueRemarks = this.queueSettingsFeaturesEnabled.includes('remarks-on-return-queue') ? true : false;
    //automate
    this.automateQueue.enableAutoCompleteQueues = this.currentSettings.autoCompleteServingQueue.status ? this.currentSettings.autoCompleteServingQueue.status : false;
    this.automateQueue.autoCompleteTime = this.currentSettings.autoCompleteServingQueue.interval ? this.currentSettings.autoCompleteServingQueue.interval : 0;
    this.automateQueue.enableAutoResetQueueNumber = this.queueSettingsFeaturesEnabled.includes('auto-reset-queue') ? true : false;
    //waiting and serving
    this.waitingAndServing.enableWaitingAndServingTimeWarning = this.currentSettings.waitingAndServingTimeWarning.status ? this.currentSettings.waitingAndServingTimeWarning.status : false;
    this.waitingAndServing.setTimeWarning =  this.currentSettings.waitingAndServingTimeWarning.interval ? this.currentSettings.waitingAndServingTimeWarning.interval : 0;
    this.waitingAndServing.enableNotifyManagersOnOverdueQueues = this.waitingAndServingTimeWarningFeauturesEnabled.includes('notify-manager-overdue-queues') ? true : false;
    this.isLoading = false;
    // this.checkDisabledFields();
  }

  revertChanges() {
    this.isChanged = false;
    this.ngOnInit();
  }

  saveChanges() {
    const queueSettings = {
      autoCompleteServingQueue: {
        interval: this.automateQueue.autoCompleteTime,
        status: this.automateQueue.enableAutoCompleteQueues,
        timeUnit: this.automateQueue.timeUnit
      },
      waitingAndServingTimeWarning: {
        status: this.waitingAndServing.enableWaitingAndServingTimeWarning,
        interval: this.waitingAndServing.setTimeWarning,
        timeUnit: this.waitingAndServing.timeUnit,
        featuresEnabled: this.waitingAndServingTimeWarningFeauturesEnabled ? this.waitingAndServingTimeWarningFeauturesEnabled : []
      },
      featuresEnabled: this.queueSettingsFeaturesEnabled ? this.queueSettingsFeaturesEnabled : []
    }
    this.settingsService.editBranchQueueSettings(this.branchId, queueSettings).subscribe(
      (res) => {
        console.log(res);
        this.toastr.success("Queue Settings has been updated", "Success!");
        this.ngOnInit();
      },
      (error) => {
        let errMsg = error.error.errors[0].msg;
        this.toastr.error(errMsg, "Error!");
        this.ngOnInit();
      }
    );
  }

  enableHoldQueueToggle(e) {
    const featureName = 'hold-queue';
    const featureName2 = 'remarks-on-hold-queue';
    this.isChanged = true;
    this.queueServing.enableHoldQueue = !this.queueServing.enableHoldQueue;
    console.log("this is working")
    console.log(this.queueServing.enableHoldQueue);
    if(!this.queueServing.enableHoldQueue){
      this.remarks.enableHoldQueueRemarks = false;
      // this.requireAllRemarksDisabled = true;
      this.updateQueueSetting('disable', featureName);
      this.updateQueueSetting('disable', featureName2);
    } else {
      this.updateQueueSetting('enable', featureName);
    }
    // this.checkDisabledFields();
    // this.checkRemarksSettings();
  }

  enableReturnQueueToggle(e) {
    const featureName = 'return-queue';
    const featureName2 = 'remarks-on-return-queue';
    this.isChanged = true;
    this.queueServing.enableReturnQueue = !this.queueServing.enableReturnQueue;
    if(!this.queueServing.enableReturnQueue){
      this.remarks.enableReturnQueueRemarks = false;
      this.updateQueueSetting('disable', featureName);
      this.updateQueueSetting('disable', featureName2);
    } else {
      this.updateQueueSetting('enable', featureName);
    }
    // this.checkDisabledFields();
    // this.checkRemarksSettings();
  }

  enableVisitorCancellationToggle(e) {
    const featureName = 'visitor-cancellation';
    this.isChanged = true;
    this.queueServing.enableVisitorCancellation = !this.queueServing.enableVisitorCancellation;
    if(!this.queueServing.enableVisitorCancellation){
      this.updateQueueSetting('disable', featureName);
    } else {
      this.updateQueueSetting('enable', featureName);
    }
  }

  enableGenerateRandomQueueNumberToggle(e) {
    const featureName = 'generate-random-queue-number';
    // const featureName2 = 'move-queue';
    // const featureName3 = 'remarks-on-move-queue';
    this.isChanged = true;
    this.queueMovement.enableGenerateRandomQueueNumber = !this.queueMovement.enableGenerateRandomQueueNumber;
    if(!this.queueMovement.enableGenerateRandomQueueNumber){
      // this.queueMovement.enableQueueMovement = false;
      // this.remarks.enableQueueMovementRemarks = false;
      this.updateQueueSetting('disable', featureName);
      // this.updateQueueSetting('disable', featureName2);
      // this.updateQueueSetting('disable', featureName3);
    } else {
      this.updateQueueSetting('enable', featureName);
    }
    // this.checkRemarksSettings();
  }

  enableQueueMovementToggle(e) {
    const featureName = 'move-queue';
    const featureName2 = 'remarks-on-move-queue';
    this.isChanged = true;
    this.queueMovement.enableQueueMovement = !this.queueMovement.enableQueueMovement;
    if(!this.queueMovement.enableQueueMovement){
      this.remarks.enableQueueMovementRemarks = false;
      this.updateQueueSetting('disable', featureName);
      this.updateQueueSetting('disable', featureName2);
    } else {
      this.updateQueueSetting('enable', featureName);
    }
    // this.checkDisabledFields();
    // this.checkRemarksSettings();
  }

  enableAllRemarksToggle(e) {
    const featureName = 'require-all-remarks-fields';
    this.isChanged = true;
    this.requireAllRemarks = !this.requireAllRemarks;
    if(this.requireAllRemarks){
      this.updateQueueSetting('enable', featureName);
    } else {
      this.updateQueueSetting('disable', featureName);
    }
    
    // if(this.requireAllRemarks){
    //   this.remarks.enableRemoveQueueRemarks = true;
    //   this.remarks.enableTransferQueueRemarks = true;
    //   this.remarks.enableSkipQueueRemarks = true;
    //   this.updateQueueSetting('enable', 'remarks-on-remove-queue');
    //   this.updateQueueSetting('enable', 'remarks-on-transfer-queue');
    //   this.updateQueueSetting('enable', 'remarks-on-skip-queue');
    //   this.remarks.enableQueueMovementRemarks = this.queueMovement.enableQueueMovement ? true : false;
    //   this.remarks.enableHoldQueueRemarks = this.queueServing.enableHoldQueue ? true : false;
    //   this.remarks.enableReturnQueueRemarks = this.queueServing.enableReturnQueue ? true : false;
    //   if(this.queueMovement.enableQueueMovement){
    //     this.updateQueueSetting('enable', 'remarks-on-move-queue');
    //   }
    //   if(this.queueServing.enableHoldQueue) {
    //     this.updateQueueSetting('enable', 'remarks-on-hold-queue');
    //   }
    //   if(this.queueServing.enableReturnQueue){
    //     this.updateQueueSetting('enable', 'remarks-on-return-queue');
    //   }
    //   this.updateQueueSetting('enable', featureName);
    // } else {
    //   this.updateQueueSetting('disable', featureName);
    // }
    // this.checkRemarksSettings();
  }

  // checkRemarksSettings() {
  //   const checker = Object.values(this.remarks).every((v) => v === true);
  //   if(checker){
  //     this.requireAllRemarks = true;
  //     this.updateQueueSetting('enable', 'require-all-remarks-fields');
  //   } else {
  //     this.requireAllRemarks = false;
  //     this.updateQueueSetting('disable', 'require-all-remarks-fields');
  //   }
  // }

  // checkDisabledFields() {
  //   if(this.queueServing.enableHoldQueue &&
  //     this.queueServing.enableReturnQueue &&
  //     this.queueMovement.enableQueueMovement){
  //       this.requireAllRemarksDisabled = false;
  //   }
  // }

  enableRemoveQueueRemarksToggle(e) {
    const featureName = 'remarks-on-remove-queue';
    this.isChanged = true;
    this.remarks.enableRemoveQueueRemarks = !this.remarks.enableRemoveQueueRemarks;
    if(!this.remarks.enableRemoveQueueRemarks){
      this.updateQueueSetting('disable', featureName);
    } else {
      this.updateQueueSetting('enable', featureName);
    }
    // this.checkRemarksSettings();
  }
    
  enableTransferQueueRemarksToggle(e) {
    const featureName = 'remarks-on-transfer-queue';
    this.isChanged = true;
    this.remarks.enableTransferQueueRemarks = !this.remarks.enableTransferQueueRemarks;
    if(!this.remarks.enableTransferQueueRemarks){
      this.updateQueueSetting('disable', featureName);
    } else {
      this.updateQueueSetting('enable', featureName);
    }
    // this.checkRemarksSettings();
  }

  enableSkipQueueRemarksToggle(e) {
    const featureName = 'remarks-on-skip-queue';
    this.isChanged = true;
    this.remarks.enableSkipQueueRemarks = !this.remarks.enableSkipQueueRemarks;
    if(!this.remarks.enableSkipQueueRemarks){
      this.updateQueueSetting('disable', featureName);
    } else {
      this.updateQueueSetting('enable', featureName);
    }
    // this.checkRemarksSettings();
  }

  enableQueueMovementRemarksToggle(e) {
    const featureName = 'remarks-on-move-queue';
    this.isChanged = true;
    this.remarks.enableQueueMovementRemarks = !this.remarks.enableQueueMovementRemarks;
    if(!this.remarks.enableQueueMovementRemarks){
      this.updateQueueSetting('disable', featureName);
    } else {
      this.updateQueueSetting('enable', featureName);
    }
    // this.checkRemarksSettings();
  }
    
  enableHoldQueueRemarksToggle(e) {
    const featureName = 'remarks-on-hold-queue';
    this.isChanged = true;
    this.remarks.enableHoldQueueRemarks = !this.remarks.enableHoldQueueRemarks;
    if(!this.remarks.enableHoldQueueRemarks){
      this.updateQueueSetting('disable', featureName);
    } else {
      this.updateQueueSetting('enable', featureName);
    }
    // this.checkRemarksSettings();
  }

  enableReturnQueueRemarksToggle(e) {
    const featureName = 'remarks-on-return-queue';
    this.isChanged = true;
    this.remarks.enableReturnQueueRemarks = !this.remarks.enableReturnQueueRemarks;
    if(!this.remarks.enableReturnQueueRemarks){
      this.updateQueueSetting('disable', featureName);
    } else {
      this.updateQueueSetting('enable', featureName);
    }
    // this.checkRemarksSettings();
  }

  enableAutoResetQueueToggle(e) {
    const featureName = 'auto-reset-queue';
    this.isChanged = true;
    this.automateQueue.enableAutoResetQueueNumber = !this.automateQueue.enableAutoResetQueueNumber;
    if(!this.automateQueue.enableAutoResetQueueNumber){
      this.updateQueueSetting('disable', featureName);
    } else {
      this.updateQueueSetting('enable', featureName);
    }
  }

  enableAutoCompleteQueueToggle(e) {
    this.isChanged = true;
    this.automateQueue.enableAutoCompleteQueues = !this.automateQueue.enableAutoCompleteQueues;
    if(!this.automateQueue.enableAutoCompleteQueues){
      this.automateQueue.autoCompleteTime = this.autoCompleteTimeList[0].interval;
    }
  }

  changeAutoCompleteTime(e) {
    this.isChanged = true;
  }

  enableWaitingAndServingTimeWarningToggle(e) {
    const featureName = 'notify-manager-overdue-queues';
    this.isChanged = true;
    this.waitingAndServing.enableWaitingAndServingTimeWarning = !this.waitingAndServing.enableWaitingAndServingTimeWarning;
    if(!this.waitingAndServing.enableWaitingAndServingTimeWarning){
      this.waitingAndServing.enableNotifyManagersOnOverdueQueues = false;
      this.waitingAndServing.setTimeWarning = this.setTimeWarningList[0].interval;
      this.updateWaitingAndServingSetting('disable', featureName);
    }
  }

  changeSetTimeWarning(e) {
    const obj = this.setTimeWarningList.find(element => element.interval == this.waitingAndServing.setTimeWarning);
    this.waitingAndServing.timeUnit = obj.timeUnit;
    this.isChanged = true;
  }

  enableNotifyManagersOnOverdueQueuesToggle(e) {
    const featureName = 'notify-manager-overdue-queues'
    this.isChanged = true;
    this.waitingAndServing.enableNotifyManagersOnOverdueQueues = !this.waitingAndServing.enableNotifyManagersOnOverdueQueues;
    if(!this.waitingAndServing.enableNotifyManagersOnOverdueQueues){
      this.updateWaitingAndServingSetting('disable', featureName);
    } else {
      this.updateWaitingAndServingSetting('enable', featureName);
    }
  }

  updateQueueSetting(action: String, name: String) {
    var index = this.queueSettingsFeaturesEnabled.indexOf(name);
    if(action == 'enable'){
      if (index < 0) {
        this.queueSettingsFeaturesEnabled.push(name);
      }
    } else {
      if (index > -1) {
        this.queueSettingsFeaturesEnabled.splice(index, 1);
      }
    }
  }

  updateWaitingAndServingSetting(action: String, name: String) {
    var index = this.waitingAndServingTimeWarningFeauturesEnabled.indexOf(name);
    if(action == 'enable'){
      if (index < 0) {
        this.waitingAndServingTimeWarningFeauturesEnabled.push(name);
      }
    } else {
      if (index > -1) {
        this.waitingAndServingTimeWarningFeauturesEnabled.splice(index, 1);
      }
    }
  }

}
