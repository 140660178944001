import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchData'
})
export class SearchDataPipe implements PipeTransform {

  transform(items: any[], value: string, label: string): any {
    if (!items) {
      return [];
    }
    if (!value) {
      return items;
    }
    if (value === '' || value === null) {
      return [];
    }

    if (label === 'serviceData') {
      return items.filter(e => (e['label'].toLowerCase()).indexOf(value.toLowerCase()) > -1);
    } else {
      return items.filter(e => e[label].toLowerCase().indexOf(value.toLowerCase()) > -1);
    }
  }

}
