import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(duration: number, ...args: any[]): any {
    const msToSec = 1000;
    const secToMin = 60;
    const minToHour = 60;
    const seconds = Math.floor((duration / msToSec) % secToMin);
    const minutes = Math.floor((duration / (msToSec * secToMin)) % minToHour);
    const hours = Math.floor((duration / (msToSec * secToMin * minToHour)));

    let minsHours;
    // Display hour and mins
    if (hours >= 1) {
      minsHours = hours + (hours > 1 ? ' hours' : ' hour') + ' ' + minutes + ' minutes';
    } else if (hours < 1 && minutes <= 60 && minutes > 0) {
      minsHours = minutes + (minutes > 1 ? ' minutes': ' minute');
    } else {
      minsHours = 'A few seconds';
    }
    return minsHours;
  }

}
