import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  constructor() { }

  setCookie(name, value, days) {
    let expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  getCookie(name) {
    const nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  eraseCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }


  deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  // Queue Action
  getQueueAction(action) {
    let actionName;
    switch (action) {
      case 'serve': actionName = 'Served Queue';
        break;
      default: actionName = null;
    }
    return actionName;
  }

  // Patch and Send Mobile
  patchMobile(mobile) {
    if (mobile) {
      const number1 = mobile.substring(0, 3);
      const number2 = mobile.substring(3, 6);
      const number3 = mobile.substring(6, 11);
      return `${number1}-${number2}-${number3}`;
    }
    return null;
  }

  transformMobile(mobile) {
    return mobile.replace(/\D/g, '');
  }

}
