import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { SetOperationHoursModalComponent } from "../../component/set-operation-hours-modal/set-operation-hours-modal.component";
import { BranchManagementService } from "../../services/branch-management.service";
import { AddSpecialScheduleComponent } from "../../component/add-special-schedule/add-special-schedule.component";
import { EditOperationHoursComponent } from "../../component/edit-operation-hours/edit-operation-hours.component";
import { ViewSpecialScheduleComponent } from "../../component/view-special-schedule/view-special-schedule.component";
import * as moment from "moment";

@Component({
  selector: "app-branch-operation-hours",
  templateUrl: "./branch-operation-hours.component.html",
  styleUrls: ["./branch-operation-hours.component.scss"],
})
export class BranchOperationHoursComponent implements OnInit, OnDestroy {
  pageDetails = [
    { type: "main", name: "Branches", routerLink: ["/branch-management"] },
    { type: "sub", name: "", routerLink: null },
    { type: "current", name: "Operation Hours", routerLink: null },
  ];
  enabledSpecialSchedule = false;
  branchId: string;
  branchData: any;
  specialSchedule = 1;
  dateToday = new Date();
  dateTodayIndex = this.dateToday.getDay();

  currentBranch = this.branchService.getSelectedBranch();

  branches: any;
  assignedBranches: any;
  subscriptions = new Subscription();
  isDeleteAvailable = false;
  dayList = [
    { label: "Sunday", value: 0 },
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
  ];
  operationHours: any;
  specialScheduleList: any;
  operationDays: any;
  specialScheduleStatus = "";
  statusTextColor = "";
  today = new Date();
  openToday = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    private dataService: DataService,
    private branchService: BranchManagementService,
    private router: Router
  ) {}

  ngOnInit() {
    this.branchId = this.activatedRoute.snapshot.paramMap.get("branchId");
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe(async (res) => {
        if (res && this.branchId !== res?._id) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(`/branch-management/operation-hours/${res?._id}`);
          });
        }
        await this.handleBranchChange(res);
        await this.getBranchDetails();
        await this.getOperationDays();
        await this.getSpecialSchedule();
      })
    );
  }

  async handleBranchChange(res: any): Promise<void> {
    this.branchId = res ? res._id : this.dataService.currentBranch$.branchId;
    this.pageDetails[1].routerLink = ["/branch-management", this.branchId];
  }

  async getBranchDetails(): Promise<void> {
    const res = await this.branchService
      .getBranchDetails(this.branchId)
      .toPromise();
    this.branchData = res.data;
    this.pageDetails[1].name = this.branchData.name;
  }

  async getOperationDays(): Promise<void> {
    const res = await this.branchService
      .getOperationDays(this.branchId)
      .toPromise();
    this.operationDays = res.data;
    this.openToday =
      this.operationDays[this.dateTodayIndex].operationHours.length > 0;
  }

  async getSpecialSchedule(): Promise<void> {
    const res = await this.branchService
      .getSpecialSchedule(this.branchId)
      .toPromise();
    this.specialScheduleList = res.data;
  }

  setStatusValue(data) {
    var startDate = moment(data.startDate).format("L");
    var endDate = moment(data.endDate).format("L");
    var today = moment(this.today).format("L");

    if (
      moment(today).isSame(startDate) ||
      moment(today).isSame(endDate) ||
      moment(today).isBetween(startDate, endDate)
    ) {
      return ["Today", "#FFFFFF", "#FE7401"];
    } else if (
      moment(today).isAfter(startDate) &&
      moment(today).isAfter(endDate)
    ) {
      return ["Done", "#B80A00", "#FFCDCD"];
    } else {
      return ["Upcoming", "#886D00", "#FFF3CD"];
    }
  }

  setOperationHours() {
    const initialState = {
      branchId: this.branchId,
    };
    this.modalRef = this.modalService.show(SetOperationHoursModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if (data) {
          this.ngOnInit();
        }
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }

  getDay(date: any) {
    return moment(date).format("dddd");
  }

  addSpecialSchedule() {
    const initialState = {
      branchId: this.branchId,
      title: "Add Special Schedule",
      subTitle: "Operation hours during a specific date or public holidays",
    };
    this.modalRef = this.modalService.show(AddSpecialScheduleComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if (data) {
          this.ngOnInit();
        }
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }

  editOperationHours(num) {
    const initialState = {
      branchId: this.branchId,
      day: num,
    };
    this.modalRef = this.modalService.show(EditOperationHoursComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if (data) {
          this.ngOnInit();
        }
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }

  viewSpecialSchedule(scheduleId: any) {
    const initialState = {
      branchId: this.branchId,
      scheduleId: scheduleId,
    };
    this.modalRef = this.modalService.show(ViewSpecialScheduleComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if (data) {
          this.ngOnInit();
        }
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
