import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DataService } from 'src/app/core/services/data.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QueueHistoryService {
  apiUrl = environment.urlAPI;
  accountId = this.authService.accountId$;
  accountData = this.dataService.accountData$
  branchId = this.accountData.data.branchId;
  
  constructor(private http: HttpClient, public authService: AuthService, private dataService: DataService) { }

  private get httpHeader(): any {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return {
      headers,
    };
  }
  
  public getQueueHistory(params, branchId): Observable<any> {
    const url = `${this.apiUrl}/queues/${branchId}/history/list?${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getQueueHistoryDetails(id, branchId): Observable<any> {
    const url = `${this.apiUrl}/queues/${branchId}/history/${id}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getQueueHistoryDownload(branchId: string): Observable<any> {
    const url = `${this.apiUrl}/queues/${branchId}/history/download`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getQueueHistoryReportsDownload(params: HttpParams, branchId: string): Observable<any> {
    const url = `${this.apiUrl}/queues/${branchId}/history/download?${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getCustomReportsQueueHistoryDownload(params: HttpParams, branchId: string, reportId: string): Observable<any> {
    console.log(branchId)
    const url = `${this.apiUrl}/queues/${branchId}/history/custom-reports/${reportId}/download?${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }
}
