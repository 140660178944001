import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { ServiceCategoryService } from "../../services/service-category.service";
import { AddServiceCategoryComponent } from "../../../service-category-v2/components/add-service-category/add-service-category.component";
import { ViewServiceCategoryComponent } from "../../components/view-service-category/view-service-category.component";
import { take } from "rxjs/operators";

@Component({
  selector: "app-service-category-page",
  templateUrl: "./service-category-page.component.html",
  styleUrls: ["./service-category-page.component.scss"],
})
export class ServiceCategoryPageComponent implements OnInit {
  isLoading = false;
  branchId: string;
  subscriptions = new Subscription();
  serviceLists: any;
  activate = false;
  procedureTemplates: any;
  categoryLists: any;
  selectedCategory = "";

  constructor(
    private dataService: DataService,
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    public httpService: HttpService,
    private categoryService: ServiceCategoryService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.setSelectedCategory(null);
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe((res) => {
        this.branchId = res
          ? res._id
          : this.dataService.currentBranch$?.branchId;
      })
    );

    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((x) => {
        this.selectedCategory = x.selectedCategory;
        // console.log(this.selectedCategory);
      })
    );

    this.getServiceLists();
    this.getCategoryList();
    this.isLoading = false;
  }

  public getServiceName(id) {
    if (id && this.serviceLists) {
      const s = this.serviceLists.find((x) => x.value === id);
      if (s) {
        return s.label;
      }
    }
  }

  public getCategoryList() {
    this.categoryService.getServiceCategories(this.branchId).subscribe(
      (res) => {
        this.categoryLists = res.data;
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  public getServiceLists() {
    this.httpService
      .get$(`services/${this.branchId}?limit=999`)
      .subscribe((data) => {
        this.serviceLists = data.data.map((element) => {
          return {
            label: element.displayName,
            value: element._id,
          };
        });
      });
  }

  public addCategory() {
    const initialState = {
      branchId: this.branchId,
      serviceLists: this.serviceLists,
      title: "New Category",
      type: "add",
    };
    this.modalRef = this.modalService.show(AddServiceCategoryComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if (data) {
        }
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }

  public setSelectedCategory(id) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        selectedCategory: id ? id : null,
      },
      queryParamsHandling: "merge",
      skipLocationChange: false,
    });
  }

  public viewCategory(id) {
    this.setSelectedCategory(id);
    const initialState = {
      branchId: this.branchId,
      categoryId: id,
      serviceLists: this.serviceLists,
      title: "View Category",
    };
    this.modalRef = this.modalService.show(ViewServiceCategoryComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.modalRef.content.successEvent.subscribe(
      (data) => {
          this.getCategoryList();
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }

  public activateCategory(ev, category) {
    const body = {
      subCategoryId: category.subCategoryId,
      assignedServices: category.assignedServices,
      activated: ev,
      displayName: category.displayName,
    };

    this.categoryService
      .updateServiceCategory(this.branchId, category._id, body)
      .subscribe(
        (res) => {
          // console.log(res);
          if (ev) {
            this.toastr.success(
              `Service category "${category.displayName}" has been Activated`,
              "Activated!"
            );
          } else {
            this.toastr.warning(
              `Service category "${category.displayName}" has been Deactivated`,
              "Deactivated!"
            );
          }

          this.getCategoryList();
        },
        (error) => {
          console.log(error);
          let errMsg = "Error on managing services";
          this.toastr.error(errMsg, "Error!");
        }
      );
  }
}
