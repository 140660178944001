import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { AuthService } from "src/app/core/auth/auth.service";
import { DataService } from "src/app/core/services/data.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  apiUrl = environment.urlAPI;
  /*   accountId = this.authService.accoutId$; */
    accountData = this.dataService.accountData$;
    branchId = this.accountData.data.branchId;
  
    constructor(
      private http: HttpClient,
      public authService: AuthService,
      private dataService: DataService
    ) {}
  
    private get httpHeader(): any {
      const headers = new HttpHeaders().set("Content-Type", "application/json");
      return {
        headers,
      };
    }
  
    /* routes for sending report an issue form data to the database */
    public createReportIssue(details): Observable<any> {
      const url = `${this.apiUrl}/support/report-an-issue`;
      return this.http.post(url, details).pipe(map((response) => response))
    }
  
      /* routes for sending report an issue form data to the database */
    public createRequestFeature(details): Observable<any> {
      const url = `${this.apiUrl}/support-request-feature/request-feature`;
      return this.http.post(url, details).pipe(map((response) => response))
    }
    
        /* routes for sending report an issue form data to the database */
    public createConnectSales(details): Observable<any> {
      const url = `${this.apiUrl}/support-connect-sales/connect-to-sales`;
      return this.http.post(url, details).pipe(map((response) => response))
    } 
}
