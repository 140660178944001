import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/core/auth/auth.service";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { ModalService } from "src/app/core/services/modal.service";
import { ServiceWindowService } from "../../services/service-window.service";

@Component({
  selector: "app-window-assign-staff-form",
  templateUrl: "./window-assign-staff-form.component.html",
  styleUrls: ["./window-assign-staff-form.component.scss"],
})
export class WindowAssignStaffFormComponent
  extends ModalService
  implements OnInit {
  windowData: any;
  windowId: string;
  staffLists: Array<any>;
  ownerLists: Array<any>;
  isConfirmDisabled: boolean;
  isAllowAllStaff: boolean;
  newStaffLists = [];
  confirmText: string;
  cancelText: string;
  featureBodyForm: any;
  branchId: string;
  accountsList: any;

  constructor(
    bsModalRef: BsModalRef,
    httpService: HttpService,
    dataService: DataService,
    toastr: ToastrService,
    authService: AuthService,
    private serviceWindowService: ServiceWindowService
  ) {
    super(bsModalRef, httpService, dataService, toastr, authService);
  }

  changeCheckbox($event) {
    console.log("changeCheckbox", $event);
    const checked = $event.checkedArray;
    this.newStaffLists = this.staffLists.filter((element, index) =>
      checked.includes(index)
    );
    console.log("newStaffLists", this.newStaffLists);
    this.checkDisableConfirm();
  }

  allowAllStaff($event) {
    this.isAllowAllStaff = $event;
    this.checkDisableConfirm();
    const feature = "allowed-staff-to-serve-and-complete-queue";
    const dataArray = this.windowData.enabledFeatures;
    const value = $event;
    const featuresArray = this.serviceWindowService.addDeleteFeature(
      dataArray,
      feature,
      value
    );
    this.featureBodyForm = { enabledFeatures: featuresArray };
  }

  confirm($event) {
    const param = `services/windows/${this.branchId}/${this.windowId}`;
    const bodyForm = {
      ...this.featureBodyForm,
      assignedEmployees: this.newStaffLists.map((element) => element._id),
    };
    this.serviceWindowService.submitPatchForm(param, bodyForm).then((data) => {
      console.log("assigned windows", data);
      this.dataService.publishDataChange$("assigned windows");
      this.bsModalRef.hide();
    });
  }

  checkDisableConfirm() {
    this.isConfirmDisabled =
      this.newStaffLists.length === 0 && !this.isAllowAllStaff ? true : false;
  }

  ngOnInit(): void {
    this.isConfirmDisabled = true;

    this.staffLists = this.accountsList
      .filter((x) => x.roleLevel !== "owner")
      .map((element) => {
        return {
          label: element.firstName + " " + element.lastName,
          _id: element._id,
        };
      });

    this.ownerLists = this.accountsList
      .filter((x) => x.roleLevel === "owner")
      .map((element) => {
        return {
          label: element.firstName + " " + element.lastName,
          _id: element._id,
        };
      });

    this.isAllowAllStaff = this.windowData.enabledFeatures.includes(
      "allowed-staff-to-serve-and-complete-queue"
    );

  }
}
