import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { HttpClient } from '@angular/common/http';
import { HttpService } from 'src/app/core/http/http.service';
import { ServiceWindowService } from "../../services/service-window.service";
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-delete-window-modal',
  templateUrl: './delete-window-modal.component.html',
  styleUrls: ['./delete-window-modal.component.scss']
})
export class DeleteWindowModalComponent extends ServiceWindowService implements OnInit {
  @Input() title: any;
  @Input() branchId: string;
  @Input() windowId: string;
  @Input() windowName: string;
  isSubmitting: boolean = false;
  isActiveWindow: boolean = false;

  constructor(
    public modalRef: BsModalRef,
    private dataService: DataService,
    public toastr: ToastrService,
    public httpService: HttpService,
    public httpClient: HttpClient,
  ) { 
    super(httpClient, httpService, toastr);
  }

  confirm() {
    this.isSubmitting = true;
    this.deleteWindow(this.branchId, this.windowId).subscribe(
      (data) => {
        if(data) {
          this.modalRef.hide();
          this.toastr.error(
            "Window has been deleted",
            "Deleted"
          );
          
          this.dataService.publishDataChange$({
            action: 'Delete window',
            data: data
          });
        }      
      },
      (err) => {
        console.log(err);
        this.title = "Oops, Wait";
        this.isActiveWindow = true;
        this.isSubmitting = false;
      }
  )}

  ngOnInit(): void {
  }

}
