import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { Subscription, timer } from "rxjs";
import { TimePipe } from "src/app/shared/pipes/time.pipe";
import { defaultAvatar } from "src/app/core/services/global";
import { DataService } from "src/app/core/services/data.service";

@Component({
  selector: "app-queue-single",
  templateUrl: "./queue-single.component.html",
  styleUrls: ["./queue-single.component.scss"],
})
export class QueueSingleComponent implements OnInit {
  defaultAvatar: string = defaultAvatar;
  @Input() servingType: string;
  @Input() serviceList: any;
  @Input() windowList: any;
  @Input() accountList: any;
  @Input() queue: any;
  @Input() activeQueue: any;
  @Input() active: string;
  @Input() lastItem: any;
  @Input() queueSettings: any;
  @Output() emittedAction = new EventEmitter();
  @Output() onClickAction = new EventEmitter();
  todayTime = Date.now();
  subscriptions = new Subscription();
  timerSubscription: Subscription;
  minsHours: any;
  duration: any;
  isTimeWarning: boolean;
  servedByData: any;

  notifying = false;
  counter: number;
  mobileNumber: any;

  isSingleServing: boolean;

  customerName: string;

  isTMC: boolean = false;

  constructor(private timePipe: TimePipe, private dataService: DataService) {}

  getServiceName(id) {
    if (id && this.serviceList) {
      const s = this.serviceList.find((x) => x._id === id);
      if (s) {
        return s.displayName;
      }
    }

    return "";
  }

  getWindowName(id) {
    if (id && this.windowList) {
      const s = this.windowList.find((x) => x._id === id);
      if (s) {
        return s.displayName;
      }
    }

    return "";
  }

  getUser(id) {
    if (id && this.accountList) {
      this.servedByData = this.accountList.find((x) => x._id === id);
    }
  }

  async getMinsHours(timeQueued) {
    this.duration = timeQueued ? this.todayTime - timeQueued : null;
    await this.getTick(this.duration);
  }

  timeConversion(duration) {
    const hours = Math.floor(duration / (1000 * 60 * 60));
    this.minsHours = this.timePipe.transform(duration);
    this.isTimeWarning = hours >= 1;
  }

  getTick(duration) {
    this.subscriptions.add(
      (this.timerSubscription = timer(0, 1000)
        .pipe()
        .subscribe(
          (value) => {
            const time = duration + value * 1000;
            this.duration = time;
            this.timeConversion(this.duration);
          },
          (err) => {},
          () => {}
        ))
    );
  }

  getTimeAgo(data) {
    if (data) {
      const y = new Date(data).getFullYear();
      const m = new Date(data).getMonth();
      const d = new Date(data).getDate();
      const h = new Date(data).getHours();
      const mm = new Date(data).getMinutes();
      const s = new Date(data).getSeconds();
      const time = moment([y, m, d, h, mm, s]).fromNow();

      return time === "a day" ? "1 day" : time;
    }
  }

  getTimeAgo2(data) {
    if (data) {
      const y = new Date(data).getFullYear();
      const m = new Date(data).getMonth();
      const d = new Date(data).getDate();
      const h = new Date(data).getHours();
      const mm = new Date(data).getMinutes();
      const s = new Date(data).getSeconds();

      const now = moment();
      const servedAt = moment(new Date(data));

      const duration = moment.duration(now.diff(servedAt));
      const minutes = duration.asMinutes();

      this.isTimeWarning = minutes > 30;

      const time = moment([y, m, d, h, mm, s]).fromNow();

      return time === "a day" ? "1 day" : time;
    }
  }

  checkTimeWarning(date) {
    const now = moment();
    const servedAt = moment(new Date(date));

    const duration = moment.duration(now.diff(servedAt));
    const minutes = duration.asMinutes();

    return minutes > 30;
  }

  emitAction(action) {
    if (action === "notify") {
      this.notifyQueue();
    } else {
      this.emittedAction.emit(action);
    }
  }

  emitClickAction($event) {
    this.onClickAction.emit($event);
  }

  notifyQueue() {
    this.notifying = true;
    this.startCountdown(5);
    this.emittedAction.emit("notify");
  }

  startCountdown(seconds) {
    this.counter = seconds;
    const interval = setInterval(() => {
      this.counter--;
      if (this.counter <= 0) {
        clearInterval(interval);
        this.notifying = false;
      }
    }, 1000);
  }

  ngOnInit(): void {
    if (this.dataService.accountData$ && (["a597d9a2-c2dd-40e7-b05f-47114be30f12", "0bb706ca-6f00-42a1-af36-431770829e90"].includes(this.dataService.accountData$.data.businessId))) {
      this.isTMC = true;
    }
    
    if (this.queue) {
      if (this.servingType === "served") {
        this.getUser(this.queue.servedBy);
        this.isSingleServing = false;

        const s = this.serviceList.find(
          (x) => x._id === this.queue.serviceId
        );

        if (s && s.queueType === "single-serving") {
          this.isSingleServing = true;
        }
      }

      const mobile = this.queue.fields.find((f) =>
        f.fieldName.includes("mobile")
      );
      if (mobile) {
        this.mobileNumber = mobile.value;
      }

      const name = this.queue.fields.find((f) =>
        f.fieldName.includes("name")
      );
      if (name && name.value && name.value.trim()) {
        this.customerName = name.value;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
