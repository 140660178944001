import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnDestroy, ViewChild, ElementRef,  ChangeDetectorRef} from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService } from 'src/app/core/http/http.service';
import { DatePipe } from '@angular/common';
import { DataService } from 'src/app/core/services/data.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppointmentService } from 'src/app/modules/appointment/services/appointment.service';
import * as moment from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SMART_FORM_NAMES } from 'src/app/modules/appointment/enum/smart_form';
import { Subject, Subscription, throwError } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, takeUntil, tap, catchError} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { RemoveAppointmentModalComponent } from 'src/app/modules/appointment/component/remove-appointment-modal/remove-appointment-modal.component';
import { EditAppointmentModalComponent } from 'src/app/modules/appointment/component/edit-appointment-modal/edit-appointment-modal.component';
import { IAppointmentPage, IFilterData, ICheckboxList, ICheckboxData, IFilterDataTimeslot } from 'src/app/modules/appointment/interface/appointment.interface';
import { AppointmentSettingsService } from 'src/app/modules/appointment/services/appointment-settings.service';
import { AppointmentDetailsModalComponent } from 'src/app/modules/appointment/component/appointment-details-modal/appointment-details-modal.component';

@Component({
  selector: 'app-for-approval',
  templateUrl: './for-approval.component.html',
  styleUrls: ['./for-approval.component.scss']
})
export class ForApprovalComponent implements OnInit, OnDestroy {
  @Input() isViewingFromEmail: boolean;
  @Input() approvalAppointmentCounts: number;
  @Input() emailAppointmentData: any;
  @Output() isViewed: EventEmitter<boolean> = new EventEmitter();
  @Output() isApprovedOrRejected: EventEmitter<boolean> = new EventEmitter();
  dropdownsVisible: { [key: string]: boolean } = {};
  @ViewChild("serviceDropdownRef", { static: false })
  serviceDropdown: ElementRef<HTMLDivElement>;
  @ViewChild("timeslotDropdownRef", { static: false })
  timeslotDropdown: ElementRef<HTMLDivElement>;
  @ViewChild("dateDropdownRef", { static: false })
  dateDropdown: ElementRef<HTMLDivElement>;
  dateOfTommorow: string;
  dateOfMonday: string;
  dateOfSunday: string;
  dateOfMonth: string;
  appointmentDate: string;
  appointmentForApproval: boolean;
  appointmentForAppoval: any;
  selectedTimeslotLabel: string = 'Show all timeslot';
  selectedDateLabel: string = 'Show all dates';
  searchString: string | null;
  itemsPerPage = 10;
  settingsLateTime: number;
  currentPage = 1;
  selectedServices: number = 0;
  noOfEntries: number[] = [10, 20, 30];
  appointmentLists: any = [];
  servicesList: Array<any> = [];
  serviceCheckboxLists: Array<ICheckboxList> = [];
  isFocused: boolean = false;
  isFocusedTimeslot: boolean = false;
  isFocusedDate: boolean = false;
  isSelectClicked: boolean = false;
  isButtonClicked: boolean = false;
  isSelectClickedDate : boolean = false;
  isSelectClickedTimeslot: boolean = false;
  isDisableCompleteBtn: boolean = false;
  selectedTimeslotValue: number;
  selectedDateValue: number;
  isLoading: boolean = false;
  subscriptions = new Subscription();
  selectedService: Array<any> = [];
  noOfPages: number[] = [];
  viewAppointmentModal: boolean = true;
  approvalAppointmentList: IAppointmentPage;
  dateFilterList: Array<IFilterData> = [];
  timeslotFilter: Array<IFilterData> = [];
  searchSubject: Subject<string> = new Subject();
  filterSubject: Subject<any> = new Subject();
  branchId: string = this.dataService.currentBranch$.branchId;
  ms8Hours = 28800000;

  private appointmentListSubjectAPI$ = new Subject<void>();
  constructor(
    private httpService: HttpService,
    private dataService: DataService,
    private appointmentService: AppointmentService,
    private appointmentSettingService: AppointmentSettingsService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private formBuild: FormBuilder,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    this.searchSubject
    .pipe(debounceTime(300), distinctUntilChanged())
    .subscribe((searchValue) => {
        this.readAppointmentList();
        this.readApprovalTimeslotList();
      }
    )
   }
   
  ngOnInit() {
    this.dataService.branchChange$.subscribe((res) => {  
      this.branchId = res.branchId ?? this.branchId;
      this.getServiceLists();
      this.readViewAppointment();
      this.getSettingsData();
    })
  }

  ngAfterViewInit(): void {
    if (this.serviceDropdown) {
      this.serviceDropdown.nativeElement;
    }
  }

  readViewAppointment() {
    this.filterSubject
    .pipe(debounceTime(300), distinctUntilChanged())
    .subscribe((filterValue) => {
      this.getSettingsData();
    });
    
    if(this.isViewingFromEmail && this.emailAppointmentData){
      this.isViewed.emit(true);
      this.viewAppointment(this.emailAppointmentData);
    }
  }

  timeMili(): number {
    const hours = 0;
    const millisecondsInHour = 60 * 60 * 1000;
    return hours * millisecondsInHour;
  }

  private formatDateWithourHours(dateCreated: number): string {
    const date = new Date(dateCreated);
    const formattedDate = this.datePipe.transform(date, ' MM/dd/yyyy, EEE');
    return formattedDate;
  }
  
  readAppointmentList() {
    this.isLoading = true;
    const approvalParams = this.appointmentApprovalParams;
    this.appointmentService
        .getForApprovedAppointmentList(this.branchId, approvalParams)
        .pipe(
          takeUntil(this.appointmentListSubjectAPI$),
          map((res) => res),
          tap((data) =>  {
            this.appointmentListLogic(data);
          }), 
          catchError((error) => {
            console.log("error on fetching queue history lists", error);
            return throwError(error);
         }),
         finalize(() => {
          //
        })
        ).subscribe()
  }

  appointmentListLogic(appointmentList) {
    const dataAppointment = [];
    this.appointmentForAppoval = appointmentList;

    appointmentList.data.forEach(appointment => {
      const settingTimeLate = !this.settingsLateTime || isNaN(this.settingsLateTime) ? 0 : this.settingsLateTime; 
      const date = new Date((appointment.timeslot+this.timeMili()) - settingTimeLate);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;

      // Use Angular's date pipe to format minutes
      const formattedMinutes = ('0' + minutes).slice(-2);

      let formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`; 
      appointment.formattedDate =  this.formatDateWithourHours(appointment.timeslot) 
                  
      appointment.formattedTimeslot = formattedTime;   
      for (const smartForm of appointment.visitorDetails) {

          switch (smartForm.fieldName) {
            case SMART_FORM_NAMES.NAME:
              appointment.firstname = smartForm.value;
              
              break;
            case SMART_FORM_NAMES.MOBILENO:
              appointment.mobileNumber = smartForm.value;
              break;
            case SMART_FORM_NAMES.PRIORITY:
            appointment.priority = smartForm.value;
              break;
            case SMART_FORM_NAMES.VISITORS:
              appointment.visitors = smartForm.value;
              break;
    
            default:
              break;
          }
        } 
      
        dataAppointment.push(appointment);
    });

    this.appointmentLists = dataAppointment;    
    this.approvalAppointmentList = appointmentList;
    const pages = appointmentList.pages;

    if (pages == 0) {
      this.noOfPages.push(1);
    } else {
      this.noOfPages = Array.from({ length: pages }, (_, i) => i + 1);
    }

    this.isLoading = false;
  }

  readApprovalTimeslotList() {
    this.subscriptions.add(
      this.appointmentService
          .getForApprovedTimeslotList(this.branchId)
          .pipe(
            takeUntil(this.appointmentListSubjectAPI$),
            map((res) => res),
            tap((data) =>  {
              this.approvalTimeslotListLogic(data);
            }), 
            catchError((error) => {
              console.log("error on fetching queue history lists", error);
              return throwError(error);
          }),
          finalize(() => {
            //
          })
          ).subscribe()
    )
  }

  approvalTimeslotListLogic(approvalTimeslotList){
    const dataAppointment = [];
    this.appointmentForAppoval = approvalTimeslotList;

    approvalTimeslotList.data.forEach(appointment => {
      const settingTimeLate = !this.settingsLateTime || isNaN(this.settingsLateTime) ? 0 : this.settingsLateTime; 
      const date = new Date((appointment.timeslot+this.timeMili()) - settingTimeLate);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;

      // Use Angular's date pipe to format minutes
      const formattedMinutes = ('0' + minutes).slice(-2);

      let formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;                
      appointment.formattedTimeslot = formattedTime;            
      dataAppointment.push(appointment);
    });
    this.getTimeslotFilter(dataAppointment);
    this.getDateFilter(dataAppointment);
  }
  selectedTimeslotFilter() {
    this.isSelectClickedTimeslot = !this.isSelectClickedTimeslot;
    this.isFocusedTimeslot = !this.isFocusedTimeslot;
  }

  selectedDateFilter(){
    this.isSelectClickedDate = !this.isSelectClickedDate;
    this.isFocusedDate = !this.isFocusedDate;
  }

  getDateFilter(appointmentList) {
    const appointmentListFilter = appointmentList.map(appointment => {
       return {
           label: appointment.formattedDate,
           value: appointment.formattedDate  
       }
     })
     this.removeDuplicatesDate(appointmentListFilter)
   }

   removeDuplicatesDate(appointmentListFilter): void {
    const uniqueTimeslotList = appointmentListFilter.filter(
      (value, index, self) =>
        self.findIndex((item) => item.value === value.value && item.label === value.label) === index
    );
      
    this.dateFilterList = this.sortArrayByDate(uniqueTimeslotList);
    this.dateFilterList.push({ value: null, label: "Show all dates"});
  }

  getTimeslotFilter(appointmentList) {
    const appointmentListFilter = appointmentList.map(appointment => {
      return {
        label: appointment.formattedTimeslot,
        value: appointment.selectedTimeslot  
      }
    })

    this.removeDuplicates(appointmentListFilter)
  }

  getSettingsData() {
     this.subscriptions.add(
      this.appointmentSettingService.getSettings(this.branchId).subscribe(
        (res) => {
          if(res) {
             res.data;
            this.settingsLateTime = res.data.lateAppointmentTimeRange.milliseconds ? res.data.lateAppointmentTimeRange.milliseconds : 0;
            this.readApprovalTimeslotList();
            this.readAppointmentList();
          }
        },
        (error) => {
          console.log(error)
        }
      )
    );
  }
  
  removeDuplicates(appointmentListFilter): void {
    const uniqueTimeslotList = appointmentListFilter.filter(
      (value, index, self) =>
        self.findIndex((item) => item.value === value.value && item.label === value.label) === index
    );

    const sortedTimeslotList = uniqueTimeslotList.sort((a, b) => {
      if (a.value === null) return 1;  // Place null values at the end
      if (b.value === null) return -1;
      return a.value - b.value;        // Sort by value in ascending order
  });

    this.timeslotFilter = sortedTimeslotList;
    this.timeslotFilter.push({ value: null, label: "Show all timeslot" })
  }

  sortArrayByDate(array: any[]): any[] {
    return array.sort((a, b) => {
      const timeA = new Date("2000-01-01 " + a.value);
      const timeB = new Date("2000-01-01 " + b.value);
  
      return timeA.getTime() - timeB.getTime();
    });
  }

  sortArrayByTime(array: any[]): any[] {
    return array.sort((a, b) => {
      return a.value - b.value;
    });
  }



  selectTimeslotDropdown(appointmentData) {
    this.selectedTimeslotValue = appointmentData.value;
    this.selectedTimeslotLabel = appointmentData.label
    this.filterSubject.next(this.selectedTimeslotValue);
    this.isFocusedTimeslot = false;
    this.getSettingsData();
  }

  selectDateDropdown(appointmentData) {
    this.selectedDateValue = appointmentData.value;
    this.selectedDateLabel = appointmentData.label
    this.filterSubject.next(this.selectedDateValue);
    this.isFocusedDate = false;
    this.getSettingsData();
  }

  get appointmentApprovalParams() {
    let params = new HttpParams();
    params = params.append("limit", this.itemsPerPage.toString());
    const offset = this.currentPage * this.itemsPerPage - this.itemsPerPage;
    params = params.append("offset", offset.toString());
    
    if (this.searchString) {
      params = params.append("search", this.searchString);
    }

    if (this.selectedTimeslotValue) {
      params = params.append("selectedTimeslot", this.selectedTimeslotValue.toString());
    }
    
    if (this.selectedDateValue) {
      params = params.append("formattedDate", this.selectedDateValue.toString());
    }
    
    if (this.selectedService.length > 0) {
      params = params.append("serviceId", this.selectedService.join(","));
    }
    
    return params;
  }

  async getServiceLists() {
    this.subscriptions.add(
      this.httpService
        .get$(`services/${this.branchId}?limit=999`)
        .pipe(
          takeUntil(this.appointmentListSubjectAPI$),
          map((res) => res.data), // for extraction of data
          tap((data) => {
            this.servicesList = data.map((element) => ({
              _id: element._id,
              name: element.displayName,
            }));
            this.setServiceData(this.servicesList);
          }),
          catchError((error) => {
            console.log("Error on fetching branch details", error);
            return throwError(error);
          }),
          finalize(() => {
          })
        )
        .subscribe()
    );
  }

  setServiceData(serviceData: any) {
    const serviceLists = serviceData.map((element) => {
      return {
        value: element._id,
        label: element.name,
      };
    });
    this.serviceCheckboxLists = this.addCheckedProperty(serviceLists);
  }

  addCheckedProperty(contents: Array<ICheckboxList>) {
    if (contents) {
      const newContents = contents.map((content) => ({
        ...content,
        checked: false, // for checkbox
      }));
      return newContents;
    }
  }

  public changeCheckboxList($event: ICheckboxData) {
    if ($event) {
      const key = $event?.key;
      const selected = $event.checkedArray;
      switch (key) {
        case "service":
          this.selectedServices = selected.length;
          this.selectedService = [];
          if (this.selectedServices > 0) {
            selected.forEach((element) => {
              this.selectedService.push(this.servicesList[element]._id);
            });
          }
          this.filterSubject.next(this.selectedServices);
          break;
        default:
          break;
      }
    }

    this.readAppointmentList();
    this.readApprovalTimeslotList();
  }

  toggleDropdown(dropdownId: string): void {
    // Close all other dropdowns before opening the current dropdown
    Object.keys(this.dropdownsVisible).forEach((id) => {
      if (id !== dropdownId) {
        this.closeDropdown(id);
      }
    });

    if (this.isDropdownVisible(dropdownId)) {
      this.closeDropdown(dropdownId);
    } else {
      this.openDropdown(dropdownId);
    }
    this.isFocused = false;
    this.isButtonClicked = false;
    this.isSelectClicked = false;
    this.isFocusedTimeslot = false;
    this.isFocusedDate = false;
    this.isSelectClickedTimeslot = false;
    this.isSelectClickedDate = false;
  }

  isDropdownVisible(dropdownId: string): boolean {
    return this.dropdownsVisible[dropdownId] ?? false; // Return the visibility state for the given dropdown ID
  }

  openDropdown(dropdownId: string): void {
    // Close all other dropdowns before opening the current dropdown
    Object.keys(this.dropdownsVisible).forEach((id) => {
      if (id !== dropdownId) {
        this.closeDropdown(id);
      }
    });

    this.dropdownsVisible[dropdownId] = true;
  }

  closeDropdown(dropdownId: string): void {
    this.dropdownsVisible[dropdownId] = false; // Set the visibility state for the given dropdown ID to false
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent): void {
    const clickedElement = event.target as HTMLElement;
    const datedropDownDiv = this.elementRef.nativeElement.querySelector(".date-dropdown");
    const timeslotDropDownDiv = this.elementRef.nativeElement.querySelector(".timeslot-dropdown");  

    if (!datedropDownDiv?.contains(clickedElement)) {
      if (this.isFocusedDate === true) {
          this.isFocusedDate = false;
      }
    }

    if (!timeslotDropDownDiv?.contains(clickedElement)) {
      if (this.isFocusedTimeslot === true) {
        this.isFocusedTimeslot = false;
      }
    }

    for (const dropdownId in this.dropdownsVisible) {
      if (this.dropdownsVisible.hasOwnProperty(dropdownId)) {
        const dropdownRef = this.elementRef.nativeElement.querySelector(
          `#${dropdownId}`
        );
        const dropdownMenuRef = this.elementRef.nativeElement.querySelector(
          `#${dropdownId}-menu`
        );
        if (dropdownRef && dropdownRef.contains(clickedElement)) {
          return;
        }
        if (dropdownMenuRef && dropdownMenuRef.contains(clickedElement)) {
          return;
        }
      }
    }
    this.dropdownsVisible = {};
  }

  public reject(appointmentData) {
   
      /* change view appointment modal to false */
      this.viewAppointmentModal = false;
      
      const initialState = {
        title: "Reject appointment?",
        data: appointmentData
      }
    
      this.modalRef = this.modalService.show(RemoveAppointmentModalComponent,{
        initialState: initialState,
        class: "modal-dialog-centered modal-md",
        ignoreBackdropClick: true,
        keyboard: false,
      });
    
      this.modalRef.content.successEvent.subscribe(
        (data) => {
          if(data === 'deleted-move-history'){
             this.readAppointmentList();
             this.readApprovalTimeslotList();
             this.isApprovedOrRejected.emit(true);
          }
        },
        (err) => {
          return false;
        }
      );
    
  }

  edit(appointmentData) {
    /* change view appointment modal to false */
    this.viewAppointmentModal = false;
  
    const initialState = {
      title: "Edit Appointment",
      data: appointmentData
    }
  
    this.modalRef = this.modalService.show(EditAppointmentModalComponent,{
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  
     this.modalRef.content.successEvent.subscribe(
      (data) => {
      
        if(data === 'edit'){
          this.readAppointmentList();
          this.readApprovalTimeslotList();
   
        }else {
          this.readAppointmentList();
          this.readApprovalTimeslotList();
        }
  
    }
      ,
      (err) => {
        return false;
      }
    ); 
  }

  approveDeclineAppointment(appointment) {
    this.viewAppointmentModal = false;
    
    const body = {
      approved: true,
      appointmentId: appointment._id
    }

    this.appointmentService.approveDeclineAppointment(this.branchId,body).subscribe(
      (res) => {
        this.readAppointmentList();
        this.isApprovedOrRejected.emit(true);
        this.modalRef.hide();
        this.toastr.success(`${res.data?.referenceNo} has been approved`, "Success!",{
          toastClass: "ngx-toastr toast-success-custom",
        });
      },
      (error) => {
        console.log(error)
        this.toastr.error("Error encountered upon submitting ", "Error!");
      }
    )
  }

  public pageChanged($event) {
    this.currentPage = $event.page;
    this.filterSubject.next(this.currentPage);
    this.readAppointmentList();
    this.readApprovalTimeslotList();
  }


  jumpToPage(page: string) {
    this.currentPage = parseInt(page);
    this.filterSubject.next(this.currentPage);
    this.readAppointmentList();
    this.readApprovalTimeslotList();
    this.cdr.detectChanges();
  }

  getNumberArray(num: number, offset: number = 0): number[] {
    if (num > 0) {
        return Array(num).fill(0).map((x, i) => i + offset);
    } else {
        return [1]
    }
}

  public entriesChanged(event) {
  }

  public selectedDateChanged($event) {

  }

  public viewAppointment(appointmentData){
    
    if(!this.viewAppointmentModal) {
      /* change view appointment modal to true */
      this.viewAppointmentModal = true;
    } else {
      const initialState = {
        title: "View Appointment Details",
        data: appointmentData,
        isDisableCompleteBtn: this.isDisableCompleteBtn,
        forApproval: true
      }
      this.modalRef =  this.modalService.show(AppointmentDetailsModalComponent,{
        initialState: initialState,
        class: "modal-dialog-centered modal-md",
        ignoreBackdropClick: true,
        keyboard: false,
      });
      this.modalRef.content.successEvent.subscribe(
        (data) => {
          if(data === 'approve-appointment' || data === 'reject-appointment' || data === 'edit-appointment'){
            this.readAppointmentList();
         }
        },
        (err) => {
          console.log("edit account", err);
          return false;
        }
      );
    }
  }

  getFormattedTime(milliseconds: number){
    let hours = Math.floor(milliseconds/(1000 * 60 * 60));
    let minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const ampm = `${hours < 12 ? "AM" : "PM"}`;
    if(hours>12){
        hours = hours - 12;
    }
    if(minutes < 10){
        return `${hours}:0${minutes} ${ampm}`;
    }
    return `${hours}:${minutes} ${ampm}`;
  }

  searchAppointment() {
    this.searchSubject.next(this.searchString);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
