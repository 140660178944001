import {
  Component,
  OnInit,
  HostListener,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { EMPTY, of, throwError } from "rxjs";
import { BranchManagementService } from "src/app/modules/branch-management/services/branch-management.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { DatePickerModalComponent } from "src/app/shared/components/date-picker-modal/date-picker-modal.component";
import { defaultAvatar } from "src/app/core/services/global";
import { Router } from "@angular/router";
import { convertMillisecondsToTime } from "src/app/shared/helpers/time.helpers";
import { calculateQueuePercentage } from "src/app/shared/helpers/percentage.helpers";
import { DataService } from "src/app/core/services/data.service";
import { Subject, Subscription } from "rxjs";
import {
  catchError,
  finalize,
  map,
  retry,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import {
  DateFilters,
  IMonitoringCardData,
} from "../../interfaces/monitoring.interface";
import { AuthService } from "src/app/core/auth/auth.service";
import { MonitoringService } from "../../services/monitoring.service";
import { DatePipe } from "@angular/common";
import { TitleCasePipe } from "@angular/common";
import {
  defaultOperatingHoursLabel,
  defaultWeeklyLabel,
  defaultDateFilter,
} from "../../constants/monitoring.constants";

const defaultMonitoringCardData: IMonitoringCardData = {
  leftDetails: {},
  rightDetails: {},
};

@Component({
  selector: "app-monitoring-page",
  templateUrl: "./monitoring-page.component.html",
  styleUrls: ["./monitoring-page.component.scss"],
  providers: [TitleCasePipe],
})
export class MonitoringPageComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  defaultAvatar: string = defaultAvatar;
  branchLists: Array<any> = [];
  branchDetails: Array<any> = [];
  queueData: {
    totalQueues: IMonitoringCardData;
    completedQueues: IMonitoringCardData;
    removedQueues: IMonitoringCardData;
    // servedQueues: IMonitoringCardData;
    onGoingQueues: IMonitoringCardData;
  } = {
      totalQueues: { ...defaultMonitoringCardData },
      completedQueues: { ...defaultMonitoringCardData },
      removedQueues: { ...defaultMonitoringCardData },
      // servedQueues: { ...defaultMonitoringCardData },
      onGoingQueues: { ...defaultMonitoringCardData },
    };
  bestPerformingBranches: Array<any> = [];
  bestPerformingStaffs: Array<any> = [];
  selectedDateFilter = "today";
  selectedDateFilterLabel = "Today";
  selectedValue = "today";
  selectedLabel = "Today";
  customDate1: string = "";
  customDate2: string = "";
  isFocused: boolean = false;
  isSelectClicked: boolean = false;
  isButtonClicked: boolean = false;
  dateFilter = defaultDateFilter.filter((item) => item.value !== "this-year");
  subscriptions = new Subscription();
  totalQueuesChartData: any;
  totalQueuesChartOptions: any;
  totalQueuesLabel: string[] = [];
  businessId = this.authService.accountData$?.businessId;
  private monitoringPageSubjectAPI$ = new Subject<void>();
  currentBranch = this.dataService.currentBranch$;
  dateLabel: string = "Today";
  currentMonth: string = "";
  isSingleDate: boolean = false;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private branchService: BranchManagementService,
    private monitoringService: MonitoringService,
    private bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    public titleCasePipe: TitleCasePipe
  ) {
    this.initializeChart();
    this.supplyCardDetails();
    this.currentMonth = this.getCurrentMonth();
  }

  getCurrentMonth(): string {
    const currentDate = new Date();
    const currentMonth = this.datePipe
      .transform(currentDate, "MMMM")
      .toUpperCase();
    return currentMonth;
  }

  initializeChart() {
    this.totalQueuesChartOptions = {
      legend: {
        display: false,
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              fontSize: 12,
              fontColor: "#191444",
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              fontSize: 12,
              fontColor: "#191444",
              min: 0,
              stepSize: 5,
              suggestedMax: 60,
            },
          },
        ],
      },
    };
  }

  supplyCardDetails() {
    this.queueData.totalQueues.leftDetails = {
      ...this.queueData.totalQueues.leftDetails,
      value: 0,
      percentage: null,
      counts: { today: 0, yesterday: 0 },
    };

    this.queueData.completedQueues.leftDetails = {
      ...this.queueData.completedQueues.leftDetails,
      value: 0,
      percentage: null,
      counts: { today: 0, yesterday: 0 },
    };

    // this.queueData.servedQueues.leftDetails = {
    //   ...this.queueData.servedQueues.leftDetails,
    //   value: 0,
    //   percentage: null,
    //   counts: { today: 0, yesterday: 0 },
    // };

    this.queueData.removedQueues.leftDetails = {
      ...this.queueData.removedQueues.leftDetails,
      value: 0,
      percentage: null,
      counts: { today: 0, yesterday: 0 },
    };

    this.queueData.onGoingQueues.leftDetails = {
      ...this.queueData.onGoingQueues.leftDetails,
      value: 0,
      formattedValue: 0,
    };

    this.queueData.totalQueues.rightDetails = {
      isSvg: false,
      style: "ic_people",
      size: 24,
      color: "#034D6C",
      bgColor: "#CDEEFF",
      addClass: "",
    };

    this.queueData.completedQueues.rightDetails = {
      isSvg: false,
      style: "ic_checkmark-circle",
      size: 24,
      color: "#104A19",
      bgColor: "#EFFBF1",
      addClass: "",
    };

    // this.queueData.servedQueues.rightDetails = {
    //   isSvg: false,
    //   style: "ic_person-delete",
    //   size: 24,
    //   color: "#191444",
    //   bgColor: "#FFF2E7",
    //   addClass: "",
    // };

    this.queueData.removedQueues.rightDetails = {
      isSvg: false,
      style: "ic_trash",
      size: 24,
      color: "#630500",
      bgColor: "#FFCDCD",
      addClass: "",
    };

    this.queueData.onGoingQueues.rightDetails = {
      isSvg: false,
      style: "ic_timer",
      size: 24,
      color: "#886D00",
      bgColor: "#FFF3CD",
      addClass: "",
    };
  }

  ngOnInit(): void {
    this.getBranches();
    this.selectDropdown(this.dateFilter[0]);
  }

  setMonthDays() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const numberOfDays = new Date(currentYear, currentMonth, 0).getDate();
    return Array.from({ length: numberOfDays }, (_, i) => (i + 1).toString());
  }

  public getBranches(): void {
    this.branchService
      .getBranchList()
      .pipe(
        takeUntil(this.monitoringPageSubjectAPI$),
        map((res) => res.data),
        switchMap((data) => {
          if (data.length === 1) {
            this.goToBranchMonitoringPage(data[0]._id);
            return EMPTY;
          }
          this.branchLists = data; 
          return of(this.branchLists);
        }),
        retry(1),
        catchError((error) => {
          console.log(
            "Error on fetching business monitoring dashboard data",
            error
          );
          return throwError(error);
        })
      )
      .subscribe();
  }

  getMonitoringDashboardData() {
    if (this.businessId == undefined || !this.businessId) {
      this.businessId = this.dataService.accountData$?.data?.businessId;
    }
    
    this.isLoading = true;

    this.isSingleDate =
      this.selectedValue === "custom" &&
      this.customDate1 &&
      this.customDate2 &&
      this.customDate1 === this.customDate2;

    let dateFilter: DateFilters | string;

    if (this.selectedValue === "custom") {
      const startDate = new Date(this.customDate1).setHours(0, 0, 0);
      const endDate = new Date(this.customDate2).setHours(23, 59, 59);
      dateFilter = { startDate, endDate };
    } else {
      dateFilter = this.selectedValue;
    }

    this.monitoringService
      .getMonitoringDashboardData(
        this.businessId,
        dateFilter,
        this.isSingleDate
      )
      .pipe(
        takeUntil(this.monitoringPageSubjectAPI$),
        map((res) => res.data),
        tap((data) => {
          this.setChartData(data?.totalCountsGraph);
          this.bestPerformingBranches = data?.bestPerformingBranches;
          this.bestPerformingStaffs = data?.bestPerformingStaffs;
          this.setQueueDetails(data?.queueDetails);
        }),
        catchError((error) => {
          console.log(
            "Error on fetching business monitoring dashboard data",
            error
          );
          return throwError(error);
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  loopData(data: any, label: number) {
    let newData = [];
    data.forEach((element, index) => {
      switch (label) {
        case 0: //label
          if (
            this.selectedValue == "today" ||
            this.selectedValue == "yesterday"
          ) {
            newData.push(element.time);
          } else if (this.selectedValue == "this-week") {
            newData = defaultWeeklyLabel;
          } else if (this.selectedValue == "this-month") {
            newData.push(index + 1);
          } else if (this.selectedValue == "custom") {
            if (this.isSingleDate) {
              newData.push(element.time);
            } else {
              let idx: number = element.date.lastIndexOf(",");
              let date1 = element.date.substring(idx, idx - 3);
              let date2 = date1.replace(/\D/g, "");
              newData.push(date2.trim());
            }
          }
          break;
        case 1: //total queues
          const totalQueues =
            element.completed +
            element.cancelled +
            element.onGoing +
            element.removed;
          newData.push(totalQueues);
          break;
        default:
          break;
      }
    });

    if (newData.length > 0) {
      if (label == 0 && this.selectedValue == "custom" && !this.isSingleDate) {
        newData.shift();
      }
      if (label == 1 && this.selectedValue == "custom" && !this.isSingleDate) {
        newData.shift();
      }
    }

    return newData;
  }

  setChartData(totalQueues: any) {
    this.totalQueuesChartData = {
      labels: totalQueues
        ? this.loopData(totalQueues, 0)
        : this.totalQueuesLabel,
      datasets: [
        {
          label: "Total Queues",
          backgroundColor: "#1AB2FF",
          hoverBackgroundColor: "#CDEEFF",
          data: totalQueues ? this.loopData(totalQueues, 1) : [],
          barPercentage: 0.1,
          barThickness: 8,
          maxBarThickness: 8,
        },
      ],
    };

    if (this.selectedValue === "this-month" || (this.selectedValue === "custom" && !this.isSingleDate)) {
      this.totalQueuesChartOptions.tooltips = {
        callbacks: {
          label: (tooltipItem, data) => {
            if (tooltipItem) {
              tooltipItem.xLabel = "";
              const index = tooltipItem.index;
              const value = tooltipItem.value;
              const date = this.selectedValue === "this-month" ? totalQueues[index].date : totalQueues[index + 1].date;
              const label = `Total Queues for ${this.datePipe.transform(date, "MMMM d, yyyy")}: ${value}`;
              return label;
            }
          },
        },
      };
    } else {
      this.totalQueuesChartOptions.tooltips = {};
    }
  }

  setQueueDetails(data: any) {
    const totalQueues = data.totalQueues;
    const completedQueues = data.completedQueues;
    // const servedQueues = data.servedQueues;
    const removedQueues = data.removedQueues;
    const onGoingQueues = data.onGoingQueues;

    this.queueData.totalQueues.leftDetails = {
      ...this.queueData.totalQueues.leftDetails,
      value: totalQueues.counts.current ?? 0,
      percentage: calculateQueuePercentage(
        totalQueues.counts.current,
        totalQueues.counts.previous
      ),
      counts: {
        today: totalQueues.counts.current,
        yesterday: totalQueues.counts.previous,
      },
    };

    this.queueData.completedQueues.leftDetails = {
      ...this.queueData.completedQueues.leftDetails,
      value: completedQueues.counts.current ?? 0,
      percentage: calculateQueuePercentage(
        completedQueues.counts.current,
        completedQueues.counts.previous
      ),
      counts: {
        today: completedQueues.counts.current,
        yesterday: completedQueues.counts.previous,
      },
    };

    // this.queueData.servedQueues.leftDetails = {
    //   ...this.queueData.servedQueues.leftDetails,
    //   value: servedQueues.counts.current ?? 0,
    //   percentage:
    //     this.selectedValue == "today"
    //       ? calculateQueuePercentage(
    //           servedQueues.counts.current,
    //           servedQueues.counts.previous
    //         )
    //       : null,
    //   counts: {
    //     today: servedQueues.counts.current,
    //     yesterday: servedQueues.counts.previous,
    //   },
    // };

    this.queueData.removedQueues.leftDetails = {
      ...this.queueData.removedQueues.leftDetails,
      value: removedQueues.counts.current ?? 0,
      percentage: calculateQueuePercentage(
        removedQueues.counts.current,
        removedQueues.counts.previous
      ),
      counts: {
        today: removedQueues.counts.current,
        yesterday: removedQueues.counts.previous,
      },
    };

    this.queueData.onGoingQueues.leftDetails = {
      ...this.queueData.onGoingQueues.leftDetails,
      value: onGoingQueues.counts ?? 0,
      formattedValue:
        onGoingQueues && onGoingQueues.counts > 0 && onGoingQueues.avgWaitingTime > 0
          ? convertMillisecondsToTime(onGoingQueues.avgWaitingTime)
          : 0,
    };
    this.setDateLabel();
    this.cdr.detectChanges();
  }

  selectCustomDateFilter() {
    const initialState = {
      hasMinDate: false,
      datePickerType: "range",
    };
    this.bsModalRef = this.bsModalService.show(DatePickerModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md2",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.bsModalRef.content.selectedDateRange.subscribe(
      (data) => {
        if (data) {
          if (data == "cancel") {
            //
          } else {
            if (data[0] && data[1]) {
              this.selectedValue = "custom";
              this.customDate1 = (data[0] as Date).toLocaleDateString("en-US");
              this.customDate2 = (data[1] as Date).toLocaleDateString("en-US");
              this.selectedLabel = this.customDate1 + " - " + this.customDate2;
              this.selectedDateFilterLabel =
                this.customDate1 + " - " + this.customDate2;

              this.getMonitoringDashboardData();
            }
          }
        }
      },
      (err) => {
        return false;
      }
    );
  }

  select() {
    this.isSelectClicked = !this.isSelectClicked;
    this.isFocused = !this.isFocused;
  }

  @HostListener("document:click")
  deselect() {
    if (!this.isSelectClicked || this.isButtonClicked) {
      this.isFocused = false;
    }
    this.isButtonClicked = false;
    this.isSelectClicked = false;
  }

  selectDropdown(list) {
    if (list.value == "custom") {
      this.selectCustomDateFilter();
    } else {
      this.selectedValue = list.value;
      this.selectedLabel = list.label;

      this.getMonitoringDashboardData();
    }
    this.isButtonClicked = true;
    if (!this.isFocused) {
      this.isButtonClicked = false;
    }
  }

  setDateLabel() {
    switch (this.selectedValue) {
      case "today":
        this.dateLabel = "yesterday";
        break;
      case "yesterday":
        this.dateLabel = "two days ago";
        break;
      case "this-week":
        this.dateLabel = "last week";
        break;
      case "this-month":
        this.dateLabel = "last month";
        break;
      default:
        this.dateLabel = "";
        break;
    }
  }

  goToBranchMonitoringPage(branchId: string): void {
    if (branchId !== null && branchId !== undefined) {
      this.router.navigate(["/monitoring", branchId]);
    }
  }

  generateReport() { }

  isAddressTooLong(address: string): boolean {
    const maxLength = 50; 
    return address.length > maxLength;
  }

  ngOnDestroy() {
    // this.subscriptions.unsubscribe();
    this.monitoringPageSubjectAPI$.next();
    this.monitoringPageSubjectAPI$.complete();
  }
}
