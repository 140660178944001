import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { BillingService } from "../../services/billing.service";

@Component({
  selector: "app-cancel-subscription-modal",
  templateUrl: "./cancel-subscription-modal.component.html",
  styleUrls: ["./cancel-subscription-modal.component.scss"],
})
export class CancelSubscriptionModalComponent implements OnInit {
  @Input() businessId: string;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() subscriptionPlan: any;
  @Output() successEvent = new EventEmitter();
  isSubmitting = false;

  constructor(
    public modalRef: BsModalRef,
    private billingService: BillingService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  cancelSubscription() {
    this.isSubmitting = true;
    this.billingService.cancelSubscription(this.businessId).subscribe(
      (data) => {
        this.isSubmitting = false;
      
        this.toastr.error('Your subscription has been cancelled', 'Cancelled!');
        this.modalRef.hide();
        this.successEvent.emit(data);
      },
      (error) => {
        this.isSubmitting = false;
        console.log(error);
      }
    );

  }
}
