import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpService } from 'src/app/core/http/http.service';
import { DataService } from 'src/app/core/services/data.service';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-modal-warning-image',
  templateUrl: './modal-warning-image.component.html',
  styleUrls: ['./modal-warning-image.component.scss']
})
export class ModalWarningImageComponent extends ModalService implements OnInit {
  title: string;
  subTitle: string;
  mainButtonText: string;
  secondaryButtonText: string;
  secondaryAction: string;

  constructor(
    public bsModalRef: BsModalRef,
    public httpService: HttpService,
    public dataService: DataService,
    public toastr: ToastrService,
    public authService: AuthService
  ) {
    super(bsModalRef, httpService, dataService, toastr, authService)
  }

  onClickSecondary() {
    this.bsModalRef.hide();
    this.dataService.publishCloseModalReason$(this.secondaryAction);
  }

  ngOnInit(): void {
  }

}
