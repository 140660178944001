import { HttpParams } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subject, Subscription, throwError } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  takeUntil,
  tap,
} from "rxjs/operators";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { TeamManagementService } from "src/app/modules/team-management/services/team-management.service";
import { DatePickerModalComponent } from "src/app/shared/components/date-picker-modal/date-picker-modal.component";
import { QueueDetailsModalComponent } from "../../component/queue-details-modal/queue-details-modal.component";
import { QueueHistoryService } from "../../services/queue-history.service";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import * as moment from "moment";
import { TimePipe } from "src/app/shared/pipes/time.pipe";
import { TitleCasePipe } from "@angular/common";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { getDuration } from "src/app/shared/helpers/time.helpers";
import { getAccountName } from "src/app/shared/helpers/team-management.helpers";

interface CheckboxList {
  value: string;
  label: string;
  checked?: boolean;
}

interface CheckboxData {
  key: string;
  checkedArray: number[];
}

interface FilterData {
  value: string;
  label: string;
}

@Component({
  selector: "app-queue-history-page",
  templateUrl: "./queue-history-page.component.html",
  styleUrls: ["./queue-history-page.component.scss"],
  providers: [TitleCasePipe],
})
export class QueueHistoryPageComponent implements OnInit {
  pageDetails = [
    { type: "main", name: "Queue Workspace", routerLink: ["/queue-workspace"] },
    { type: "current", name: "Queue History", routerLink: null },
  ];
  // queueHistory = {
  //   pages: 1,
  //   success: true,
  //   total: 1,
  //   data: [],
  // };
  // isLoading = false;
  // isDownloading = false;
  // itemsPerPage = 10;
  // selectedPage = 1;
  // items = 100;
  // searchSubject: Subject<string> = new Subject();
  // filterSubject: Subject<any> = new Subject();
  // searchString: string;
  // selectedDateFilterLabel = "This Month";
  // selectedDateFilter = "this-month";
  // accountList: any;
  subscriptions = new Subscription();
  branchId: string = "";
  // selectedUsers = [];
  // selectedStatus = [];
  // serviceLists: any;
  // selectedService = [];
  // customDate1: any;
  // customDate2: any;
  isLoading: boolean = false;
  isDownloading: boolean = false;
  accountLists: Array<any> = [];
  serviceLists: Array<any> = [];
  queueHistory = {
    pages: 1,
    success: true,
    total: 1,
    data: [],
  };
  itemsPerPage = 10;
  currentPage = 1;
  items = 100;
  searchSubject: Subject<string> = new Subject();
  filterSubject: Subject<any> = new Subject();
  searchString: string | null;
  noOfEntries: number[] = [10, 20, 30];
  noOfPages: number[] = [];
  selectedStaffs: Array<any> = [];
  selectedService: Array<any> = [];
  selectedStatus: Array<any> = [];
  selectAllStaffs: boolean = false;
  accountCheckboxLists: Array<CheckboxList> = [];
  serviceCheckboxLists: Array<CheckboxList> = [];
  statusCheckboxLists: Array<CheckboxList> = [];
  selectedAccounts: number = 0;
  selectedServices: number = 0;
  selectedStatuses: number = 0;
  statusLists = [
    { value: "completed", label: "Completed" },
    { value: "removed", label: "No Show (Removed)" },
    { value: "cancelled", label: "Cancelled" },
  ];
  dateFilter: Array<FilterData> = [
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "this-week", label: "This Week" },
    { value: "this-month", label: "This Month" },
    { value: "custom", label: "Custom" },
    // { value: "this-year", label: "This Year" },
  ];
  isFocused: boolean = false;
  isSelectClicked: boolean = false;
  isButtonClicked: boolean = false;
  selectedDateFilterLabel = "This Month";
  selectedDateFilter = "this-month";
  customDate1: string = "";
  customDate2: string = "";
  private queueHistorySubjectAPI$ = new Subject<void>();

  constructor(
    private queueHistoryService: QueueHistoryService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private teamService: TeamManagementService,
    private dataService: DataService,
    private httpService: HttpService,
    private titleCase: TitleCasePipe,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.setStatusData(this.statusLists);
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe(async (res) => {
        await this.handleBranchChange(res);

        await this.getAccounts(this.branchId);
        await this.getServiceLists(this.branchId);
        await this.getQueueHistory(this.branchId);
      })
    );

    this.searchSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchValue) => {
        this.getQueueHistory(this.branchId);
      });

    this.filterSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((filterValue) => {
        this.getQueueHistory(this.branchId);
      });
  }

  async handleBranchChange(res: any): Promise<void> {
    this.branchId = res ? res._id : this.dataService.currentBranch$.branchId;
  }

  get historyParams() {
    let params = new HttpParams();
    params = params.append("limit", this.itemsPerPage.toString());
    const offset = this.currentPage * this.itemsPerPage - this.itemsPerPage;
    params = params.append("offset", offset.toString());
    if (this.searchString) {
      params = params.append("search", this.searchString);
    }
    if (this.selectedStaffs.length > 0) {
      params = params.append("servedBy", this.selectedStaffs.join(","));
    }
    if (this.selectedStatus.length > 0) {
      params = params.append("status", this.selectedStatus.join(","));
    }
    if (this.selectedService.length > 0) {
      params = params.append("serviceId", this.selectedService.join(","));
    }
    if (this.selectedDateFilter === "custom") {
      params = params.append(
        "startDate",
        new Date(this.customDate1).setHours(0, 0, 0).toString()
      );
      params = params.append(
        "endDate",
        new Date(this.customDate2).setHours(23, 59, 59).toString()
      );
    } else {
      params = params.append("filterBy", this.selectedDateFilter);
    }
    return params;
  }

  get isSearch() {
    if (this.searchString) {
      return true;
    }
    if (this.selectedStaffs.length > 0) {
      return true;
    }
    if (this.selectedStatus.length > 0) {
      return true;
    }
    if (this.selectedService.length > 0) {
      return true;
    }
  }

  async getAccounts(branchId: string) {
    this.subscriptions.add(
      this.teamService
        .getTeamList("", branchId)
        .pipe(
          takeUntil(this.queueHistorySubjectAPI$),
          map((res) => res.data),
          tap((data) => {
            this.accountLists = data.map((element) => {
              return {
                _id: element._id,
                name: element.firstName + " " + element.lastName,
                avatarUrl: element.avatarUrl,
              };
            });
            this.setAccountData(this.accountLists);
          }),
          catchError((error) => {
            console.log("error on fetching account lists", error);
            return throwError(error);
          }),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe()
    );
  }

  async getServiceLists(branchId: string) {
    this.subscriptions.add(
      this.httpService
        .get$(`services/${branchId}?limit=999`)
        .pipe(
          takeUntil(this.queueHistorySubjectAPI$),
          map((res) => res.data), // for extraction of data
          tap((data) => {
            this.serviceLists = data.map((element) => ({
              _id: element._id,
              name: element.displayName,
            }));
            this.setServiceData(this.serviceLists);
          }),
          catchError((error) => {
            console.log("Error on fetching branch details", error);
            return throwError(error);
          }),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe()
    );
  }

  async getQueueHistory(branchId: string) {
    this.subscriptions.add(
      this.queueHistoryService
        .getQueueHistory(this.historyParams, branchId)
        .pipe(
          takeUntil(this.queueHistorySubjectAPI$),
          map((res) => res),
          tap((data) => {
            console.log("data of queue history")
            console.log(data);
            this.queueHistory = data;
            const pages = data.pages;
            if (pages == 0) {
              this.noOfPages.push(1);
              console.log("queue history if ");
              console.log(this.noOfPages);
            } else {
              this.noOfPages = Array.from({ length: pages }, (_, i) => i + 1);
              console.log("queue history else ");
              console.log(this.noOfPages);
            }
          }),
          catchError((error) => {
            console.log("error on fetching queue history lists", error);
            return throwError(error);
          }),
          finalize(() => {
            //
          })
        )
        .subscribe()
    );
  }

  public searchHistory() {
    this.searchSubject.next(this.searchString);
    // this.getQueueHistory(this.branchId);
  }

  pageChange(event: PageChangedEvent) {
    this.currentPage = event.page;
    this.filterSubject.next(this.currentPage);
    this.getQueueHistory(this.branchId);
  }

  jumpToPage(page: string) {
    this.currentPage = parseInt(page);
    this.filterSubject.next(this.currentPage);
    this.getQueueHistory(this.branchId);
    this.cdr.detectChanges();
  }

  entriesChange(a) {
    this.itemsPerPage = a;
    this.filterSubject.next(this.itemsPerPage);
    this.getQueueHistory(this.branchId);
  }

  setAccountData(accountData: any) {
    const accountsLists = accountData.map((element) => {
      return {
        value: element._id,
        label: element.name,
      };
    });
    this.accountCheckboxLists = this.addCheckedProperty(accountsLists);
  }

  setServiceData(serviceData: any) {
    const serviceLists = serviceData.map((element) => {
      return {
        value: element._id,
        label: element.name,
      };
    });
    this.serviceCheckboxLists = this.addCheckedProperty(serviceLists);
  }

  setStatusData(statusData: any) {
    this.statusCheckboxLists = this.addCheckedProperty(statusData);
  }

  addCheckedProperty(contents: Array<CheckboxList>) {
    if (contents) {
      const newContents = contents.map((content) => ({
        ...content,
        checked: false, // for checkbox
      }));
      return newContents;
    }
  }

  changeCheckboxList($event: CheckboxData) {
    if ($event) {
      const key = $event?.key;
      const selected = $event.checkedArray;
      switch (key) {
        case "staff":
          this.selectedAccounts = selected.length;
          this.selectedStaffs = [];
          if (this.selectedAccounts > 0) {
            selected.forEach((element) => {
              this.selectedStaffs.push(this.accountLists[element]._id);
            });
          }
          this.filterSubject.next(this.selectedStaffs);
          break;
        case "service":
          this.selectedServices = selected.length;
          this.selectedService = [];
          if (this.selectedServices > 0) {
            selected.forEach((element) => {
              this.selectedService.push(this.serviceLists[element]._id);
            });
          }
          this.filterSubject.next(this.selectedService);
          break;
        case "status":
          this.selectedStatuses = selected.length;
          this.selectedStatus = [];
          if (this.selectedStatuses > 0) {
            selected.forEach((element) => {
              this.selectedStatus.push(this.statusLists[element].value);
            });
          }
          this.filterSubject.next(this.selectedStatus);
          break;
        default:
          break;
      }
      this.getQueueHistory(this.branchId);
    }
  }

  selectDate() {
    this.isSelectClicked = !this.isSelectClicked;
    this.isFocused = !this.isFocused;
  }

  @HostListener("document:click")
  deselect() {
    if (!this.isSelectClicked || this.isButtonClicked) {
      this.isFocused = false;
    }
    this.isButtonClicked = false;
    this.isSelectClicked = false;
  }

  selectDateDropdown(list) {
    if (list.value == "custom") {
      this.selectCustomDateFilter();
    } else {
      this.selectedDateFilter = list.value;
      this.selectedDateFilterLabel = list.label;
      this.filterSubject.next(this.selectedDateFilter);
      this.getQueueHistory(this.branchId);
    }
    this.isSelectClicked = !this.isSelectClicked;
    this.isFocused = false;
  }

  selectCustomDateFilter() {
    const initialState = {
      hasMinDate: false,
      datePickerType: "range",
    };
    this.modalRef = this.modalService.show(DatePickerModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md2",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.selectedDateRange.subscribe(
      (data) => {
        if (data) {
          if (data == "cancel") {
            //
          } else {
            if (data[0] && data[1]) {
              this.selectedDateFilter = "custom";
              this.customDate1 = (data[0] as Date).toLocaleDateString("en-US");
              this.customDate2 = (data[1] as Date).toLocaleDateString("en-US");
              this.selectedDateFilterLabel = this.customDate1 + " - " + this.customDate2;

              this.filterSubject.next(this.selectedDateFilter);
              this.getQueueHistory(this.branchId);
            }
          }
        }
      },
      (err) => {
        return false;
      }
    );
  }

  // getServiceLists() {
  //   this.httpService
  //     .get$(`services/${this.branchId}?limit=999`)
  //     .subscribe((data) => {
  //       this.serviceLists = data.data.map((element) => {
  //         return {
  //           label: element.displayName,
  //           id: element._id,
  //         };
  //       });
  //     });
  // }

  // getQueueHistory() {
  //   this.isLoading = true;
  //   this.queueHistoryService
  //     .getQueueHistory(this.historyParams, this.branchId)
  //     .subscribe(
  //       (res) => {
  //         this.queueHistory = res;
  //         this.isLoading = false;
  //       },
  //       (error) => {
  //         this.isLoading = false;
  //         console.log("HISTORY LIST error", error);
  //       }
  //     );
  // }

  // getAccounts() {
  //   this.teamService.getTeamList("", this.branchId).subscribe((res) => {
  //     this.accountList = res.data;
  //   });
  // }

  // selectAllUsersFilter(isChecked) {
  //   const usersCheckbox = document.getElementsByName("users-checkbox") as any;
  //   this.selectedUsers = [];

  //   if (isChecked) {
  //     this.accountList.forEach((element) => {
  //       this.selectedUsers.push(element._id);
  //     });
  //   }

  //   usersCheckbox.forEach((element) => {
  //     element.checked = isChecked;
  //   });

  //   this.filterSubject.next(this.selectedUsers);
  // }

  // selectUserFilter(id) {
  //   if (this.selectedUsers.includes(id)) {
  //     const index = this.selectedUsers.indexOf(id);
  //     if (index > -1) {
  //       this.selectedUsers.splice(index, 1);
  //     }
  //   } else {
  //     this.selectedUsers.push(id);
  //   }

  //   this.getQueueHistory();
  // }

  // clearUserFilter() {
  //   this.selectedUsers = [];
  //   const usersCheckbox = document.getElementsByName("users-checkbox") as any;
  //   const allUsersCheckbox = document.getElementsByName(
  //     "all-users-checkbox"
  //   ) as any;
  //   allUsersCheckbox[0].checked = false;
  //   usersCheckbox.forEach((element) => {
  //     element.checked = false;
  //   });
  //   this.getQueueHistory();
  // }

  // selectAllServiceFilter(isChecked) {
  //   const usersCheckbox = document.getElementsByName("service-checkbox") as any;
  //   this.selectedService = [];

  //   if (isChecked) {
  //     this.serviceLists.forEach((element) => {
  //       this.selectedService.push(element.id);
  //     });
  //   }

  //   usersCheckbox.forEach((element) => {
  //     element.checked = isChecked;
  //   });

  //   this.filterSubject.next(this.selectedService);
  // }

  // selectServiceFilter(id) {
  //   if (this.selectedService.includes(id)) {
  //     const index = this.selectedService.indexOf(id);
  //     if (index > -1) {
  //       this.selectedService.splice(index, 1);
  //     }
  //   } else {
  //     this.selectedService.push(id);
  //   }

  //   this.getQueueHistory();
  // }

  // clearServiceFilter() {
  //   this.selectedService = [];
  //   const usersCheckbox = document.getElementsByName("service-checkbox") as any;
  //   const allUsersCheckbox = document.getElementsByName(
  //     "all-service-checkbox"
  //   ) as any;
  //   allUsersCheckbox[0].checked = false;
  //   usersCheckbox.forEach((element) => {
  //     element.checked = false;
  //   });
  //   this.getQueueHistory();
  // }

  // selectAllStatusFilter(isChecked) {
  //   const usersCheckbox = document.getElementsByName("status-checkbox") as any;
  //   this.selectedStatus = [];

  //   if (isChecked) {
  //     this.selectedStatus.push("completed");
  //     this.selectedStatus.push("removed");
  //     this.selectedStatus.push("cancelled");
  //   }

  //   usersCheckbox.forEach((element) => {
  //     element.checked = isChecked;
  //   });

  //   this.filterSubject.next(this.selectedStatus);
  // }

  // selectStatusFilter(id) {
  //   if (this.selectedStatus.includes(id)) {
  //     const index = this.selectedStatus.indexOf(id);
  //     if (index > -1) {
  //       this.selectedStatus.splice(index, 1);
  //     }
  //   } else {
  //     this.selectedStatus.push(id);
  //   }

  //   this.getQueueHistory();
  // }

  // clearStatusFilter() {
  //   this.selectedStatus = [];
  //   const usersCheckbox = document.getElementsByName("status-checkbox") as any;
  //   const allUsersCheckbox = document.getElementsByName(
  //     "all-status-checkbox"
  //   ) as any;
  //   allUsersCheckbox[0].checked = false;
  //   usersCheckbox.forEach((element) => {
  //     element.checked = false;
  //   });
  //   this.getQueueHistory();
  // }

  // public searchHistory() {
  //   this.searchSubject.next(this.searchString);
  // }

  downloadList() {
    this.isDownloading = true;
    this.queueHistoryService.getQueueHistoryDownload(this.branchId).subscribe(
      (res) => {
        this.downloadQueueHistory(res.data);
        this.isDownloading = false;
      },
      (error) => {
        this.isDownloading = false;
        console.log("HISTORY LIST DOWNLOAD error", error);
      }
    );
  }

  // pageChange(page) {
  //   this.selectedPage = page.page;
  //   this.getQueueHistory();
  // }

  // entriesChange(a) {
  //   this.itemsPerPage = a;
  //   this.getQueueHistory();
  // }

  viewDetails(queueId: string) {
    const initialState = {
      title: "View Queue Details",
      queueId: queueId,
      branchId: this.branchId,
      serviceLists: this.serviceLists,
      accountList: this.accountLists,
    };
    this.modalRef = this.modalService.show(QueueDetailsModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  // selectDateFilter(selected) {
  //   this.selectedDateFilter = selected;
  //   switch (selected) {
  //     case "today":
  //       this.selectedDateFilterLabel = "Today";
  //       this.getQueueHistory();
  //       break;
  //     case "yesterday":
  //       this.selectedDateFilterLabel = "Yesterday";
  //       this.getQueueHistory();
  //       break;
  //     case "this-week":
  //       this.selectedDateFilterLabel = "This Week";
  //       this.getQueueHistory();
  //       break;
  //     case "this-month":
  //       this.selectedDateFilterLabel = "This Month";
  //       this.getQueueHistory();
  //       break;
  //     case "this-year":
  //       this.selectedDateFilterLabel = "This Year";
  //       this.getQueueHistory();
  //       break;
  //     case "custom":
  //       this.selectCustomDateFilter();
  //       break;
  //   }
  // }

  // selectCustomDateFilter() {
  //   const initialState = {
  //     title: "Edit Profile",
  //     hasMinDate: false,
  //   };
  //   this.modalRef = this.modalService.show(DatePickerModalComponent, {
  //     initialState: initialState,
  //     class: "modal-dialog-centered modal-md2",
  //     ignoreBackdropClick: true,
  //     keyboard: false,
  //   });

  //   this.modalRef.content.selectedDateRange.subscribe(
  //     (data) => {
  //       if (data) {
  //         this.customDate1 = (data[0] as Date).toLocaleDateString("en-US");
  //         this.customDate2 = (data[1] as Date).toLocaleDateString("en-US");

  //         this.selectedDateFilterLabel =
  //           this.customDate1 + " - " + this.customDate2;
  //         this.getQueueHistory();
  //       }
  //     },
  //     (err) => {
  //       return false;
  //     }
  //   );
  // }

  getServiceName(id) {
    let name = "";
    if (id && this.serviceLists) {
      const s = this.serviceLists.find((x) => x.id === id);
      if (s) {
        name = s.label;
      }
    }
    return name;
  }

  getAccountName(accountId: string) {
    return getAccountName(this.accountLists, accountId);
  }

  // getAccountName(id) {
  //   let name = "";
  //   if (id && this.accountLists) {
  //     const a = this.accountLists.find((x) => x._id === id);
  //     if (a) {
  //       name = a.firstName + " " + a.lastName;
  //     }
  //   }

  //   return this.titleCase.transform(name);
  // }

  downloadQueueHistory(list) {
    const arrayData = list.map((element) => {
      return {
        queueNo: element.queueNo,
        servedBy: this.getAccountName(element.servedBy),
        serviceName: this.getServiceName(element.serviceId),
        dateAndTimeQueued: moment(new Date(element.bookedAt)).format(
          "MM/DD/yyyy - hh:mm a"
        ),
        formattedWaitingTime: element.waitingTime
          ? this.getTimeAgo(element.waitingTime)
          : this.getTimeAgo2(element.bookedAt),
        formattedServingTime: element.servingTime
          ? this.getTimeAgo(element.servingTime)
          : "",
        formattedStatus: this.titleCase.transform(element.status),
      };
    });

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: [
        "Queue Number",
        "Served By",
        "Service",
        "Date and Time Queued",
        "Waiting Time",
        "Serving Time",
        "Status",
      ],
    };
    // tslint:disable-next-line: no-unused-expression
    new AngularCsv(arrayData, "Queue History", options);
  }

  getTimeAgo(data) {
    if (data) {
      const timePipe = new TimePipe();
      return timePipe.transform(data);

      // const y = new Date(data).getFullYear();
      // const m = new Date(data).getMonth();
      // const d = new Date(data).getDate();
      // const h = new Date(data).getHours();
      // const mm = new Date(data).getMinutes();
      // const s = new Date(data).getSeconds();
      // const time = moment([y, m, d, h, mm, s]).fromNow();

      // return time === 'a day' ? '1 day' : time;
    }
  }

  getDuration(milliseconds: number) {
    return getDuration(milliseconds);
  }

  getTimeAgo2(data) {
    if (data) {
      const y = new Date(data).getFullYear();
      const m = new Date(data).getMonth();
      const d = new Date(data).getDate();
      const h = new Date(data).getHours();
      const mm = new Date(data).getMinutes();
      const s = new Date(data).getSeconds();
      const time = moment([y, m, d, h, mm, s]).fromNow(true);

      return time === "a day" ? "1 day" : time;
    }
  }

  public staffDropdownVisible: boolean = false;
  dropdownsVisible: { [key: string]: boolean } = {}; // Object to track dropdown visibility
  @ViewChild("staffDropdownRef", { static: false })
  staffDropdown: ElementRef<HTMLDivElement>;
  @ViewChild("serviceDropdownRef", { static: false })
  serviceDropdown: ElementRef<HTMLDivElement>;
  @ViewChild("statusDropdownRef", { static: false })
  statusDropdown: ElementRef<HTMLDivElement>;

  ngAfterViewInit(): void {
    if (this.staffDropdown) {
      this.staffDropdown.nativeElement;
    }
    if (this.serviceDropdown) {
      this.serviceDropdown.nativeElement;
    }
    if (this.statusDropdown) {
      this.statusDropdown.nativeElement;
    }
  }

  toggleDropdown(dropdownId: string): void {
    // Close all other dropdowns before opening the current dropdown
    Object.keys(this.dropdownsVisible).forEach((id) => {
      if (id !== dropdownId) {
        this.closeDropdown(id);
      }
    });

    if (this.isDropdownVisible(dropdownId)) {
      this.closeDropdown(dropdownId);
    } else {
      this.openDropdown(dropdownId);
    }
    this.isFocused = false;
    this.isButtonClicked = false;
    this.isSelectClicked = false;
  }

  isDropdownVisible(dropdownId: string): boolean {
    return this.dropdownsVisible[dropdownId] ?? false; // Return the visibility state for the given dropdown ID
  }

  openDropdown(dropdownId: string): void {
    // Close all other dropdowns before opening the current dropdown
    Object.keys(this.dropdownsVisible).forEach((id) => {
      if (id !== dropdownId) {
        this.closeDropdown(id);
      }
    });

    this.dropdownsVisible[dropdownId] = true;
  }

  closeDropdown(dropdownId: string): void {
    this.dropdownsVisible[dropdownId] = false; // Set the visibility state for the given dropdown ID to false
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent): void {
    const clickedElement = event.target as HTMLElement;
    const dropdownDiv =
      this.elementRef.nativeElement.querySelector(".date-dropdown");
    if (!dropdownDiv?.contains(clickedElement)) {
      if (this.isFocused === true) {
        this.isFocused = false;
      }
    }
    for (const dropdownId in this.dropdownsVisible) {
      if (this.dropdownsVisible.hasOwnProperty(dropdownId)) {
        const dropdownRef = this.elementRef.nativeElement.querySelector(
          `#${dropdownId}`
        );
        const dropdownMenuRef = this.elementRef.nativeElement.querySelector(
          `#${dropdownId}-menu`
        );
        if (dropdownRef && dropdownRef.contains(clickedElement)) {
          return;
        }
        if (dropdownMenuRef && dropdownMenuRef.contains(clickedElement)) {
          return;
        }
      }
    }
    this.dropdownsVisible = {};
  }

  ngOnDestroy(): void {
    this.queueHistorySubjectAPI$.next();
    this.queueHistorySubjectAPI$.complete();
    document.removeEventListener("click", this.onDocumentClick.bind(this));
  }
}
