import { Component, Input, OnInit, ElementRef, ViewChild  } from '@angular/core';

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss']
})
export class AccordionItemComponent implements OnInit {
  @Input() label: string;
  @Input() content: string;
  @Input() isLastItem: boolean = false;
  public isCollapsed: boolean = true;

  @ViewChild('scrollableContainer') scrollableContainer: ElementRef;

  ngOnInit(): void {
    console.log(this.label)
  }



  toggleAccordion(): void {
    this.isCollapsed = !this.isCollapsed;
    const container = this.scrollableContainer.nativeElement;

    if(!this.isCollapsed) {
      container.scrollIntoView({behavior: 'smooth',});
    }
  }
}