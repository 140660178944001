import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-support-help-center-sub-branch",
  templateUrl: "./support-help-center-sub-branch.component.html",
  styleUrls: ["./support-help-center-sub-branch.component.scss"],
})
export class SupportHelpCenterSubBranchComponent implements OnInit {


  constructor() {}

  ngOnInit(): void {}
}
