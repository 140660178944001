import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-edit-billing-address',
  templateUrl: './edit-billing-address.component.html',
  styleUrls: ['./edit-billing-address.component.scss']
})
export class EditBillingAddressComponent implements OnInit {
  title: any;
  subTitle: any;
  billingAddressForm = this.fb.group({
    addressLine1: ["", [Validators.required]],
    addressLine2: ["", [Validators.required]],
    country: [""],
    zipCode: ["", [Validators.required]],
  });
  countries: any = [
    {
      _id: 1, name: 'Philippines'
    }
  ]

  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.f['country'].patchValue(this.countries[0]._id);
  }

  get f() {
    return this.billingAddressForm.controls;
  }

  changeCountry() {

  }

  updateBillingAddress() {
    this.toastr.success("Billing address has been updated", "Success!");
    this.modalRef.hide();
    console.log(this.billingAddressForm.value);
  }

}
