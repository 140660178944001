import { Component, HostListener, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AppointmentService } from '../../services/appointment.service';
import { DataService } from 'src/app/core/services/data.service';
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { DatePickerModalComponent } from 'src/app/shared/components/date-picker-modal/date-picker-modal.component';
import { GenericService } from "src/app/core/services/generic.service";
import { ISelectedTime, ITimeSlots, ITimeSlotData, ISettingLeadTime } from '../../interface/appointment.interface';
import { HttpService } from "src/app/core/http/http.service";
import { AppointmentSettingsService } from '../../services/appointment-settings.service'; 
import { SETTING_LEAD_TIME, APPOINTMENT_SETTINGS } from '../../enum/smart_form'; 

@Component({
  selector: 'app-edit-appointment-modal',
  templateUrl: './edit-appointment-modal.component.html',
  styleUrls: ['./edit-appointment-modal.component.scss']
})
export class EditAppointmentModalComponent implements OnInit, OnDestroy  {
  @Input() title: any;
  @Input() subTitle: string;
  @Input() data: any;
  @Input() isBookingAgain: boolean = false;

  timeslots: Array<ITimeSlots> = [];
  timeSlotData: Array<ITimeSlotData> = [];
  modifiedCategoryLists: Array<any>;
  selectedServiceCategoryId: string;
  selectedDateLabelValue: string;
  categoryLists: Array<any>;
  selectedTimeSlotData: ITimeSlotData;
  selectedTimeSlotLabel: string;
  operatingDays: Array<any> = [];
  validFields: Array<any> = [];
  tagLists: Array<any> = [];
  settingFeaturesEnable: Array<string> = [];
  enabledFieldLists: Array<any>;
  requiredValidators: any;
  mobileValidators: any;
  inputForm: FormGroup;
  services: any;
  enableAppointmentService:boolean =  true;
  serviceLists: Array<any>;
  selectedBranchId: string;
  selectedServiceId: string;
  isFocused: boolean = false;
  serviceData: any;
  isSelectClicked: boolean;
  branchId: string;
  isButtonClicked: boolean;
  selectedDateValue: string = "";
  selectedDateLabel: string = "";
  isActiveTimeSlot: boolean = false;
  isActiveService: boolean = false;
  isSubmitting: boolean = false;
  printTimeSlotData: boolean = false;
  isSelected: boolean = false;
  isSelectClickedTimeslot: boolean = false;
  isFocusedTimeslot: boolean = false;
  isDirty = false;
  selectedTimeSlotValue: number;
  gmtMiliseconds: number;
  printTimeSlotLeft:number;
  isConfirmDisabled: boolean;
  noNumberValidator: any;
  timeslotValue: string;
  queue: any;
  selectedTime: ISelectedTime = {
    value: -1,
    label: ''
  };
  dayValue: number;
  appointmentScheduleRange: number;
  type: string;
  modifiedServiceLists: Array<any>;
  submitting:boolean =  false;
  selectedDateAndTime: boolean =  false;
  subscriptions = new Subscription();
  universalTags:any = []
  canProceed: boolean = false;
  @Output() successEvent = new EventEmitter();
  settingsLeadTime: ISettingLeadTime;
  priorityIndex: number = 0 | -1;

  constructor(
    public modalRef: BsModalRef,
    public modalRefDatePicker: BsModalRef,
    public httpService: HttpService,
    private modalService: BsModalService,
    private dataService: DataService,
    private genericService: GenericService,
    private appointmentService: AppointmentService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private elementRef: ElementRef,
    private appointmentSettingService: AppointmentSettingsService
  ) {
      this.inputForm = this.formBuilder.group({
        timeslot: ["", Validators.required]
    })

    }

    timeMili(): number {
      const hours = 8;
      const millisecondsInHour = 60 * 60 * 1000;
      return hours * millisecondsInHour;
    }

    timeEightHoursMili(): number {
      const hours = 8;
      const millisecondsInHour = 60 * 60 * 1000;
      return hours * millisecondsInHour;
  }

   modifyServiceLists() {
      this.modifiedServiceLists = this.services;
      this.modifiedServiceLists = this.serviceLists.map((element) => {
        return {
          label: element.displayName,
          value: element._id,
          disabled: !element.enableAppointment,
          suspended: element.suspended
        };
      });
    } 

    
  async initServiceData() {
    if (this.selectedServiceId) {
      await this.selectService(this.selectedServiceId);
    }
  }

  selectedTimeslotFilter() {
    this.isSelectClickedTimeslot = !this.isSelectClickedTimeslot;
    this.isFocusedTimeslot = !this.isFocusedTimeslot;
  }

  getServicecategoryList() {
      const branchId = this.dataService.currentBranch$.branchId
      this.subscriptions.add(
          this.appointmentService.getServiceCategories(branchId).subscribe(
            async (res) => {
              if(res) {
                this.categoryLists = res.data;
              }
            },
            (error) => {
              console.log(error)
            }
          )
   )
 }      

 async serviceList() {
    const branchId = this.dataService.currentBranch$.branchId;
    this.subscriptions.add(
    this.appointmentService.getServiceList(branchId).subscribe(
    async  (res) => {
        if(res) {
          let modifiedServices = [];
          let selectedDay;
          let dateFormattedObject;
          let dayValue;
          if(new Date(this.data.formattedDate) >= new Date(new Date().setHours(0,0,0,0))){
            selectedDay = this.datePipe.transform(this.data.formattedDate , 'EEEE');  
            dateFormattedObject = new Date(this.data.formattedDate);
            dayValue = this.getAppointmentDay(selectedDay)
          } else {
            dateFormattedObject = new Date(new Date().setHours(0,0,0,0));
            dayValue = dateFormattedObject.getDay();
          }
          res.data.forEach(service => modifiedServices.push({label: service.displayName, value: service._id }));
          this.services = modifiedServices;
          this.serviceLists = res.data;
          this.selectedServiceId = this.data.serviceId;
         if(this.serviceLists) {
            await this.modifyServiceLists();
            await this.getTimeSlot(dayValue)
            /* this.getUniversalTag() */
            this.getAppointmentSetting();     
          }
          await this.setDateLabel(dateFormattedObject); 
          await this.getServicecategoryList();
          await this.appointmentDetails(this.data.serviceId);
          await this.getTimeSlotLabel();
          for(let service of res.data) {
             if(!service.enableAppointment) {
                if(service._id === this.data.serviceId) {
                  this.enableAppointmentService = false;
                }
             } 
          }
        }
      },
      (error) => {
        console.log(error)
      }
    )
    )
  }

  getAppointmentSetting() {
    const branchId = this.dataService.currentBranch$.branchId;
    this.subscriptions.add(
      this.appointmentSettingService.getSettings(branchId).subscribe(
        (res) => {
          if(res) {
              res.data;
              this.settingsLeadTime = res.data.leadTime;
              this.appointmentScheduleRange = this.getAppointmentScheduleRange(res.data.scheduleRange)
              this.settingFeaturesEnable = res.data.featuresEnabled;
          }
        },
        (error) => {
          console.log(error)
        }
      )
   );
  }


  async getServiceData() {
    this.branchId = this.dataService.currentBranch$.branchId;
    if (
      this.selectedServiceId &&
      this.selectedServiceId !== "Please Select Service"
    ) {
      this.httpService
        .get$(`services/${this.branchId}/${this.selectedServiceId}`)
        .toPromise()
        .then(
          (data) => {
            this.serviceData = data.data;
            this.enabledFieldLists = this.serviceData?.queueForm
              ? this.serviceData.queueForm.fields
                .filter((element) => element.enabled)
                .map((element, i) => {
                  const validatorArr = [];
                  element.required
                    ? validatorArr.push(this.requiredValidators)
                    : null;
                  element.fieldName == "name"
                    ? validatorArr.push(this.noNumberValidator)
                    : null; // added validator for name field 
                  // element.required && //remove for optional mobileNo
                  ["mobileNo", "mobile-number"].includes(element.fieldType)
                    ? validatorArr.push(this.mobileValidators)
                    : null;
                  return {
                    ...element,
                    label: element.label,
                    value: this.data.serviceId !== this.selectedServiceId ?  "" : this.data.visitorDetails[i]?.value ,
                    validators: validatorArr,
                  };
                })
              : null;
            this.validFields = this.enabledFieldLists
              ? this.enabledFieldLists.map((element, i) => {
                return {
                  ...element,
                  i: i,
                  valid: !element.required
                    ? true
                    : element.value
                      ? true
                      : false,
                  value: element?.value ? element?.value : "",
                  fieldType: element?.fieldType,
                };
              })
              : null;
            this.isConfirmDisabled = this.validFields
              ? this.validFields.filter((element) => !element.valid).length !==
              0
              : false;
            if (
              this.serviceData &&
              this.serviceData.enabledFeatures.includes("auto-tag")
            ) {
              this.serviceData.tags.forEach((t) => {
                if (!this.tagLists.includes(t)) {
                  this.tagLists.push(t);
                }
              });
            }
          },
          (error) => {
            console.log("error", error);
          }
        );
      this.isSelected = true;
    }
  }

  async patchValue() {
    const { fields, ...queue } = this.queue;
    this.queue = {
      ...queue,
      fields: fields.map((element) =>
        ["mobileNo", "mobile-number"].includes(element.fieldName)
          ? {
            ...element,
            value: this.genericService.patchMobile(element.value),
          }
          : element
      ),
    };
    this.tagLists = this.queue.tags;
  }

  changeInput(index, $event) {
    const isToggleSwitch = typeof $event === 'boolean';

    if ($event.valid) {
      this.validFields = this.validFields.map((element, i) =>
        i === index
          ? {
            ...element,
            valid: true,
            value: $event.value,
          }
          : {
            ...element,
            valid: element.valid,
            value: element.value,
          }
      );
    } else {
      this.validFields = this.validFields.map((element, i) =>
        i === index
          ? {
            ...element,
            valid: false,
            value: $event.value,
          }
          : {
            ...element,
            valid: element.valid,
            value: element.value,
          }
      );
    }

    if (isToggleSwitch) {
      this.validFields = this.validFields.map((element, i) =>
        i === index
          ? {
            ...element,
            valid: true,
            value: $event,
          }
          : {
            ...element,
            valid: element.valid,
            value: element.value,
          }
      );
    }

    const valid = this.validFields.filter((element) => element.valid);
    this.isConfirmDisabled = valid.length !== this.enabledFieldLists.length;
    this.isDirty = true;
  }
  

  public appointmentDetails(serviceId) {
    const branchId = this.dataService.currentBranch$.branchId;
    this.subscriptions.add(
      this.appointmentService.getAppointmentCategoryDetails(branchId,serviceId).subscribe(
        (res) => {
          if(res) {
            this.operatingDays = res.data.operatingDays;
            this.getCurrentDate();
          }
        },
        (error) => {
          console.log(error)
        }
      )
    )
  }

  getAppointmentScheduleRange(appointmentScheduleRange) {
    const currentDate = new Date();
    const calculatedDate = new Date()
    let appointmeRange: number;
    if(appointmentScheduleRange.type === 'weeks' ) {
      appointmeRange = calculatedDate.setDate(currentDate.getDate() + (appointmentScheduleRange.value * 7)); 
    }
  
    if(appointmentScheduleRange.type === 'months') {
      appointmeRange = calculatedDate.setMonth(currentDate.getMonth() + appointmentScheduleRange.value); 
    }
  
    return appointmeRange;
  }

  selectDateDropdown() {
    const initialState = {
      title: 'Select Appointment Schedule',
      hasMaxDate: true,
      maxDate: new Date(this.appointmentScheduleRange),
      hasMinDate: true,
      datePickerType: 'single',
    }

    this.modalRefDatePicker = this.modalService.show(DatePickerModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false
    })

    this.modalRefDatePicker.content.selectedDateRange.subscribe(
      (data) => {
        if (data) {
          if (data == 'cancel') {
            //
          } else {
            this.setDateLabel(data);
            this.selectedDateLabelValue = this.selectedDateLabel;
            this.inputForm.controls['timeslot'].setValue(""); 
          }
        }
      },
      (err) => {
        return false
      }
    )
  }

      /*
     get the appointment time slot details
     inprogress funtion for get remaining timeslot 
     */
     public appointmentTimeSlotDetails(gmtMiliseconds) {
      const body = {
        branchId: this.dataService.accountData$.data.branchId,
        categoryId: '8a47ecc2-5710-4045-a5a0-1b27c08797d0',
        time: gmtMiliseconds,
      }
      this.subscriptions.add(
        this.appointmentService.getAppointmentTimeSlotData(body).subscribe(
          (res) => {
            if(res) {
              this.timeSlotData = res.data.timeslot
            }
          },
          (error) => {
            console.log(error)
          }
        )
      )
    }

  async setDateLabel(date: Date) {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const formattedDate = date.toLocaleDateString('en-US', options);

    const dateObject = new Date(date);

    this.selectedDateLabel = this.datePipe.transform(formattedDate, 'MM/dd/yyyy');

    const selectedDay = this.datePipe.transform(this.selectedDateLabel , 'EEEE');

    const dayValue = this.getAppointmentDay(selectedDay)

    const gmtTime = this.convertToGMT(dateObject);

    const gmtTimeMilis = gmtTime.getTime() + this.timeMili() ;

    this.gmtMiliseconds = gmtTimeMilis;
    this.dayValue = dayValue;
    await this.getTimeSlot(dayValue)
  }

  async selectService($event) {
    this.serviceData = null;
    this.selectedServiceId = $event;
    this.modifiedCategoryLists = [];
    this.modifiedCategoryLists = this.checkCategory(); 
    if (!this.modifiedCategoryLists || this.modifiedCategoryLists.length <= 0) {
      this.isActiveService = false;
    } else {
      this.isConfirmDisabled = true;
      this.isSelected = false;
    }
    this.isDirty = true; 

    for(let service of this.services ) {
      if(this.selectedServiceId === service.value) {
         this.isActiveService = true;
      }
   }
    this.isActiveTimeSlot = false;
    this.appointmentDetails(this.selectedServiceId)
  }

  public async getTimeSlot(dayValue) {
    let appointmentTime: any = [];
    if(this.operatingDays) { 
      let timeSlotsData = this.operatingDays.filter(data => data.day === dayValue);
        for(let timeSlot of timeSlotsData) {
              timeSlot.timeslot.forEach( data => {
              appointmentTime.push(this.getTimeslotData(data));   
          })
        }
        this.timeslots = appointmentTime;
        await this.getTimeSlotLabel();
        
        if(this.timeslots.length === 0 || !this.timeslots) {
          this.inputForm.controls['timeslot'].setValue(""); 
          this.selectedTimeSlotValue = null;
          this.selectedTimeSlotLabel = null;
        } else {
          const currentDate = new Date();
    
          if(new Date(this.data.timeslot) >= currentDate){
            let timeslot = this.timeslots.find((e)=>{
              return e.label === this.data.formattedTimeslot
            }); 
            this.selectedTimeSlotValue = timeslot.time;
            this.selectedTimeSlotLabel = timeslot.label;
          } else {
            const timeslotData = this.timeslots.find((e)=>{
              return !e.disabled
            }); 
            this.selectedTimeSlotValue = timeslotData.time;
            this.selectedTimeSlotLabel = timeslotData.label;
          }
        } 
     }
    //  this.inputForm.controls['timeslot'].setValue(this.selectedTimeSlotValue); 
  }

  
  getTimeslotData(value) {
    const date = new Date(value.time + this.timeEightHoursMili()); 
    let utcHours = date.getUTCHours()
    let utcMinutes = date.getUTCMinutes().toString().padStart(2, "0");
    let twelveClockHour:string;
    let appointmentTimeslot: string;
    let leadTimeValue: number = 0;
    const currentGMTTime = new Date();
    // Convert GMT time to UTC
    const currentUTCTime = new Date(currentGMTTime.toUTCString());
    // Get the milliseconds since the Unix epoch for the UTC time
    const millisecondsSinceEpoch = (currentUTCTime.getTime() + this.timeMili()) - this.gmtMiliseconds;

    if(this.settingsLeadTime.type === SETTING_LEAD_TIME.MINUTES) {
      leadTimeValue = this.settingsLeadTime.value * 60 * 1000
    } 

    if(this.settingsLeadTime.type === SETTING_LEAD_TIME.HOURS) {
      leadTimeValue = this.settingsLeadTime.value * ( 60 * 60 * 1000);
    }

    if((millisecondsSinceEpoch + leadTimeValue) > (value.time + this.timeEightHoursMili())) {
      value.disabled = true;
    }else{
      value.disabled = false;
    }
    
    utcHours >= 12 ? twelveClockHour = "PM" : twelveClockHour = "AM"
    
    if(utcHours > 12) {
      utcHours = utcHours - 12;
    } 
    
    appointmentTimeslot = utcHours+":"+ utcMinutes+" "+ twelveClockHour;
    return ({label: appointmentTimeslot, value: appointmentTimeslot, slots: value.slots, time: value.time, disabled: value.disabled});  
  }

  getAppointmentDay(day: string):number {
    let dayNumber: number;
    switch (day) {
      case 'Sunday': dayNumber = 0; 
          break; 
      case 'Monday': dayNumber = 1; 
          break;
      case 'Tuesday': dayNumber = 2; 
          break; 
      case 'Wednesday': dayNumber = 3; 
          break;
      case 'Thursday': dayNumber = 4; 
          break;
      case 'Friday': dayNumber = 5; 
          break;
      case 'Saturday': dayNumber = 6; 
          break;
      default: 
          break;          
    }

    return dayNumber;
  }

  getCurrentDate(): void {
      const selectedDay = this.datePipe.transform(this.selectedDateLabel , 'EEEE');
      const dayValue = this.getAppointmentDay(selectedDay)
      this.getTimeSlot(dayValue)
      this.getServiceData()
  }  

  convertToGMT(localTime: Date) {
    localTime.setHours(0,0,0,0);
    const utcMilliseconds = Date.UTC(
      localTime.getUTCFullYear(),
      localTime.getUTCMonth(),
      localTime.getUTCDate(),
      localTime.getUTCHours(),
      localTime.getUTCMinutes(),
      localTime.getUTCSeconds(),
      localTime.getUTCMilliseconds()
    );
    return new Date(utcMilliseconds);
  } 

  dropdownsVisible: { [key: string]: boolean } = {};
  @ViewChild("timeslotDropdownRef", { static: false })
  timeslotDropdownRef: ElementRef<HTMLDivElement>;

  async selectedTimeSlot($data) {
    this.isFocusedTimeslot = true;
    this.selectedTimeSlotLabel = $data.label
    this.selectedTimeSlotValue = $data.time;
    this.inputForm.controls['timeslot'].setValue(this.selectedTimeSlotValue); 
    this.selectedTimeSlotData = this.getTimeSlotData(); 
    await this.getServiceData();
    this.isFocusedTimeslot = false;
  }

  isDropdownVisible(dropdownId: string): boolean {
    return this.dropdownsVisible[dropdownId] ?? false; // Return the visibility state for the given dropdown ID
  }

  openDropdown(dropdownId: string): void {
    // Close all other dropdowns before opening the current dropdown
    Object.keys(this.dropdownsVisible).forEach((id) => {
      if (id !== dropdownId) {
        this.closeDropdown(id);
      }
    });

    this.dropdownsVisible[dropdownId] = true;
  }

  closeDropdown(dropdownId: string): void {
    this.dropdownsVisible[dropdownId] = false; // Set the visibility state for the given dropdown ID to false
    this.isFocusedTimeslot = false;
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent): void {
    const clickedElement = event.target as HTMLElement;
      const timeslotDropDownDiv =
      this.elementRef.nativeElement.querySelector(".timeslot-dropdown");  

    if (!timeslotDropDownDiv?.contains(clickedElement)) {
      if (this.isFocusedTimeslot === true) {
        this.isFocusedTimeslot = false;
      }
    }
    for (const dropdownId in this.dropdownsVisible) {
      if (this.dropdownsVisible.hasOwnProperty(dropdownId)) {
        const dropdownRef = this.elementRef.nativeElement.querySelector(
          `#${dropdownId}`
        );
        const dropdownMenuRef = this.elementRef.nativeElement.querySelector(
          `#${dropdownId}-menu`
        );
        if (dropdownRef && dropdownRef.contains(clickedElement)) {
          return;
        }
        if (dropdownMenuRef && dropdownMenuRef.contains(clickedElement)) {
          return;
        }
      }
    }
    this.dropdownsVisible = {};
  }

  
   get appointmentForm() {
    return this.inputForm.controls;
  } 

  appointmentPatchValue() {
    const currentDate = new Date(new Date().setHours(0,0,0,0));
    
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    if(new Date(this.data.formattedDate) >= currentDate){
      this.selectedDateLabel = this.data.formattedDate;
    } else {
      this.selectedDateLabel = currentDate.toLocaleDateString('en-US', options);
    }
  }

  getTimeSlotLabel() {
    for(let timeslot of this.timeslots) {
         if(timeslot.label === this.data.formattedTimeslot) {
        this.timeslotValue = timeslot.value;  
        this.inputForm.controls.timeslot.patchValue(timeslot.value);
       }  
    } 
  }

  getTimeSlotData(): ITimeSlotData {
    for(let timeslot of this.timeSlotData ) {
      if(timeslot.time == this.selectedTimeSlotValue) {
         return timeslot;
      }
    }
  }
  
  select($event) {
    this.isSelectClicked = !this.isSelectClicked;
    this.isFocused = !this.isFocused;
  }

  removeTag($event) {
    const tagIndex = this.tagLists.indexOf(!$event.value ? $event : $event.value);
    this.tagLists.splice(tagIndex, 1);
  }

  addTag($event) {
    this.tagLists.push($event);
  }

  initValidators() {
    this.requiredValidators = {
      type: "required",
      value: Validators.required,
      errormsg: "This field is required",
    };
    this.mobileValidators = {
      // type: "minlength",
      // value: Validators.minLength(12),
      // errormsg: "Minimum length is 12",
      type: "pattern",
      value: Validators.pattern(/^9[0-9]{2}[-]?[0-9]{3}[-]?[0-9]{4}$/),
      errormsg: "Invalid mobile number format",
      //enhance regex for pattern
    };
    this.noNumberValidator = {
      type: "pattern",
      value: Validators.pattern(/^[A-Za-z- ñÑ.]*$/),
      errormsg: "Invalid name format",
      //enhance regex for pattern (name)
    }
  }

  checkCategory() {
    this.categoryLists = this.categoryLists.filter(x => !x.subCategoryId);
    const serviceCategories = this.categoryLists.filter(
      (s) =>
        s.assignedServices.includes(this.selectedServiceId) &&
        (!s.parentCategoryId || s.parentCategoryId)
    );

    let categoryChoices = [];
    if (serviceCategories) {
      serviceCategories.forEach((c1) => {
        let categoryLabel: any;
        if (c1.parentCategoryId) {
          const categoryLevel1 = this.categoryLists.find(
            (c) => c._id === c1.parentCategoryId
          );
          if (!categoryLevel1) {
            return [];
          }
          if (categoryLevel1.parentCategoryId) {
            const categoryLevel2 = this.categoryLists.find(
              (c) => c._id === categoryLevel1.parentCategoryId
            );
            if (!categoryLevel2) {
              return [];
            }
            categoryLabel = {
              label:
                categoryLevel2.displayName +
                " > " +
                categoryLevel1.displayName +
                " > " +
                c1.displayName,
              value: c1._id,
            };
          } else {
            categoryLabel = {
              label: (categoryLabel =
                categoryLevel1.displayName + " > " + c1.displayName),
              value: c1._id,
            };
          }
        } else {
          categoryLabel = {
            label: (categoryLabel = c1.displayName),
            value: c1._id,
          };
        }

        categoryChoices.unshift(categoryLabel);
      });

      return categoryChoices;
    }
  }
  

  saveChanges($event) {
    this.isSubmitting = true;
    this.validFields.map(data => delete data._id);
    const body = {
      branchId: this.dataService.currentBranch$.branchId,
      serviceId: this.selectedServiceId,
      appointmentId: this.data._id,
      contactNo:  this.inputForm.value.contactNo
          ? this.inputForm.value.contactNo.replaceAll("-", "")
          : "",
      customerRemarks: '',
      tags: this.tagLists,
      selectedTimeslot: this.selectedTimeSlotValue,
      timeslot: this.selectedTimeSlotValue + this.gmtMiliseconds,
      visitorDetails: this.validFields
    }

    this.subscriptions.add(
      this.appointmentService.editAppointment(body).subscribe(
        (res) => {
          if(res) {
            this.isSubmitting = false;
            this.successEvent.emit("edit-modal")
            this.modalRef.hide();
            this.toastr.success(`${this.data?.referenceNo} has been updated`, "Success!",{
              toastClass: "ngx-toastr toast-success-custom",
            });
          }
        },
        (error) => {
          console.log(error.error.message);
          let resultError = 
          !this.settingFeaturesEnable.includes(APPOINTMENT_SETTINGS.ENABLE_APPOINTMENT) ? "Appointment features are disabled. Please contact Sales Support to enable this feature." 
          : "Error encountered upon submitting ";
          this.toastr.error(resultError, "Error!");
          this.isSubmitting = false;  
        }
      )
    )
  }

  bookAgain(){
    this.validFields.map(data => delete data._id);
    const body = {
      branchId: this.dataService.currentBranch$.branchId,
      serviceId: this.selectedServiceId,
      timeslot: this.selectedTimeSlotValue + this.gmtMiliseconds,
      visitorDetails: this.validFields
    }

    this.appointmentService.addAppointment(body).subscribe(
      (res) => {
        this.isSubmitting = false;
        this.modalRef.hide();
        this.toastr.success("Appointment has been booked", "Success!");
      },
      (error) => {
        let resultError = 
        !this.settingFeaturesEnable.includes(APPOINTMENT_SETTINGS.ENABLE_APPOINTMENT) ? "Appointment features are disabled. Please contact Sales Support to enable this feature." 
        : "Error encountered upon submitting ";
        this.toastr.error(resultError, "Error!");
        this.isSubmitting = false;
      })
  }



  closeModal() {
    this.modalRef.hide();
  }

  
  getUniversalTag() {
    this.httpService
        .get$(`queues/universal-tag-set/${this.branchId}`)
        .toPromise()
        .then(
          (data) => {
            const dataFound =  data.result.filter(tag => !tag.deleted);
            this.universalTags = dataFound;
          },
          (error) => {
            console.log("error", error);
          }
        );
  }


  ngOnInit() {
    this.serviceList();
    this.appointmentPatchValue();
    this.getTimeSlot(new Date(this.selectedDateLabel).getDay());
    this.selectedDateLabelValue = this.selectedDateLabel;
    this.tagLists = this.data.tags
    // this.inputForm.controls['timeslot'].setValue()
    if (this.queue) {
      this.getServiceData();
      this.patchValue();
      this.modifiedCategoryLists = [];
  /*     this.getUniversalTag() */
      this.modifiedCategoryLists = this.checkCategory();
      this.selectedServiceCategoryId = this.queue?.serviceCategoryId;
      
    } else {
      this.initServiceData();
    }
    this.initValidators();
    if (this.type === 'Edit') {
      this.priorityIndex = this.queue?.fields.findIndex(x => x.fieldName == 'priority');
      if (this.priorityIndex > -1) {
        const prio = this.queue.fields[this.priorityIndex];
        if (prio.value == 'true') {
          prio.value = true;
        } else {
          prio.value = false;
        }
      }
      this.canProceed = true;
    }
  } 

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
   
}



