import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpService } from 'src/app/core/http/http.service';
import { DataService } from 'src/app/core/services/data.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { QueueTransferWindowModalComponent } from '../queue-transfer-window-modal/queue-transfer-window-modal.component';

@Component({
  selector: 'app-queue-window-leave-modal',
  templateUrl: './queue-window-leave-modal.component.html',
  styleUrls: ['./queue-window-leave-modal.component.scss']
})
export class QueueWindowLeaveModalComponent extends ModalService implements OnInit {
  buttonLists = [{
    icon: 'ic_log-out',
    title: 'Leave',
    caption: 'Leave now and wait for the next attending staff'
  },
  {
    icon: 'ic_checkmark-circle-2',
    title: 'Complete',
    caption: 'Complete all your current serving queues'
  }];
  windowId: string;
  accountId: string;
  branchId: string;
  windowData: any;
  
  constructor(
    bsModalRef: BsModalRef,
    httpService: HttpService,
    dataService: DataService,
    toastr: ToastrService,
    authService: AuthService,
    private router: Router,
    private modalService: BsModalService
  ) {
    super(bsModalRef, httpService, dataService, toastr, authService)
  }

  choose($event) {
    const fn = 'this.' + $event.toLowerCase() + '()';
    eval(fn);
  }

  leave()  {
    this.bsModalRef.hide();
    this.dataService.publishCloseModalReason$({action: 'confirmLeaveWindow', remarks: ''});
  }
  
  transfer() {
    this.bsModalRef.hide();
    const initialState = {
      title: 'Transfer to Another Window',
      subTitle: `Select a window where you’ll transfer all your current serving queues before you leave ${this.windowData.displayName}`,
      branchId: this.branchId,
      type: 'bulk',
      currentWindow: this.windowId,
    }
    this.bsModalRef = this.modalService.show(QueueTransferWindowModalComponent, {
      initialState: initialState,
      class: 'modal-dialog-centered',
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  complete() {
    this.httpService.patch$(`queues/${this.branchId}/bulk-complete/${this.windowId}`, '').subscribe(
      data => {
        this.bsModalRef.hide();
        this.dataService.publishCloseModalReason$({action: 'confirmLeaveWindow', remarks: ''});
      }, error => {}
    );
  }

  goBackToWindowSelect() {
    this.dataService.currentWindow$ = null;
    this.dataService.assignedWindowLists$ = null;   
    this.router.navigate([`/queue-workspace`]);
  }

  ngOnInit(): void {
    if(this.dataService.assignedWindowLists$?.length > 1 && this.dataService.assignedWindowLists$.filter(x => !x.suspended)?.length > 1){
      this.buttonLists = [{
        icon: 'ic_log-out',
        title: 'Leave',
        caption: 'Leave now and wait for the next attending staff'
      }, {
        icon: 'ic_swap',
        title: 'Transfer',
        caption: 'Move your current serving queues to a different window'
      }, {
        icon: 'ic_checkmark-circle-2',
        title: 'Complete',
        caption: 'Complete all your current serving queues'
      }];
    }
  }

}
