import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() value: number;
  @Input() validators: Array<any>;
  @Input() placeholder: string;
  @Input() min: string | number;
  @Input() max: string | number;
  @Input() buttonText: string;
  @Input() externalData: any;
  @Input() showSaveButton: any = true;
  @Output() savedInput = new EventEmitter();
  inputEditForm: FormGroup;
  errorMsg: string;

  constructor(
    private fb: FormBuilder
  ) { }
  
  initForm() {
    this.inputEditForm = this.fb.group({
      inputName: [Number(this.value)]
    });

  }

  get f() { return this.inputEditForm.controls; }
  get inputNumber() { return Number(this.f['inputName'].value)}

  change($event) {
    if (this.inputNumber > this.max) {
      this.f['inputName'].setValue(this.max);
    }

    if(!this.showSaveButton){
      this.save();
    }
  }

  blur() {
    if (this.inputNumber < this.min) {
      this.f['inputName'].setValue(this.min);
    }
  }

  minus() {
    this.f['inputName'].setValue(this.inputNumber - 1);
    this.blur();
  }

  add() {
    this.f['inputName'].setValue(this.inputNumber + 1);
  }

  save() {
    this.savedInput.emit({
      value: this.f['inputName'].value,
      valid: this.f['inputName'].valid
    });
  }

  ngOnChanges(): void {
    if (this.inputEditForm) {
      this.f['inputName'].setValue(this.value);
    }
  }

  ngOnInit(): void {
    this.initForm();

    setTimeout(() => {
      if(!this.showSaveButton){
        this.save();
      }
    });
  }

}
