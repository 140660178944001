export function getMilliseconds(hour: number, minutes: number = 0) {
    return (hour % 24) * 60 * 60 * 1000 + minutes * 60 * 1000;
}

export function getTimeInMilliseconds(time:{type: string, value: number}){
    switch (time.type){
        case "minutes":
            return getMilliseconds(0, time.value);
        case "hours":
            return getMilliseconds(time.value, 0);
        case "days":
            return time.value * 24 * 60 * 60 * 1000;
        case "weeks":
            return time.value * 7 * 24 * 60 * 60 * 1000;
        default:
            return 0;
    }
}