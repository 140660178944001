import { createFeatureSelector, createSelector } from '@ngrx/store';
import { INotificationState } from '../../interfaces'; 

const notificationState = createFeatureSelector<INotificationState>('notificationState');

export const checkNotificationState = createSelector(
    notificationState,
    (state) => state.isOpen
);

export const checkMarkAllAsReadState = createSelector(
    notificationState,
    (state) => state.isMarkAllAsRead
);

export const checkIfHasNotification = createSelector(
    notificationState,
    (state) => state.hasNotification
);