import { Component, OnInit, ElementRef } from "@angular/core";
import { DataService } from "src/app/core/services/data.service";
import { Subscription } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SettingsService } from "../../services/settings.service";
import { ToastrService } from "ngx-toastr";
import { HttpService } from "src/app/core/http/http.service";
import { BranchManagementService } from "src/app/modules/branch-management/services/branch-management.service";
import { BusinessService } from "src/app/modules/auth/services/business.service";
import { BusinessEnum } from "src/app/modules/auth/enums/business-enum.enum";

@Component({
  selector: "app-business-settings",
  templateUrl: "./business-settings.component.html",
  styleUrls: ["./business-settings.component.scss"],
})
export class BusinessSettingsComponent implements OnInit {
  isLoading = false;
  subscriptions = new Subscription();
  selectedBranch = this.branchService.getSelectedBranch();
  profileData: any;
  businessSettingsForm = this.formBuilder.group({
    businessName: ["", [Validators.required]],
    natureOfBusiness: ["", [Validators.required]],
    typeOfBusiness: ["", [Validators.required]],
    about: [""],
  });
  oldPhoto = true;
  industries: any = [];
  industryTypes: any = [];
  selectTypeOfBusiness: string;
  selectNatureOfBusinessId: string;
  selectedPhoto: any;
  businessEnum = BusinessEnum;
  showIndustryTypes: boolean = true;
  typeNames: any = [
    this.businessEnum.foodAndBeverage, 
    this.businessEnum.healthAndWellness, 
    this.businessEnum.healthcare, 
    this.businessEnum.utilities, 
    this.businessEnum.others
  ];
  newSelectedIndustryType: string;
  showThisCode: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private settingService: SettingsService,
    private branchService: BranchManagementService,
    private httpService: HttpService,
    private toastr: ToastrService,
    private businessService: BusinessService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.getBusinessData(false);
    this.getIndustries();
  }

  getBusinessData(isUpdate) {
    this.settingService
      .getBusinessData(this.selectedBranch.businessId)
      .subscribe((res) => {
        // console.log(res.data)
        if (isUpdate) {
          const newData = {
            about: res.data.about ? res.data.about : "",
            avatarUrl: res.data.avatarUrl,
            branchId: this.selectedBranch.branchId,
            branchName: this.selectedBranch.branchName,
            businessId: this.selectedBranch.businessId,
            industryId: res.data.industryId,
            industryTypeId: res.data.industryTypeId,
            industryName: this.industries.find(
              (x) => x.id === res.data.industryId
            )?.name,
            name: res.data.name,
            ownerId: res.data.ownerId,
            _id: this.selectedBranch._id,
          };
          this.selectedBranch = newData;
          this.branchService.setSelectedBranch(newData);
          this.dataService.publishBusinessData$(newData);
        }
        this.selectedBranch.industryId = res.data.industryId ? res.data.industryId : '';
        this.selectedBranch.industryTypeId = res.data.industryTypeId ? res.data.industryTypeId : '';
        this.businessSettingsForm.markAsPristine();
        this.oldPhoto = true;
        // console.log('selected branch data', this.selectedBranch);
      });
  }

  getIndustries() {
    this.subscriptions.add(
      this.settingService.getIndustries().subscribe((res) => {
        if (res) {
          // let typeData = [];
          // let filteredTypes = res.data.filter(x => 
          //   (x.name !== this.businessEnum.bankingAndFinance && x.name !== this.businessEnum.retail)
          // );
          // typeData = this.manualSort(res.data);
          this.industries = res["total"] > 0 ? res.data.map((element) => {
            return {
              id: element._id,
              name: element.name,
            };
          })
          : [];
          this.patchValue();
        }
      })
    );
  }

  selectNatureOfBusiness(industryId: string) {
    this.industries.findIndex((element) => {
        if(element.id == industryId){
          this.selectedBranch.industryName = element.name; 
          this.selectNatureOfBusinessId = element.id;
        }   
    });
     this.getIndustryTypes(industryId); 
  } 

  getIndustryTypes(industryId: string) {
    this.subscriptions.add(
      this.businessService.getIndustryTypes(industryId).subscribe(
        (data: any) => {
          if (data) {
            const types = data.data.map((element) => {
              return {
                _id: element._id,
                name: element.name
              };
            }).filter(x => !x.suspended);
            this.industryTypes = types;
            const indexOfInstryType = types.findIndex(industryType => this.selectedBranch.industryTypeId == industryType._id);
             if(indexOfInstryType == -1) {
                const indexOfBusinessNature = this.industries.findIndex(element => element.name == this.selectedBranch.industryName);
                if(this.industries[indexOfBusinessNature].name != 'Others') {
                  const industryTypeWithEmptyValue = ["", ...this.industryTypes];
                  this.industryTypes = industryTypeWithEmptyValue; 
                  this.businessSettingsForm.get('typeOfBusiness').setValidators([Validators.required]); 
                  this.businessSettingsForm.controls["typeOfBusiness"].setValue("");
                }else {
                  this.businessSettingsForm.get('typeOfBusiness').clearValidators();
                  this.businessSettingsForm.get('typeOfBusiness').updateValueAndValidity();
                }   
            } else {  
             this.f["typeOfBusiness"].patchValue(this.selectedBranch.industryTypeId ? this.selectedBranch.industryTypeId : "");
            } 
          }
        },
        (error) => {
          console.log("industry types", error);
        }
      )
    );
  }

   clickTypeOfBusiness($event) {
    if($event !== "") {
      this.industryTypes =  this.industryTypes.filter(types => types !== '');
    }
  } 


  patchValue() {
    this.f["businessName"].patchValue(this.selectedBranch.name ? this.selectedBranch.name : "");
    this.checkIndustry(this.selectedBranch.industryId ? this.selectedBranch.industryId : "");
    if (this.f["natureOfBusiness"].value != '') {
      this.getIndustryTypes(this.f["natureOfBusiness"].value);
    }
    this.f["about"].patchValue(this.selectedBranch.about ? this.selectedBranch.about : "");
    this.isLoading = false;
  }

  checkIndustry(id: String) {
    console.log("this is working now in path value");
    const index = this.industries.findIndex((element) => element.id == id );
    if (index > -1) {
      this.f["natureOfBusiness"].patchValue(this.industries[index].id);
    }
  }

  get f() {
    return this.businessSettingsForm.controls;
  }

  revertChanges() {
    this.selectNatureOfBusiness(this.selectedBranch.industryId);
    this.ngOnInit();
  }

  saveChanges() {
    let data = {
      name: this.businessSettingsForm.value.businessName,
      industryId: this.businessSettingsForm.value.natureOfBusiness,
      industryTypeId: this.businessSettingsForm.value.typeOfBusiness,
      about: this.businessSettingsForm.value.about,
    };
    this.settingService
      .editBusinessSettings(this.selectedBranch.businessId, data)
      .subscribe(
        (res) => {
          this.submitPhoto(this.selectedBranch.businessId);
          this.toastr.success("Business Settings has been updated", "Success!");
        },
        (error) => {
          let errMsg = error.error.errors[0].msg;
          this.toastr.error(errMsg, "Error!");
          this.ngOnInit();
        }
      );
  }

  uploadPhoto($event) {
    this.selectedPhoto = $event;
    if (this.selectedPhoto) {
      this.oldPhoto = false;
    }
  }

  submitPhoto(businessId) {
    const param = `business/${businessId}/presign-image-url`;
    this.httpService
      .uploadPhoto(param, this.selectedPhoto)
      .then(async (data) => {
        this.getBusinessData(true);
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  formValueChanged() {
    if (this.businessSettingsForm.dirty) {
      return true;
    }
    if (this.oldPhoto === false) {
      return true;
    }
    return false;
  }

  manualSort(data: any) {
    let arr = [];
    this.typeNames.forEach(element => {
      let index = data.findIndex(x => x.name === element);
      if (index > -1) {
        arr.push(data[index]);
      }
    });
    return arr;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
