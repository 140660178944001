import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-code-login-modal",
  templateUrl: "./code-login-modal.component.html",
  styleUrls: ["./code-login-modal.component.scss"],
})
export class CodeLoginModalComponent implements OnInit {
  code: string;
  title: string;
  counter = 300;
  isExpired = false;
  int: any;

  @Output() generateNewCode = new EventEmitter();


  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {
    this.int = setInterval(() => {
      this.counter--;
      if (this.counter <= 0) {
        this.isExpired = true;
        clearInterval(this.int);
      }
    }, 1000);
  }

  newCode(){
    this.bsModalRef.hide();
    this.generateNewCode.emit()
  }

}
