import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, Subscription, forkJoin, throwError } from "rxjs";
import { AuthService } from "src/app/core/auth/auth.service";
import { DataService } from "src/app/core/services/data.service";
import { TeamManagementService } from "src/app/modules/team-management/services/team-management.service";
import { DashboardService } from "../../services/dashboard.service";
import { ServiceCategoryServiceV2 } from "src/app/modules/service-category-v2/services/service-category.service";
import { catchError, map, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "app-dashboard-page",
  templateUrl: "./dashboard-page.component.html",
  styleUrls: ["./dashboard-page.component.scss"],
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  accountData = this.dataService.accountData$;
  queuesCount: Array<any>;
  activeQueuesCount: Array<any>;
  currentQueuesCount: Array<any>;
  branchId: string = '';
  subscriptions = new Subscription();
  assignedBranches = this.authService.assignedBranches$;
  windowsCount: Array<any>;
  servicesCount: Array<any>;
  accountList: Array<any>;
  branchData = this.dataService.currentBranch$;
  categoryLists: Array<any>;
  private dashboardSubjectAPI$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private teamService: TeamManagementService,
    private dashboardService: DashboardService,
    private dataService: DataService,
    private categoryService: ServiceCategoryServiceV2,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe((res) => {
        if(res){
          this.branchId = res
          ? res._id
          : this.dataService.currentBranch$?.branchId;

          this.getAccounts();
          this.getQueuesCount();
          if (this.accountData?.data?.roleLevel !== "staff") {
            this.getServiceCategoryList();
          }

          Notification.requestPermission().then((result) => {
            // console.log(result);
          });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.dashboardSubjectAPI$.next();
    this.dashboardSubjectAPI$.complete();
  }

  getQueuesCount() {
    const queuesCount$ = this.dashboardService.getQueuesCount(this.branchId);
    const activeQueuesCount$ = this.dashboardService.getActiveQueuesCount(this.branchId);
    const currentQueuesCount$ = this.dashboardService.getCurrentQueuesCount(this.branchId);
    const windowsCount$ = this.dashboardService.getWindowStatus(this.branchId);
    const servicesCount$ = this.dashboardService.getServiceStatus(this.branchId);
  
    forkJoin([
      queuesCount$,
      activeQueuesCount$,
      currentQueuesCount$,
      windowsCount$,
      servicesCount$
    ]).subscribe(([res1, res2, res3, res4, res5]) => {
      this.queuesCount = res1.data;
      this.activeQueuesCount = res2.data;
      this.currentQueuesCount = res3.data;
      this.windowsCount = res4.data;
      this.servicesCount = res5.data;
    });
  }

  getAccounts() {
    this.teamService.getTeamList("", this.branchId).subscribe((res) => {
      this.accountList = res.data;
    });
  }

  getServiceCategoryList() {
    this.subscriptions.add(
      this.categoryService
        .getServiceCategories(this.branchId)
        .pipe(
          takeUntil(this.dashboardSubjectAPI$),
          map((res) => res.data), // for extraction of data
          tap((data) => {
            this.categoryLists = data;
          }),
          catchError((error) => {
            console.log("Error on fetching service category lists", error);
            return throwError(error);
          }),
        )
        .subscribe()
    );
  }
}
