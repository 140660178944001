import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/core/auth/auth.service";
import { DataService } from "src/app/core/services/data.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TeamManagementService {
  apiUrl = environment.urlAPI;
  accountId = this.authService.accountId$;

  constructor(private http: HttpClient, public authService: AuthService, private dataService: DataService) {}

  private get httpHeader(): any {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return {
      headers,
    };
  }

  public getTeamList(params, branchId): Observable<any> {
    const url = `${this.apiUrl}/accounts/${branchId}/list?${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public checkAvailability(email, branchId): Observable<any> {
    const url = `${this.apiUrl}/accounts/${branchId}/check-email-availability/${email}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public addAccount(accountData, branchId): Observable<any> {
    const url = `${this.apiUrl}/accounts/${branchId}`;
    return this.http.post(url, accountData).pipe(map((response) => response));
  }

  public editAccount(accountData, branchId, accountId): Observable<any> {
    const url = `${this.apiUrl}/accounts/${branchId}/${accountId}`;
    return this.http.patch(url, accountData).pipe(map((response) => response));
  }

  public getProfileData(accountId, branchId): Observable<any> {
    const url = `${this.apiUrl}/accounts/${branchId}/profile/${accountId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public resetPassword(newPassword, accountId, branchId) {
    const url = `${this.apiUrl}/accounts/${branchId}/reset-password/${accountId}`;
    return this.http.patch(url, newPassword).pipe(map((response) => response));
  }

  public deleteAccount(accountId, branchId) {
    const url = `${this.apiUrl}/accounts/${branchId}/${accountId}`;
    return this.http.delete(url).pipe(map((response) => response));
  }

  public getProfileMetrics(filter, branchId, accountId): Observable<any> {
    const url = `${this.apiUrl}/queues/${branchId}/profile/${accountId}?filterBy=${filter}`;
    return this.http.get<any>(url, this.httpHeader);
  }
  
}
