import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { finalize, map, take, takeUntil, tap } from "rxjs/operators";
import { HttpService } from "src/app/core/http/http.service";
import { SocketService } from "src/app/core/http/socket.service";
import { DataService } from "src/app/core/services/data.service";
import { kyooLogo } from "src/app/core/services/global";

@Component({
  selector: "app-queue-ongoing",
  templateUrl: "./queue-ongoing.component.html",
  styleUrls: ["./queue-ongoing.component.scss"],
})
export class QueueOngoingComponent implements OnInit, OnChanges, OnDestroy {
  @Input() branchId: string;
  @Input() selectedServiceId: string;
  @Input() queueSettings: string;
  @Input() filterParam: any;
  @Input() filterParamString: any;
  @Input() windowId: string;
  @Input() serviceLists: any;
  @Input() windowList: any;
  @Input() accountList: any;
  @Input() activeQueue: any;
  @Output() emittedQueueId = new EventEmitter();
  @Output() emittedActiveQueue = new EventEmitter();
  @Output() emittedAction = new EventEmitter();
  @Output() emittedQueueList = new EventEmitter();
  onGoingLists: Array<any>;
  currentLists: Array<any>;
  active: string;
  servingType: string;
  loadNumber: number = 0;
  isEmptyList: boolean;
  isQueueTypeChanged: boolean;
  isQueueListsInit: boolean;
  httpGet$: Subject<void> = new Subject<void>();
  subscriptions = new Subscription();
  params: any;
  queueSocket: BehaviorSubject<any>;
  currentWindow = this.dataService.currentWindow$;
  assignedServices: any;
  services: any;

  storedOnGoingList: any[] = [];

  constructor(
    private httpService: HttpService,
    private dataService: DataService,
    private wsService: SocketService,
    private toastr: ToastrService
  ) { }

  async getWindowList() {
    this.httpService.get$(`services/windows/${this.branchId}?limit=999`).subscribe(async (data) => {
      const windowData = data.data.find((x) => x._id === this.currentWindow);
      if (windowData) {
        this.assignedServices = windowData?.assignedServices;
        this.services = this.assignedServices.map((service) => {
          return service._id
        });
        if (this.services) {
          setTimeout(() => {
            this.getOnGoingList();
          }, 1500); 
        }
      }
    });
  }

  async getOnGoingList(queue?) {
    let param = "";
    for (const [key, value] of Object.entries(this.filterParam)) {
      if (value && key !== "state") {
        param = param.concat(`&${key}=${value}`);
      }

      if (key === "serviceIds" && (!value || value === "all")) {
        param = param.concat(`&${key}=${this.serviceLists?.map((a) => a._id)}`);
      }
    }

    if (
      (param.indexOf("undefined") <= -1 && this.params !== param) ||
      (param.indexOf("undefined") <= -1 && this.isQueueListsInit)
    ) {
      this.params = param;
      // cancel pending get queue list requests
      this.httpGet$.next();
      this.httpService
        .get$(`queues/${this.branchId}/list?${param}`)
        .pipe(
          tap(() => {
            this.loadNumber++;
          }),
          takeUntil(this.httpGet$)
        )
        .subscribe(
          async (data) => {
            this.onGoingLists = [];
            this.currentLists = [];
            if (this.services || this.serviceLists) { 
              const services = this.services || this.serviceLists.map((service) => service._id);
              this.params = param;
              this.onGoingLists = data.data.filter(
                (x) => !x.served && services.includes(x.serviceId) // to only fetch the queues having service that is assigned in the current window
              );
              this.currentLists = data.data.filter(
                (x) => x.served && x.windowId === this.windowId
              );
              // const empty = [null, [], '', undefined, 'waitingTime:desc', 'pending'];
              // if (empty.includes(this.filterParam.search) && empty.includes(this.filterParam.serviceIds) && empty.includes(this.filterParam.tags) && empty.includes(this.filterParam.sort) && empty.includes(this.filterParam.state) && empty.includes(this.filterParam.windowId)) {

              // for TMC
              if (this.dataService.accountData$ && ([
                "a597d9a2-c2dd-40e7-b05f-47114be30f12", //??
                "0bb706ca-6f00-42a1-af36-431770829e90", //dev
                "a94bf1a5-5677-425e-87ba-a0ae9078ae22", //staging
                "e42367dc-f0b0-4e6e-8a4b-819cf9d50262"  //demo
              ].includes(this.dataService.accountData$.data.businessId))) {
                this.onGoingLists.sort((a, b) => a.createdAt - b.createdAt)
                await this.storeQueueList();
              }

              this.emittedQueueList.emit({
                currentLists: this.currentLists,
                onGoingLists: this.onGoingLists,
              });
              this.getQueueListDisplay(queue);
              this.isEmptyList = data.data.length ? false : true;
              this.isQueueTypeChanged = false;
              this.isQueueListsInit = false;
            } else {
              await this.getWindowList();
            }
          },
          (error) => {
            console.log("error", error);
            this.isEmptyList = true;
          }
        );
    }
  }

  async storeQueueList() {
    await this.httpService
      .get$(`queues/${this.branchId}/list?state=pending&sort=waitingTime:desc`)
      .pipe(
        tap(() => {
          this.loadNumber++;
        }),
        takeUntil(this.httpGet$)
      )
      .subscribe(
        (data) => {
          const onGoingLists = data.data.filter(
            (x) => !x.served && this.services.includes(x.serviceId) // to only fetch the queues having service that is assigned in the current window
          );
          const currentLists = data.data.filter(
            (x) => x.served && x.windowId === this.windowId
          );
          this.storedOnGoingList = this.groupQueueByService(onGoingLists, currentLists).ongoing;
          this.onGoingLists.forEach(queue => {
            queue.numberInList = this.getNumberInList(queue, this.storedOnGoingList);
          });
        }
      );
  }

  groupQueueByService(ongoing, current) {
    const ongoingList = ongoing.reduce((acc, obj) => {
      if (!acc[obj.serviceId]) {
        acc[obj.serviceId] = [];
      }
      acc[obj.serviceId].push(obj);
      return acc;
    }, {});

    const currentList = current.reduce((acc, obj) => {
      if (!acc[obj.serviceId]) {
        acc[obj.serviceId] = [];
      }
      acc[obj.serviceId].push(obj);
      return acc;
    }, {});

    return { ongoing: ongoingList, current: currentList };
  }

  getNumberInList(queue, list) {
    const listByCreatedAt = list[queue.serviceId].sort((a, b) => a.createdAt - b.createdAt)
    // return list[queue.serviceId].findIndex((q) => q._id === queue._id) + 1;
    return listByCreatedAt.findIndex((q) => q._id === queue._id) + 1;
  }

  selectQueue(id) {
    if (this.active !== id) {
      this.active = id;
      this.emittedQueueId.emit(id);
    }
  }

  doQueueAction($event) {
    this.emittedAction.emit($event);
  }

  checkDataChange() {
    this.subscriptions.add(
      this.dataService.dataChange$.subscribe((res) => {
        if (res) {
          if (res?.action === "tagUpdate") {
            if (res?.queue?.served) {
              const queueIndex = this.currentLists.findIndex(
                (x) => x._id === res?.queue?._id
              );
              if (queueIndex > -1) {
                this.currentLists[queueIndex] = res?.queue;
              }
            } else {
              const queueIndex = this.onGoingLists.findIndex(
                (x) => x._id === res?.queue?._id
              );
              if (queueIndex > -1) {
                this.onGoingLists[queueIndex] = res?.queue;
              }
            }

            this.emittedActiveQueue = res?.queue;
            return;
          }

          if (
            [
              "added",
              "served",
              "removed",
              "completed",
              "moved",
              "returned",
              "hold",
              "resume",
              "skip",
              "updated",
            ]?.includes(res?.action)
          ) {
            this.isQueueListsInit = true;
          }
          const index = res.action === "updated" ? res.queue : null;
          this.getOnGoingList(index);
        }
      })
    );
  }

  async ngOnInit() {
    if (this.currentWindow) {
      // await this.getWindowList();
      this.checkDataChange();
      this.checkRealtimeUpdates();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Reset Load Number
    if (this.servingType !== this.filterParam.state) {
      this.loadNumber = 0;
      this.isQueueTypeChanged = true;
      this.httpGet$.next();
      this.getQueueListDisplay();
      this.servingType = this.filterParam.state;
    }
    this.getOnGoingList();
  }

  async getQueueListDisplay(queue?) {
    if (this.filterParam.state === "pending") {
      if (queue) {
        this.active = queue;
      } else {
        this.active =
          this.onGoingLists && this.onGoingLists.length > 0
            ? this.onGoingLists[0]?._id
            : null;
      }

      this.emittedQueueId.emit(this.active);
      if (this.params && !this.params.includes("search")) {
        await this.selectQueue(this.active);
      }
    } else {
      if (queue) {
        this.active = queue;
      } else {
        this.active =
          this.currentLists && this.currentLists.length > 0
            ? this.currentLists[0]?._id
            : null;
      }

      this.emittedQueueId.emit(this.active);
      if (this.params && !this.params.includes("search")) {
        await this.selectQueue(this.active);
      }
    }
  }

  checkRealtimeUpdates() {
    const accountId = this.dataService.accountData$.data.accountId;
    this.queueSocket = this.wsService
      .connect(`v4::queues::${this.branchId}`)
      .pipe(
        map((response: any): any => {
          return response;
        })
      ) as BehaviorSubject<any>;
    this.subscriptions.add(
      this.queueSocket.subscribe((msg) => {
        const a = this.serviceLists.find(
          (x) => x._id === msg.payload.serviceId
        );

        if (a) {
          if (msg.event === "add" && accountId !== msg.payload.accountId) {
            this.toastr.success(
              `Queue ${msg.payload.queueNo} has been added`,
              "Success!", {
              positionClass: 'toast-bottom-right-custom'
            }
            );
            const notif = new Notification("Queue Added!", {
              body: `Queue ${msg.payload.queueNo} has been added`,
              icon: kyooLogo,
            });
            this.isQueueListsInit = true;
          } else if (
            msg.event === "remove" &&
            accountId !== msg.payload.accountId
          ) {
            this.toastr.error(
              `Queue ${msg.payload.queueNo} has been removed`,
              "Removed!", {
              positionClass: 'toast-bottom-right-custom'
            }
            );
            this.isQueueListsInit = true;
          } else if (
            msg.event === "hold" &&
            accountId !== msg.payload.accountId
          ) {
            this.toastr.warning(
              `${msg.payload.queueNo} is being on hold`,
              "On Hold!", {
              positionClass: 'toast-bottom-right-custom'
            }
            );
            this.isQueueListsInit = true;
          } else if (
            msg.event === "cancel" &&
            accountId !== msg.payload.accountId
          ) {
            this.toastr.error(
              `${msg.payload.queueNo} has been cancelled`,
              "Cancelled!", {
              positionClass: 'toast-bottom-right-custom'
            }
            );
            this.isQueueListsInit = true;
          } else if (
            msg.event === "resume" &&
            accountId !== msg.payload.accountId
          ) {
            this.toastr.success(
              `${msg.payload.queueNo} has been resumed`,
              "Resumed!", {
              positionClass: 'toast-bottom-right-custom'
            }
            );
            this.isQueueListsInit = true;
          } else if (
            msg.event === "serve" &&
            accountId !== msg.payload.accountId
          ) {
            this.toastr.success(
              `${msg.payload.queueNo} is being served`,
              "Served!", {
              positionClass: 'toast-bottom-right-custom'
            }
            );
            this.isQueueListsInit = true;
          } else if (
            msg.event === "transfer-service" &&
            accountId !== msg.payload.accountId
          ) {
            if (msg.payload.timeline?.length > 0 && msg.payload.timeline[msg.payload.timeline.length - 1].event === "transfer-service") {
              this.toastr.success(`${msg.payload.queueNo} ${msg.payload.timeline[msg.payload.timeline.length - 1].eventSummary}`, 'Transferred!', {
                positionClass: 'toast-bottom-right-custom'
              });
            } else {
              this.toastr.success(`${msg.payload.queueNo} has been transferred to another service`, 'Transferred!', {
                positionClass: 'toast-bottom-right-custom'
              });
            }
            this.isQueueListsInit = true;
          } else if (
            msg.event === "transfer-window" &&
            accountId !== msg.payload.accountId
          ) {
            // this.toastr.success(`${msg.payload.queueNo} has been transferred`, "Served!");
            this.isQueueListsInit = true;
          } else if (
            msg.event === "next-procedure" &&
            accountId !== msg.payload.accountId
          ) {
            // this.toastr.success(`Queue ${msg.payload.queueNo} has been added`, 'Success!');
            const notif = new Notification("Queue Added!", {
              body: `Queue ${msg.payload.queueNo} has been added`,
              icon: kyooLogo,
            });
            this.isQueueListsInit = true;
          }
        }
        this.getOnGoingList();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
