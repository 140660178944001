import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { TitleCasePipe } from "@angular/common";
import { ServiceCategoryService } from "../../../service-category/services/service-category.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-add-service-category",
  templateUrl: "./add-service-category.component.html",
  styleUrls: ["./add-service-category.component.scss"],
  providers: [TitleCasePipe],
})
export class AddServiceCategoryComponent implements OnInit {
  branchId: any;
  title: any;
  type: string;
  categoryDetails: any;
  serviceLists: any;
  parentCategoryId: any;
  labelName = "Category Name";
  placeholder = "Enter Category Name";
  categoryName = "";
  errMsg = "Please enter category name";
  cancelText = "Cancel";
  confirmText = "Proceed";
  isTouched = false;
  isSubmitting = false;
  @Output() successEvent = new EventEmitter();

  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    private toastr: ToastrService,
    private titleCasePipe: TitleCasePipe,
    private categoryService: ServiceCategoryService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.type === "edit") {
      this.categoryName = this.categoryDetails.displayName;
    }
  }

  onSubmit() {
    this.isSubmitting = true;
    const body = {
      displayName: this.categoryName,
      activated: true,
      parentCategoryId: this.parentCategoryId,
    };

    if (this.type === "add") {
      let addedText = body.parentCategoryId ? "Subcategory " : "New Category ";
      this.categoryService.addServiceCategory(this.branchId, body).subscribe(
        (res) => {
          console.log("ADD category", res);
          this.modalRef.hide();
          this.isSubmitting = false;
          this.successEvent.emit(res);
          this.toastr.success(`${addedText} has been added`, "Success!");
          // this.router.navigate(["/apps/service-category/", res.data._id], {
          //   queryParams: { type: "add" },
          // });
        },
        (error) => {
          console.log("error", error);
          this.isSubmitting = false;
        }
      );
    } else {
      const body = {
        displayName: this.categoryName,
      };

      this.categoryService
        .updateServiceCategory(this.branchId, this.categoryDetails._id, body)
        .subscribe(
          (res) => {
            console.log(res);
            this.modalRef.hide();
            this.successEvent.emit(res.data);
            this.toastr.success(`Category name has been updated`, "Success!");
          },
          (error) => {
            console.log(error);
            this.isSubmitting = false;
            let errMsg = "Error on managing services";
            this.toastr.error(errMsg, "Error!");
          }
        );
    }
  }

  touchTextField() {
    this.isTouched = true;
  }

  cancel() {
    this.modalRef.hide();
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        selectedCategory: null,
      },
      queryParamsHandling: "merge",
      skipLocationChange: false,
    });
  }
}
