import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  OnChanges,
  ElementRef,
  ViewChild,
  Renderer2,
} from "@angular/core";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { BranchManagementService } from "src/app/modules/branch-management/services/branch-management.service";
import { FormControlName } from "@angular/forms";

interface DropDownData {
  value: number | string;
  label: string;
}
@Component({
  selector: "app-input-select",
  templateUrl: "./input-select.component.html",
  styleUrls: ["./input-select.component.scss"],
})
export class InputSelectComponent implements OnInit, OnChanges {

  @Input() isReport: boolean;
  @Input() type: string;
  @Input() label: string;
  @Input() branchId = this.dataService.currentBranch$?.branchId;
  @Input() filter: any;
  @Input() placeholder: string;
  @Input() dropdownList: Array<any>;
  @Input() originalList: any;
  @Input() activeValue: string;
  @Input() selectedMultipleList: string[];
  @Input() selectedDropdownValue: string;
  @Input() filterParamArray: Array<any>;
  @Input() filterParam: any;
  @Input() filterParamArrayString: string;
  @Input() isClearValue: boolean = false;
  @Input() isDisabled: boolean;
  @Input() sortType: string;
  @Input() appointmentTimeslot: boolean = false;
  @Output() selectedDropdown = new EventEmitter();
  isFocused: boolean;
  selectedValue: string;
  isSelectClicked: boolean;
  isButtonClicked: boolean;
  selectedLists: any[] = [];
  universalTags:Array<any> = [];

  searchInput: string = '';
  dropdownListSearch: any[] = [];

  @ViewChild("multipleSelect") multipleContent: ElementRef;
  @ViewChild("multipleInput") multipleInput: ElementRef;

  constructor(
    private httpService: HttpService,
    private dataService: DataService,
    private renderer: Renderer2,
    private branchService: BranchManagementService
  ) {

  }

  initDropdown() {
    if (this.dropdownList) {
      if (this.type === "single" || this.type === "search") {
        const findLabel =
          this.dropdownList && this.activeValue
            ? this.dropdownList.find(
              (element) => element.value === this.activeValue
            )?.label
            : null;
        this.selectedValue = findLabel ? findLabel : this.placeholder ? this.placeholder : this.dropdownList[0].label;
        if (!this.activeValue) {
          this.selectedDropdown.emit(this.selectedValue);
        }
      }
    } else {
      this.checkDropdownList();
    }

    if (this.isReport) {
      this.selectedLists = [{ value: 0, label: "SHOW ALL" }];
    }

    if (this.type === "search" && this.dropdownList) {
      this.dropdownListSearch = this.dropdownList;
    }
  }

  processCategoryLabel(label) {
    if (label.includes(">")) {
      return label.split(">")
    } else {
      return [label]
    }
  }

  checkDropdownList() {
    switch (this.filter) {
      case "tags":
        {
          this.getQueueTagLists();
        
        }
        break;
      case "windowId":
        {
          this.getWindowLists();
        }
        break;
    }
  }

  getQueueTagLists() {

    this.httpService.get$(`queues/${this.branchId}/list`).subscribe(
      (data) => {
        this.dropdownList = [];
        this.selectedLists = [];
        let tags = [];
        let selectedTag = [];

        for(let queueItem of data.data) {
           for(let queuetag of queueItem.tags) {
              tags.push(queuetag);
           }
        }

       if (
          this.filterParam &&
          this.filterParam.tags &&
          this.filterParam.tags.length > 0
        ) {
          selectedTag = this.filterParam.tags;
          selectedTag.forEach((x) => {
            this.selectedLists.push({
              label: x.toUpperCase(),
              value: x,
            });
          });
        }

        tags =  [...new Set(tags)]

        tags = tags.filter(item => !selectedTag.includes(item))

        if (data && data.data) {
        
          tags.forEach((element) => {
            if (!tags.includes(element.text)) {
              this.dropdownList.push({
                label: element.toUpperCase(),
                value: element,
              });
            }
          });
        } else if (data && data.data) {
          tags.forEach((element) => {
            if (!tags.includes(element.text)) {
              this.dropdownList.push({
                label: element.toUpperCase(),
                value: element,
              });
            }
          });
        } 

        setTimeout(() => {
          if (this.selectedLists.length === 0) {
            this.renderer.setStyle(
              this.multipleInput.nativeElement,
              "height",
              `$36px`
            );
          } else {
            this.renderer.setStyle(
              this.multipleInput.nativeElement,
              "height",
              `${this.multipleContent.nativeElement.offsetHeight + 8}px`
            );
          }
        }, 100);
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  getWindowLists() {
    this.httpService
      .get$(`services/windows/${this.branchId}?limit=999`)
      .subscribe(
        (data) => {
          this.dropdownList = data.data.map((element) => {
 
            return {
              label: element.displayName,
              value: element._id,
            };
          });
          const selected = this.filterParamArray.find(
            (element) => element?.param === "windowId"
          )?.value;
          this.selectedLists = this.dropdownList.filter((element) =>
            selected?.includes(element.value)
          );
        },
        (error) => {
          console.log("error", error);
        }
      );
  }

  select() {
    if (this.type === "search" && this.searchInput === this.placeholder) {
      this.searchInput = '';
      this.selectedValue = '';
    }

    this.isSelectClicked = !this.isSelectClicked;
    this.isFocused = !this.isFocused;
    // if (!this.isFocused) {
    //   this.isSelectClicked = false;
    // }
  }

  searchInputUpdated($event) {
    this.isSelectClicked = true;
    this.isFocused = true;
    if (this.dropdownList) {
      if (this.searchInput) {
        const pattern = new RegExp(`${this.searchInput}`, 'i');
        this.dropdownListSearch = this.dropdownList.filter(item => item.label.search(pattern) != -1)
      } else {
        this.dropdownListSearch = this.dropdownList;
      }
    }
  }

  @HostListener("document:click")
  deselect() {
    if (!this.isSelectClicked || this.isButtonClicked) {
      this.isFocused = false;
    }
    this.isButtonClicked = false;
    this.isSelectClicked = false;

    if (this.type === "search") {
      this.searchInput = this.selectedValue;
    }
  }

  selectDropdown(list) {
    this.selectedValue = list.label;

    if (this.type === "search") {
      this.searchInput = list.label;
    }

    this.selectedDropdown.emit(list.value);
    this.isButtonClicked = true;
    if (!this.isFocused) {
      this.isButtonClicked = false;
    }
  }

  selectDropdownMultiple(list) {
    if (list.value === 0) {
      this.dropdownList = this.dropdownList.concat(
        this.selectedLists.filter((x) => x.value !== 0)
      );
      this.selectedLists = [list];
      this.selectedDropdown.emit([]);
    } else {
      this.selectedLists = this.selectedLists.filter((x) => x.value !== 0);
      this.selectedLists.push(list);
      this.dropdownList = this.dropdownList.filter(
        (x) => x.value !== list.value
      );
      this.selectedDropdown.emit(this.selectedLists);
    }

    setTimeout(() => {
      this.renderer.setStyle(
        this.multipleInput.nativeElement,
        "height",
        `${this.multipleContent.nativeElement.offsetHeight + 8}px`
      );
    }, 100);
  }

  delete(i, data) {
    this.selectedLists.splice(i, 1);
    this.dropdownList.push(data);
    if (this.selectedLists.length <= 0 && this.isReport) {
      this.selectedLists = [{ value: 0, label: "SHOW ALL" }];
    }

    this.selectedDropdown.emit(this.selectedLists);
    setTimeout(() => {
      if (this.selectedLists.length === 0) {
        this.renderer.setStyle(
          this.multipleInput.nativeElement,
          "height",
          `$36px`
        );
      } else {
        this.renderer.setStyle(
          this.multipleInput.nativeElement,
          "height",
          `${this.multipleContent.nativeElement.offsetHeight + 8}px`
        );
      }
    }, 100);
  }

  ngOnInit(): void {
    if (!this.branchId) {
      const branch = this.branchService.getSelectedBranch();
      this.branchId = branch._id;
    }
    this.initDropdown();
    if (this.selectedDropdownValue) {
      const s = this.dropdownList.find((x) => x.value === this.selectedDropdownValue);
      this.selectedValue = s.label;
    }
  }

  ngOnChanges(changes): void {
    if (changes?.isClearValue && this.dropdownList) {
      this.dropdownList = this.dropdownList.concat(this.selectedLists);
      this.selectedLists = [];
      if (this.isReport) {
        this.selectedLists = [{ value: 0, label: "SHOW ALL" }];
      }
      if (this.selectedMultipleList && this.dropdownList) {
        this.selectedMultipleList.forEach((selected) => {
          const s = this.dropdownList.find((x) => x.value === selected);
          if (s) {
            this.dropdownList = this.dropdownList.filter(
              (x) => x.value !== s.value
            );
            this.selectedLists.push(s);
          }
        });
      } else {
        this.dropdownList = this.removeShowAll(this.dropdownList, 0, "SHOW ALL"); // fix for SHOW ALL dropdown data bug
      }
      if (this.multipleInput) {
        this.renderer.setStyle(
          this.multipleInput.nativeElement,
          "height",
          `36px`
        );
      }
    } else {
      if (changes?.dropdownList?.currentValue !== changes?.dropdownList?.previousValue) {
        this.selectedValue = this.placeholder; // reset value for changes
      }
    }
  }

  removeShowAll(data: DropDownData[], value: number | string, label: string): DropDownData[] {
    return data.filter((obj) => obj.value !== value || obj.label !== label);
  }

}
