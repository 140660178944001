import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { defaultAvatar } from "src/app/core/services/global";

@Component({
  selector: "app-radio-list",
  templateUrl: "./radio-list.component.html",
  styleUrls: ["./radio-list.component.scss"],
})
export class RadioListComponent implements OnInit {
  @Input()
  radioTitle: string;
  @Input()
  radioLists: Array<any>;
  @Input()
  type: string;
  @Input()
  value: any;
  @Input()
  blankText: string;
  @Input()
  disabledText: string;
  @Input()
  accountList: any;
  @Output()
  changedRadio = new EventEmitter();
  radioForm: FormGroup;
  defaultAvatar: string = defaultAvatar;

  _selectedRadio: string;
  @Input()
  public set selectedRadio(selectedRadio) {
    if (selectedRadio) {
      setTimeout(() => {
        this.initForm();
      }, 100);
    }
    this._selectedRadio = selectedRadio;
  }

  public get selectedRadio() {
    return this._selectedRadio;
  }

  constructor(
    private fb: FormBuilder,
  ) {
  }

  initForm() {
    this.radioForm = this.fb.group({
      inputRadio: [Number(this.value)],
    });
    this.f["inputRadio"].setValue(this.selectedRadio);
  }

  get f() {
    return this.radioForm.controls;
  }

  change() {
    this.changedRadio.emit(this.f["inputRadio"].value);
  }

  getAccount(id) {
    let account;
    if (id && this.accountList) {
      const a = this.accountList.find((x) => x._id === id);
      if (a) {
        account = a;
      }
    }

    return account;
  }

  ngOnInit(): void {
    console.log(this.radioLists);
    console.log(this.accountList);
    this.initForm();
  }
}
