import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { HttpService } from "src/app/core/http/http.service";
import { TeamManagementService } from "src/app/modules/team-management/services/team-management.service";

@Component({
  selector: "app-new-password-page",
  templateUrl: "./new-password-page.component.html",
  styleUrls: ["./new-password-page.component.scss"],
})
export class NewPasswordPageComponent implements OnInit {
  passwordShown = false;
  passwordShown2 = false;
  
  passwordForm = this.fb.group({
    password: ["", [Validators.required, Validators.minLength(6)]],
    confirmPassword: ["", [Validators.required, Validators.minLength(6)]],
  });

  focus: boolean;
  focus1: boolean;

  token: any;

  isSubmitted = false;
  errMsg = '';

  @ViewChild("password1", { static: false }) password1: any;
  @ViewChild("password2", { static: false }) password2: any;

  get f() {
    return this.passwordForm.controls;
  }
  subscriptions = new Subscription();

  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute, private httpService: HttpService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        this.token = params.token;
      })
    );
  }

  showPassword() {
    this.passwordShown = !this.passwordShown;
  }

  showPassword2() {
    this.passwordShown2 = !this.passwordShown2;
  }

  focusBlurPassword(isFocused) {
    this.focus1 = isFocused;
    this.password1.nativeElement.className =
      "input-group input-group-merge mr-2";
    if (isFocused) {
      this.password1.nativeElement.className =
        "input-group input-group-merge mr-2 focused";
    }
  }

  focusBlurPassword2(isFocused) {
    this.focus = isFocused;
    this.password2.nativeElement.className =
      "input-group input-group-merge mr-2";
    if (isFocused) {
      this.password2.nativeElement.className =
        "input-group input-group-merge mr-2 focused";
    }
  }

  submitForm() {
    const newPassword = {
      password: this.passwordForm.value.password
    }
    this.httpService.patch$(`authenticate/forgot-password?token=${this.token}`, newPassword).subscribe(
      data => {
        this.isSubmitted = true;
      }, error => {
        console.log('error', error);
        this.errMsg = 'Reset password FAILED! Reset password link has expired.'
      }
    )
  }

}
