import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { HttpService } from "src/app/core/http/http.service";
import { GenericService } from "src/app/core/services/generic.service";
import { TeamManagementService } from "../../services/team-management.service";

@Component({
  selector: "app-edit-account-modal",
  templateUrl: "./edit-account-modal.component.html",
  styleUrls: ["./edit-account-modal.component.scss"],
})
export class EditAccountModalComponent implements OnInit {
  title: string;
  profileData: any;
  inputFocus: string;
  selectedPhoto: any;
  accountForm = this.fb.group({
    email: ["", Validators.required],
    password: ["", Validators.required],
    firstName: [
      "",
      [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(/^[A-Za-z- ñÑ.]*$/),
      ],
    ],
    lastName: [
      "",
      [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(/^[A-Za-z- ñÑ.]*$/),
      ],
    ],
    mobileNo: ["", Validators.minLength(10)],
    role: ["", Validators.required],
  });

  @Output() successEvent = new EventEmitter();
  isSubmitting = false;
  newPhoto = false;
  errMsg = "";

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    public toastr: ToastrService,
    private teamService: TeamManagementService,
    private httpService: HttpService,
    private genericService: GenericService
  ) {}

  ngOnInit() {
    this.patchValue();
    console.log(this.profileData);
  }
  get f() {
    return this.accountForm.controls;
  }

  changeInput(ev) {
    this.accountForm.value.mobileNo = ev.value;
    this.accountForm.markAsDirty();
  }

  patchValue() {
    const email =
      typeof this.profileData.email === "string"
        ? this.profileData.email
        : this.profileData.email.value;
    this.accountForm.controls.email.patchValue(email);
    this.accountForm.controls.firstName.patchValue(this.profileData.firstName);
    this.accountForm.controls.lastName.patchValue(this.profileData.lastName);

    this.accountForm.controls.mobileNo.patchValue(
      this.genericService.patchMobile(this.profileData.contactNo)
    );
    this.accountForm.controls.role.patchValue(this.profileData.roleLevel);

    if (this.profileData.roleLevel === "owner") {
      this.accountForm.controls.role.disable();
    } else {
      this.accountForm.controls.role.enable();
    }
  }

  uploadPhoto(event) {
    this.newPhoto = true;
    
    this.selectedPhoto = event;
  }

  submitPhoto(profileData) {
    console.log("upload photo", this.selectedPhoto);
    const param = `accounts/${this.profileData.branchId}/${profileData._id}/presign-image-url`;
    this.httpService
      .uploadPhoto(param, this.selectedPhoto)
      .then(async (data) => {
        console.log("submitted", data);
        this.successEvent.emit(profileData);
      })
      .catch(async (error) => {});
  }

  saveChanges() {
    this.errMsg = "";
    const formData = {
      firstName: this.accountForm.value.firstName,
      lastName: this.accountForm.value.lastName,
      contactNo: this.accountForm.value.mobileNo && this.accountForm.value.mobileNo.length != 1
        ? this.accountForm.value.mobileNo.replaceAll("-", "")
        : "",
      avatarUrl: "",
      roleLevel:
        this.profileData.roleLevel === "owner"
          ? "owner"
          : this.accountForm.value.role,
    };

    console.log(formData);

    this.teamService
      .editAccount(formData, this.profileData.branchId, this.profileData._id)
      .subscribe(
        (res) => {
          console.log("EDIT ACCOUNT SUCCESS", res);
          res.data.contactNo = formData.contactNo;
          res.data.firstName = formData.firstName;
          res.data.lastName = formData.lastName;

          if (this.selectedPhoto) {
            this.submitPhoto(res.data);
          } else {
            this.successEvent.emit(res.data);
          }
          this.isSubmitting = false;
          this.bsModalRef.hide();
          this.toastr.success("Profile has been updated", "Success!");
        },
        (error) => {
          console.log("error", error);
          this.errMsg = error.error.errors[0].msg;
          this.isSubmitting = false;
        }
      );
  }
}
