import { MapsAPILoader } from "@agm/core";
import {
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { BranchManagementService } from "../../services/branch-management.service";

@Component({
  selector: "app-edit-address-modal",
  templateUrl: "./edit-address-modal.component.html",
  styleUrls: ["./edit-address-modal.component.scss"],
})
export class EditAddressModalComponent implements OnInit {
  private geoCoder;
  address: string;
  latitude = 14.547956768247687;
  longitude = 121.05131113274915;
  zoom = 17;

  @ViewChild("searchAddress") searchElementRef: ElementRef;
  @Output() successEvent = new EventEmitter();

  branchData: any;
  isSubmitting = false;
  isDisabled = true;


  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public bsModalRef: BsModalRef,
    private branchService: BranchManagementService, 
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    debugger
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      this.latitude = this.branchData.location.coordinates[0];
      this.longitude = this.branchData.location.coordinates[1];
      this.zoom = 8;
      this.getAddress(this.latitude, this.longitude);

    });

    setTimeout(() => {
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );

      autocomplete.setComponentRestrictions({
        country: ["ph"],
      });

      console.log(this.searchElementRef.nativeElement);
      console.log(autocomplete);

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          const result = document.getElementsByClassName(
            "pac-container"
          ) as HTMLCollectionOf<HTMLElement>;
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          this.isDisabled = false;

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return null;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.address = place.formatted_address;
          this.blur();

          this.zoom = 15;
        });
      });
    }, 500);
  }

  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = this.branchData.location.coordinates[0];
        this.longitude = this.branchData.location.coordinates[1];
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: any) {
    console.log($event);
    this.isDisabled = false;

    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  blur() {
    const result = document.getElementsByClassName(
      "pac-container"
    ) as HTMLCollectionOf<HTMLElement>;
    result[0] ? (result[0].hidden = true) : "";
  }

  focus() {
    const result = document.getElementsByClassName(
      "pac-container"
    ) as HTMLCollectionOf<HTMLElement>;
    result[0] ? (result[0].hidden = false) : "";
  }

  submit() {
    this.blur();
    this.isSubmitting = true;

    const formData = {
      name: this.branchData.name,
      email: this.branchData.email,
      mobileNo: this.branchData.mobileNo,
      address: this.address,
      coordinates: {
        lat: this.latitude,
        lng: this.longitude,
      }
    }

    this.branchService.editBranchDetails(formData, this.branchData._id).subscribe(
      (res) => {
        console.log("EDIT BRANCH SUCCESS", res);
        this.successEvent.emit(res.data);
        this.isSubmitting = false;
        this.bsModalRef.hide();
        this.toastr.success("Branch has been updated", "Success!");
      },
      (error) => {
        console.log("error", error);
        this.isSubmitting = false;
      }
    );

  }
}
