import { Component, Input, OnInit } from '@angular/core';
import * as iconLists from '../../data/icon.json';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input() iconStyle: string = '';
  @Input() addClass: string = '';
  @Input() color: string = '';
  @Input() size: number = 24;
  iconLists: {} = iconLists;
  icon: string = '';

  constructor() { }

  getIcon() {
    this.icon = this.iconLists['default'][this.iconStyle];
  }

  ngOnInit(): void {
    this.getIcon();
  }

}
