import { Component, EventEmitter, Input, OnInit, Output, ElementRef, HostListener, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnInit {
  @Input() title: string;
  @Input() placeholder: string = '+Add Tag';//PAL REQUEST
  @Input() isTransactions: boolean = false; //PAL REQUEST
  @Input() titleClass: string;
  @Input() subTitleClass: string;
  @Input() subTitle: string;
  @Input() tagItems: Array<any>;
  @Input() universalTags: Array<any>;
  @Output() addedTag = new EventEmitter();
  @Output() removedTag = new EventEmitter();
  @Output() getUniverSalTag = new EventEmitter();
  @Output() inputFocused = new EventEmitter();//PAL REQUEST
  editText: string;
  inputText: string;
  maxLength = 50;
  leftLocation: string;
  showUniversalTag = false;
  inputLocation: DOMRect;
  @ViewChild('tagInputStyle', { static: true }) tagInputStyle: ElementRef;

  constructor(
    private el: ElementRef
  ) {
  }


  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.showUniversalTag = false;
    }
  }

  add($event) {
    this.addedTag.emit($event.value);
  }


  myDataTag(nameTag) {
    this.showUniversalTag = true;
    const tagData = {
      value: ''
    }
    tagData.value = nameTag;
    this.tagItems.push(tagData.value);
    this.addedTag.emit(tagData.value);
    this.showUniversalTag = false;
    this.tagItems = [...new Set(this.tagItems)]
  }

  remove($event) {
    this.removedTag.emit($event);
  }

  onTextChange($event) {
    if ($event) {
      this.editText = $event;
      this.showUniversalTag = false;
      this.editText = this.editText.slice(0, this.maxLength);
      setTimeout(() => {
        this.inputText = this.editText;
      }, 100)
    }
  }

  onBlur($event) {
    if ($event) {
      this.onTextChange($event);
      setTimeout(() => {
        this.inputText = this.editText;
      }, 100)
    }

  }


  onFocus($event) {
    const tagStyleElement = this.el?.nativeElement.querySelector('.ng2-tags-container') as HTMLInputElement;
    const inputElement = this.el?.nativeElement.querySelector('input');

    this.inputFocused.emit(true)//PAL REQUEST

    if (inputElement) {
      this.showUniversalTag = true;
      const rect = inputElement.getBoundingClientRect();
      const tagStyleRect = tagStyleElement.getBoundingClientRect();
      const leftInPixels = Math.round(rect.left - tagStyleRect.left) + 'px';
      this.leftLocation = leftInPixels;
    }

    if ($event) {
      this.onTextChange($event);
      setTimeout(() => {
        this.inputText = this.editText;
      }, 100)
      this.getUniverSalTag.emit($event);
    }

  }

  //PAL REQUEST
  universalTagChecklist(tags: any[]): Array<any> {
    let tagsList = []
    for (const tag of tags) {
      tagsList.push({
        label: tag.name,
        _id: tag._id
      })
    }
    return tagsList;
  }

  ngOnInit(): void {
  }

}
