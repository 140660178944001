import { Component, OnDestroy, OnInit} from '@angular/core';
import { IAppointmentBtn } from '../../interface/appointment.interface';
import { AddAppointmentModalComponent } from 'src/app/modules/appointment/component/add-appointment-modal/add-appointment-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from "@angular/router";
import { AppointmentService } from '../../services/appointment.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
@Component({
  selector: 'app-schedule-appointment-page',
  templateUrl: './schedule-appointment-page.component.html',
  styleUrls: ['./schedule-appointment-page.component.scss']
})
export class ScheduleAppointmentPageComponent implements OnInit, OnDestroy {
  selectedTab: number = 0;
  tabLists: Array<IAppointmentBtn> = [
    { value: "today`s-appointment", label: "TODAY’S APPOINTMENT" },
    { value: "upcoming-appointments", label: "UPCOMING APPOINTMENTS" },
    { value: "for-approval", label: "FOR APPROVAL" },
 /*    { value: "calendar-view", label: "CALENDAR VIEW" }, */
  ];
  parentData: string;
  branchId:string =  this.dataService.currentBranch$.branchId;
  subscriptions = new Subscription();
  isViewingFromEmail: boolean;
  appointmentId: string;
  appointmentData: any;
  appovalAppointmentCounts: number = 0;
  isLoading: boolean = true;
  ms8Hours = 28800000;

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private router: Router,
    private appointmentService: AppointmentService,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.dataService.branchChange$.subscribe((res) => {
      if(res) {
        this.branchId = res ? res.branchId : this.branchId; 
        this.readAppointmentStatus();
        this.readCountApprovalAppointments();
        this.isLoading = false;
      }
    });
  }

  public readCountApprovalAppointments() {
    this.subscriptions.add(
      this.appointmentService.getCountApprovalAppointment(this.branchId).subscribe(
        res => {
          if(res) {
            this.appovalAppointmentCounts = res.data;
          }
        },
        error => {
          console.log(error);
        } 
      )
    );
  }

  public readAppointmentStatus() {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params)=>{
        if(params.appointmentId){
          this.isViewingFromEmail = true;
          this.appointmentId = params.appointmentId;
          this.appointmentService.getAppointment(this.branchId, this.appointmentId).subscribe(
            (res)=>{
              this.appointmentData = res.data;
              this.appointmentData.formattedTimeslot = this.getFormattedTime(this.appointmentData.selectedTimeslot + this.ms8Hours);
              this.appointmentService.getServiceDetails(this.appointmentData.branchId, this.appointmentData.serviceId)
                .subscribe((res)=>{
                  this.appointmentData.serviceName = res.data.displayName;
                  if(this.appointmentData.approved){
                    if(new Date(this.appointmentData.timeslot).setHours(0,0,0,0) === new Date().setHours(0,0,0,0)){
                      this.appointmentData.status = "ONGOING"
                      this.selectedTab = 0;
                    } else {
                        this.selectedTab = 1;
                        this.appointmentData.status = "UPCOMING"
                      }
                  } else {
                    this.selectedTab = 2;
                    this.appointmentData.status = "PENDING"
                  }
                })
            },
            (error)=>{
              this.appointmentService.getHistoryAppointmentDetails(this.branchId, this.appointmentId)
                .subscribe((res)=>{
                  this.router.navigate([`/appointment/appointment-history/`], {
                    queryParams: {appointmentId: this.appointmentId}
                  })
                })
            }
          )
        }
      })
    )
  }

  public isViewed(){
    this.isViewingFromEmail = false;
  }

  public isApprovedOrRejected($event) {
    this.readCountApprovalAppointments();
  }



  getFormattedTime(milliseconds: number){
    let hours = Math.floor(milliseconds/(1000 * 60 * 60));
    let minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const ampm = `${hours < 12 ? "AM" : "PM"}`;
    if(hours>12){
        hours = hours - 12;
    }
    if(minutes < 10){
        return `${hours}:0${minutes} ${ampm}`;
    }
    return `${hours}:${minutes} ${ampm}`;
  }

  public addAppointment(): void {
      const initialState = {
        title: "Add Appointment",
    }

    this.modalRef =  this.modalService.show(AddAppointmentModalComponent,{
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    })

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if(data === 'add'){
           this.appointmentService.modalEvent.emit(true);
        }
      },
      (err) => {
        return false;
      }
    ); 

 }

 appointmentSettings() {
  this.router.navigate(["/appointment/settings"]);
 }

 appointmentHistory() {
  this.router.navigate(["/appointment/appointment-history"]);
 }

 handleSuccessEvent($event) {
  }

  public activeTab(tabValue) {
    this.selectedTab =  tabValue;
    this.readCountApprovalAppointments();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
