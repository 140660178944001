import { Component, OnInit } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { FreeTrialExpiredComponent } from "../../components/free-trial-expired/free-trial-expired.component";
import { CostBreakdownComponent } from "../../components/cost-breakdown/cost-breakdown.component";
import { Router } from "@angular/router";
import { BranchManagementService } from "src/app/modules/branch-management/services/branch-management.service";
import { SUBSCRIPTION_PLAN } from "src/app/shared/const/plan";
import { DataService } from "src/app/core/services/data.service";
import { BillingService } from "../../services/billing.service";
import { CancelSubscriptionModalComponent } from "../../components/cancel-subscription-modal/cancel-subscription-modal.component";
import { BusinessService } from "src/app/modules/auth/services/business.service";

@Component({
  selector: "app-billing-page",
  templateUrl: "./billing-page.component.html",
  styleUrls: ["./billing-page.component.scss"],
})
export class BillingPageComponent implements OnInit {
  isLoading = false;
  title = "Billing";
  subTitle = "Manage your subscription and invoices.";
  subscription: any;
  subscriptionData: any;
  subscribed = false;
  upgraded = false;
  withAddOns = false;
  withTopUps = false;
  topUps: any = [
    {
      topUpName: "SMS",
      topUpValueName: "SMS Credits",
      topUpValueUsed: 0,
      topUpValueLimit: 1000,
    },
  ];
  // invoiceHistoryData: any = [];
  invoiceHistoryData: any = [
    {
      _id: 1,
      soaNo: "201911090002",
      period: "March 22, 2022",
      amount: 5000,
    },
    {
      _id: 2,
      soaNo: "201911090003",
      period: "April 23, 2022",
      amount: 8000,
    },
    {
      _id: 3,
      soaNo: "201911090004",
      period: "May 24, 2022",
      amount: 7000,
    },
  ];
  selectedBranch = this.branchService.getSelectedBranch();
  isSubmitting = false;
  isSubmitting2 = false;
  invoiceList: any;
  featuresCounts: any;
  featureLimits = SUBSCRIPTION_PLAN.professional;
  subscriptionPlan: any;
  isExpired = false;

  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    private router: Router,
    private billingService: BillingService,
    private branchService: BranchManagementService,
    private dataService: DataService,
    private businessService: BusinessService
  ) {}

  ngOnInit(): void {
    // this.isLoading = true;
    // if(this.subscriptionData){
    //   this.isLoading = false;
    // }

    this.getBillingDetails();
    this.getInvoiceList();
  }

  getAmountText(data) {
    if (data) {
      return data.slice(0, -2);
    }
  }
  getBillingDetails() {
    this.billingService
      .getBillingDetails(this.selectedBranch.businessId)
      .subscribe(
        (data) => {
          this.featuresCounts = data.data.featuresCounts;
          this.subscription = data.data.subscription;
          this.subscriptionData = data.data;

          if (this.subscription) {
            this.subscribed = true;
            const address = this.subscriptionData.customer.address;

            this.subscriptionPlan = {
              paymentMethod: this.subscriptionData.paymentMethod
                ? this.subscriptionData.paymentMethod
                : null,
              plan: this.subscription.plan.metadata.plan,
              amount: this.subscription.plan.amount,
              startDate: this.subscription.current_period_start * 1000,
              endDate: this.subscription.ended_at
                ? this.subscription.ended_at * 1000
                : this.subscription.current_period_end * 1000,
              customerId: this.subscriptionData.customerId,
              subscriptionId: this.subscriptionData.subscriptionId,
              billingAddress: `${address.line1 ? address.line1 : ""} ${
                address.line2 ? address.line2 : ""
              } ${address.city ? address.city : ""} ${
                address.state ? address.state : ""
              } Philippines`,
              interval: this.subscriptionData.subscription.plan.interval,
              productId: this.subscriptionData.subscription.plan.product,
              cancelled:
                this.subscriptionData.subscription.cancel_at ||
                this.subscriptionData.subscription.canceled_at
                  ? true
                  : false,
            };
            this.dataService.setSubscriptionPlan(this.subscriptionPlan);
          } else {
            this.subscribed = false;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  billingSettings() {
    this.router.navigate(["/settings"], {
      queryParams: { type: "billing" },
    });
  }

  subscribeToPlan() {
    // for free trial expired modal testing only
    // const initialState = {
    //   title: "The free trial period for 10 days has expired",
    //   subTitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim risus nec ornare donec arcu, turpis morbi orci donec."
    // };
    // this.modalRef = this.modalService.show(FreeTrialExpiredComponent, {
    //   initialState: initialState,
    //   class: "modal-dialog-centered modal-md",
    //   ignoreBackdropClick: true,
    //   keyboard: false,
    // });
    // this.subscribed = true;
  }

  upgradePlan() {
    this.router.navigate(["/subscription"]);
  }

  cancelPlan() {
    const initialState = {
      title: "Are you sure you want to cancel?",
      businessId: this.selectedBranch.businessId,
      subscriptionPlan: this.subscriptionPlan,
    };

    this.modalRef = this.modalService.show(CancelSubscriptionModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        this.getBillingDetails();
      },
      (err) => {
        console.log("payment", err);
        return false;
      }
    );
  }

  seeCostBreakDown() {
    const initialState = {
      title: "Cost Breakdown",
      subTitle: "Manage your subscription and invoices",
    };

    this.modalRef = this.modalService.show(CostBreakdownComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  manageSubscription(showSubmit?) {
    const daysRemaining = this.businessService.getDaysRemaining(
      this.subscriptionPlan
        ? this.subscriptionPlan?.startDate
        : this.subscriptionData?.createdAt,
      this.subscriptionPlan
        ? this.subscriptionPlan?.endDate
        : this.subscriptionData?.expiredAt,
        this.subscriptionPlan
        ? this.subscriptionPlan?.cancelled
        : true,
    );

    if (Math.round(daysRemaining.consumedHours) <= 0) {
      this.isExpired = true;
    }

    if (this.subscribed && (this.subscriptionPlan.cancelled || showSubmit) && !this.isExpired) {
      this.isSubmitting = showSubmit;
      this.isSubmitting2 = !showSubmit;
      this.billingService
        .createCustomerPortal(this.selectedBranch.businessId, {})
        .subscribe(
          (data) => {
            this.isSubmitting = false;
            this.isSubmitting2 = false;
            window.location.href = data.data.url;
          },
          (error) => {
            this.isSubmitting = false;
            this.isSubmitting2 = false;
            console.log(error);
          }
        );
    } else {
      this.upgradePlan();
    }
  }

  getInvoiceList() {
    this.billingService
      .getInvoiceList(this.selectedBranch.businessId)
      .subscribe(
        (data) => {
          this.invoiceList = data.data;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  showInvoice(url) {
    window.open(url, "_blank");
  }
}
