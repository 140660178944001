import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/core/auth/auth.service";
import { DataService } from "src/app/core/services/data.service";
import { defaultAvatar } from "src/app/core/services/global";
import { BranchManagementService } from "src/app/modules/branch-management/services/branch-management.service";
import { EditAccountModalComponent } from "../../components/edit-account-modal/edit-account-modal.component";
import { RemoveAccountModalComponent } from "../../components/remove-account-modal/remove-account-modal.component";
import { ResetPasswordModalComponent } from "../../components/reset-password-modal/reset-password-modal.component";
import { TeamManagementService } from "../../services/team-management.service";
import * as moment from "moment";

@Component({
  selector: "app-profile-page",
  templateUrl: "./profile-page.component.html",
  styleUrls: ["./profile-page.component.scss"],
  providers: [DatePipe],
})
export class ProfilePageComponent implements OnInit, OnDestroy {
  pageDetails = [
    { type: "main", name: "Team", routerLink: ["/team-management"] },
    { type: "current", name: "View Profile", routerLink: null },
  ];
  selectedDate: any;
  dateFilterLabel: any;
  weekNumber: string;
  todayDate: any;
  selectedDateFilterTitle: string;
  weekDateLabel: string;
  monthDateLabel: string;

  performanceDataLabel: string;
  profileMetrics: any;

  accountId: string;
  loggedInAccountId = this.authService.accountId$;
  profileData: any;
  defaultAvatar = defaultAvatar;

  branchData: any;
  subscriptions = new Subscription();
  branchId: string;

  constructor(
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private teamService: TeamManagementService,
    private router: Router,
    private dataService: DataService,
    private authService: AuthService,
    private branchService: BranchManagementService
  ) {}

  ngOnInit() {
    this.accountId = this.activatedRoute.snapshot.paramMap.get("accountId");
    this.branchData = this.branchService.getSelectedBranch();
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe(async (res) => {
        if (this.branchData.branchId != res?._id) {
          this.router.navigate(["/team-management"]);
          return
        } else {
          await this.handleBranchChange(res);
          await this.getProfileData();
          this.selectDate("today");
        }
      })
    );
  }

  async handleBranchChange(res: any): Promise<void> {
    this.branchId = res ? res._id : this.dataService.currentBranch$.branchId;
  }

  async getProfileData() {
    this.subscriptions.add(
      this.teamService
      .getProfileData(this.accountId, this.branchId)
      .subscribe((res) => {
        this.profileData = res.data;
        // console.log("PROFILE DATA", res);
      })
    )
  }

  async getProfileMetrics() {
    this.subscriptions.add(
      this.teamService
      .getProfileMetrics(this.selectedDate, this.branchId, this.accountId)
      .subscribe((res) => {
        this.profileMetrics = res.data;
        // console.log(
        //   "PROFILE METRICS",
        //   new Date(this.profileMetrics.servingTime)
        // );
      })
    )
  }

  getAvgWaitingTimeFormat(data) {
    let value = "Evaluating...";
    if (data) {
      if (data.days > 0) {
        value =
          data.days +
          " day" +
          (data.days > 1 ? "s " : " ") +
          "and " +
          data.hours +
          " hr" +
          (data.hours > 1 ? "s" : "");
      } else if (data.hours > 0) {
        value =
          data.hours +
          " hr" +
          (data.hours > 1 ? "s " : " ") +
          "and " +
          data.minutes +
          " min" +
          (data.minutes > 1 ? "s" : "");
      } else if (data.minutes > 0) {
        value =
          data.minutes +
          " min" +
          (data.minutes > 1 ? "s " : " ") +
          "and " +
          data.seconds +
          " sec";
      } else if (data.seconds > 0) {
        value = data.seconds + " sec";
      }
    }

    return value;
  }

  getAvgTimeFormat(data) {
    const y = new Date(data).getFullYear();
    const m = new Date(data).getMonth();
    const d = new Date(data).getDate();
    const h = new Date(data).getHours();
    const mm = new Date(data).getMinutes();
    const s = new Date(data).getSeconds();
    return moment([y, m, d, h, mm, s]).fromNow(true);
  }

  getChangePasswordLabel() {
    if (this.accountId === this.authService.accountId$) {
      return "Change Password";
    }
    return "Reset Password";
  }

  public async selectDate(date) {
    // console.log("selected date", date);
    this.selectedDate = date;
    this.selectedDateFilterTitle = this.selectedDate.replace("-", " ");

    let dateFilter = "";
    if (date === "today") {
      dateFilter = "today";
      this.performanceDataLabel = "Today's";
    } else if (date === "this-week") {
      dateFilter = "current-week";
      this.performanceDataLabel = "This Week's";
    } else if (date === "this-month") {
      dateFilter = "current-month";
      this.performanceDataLabel = "This Month's";
    }

    this.changeDateFilterLabel();
    await this.getProfileMetrics();
  }

  private changeDateFilterLabel() {
    // console.log("changeDateFilterLabel", this.selectedDate);
    switch (this.selectedDate) {
      case "today":
        {
          this.getToday();
        }
        break;
      case "this-week":
        {
          this.getWeek();
        }
        break;
      case "this-month":
        {
          const todayDate = Date.now();
          this.monthDateLabel = this.datePipe.transform(todayDate, "MMMM yyyy");
        }
        break;
    }
  }

  private getToday() {
    setInterval(() => {
      const todayDate = Date.now();
      this.dateFilterLabel = this.datePipe.transform(
        todayDate,
        "MMMM dd, yyyy — hh:mm aa"
      );
    }, 1);
  }

  private getWeek() {
    const curr = new Date(); // get current date
    const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    const last = first + 6; // last day is the first day + 6
    const firstday = new Date(curr.setDate(first)).toUTCString();
    // const lastday = new Date(curr.setDate(last)).toUTCString();
    const lastday = new Date(firstday).setDate(
      new Date(firstday).getDate() + 6
    );

    this.weekNumber = `Week ${this.getWeekNum()}`;
    this.weekDateLabel = `${this.datePipe.transform(
      firstday,
      "MMMM dd"
    )} - ${this.datePipe.transform(lastday, "MMMM dd, yyyy")}`;
  }

  private getWeekNum() {
    let curr = new Date();
    let onejan = new Date(curr.getFullYear(), 0, 1);
    let today = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate());
    let dayOfYear = (today.getTime() - onejan.getTime() + 1) / 86400000;
    return Math.ceil(dayOfYear / 7);
  }

  removeAccount() {
    const initialState = {
      title: "Remove Account",
      profileData: this.profileData,
      branchId: this.branchId, // add branchId to supply component needs
    };
    this.modalRef = this.modalService.show(RemoveAccountModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        console.log("delete account", data);
        this.router.navigate(["/team-management"]);
      },
      (err) => {
        console.log("delete account", err);
        return false;
      }
    );
  }

  resetPassword() {
    const initialState = {
      title: this.getChangePasswordLabel(),
      profileData: this.profileData,
    };
    this.modalRef = this.modalService.show(ResetPasswordModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  editProfile() {
    const initialState = {
      title: "Edit Profile",
      profileData: this.profileData,
    };
    this.modalRef = this.modalService.show(EditAccountModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        // console.log("edit account", data);
        this.profileData.firstName = data.firstName;
        this.profileData.lastName = data.lastName;
        this.profileData.contactNo = data.contactNo;
        this.profileData.avatarUrl = data.avatarUrl;
      },
      (err) => {
        console.log("edit account", err);
        return false;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
