import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DataService } from 'src/app/core/services/data.service';
import { AppointmentSettingsService } from '../../services/appointment-settings.service';

@Component({
  selector: 'app-appointment-settings-page',
  templateUrl: './appointment-settings-page.component.html',
  styleUrls: ['./appointment-settings-page.component.scss']
})
export class AppointmentSettingsPageComponent implements OnInit, OnDestroy {

  subscriptions = new Subscription();
  accountData = this.dataService.accountData$;
  selectedBranch = this.dataService.currentBranch$;

  pageDetails = [
    { type: "main", name: "Appointment", routerLink: ["/appointment"] },
    { type: "current", name: "Settings", routerLink: null },
  ];

  selectedNav: string = "";
  settingsNav: any = {
    appointmentSetup: false,
  };

  services: Array<any>;
  selectedService: any;
  enableAppointmentService: boolean = false;
  showPriceRate: boolean = false;

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private appointmentSettingsService: AppointmentSettingsService
  ) { }

  ngOnInit(): void {
    this.gotoNav("appointmentSetup");
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe((res) => {
        this.selectedBranch = res ? res : this.selectedBranch;
        this.getServicesList();
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getServicesList(): void {
    this.subscriptions.add(
      this.appointmentSettingsService.getServicesList(this.selectedBranch.branchId)
        .subscribe(async (res) => {
          this.services = res.data;
        })
    )
  }

  getService(serviceId): void {
    this.selectedService = this.services.filter((service) => {
      return service._id === serviceId;
    })[0];
  }

  gotoNav(nav: any){
    this.selectedNav = nav;
    Object.keys(this.settingsNav).forEach(key=>{
      this.settingsNav[key] = false;
    });
    this.settingsNav[nav] = true;

    if(nav != 'appointmentSetup'){
      this.getService(nav);
    }
  }

  updateSelectedService(service: any){
    this.selectedService.displayName = service.displayName;
    this.selectedService.description = service.description;
    this.selectedService.enableAppointment = service.enableAppointment;
  }
}
