export interface ServiceLists {
  _id: string,
  name: string
}

export function getServiceName(serviceLists: Array<ServiceLists>, serviceId: string) {
  let name = "";
  if (serviceId && serviceLists) {
    const service = serviceLists.find((x) => x._id === serviceId);
    if (service) {
      name = service.name;
    } else {
      name = "-";
    }
  }
  return name;
}
