import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import { debounceTime, distinctUntilChanged, finalize, map, takeUntil, tap, catchError} from 'rxjs/operators';
import { EditAppointmentModalComponent } from '../../component/edit-appointment-modal/edit-appointment-modal.component';
import { FilterData } from 'src/app/modules/monitoring/interfaces/monitoring.interface';
import { HttpParams } from "@angular/common/http";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataService } from 'src/app/core/services/data.service';
import { HttpService } from 'src/app/core/http/http.service';
import { AppointmentService } from 'src/app/modules/appointment/services/appointment.service';
import { DatePickerModalComponent } from "src/app/shared/components/date-picker-modal/date-picker-modal.component";
import { AppointmentHistoryDetailModalComponent } from '../../component/appointment-history-detail-modal/appointment-history-detail-modal.component';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { SMART_FORM_NAMES } from '../../enum/smart_form';
import { Subject, Subscription, throwError } from 'rxjs';
import { ICheckboxList, ICheckboxData } from '../../interface/appointment.interface';
import { AppointmentSettingsService } from '../../services/appointment-settings.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-history-appointment-page',
  templateUrl: './history-appointment-page.component.html',
  styleUrls: ['./history-appointment-page.component.scss']
})
export class HistoryAppointmentPageComponent implements OnInit,OnDestroy {
  itemsPerPage = 10;
  currentPage = 1;
  noOfEntries: number[] = [10, 20, 30];
  appointmentLists: any = [];
  isLoading: boolean = false;
  appointmentHistoryLists: any [] = [];
  appointmentHistoryPage: any;
  appointmentHistoryTotal: any;
  appointmentDate: string;
  isSelectClicked: boolean = false;
  isButtonClicked: boolean = false;
  selectedTimeslotLabel: string = 'Show all timeslot';
  historyViewAppointmentModal: boolean = true;
  isFocused: boolean = false;
  isFocusedCalendar: boolean = false;
  pageDetails = [
    { type: "main", name: "Appointment", routerLink: ["/appointment"] },
    { type: "current", name: "Appointment History", routerLink: null },
  ];
  searchString: string | null;
  selectedServices: number = 0;
  selectedStatuses: number = 0;
  selectedAccounts: number = 0;
  settingsLateTime: number = 0;
  selectedTimeslotValue: number;
  selectedService: Array<any> = [];
  selectedStatus: Array<any> = [];
  selectedStaffs: Array<any> = [];
  accountLists: Array<any> = [];
  servicesList: Array<any> = [];
  statusList: Array<any> = [];
  staffList: Array<any> = [];
  serviceCheckboxLists: Array<ICheckboxList> = [];
  statusCheckboxLists: Array<ICheckboxList> = [];
  accountCheckboxLists: Array<ICheckboxList> = [];
  subscriptions = new Subscription();
  appointmentHistoryListsForm: FormGroup = this.formBuild.group({
    search: null,
    timeslot: null,
    selectedTimeslot: null
  });
  selectedDateFilterLabel = "This Month";
  selectedDateFilter = "this-month";
  customDate1: string = "";
  customDate2: string = "";
  statusLists = [
    { value: "completed", label: "COMPLETED" },
    { value: "no-show", label: "NO SHOW" },
    { value: "rejected", label: "REJECTED" },
    { value: "canceled-by-staff", label: "CANCELED BY STAFF" },
    { value: "canceled-by-visitor", label: "CANCELED BY VISITOR" },
  ];
  dateFilter: Array<FilterData> = [
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "this-week", label: "This Week" },
    { value: "this-month", label: "This Month" },
    { value: "custom", label: "Custom" },
    // { value: "this-year", label: "This Year" },
  ];
  timeslotFilter: Array<any> = [];
  searchSubject: Subject<string> = new Subject();
  filterSubject: Subject<any> = new Subject();
  branchId: string = this.dataService.currentBranch$.branchId;
  isViewingFromEmail: boolean;
  emailAppointmentId: string;
  emailAppointmentData: any;
  ms8Hours = 28800000;

 private appointmentHistoryListSubjectAPI$ = new Subject<void>();
  constructor(
    private httpService: HttpService,
    private appointmentService: AppointmentService,
    private appointmentSettingService: AppointmentSettingsService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private dataService : DataService,
    private formBuild: FormBuilder,
    private elementRef: ElementRef,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute
  ) { 
    this.appointmentHistoryListsForm.controls['search']
    .valueChanges
    .pipe(debounceTime(500), distinctUntilChanged())
    .subscribe(
      (value: string) => {

        this.appointmentHistoryListsForm.patchValue({
          search: value
        })
        this.getAppointmentHistory();
      }
    )
  }

  ngOnInit() {
    this.isLoading= true;
    this.dataService.branchChange$.subscribe((res) => { 
      if(res) {
        this.branchId = res.branchId ?? this.branchId;
        this.getSettingsData();
        this.setStatusData(this.statusLists);
        this.getServiceListsData();
        this.getAccounts();
        this.readSeachAppointment();
        this.readFilterAppointment();
        this.readHistoryActiveRouteParams();
        this.isLoading = false;
      }
    })
  }

  readSeachAppointment() {
    this.subscriptions.add(
      this.searchSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchValue) => {
        this.getSettingsData();
      })
    );
  }

  readFilterAppointment() {
    this.filterSubject
    .pipe(debounceTime(300), distinctUntilChanged())
    .subscribe((filterValue) => {
      this.getSettingsData();
    });
  }

  readHistoryActiveRouteParams() {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params)=>{
        if(params.appointmentId){
          this.isViewingFromEmail = true;
          this.emailAppointmentId = params.appointmentId;
          this.appointmentService.getHistoryAppointmentDetails(this.branchId, this.emailAppointmentId)
            .subscribe((res)=>{
              const options: Intl.DateTimeFormatOptions = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              };
              this.emailAppointmentData = res.data;
              this.emailAppointmentData.formattedDate = new Date(this.emailAppointmentData.timeslot).toLocaleDateString('en-US', options);
              // this.emailAppointmentData.formattedDate = new Date(this.emailAppointmentData.timeslot + this.ms8Hours);
              this.emailAppointmentData.formattedTimeslot = this.getFormattedTime(this.emailAppointmentData.selectedTimeslot + this.ms8Hours);
              this.emailAppointmentData.status = (res.data.status.split('-').join(' ')).toUpperCase();
              this.viewAppointment(this.emailAppointmentData);
            })
        }
      })
    )
  }

  private formatDateWithourHours(dateCreated: number): string {
    const date = new Date(dateCreated);
    const formattedDate = this.datePipe.transform(date, 'MM/dd/yyyy, EEE');
    return formattedDate;
  }

  selectDate() {
    this.isSelectClicked = !this.isSelectClicked;
    this.isFocusedCalendar = !this.isFocusedCalendar;
  }

  selectedTimeslotFilter() {
    this.isSelectClicked = !this.isSelectClicked;
    this.isFocused = !this.isFocused;
  }

  timeMili(): number {
    const hours = 0;
    const millisecondsInHour = 60 * 60 * 1000;
    return hours * millisecondsInHour;
  }

  public getAppointmentHistory():void {
    this.isLoading = true;
    const httpQueryParams = this.httpService.convertFormGroupToParams(this.appointmentHistoryListsForm)
    const appointmentHistoryParams = this.appointmentTodayParams+'&'+httpQueryParams;
    this.subscriptions.add(
      this.appointmentService
      .getHistoryAppointmentList(this.branchId, appointmentHistoryParams)
      .pipe(
        takeUntil(this.appointmentHistoryListSubjectAPI$),
        map((res) => res),
        tap((data) => {
          this.appointmentHistoryLogic(data);
        }),
        catchError((error) => {
          console.log("error on fetching queue history lists", error);
          return throwError(error);
        }),
        finalize(() => {
          //
        })
      )
      .subscribe()
    )
   }

   appointmentHistoryLogic(appointmentHistory) {
    this.isLoading = false;
    let dataAppointment = [];

    appointmentHistory.data.forEach( appointment => {
      const settingTimeLate = !this.settingsLateTime || isNaN(this.settingsLateTime) ? 0 : this.settingsLateTime; 
      const getDateAppointment = new Date(appointment.formattedDate);
      const dateMili = getDateAppointment.getTime();
      const date = new Date((appointment.timeslot+this.timeMili()));
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;

      // Use Angular's date pipe to format minutes
      const formattedMinutes = ('0' + minutes).slice(-2);

      let formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`; 

      appointment.formattedDateWithDay = this.formatDateWithourHours(dateMili);

      appointment.formattedTimeslot = formattedTime; 

      if( appointment.status === 'CANCELED BY-STAFF'){
        appointment.status = 'CANCELED BY STAFF'
      } else if(appointment.status === 'CANCELED BY-VISITOR'){
        appointment.status = 'CANCELED BY VISITOR'
      }

      for (const smartForm of appointment.visitorDetails) {
        switch (smartForm.fieldName) {
          case SMART_FORM_NAMES.NAME:
            appointment.firstname = smartForm.value;
            break;
          case SMART_FORM_NAMES.MOBILENO:
            appointment.mobileNumber = smartForm.value;
            break;
          case SMART_FORM_NAMES.PRIORITY:
          appointment.priority = smartForm.value;
            break;
          case SMART_FORM_NAMES.VISITORS:
            appointment.visitors = smartForm.value;
            break;
          default:
            break;
        }
      } 

      dataAppointment.push(appointment);   
    }); 

    this.appointmentHistoryLists = dataAppointment;
    this.appointmentHistoryPage = appointmentHistory.pages;
    this.appointmentHistoryTotal = appointmentHistory.total;
   }

   public getAppointmentTimeslotList():void {
    this.isLoading = true;
    this.subscriptions.add(
      this.appointmentService
      .getHistoryAppointmentListData(this.branchId)
      .pipe(
        takeUntil(this.appointmentHistoryListSubjectAPI$),
        map((res) => res),
        tap((data) => {
          this.appointmentTimeslotLogic(data);
        }),
        catchError((error) => {
          console.log("error on fetching queue history lists", error);
          return throwError(error);
        }),
        finalize(() => {
          //
        })
      )
      .subscribe()
    )
   }

  appointmentTimeslotLogic(appointmentTimeslot) {
    this.isLoading = false;
    let dataAppointment = [];
    
    appointmentTimeslot.data.forEach( appointment => {
      const settingTimeLate = !this.settingsLateTime || isNaN(this.settingsLateTime) ? 0 : this.settingsLateTime; 
      const getDateAppointment = new Date(appointment.formattedDate);
      const dateMili = getDateAppointment.getTime();
      const date = new Date((appointment.timeslot+this.timeMili()));
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;

      // Use Angular's date pipe to format minutes
      const formattedMinutes = ('0' + minutes).slice(-2);

      let formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`; 

      appointment.formattedDateWithDay = this.formatDateWithourHours(dateMili);

      appointment.formattedTimeslot = formattedTime; 

      dataAppointment.push(appointment);   
    }); 

    this.getTimeslotFilter(dataAppointment);
  } 

  getTimeslotFilter(appointmentList) {
    const appointmentListFilter = appointmentList.map(appointment => {
      return {
          label: appointment.formattedTimeslot,
          value: appointment.selectedTimeslot && appointment.selectedTimeslot !== undefined 
          ? appointment.selectedTimeslot : 0  
      }
    })
    this.removeDuplicates(appointmentListFilter)
   
  }

  selectDateDropdown(list) {
    if (list.value == "custom") {
      this.selectCustomDateFilter();
    } else {
      this.selectedDateFilter = list.value;
      this.selectedDateFilterLabel = list.label;
      this.filterSubject.next(this.selectedDateFilter);
      this.getSettingsData()
    }
    this.isSelectClicked = !this.isSelectClicked;
    this.isFocusedCalendar = false;
  }


  selectTimeslotDropdown(appointmentData) {
    this.selectedTimeslotValue = appointmentData.value;
    this.selectedTimeslotLabel = appointmentData.label
    this.appointmentHistoryListsForm.patchValue({
      selectedTimeslot: this.selectedTimeslotValue,
    }) 
    this.filterSubject.next(this.selectedTimeslotValue);
    this.isFocused = false;
  }

  removeDuplicates(appointmentListFilter): void {
    const uniqueAppointmentTime = appointmentListFilter.filter(
      (value, index, self) =>
        self.findIndex((item) => item.value === value.value && item.label === value.label) === index
    );
    this.timeslotFilter = this.sortArrayByTime(uniqueAppointmentTime); 
    this.timeslotFilter.push({ value: null, label: "Show all timeslot"});
  }

  sortArrayByTime(array: any[]): any[] {
    return array.sort((a, b) => {
        return a.value - b.value;
    });
  }

   async getAccounts() {
    this.subscriptions.add(
      this.appointmentService
        .getTeamList("", this.branchId)
        .pipe(
          takeUntil(this.appointmentHistoryListSubjectAPI$),
          map((res) => res.data),
          tap((data) => {

            this.accountLists = data.map((element) => {
              return {
                _id: element._id,
                name: element.firstName + " " + element.lastName,
                avatarUrl: element.avatarUrl,
              };
            });
            this.setAccountData(this.accountLists);
          }),
          catchError((error) => {
            console.log("error on fetching account lists", error);
            return throwError(error);
          }),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe()
    );
  }

  getSettingsData() {
     this.subscriptions.add(
      this.appointmentSettingService.getSettings(this.branchId).subscribe(
        (res) => {
          if(res) {
             res.data;
            this.settingsLateTime = res.data.lateAppointmentTimeRange.milliseconds ? res.data.lateAppointmentTimeRange.milliseconds : 0 ;
            this.getAppointmentHistory();
            this.getAppointmentTimeslotList();
          }
        },
        (error) => {
          console.log(error)
        }
      )
    );
  }
  
  
  public viewAppointment(appointment):void {

    if(!this.historyViewAppointmentModal) {
      this.historyViewAppointmentModal = true;
      }else {
          const initialState = {
            title: "View Appointment Details",
            data: appointment
        }

        this.modalRef =  this.modalService.show(AppointmentHistoryDetailModalComponent,{
          initialState: initialState,
          class: "modal-dialog-centered modal-md",
          ignoreBackdropClick: true,
          keyboard: false,
        })
      }
  }  

  getServiceListsData() {
    const date = new Date();
    this.setDateLabel(date);
    this.subscriptions.add(
      this.appointmentService.getServiceList(this.branchId).subscribe(
         (res) => {
          if(res) {
            this.servicesList = res.data.map((element) => ({
              _id: element._id,
              name: element.displayName,
            }));
            this.setServiceData(this.servicesList);
          }
        },
        (error) => {
          console.log(error)
        }
    )
    )
}

setAccountData(accountData: any) {
  const accountsLists = accountData.map((element) => {
    return {
      value: element._id,
      label: element.name,
    };
  });
  this.accountCheckboxLists = this.addCheckedProperty(accountsLists);
}

  get appointmentTodayParams() {
    let params = new HttpParams();
    params = params.append("limit", this.itemsPerPage.toString());
    const offset = this.currentPage * this.itemsPerPage - this.itemsPerPage;
    params = params.append("offset", offset.toString());
    if (this.searchString) {
      params = params.append("search", this.searchString);
    }
    if (this.searchString) {
      params = params.append("search", this.searchString);
    }
    if (this.selectedStatus.length > 0) {
      params = params.append("status", this.selectedStatus.join(","));
    }
    if (this.selectedService.length > 0) {
      params = params.append("serviceId", this.selectedService.join(","));
    }
    if (this.accountLists.length > 0) {
      params = params.append("accountId", this.selectedStaffs.join(","));
    }
    if (this.selectedDateFilter === "custom") {
      params = params.append(
        "startDate",
        new Date(this.customDate1).setHours(0, 0, 0).toString()
      );
      params = params.append(
        "endDate",
        new Date(this.customDate2).setHours(23, 59, 59).toString()
      );
    } else {
      params =   this.selectedTimeslotValue ? null : params.append("dateFilter",this.selectedDateFilter);
    }

    return params;
  }

  get isSearch() {
    if (this.searchString) {
      return true;
    }
    if (this.selectedStatus.length > 0) {
      return true;
    }
    if (this.selectedService.length > 0) {
      return true;
    }
  }

  dropdownsVisible: { [key: string]: boolean } = {};
  @ViewChild("serviceDropdownRef", { static: false })
  serviceDropdown: ElementRef<HTMLDivElement>;
  @ViewChild("statusDropdownRef", { static: false })
  statusDropdown: ElementRef<HTMLDivElement>;
  @ViewChild("staffDropdownRef", { static: false })
  staffDropdown: ElementRef<HTMLDivElement>;
  

  setServiceData(serviceData: any) {
    const serviceLists = serviceData.map((element) => {
      return {
        value: element._id,
        label: element.name,
      };
    });
  
    this.serviceCheckboxLists = this.addCheckedProperty(serviceLists);
  }

  setStatusData(statusData: any) {
    this.statusCheckboxLists = this.addCheckedProperty(statusData);
  }

  addCheckedProperty(contents: Array<ICheckboxList>) {
    if (contents) {
      const newContents = contents.map((content) => ({
        ...content,
        checked: false, // for checkbox
      }));
      return newContents;
    }
  }

  public changeCheckboxList($event: ICheckboxData) {
    if ($event) {
      const key = $event?.key;
      const selected = $event.checkedArray;
      switch (key) {
        case "service":
          this.selectedServices = selected.length;
          this.selectedService = [];
          if (this.selectedServices > 0) {
            selected.forEach((element) => {
              this.selectedService.push(this.servicesList[element]._id);
            });
          }
          this.filterSubject.next(this.selectedServices);
          break;
        case "status":
          this.selectedStatuses = selected.length;
          this.selectedStatus = [];
          if (this.selectedStatuses > 0) {
            selected.forEach((element) => {
              this.selectedStatus.push(this.statusLists[element].value);
            });
          }
          this.filterSubject.next(this.selectedStatus);
          break;
          case "staff":
            this.selectedAccounts = selected.length;
            this.selectedStaffs = [];
            if (this.selectedAccounts > 0) {
              selected.forEach((element) => {
                this.selectedStaffs.push(this.accountLists[element]._id);
              });
            }

            this.filterSubject.next(this.selectedStaffs);
            break;
        default:
          break;
      }

    }
  }

  toggleDropdown(dropdownId: string): void {
    // Close all other dropdowns before opening the current dropdown
    Object.keys(this.dropdownsVisible).forEach((id) => {
      if (id !== dropdownId) {
        this.closeDropdown(id);
      }
    });

    if (this.isDropdownVisible(dropdownId)) {
      this.closeDropdown(dropdownId);
    } else {
      this.openDropdown(dropdownId);
    }
    this.isFocused = false;
    this.isButtonClicked = false;
    this.isSelectClicked = false;
  }

  isDropdownVisible(dropdownId: string): boolean {
    return this.dropdownsVisible[dropdownId] ?? false; // Return the visibility state for the given dropdown ID
  }

  openDropdown(dropdownId: string): void {
    // Close all other dropdowns before opening the current dropdown
    Object.keys(this.dropdownsVisible).forEach((id) => {
      if (id !== dropdownId) {
        this.closeDropdown(id);
      }
    });

    this.dropdownsVisible[dropdownId] = true;
  }

  closeDropdown(dropdownId: string): void {
    this.dropdownsVisible[dropdownId] = false; // Set the visibility state for the given dropdown ID to false
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent): void {
    const clickedElement = event.target as HTMLElement;
    const dropdownDiv =
      this.elementRef.nativeElement.querySelector(".date-dropdown");
    const dropdownAppointmentDiv =  this.elementRef.nativeElement.querySelector(".date-appointment-dropdown");
    if (!dropdownDiv?.contains(clickedElement)) {
      if (this.isFocused === true) {
        this.isFocused = false;
      }
    }
    if (!dropdownAppointmentDiv?.contains(clickedElement)) {
      if (this.isFocusedCalendar === true) {
        this.isFocusedCalendar = false;
      }
    }
    for (const dropdownId in this.dropdownsVisible) {
      if (this.dropdownsVisible.hasOwnProperty(dropdownId)) {
        const dropdownRef = this.elementRef.nativeElement.querySelector(
          `#${dropdownId}`
        );
        const dropdownMenuRef = this.elementRef.nativeElement.querySelector(
          `#${dropdownId}-menu`
        );
        if (dropdownRef && dropdownRef.contains(clickedElement)) {
          return;
        }
        if (dropdownMenuRef && dropdownMenuRef.contains(clickedElement)) {
          return;
        }
      }
    }
    this.dropdownsVisible = {};
  }
  
  
  public bookAgain(appointment): void {
    this.historyViewAppointmentModal = false;
    const initialState = {
      title: "Book Appointment Again",
      isBookingAgain: true,
      data: appointment
    }

    this.modalRef = this.modalService.show(EditAppointmentModalComponent,{
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    });

  } 

  public setDateLabel(date: Date) {
    
  }


  public pageChanged($event) {
    this.currentPage = $event.page;
    this.filterSubject.next(this.currentPage);
    this.getAppointmentHistory();
  }

  public jumpToPage(event) {
    this.currentPage = +event
  }

  getNumberArray(num: number, offset: number = 0): number[] {
    if (num > 0) {
        return Array(num).fill(0).map((x, i) => i + offset);
    } else {
        return [1]
    }
}

  public entriesChanged(event) {
    this.appointmentHistoryListsForm.patchValue({
      limit: event
    });
     
    this.getAppointmentHistory();
  }

  public selectedDateChanged(list) {
    if (list.value == "custom") {
      this.selectCustomDateFilter();
    } else {
      this.selectedDateFilter = list.value;
      this.selectedDateFilterLabel = list.label;
      this.filterSubject.next(this.selectedDateFilter);
      this.getSettingsData();
    }
    this.isSelectClicked = !this.isSelectClicked;
    this.isFocused = false;
  }

  selectCustomDateFilter() {
    const initialState = {
      hasMinDate: false,
      datePickerType: "range",
    };
    this.modalRef = this.modalService.show(DatePickerModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md2",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.selectedDateRange.subscribe(
      (data) => {
        if (data) {
          if (data == "cancel") {
            //
          } else {
            if (data[0] && data[1]) {
              this.selectedDateFilter = "custom";
              this.customDate1 = (data[0] as Date).toLocaleDateString("en-US");
              this.customDate2 = (data[1] as Date).toLocaleDateString("en-US");
              this.selectedDateFilterLabel = this.customDate1 + " - " + this.customDate2;

              this.filterSubject.next(this.selectedDateFilter);
              this.getSettingsData();
            }
          }
        }
      },
      (err) => {
        return false;
      }
    );
  }

  getFormattedTime(milliseconds: number){
    let hours = Math.floor(milliseconds/(1000 * 60 * 60));
    let minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const ampm = `${hours < 12 ? "AM" : "PM"}`;
    if(hours>12){
        hours = hours - 12;
    }
    if(minutes < 10){
        return `${hours}:0${minutes} ${ampm}`;
    }
    return `${hours}:${minutes} ${ampm}`;
  }

  ngOnDestroy(): void {
    this.appointmentHistoryListSubjectAPI$.next();
    this.appointmentHistoryListSubjectAPI$.complete();
    this.subscriptions.unsubscribe();
  }
}
