import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/core/services/data.service';
import { AppointmentService } from '../../services/appointment.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { RemoveAppointmentModalComponent } from '../remove-appointment-modal/remove-appointment-modal.component';
import { EditAppointmentModalComponent } from '../edit-appointment-modal/edit-appointment-modal.component';
import * as moment from 'moment';
import { SMART_FORM_NAMES } from '../../enum/smart_form';
import { Subscription } from 'rxjs';
import { APPOINTMENT_SETTINGS } from 'src/app/modules/appointment/enum/smart_form';
import { IAccountServe } from '../../interface/appointment.interface';
@Component({
  selector: 'app-appointment-details-modal',
  templateUrl: './appointment-details-modal.component.html',
  styleUrls: ['./appointment-details-modal.component.scss']
})
export class AppointmentDetailsModalComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() isDisableCompleteBtn: boolean = false;
  @Input() subTitle: string;
  @Input() data: any;
  @Input() settingsFeaturesEnable: any;
  @Input() viewModal: boolean;
  @Input() forApproval: boolean;
  @Output() successEvent = new EventEmitter();
  newFormattedDate: string;
  dataTimeCreatedFormatted: string;
  dateTimeslotFormatted: string;
  subscription = new Subscription(); 
  formFields:Array<string> = ['name', 'mobileNo', 'email'];
  selectedCurrentBranch: any = this.dataService.currentBranch$;
  selectedAccountData: IAccountServe

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private dataService: DataService,
    private appointmentService: AppointmentService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.setDateLabel();
    this.accountList();
    this.dataTimeCreatedFormatted = this.formatDate(this.data.createdAt);
    this.dateTimeslotFormatted = this.formatDateWithourHours(this.data.timeslot);

    for (const smartForm of this.data.visitorDetails) {

      switch (smartForm.fieldName) {
        case SMART_FORM_NAMES.NAME:
          this.data.firstname = smartForm.value;   
          break;
        case SMART_FORM_NAMES.MOBILENO:
          this.data.mobileNumber = smartForm.value;
          break;
        case SMART_FORM_NAMES.PRIORITY:
          this.data.priority = smartForm.value ? "Yes" : "No";
          break;
        case SMART_FORM_NAMES.VISITORS:
          this.data.visitors = smartForm.value;
          break;

        default:
          break;
      }
    };

  }


  private formatDate(dateCreated: number): string {
    const date = new Date(dateCreated);
    const formattedDate = this.datePipe.transform(date, 'EEE, MM/dd/yyyy - h:mm a');
    return formattedDate;
  }

  private formatDateWithourHours(dateCreated: number): string {
    const date = new Date(dateCreated);
    const formattedDate = this.datePipe.transform(date, 'EEE, MM/dd/yyyy');
    return formattedDate;
  }

  setDateLabel() {
    this.newFormattedDate = moment(this.data.formattedDate).format("dddd, MMMM Do YYYY")
  }

  Remove() {
    this.modalRef.hide();

    const initialState = {
      title: "Remove appointment?",
      data: this.data
    }

    this.modalRef = this.modalService.show(RemoveAppointmentModalComponent,{
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if(data === 'deleted-move-history'){
          this.successEvent.emit("delete-detail-view")
        }
      },
      (err) => {
        return false;
      }
    );
  }

  Edit() {
    this.modalRef.hide();

    const initialState = {
      title: "Edit appointment?",
      data: this.data
    }

    this.modalRef = this.modalService.show(EditAppointmentModalComponent,{
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    });

  
        this.modalRef.content.successEvent.subscribe(
          (data) => {
            if(data === 'edit-modal'){
              this.successEvent.emit("edit-detail-modal")
            }
          },
          (err) => {
            return false;
          }
        )
    
  }

  accountList() {
    this.subscription.add(
      this.appointmentService
        .getTeamList("", this.selectedCurrentBranch.branchId)
        .subscribe(
          res => {
            for(let account of res.data) {
              if(account?._id === this.dataService.accountData$.data.accountId) {
                this.selectedAccountData = {
                  accountId: account._id,
                  email: account.email,
                  avatarUrl: account.avatarUrl,
                  roleLevel: account.roleLevel,
                  fullname: account.firstName + " " + account.lastName,
                } 
              }
            }
          },
          error => {
            console.log("error data");
            console.log(error);
          }
        )
    );
  }

  completeAppointment() {

    if(this.settingsFeaturesEnable.includes(APPOINTMENT_SETTINGS.SYNC_TO_QUEUE_LIST)) {
      const branchId = this.dataService.currentBranch$.branchId;

      const requestForm = {
        status: "completed",
        completedBy: this.selectedAccountData ?? {},
        remarks: "",
        queueGroupId: ""
      }

        this.appointmentService.moveAppointment(branchId,requestForm, this.data._id ).subscribe(
          (res) => {
            this.successEvent.emit('move-history');
            this.modalRef.hide();
            this.toastr.success(`New appointment added - ${this.data.referenceNo}`, "Success!",{
              toastClass: "ngx-toastr toast-success-custom",
            });
          },
          (error) => {
            console.log(error)
            this.toastr.error("Error encountered upon submitting ", "Error!");
          }
        )
    } else {
      this.modalRef.hide();
      this.successEvent.emit('appointment-modal')
    }
  }
  public reject() {
    this.modalRef.hide();

    const initialState = {
      title: "Reject appointment?",
      data: this.data
    }
  
    this.modalRef = this.modalService.show(RemoveAppointmentModalComponent,{
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  
    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if(data === 'deleted-move-history'){
          this.successEvent.emit('reject-appointment');
        }
      },
      (err) => {
        return false;
      }
    );
  
}

  approveDeclineAppointment() {
    const branchId = this.dataService.currentBranch$.branchId;

    const body = {
      approved: true,
      appointmentId: this.data._id
    }

    this.appointmentService.approveDeclineAppointment(branchId, body).subscribe(
      (res) => {
        this.successEvent.emit('approve-appointment');
        this.modalRef.hide();
        this.toastr.success(`Appointment Approved`, "Success!");
      },
      (error) => {
        console.log(error)
        this.toastr.error("Error encountered upon submitting ", "Error!");
      }
    )
  }
  edit() {
    this.modalRef.hide();
  
    const initialState = {
      title: "Edit Appointment",
      data: this.data
    }
  
    this.modalRef = this.modalService.show(EditAppointmentModalComponent,{
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  
     this.modalRef.content.successEvent.subscribe(
      (data) => {
        if(data === 'edit'){
          this.successEvent.emit('edit-appointment');
        }
      },
      (err) => {
        return false;
      }
    ); 
  }

  closeModal() {
    this.modalRef.hide();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
