import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-edit-payment-method',
  templateUrl: './edit-payment-method.component.html',
  styleUrls: ['./edit-payment-method.component.scss']
})
export class EditPaymentMethodComponent implements OnInit {
  title: any;
  subTitle: any;
  paymentMethodForm = this.fb.group({
    nameOnCard: ["", [Validators.required]],
    creditCardNo: ["", [Validators.required]],
    expirationDate: ["", [Validators.required]],
    securityCode: ["", [Validators.required]],
  });

  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

  get f() {
    return this.paymentMethodForm.controls;
  }

  updatePaymentMethod() {
    this.toastr.success("Billing payment method has been updated", "Success!");
    this.modalRef.hide();
    console.log(this.paymentMethodForm.value);
  }

}
