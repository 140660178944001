import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { ProcedureService } from "../../services/procedure.service";
import { trimString } from "src/app/shared/helpers/string.helpers";


@Component({
  selector: "app-add-edit-details-modal",
  templateUrl: "./add-edit-details-modal.component.html",
  styleUrls: ["./add-edit-details-modal.component.scss"],
})
export class AddEditDetailsModalComponent implements OnInit {
  @Output() successEvent = new EventEmitter();
  @Input() title: any;
  @Input() branchId: string;
  @Input() templateData: any;
  @Input() serviceLists: any;
  @Input() type: string;
  public procedureDetailsForm = this.fb.group({
    name: ["", [Validators.required]],
    description: [""],
  });

  selectedProcedureType: string = '';
  selectedOrigin: string[] = [];

  unTouched = true;

  public procedureTypes = [
    { value: "step-by-step", label: "Step-by-Step" },
    { value: "flexible", label: "Flexible" },
  ];

  isSubmitting = false;

  constructor(
    private procedureService: ProcedureService,
    public modalRef: BsModalRef,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.templateData && this.type === "edit") {
      this.procedureDetailsForm.controls.name.patchValue(
        this.templateData.displayName
      );
      this.procedureDetailsForm.controls.description.patchValue(
        this.templateData.description
      );
      this.selectedOrigin = this.templateData.originServices;

      this.selectedProcedureType = this.templateData.procedureType;
      this.procedureDetailsForm.valueChanges.subscribe(() => this.unTouched = false)
    }
  }

  get f() {
    return this.procedureDetailsForm.controls;
  }

  selectProcedureType(ev) {
    if (ev != 'Please Select Type') {
      this.selectedProcedureType = ev;
    }
  }

  selectServices(ev) {
    if (ev) {
      this.selectedOrigin = [];
      if (ev.length != 0) {
        this.selectedOrigin = (ev as any).map((x) => x.value);
      }
      this.unTouched = false;
    }
  }

  get isDisabled() {
    if (!this.procedureDetailsForm.valid || this.selectedProcedureType == '' || this.procedureDetailsForm.value.name.trim() == '' ||  
      this.selectedOrigin.length == 0 || this.isSubmitting || this.unTouched) {
      return true
    }
    return false
  }

  onSubmit() {
    this.isSubmitting = true;
    
    const body = {
      displayName: trimString(this.f.name.value),
      description: trimString(this.f.description?.value),
      procedureType: this.selectedProcedureType,
      originServices: this.selectedOrigin,
    };

    if (this.type === "add") {
      this.procedureService.addProcedureDetails(this.branchId, body).subscribe(
        (res) => {
          this.modalRef.hide();
          this.isSubmitting = false;
          this.router.navigate(["/apps/service-procedure/", res.result._id], {
            queryParams: { type: "new" },
          });
        },
        (error) => {
          console.log("error", error);
          this.isSubmitting = false;
        }
      );
    } else {
      body.procedureType = this.templateData.procedureType;
      this.procedureService.updateProcedureDetails(this.branchId, this.templateData._id, body).subscribe(
        (res) => {
          this.modalRef.hide();
          this.isSubmitting = false;
          this.toastr.success("Procedure Details has been updated", "Success!");
          this.successEvent.emit(res)
        },
        (error) => {
          console.log("error", error);
          this.isSubmitting = false;
        }
      );
    }
  }
}