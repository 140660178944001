import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss']
})
export class CheckboxListComponent implements OnInit, OnChanges {
  @Input() checkboxTitle: string;
  @Input() checkboxLists: Array<any>;
  @Input() checkedLists: Array<any>;
  @Input() isScrolling: boolean;
  @Input() isCheckboxDisabled: boolean;
  @Input() height: string;
  @Input() fixedValues: Array<any>;
  @Input() enabledSearch: boolean = false;
  @Input() searchData: string = '';
  @Input() serviceAssignWindows: boolean = false;
  @Output() changedCheckbox = new EventEmitter();
  checkboxForm: FormGroup;
  checkboxControls: Array<any>;
  filteredCheckbox: Array<any>;
  patchedLists: Array<any>;
  isConfirmDisabled: boolean;
  selectAll: boolean;
  disableSelectAll: boolean = false;
  allIsClicked: boolean;
  searchSubject: Subject<string> = new Subject();
  searchString: string;
  disabledCheckedArray: Array<any>;
  selectedWindows: Array<any> = [];

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) { }

  initForm() {
    this.checkboxControls = this.checkboxLists.map(c => false);
    this.checkboxForm = this.fb.group({
      checkboxFormArray: this.fb.array(this.checkboxControls)
    });
  }

  get f() { return this.checkboxForm.controls; }
  get cbFormArray() { return this.checkboxForm.get('checkboxFormArray') as FormArray; }

  selectAllClicked(ev) {
    if (ev) {
      this.f['checkboxFormArray'].patchValue(this.checkboxLists.map(c => true));
      this.change(ev);
    } else {
      this.f['checkboxFormArray'].patchValue(this.checkboxLists.map(c => false));
      this.change(ev);
    }
  }

  patchValue() {
    const checkId = this.checkedLists;
    this.patchedLists = this.checkboxLists.map(element => {
      return checkId.includes(element._id) ? true : null
    });

    this.f['checkboxFormArray'].patchValue(this.patchedLists);
    this.change();
  }

  filterCheckbox() {
    this.filteredCheckbox = this.f['checkboxFormArray'].value.map((element, index) => element ? index : null).filter(element => element !== null);
    if (this.serviceAssignWindows && (this.disabledCheckedArray && this.disabledCheckedArray?.length > 0)) {
      this.filteredCheckbox = (this.filteredCheckbox.concat(this.disabledCheckedArray?.filter((item) => !this.filteredCheckbox.includes(item)))).sort();
    }
  }


  change($event?: any, checkbox?: any) {
    let eventValue = $event;

    if (this.serviceAssignWindows) {
      eventValue = [];
      if (checkbox) {
        if ($event) {
          this.selectedWindows.push(checkbox._id);
          this.selectedWindows = [...new Set(this.selectedWindows)];
        } else {
          if (this.selectedWindows.includes(checkbox._id)) {
            const index = this.selectedWindows.indexOf(checkbox._id);
            this.selectedWindows.splice(index, 1);
          }
        }
      }

      this.selectedWindows.forEach(windowId => {
        this.checkboxLists.forEach((checkbox, index) => {
          if (checkbox._id === windowId) {
            eventValue.push(index);
          }
        });
      });
    }

    this.filterCheckbox();

    this.isConfirmDisabled = this.filteredCheckbox.length === 0 ? true : false;

    if (this.serviceAssignWindows) {
      this.filteredCheckbox = eventValue;
    }

    this.changedCheckbox.emit({
      value: eventValue,
      checkedArray: this.filteredCheckbox,
      isConfirmDisabled: this.isConfirmDisabled
    });

    const c = this.f['checkboxFormArray'].value;
    if (c) {
      const s = c.filter(x => x);
      if (s) {
        if (s.length === c.length) {
          this.selectAll = true
        } else {
          this.selectAll = false
        }
      }
    }
  }

  checkDisableCheckbox() {
    this.isCheckboxDisabled ? this.checkboxForm.disable() : this.checkboxForm.enable();
    if (this.isCheckboxDisabled) {
      this.f['checkboxFormArray'].patchValue(this.checkboxLists.map(c => true));
      this.selectAll = true
      this.change();
    } else if (this.serviceAssignWindows) {
      this.disabledCheckedArray = [];
      this.checkboxLists.forEach((element, index) => {
        if (element?.disabled) {
          const control = this.cbFormArray.at(index) as FormControl;
          control.disable();
          if (!this.disabledCheckedArray.includes(index)) {
            this.disabledCheckedArray.push(index);
          }
        }
      });
    }
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.disableSelectAll = this.checkboxLists.some(obj => obj.disabled === true);
    this.initForm();
    if (this.checkedLists) {
      this.patchValue();
    }
    if (this.checkboxForm) {
      this.checkDisableCheckbox();
    }

    this.searchSubject
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((searchTextValue) => {
        // this.searchString = searchTextValue
      });

    if (this.serviceAssignWindows) {
      if (this.checkedLists) {
        this.selectedWindows.push(...this.checkedLists);
        this.selectedWindows = [...new Set(this.selectedWindows)];
      }
    }
  }

  public searchBranch() {
    this.searchSubject.next(this.searchString);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.checkboxForm) {
      this.checkDisableCheckbox();
    }
  }

}