import { FilterData } from "../interfaces/monitoring.interface";

export const defaultOperatingHoursLabel: string[] = [
  "8:00 AM",
  "9:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "1:00 PM",
  "2:00 PM",
  "3:00 PM",
  "4:00 PM",
  "5:00 PM",
];

export const defaultWeeklyLabel: string[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const defaultDateFilter: FilterData[] = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "yesterday",
    label: "Yesterday",
  },
  {
    value: "this-week",
    label: "This Week",
  },
  {
    value: "this-month",
    label: "This Month",
  },
  {
    value: "this-year",
    label: "This Year",
  },
  {
    value: "custom",
    label: "Custom",
  },
];
