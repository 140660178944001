import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpService } from 'src/app/core/http/http.service';
import { DataService } from 'src/app/core/services/data.service';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-queue-transfer-service-modal',
  templateUrl: './queue-transfer-service-modal.component.html',
  styleUrls: ['./queue-transfer-service-modal.component.scss']
})
export class QueueTransferServiceModalComponent extends ModalService implements OnInit {
  serviceLists: Array<any>;
  currentWindow: string;
  selectedQueueId: string;
  selectedServiceId: string;
  branchId: string;
  enableRemarks: boolean;
  requireRemarks: boolean;
  remarks = '';
  currentServiceId: string;

  constructor(
    bsModalRef: BsModalRef,
    httpService: HttpService,
    dataService: DataService,
    toastr: ToastrService,
    authService: AuthService
  ) {
    super(bsModalRef, httpService, dataService, toastr, authService)
  }

  getServiceLists() {
    this.httpService.get$(`services/${this.branchId}?limit=999`).subscribe(
      data => {
        this.serviceLists = data.data.filter(x => x._id !== this.currentServiceId && !x.suspended).map(element => {
          return {
            label: element.displayName,
            value: element._id
          }
        }).sort((a, b) => a.label.localeCompare(b.label));
        this.selectedServiceId = this.serviceLists[0]?.value;
      }
    )
  }

  selectService($event) {
    this.selectedServiceId = $event;
  }

  confirm() {
    const bodyForm = {
      windowId: this.currentWindow,
      destinationServiceId: this.selectedServiceId,
      remarks: this.remarks
    }

    const service = this.serviceLists.find(x => x.value === this.selectedServiceId);

    this.httpService.patch$(`queues/${this.branchId}/${this.selectedQueueId}/transfer-service`, bodyForm).subscribe(
      data => {
        const queueNo = data.data.queueNo;
        // this.toastr.success(`${queueNo} has been transferred to ${service.label}`, 'Transferred!', {
        //   positionClass: 'toast-bottom-right-custom'
        // });
        this.dataService.publishDataChange$({
          action: 'added',
          queue: data?.data?._id
        });
        this.bsModalRef.hide();
      }, error => {
        this.toastr.error('Queue has not been transferred', 'Failed!', {
          positionClass: 'toast-bottom-right-custom'
        });
        this.bsModalRef.hide();
      }
    )
  }

  ngOnInit(): void {
    this.getServiceLists();
  }

}
