import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { BranchManagementService } from "../../services/branch-management.service";

@Component({
  selector: "app-delete-tv-modal",
  templateUrl: "./delete-tv-modal.component.html",
  styleUrls: ["./delete-tv-modal.component.scss"],
})
export class DeleteTvModalComponent implements OnInit {
  isSubmitting = false;
  tvId: string;
  tvName: string;
  branchId: string;
  title: string;
  @Output() successEvent = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private branchService: BranchManagementService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  deleteTv() {
    this.branchService.removeTv(this.branchId, this.tvId).subscribe((res) => {
      console.log("REMOVE TV", res);
      this.toastr.error(`${this.tvName} has been removed`, 'Removed');
      this.successEvent.emit(res);
      this.bsModalRef.hide();
    });
  }
}
