import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { FormGroup ,FormBuilder, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { StatusFilter } from "../../interfaces/support.interface";
import { ToastrService } from "ngx-toastr";
import { SupportService } from "../../services/support.service";
import { DataService } from 'src/app/core/services/data.service';
import { Subscription } from "rxjs";


@Component({
  selector: "app-support-connect-sales-modal",
  templateUrl: "./support-connect-sales-modal.component.html",
  styleUrls: ["./support-connect-sales-modal.component.scss"],
})
export class SupportConnectSalesModalComponent implements OnInit,OnDestroy {
  title: string = "";
  subTitle: string = "";
  description: string = "";
  isSelectClicked: boolean;
  mobileNo: string = "";
  @Input() templateData: any;
  disableBtn1: boolean = true;
  disableBtn2: boolean = true;
  isSubmitting = false;
  selectedFeature: any;
  numberOFUser: string = "";
  inputForm: FormGroup;
  @Output() successEvent = new EventEmitter();
  selectNumberOfUser: Array<StatusFilter> = [
    {
      label: "1-10 staff/employees",
      value: "1-10 staff/employees",
    },
    {
      label: "11-50 staff/employees",
      value: "11-50 staff/employees",
    },
    {
      label: "50+ staff/employees",
      value: "50+ staff/employees",
    },
  ];

  listFeature: Array<StatusFilter> = [

    { 
      label: "APPOINTMENT",
      value: "APPOINTMENT",
    },
    {
      label: "FEEDBACK",
      value: "FEEDBACK",
    },
    {
      label: "SERVICE PROCEDURE",
      value: "SERVICE PROCEDURE",
    },
  ];
 
  subscriptions = new Subscription()

  constructor(
      public modalRef: BsModalRef, 
      private formBuilder: FormBuilder,
      private supportService: SupportService,  
      private dataService: DataService,   
      private toastr: ToastrService
  ) {
    this.inputForm = this.formBuilder.group({
      mobileNo: [ null, [Validators.required ,Validators.minLength(10),Validators.pattern(/^9[0-9]{2}[-]?[0-9]{3}[-]?[0-9]{4}$/)]],
      description: [null, Validators.required],
    });
  }


  changeInput(ev) {
    this.inputForm.controls['mobileNo'].setValue(ev.value);
    this.inputForm.markAsDirty(); 
  }

  closeModal() {
    this.modalRef.hide();
  }

 
  featureFunction(ev) {
    this.selectedFeature = ev
    if(this.selectedFeature.length === 0) {
      this.disableBtn1  = true
    } else {
     this.disableBtn1 = false;      
    return this.selectedFeature.map(data => data.value) 
    }
  }

  selectedNumberOfUser(ev){
    this.numberOFUser = ev     
    if (this.numberOFUser === "Enter Number") {
      this.disableBtn2 = true;
    } else {
      this.disableBtn2 = false;
    }
  }


  connectToSales() {
   this.isSubmitting = true;
  
   const body = {
       branchId: this.dataService.accountData$.data.branchId, 
       mobileNo: this.inputForm.value.mobileNo
       ? this.inputForm.value.mobileNo.replaceAll("-", "")
       : "",
       numberOfUser: this.numberOFUser,
       featureNeeded: this.selectedFeature.map(data => data.value).toString(),
       description: this.inputForm.value.description,
    }
  
    this.subscriptions.add(
      this.supportService.createConnectSales(body).subscribe(
        (res) => {
          this.modalRef.hide();
          this.toastr.success("Form has been submitted", "Success!");
        },
          (error) => {
            console.log("Error encountered upon submitting ", error);
            this.toastr.error("Error encountered upon submitting ", "Error!");
            this.isSubmitting = false;  
          }
      ) 
    )
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
