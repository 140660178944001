import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subject, Subscription, forkJoin, throwError } from "rxjs";
import { AuthService } from "src/app/core/auth/auth.service";
import { DataService } from "src/app/core/services/data.service";
import { defaultAvatar } from "src/app/core/services/global";
import { SubscriptionLimitModalComponent } from "src/app/modules/billing/components/subscription-limit-modal/subscription-limit-modal.component";
import { TeamManagementService } from "src/app/modules/team-management/services/team-management.service";
import { AddBranchModalComponent } from "../../component/add-branch-modal/add-branch-modal.component";
import { AddBranchSuccessModalComponent } from "../../component/add-branch-success-modal/add-branch-success-modal.component";
import { BranchManagementService } from "../../services/branch-management.service";
import { MonitoringService } from "src/app/modules/monitoring/services/monitoring.service";
import {
  catchError,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";

@Component({
  selector: "app-branches-page",
  templateUrl: "./branches-page.component.html",
  styleUrls: ["./branches-page.component.scss"],
})
export class BranchesPageComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  defaultAvatar = defaultAvatar;
  branchesList: any[];
  branchData: any;
  profileData: any;
  branchId: string = "";
  subscriptions = new Subscription();
  private branchPageSubjectAPI$ = new Subject<void>();
  selectedBranch: any;
  assignedBranches = this.authService.assignedBranches$;
  role: any;

  constructor(
    private router: Router,
    private branchService: BranchManagementService,
    private authService: AuthService,
    private dataService: DataService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private teamService: TeamManagementService,
    private monitoringService: MonitoringService
  ) {}

  ngOnInit() {
    this.branchData = this.branchService.getSelectedBranch();
    console.log(this.branchData)
    this.getAccountOwner();
    this.getBranches();
  }

  addBranch() {
    // if(this.branchesList.data && this.branchesList.data.length >= 1){
    //   const initialState = {
    //     title: "Your business is doing so great",
    //     subTitle1: `that you’ve reached the maximum number of branch on your business!`,
    //     subTitle2: `You have <b>1 branch</b> slots on your current plan`,
    //     subTitle3: `Upgrade your plan if you wish to add more branch 👍`
    //   };
    //   this.modalRef = this.modalService.show(SubscriptionLimitModalComponent, {
    //     initialState: initialState,
    //     class: "modal-dialog-centered modal-md",
    //     ignoreBackdropClick: true,
    //     keyboard: false,
    //   });
    // } else {
    const initialState = {
      title: "Add Branch",
      branchData: this.branchData,
    };

    this.modalRef = this.modalService.show(AddBranchModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-xl m-0",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if (data) {
          // this.dataService.publishNewBranch$(data.data);
          // this.viewBranch(data.data._id);
          const initialState = {
            branchData: data.data,
          };
          this.modalRef = this.modalService.show(
            AddBranchSuccessModalComponent,
            {
              initialState: initialState,
              class: "modal-dialog-centered modal-md",
              ignoreBackdropClick: true,
              keyboard: false,
            }
          );
          this.getBranches();
          // this.router
          //   .navigateByUrl("/", { skipLocationChange: true })
          //   .then(() => {
          //     this.router.navigateByUrl(`/branch-management/${data.data._id}`);
          //   });
        }
      },
      (err) => {
        console.log("add branch", err);
        return false;
      }
    );
    // }
  }

  getBranches() {
    this.isLoading = true;
    this.subscriptions.add(
      this.branchService
        .getBranchList()
        .pipe(
          takeUntil(this.branchPageSubjectAPI$),
          map((res) => res.data),
          tap((data) => {
            this.branchesList = data;
            // console.log(this.branchesList)
            let isBranchExists: boolean = this.branchesList.some(
              (item: { _id: string; }) => item._id === this.branchData.branchId
            );
            if (!isBranchExists) {
              this.selectBranchFromDelete(this.branchesList[0])
            }
          }),
          catchError((error) => {
            console.log(
              "Error on fetching business monitoring dashboard data",
              error
            );
            this.isLoading = false;
            return throwError(error);
          }),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe()
    );
  }

  viewBranch(branch) {
    this.selectNewBranch(branch);
  }

  selectNewBranch(data) {
    this.selectedBranch = {
      _id: data._id,
      branchId: data._id,
      branchName: data.name,
      name: data.business.name,
      businessId: data.business._id,
      avatarUrl: data.business.avatarUrl,
      industryName: data.business.industry.name,
      industryId: data.business.industryId,
      industryTypeId: data.business.industryTypeId,
      ownerId: data.business.ownerId,
      roleLevel: this.branchData.roleLevel,
    };
    
    this.branchService.setSelectedBranch(this.selectedBranch);
    this.dataService.currentBranch$ = this.selectedBranch;
    this.dataService.publishBranchChange$(this.selectedBranch);
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(`/branch-management/${this.selectedBranch._id}`);
    });
  }

  selectBranchFromDelete(data) {
    const selectedBranch = {
      _id: data._id,
      branchId: data._id,
      branchName: data.name,
      name: this.dataService.currentBranch$.name,
      businessId: this.dataService.currentBranch$.businessId,
      avatarUrl: this.dataService.currentBranch$.avatarUrl,
      industryName: this.dataService.currentBranch$.industryName,
      industryId: this.dataService.currentBranch$.industryId,
      industryTypeId: this.dataService.currentBranch$.industryTypeId,
      ownerId: this.dataService.currentBranch$.ownerId,
      roleLevel: this.dataService.currentBranch$.roleLevel,
    };
    
    this.branchService.setSelectedBranch(selectedBranch);
    this.dataService.currentBranch$ = selectedBranch;
    this.dataService.publishBranchChange$(selectedBranch);
  }

  getAccountOwner() {
    this.subscriptions.add(
      this.teamService
        .getProfileData(this.branchData.ownerId, this.branchData._id)
        .subscribe((res) => {
          this.profileData = res.data;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.branchPageSubjectAPI$.unsubscribe();
  }
}
