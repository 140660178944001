import { TitleCasePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import * as moment from "moment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { defaultAvatar } from "src/app/core/services/global";
import { QueueHistoryService } from "../../services/queue-history.service";
import { ServiceCategoryService } from "src/app/modules/service-category/services/service-category.service";
import { Subscription } from "rxjs";
import { getDuration } from "src/app/shared/helpers/time.helpers";

@Component({
  selector: "app-queue-details-modal",
  templateUrl: "./queue-details-modal.component.html",
  styleUrls: ["./queue-details-modal.component.scss"],
  providers: [ TitleCasePipe ]
})
export class QueueDetailsModalComponent implements OnInit, OnDestroy {
  queueData: any;
  title: string;
  queueId: string;
  branchId: string;
  serviceLists: any;
  accountList: any;
  defaultAvatar = defaultAvatar;
  servedByData: any;
  showQueueForm: boolean;
  categoryLists: any = [];
  subscriptions = new Subscription();

  constructor(
    public bsModalRef: BsModalRef,
    public titleCasePipe: TitleCasePipe,
    private queueHistoryService: QueueHistoryService,
    private categoryService: ServiceCategoryService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getServiceCategoryList();
    await this.getQueueHistoryDetails();
  }

  async getServiceCategoryList() {
    this.subscriptions.add(
      this.categoryService
        .getServiceCategories(this.branchId)
        .subscribe((data) => {
          this.categoryLists = data.data;
        })
    );
  }

  async getQueueHistoryDetails() {
    this.subscriptions.add(
      this.queueHistoryService
      .getQueueHistoryDetails(this.queueId, this.branchId)
      .subscribe((res) => {
        console.log("this is my res data")
        console.log(res);
        this.queueData = res.data;
        if (this.queueData) {
          let visitorsIndex: number = 0 | -1;
          visitorsIndex = this.queueData.fields.findIndex(
            (x) => x.fieldName == "visitors"
          );
          if (visitorsIndex > -1) {
            this.queueData.fields[visitorsIndex].label = "How Many People?";
          }

          let priorityIndex: number = 0 | -1;
          priorityIndex = this.queueData.fields.findIndex(
            (x) => x.fieldName == "priority"
          );
          if (priorityIndex > -1) {
            this.queueData.fields[priorityIndex].label =
              "Is this queue a priority? (ex. PWD, Senior Citizen, etc.)";
            const prio = this.queueData.fields[priorityIndex];
            if (prio.value == "false" || prio.value == "") {
              this.queueData.fields[priorityIndex].value = "false";
            } else {
              this.queueData.fields[priorityIndex].value = "true";
            }
          }
          this.getAccountName(this.queueData.servedBy);
          this.queueData.fields.forEach((x) => {
            if (x.value) {
              this.showQueueForm = true;
            }
          });
        }
      })
    )
  }

  checkFormattedTime(date) {
    // console.log(
    //   new Date().toLocaleDateString("en-US") ===
    //     new Date(date).toLocaleDateString("en-US")
    // );
    // console.log(
    //   new Date().toLocaleDateString("en-US"),
    //   new Date(date).toLocaleDateString("en-US")
    // );

    return (
      new Date().toLocaleDateString("en-US") ===
      new Date(date).toLocaleDateString("en-US")
    );
  }

  getFormattedSummary(summary) {
    if (summary.indexOf("on") > -1) {
      let service = summary.substring(summary.indexOf("on") + 3);
      service = service.substring(0, service.indexOf("by") - 1);
      const server = summary.substring(summary.indexOf("by") - 1);
      const status = summary.substring(0, summary.indexOf(service));
      return `${status}<b>${service}</b>${server}`;
    } else {
      return summary;
    }
  }

  getServiceName(id) {
    let name = "";
    if (id && this.serviceLists) {
      const s = this.serviceLists.find((x) => x._id === id);
      if (s) {
        name = s.name;
      }
    }
    return name;
  }

  getServiceCategoryName(id, categoryId) {
    let serviceName = [];
    if (id && this.serviceLists) {
      const s = this.serviceLists.find((x) => x._id === id);
      if (s) {
        serviceName = [s?.name];
      }
    }

    let serviceCategoryName = [];
    if (categoryId && this.categoryLists) {
      const c = this.categoryLists.find((x) => x._id === categoryId);
      if (c?.parentCategoryId) {
        const c1 = this.categoryLists.find(
          (x) => x._id === c?.parentCategoryId
        );
        if (c1?.parentCategoryId) {
          const c2 = this.categoryLists.find(
            (x) => x._id === c1?.parentCategoryId
          );
          serviceCategoryName = [
            c2?.displayName,
            c1?.displayName,
            c?.displayName,
          ];
        } else {
          serviceCategoryName = [c1?.displayName, c?.displayName];
        }
      } else {
        serviceCategoryName = [c?.displayName];
      }
    }

    return {
      serviceCategory: serviceCategoryName,
      serviceName: serviceName,
    };
  }

  getTimeAgo(data) {
    if (data) {
      const y = new Date(data).getFullYear();
      const m = new Date(data).getMonth();
      const d = new Date(data).getDate();
      const h = new Date(data).getHours();
      const mm = new Date(data).getMinutes();
      const s = new Date(data).getSeconds();
      const time = moment([y, m, d, h, mm, s]).fromNow();

      return time === "a day" ? "1 day" : time;
    }
  }

  getWaitingTime(data, from) {
    if (data) {
      const y = new Date(data).getFullYear();
      const m = new Date(data).getMonth();
      const d = new Date(data).getDate();
      const h = new Date(data).getHours();
      const mm = new Date(data).getMinutes();
      const s = new Date(data).getSeconds();

      const fromTime = moment(new Date(from));
      const time = moment([y, m, d, h, mm, s]).from(fromTime, true);

      return time;
    }
  }

  getAccountName(id) {
    if (id && this.accountList) {
      const a = this.accountList.find((x) => x._id === id);
      if (a) {
        this.servedByData = a;
      }
    }
  }

  getWaitingTimeDuration(start, end) {
    const duration = this.getDuration(end - start);
    return duration;
  }

  getDuration(milliseconds: number): string {
    if (milliseconds <= 0) {
      return '00:00:00'
    } else {
      return getDuration(milliseconds);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
