import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DragulaService } from "ng2-dragula";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { ProcedureService } from "../../services/procedure.service";

@Component({
  selector: "app-edit-procedures-modal",
  templateUrl: "./edit-procedures-modal.component.html",
  styleUrls: ["./edit-procedures-modal.component.scss"],
})
export class EditProceduresModalComponent implements OnInit {
  constructor(
    private router: Router,
    private httpService: HttpService,
    private dataService: DataService,
    private dragulaService: DragulaService,
    private activatedRoute: ActivatedRoute,
    private procedureService: ProcedureService,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef
  ) {
    // dragulaService.createGroup("procedureModel", {
    //   accepts: (el, target, source, sibling): boolean => {
    //     if (el.classList.contains("disabled-drag") || target.classList.contains("disabled-drag")) {
    //       return false;
    //     }
    //     return true;
    //   },
    // });
  }

  @Input() inQueue: boolean;
  @Input() queue: any;
  @Input() serviceLists: any;
  @Input() procedureTemplateData: any;
  @Input() branchId: any;
  assignedProcedures = [];
  @Output() successEvent = new EventEmitter();

  subscriptions = new Subscription();
  isSubmitting = false;
  assignAnotherProcedure = false;
  disableButton = false;

  isTMC: boolean = false;

  searchInput: string = '';
  serviceListsSearch: any[] = [];

  ngOnInit(): void {
    // for TMC
    if (this.dataService.accountData$ && ([
      "a597d9a2-c2dd-40e7-b05f-47114be30f12", //??
      "0bb706ca-6f00-42a1-af36-431770829e90", //dev
      "a94bf1a5-5677-425e-87ba-a0ae9078ae22", //staging
      "e42367dc-f0b0-4e6e-8a4b-819cf9d50262"  //demo
    ].includes(this.dataService.accountData$.data.businessId))) {
      this.isTMC = true;
    }

    this.serviceListsSearch = this.serviceLists;

    this.assignAnotherProcedure = this.queue.procedureQueue.modules.includes(
      "assign-new-procedure"
    );

    this.queue.procedureQueue.procedures.forEach((p) => {
      this.assignedProcedures.push({
        ...p,
        label: this.getServiceName(p.serviceId),
      });
    });
    this.checkDrag();
  }

  checkDrag() {
    this.subscriptions.add(
      this.dragulaService
        .dropModel("procedureModel")
        .subscribe(({ targetModel }) => {
          this.assignedProcedures = targetModel
            .map((element, i) => {
              return {
                ...element,
                index: i,
              };
            })
            .sort((a, b) => a.index - b.index);
          console.log("assignedProcedures", this.assignedProcedures);
          this.checkIfButtonDisabled();
        })
    );
  }

  checkIfStepError(index) {
    let x = false;
    if (this.assignedProcedures.length > 1) {
      if (this.assignedProcedures.length > index + 1) {
        const v1 = this.assignedProcedures[index];
        const v2 = this.assignedProcedures[index + 1];
        if (v1.serviceId === v2.serviceId) {
          x = true;
        }
      }

      if (index > 0) {
        const v1 = this.assignedProcedures[index];
        const v2 = this.assignedProcedures[index - 1];
        if (v1.serviceId === v2.serviceId) {
          x = true;
        }
      }
    }

    return x;
  }

  checkIfButtonDisabled() {
    this.disableButton = false;
    this.assignedProcedures.forEach((e, i) => {
      if (this.assignedProcedures.length > i + 1) {
        const v1 = this.assignedProcedures[i];
        const v2 = this.assignedProcedures[i + 1];
        if (v1.serviceId === v2.serviceId) {
          this.disableButton = true;
        }
      }

      if (i > 0) {
        const v1 = this.assignedProcedures[i];
        const v2 = this.assignedProcedures[i - 1];
        if (v1.serviceId === v2.serviceId) {
          this.disableButton = true;
        }
      }
    });
  }

  public getServiceName(id) {
    if (id && this.serviceLists) {
      const s = this.serviceLists.find((x) => x._id === id);
      if (s) {
        return s.displayName;
      }
    }
  }

  select(service) {
    this.assignedProcedures.push({
      serviceId: service._id,
      label: service.displayName,
      optional: false,
      state: "pending",
    });

    this.checkIfButtonDisabled();
    console.log(this.assignedProcedures);
  }

  cancel() {
    this.bsModalRef.hide();
  }

  save() {
    this.bsModalRef.hide();
    const data = {
      procedureType: this.queue.procedureQueue.procedureType,
      procedureTemplateId: this.queue.procedureQueue.procedureTemplateId,
      queueId: this.queue._id,
      modules: this.assignAnotherProcedure ? ["assign-new-procedure"] : [],
      procedures: this.assignedProcedures,
    };

    this.isSubmitting = true;

    let procedureQueueId = this.queue.procedureQueueId || this.queue.procedureQueue._id;
    this.httpService
      .patch$(
        `queues/procedure-queues/${this.branchId}/${procedureQueueId}`,
        data
      )
      .subscribe(
        (data) => {
          console.log("update procedure queue", data);
          this.toastr.success(
            `Service procedure of ${this.queue.queueNo} has been updated`,
            "Success!"
          );
          this.dataService.publishDataChange$({
            action: "completed",
            queue: data?.data?._id,
          });
          this.bsModalRef.hide();
          this.isSubmitting = false;
          if (this.inQueue) {
            this.successEvent.emit(data);
          } else {
            this.successEvent.emit(true);
          }
        },
        (error) => {
          this.toastr.error("Queue has not been completed", "Failed!");
        }
      );
  }

  optionalProcedure(ev, id) {
    // const s = this.assignedProcedures.find((x) => x.id === id);
    // s.optional = ev;
    this.assignedProcedures[id].optional = ev;
    this.checkIfButtonDisabled();
    // console.log(s)
  }

  setModule(ev) {
    this.assignAnotherProcedure = ev;
  }

  remove(i) {
    this.assignedProcedures.splice(i, 1);
    this.checkIfButtonDisabled();
  }

  searchInputUpdated($event) {
    if (this.searchInput) {
      const pattern = new RegExp(`${this.searchInput}`, 'i');
      this.serviceListsSearch = this.serviceLists.filter(service => service.displayName.search(pattern) != -1)
    } else {
      this.serviceListsSearch = this.serviceLists;
    }
  }
}
