import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-input-edit',
  templateUrl: './input-edit.component.html',
  styleUrls: ['./input-edit.component.scss']
})
export class InputEditComponent implements OnInit, OnChanges {
  @Input() label: string;

  _value: any;
  @Input() public set value(value){
    if(value){
      this._value = value;
      this.initForm();
    }
  }

  public get value(){
    return this._value;
  }

  @Input() validator1: string;
  @Input() errorMsg1: string;
  @Input() type: string;
  @Input() module: string;
  @Input() maxLength: string | number;
  @Output() savedInput = new EventEmitter();
  inputEditForm: FormGroup;
  errorMsg: string;
  isReadOnly: boolean;
  isConfirmDisabled: boolean;

  constructor(
    private fb: FormBuilder
  ) { }
  
  initForm() {
    this.inputEditForm = this.fb.group({
      inputName: [this.value, Validators[this.validator1]]
    });
  }

  get f() { return this.inputEditForm.controls; }

  changeInput() {
    this.isConfirmDisabled = this.f['inputName'].errors ? true : false;

    // if(this.f['inputName'].value){
    //   const value = this.f['inputName'].value.toUpperCase();
    //   this.f['inputName'].patchValue(value);
    // }
  }

  edit() {
    this.isReadOnly = false;
  }

  save() {
    this.savedInput.emit(this.f['inputName'].value);
    this.isReadOnly = true;
  }

  cancel() {
    this.f['inputName'].setValue(this.value);
    this.isReadOnly = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initForm();    
  }

  ngOnInit(): void {    
    this.errorMsg = this.errorMsg1;
    this.isReadOnly = true;
  }

}
