import { Component, Input, OnInit } from '@angular/core';
import { BreadCrumbs } from './breadcrumbs.interface';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() pageDetails: Array<BreadCrumbs> = []
  
  constructor() { }

  ngOnInit(): void {
  }

}
