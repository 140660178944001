import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { defaultAvatar } from 'src/app/core/services/global';
import { TitleCasePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-detail-edit',
  templateUrl: './detail-edit.component.html',
  styleUrls: ['./detail-edit.component.scss'],
  providers: [ TitleCasePipe ]
})
export class DetailEditComponent implements OnInit {
  @Input() type: string;
  @Input() title: string;
  @Input() buttonText: string;
  @Input() label1: string;
  @Input() value1: any;
  @Input() emptyString: boolean;
  @Input() additionalInfo: boolean;
  @Output() buttonClicked = new EventEmitter();
  defaultAvatar: string = defaultAvatar;

  constructor(
    public titleCasePipe: TitleCasePipe
  ) { }

  click($event) {
    this.buttonClicked.emit($event);
  }

  ngOnInit(): void {}

}
