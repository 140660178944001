import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-support-help-center-sub-reports",
  templateUrl: "./support-help-center-sub-reports.component.html",
  styleUrls: ["./support-help-center-sub-reports.component.scss"],
})
export class SupportHelpCenterSubReportsComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {}
}
