import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";

/* interface model */
import { HelpCenterCategories } from "../../interfaces/support.interface";

@Component({
  selector: "app-support-help-center",
  templateUrl: "./support-help-center.component.html",
  styleUrls: ["./support-help-center.component.scss"],
})
export class SupportHelpCenterComponent implements OnInit {
  helpCenterCategories: string = "";
  displayHelpCategories: boolean = true;
  searchText: string = "";
  dataArray: any;
  searchCategoryArray: Array<HelpCenterCategories>;
  @Input() categories: Array<HelpCenterCategories>;

  constructor(private router: Router) {}

  helpCenterBtn(): void {
    this.displayHelpCategories = true;
  }

  ngOnInit(): void {
    this.dataArray = this.categories;
  }

  searchKey(inputText: string) {
    this.searchText = inputText;
    console.log(this.searchText);
    let searchCategory = this.categories.filter((data) =>
      data.title.toLowerCase().includes(this.searchText.toLowerCase())
    );

    this.dataArray = searchCategory;
  }

  selectCategory(index: number): void {
    const category = this.categories[index];
    if (category.id === index) {
      this.router.navigate(["/support/help-center", category.category]);
    }
  }
}
