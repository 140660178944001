import { Component, OnInit } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AuthService } from "src/app/core/auth/auth.service";
import { Router } from "@angular/router";
import { BranchManagementService } from "src/app/modules/branch-management/services/branch-management.service";
import { BillingService } from "../../services/billing.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-free-trial-expired",
  templateUrl: "./free-trial-expired.component.html",
  styleUrls: ["./free-trial-expired.component.scss"],
})
export class FreeTrialExpiredComponent implements OnInit {
  title: any;
  subTitle1: any;
  subTitle2: any;
  subTitle3: any;
  trial: boolean;
  type: string;
  roleLevel: string;
  businessId: string;
  cancelled: boolean = false;
  plan: string;
  environmentType = environment.env;


  constructor(
    private branchService: BranchManagementService,
    public modalRef: BsModalRef,
    public authService: AuthService,
    private router: Router,
    private billingService: BillingService
  ) {}

  ngOnInit(): void {}

  contactSales() {
    window.open("https://info.kyoo.com/contact", "blank");
  }

  subscribeToPlan() {
    this.modalRef.hide();
    this.router.navigate(["/subscription"]);

    // if (this.trial) {
    //   this.router.navigate(["/subscription"]);
    // } else {
    //   if (this.type === "remaining" && this.cancelled) {
    //     this.customerPortal();
    //   } else {
    //     this.router.navigate(["/subscription"]);
    //   }
    // }
  }

  customerPortal() {
    this.billingService.createCustomerPortal(this.businessId, {}).subscribe(
      (data) => {
        window.location.href = data.data.url;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  logout() {
    this.authService.logout().subscribe((res) => {
      if (res.success) {
        this.modalRef.hide();
        setTimeout(() => {
          this.router.navigate(["/login"]);
        }, 500);
      }
    });
  }
}
