import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-edit-billing-currency',
  templateUrl: './edit-billing-currency.component.html',
  styleUrls: ['./edit-billing-currency.component.scss']
})
export class EditBillingCurrencyComponent implements OnInit {
  title: any;
  subTitle: any;
  selectedCurrency: any;
  currencies: any = [
    {
      _id: 1, name: '(USD) US Dollar'
    },
    {
      _id: 2, name: '(PHP) Philippine Pesos'
    }
  ]

  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.selectedCurrency = this.currencies[0]._id;
  }

  changeCurrency() {

  }

  updateBillingCurrency() {
    this.toastr.success("Billing currency has been updated", "Success!");
    this.modalRef.hide();
    console.log(this.selectedCurrency);
  }

}
