import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { SettingsService } from "../../services/settings.service";

export enum SMSFeatures {
  newQueue = "new-queue",
  servedQueue = "serve-queue",
  returnedQueue = "returned-queue",
  removedQueue = "remove-queue-by-staff",
  skippedQueue = "skipped-queue",
  holdQueue = "hold-queue",
  resumedQueue = "resumed-queue",
  completedQueue = "completed-queue",
  transferredQueue = "transferred-queue",
  notifyQueue = "notify-queue",
  notifyNextInlineQueue = "notify-next-inline-queue",
}

@Component({
  selector: "app-sms-settings",
  templateUrl: "./sms-settings.component.html",
  styleUrls: ["./sms-settings.component.scss"],
})

export class SmsSettingsComponent implements OnInit {

  enableSmsNotification: boolean;

  subscriptions = new Subscription();
  selectedBranch: any;
  enabledFeatures = [];

  smsFeaturesEnum = SMSFeatures;

  smsSettingsData: any;

  isPending = false;

  constructor(
    private settingsService: SettingsService,
    private dataService: DataService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe((res) => {
        this.selectedBranch = res ? res : this.selectedBranch;
        if (this.selectedBranch) {
          this.getSmsSettings();
        }
      })
    );
  }

  getSmsSettings() {
    this.settingsService
      .getSmsSettings(this.selectedBranch.businessId, this.selectedBranch._id)
      .subscribe((data) => {
        console.log("get sms setting", data);
        if (data && data.data) {
          this.smsSettingsData = data.data;
          this.enableSmsNotification = this.smsSettingsData.isEnableSms;
          this.enabledFeatures = this.smsSettingsData.enabledFeatures;
        }
      },
        (error) => {
          console.log("error", error);
        });
  }

  enableSMSSetting() {
    const body = {
      businessId: this.selectedBranch.businessId,
      branchId: this.selectedBranch._id,
    };
    this.settingsService.enableSmsSetting(body).subscribe((data) => {
      this.isPending = true;
      this.toastr.success("Email has been sent", "Success!");
      console.log("enableSMS", data);
    },
      (error) => {
        this.toastr.success("Please try again..", "Error!");
        console.log("error", error);
      });
  }

  updateSmsSettings() {
    const body = {
      businessId: this.selectedBranch.businessId,
      branchId: this.selectedBranch._id,
      enabledFeatures: this.enabledFeatures,
      isEnableSms: this.enableSmsNotification
    };
    this.settingsService.updateSmsSettings(body).subscribe(
      (data) => {
        this.toastr.success("SMS Settings has been updated", "Success!");
        console.log("updateSMS", data);
      },
      (error) => {
        this.toastr.success("SMS Settings Error", "Failed!");
        console.log("error", error);
      }
    );
  }

  enableSmsFeature(ev, feature) {
    if (ev && !this.enabledFeatures.includes(feature)) {
      this.enabledFeatures.push(feature);
    } else {
      if (this.enabledFeatures.includes(feature)) {
        const index = this.enabledFeatures.indexOf(feature);
        if (index > -1) {
          this.enabledFeatures.splice(index, 1);
        }
      }
    }

    this.updateSmsSettings();
  }

  enableSMSNotif(ev) {
    this.enableSmsNotification = ev;
    this.updateSmsSettings();
  }
}
