import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ServiceCategoryServiceV2 } from '../../services/service-category.service';

@Component({
  selector: 'app-assign-service-modal',
  templateUrl: './assign-service-modal.component.html',
  styleUrls: ['./assign-service-modal.component.scss']
})
export class AssignServiceModalComponent implements OnInit {

  title: string;
  subTitle: string;
  branchId: string;
  assignedServices: any;
  serviceLists: any;
  mappedServiceLists: any;
  categoryId: string;
  categoryName: string;

  selectedServices: any;

  isSubmitting = false;
  prevSelected: number = 0;
  hasChanges: boolean = true;

  @Output() successEvent = new EventEmitter();

  constructor(public bsModalRef: BsModalRef, private categoryService: ServiceCategoryServiceV2, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.hasChanges = true;
    this.mappedServiceLists = this.serviceLists.map(element => {
      return {
        label: element.displayName,
        _id: element._id
      }
    });

    console.log(this.mappedServiceLists)
  }

  cancel(){
    this.bsModalRef.hide();
  }

  confirm(){
    this.isSubmitting = true;

    const serviceIds = this.selectedServices?.map((x) => {
      return x._id;
    });
    console.log(serviceIds)

    const body = {
      assignedServices: serviceIds,
      displayName: this.categoryName,
    };

    this.categoryService
      .updateServiceCategory(this.branchId, this.categoryId, body)
      .subscribe(
        (res) => {
          console.log(res);
          this.isSubmitting = false;
          this.toastr.success(`Category has been updated`, "Success!");
          this.successEvent.emit(res);
          this.bsModalRef.hide();
        },
        (error) => {
          console.log(error);
          this.isSubmitting = false;
          let errMsg = "Error on managing services";
          this.toastr.error(errMsg, "Error!");
        }
      );
  }

  changeCheckbox(ev){
    const checked = ev.checkedArray;
    this.prevSelected = ev.checkedArray.length;
    this.selectedServices = this.mappedServiceLists.filter((element, index) =>
      checked.includes(index)
    );
    this.hasChanges = ev.value !== undefined ? false : true;
  }

}
