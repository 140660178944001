import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ClipboardService } from "ngx-clipboard";
import { TeamManagementService } from "../../services/team-management.service";

@Component({
  selector: "app-reset-password-modal",
  templateUrl: "./reset-password-modal.component.html",
  styleUrls: ["./reset-password-modal.component.scss"],
})
export class ResetPasswordModalComponent implements OnInit {
  title: string;
  passwordShown = false;
  passwordType = "password";
  inputFocus: any;
  profileData: any;
  inputPassword: any;
  success = false;

  accountForm = this.fb.group({
    password: ["", [Validators.required, Validators.minLength(6)]],
  });

  isSubmitting = false;
  @ViewChild("pop", { static: false }) pop: any;
  @ViewChild("password", { static: false }) password: any;

  constructor(
    public bsModalRef: BsModalRef,
    private teamService: TeamManagementService,
    private clipboardApi: ClipboardService,
    private fb: FormBuilder
  ) {}

  get f() {return this.accountForm.controls; }

  ngOnInit(): void {
    console.log(this.profileData);
  }

  showPassword() {
    this.passwordShown = !this.passwordShown;
  }

  get email() {
    return typeof this.profileData.email === "string"
      ? this.profileData.email
      : this.profileData.email.value;
  }

  get randomPassword() {
    const chars =
      "abcdefghijklmnopqrstuvwxyz!@#$%&*-+ABCDEFGHIJKLMNOP1234567890";
    let pass = "";
    for (var x = 0; x < 8; x++) {
      var i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
    console.log(pass);
    return pass;
  }

  generatePassword() {
    this.accountForm.controls.password.patchValue(this.randomPassword)
    // this.inputPassword = this.randomPassword;
    this.passwordShown = true;
    this.password.nativeElement.focus();
  }

  resetPassword() {

    const formData = {
      newPassword: this.accountForm.value.password,
    };
    this.isSubmitting = true;
    this.teamService
      .resetPassword(formData, this.profileData._id, this.profileData.branchId)
      .subscribe(
        (res) => {
          console.log("RESET PASSWORD SUCCESS", res);
          this.isSubmitting = false;
          this.success = true;
        },
        (error) => {
          console.log("error", error);
          this.isSubmitting = false;
        }
      );

    console.log(this.inputPassword);
    this.passwordShown = false;
  }

  isOpen = false;
  copyToClipboard() {
    console.log(this.pop);
    this.isOpen = !this.isOpen;
    setTimeout(() => {
      this.isOpen = !this.isOpen;
    }, 1500);
    const cope = `Email:${this.email}, Password:${this.accountForm.value.password}`;
    this.clipboardApi.copyFromContent(cope);
  }
}
