import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { ServiceCategoryService } from "../../services/service-category.service";
import { AddServiceCategoryComponent } from "../../../service-category-v2/components/add-service-category/add-service-category.component";
import { EditServiceCategoryNameComponent } from "../edit-service-category-name/edit-service-category-name.component";

@Component({
  selector: "app-view-service-category",
  templateUrl: "./view-service-category.component.html",
  styleUrls: ["./view-service-category.component.scss"],
})
export class ViewServiceCategoryComponent implements OnInit {
  branchId: any;
  categoryId: any;
  serviceLists: any;
  title: any;
  categoryDetails: any;
  servicesArr = [];
  refreshList: boolean;
  @Output() successEvent = new EventEmitter();

  constructor(
    private bsModalService: BsModalService,
    public modalRef: BsModalRef,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private categoryService: ServiceCategoryService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getCategoryDetails();
  }

  getCategoryDetails() {
    console.log(this.serviceLists);
    this.categoryService
      .getServiceCategoryById(this.branchId, this.categoryId)
      .subscribe((res) => {
        this.categoryDetails = res.data;
        if (this.categoryDetails.assignedServices) {
          this.categoryDetails.assignedServices.forEach((s) => {
            const serviceName = this.getServiceName(s);
            if (serviceName) {
              this.servicesArr.push(serviceName);
            }
          });
        }
      });
  }

  public getServiceName(id) {
    if (id && this.serviceLists) {
      const s = this.serviceLists.find((x) => x.value === id);
      if (s) {
        return s.label;
      }
    }
  }

  public setSelectedCategory(id) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        selectedCategory: id ? id : null,
      },
      queryParamsHandling: "merge",
      skipLocationChange: false,
    });
  }

    editCategoryName() {
    this.modalRef.hide();
    const initialState = {
      branchId: this.branchId,
      categoryId: this.categoryId,
      title: "Edit Category Name",
      type: "edit",
      categoryDetails: this.categoryDetails,
      serviceLists: this.serviceLists,
    };
    this.bsModalRef = this.bsModalService.show(AddServiceCategoryComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.bsModalRef.content.successEvent.subscribe(
      (data) => {
        this.successEvent.emit(true);

        setTimeout(() => {
          const initialState = {
            branchId: this.branchId,
            categoryId: this.categoryId,
            title: "View Category",
            serviceLists: data,
            refreshList: true
          };
          this.modalRef = this.bsModalService.show(
            ViewServiceCategoryComponent,
            {
              initialState: initialState,
              class: "modal-dialog-centered",
              ignoreBackdropClick: true,
              keyboard: false,
            }
          );
        }, 500);
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }

  cancel() {
    this.modalRef.hide();
    this.setSelectedCategory(null);
    this.successEvent.emit("");
  }

  manageServices() {
    this.modalRef.hide();
    this.router.navigate([`/apps/service-category/${this.categoryId}`]);
  }
}
