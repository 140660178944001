import { Component, OnInit } from "@angular/core";
import { IAccordion } from "src/app/shared/components/accordion/interfaces/accordion";

@Component({
  selector: "app-support-help-center-sub-tv-display",
  templateUrl: "./support-help-center-sub-tv-display.component.html",
  styleUrls: ["./support-help-center-sub-tv-display.component.scss"],
})
export class SupportHelpCenterSubTVDisplayComponent implements OnInit {

  account: IAccordion  = {
    title: 'Account',
    items: [
      {
        label: 'Forgot Password',
        content: `
          <p>If you need to reset your password, just follow these simple steps:</p>
          <ul>
            <li>
              Go to the password recovery page by <a href="https://app.kyoo.com/forgot-password" target="_blank">clicking on this link</a>
            </li>
            <li>Once you are there, enter your email address.</li>
            <li>Click on "Submit".</li>
          </ul>
        `
      }, 
      {
        label: 'I did not receive any recovery link',
        content: `
          <p>If you did not receive an email with the link, consider the following options:</p>
          <ul>
            <li>Double-check the email address you entered to ensure its accurate.</li>
            <li>Try refreshing your email inbox to see if the email arrives.</li>
            <li>Look in your Spam Folder or Promotions Tab, as it might have landed there.</li>
          </ul>
        `
      }
    ]
  }

  businessPortal: IAccordion = {
    title: "Business Portal",
    items: [
      {
        label: 'Has the Business Portal experienced a crash or become unresponsive?',
        content: `
          <ul>
            <li>
              It might be helpful to look at the system logs. 
              They could provide valuable information like error messages or exceptions that could help us figure out what caused the crash.
            </li>
          </ul>
        `
      }
    ]
  }

  kiosk: IAccordion  = {
    title: 'Kiosk',
    items: [
      {
        label: 'The Kyoo kiosk is showing error messages or experiencing system crashes?',
        content: `
          <p>
            If you come across any error messages or experience system crashes, 
            it might be related to software or configuration issues. No worries, 
            start by giving the kiosk a quick restart to see if that helps resolve the problem
          </p>
        `
      }
    ]
  }

  tvDisplay: IAccordion  = {
    title: 'TV Display',
    items: [
      {
        label: 'The Kyoo TV Display is not displaying real-time data?',
        content: `
          <ul>
            <li>Make sure that your Kyoo TV Box is connected to your network/wifi.</li>
            <li>
                Verify your Wi-Fi connection or any other network settings. Also, 
                ensure there are no network outages or security system restrictions that could be causing the problem.
            </li>
          </ul>
        `
      },
      {
        label: 'What steps should be taken if the TV Display logs out independently?',
        content: `
          <ul>
            <li>
              If the TV Display logs out unexpectedly, here's what you need to do:
              <ol>
                <li>Open the Business Portal and go to the "Multibranch Management" section.</li>
                <li>Choose the appropriate branch location from the list.</li>
                <li>Choose the appropriate branch location from the list.</li>
                <li>Click on the "Add TV Display" button.</li>
                <li>A unique code will appear on the screen.</li>
                <li>Now, take this code and enter it into the TV Display Application on the TV.</li>
              </ol>
            </li>
          </ul>
        `
      },
      {
        label: 'What should I do if the TV Box keeps disconnecting from the speaker it`s connected to?',
        content: `
            <ul> 
              <li>
                If you've got a wired connection to the speaker, ensure that all cables and connections 
                between the TV Box and the speaker are secure and properly plugged in.
              </li>
              <li>
                If you're using Bluetooth to connect the TV Box to the speaker, interference from other devices or electronic 
                equipment can disrupt the connection. Make sure there are no other active Bluetooth devices nearby that might be causing interference.
              </li> 
            </ul>
        `
      },
      {
        label: 'What should I do if the Kyoo TV Box remote is not working?',
        content: `
          <ul>
            <li>
                Make sure the batteries are new and inserted correctly. 
                Sometimes, weak batteries or incorrect placement can lead to connection problems.
            </li>
            <li>
                Go into your settings and look for 'Remote and Accessories.' From there, click on 'Setup Buttons on Remote.' 
                Follow the prompts to test the buttons and see if they're responsive. This should help you get those buttons back in action!
            </li>
          </ul>
        `
      }
    ]
  }

  constructor() {}

  ngOnInit(): void {}
}
