import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { Subject, Subscription, throwError } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { HttpService } from "src/app/core/http/http.service";
import { defaultAvatar } from "src/app/core/services/global";
import { TitleCasePipe } from "@angular/common";
import { BranchManagementService } from "src/app/modules/branch-management/services/branch-management.service";
import { TeamManagementService } from "src/app/modules/team-management/services/team-management.service";
import { DataService } from "src/app/core/services/data.service";
import { DatePickerModalComponent } from "src/app/shared/components/date-picker-modal/date-picker-modal.component";
import {
  catchError,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { ServiceLists } from "src/app/shared/helpers/service.helpers";
import { defaultDateFilter } from "../../constants/monitoring.constants";
import { FilterData } from "../../interfaces/monitoring.interface";

@Component({
  selector: "app-monitoring-branch-page",
  templateUrl: "./monitoring-branch-page.component.html",
  styleUrls: ["./monitoring-branch-page.component.scss"],
  providers: [TitleCasePipe],
})
export class MonitoringBranchPageComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  pageDetails = [
    { type: "main", name: "Monitoring", routerLink: ["/monitoring"] },
    { type: "current", name: "Branches", routerLink: null },
  ];
  branchId: string = "";
  branchData: Array<any> = [];
  accountLists: Array<any> = [];
  serviceLists: Array<ServiceLists> = [];
  selectedService: string = "all";
  defaultAvatar: string = defaultAvatar;
  activeTab: number = 0;
  isFocused: boolean = false;
  isSelectClicked: boolean = false;
  isButtonClicked: boolean = false;
  selectedValue: string = "today";
  selectedLabel: string = "Today";
  selectedDateFilter: string = "today";
  selectedDateFilterLabel: string = "Today";
  customDate1: string = "";
  customDate2: string = "";
  isFocused1: boolean = false;
  isSelectClicked1: boolean = false;
  isButtonClicked1: boolean = false;
  selectedServiceId: string = "";
  selectedServiceLabel: string = "";
  tabLists: Array<FilterData> = [
    { value: "branch-performance", label: "BRANCH PERFORMANCE" },
    { value: "visitor-volumes", label: "VISITOR VOLUMES" },
    { value: "team-performance", label: "TEAM PERFORMANCE" },
  ];
  subscriptions = new Subscription();
  dateFilter = defaultDateFilter.filter((item) => item.value !== "this-year");
  private monitoringBranchPageSubjectAPI$ = new Subject<void>();

  constructor(
    private branchService: BranchManagementService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    private bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getBranchId();
    this.getBranchData();
    this.getServiceLists();
    this.selectDateDropdown(this.dateFilter[0]);
    this.chooseTab(this.activeTab);
  }

  getBranchId() {
    this.branchId = this.activatedRoute.snapshot.paramMap.get("branchId");
  }

  getBranchData() {
    this.subscriptions.add(
      this.branchService
        .getBranchDetails(this.branchId)
        .pipe(
          takeUntil(this.monitoringBranchPageSubjectAPI$),
          map((res) => res.data),
          tap((data) => {
            if (data && typeof data === "object") {
              const { _id, name, avatarUrl } = data;
              this.branchData.push({ _id, name, avatarUrl });
            } else {
              this.router.navigate(["/not-found"]);
            }
          }),
          catchError((error) => {
            console.log("Error on fetching branch details", error);
            return throwError(error);
          }),
        )
        .subscribe()
    );
  }

  getServiceLists() {
    this.subscriptions.add(
      this.httpService
        .get$(`services/${this.branchId}?limit=999`)
        .pipe(
          takeUntil(this.monitoringBranchPageSubjectAPI$),
          map((res) => res.data), // for extraction of data
          tap((data) => {
            this.serviceLists = data.map((element) => ({
              _id: element._id,
              name: element.displayName,
            }));
          }),
          catchError((error) => {
            console.log("Error on fetching service lists", error);
            return throwError(error);
          }),
        )
        .subscribe(
          () => {
            if (this.serviceLists.length > 0) {
              this.serviceLists.unshift({
                _id: "all",
                name:
                  "All Service" +
                  (this.serviceLists ? "s " : " ") +
                  "(" +
                  this.serviceLists?.length +
                  ")",
              });
              this.selectServiceDropdown(this.serviceLists[0]);
              this.selectedService = this.serviceLists[0]._id;
            }
          }
        )
    );
  }

  chooseTab(i) {
    this.activeTab = i;
  }

  selectDate() {
    this.isSelectClicked = !this.isSelectClicked;
    this.isFocused = !this.isFocused;
  }

  selectService() {
    this.isSelectClicked1 = !this.isSelectClicked1;
    this.isFocused1 = !this.isFocused1;
  }

  @HostListener("document:click")
  deselect() {
    if (!this.isSelectClicked || this.isButtonClicked) {
      this.isFocused = false;
    }
    if (!this.isSelectClicked1 || this.isButtonClicked1) {
      this.isFocused1 = false;
    }
    this.isButtonClicked = false;
    this.isSelectClicked = false;
    this.isButtonClicked1 = false;
    this.isSelectClicked1 = false;
  }

  selectDateDropdown(list) {
    if (list.value === "custom") {
      this.selectCustomDateFilter();
    } else {
      this.selectedValue = list.value;
      this.selectedLabel = list.label;
    }
    this.isButtonClicked = true;
    if (!this.isFocused) {
      this.isButtonClicked = false;
    }
  }

  selectServiceDropdown(list) {
    this.selectedServiceId = list._id;
    this.selectedServiceLabel = list.name;
    this.selectedService = this.selectedServiceId;

    this.isButtonClicked1 = true;
    if (!this.isFocused1) {
      this.isButtonClicked1 = false;
    }
  }

  selectCustomDateFilter() {
    const initialState = {
      hasMinDate: false,
      datePickerType: "range",
    };
    this.bsModalRef = this.bsModalService.show(DatePickerModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md2",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.bsModalRef.content.selectedDateRange.subscribe(
      (data) => {
        if (data) {
          if (data == "cancel") {
            // this.customErrMsg = ''
          } else {
            if (data[0] && data[1]) {
              this.selectedValue = "custom";
              this.customDate1 = (data[0] as Date).toLocaleDateString("en-US");
              this.customDate2 = (data[1] as Date).toLocaleDateString("en-US");
              this.selectedLabel = this.customDate1 + " - " + this.customDate2;
              this.selectedDateFilterLabel =
                this.customDate1 + " - " + this.customDate2;

              const startDate = new Date(this.customDate1);
              const endDate = new Date(this.customDate2);

              const threeMonthsLater = new Date(startDate.getTime()); // Create a new date object with the same time as the start date
              threeMonthsLater.setMonth(startDate.getMonth() + 3); // Add three months to the new date object

              if (endDate > threeMonthsLater) {
                // Do something if the selected dates are invalid
                // this.customErrMsg = 'Selected dates must not be more than 3 months'
              } else {
                // Do something if the selected dates are valid
                // this.customErrMsg = ''
              }
            } else {
              // this.customErrMsg = ''
            }
          }
        }
      },
      (err) => {
        return false;
      }
    );
  }

  generateReport() {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.monitoringBranchPageSubjectAPI$.next();
    this.monitoringBranchPageSubjectAPI$.complete();
  }
}
