import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DataService } from "src/app/core/services/data.service";
import { Subscription } from "rxjs";
import { HttpService } from "src/app/core/http/http.service";
import { ToastrService } from "ngx-toastr";

interface IDropdown {
  label: string;
  value: string;
}

interface ISettings {
  _id: string
  iconType: string
  procedureQueueType: string
  featuresEnabled: string[]
  createdAt: number
  status: boolean
  rateType: string
  timePreferred: string
  rateRangeValue: string
  branchId: string
  updatedAt: number
  predefinedRemarks: IRemark[]
  customerGetFeedbackWhen: CUSTOMER_GET_FEEDBACK_WHEN

}

interface IRemark {
  _id: string
  createdAt: number
  text: string
  type: PREDEFINED_REMARK_TYPE
  settingsId: string
  branchId: string
  authorId: string
  updatedAt: number
}

enum PREDEFINED_REMARK_TYPE {
  POSITIVE = 'positive',
  NEGATIVE = 'negative'
}

enum CUSTOMER_GET_FEEDBACK_WHEN {
  COMPLETED_THE_QUEUE = 'queue-completed',
  JOINS_THE_QUEUE = 'queue-added'
}

enum FEEBACK_SETTING_FEATURES_ENABLED {
  // allow customers to add comments on their ratings
  REMARKS_AFTER_RATING = 'remarks-after-rating',
  // require visitors to add comments on their ratings
  REQUIRE_COMMENTS_OR_REMARKS = 'require-comments-remarks',
  // allow the branch to add predefined remarks
  PREDEFINED_REMARKS = 'predefined-remarks',
}

@Component({
  selector: 'app-feedback-settings',
  templateUrl: './feedback-settings.component.html',
  styleUrls: ['./feedback-settings.component.scss']
})


export class FeedbackSettingsComponent implements OnInit {

  positivePredefinedReasons: string[] = [];
  negativePredefinedReasons: string[] = [];

  enable: boolean = true;

  settings: ISettings;

  subscriptions = new Subscription();
  accountData = this.dataService.accountData$;
  selectedBranch = this.dataService.currentBranch$;

  ratingTypes: IDropdown[] = [
    { label: 'Icon', value: 'icon' },
    { label: 'Numeric', value: 'numeric' }
  ]

  iconTypes: IDropdown[] = [
    { label: 'Star', value: 'star' },
    { label: 'Emotion', value: 'emotion' }
  ]

  ratingValueRange: IDropdown[] = [
    // { label: '1-2', value: '1-2' },
    { label: '1-3', value: '1-3' },
    { label: '1-5', value: '1-5' },
    // { label: '1-10', value: '1-10' }
  ]

  feedbackTrigger: IDropdown[] = [
    { label: 'Visitor completed the queue', value: CUSTOMER_GET_FEEDBACK_WHEN.COMPLETED_THE_QUEUE },
    { label: 'Visitor joins the queue', value: CUSTOMER_GET_FEEDBACK_WHEN.JOINS_THE_QUEUE },
  ]

  isLoading: boolean = false;

  constructor(
    private toaster: ToastrService,
    private dataService: DataService,
    public httpService: HttpService) {

  }

  ngOnInit(): void {
    this.getSettings()
  }

  saveChanges() {
    this.isLoading = true;

    this.httpService.patch$(`feedback/${this.selectedBranch._id}/settings`, this.settings).subscribe(
      (data) => {
        this.isLoading = false;
        this.settings = data.result;
        this.toaster.success("Feedback settings has been updated.", "Success!")
      },
      (error) => console.log(error)
    )
  }

  getSettings() {

    this.isLoading = true;
    console.log("get")
    this.httpService.get$(`feedback/${this.selectedBranch._id}/settings`).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.result) {
          this.settings = data.result;
          this.positivePredefinedReasons = this.settings.predefinedRemarks.filter(reasons => reasons.type === PREDEFINED_REMARK_TYPE.POSITIVE).map(reasons => reasons.text);
          this.negativePredefinedReasons = this.settings.predefinedRemarks.filter(reason => reason.type === PREDEFINED_REMARK_TYPE.NEGATIVE).map(reason => reason.text);
          
          if(!this.settings.featuresEnabled.includes('remarks-after-rating')) {
            this.settings.featuresEnabled = this.settings.featuresEnabled.filter((feature) => !['require-comments-remarks', 'predefined-remarks'].includes(feature));
          }
        }
      },
      (error) => console.log(error)
    )
  }

  enableFeedback(event: boolean) {
    console.log(event)
    if (this.settings) {
      this.enable = event;
      this.settings.status = event
    } else {
      console.log("create settings!")
      const payload = {
        "status": true,
        "rateType": "numeric",
        "rateRangeValue": "1-5",
        "timePreferred": "completed-process",
        "featuresEnabled": ["remarks-after-rating"]
      }
      this.httpService.post$(`feedback/${this.selectedBranch._id}/settings`, payload).subscribe(
        (data) => {
          this.settings = data.result
        },
        (error) => console.log(error))
    }
    console.log(event)
  }

  dropdownValueChange(value: string, fieldName: string) {
    console.log(value)
    this.settings[fieldName] = value
  }

  remarksToggleChange(event: boolean, feature: FEEBACK_SETTING_FEATURES_ENABLED) {
    if (event) {
      this.settings.featuresEnabled.push(feature)
    } else {
      const index = this.settings.featuresEnabled.findIndex(enabledFeature => enabledFeature === feature)
      this.settings.featuresEnabled.splice(index, 1)
    }

    if(feature === 'remarks-after-rating') {
      if(!this.settings.featuresEnabled.includes('remarks-after-rating')) {
        this.settings.featuresEnabled = this.settings.featuresEnabled.filter((feature) => !['require-comments-remarks', 'predefined-remarks'].includes(feature));
      }
    }
  }

  onTagAdded(tag, type: PREDEFINED_REMARK_TYPE): void {

    const payload = {
      settingsId: this.settings._id,
      predefinedRemarks: [
        { type, text: tag.value }
      ]
    }

    const subscription = this.httpService.post$(`feedback/${this.selectedBranch._id}/predefined-remarks`, payload).subscribe(
      (data) => {
        this.toaster.success(`${type} predefined reason has been added.`, "Success!");
        // this.isLoading = false;
        subscription.unsubscribe();
      },
      (error) => {
        subscription.unsubscribe();
        console.log(error)
      }
    )

  }

  onTagRemoved(removedTag: string, type: PREDEFINED_REMARK_TYPE): void {

    const result = this.settings.predefinedRemarks.find(reason => reason.text === removedTag)
    // this.isLoading = true;
    if (result) {
      this.httpService.delete$(`feedback/${this.selectedBranch._id}/predefined-remarks/${result._id}`).subscribe(
        (data) => {
          this.toaster.warning(`${type} predefined reason has been removed.`, "Removed!");
          // this.isLoading = false;
        },
        (error) => console.log(error)
      )
    }

  }


}
