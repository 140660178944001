import { Component, OnInit, ElementRef, OnDestroy } from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from "@angular/router";
import { defaultAvatar } from "src/app/core/services/global";

import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { AuthService } from "src/app/core/auth/auth.service";
import { JwtService } from "src/app/core/services/jwt.service";
import { HttpClient } from "@angular/common/http";
import { DataService } from "src/app/core/services/data.service";
import { HttpService } from "src/app/core/http/http.service";
import { BranchManagementService } from "src/app/modules/branch-management/services/branch-management.service";
import { Observable, Subject, Subscription } from "rxjs";
import { BusinessService } from "src/app/modules/auth/services/business.service";
import { environment } from "src/environments/environment";
import { Store } from "@ngrx/store";
import { checkIfHasNotification } from "src/app/modules/notifications/store/selectors/notifications";
import { open, setHasNotification } from "src/app/modules/notifications/store/actions/notifications";
import { map, takeUntil, tap } from "rxjs/operators";
import { TeamManagementService } from "src/app/modules/team-management/services/team-management.service";

var misc: any = {
  sidebar_mini_active: true,
};

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  public focus;
  public listTitles: any[];
  public location: Location;
  sidenavOpen: boolean = true;
  defaultAvatar: string = defaultAvatar;
  branchData: any;
  accountId: string = this.authService.accountId$;
  accountData = this.dataService.accountData$;
  branchesList: any;

  assignedBranches = this.authService.assignedBranches$;
  role: any;
  environmentType = environment.env;

  // testBranch = {
  //   _id: "branchId",
  //   branchName: "BGC Taguig",
  //   name: "Petzania Animal Center",
  //   avatarUrl: "",
  // };

  selectedBranch: any;
  subscriptions = new Subscription();

  storedBranch = this.branchService.getSelectedBranch();
  storedSubscription = this.dataService.getSubscriptionPlan();
  daysRemaining = "";
  subscribed = false;
  isNotificationLoading: boolean = false;
  markAllAsReadState$: Observable<boolean>;
  isMarkedAllAsRead: boolean;
  hasNotificationState$: Observable<boolean>
  hasNotification: boolean;
  private destroyHasNotificationState$ = new Subject<void>();

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    private httpService: HttpService,
    private authService: AuthService,
    private businessService: BusinessService,
    private dataService: DataService,
    private branchService: BranchManagementService,
    private teamManagementService: TeamManagementService,
    private store: Store<{ notificationState: boolean }>
  ) {
    this.location = location;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator

        if (window.innerWidth < 1200) {
          document.body.classList.remove("g-sidenav-pinned");
          document.body.classList.add("g-sidenav-hidden");
          this.sidenavOpen = false;
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });

    if (this.dataService.accountData$) {
      const accountData = this.dataService.accountData$?.data;
      if (accountData) {
        const branchId = accountData.branchId;
        const accountId = accountData.accountId;
        const accountAnnouncement = this.dataService.accountData$?.data?.announcements;
        if (accountAnnouncement) {
          const checker: boolean = accountAnnouncement.some(announcement => announcement.hasRead === false);
          if (checker) {
            this.store.dispatch(setHasNotification({ hasNotification: checker }));
          }
        } else {
          this.getAccountAnnouncements(accountId, branchId);
        }
      }
    }
    
    this.store
      .select(checkIfHasNotification)
      .pipe(takeUntil(this.destroyHasNotificationState$))
      .subscribe((state) => {
        this.hasNotification = state;
      });
  }

  getAccountAnnouncements(accountId: string, branchId: string) {
    this.subscriptions.add(
      this.teamManagementService
        .getProfileData(accountId, branchId)
        .pipe(
          map(res => res.data),
          tap(account => {
            if (account && account.announcements) {
              const accountAnnouncement = account.announcements;
              const checker: boolean = accountAnnouncement.some(announcement => announcement.hasRead === false);
              this.store.dispatch(setHasNotification({ hasNotification: checker }));
            }
          })
        )
        .subscribe()
    );
  }

  upgradePlan() {
    this.router.navigate(["/subscription"]);
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  openSidebar() {
    // if (document.body.classList.contains("g-sidenav-pinned")) {
    //   document.body.classList.remove("g-sidenav-pinned");
    //   document.body.classList.add("g-sidenav-hidden");
    //   this.sidenavOpen = false;
    // } else {
    //   document.body.classList.add("g-sidenav-pinned");
    //   document.body.classList.remove("g-sidenav-hidden");
    //   this.sidenavOpen = true;
    // }

    const sidenavToggler =
      document.getElementsByClassName("sidenav-toggler")[0];
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("g-sidenav-pinned")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }

    if (misc.sidebar_mini_active === true) {
      body.classList.remove("g-sidenav-pinned");
      body.classList.add("g-sidenav-hidden");
      sidenavToggler.classList.remove("active");
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add("g-sidenav-pinned");
      body.classList.remove("g-sidenav-hidden");
      sidenavToggler.classList.add("active");
      misc.sidebar_mini_active = true;
    }

    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  }

  toggleSidenav() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }

  logout() {
    const bodyForm = {
      accountId: this.accountId,
    };

    const currentWindow = this.accountData.data.attendingWindow;

    if (currentWindow) {
      this.httpService
        .patch$(
          `services/windows/${this.accountData.data.branchId}/${currentWindow}/attending-staff/leave`,
          bodyForm
        )
        .subscribe();
    }

    this.authService.logout().subscribe((res) => {
      if (res.success) {
        this.router.navigate(["/login"]);
      }
    });
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter((listTitle) => listTitle);
    this.subscriptions.add(
      this.dataService.newBranch$.subscribe((res) => {
        if (res) {
          this.getBranches(res);
        }
      })
    );

    this.subscriptions.add(
      this.dataService.businessData$.subscribe((res) => {
        this.selectedBranch = res;
        if (res) {
          this.dataService.currentBranch$ = res;
          this.getBranches(null);
        }
      })
    );

    this.subscriptions.add(
      this.dataService.branchChange$.subscribe((res) => {
        if (res) {
          this.storedBranch = res;
          this.selectedBranch = res;
          this.role = this.selectedBranch.roleLevel;
        }
      })
    )

    this.getBranchesInit();

    const daysRemaining = this.businessService.getDaysRemaining(
      this.storedSubscription.startDate,
      this.storedSubscription.endDate,
      this.storedSubscription.cancelled,
    );

    this.subscribed = this.storedSubscription.plan?.toLowerCase() !== 'trial';

    if (this.storedSubscription.cancelled || this.storedSubscription.plan?.toLowerCase() === 'trial') {
      if (Math.round(daysRemaining.consumedHours) <= 0) {
        this.daysRemaining = "Expired";
      } else {
        this.daysRemaining =
          Math.round(daysRemaining.daysConsumed) < 1
            ? `${Math.round(daysRemaining.consumedHours)} hour/s remaining`
            : `${Math.round(daysRemaining.daysConsumed)} day/s remaining`;
      }
    } else {
      this.daysRemaining = "Auto-renewal";
    }

  }

  getBranches(data) {
    this.branchService.getBranchList().subscribe((res) => {
      this.branchService.publishBranches$(res.data);
      this.branchService.publishAssignedBranches$(this.assignedBranches);
      this.branchesList = res;
      if (data) {
        const selectedBranch = this.assignedBranches.find(
          (x) => x.branchId === data.settings?.branchId
        );
        this.role = selectedBranch ? selectedBranch.roleLevel : this.role;
        data.roleLevel = this.role;
        this.selectNewBranch(data);
      }
    });
  }

  getBranchesInit() {
    this.branchService.getBranchList().subscribe((res) => {
      // res.data.push(this.testBranch);
      this.branchesList = res;
      if (this.storedBranch && this.storedBranch.branchId === this.accountData.data.branchId) {
        this.selectedBranch = this.storedBranch;
        this.role = this.selectedBranch.roleLevel;
        this.dataService.currentBranch$ = this.selectedBranch;
        this.dataService.publishBranchChange$(this.selectedBranch);
        this.branchService.setSelectedBranch(this.selectedBranch);
      } else {
        const selected = res.data.find(
          (x) => x._id === this.accountData.data.branchId
        );
        const currentBranch = this.assignedBranches.find(
          (x) => x.branchId === selected._id
        );
        // selected.roleLevel = this.assignedBranches[0].roleLevel;
        // this.role = this.assignedBranches[0].roleLevel;
        selected.roleLevel = currentBranch.roleLevel;
        this.role = currentBranch.roleLevel;
        this.selectBranch(selected, true);
      }
    });
  }

  viewMyProfile() {
    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return false;
    // };
    // this.router.onSameUrlNavigation = "reload";
    this.router.navigate(["/team-management", this.accountId]);
  }

  selectNewBranch(data) {
    const s = this.branchService.getSelectedBranch();
    if (s && data._id !== s._id) {
      this.authService.branchLogout(data._id).subscribe((res) => {
        if (res) {
          this.dataService.accountData$ = res;
          this.accountData = res;
        }
      });
    }

    this.selectedBranch = {
      _id: data._id,
      branchName: data.name,
      name: this.dataService.currentBranch$.name,
      businessId: data.businessId,
      avatarUrl: this.dataService.currentBranch$.avatarUrl,
      industryName: this.dataService.currentBranch$.industryName,
      industryId: this.dataService.currentBranch$.industryId,
      industryTypeId: this.dataService.currentBranch$.industryTypeId,
      ownerId: data.ownerId,
      branchId: data._id,
      roleLevel: data.roleLevel,
    };

    this.branchService.setSelectedBranch(this.selectedBranch);
    this.dataService.currentBranch$ = this.selectedBranch;
    this.dataService.publishBranchChange$(this.selectedBranch);
  }

  selectBranch(data, init?) {
    if (!init) {
      this.authService.branchLogout(data._id).subscribe((res) => {
        if (res) {
          this.dataService.accountData$ = res;
          this.accountData = res;
        }
      });
    }

    const selectedBranch = {
      _id: data._id,
      branchName: data.name,
      name: data.business.name,
      businessId: data.business._id,
      avatarUrl: data.business.avatarUrl,
      industryName: data.business.industry.name,
      industryId: data.business.industry._id,
      industryTypeId: data.business.industryTypeId,
      ownerId: data.ownerId,
      branchId: data._id,
      roleLevel: data.roleLevel
        ? data.roleLevel
        : this.assignedBranches
          ? this.assignedBranches.find((x) => x.branchId === data._id)?.roleLevel
          : this.selectedBranch.roleLevel,
    };

    this.selectedBranch = selectedBranch;

    this.branchService.setSelectedBranch(this.selectedBranch);
    this.dataService.currentBranch$ = this.selectedBranch;
    this.dataService.publishBranchChange$(this.selectedBranch);
  }

  openNotifications() {
    this.store.dispatch(open({isOpen: true}));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.destroyHasNotificationState$.next();
    this.destroyHasNotificationState$.complete();
  }
}
