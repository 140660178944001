import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

interface AnnouncementPayload {
  announcementIds: string[];
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  urlAPI = environment.urlAPI;

  constructor(
    private http: HttpClient
  ) { }

  private get httpHeader(): any {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return {
      headers,
    };
  }
  
  public getAnnouncementsByBranchId(status?: string): Observable<any> {
    const url = `${this.urlAPI}/notifications/announcements/list?limit=999&status=${status}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public updateAnnouncementHasRead(data: AnnouncementPayload): Observable<any> {
    const url = `${this.urlAPI}/accounts/announcements`;
    return this.http.patch(url, data).pipe(map((response) => response));
  }
}