import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import * as moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject, Subscription, timer } from "rxjs";
import { last, startWith, switchMap } from "rxjs/operators";
import { AuthService } from "src/app/core/auth/auth.service";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { TimePipe } from "src/app/shared/pipes/time.pipe";
import { TitleCasePipe } from "@angular/common";
import { TransactionsModalComponent } from "../transactions-modal/transactions-modal.component";

@Component({
  selector: "app-queue-main",
  templateUrl: "./queue-main.component.html",
  styleUrls: ["./queue-main.component.scss"],
  providers: [TitleCasePipe],
})
export class QueueMainComponent implements OnInit, OnChanges, OnDestroy {
  @Input() queue: any;
  @Input() serviceLists: any;
  @Input() categoryLists: any;
  @Input() branchId: string;
  timeQueued: number;
  subscriptions = new Subscription();
  timerSubscription: Subscription;
  timer$: Observable<any>;
  timerSubject$ = new Subject();
  minsHours: any;
  duration: any;
  isTimeWarning: boolean;
  universalTags: Array<any> = [];

  constructor(
    private httpService: HttpService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private dataService: DataService,
    private timePipe: TimePipe,
    private titleCasePipe: TitleCasePipe
  ) { }

  getServiceName(id, categoryId) {
    let serviceName = [];
    if (id && this.serviceLists) {
      const s = this.serviceLists.find(x => x._id === id);
      if (s) {
        serviceName = [s?.displayName];
      }
    }

    let serviceCategoryName = [];
    if (categoryId && this.categoryLists) {
      const c = this.categoryLists.find(x => x._id === categoryId);
      if (c?.parentCategoryId) {
        const c1 = this.categoryLists.find(x => x._id === c?.parentCategoryId);
        if (c1?.parentCategoryId) {
          const c2 = this.categoryLists.find(x => x._id === c1?.parentCategoryId);
          serviceCategoryName = [c2?.displayName, c1?.displayName, c?.displayName];
        } else {
          serviceCategoryName = [c1?.displayName, c?.displayName];
        }
      } else {
        serviceCategoryName = [c?.displayName];
      }
    }

    return {
      serviceCategory: serviceCategoryName,
      serviceName: serviceName,
    };
  }

  async getMinsHours() {
    this.duration = this.timeQueued ? this.timeQueued : null;
    await this.getTick();
  }

  async timeConversion(duration) {
    const hours = Math.floor(duration / (1000 * 60 * 60));
    this.minsHours = this.timePipe.transform(duration);
    this.isTimeWarning = hours >= 1;
  }

  async getTick() {
    this.timer$ = this.timerSubject$.pipe(
      startWith(0),
      switchMap(() => timer(0, 1000))
    );
    this.subscriptions.add(
      (this.timerSubscription = this.timer$.subscribe(
        async (value) => {
          const time = this.duration + value * 1000;
          const duration = time;
          await this.timeConversion(duration);
        },
        (err) => { },
        () => { }
      ))
    );
  }

  addTag($event) {
    // PAL REQUEST
    let newSet = [...this.queue.tags,];
    // PAL REQUEST
    if (typeof $event === 'object') {
      newSet = [...$event]
    } else {
      newSet.push($event)
    }

    let bodyForm = {
      tags: newSet,
    };

    bodyForm.tags = [...new Set(bodyForm.tags)]
    this.httpService
      .patch$(`queues/${this.branchId}/${this.queue._id}`, bodyForm)
      .subscribe(
        (data) => {
          this.bsModalRef.hide();
          // PAL REQUEST
          this.toastr.success("Transaction has been added", "Success!", {
            positionClass: 'toast-bottom-right-custom'
          });
          this.dataService.publishDataChange$(data);
          this.queue = data?.data;
          this.dataService.publishDataChange$({
            action: "tagUpdate",
            queue: data?.data,
          });
        },
        (error) => {
          console.log("error", error);
          // PAL REQUEST
          this.toastr.error("Transaction has not been added", "Failed!", {
            positionClass: 'toast-bottom-right-custom'
          });
          this.dataService.publishDataChange$(error);
        }
      );
  }

  removeTag($event) {
    const newTags = this.queue.tags.filter((element) => element !== $event);
    const bodyForm = {
      tags: newTags,
    };
    this.httpService
      .patch$(`queues/${this.branchId}/${this.queue._id}`, bodyForm)
      .subscribe(
        (data) => {
          this.bsModalRef.hide();
          this.toastr.warning("Tag has been removed", "Warning!", {
            positionClass: 'toast-bottom-right-custom'
          });
          this.dataService.publishDataChange$(data);
          this.queue = data?.data;
          this.dataService.publishDataChange$({
            action: "tagUpdate",
            queue: data?.data,
          });
        },
        (error) => {
          console.log("error", error);
          this.toastr.error("Tag has not been removed", "Failed!", {
            positionClass: 'toast-bottom-right-custom'
          });
          this.dataService.publishDataChange$(error);
        }
      );
  }

  getUniversalTag() {
    this.httpService
      .get$(`queues/universal-tag-set/${this.branchId}`)
      .toPromise()
      .then(
        (data) => {
          const dataFound = data.result.filter(tag => !tag.deleted);
          this.universalTags = dataFound;

        },
        (error) => {
          console.log("error", error);
        }
      );
  }


  ngOnInit(): void {
    if (this.queue) {
      this.getUniversalTag()
      this.getMinsHours();
      let index: number = 0 | -1;
      index = this.queue.fields.findIndex(x => x.fieldName == 'priority');
      if (index > -1) {
        const prio = this.queue.fields[index];
        if (prio.value == 'false' || prio.value == '') {
          this.queue.fields[index].value = 'false';
        } else {
          this.queue.fields[index].value = 'true';
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.timeQueued = this.queue.waitingTime;
    this.duration = this.timeQueued ? this.timeQueued : null;
    this.timerSubject$.next(0);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  textTransform(text) {
    let newText = "";
    newText = text.replace(/_+/g, " ");
    newText = this.titleCasePipe.transform(newText);
    return newText;
  }

  // PAL REQUEST
  tagsInputFocused(event: any) {
    this.openTransactionsModal()
  }

  // PAL REQUEST
  openTransactionsModal() {
    const initialState = {
      universalTags: this.universalTags,
      queueCurrentTags: this.queue.tags
    };

    this.bsModalRef = this.modalService.show(TransactionsModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    })

    this.bsModalRef.content.selectedTags.subscribe((data => {
      this.addTag(data)
    }))

    // this.bsModalRef.content.selectedDateRange.subscribe(
    //   (data) => {
  }
}
