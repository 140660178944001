import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TitleCasePipe } from '@angular/common'
import { ToastrService } from 'ngx-toastr'
import { Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { DataService } from 'src/app/core/services/data.service'
import { ReportsService } from '../../services/reports.service'
import { DeleteCustomReportModalComponent } from '../../component/delete-custom-report-modal/delete-custom-report-modal.component'
import { EMPTY, Subject, Subscription, forkJoin, of, throwError } from 'rxjs'
import { catchError, map, takeUntil, tap } from 'rxjs/operators'
import { SettingsService } from 'src/app/modules/settings/services/settings.service'

interface CheckboxList {
  value: string
  label: string
  checked?: boolean
}

interface CheckboxData {
  key: string
  checkedArray: number[]
}
@Component({
  selector: 'app-custom-reports-page',
  templateUrl: './custom-reports-page.component.html',
  styleUrls: ['./custom-reports-page.component.scss'],
  providers: [TitleCasePipe]
})
export class CustomReportsPageComponent implements OnInit, OnDestroy {
  pageDetails = [
    { type: "main", name: "Reports", routerLink: ["/reports"] },
    { type: "current", name: "Create Custom Report", routerLink: null },
  ];
  subscriptions = new Subscription()
  branchId = this.dataService.currentBranch$?.branchId
  type: string = ''
  customerDetails: Array<any> = []
  queueWorkspace: Array<any> = []
  queueDetails: Array<any> = []
  statistics: Array<any> = []
  teamMembers: Array<any> = []
  queueSource: Array<any> = []
  customerDetailsArr: number[] = []
  queueWorkspaceArr: number[] = []
  queueDetailsArr: number[] = []
  statisticsArr: number[] = []
  teamMembersArr: number[] = []
  queueSourceArr: number[] = []
  customFormFields: Array<any> = []
  customFormFieldsArr: number[] = []
  reportName: string = ''
  reportDescription: string = ''
  customContents: Array<string> = []
  isLoading: boolean = false
  isSubmitting: boolean = false
  reportContents: any
  reportData: any
  reportId: string = ''
  isChanged: boolean = false
  customFormFieldSubPageSubjectAPI$ = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleCasePipe: TitleCasePipe,
    private modalRef: BsModalRef,
    private reportService: ReportsService,
    private modalService: BsModalService,
    private dataService: DataService,
    private toastr: ToastrService,
    private settingsService: SettingsService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.type = this.activatedRoute.snapshot.queryParamMap.get('type');
    this.reportContents = this.dataService.getCustomReportData();
  
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe(async (res) => {
        if (res && this.branchId !== res?._id) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(`/reports`);
          });
        }
        await this.handleBranchChange(res);
      })
    );
  
    this.isLoading = true;
  
    const customFormFields$ = this.settingsService.customSmartForms('GET', this.branchId).pipe(
      takeUntil(this.customFormFieldSubPageSubjectAPI$),
      map((res) => res.result?.fields),
      tap((data) => {
        if (data && data.length > 0) {
          return of(data);
        } else {
          return EMPTY;
        }
      }),
      catchError((error) => {
        console.error("Error on fetching custom form fields", error);
        this.isLoading = false;
        return throwError(error);
      })
    );

    // Use forkJoin to wait for both observables to complete
    forkJoin([customFormFields$]).subscribe(([customFormFields]) => {
      if (this.reportContents) {
        this.customerDetails = this.addCheckedProperty(this.reportContents[0]);
        this.queueWorkspace = this.addCheckedProperty(this.reportContents[1]);
        this.queueDetails = this.addCheckedProperty(this.reportContents[2]);
        this.statistics = this.addCheckedProperty(this.reportContents[3]);
        this.teamMembers = this.addCheckedProperty(this.reportContents[4]);
        this.queueSource = this.addCheckedProperty(this.reportContents[5]);
      }
  
      if (customFormFields) {
        const customFields = customFormFields.map((item) => ({
          value: item.fieldName,
          label: item.label,
        }));
        this.customFormFields = this.addCheckedProperty(customFields);
        this.customerDetails = this.customerDetails.concat(this.customFormFields);
      }
  
      this.isLoading = false;
  
      if (this.type == 'create') {
        // create report logic
      } else {
        if (!localStorage.getItem('selectedReport')) {
          // this.router.navigate(['/not-found']);
          this.router.navigate([`/reports`]);
        } else {
          this.pageDetails[1].name = 'Edit Custom Report';
          this.reportData = this.dataService.getSelectedReport();
          this.patchValue(this.reportData);
        }
      }
    });
  }

  async handleBranchChange(res: any): Promise<void> {
    this.branchId = res ? res._id : this.dataService.currentBranch$.branchId;
  }

  addCheckedProperty(contents: Array<CheckboxList>) {
    if (contents) {
      const newContents = contents.map(content => ({
        ...content,
        checked: false // for checkbox
      }))
      return newContents
    }
  }

  patchValue(report: any): void {
    if (report) {
      this.reportId = report._id
      this.reportName = report.name
      this.reportDescription = report.description
      if (report.contents && report.contents.length > 0) {
        for (var i = 0; i < report.contents.length; ++i) {
          const contentValue = report.contents[i]
          let arrayIdx: number = this.findContentDetails(contentValue)
          let itemIdx: number;
          if (arrayIdx != -1) {
            itemIdx = this.findContentIndex(report.contents[i], arrayIdx)
            if (itemIdx != -1) {
              this.storeSelectedContents(arrayIdx, itemIdx)
            }
          } else {
            const fieldObj = this.customFormFields.filter(content => content?.value == contentValue)
            if (fieldObj) {
              let idx: number = this.customerDetails.findIndex(
                (content) => content.value === contentValue
              )
              if (idx != -1) {
                this.storeSelectedContents(0, idx)
              }
            }
          }
          if (i == (report.contents.length - 1)) {
            this.updateCheckboxes()
          }
        }
      }
    }
  }

  updateCheckboxes() {
    const checkboxDataArray = [
      { key: 'customerDetails', checkedArray: this.customerDetailsArr },
      { key: 'queueWorkspace', checkedArray: this.queueWorkspaceArr },
      { key: 'queueDetails', checkedArray: this.queueDetailsArr },
      { key: 'statistics', checkedArray: this.statisticsArr },
      { key: 'teamMembers', checkedArray: this.teamMembersArr },
      { key: 'queueSource', checkedArray: this.queueSourceArr },
    ];
  
    for (const checkboxData of checkboxDataArray) {
      this.changeCheckboxList(checkboxData);
    }
  
    this.isLoading = false;
  }

  displayContents(contents: any, index: number) {
    const array: [] = contents
    const string = array.join(', ') + ','
    let result: string = '-'
    if (contents && contents.length > 0) {
      result = string.slice(0, -1)
      if (contents.length == this.reportContents[index].length) {
        result = 'All'
      }
    }
    return result
  }

  storeSelectedContents(index: number, itemIndex: number): void {
    const reportArr = [
      this.customerDetailsArr,
      this.queueWorkspaceArr,
      this.queueDetailsArr,
      this.statisticsArr,
      this.teamMembersArr,
      this.queueSourceArr,
    ];

    reportArr[index].push(itemIndex);
  }

  findContentDetails(detail: string): number {
    const searchValue = detail
    const property = 'value'
    let index: number = -1

    index = this.reportContents
      .findIndex(array => array
        .some(obj => obj[property] === searchValue))
    return index
  }

  findContentIndex(detail: string, index: number): number {
    const searchValue = detail
    let idx: number = -1

    idx = this.reportContents[index].findIndex(
      (content) => content.value === searchValue
    )
    return idx
  }

  changeCheckboxList($event: CheckboxData) {
    if ($event?.key) {
      const key = $event.key;
      const reportsProperties: { [key: string]: any[] } = {
        'customerDetails': this.customerDetails,
        'queueWorkspace': this.queueWorkspace,
        'queueDetails': this.queueDetails,
        'statistics': this.statistics,
        'teamMembers': this.teamMembers,
        'queueSource': this.queueSource,
      };
  
      if (key in reportsProperties) {
        reportsProperties[key] = this.updateSelectedContents(reportsProperties[key], $event.checkedArray);
      }
  
      this.customContents = this.mergeSelectedContents();
    }
  }

  updateSelectedContents(contents: Array<CheckboxList>, selected: Array<any>) {
    const length = contents.length
    let arr = contents
    for (var i = 0; i < length; ++i) {
      arr[i].checked = selected.includes(i) ? true : false
    }
    return arr
  }

  mergeSelectedContents() {
    const mergedArr: CheckboxList[] = this.customerDetails
      .concat(
        this.queueWorkspace,
        this.queueDetails,
        this.statistics,
        this.teamMembers,
        this.queueSource,
      )
    const filterArr: CheckboxList[] = mergedArr.filter(item => item.checked === true)
    const customContents: string[] = filterArr.map(item => item.value)

    return customContents
  }

  get disableSave() {
    if ((this.reportName == '' || this.reportName.trim() == '') || this.customContents.length == 0) {
      return true
    }
    return false
  }

  saveCustomReport() {
    this.isSubmitting = true
    const payload = {
      name: this.titleCasePipe.transform(this.reportName).replace(/\s+/g, ' ').trim(),
      description: this.reportDescription ? this.reportDescription.replace(/\s+/g, ' ').trim() : '',
      contents: this.customContents
    }
    if (payload) {
      if (this.type == 'create') {
        this.subscriptions.add(
          this.reportService.customReports('POST', this.branchId, payload).subscribe(res => {
            if (res.success) {
              this.isSubmitting = false
              this.toastr.success('Custom Report has been added.', 'Success!')
              this.router.navigate(['/reports'])
            }
          }, (error) => {
            const errMsg = error.error.errors;
            console.log('add custom report error', error)
            if (errMsg) {
              this.toastr.error(errMsg, 'Error!')
            }
            this.isSubmitting = false
          })
        )
      } else {
        this.subscriptions.add(
          this.reportService.customReports('PATCH', this.branchId, payload, this.reportId).subscribe(res => {
            if (res.success) {
              this.isSubmitting = false
              this.toastr.success('Custom Report has been updated.', 'Success!')
              this.router.navigate(['/reports'])
            }
          }, (error) => {
            const errMsg = error.error.errors;
            console.log('update custom report error', error)
            if (errMsg) {
              this.toastr.error(errMsg, 'Error!')
            }
            this.isSubmitting = false
          })
        )
      }
    }
  }

  deleteCustomReport() {
    const initialState = {
      reportId: this.reportId,
      reportName: this.reportName,
      branchId: this.branchId,
    }
    this.modalRef = this.modalService.show(DeleteCustomReportModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false
    })

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if (data == 'delete') {
          this.subscriptions.add(
            this.reportService.customReports('DELETE', this.branchId, null, this.reportId).subscribe(res => {
              if (res) {
                this.toastr.error(`${this.reportName} has been deleted.`, 'Deleted!');
                this.router.navigate(['/reports'])
              }
            })
          )
        }
      },
      (err) => {
        console.log("delete custom report error", err)
        return false
      }
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe(); // Unsubscribe from all subscriptions
    localStorage.removeItem('selectedReport');
  }
}
