import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  DateFilters,
  MonitoringDataTypes,
} from "../interfaces/monitoring.interface";

@Injectable({
  providedIn: "root",
})
export class MonitoringService {
  urlAPI = environment.urlAPI;

  constructor(private http: HttpClient) {}

  private get httpHeader(): any {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    // .set('Cache-Control', 'no-cache')
    return {
      headers,
    };
  }

  public getMonitoringDashboardData(
    businessId: string,
    dateFilter: string | DateFilters,
    isSingleDate?: boolean
  ): Observable<any> {
    const dateFilters = typeof dateFilter === "object" ? dateFilter : {};
    const dateFilterParams: string[] = [];

    for (const [key, value] of Object.entries(dateFilters)) {
      if (value) {
        dateFilterParams.push(`${key}=${value}`);
      }
    }

    const preUrl =
      dateFilterParams.length > 0
        // ? `filterBy=custom&${dateFilterParams.join("&")}`
        ? `filterBy=${
          isSingleDate ? "singledate" : "custom"}&${dateFilterParams.join("&")}`
        : `filterBy=${dateFilter}`;
    const url = `${this.urlAPI}/business/${businessId}/monitoring/dashboard?${preUrl}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getBranchPerformanceData(
    data: MonitoringDataTypes,
    purpose?: string,
    isSingleDate?: boolean
  ): Observable<any> {
    const dateFilters =
      typeof data.dateFilter === "object" ? data.dateFilter : {};
    const dateFilterParams: string[] = [];

    for (const [key, value] of Object.entries(dateFilters)) {
      if (value) {
        dateFilterParams.push(`${key}=${value}`);
      }
    }

    const serviceParam =
      data.selectedServiceId !== "all"
        ? `&servicesId=${data.selectedServiceId}`
        : "";
    const preUrl =
      dateFilterParams.length > 0
        ? `filterBy=${
            isSingleDate && purpose === "chart" ? "singledate" : "custom"
          }&${dateFilterParams.join("&")}${serviceParam}`
        : `filterBy=${data.dateFilter}${serviceParam}`;
    const targetUrl =
      purpose === "chart" ? "branch-performance-chart" : "branch-performance";
    const url = `${this.urlAPI}/queues/${data.branchId}/monitoring/${targetUrl}?${preUrl}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getVisitorVolumesData(
    data: MonitoringDataTypes,
    isSingleDate?: boolean
  ): Observable<any> {
    const dateFilters =
      typeof data.dateFilter === "object" ? data.dateFilter : {};
    const dateFilterParams: string[] = [];

    for (const [key, value] of Object.entries(dateFilters)) {
      if (value) {
        dateFilterParams.push(`${key}=${value}`);
      }
    }

    const serviceParam =
      data.selectedServiceId !== "all"
        ? `&servicesId=${data.selectedServiceId}`
        : "";
    const preUrl =
      dateFilterParams.length > 0
        ? `filterBy=${
            isSingleDate ? "singledate" : "custom"
          }&${dateFilterParams.join("&")}${serviceParam}`
        : `filterBy=${data.dateFilter}${serviceParam}`;
    const url = `${this.urlAPI}/queues/${data.branchId}/monitoring/visitor-volumes?${preUrl}`;

    return this.http.get<any>(url, this.httpHeader);
  }

  getBranchListDetails(
    branchId: string,
    dateFilter: string | DateFilters
  ): Observable<any> {
    const url = `${this.urlAPI}/queues/${branchId}/monitoring/branch-list-details?filterBy=${dateFilter}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  getBusinessBranches(businessId: string): Observable<any> {
    const url = `${this.urlAPI}/branches/list/${businessId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getStaffPerformance(branchId: string, params): Observable<any> {
    console.log(params)
    const url = `${this.urlAPI}/accounts/branch/${branchId}/monitoring/staff-performance?${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  // public getWindowData(params, branchId: string): Observable<any> {
  //   const url = `${this.urlAPI}/accounts/branch/${branchId}/monitoring/list?${params}`;
  //   return this.http.get<any>(url, this.httpHeader); 
  // }
}
