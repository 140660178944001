import { HttpParams } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  OnDestroy
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { HttpService } from "src/app/core/http/http.service";
import { DatePickerModalComponent } from "src/app/shared/components/date-picker-modal/date-picker-modal.component";
import { DataService } from "src/app/core/services/data.service";
import { ReportsService } from "../../services/reports.service";
import { TeamManagementService } from "src/app/modules/team-management/services/team-management.service";
import { TitleCasePipe, DatePipe } from '@angular/common'
import * as ExcelJS from "exceljs/dist/exceljs.min.js"
import * as fs from "file-saver"

@Component({
  selector: "app-report-filter-modal",
  templateUrl: "./report-filter-modal.component.html",
  styleUrls: ["./report-filter-modal.component.scss"],
  providers: [TitleCasePipe, DatePipe]
})
export class ReportFilterModalComponent implements OnInit, OnDestroy {
  accountData = this.dataService.accountData$
  isDownloading: boolean = false
  title: string;
  subtitle: string;
  selectedDateFilter = "today";
  selectedDateFilterLabel = "Today";
  customDate1: any;
  customDate2: any;
  @Output() generate = new EventEmitter();
  isFocused = false;
  isSelectClicked: boolean;
  isButtonClicked: boolean;
  selectedValue = "today";
  selectedLabel = "Today";
  reportType: string = '';
  selectedWindows: any;
  branchId: string;
  serviceWindowLists: any;
  clear = false;
  filterType: any;
  subscriptions = new Subscription();
  accountList: Array<any> = []
  customErrMsg: string = ''
  dateFilter = [
    {
      value: "today",
      label: "Today",
    },
    {
      value: "yesterday",
      label: "Yesterday",
    },
    {
      value: "this-week",
      label: "This Week",
    },
    {
      value: "this-month",
      label: "This Month",
    },
    // {
    //   value: "this-year",
    //   label: "This Year",
    // },
    {
      value: "custom",
      label: "Custom",
    },
  ];
  
  constructor(
    private modalService: BsModalService,
    private httpService: HttpService,
    public bsModalRef: BsModalRef,
    public bsModalRef2: BsModalRef,
    private dataService: DataService,
    private teamService: TeamManagementService,
    private reportService: ReportsService,
    private titleCasePipe: TitleCasePipe,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    console.log(this.filterType)
    this.getAccounts();
    if (this.filterType === 'service') {
      this.getServiceLists()
    } else {
      this.getWindowList()
    }
  }

  getAccounts() {
    this.teamService.getTeamList("", this.branchId).subscribe((res) => {
      this.accountList = res.data;
    }, (error) => {
      console.log('error on fetching account lists', error)
    });
  }

  formatDate1(date) {
    const d = new Date(date);
    return `${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`;
  }

  generateReport() {
    this.isDownloading = true;
    let params = new HttpParams();
    params = params.append("filterBy", this.selectedValue);


    if (this.selectedValue === "custom") {
      params = params.append("startDate", new Date(this.customDate1).getTime().toString());
      params = params.append("endDate", new Date(this.customDate2).getTime().toString());
    }

    if (this.selectedWindows && this.selectedWindows.length > 0) {
      let windowIds = [];
      this.selectedWindows.forEach(x => {
        windowIds.push(x.value)
      });
      params = params.append(`${this.filterType}sId`, windowIds.join(','));
    } else {
      params = params.append(`${this.filterType}sId`, 'show-all');
    }
    // this.bsModalRef.hide();
    // this.generate.emit(params);
    this.getReportData(params)
  }

  datePeriod: any
  getDatePeriod(data) {
    const curr = new Date() // get current date
    const first = curr.getDate() - curr.getDay() // First day is the day of the month - the day of the week
    const firstday = new Date(curr.setDate(first)).toUTCString()

    let date: any

    switch (data.filterBy) {
      case "today":
        this.datePeriod = this.datePipe.transform(new Date(), "MMMM dd, yyyy")
        break
      case "yesterday":
        date = new Date().setDate(new Date().getDate() - 1)
        this.datePeriod = this.datePipe.transform(date, "MMMM dd, yyyy")
        break
      case "this-week":
        date = new Date(firstday).setDate(new Date(firstday).getDate())
        const date2 = new Date(firstday).setDate(
          new Date(firstday).getDate() + 6
        )
        this.datePeriod =
          this.datePipe.transform(date, "MMMM dd, yyyy") +
          " - " +
          this.datePipe.transform(date2, "MMMM dd, yyyy")
        break
      case "this-month":
        this.datePeriod = this.datePipe.transform(curr, "MMMM yyyy")
        break
      case "this-year":
        this.datePeriod = this.datePipe.transform(curr, "yyyy")
        break
      case "custom":
        const start = new Date(parseInt(data.startDate))
        const end = new Date(parseInt(data.endDate))
        this.datePeriod =
          this.datePipe.transform(start, "MMMM dd, yyyy") +
          " - " +
          this.datePipe.transform(end, "MMMM dd, yyyy")
        break
    }
  }

  getReportData(params: HttpParams) {
    const paramsObject = params.keys().reduce((object, key) => {
      object[key] = params.get(key);
      return object;
    }, {});
    this.getDatePeriod(paramsObject);

    if (this.reportType === "arrivalTrend") {
      this.subscriptions.add(
        this.reportService
        .getCustomerArrival(params, this.branchId)
        .subscribe((res) => {
          if (res && res.data) {
            this.generateCustomerArrivalReport(res.data);
          }
        }, (error) => {
          console.log('error on fetching queue counts data', error);
          this.isDownloading = false;
        })
      );
    } else if (this.reportType === "waitingTime") {
      this.subscriptions.add(
        this.reportService
        .getAvgWaitingTime(params, this.branchId)
        .subscribe((res) => {
          if (res && res.data) {
            this.generateAvgWaitingReport(res.data);
          }
        }, (error) => {
          console.log('error on fetching queue counts data', error);
          this.isDownloading = false;
        })
      );
    } else if (this.reportType === "servingTime") {
      this.subscriptions.add(
        this.reportService
        .getAvgServingTime(params, this.branchId)
        .subscribe((res) => {
          if (res && res.data) {
            this.generateAvgServingTimeReport(res.data);
          }
        }, (error) => {
          console.log('error on fetching queue counts data', error);
          this.isDownloading = false;
        })
      );
    }

  }

  generateAvgServingTimeReport(allData) {
    // Excel Title, Header, Data

    // Create workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(
      "Average Queue Serving Time report"
    );

    // Add Row and formatting
    const titleRow = worksheet.addRow(["Average Serving Time Report"]);
    titleRow.font = { name: "Arial", family: 4, size: 14 };
    const subTitleRow = worksheet.addRow([
      "Date & Time Generated: " +
        this.datePipe.transform(new Date(), "EEEE, MMMM dd, yyyy hh:mm a"),
    ]);
    const subTitleRow2 = worksheet.addRow([
      `Generated By: ${this.accountData.data.firstName} ${this.accountData.data.lastName}`,
    ]);
    const subTitleRow4 = worksheet.addRow([
      "Period Covered: " + this.datePeriod,
    ]);

    // const subTitleRow = worksheet.addRow(["Date & Time Generated: "]);
    // const subTitleRow2 = worksheet.addRow(["Generated By: "]);
    // subTitleRow.font = { name: "Arial", bold: true };
    // subTitleRow2.font = { name: "Arial", bold: true };

    worksheet.addRow([]);
    // const preheadRow = worksheet.addRow(["Queue Group: "]);
    // const preheadRow2 = worksheet.addRow(["Period Covered: "]);

    const title = `${this.titleCasePipe.transform(this.filterType)} Name`;
    const header = ["Date", title, "Average Queue Serving Time"];

    // Add Header Row
    const headerRow = worksheet.addRow(header);

    headerRow.eachCell((cell, number) => {
      cell.font = { name: "Arial", bold: true };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });
    
    // Add Data and Conditional Formatting
    allData.forEach((d) => {
      const arrayData = [this.formatDate1(d.range.date), this.filterType === "service" ? d.serviceName : d.windowName, (d.counts.avgServingTime ? d.counts.avgServingTime : 0)];
      const row = worksheet.addRow(arrayData);
      const number2 = row.getCell(2);
      number2.alignment = { vertical: "middle", horizontal: "center" };

      const number3 = row.getCell(3);
      number3.alignment = { vertical: "middle", horizontal: "right" };
    });

    // width
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 35;
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      fs.saveAs(blob, "Average Queue Serving Time Report.xlsx");
    });
    this.bsModalRef.hide()
  }


  generateAvgWaitingReport(allData) {
    // Excel Title, Header, Data

    // Create workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(
      "Average Queue Waiting Time report"
    );

    // Add Row and formatting
    const titleRow = worksheet.addRow(["Average Waiting Time Report"]);
    titleRow.font = { name: "Arial", family: 4, size: 14 };
    const subTitleRow = worksheet.addRow([
      "Date & Time Generated: " +
        this.datePipe.transform(new Date(), "EEEE, MMMM dd, yyyy hh:mm a"),
    ]);
    const subTitleRow2 = worksheet.addRow([
      `Generated By: ${this.accountData.data.firstName} ${this.accountData.data.lastName}`,
    ]);
    const subTitleRow4 = worksheet.addRow([
      "Period Covered: " + this.datePeriod,
    ]);

    // const subTitleRow = worksheet.addRow(["Date & Time Generated: "]);
    // const subTitleRow2 = worksheet.addRow(["Generated By: "]);
    // subTitleRow.font = { name: "Arial", bold: true };
    // subTitleRow2.font = { name: "Arial", bold: true };

    worksheet.addRow([]);
    // const preheadRow = worksheet.addRow(["Queue Group: "]);
    // const preheadRow2 = worksheet.addRow(["Period Covered: "]);

    const title = `${this.titleCasePipe.transform(this.filterType)} Name`;
    const header = ["Date", title, "Average Queue Waiting Time"];

    // Add Header Row
    const headerRow = worksheet.addRow(header);

    headerRow.eachCell((cell, number) => {
      cell.font = { name: "Arial", bold: true };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // Add Data and Conditional Formatting
    allData.forEach((d) => {
      const arrayData = [this.formatDate1(d.range.date), this.filterType === "service" ? d.serviceName : d.windowName, (d.counts.avgWaitingTime ? d.counts.avgWaitingTime : 0)];
      const row = worksheet.addRow(arrayData);
      const number2 = row.getCell(2);
      number2.alignment = { vertical: "middle", horizontal: "center" };

      const number3 = row.getCell(3);
      number3.alignment = { vertical: "middle", horizontal: "right" };
    });

    // width
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 35;
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      fs.saveAs(blob, "Average Queue Waiting Time Report.xlsx");
    });
    this.bsModalRef.hide()
  }

  generateCustomerArrivalReport(allData) {
    // Excel Title, Header, Data

    // Create workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Customer Arrival report");

    // Add Row and formatting
    const titleRow = worksheet.addRow(["Customer Arrival Report"]);
    titleRow.font = { name: "Arial", family: 4, size: 14 };
    const subTitleRow = worksheet.addRow([
      "Date & Time Generated: " +
        this.datePipe.transform(new Date(), "EEEE, MMMM dd, yyyy hh:mm a"),
    ]);
    const subTitleRow2 = worksheet.addRow([
      `Generated By: ${this.accountData.data.firstName} ${this.accountData.data.lastName}`,
    ]);
    const subTitleRow3 = worksheet.addRow([
      "Period Covered: " + this.datePeriod,
    ]);

    worksheet.addRow([]);

    // const preheadRow = worksheet.addRow(["Queue Group: "]);
    // const preheadRow2 = worksheet.addRow(["Period Covered: "]);
    // preheadRow.font = { name: "Arial", bold: true };
    // preheadRow2.font = { name: "Arial", bold: true };

    const title = `${this.titleCasePipe.transform(this.filterType)} Name`;
    let header = ["Date", title];

    allData.headers.forEach((x) => {
      header.push(x.time);
    });

    header.push("TOTAL");

    // Add Header Row
    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.font = { name: "Arial", bold: true };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    allData.counts.forEach((d) => {
      const total = d.counts.reduce((a, b) => a + b, 0);
      let arrayData = [
        this.formatDate1(d.range.date),
        this.filterType === "service" ? d.serviceName : d.windowName,
      ];

      d.counts.forEach((x) => {
        arrayData.push(x);
      });
      arrayData.push(total);

      const row = worksheet.addRow(arrayData);

      for (let index = 2; index < header.length; index++) {
        const element = index + 1;
        row.getCell(element).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
      }
    });

    for (let index = 0; index < header.length; index++) {
      const element = index + 1;
      worksheet.getColumn(element).width = 30;
    }

    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      fs.saveAs(blob, "Customer Arrival Report.xlsx");
    });
    this.bsModalRef.hide()
  }

  selectFilter(ev) {
    this.selectedWindows = ev;
  }

  getWindowList() {
    this.httpService
      .get$(`services/windows/${this.branchId}?limit=999`)
      .subscribe((data) => {
        this.serviceWindowLists = data.data.map((element) => {
          return {
            label: element.displayName,
            value: element._id,
          };
        });
      });
  }

  getServiceLists() {
    this.httpService.get$(`services/${this.branchId}?limit=999`).subscribe((data) => {
      this.serviceWindowLists = data.data.map((element) => {
        return {
          label: element.displayName,
          value: element._id,
        };
      });
    });
  }
  
  selectCustomDateFilter() {
    const initialState = {
      hasMinDate: false,
      datePickerType: 'range',
      customReport: true
    }
    this.bsModalRef2 = this.modalService.show(DatePickerModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md2",
      ignoreBackdropClick: true,
      keyboard: false
    })

    this.bsModalRef2.content.selectedDateRange.subscribe(
      (data) => {
        if (data) {
          if (data == 'cancel') {
            this.customErrMsg = ''
          } else {
            if (data[0] && data[1]) {
              this.selectedValue = 'custom'
              this.customDate1 = (data[0] as Date).toLocaleDateString("en-US")
              this.customDate2 = (data[1] as Date).toLocaleDateString("en-US")
              this.selectedLabel = this.customDate1 + " - " + this.customDate2
              this.selectedDateFilterLabel = this.customDate1 + " - " + this.customDate2

              const startDate = new Date(this.customDate1)
              const endDate = new Date(this.customDate2)

              const threeMonthsLater = new Date(startDate.getTime()) // Create a new date object with the same time as the start date
              threeMonthsLater.setMonth(startDate.getMonth() + 3) // Add three months to the new date object

              if (endDate > threeMonthsLater) {
                // Do something if the selected dates are invalid
                this.customErrMsg = 'Selected dates must not be more than 3 months'
              } else {
                // Do something if the selected dates are valid
                this.customErrMsg = ''
              }
            } else {
              this.customErrMsg = ''
            }
          }
        }
      },
      (err) => {
        return false
      }
    )
  }

  select() {
    this.isSelectClicked = !this.isSelectClicked
    this.isFocused = !this.isFocused
  }

  @HostListener("document:click")
  deselect() {
    if (!this.isSelectClicked || this.isButtonClicked) {
      this.isFocused = false
    }
    this.isButtonClicked = false
    this.isSelectClicked = false
  }

  selectDropdown(list) {
    if (list.value === "custom") {
      this.selectCustomDateFilter()
    } else {
      this.selectedValue = list.value
      this.selectedLabel = list.label
    }
    this.isButtonClicked = true
    if (!this.isFocused) {
      this.isButtonClicked = false
    }
  }

  clearFilter(){
    this.clear = !this.clear;
    this.select();
    this.deselect();
    this.selectDropdown({value: "today", label: "Today"})
    this.customErrMsg = '';
  }

  cancel() {
    this.generate.emit(
      {action: 'cancel', selected: this.filterType}
    );
    this.bsModalRef.hide();
  }

  closeModal() {
    this.generate.emit('close');
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
