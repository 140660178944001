import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { defaultAvatar } from 'src/app/core/services/global';
import { getAccountAvatar, getAccountName } from 'src/app/shared/helpers/team-management.helpers';
import { getDuration, getDuration2 } from 'src/app/shared/helpers/time.helpers';

@Component({
  selector: 'app-owner-dashboard',
  templateUrl: './owner-dashboard.component.html',
  styleUrls: ['./owner-dashboard.component.scss']
})
export class OwnerDashboardComponent implements OnInit, OnChanges {
  dateToday = new Date();
  dateNow = Date.now();
  defaultAvatar: string = defaultAvatar;
  @Input() servicesCount: any;
  @Input() accountList: any;
  @Input() serviceCategories: any;
  @Input() queuesCount: any;
  @Input() activeQueuesCount: any;
  @Input() currentQueuesCount: any;
  @Input() windowsCount: any;
  serviceLists: any;
  ONE_HOUR: number = 3600000;

  constructor() { }

  ngOnInit(): void {}

  getServiceName(id: string): string {
    const service = this.serviceLists.find((x: { _id: string; }) => x._id === id);
    return service ? service.name : '';
  }

  getServiceCategoryName(id: string, categoryId: string) {
    let serviceName: string[] = [];
    if (id && this.serviceLists) {
      const service = this.serviceLists.find((x: { _id: string; }) => x._id === id);
      if (service) {
        serviceName = [service.displayName];
      }
    }

    let serviceCategoryName: string[] = [];
    if (categoryId && this.serviceCategories) {
      const category = this.serviceCategories.find((x: { _id: string; }) => x._id === categoryId);
      if (category?.parentCategoryId) {
        const parentCategory = this.serviceCategories.find((x) => x._id === category?.parentCategoryId);
        if (parentCategory?.parentCategoryId) {
          const grandParentCategory = this.serviceCategories.find((x) => x._id === parentCategory?.parentCategoryId);
          serviceCategoryName = [
            grandParentCategory?.displayName,
            parentCategory?.displayName,
            category?.displayName,
          ];
        } else {
          serviceCategoryName = [parentCategory?.displayName, category?.displayName];
        }
      } else {
        serviceCategoryName = [category?.displayName];
      }
    }

    return {
      serviceCategory: serviceCategoryName,
      serviceName: serviceName,
    };
  }

  getAccountDetails(accountId: string) {
    return this.accountList?.find((x: { _id: string; }) => x._id === accountId) || {};
  }

  getAccountAvatar(accountId: string): string {
    return getAccountAvatar(this.accountList, accountId);
  }

  getAccountName(accountId: string): string {
    return getAccountName(this.accountList, accountId);
  }
  
  getAccountActivityStatus(accountId: string): string {
    const account = this.getAccountDetails(accountId);
    return account.activityStatus || 'offline';
  }

  getDuration(milliseconds: number): string {
    return milliseconds > 0 ? getDuration(milliseconds) : '';
  }

  isOccupied(occupied: any): boolean {
    return !!occupied.queue;
  }

  isOneHourOrMoreAgo(updatedAt: number): boolean {
    return getDuration2(this.dateNow - updatedAt).isOneHourOrMore;
  }

  getServingTime(servedAt: number): string {
    return servedAt > 0 ? this.getDuration(this.dateNow - servedAt) : '--:--';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.servicesCount && changes.servicesCount.currentValue) {
      this.serviceLists = changes.servicesCount.currentValue;
      const enabledServices = this.servicesCount.filter((x: { suspended: boolean; }) => !x.suspended);
      this.servicesCount = enabledServices;
    }
    
    if (changes.windowsCount && changes.windowsCount.currentValue) {
      this.windowsCount = changes.windowsCount.currentValue;
    }

    if (changes.currentQueuesCount && changes.currentQueuesCount.currentValue) {
      this.currentQueuesCount = changes.currentQueuesCount.currentValue;
    }

    if (changes.activeQueuesCount && changes.activeQueuesCount.currentValue) {
      this.activeQueuesCount = changes.activeQueuesCount.currentValue;
    }

    if (changes.queuesCount && changes.queuesCount.currentValue) {
      this.queuesCount = changes.queuesCount.currentValue;
    }

    if (changes.accountList && changes.accountList.currentValue) {
      const accountLists = changes.accountList.currentValue;
      const mappedAccountLists = accountLists.map((account) => ({
        ...account,
        name: account.firstName + " " + account.lastName,
      }));
      this.accountList = mappedAccountLists;
    }

    if (changes.serviceCategories && changes.serviceCategories.currentValue) {
      this.serviceCategories = changes.serviceCategories.currentValue;
    }
  }
}