import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ClipboardService } from "ngx-clipboard";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { TeamManagementService } from "../../services/team-management.service";
import { BranchManagementService } from "src/app/modules/branch-management/services/branch-management.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-add-account-modal",
  templateUrl: "./add-account-modal.component.html",
  styleUrls: ["./add-account-modal.component.scss"],
})
export class AddAccountModalComponent implements OnInit {
  step = 1;
  passwordShown = false;

  accountForm = this.fb.group({
    email: [
      null,
      [
        Validators.required,
        Validators.pattern(
          /^[a-zA-Z0-9\_\-\.]+\@[a-zA-Z0-9]{1,100}(\.[a-zA-Z0-9]{1,7}){1,3}$/
        ),
      ],
    ],
    password: ["", [Validators.required, Validators.minLength(6)]],
    firstName: [
      null,
      [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(/^[A-Za-z- ñÑ.]*$/),
      ],
    ],
    lastName: [
      null,
      [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(/^[A-Za-z- ñÑ.]*$/),
      ],
    ],
    mobileNo: [
      "",
      [Validators.minLength(10), Validators.pattern(/^[\d -]{12}$/)],
    ],
  });

  selectedAccountRole: any;
  selectedPhoto: any;

  isSubmitting = false;
  branchId: string;
  branchData: string;
  @ViewChild("password", { static: false }) password: any;
  emailIsAvailable = true;
  accountExists = false;

  @Output() successEvent = new EventEmitter();
  errMsg: string;
  emailErrMsg: string;
  errParam: string;
  branchName: string;
  subscriptions = new Subscription;

  constructor(
    private modalRef: BsModalRef,
    private fb: FormBuilder,
    private teamService: TeamManagementService,
    private clipboardApi: ClipboardService,
    private dataService: DataService,
    private httpService: HttpService,
    private branchService: BranchManagementService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.branchService.getBranchDetails(this.branchId).subscribe(res => {
        this.branchName = res.data.name;
      })
    );
  }

  get f() {
    return this.accountForm.controls;
  }

  cancel() {
    this.modalRef.hide();
  }

  checkAvailability() {
    if (!this.f["email"]?.errors) {
      this.isSubmitting = true;
      this.teamService
        .checkAvailability(this.accountForm.value.email, this.branchId)
        .subscribe(
          (res) => {
            console.log("AVAILABILITY", res);
            this.isSubmitting = false;
            if (res && res.data.isAvailable) {
              if (res.data.assignedBranchAccounts && res.data.assignedBranchAccounts.length > 0) {
                this.step = this.step + 2;
                this.accountExists = true;
              } else {
                this.step++;
                this.accountExists = false;
              }
            }
            this.emailIsAvailable = res.data.isAvailable;
          },
          (error) => {
            console.log("error", error);
            this.emailErrMsg = error.error.errors[0].msg;
            this.isSubmitting = false;
          }
        );
    }
  }

  back() {
    if (this.accountExists && this.step === 3) {
      this.step = 1;
    } else {
      this.step--;
    }
  }

  showPassword() {
    this.passwordShown = !this.passwordShown;
  }

  public proceedPassword() {
    if (!this.f["email"]?.errors && !this.f["password"]?.errors) {
      this.step++;
      this.passwordShown = false;
    }
  }

  public proceed() {
    if (this.accountExists && this.step === 3) {
      this.submitAccount();
    } else {
      this.step++;
    }
  }

  async submitPhoto(accountId) {
    console.log("upload photo", this.selectedPhoto);
    const param = `accounts/${this.branchId}/${accountId}/presign-image-url`;
    await this.httpService
      .uploadPhoto(param, this.selectedPhoto)
      .then(async (data) => {
        console.log("submitted", data);
        this.successEvent.emit(data);
      })
      .catch(async (error) => {});
  }

  public submitAccount() {
    this.errMsg = null;
    this.errParam = null;
    this.isSubmitting = true;
    // this.f['mobileNo'].patchValue(this.f['mobileNo'].value.replace(/[^0-9 ]/g, ""));
    const formData = {
      firstName: this.accountExists ? "N/A" : this.accountForm.value.firstName,
      lastName: this.accountExists ? "N/A" : this.accountForm.value.lastName,
      email: this.accountForm.value.email,
      password: this.accountForm.value.password,
      contactNo: this.accountForm.value.mobileNo && this.accountForm.value.mobileNo.length != 1
        ? this.accountForm.value.mobileNo.replaceAll("-", "")
        : "",
      roleLevel: this.selectedAccountRole === 3 ? "branch-manager" : "staff",
    };

    this.teamService.addAccount(formData, this.branchId).subscribe(
      async (res) => {
        console.log("ADD ACCOUNT SUCCESS", res);
        if (this.selectedPhoto) {
          await this.submitPhoto(res.data._id);
        } else {
          this.successEvent.emit(res);
        }
        this.isSubmitting = false;
        if (this.accountExists) {
          this.step = this.step + 2;
        } else {
          this.step++
        }
      },
      (error) => {
        console.log("error", error);
        this.errParam = error.error.errors[0].param;
        this.errMsg = error.error.errors[0].msg;
        this.isSubmitting = false;
      }
    );
  }

  selectRole(value) {
    this.selectedAccountRole = value;
  }

  uploadPhoto(event) {
    this.selectedPhoto = event;
  }

  addAnotherAccount() {
    this.step = 1;
    this.accountForm.reset();
    this.selectedAccountRole = 0;
    this.passwordShown = false;
  }

  isOpen = false;
  copyToClipboard() {
    const cope = `Email:${this.accountForm.value.email}, Password:${this.accountForm.value.password}`;
    this.clipboardApi.copyFromContent(cope);

    this.isOpen = !this.isOpen;
    setTimeout(() => {
      this.isOpen = !this.isOpen;
    }, 1500);
  }

  get randomPassword() {
    const chars =
      "abcdefghijklmnopqrstuvwxyz!@#$%&*-+ABCDEFGHIJKLMNOP1234567890";
    let pass = "";
    for (var x = 0; x < 8; x++) {
      var i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
    console.log(pass);
    return pass;
  }

  generatePassword() {
    this.accountForm.controls.password.patchValue(this.randomPassword);
    this.passwordShown = true;
    this.password.nativeElement.focus();
  }
}
