import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, ChangeDetectorRef} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';

interface CheckboxList {
  label: string;
  checked: boolean;
}

@Component({
  selector: 'app-checkbox-list-v2',
  templateUrl: './checkbox-list-v2.component.html',
  styleUrls: ['./checkbox-list-v2.component.scss']
})
export class CheckboxListV2Component implements OnInit {
  @Input() checkboxListTitle: string = '';  
  @Input() checkboxListDetails: Array<any> = [];
  @Input() checkboxListKey: string = '';
  @Input() showCarret: boolean = false;
  @Input() isLastContent: boolean = false;
  @Input() isUpdate: boolean = false;
  @Output() changedCheckboxLists = new EventEmitter();
  contentsForm: FormGroup;
  hideContents: boolean = false;
  selectAllContents: boolean = false;
  filteredCheckbox: Array<any>= [];

  constructor(
    private formBuilder: FormBuilder
  ) { 
    this.contentsForm = this.formBuilder.group({
      contents: this.formBuilder.array([])
    });
  }

  ngOnInit(): void {
    if (this.checkboxListDetails) {
      this.checkboxListDetails = this.checkboxListDetails.map((element) => {
        return {
          label: element.label,
          checked: element.checked
        };
      }),
      this.initForm(this.checkboxListDetails)
      if (this.isUpdate) {
        for (var i=0; i<this.checkboxListDetails.length; ++i) {
          this.onChange(this.checkboxListDetails[i].checked, i)
        }
      }
    }
  }

  get contents(): FormArray {
    return this.contentsForm.get('contents') as FormArray
  }

  initForm(contents: Array<CheckboxList>): void {
    const contentControls = contents.map(content => this.formBuilder.group(content))

    this.contentsForm = this.formBuilder.group({
      contents: this.formBuilder.array(contentControls)
    });
  }

  selectAll($event): void {
    for (var i=0; i<this.contents.controls.length; ++i) {
      const checkboxGroup = this.contents.controls[i] as FormGroup
      checkboxGroup.controls.checked.setValue( $event ? true : false )
    }
    this.emitData();
  }

  hideShowContents(): void {
    this.hideContents = !this.hideContents
  }

  onChange(checked: boolean, index: number): void {
    const checkboxGroup = this.contents.controls[index] as FormGroup
    checkboxGroup.controls.checked.setValue(checked)
    this.selectAllContents = this.allItemsChecked
    this.emitData()
  }

  get allItemsChecked(): boolean {
    for (let i = 0; i < this.contents.controls.length; ++i) {
      if (!this.contents.controls[i].value.checked) {
        return false
      }
    }
    return true
  }

  get indeterminate(): boolean {
    let hasOneItemChecked: boolean = false;
    for (let i = 0; i < this.contents.controls.length; ++i) {
      if (this.contents.controls[i].value.checked) {
        hasOneItemChecked = true
        break
      }
    }
    return hasOneItemChecked
  }

  filterCheckbox() {
    this.filteredCheckbox = this.contents.controls
      .map((element, index) => element.value.checked ? index : null)
      .filter(element => element !== null)
  }

  emitData() {
    this.filterCheckbox()
    this.changedCheckboxLists.emit({
      checkedArray: this.filteredCheckbox,
      key: this.checkboxListKey
    })
  }
}
