import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, HostListener, OnInit, Output, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/core/http/http.service';
import { DatePickerModalComponent } from 'src/app/shared/components/date-picker-modal/date-picker-modal.component';
import { ReportsService } from '../../services/reports.service';
import { DataService } from 'src/app/core/services/data.service';
import { TeamManagementService } from 'src/app/modules/team-management/services/team-management.service';
import { TitleCasePipe, DatePipe } from '@angular/common'
import * as ExcelJS from "exceljs/dist/exceljs.min.js"
import * as fs from "file-saver"

@Component({
  selector: 'app-queue-count-filter-modal',
  templateUrl: './queue-count-filter-modal.component.html',
  styleUrls: ['./queue-count-filter-modal.component.scss'],
  providers: [TitleCasePipe, DatePipe]
})
export class QueueCountFilterModalComponent implements OnInit, OnDestroy {
  accountData = this.dataService.accountData$
  isDownloading: boolean = false
  title: string;
  subtitle: string;
  selectedDateFilter = "today";
  selectedDateFilterLabel = "Today";
  customDate1: any;
  customDate2: any;
  @Output() generate = new EventEmitter();
  isFocused = false;
  isSelectClicked: boolean;
  isButtonClicked: boolean;
  selectedValue = "today";
  selectedLabel = "Today";
  selectedWindows: any;
  selectedStatus: any;
  branchId: string;
  reportType: string;
  serviceWindowLists: any;
  filterType: string;
  clear = false;
  accountList: Array<any> = []
  subscriptions = new Subscription();
  customErrMsg: string = ''
  dateFilter = [
    {
      value: "today",
      label: "Today",
    },
    {
      value: "yesterday",
      label: "Yesterday",
    },
    {
      value: "this-week",
      label: "This Week",
    },
    {
      value: "this-month",
      label: "This Month",
    },
    // {
    //   value: "this-year",
    //   label: "This Year",
    // },
    {
      value: "custom",
      label: "Custom",
    },
  ];
  statusFilter = [
    {
      value: "served",
      label: "Served",
    },
    {
      value: "completed",
      label: "Completed",
    },
    {
      value: "removed",
      label: "Removed",
    },
    {
      value: "cancelled",
      label: "Cancelled",
    }
  ];

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private httpService: HttpService,
    public bsModalRef2: BsModalRef,
    private reportService: ReportsService,
    private titleCasePipe: TitleCasePipe,
    private datePipe: DatePipe,
    private dataService: DataService,
    private teamService: TeamManagementService
  ) {}

  ngOnInit(): void {
    this.getAccounts();
    if (this.filterType === 'service') {
      this.getServiceLists()
    } else {
      this.getWindowList()
    }
  }

  getAccounts() {
    this.subscriptions.add(
      this.teamService.getTeamList("", this.branchId).subscribe((res) => {
        this.accountList = res.data;
      }, (error) => {
        console.log('error on fetching account lists', error)
      })
    );
  }

  clearFilter(){
    this.clear = !this.clear;
    this.select();
    this.deselect();
    this.selectDropdown({value: "today", label: "Today"})
    this.customErrMsg = '';
  }

  formatDate1(date) {
    const d = new Date(date);
    return `${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`;
  }

  generateReport() {
    this.isDownloading = true
    let params = new HttpParams();
    params = params.append("filterBy", this.selectedValue);

    if (this.selectedValue === "custom") {
      params = params.append("startDate", new Date(this.customDate1).getTime().toString());
      params = params.append("endDate", new Date(this.customDate2).getTime().toString());
    }

    if (this.selectedWindows && this.selectedWindows.length > 0) {
      let windowIds = [];
      this.selectedWindows.forEach(x => {
        windowIds.push(x.value)
      });
      params = params.append(`${this.filterType}sId`, windowIds.join(','));
    } else {
      params = params.append(`${this.filterType}sId`, 'show-all');
    }

    if (this.selectedStatus && this.selectedStatus.length > 0) {
      let statuses = [];
      this.selectedStatus.forEach(x => {
        statuses.push(x.value)
      });
      params = params.append('statuses', statuses.join(','));
    }
    // this.generate.emit(params);
    // this.bsModalRef.hide();
    this.getReportData(params)
  }

  datePeriod: any
  getDatePeriod(data) {
    const curr = new Date() // get current date
    const first = curr.getDate() - curr.getDay() // First day is the day of the month - the day of the week
    const firstday = new Date(curr.setDate(first)).toUTCString()

    let date: any

    switch (data.filterBy) {
      case "today":
        this.datePeriod = this.datePipe.transform(new Date(), "MMMM dd, yyyy")
        break
      case "yesterday":
        date = new Date().setDate(new Date().getDate() - 1)
        this.datePeriod = this.datePipe.transform(date, "MMMM dd, yyyy")
        break
      case "this-week":
        date = new Date(firstday).setDate(new Date(firstday).getDate())
        const date2 = new Date(firstday).setDate(
          new Date(firstday).getDate() + 6
        )
        this.datePeriod =
          this.datePipe.transform(date, "MMMM dd, yyyy") +
          " - " +
          this.datePipe.transform(date2, "MMMM dd, yyyy")
        break
      case "this-month":
        this.datePeriod = this.datePipe.transform(curr, "MMMM yyyy")
        break
      case "this-year":
        this.datePeriod = this.datePipe.transform(curr, "yyyy")
        break
      case "custom":
        const start = new Date(parseInt(data.startDate))
        const end = new Date(parseInt(data.endDate))
        this.datePeriod =
          this.datePipe.transform(start, "MMMM dd, yyyy") +
          " - " +
          this.datePipe.transform(end, "MMMM dd, yyyy")
        break
    }
  }

  getReportData(params: HttpParams) {
    const paramsObject = params.keys().reduce((object, key) => {
      object[key] = params.get(key);
      return object;
    }, {});
    this.getDatePeriod(paramsObject);

    this.subscriptions.add(
      this.reportService
      .getQueueCounts(params, this.branchId)
      .subscribe((res) => {
        if (res && res.data) {
          this.generateQueueCountReport(res.data);
        }
      }, (error) => {
        console.log('error on fetching queue counts data', error)
        this.isDownloading = false;
      })
    );
  }

  generateQueueCountReport(allData) {
    const title = `${this.titleCasePipe.transform(this.filterType)} Name`;
    var header = [
      "Date",
      title,
      "No. of Served Queues",
      "No. of Completed Queues",
      "No. of Removed Queues",
      "No. of Cancelled Queues",
      "Total No. of Queues",
    ];
    // Create workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Queue count report");

    // Add Row and formatting
    const titleRow = worksheet.addRow(["QUEUE COUNT REPORT"]);
    titleRow.font = { name: "Arial", family: 4, size: 14 };
    const subTitleRow = worksheet.addRow([
      "Date & Time Generated: " +
        this.datePipe.transform(new Date(), "EEEE, MMMM dd, yyyy hh:mm a"),
    ]);
    const subTitleRow2 = worksheet.addRow([
      `Generated By: ${this.accountData.data.firstName} ${this.accountData.data.lastName}`,
    ]);
    const subTitleRow4 = worksheet.addRow([
      "Period Covered: " + this.datePeriod,
    ]);

    // Blank Row
    worksheet.addRow([]);

    // Add Header Row
    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.font = { name: "Arial", bold: true };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // Add Data and Conditional Formatting
    allData.forEach((d) => {
      const total =
        d.counts.totalServedQueues +
        d.counts.totalRemovedQueues +
        d.counts.totalCancelledQueues;
      const arrayData = [
        this.formatDate1(d.range.date),
        this.filterType === "service" ? d.serviceName : d.windowName,
        d.counts.totalServedQueues,
        d.counts.totalCompletedQueues,
        d.counts.totalRemovedQueues,
        d.counts.totalCancelledQueues,
        total,
      ];
      const row = worksheet.addRow(arrayData);
      const number1 = row.getCell(3);
      const number2 = row.getCell(4);
      const number3 = row.getCell(5);
      const number4 = row.getCell(6);
      const number5 = row.getCell(7);
      number1.alignment = { vertical: "middle", horizontal: "center" };
      number2.alignment = { vertical: "middle", horizontal: "center" };
      number3.alignment = { vertical: "middle", horizontal: "center" };
      number4.alignment = { vertical: "middle", horizontal: "center" };
      number5.alignment = { vertical: "middle", horizontal: "center" };
    });

    // width
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      fs.saveAs(blob, "Queue Counts Report.xlsx");
    });
    this.bsModalRef.hide()
    // this.isDownloading = false;
  }

  selectFilter(ev) {
    this.selectedWindows = ev;
  }

  selectStatus(ev) {
    this.selectedStatus = ev;
  }

  getWindowList() {
    this.subscriptions.add(
      this.httpService
      .get$(`services/windows/${this.branchId}?limit=999`)
      .subscribe((data) => {
        this.serviceWindowLists = data.data.map((element) => {
          return {
            label: element.displayName,
            value: element._id,
          };
        });
      })
    );
  }

  getServiceLists() {
    this.subscriptions.add(
      this.httpService.get$(`services/${this.branchId}?limit=999`).subscribe((data) => {
        this.serviceWindowLists = data.data.map((element) => {
          return {
            label: element.displayName,
            value: element._id,
          };
        });
      })
    );
  }

  selectCustomDateFilter() {
    const initialState = {
      hasMinDate: false,
      datePickerType: 'range',
      customReport: true
    }
    this.bsModalRef2 = this.modalService.show(DatePickerModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md2",
      ignoreBackdropClick: true,
      keyboard: false
    })

    this.bsModalRef2.content.selectedDateRange.subscribe(
      (data) => {
        if (data) {
          if (data == 'cancel') {
            this.customErrMsg = ''
          } else {
            if (data[0] && data[1]) {
              this.selectedValue = 'custom'
              this.customDate1 = (data[0] as Date).toLocaleDateString("en-US")
              this.customDate2 = (data[1] as Date).toLocaleDateString("en-US")
              this.selectedLabel = this.customDate1 + " - " + this.customDate2
              this.selectedDateFilterLabel = this.customDate1 + " - " + this.customDate2

              const startDate = new Date(this.customDate1)
              const endDate = new Date(this.customDate2)

              const threeMonthsLater = new Date(startDate.getTime()) // Create a new date object with the same time as the start date
              threeMonthsLater.setMonth(startDate.getMonth() + 3) // Add three months to the new date object

              if (endDate > threeMonthsLater) {
                // Do something if the selected dates are invalid
                this.customErrMsg = 'Selected dates must not be more than 3 months'
              } else {
                // Do something if the selected dates are valid
                this.customErrMsg = ''
              }
            } else {
              this.customErrMsg = ''
            }
          }
        }
      },
      (err) => {
        return false
      }
    )
  }

  select() {
    this.isSelectClicked = !this.isSelectClicked
    this.isFocused = !this.isFocused
  }

  @HostListener("document:click")
  deselect() {
    if (!this.isSelectClicked || this.isButtonClicked) {
      this.isFocused = false
    }
    this.isButtonClicked = false
    this.isSelectClicked = false
  }

  selectDropdown(list) {
    if (list.value === "custom") {
      this.selectCustomDateFilter()
    } else {
      this.selectedValue = list.value
      this.selectedLabel = list.label
    }
    this.isButtonClicked = true
    if (!this.isFocused) {
      this.isButtonClicked = false
    }
  }

  cancel() {
    this.generate.emit(
      {action: 'cancel', selected: this.filterType}
    );
    this.bsModalRef.hide();
  }

  closeModal() {
    this.generate.emit('close');
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

