import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { BusinessService } from "src/app/modules/auth/services/business.service";
import { BranchManagementService } from "src/app/modules/branch-management/services/branch-management.service";
import { LoaderService } from "../services/loader.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptorService implements HttpInterceptor {
  isRefreshing = false;
  refreshTokenSubject = new BehaviorSubject<any>(null);
  selectedBranch = this.branchService.getSelectedBranch();

  constructor(
    private authService: AuthService,
    private router: Router,
    private loaderService: LoaderService,
    private businessService: BusinessService,
    private branchService: BranchManagementService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // If has AccessToken and not S3 Bucket
    
    if (
      this.authService.getAccessToken() &&
      !request.url.includes("amazonaws")
    ) {
      request = this.addToken(request, this.authService.getAccessToken());
    }
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          console.log("error", error.error);

          if (error.error.error && error.error.error.indexOf("EXPIRED") > -1) {
            console.log("must logout");

            // To Edit
            this.authService.removeTokens();
            // this.router.navigate(['']);

            this.router.navigate([""]).then(() => {
              window.location.reload();
            });
          }

          if (
            error.error.errors.indexOf("invalid") > -1 ||
            error.error.errors.indexOf("Invalid") > -1 ||
            error.error.errors.indexOf("EXPIRED") > -1 ||
            error.error.errors.indexOf("not found") > -1
          ) {
            console.log("must logout");
            // To Edit
            this.authService.removeTokens();
            // this.router.navigate(['']);

            this.router.navigate([""]).then(() => {
              window.location.reload();
            });
          }

          if (error.error.errors[0].error === "INVALID_CREDENTIALS") {
            return throwError("INVALID_CREDENTIALS");
          }

          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing && !this.authService.getAccessToken()) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.data.accessToken);
          return next.handle(this.addToken(request, token.data.accessToken));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }
}
