import { Component, HostListener, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AppointmentService } from '../../services/appointment.service';
import { DataService } from 'src/app/core/services/data.service';
import { ToastrService } from "ngx-toastr";
import { DatePickerModalComponent } from 'src/app/shared/components/date-picker-modal/date-picker-modal.component';
import { Subscription } from "rxjs";
import { ISelectedTime, ITimeSlots , ITimeSlotData, ISettingLeadTime, ISettingScheduleRange } from '../../interface/appointment.interface';
import { HttpService } from "src/app/core/http/http.service";
import { GenericService } from "src/app/core/services/generic.service";
import { AppointmentSettingsService } from '../../services/appointment-settings.service';
import { SETTING_LEAD_TIME, APPOINTMENT_SETTINGS } from '../../enum/smart_form'; 

@Component({
  selector: 'app-add-appointment-modal',
  templateUrl: './add-appointment-modal.component.html',
  styleUrls: ['./add-appointment-modal.component.scss']
})
export class AddAppointmentModalComponent implements OnInit {

  @Input() title: any;
  @Input() subTitle: string;
  @Input() data: any;
  timeslots: Array<ITimeSlots> = [];
  timeSlotData: Array<ITimeSlotData> = [];
  modifiedCategoryLists: Array<any>;
  selectedServiceCategoryId: string;
  categoryLists: Array<any>;
  selectedTimeSlotData: ITimeSlotData;
  operatingDays: Array<any> = [];
  validFields: Array<any> = [];
  tagLists: Array<any> = [];
  enabledFieldLists: Array<any>;
  requiredValidators: any;
  mobileValidators: any;
  inputForm: FormGroup;
  services: any;
  serviceLists: Array<any>;
  selectedBranchId: string;
  selectedServiceId: string;
  isFocused: boolean = false;
  serviceData: any;
  isChanged: boolean;
  isSelectClicked: boolean;
  branchId: string;
  isButtonClicked: boolean;
  selectedDateValue: string = "";
  selectedDateLabel: string = "";
  isActiveTimeSlot: boolean = false;
  isActiveService: boolean = false;
  isSubmitting: boolean = false;
  printTimeSlotData: boolean = false;
  isSelected: boolean = false;
  isDirty = false;
  selectedTimeSlotValue: number;
  gmtMiliseconds: number;
  printTimeSlotLeft:number;
  isConfirmDisabled: boolean;
  noNumberValidator: any;
  queue: any;
  selectedTime: ISelectedTime = {
    value: -1,
    label: ''
  };
  dateMilliseconds: number;
  type: string;
  modifiedServiceLists: Array<any>;
  submitting:boolean =  false;
  selectedDateAndTime: boolean =  false;
  subscriptions = new Subscription();
  universalTags:any = []
  canProceed: boolean = false;
  buttonClicked = false;
  appointmentScheduleRange: number;
  settingFeaturesEnable: Array<string> = [];
  @Output() successEvent = new EventEmitter();
  priorityIndex: number = 0 | -1;
  settingsLeadTime: ISettingLeadTime;
  constructor(
    public modalRef: BsModalRef,
    public modalRefDatePicker: BsModalRef,
    public httpService: HttpService,
    private modalService: BsModalService,
    private dataService: DataService,
    private appointmentService: AppointmentService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private genericService: GenericService,
    private appointmentSettingService: AppointmentSettingsService
    
  ) {
    /* temporary the value of firstname is same in lastname value */
    this.inputForm = this.formBuilder.group({
        timeslot: ["", Validators.required],
    })
  }


  timeMili(): number {
      const hours = 8;
      const millisecondsInHour = 60 * 60 * 1000;
      return hours * millisecondsInHour;
  }

  timeEightHoursMili(): number {
    const hours = 8;
    const millisecondsInHour = 60 * 60 * 1000;
    return hours * millisecondsInHour;
  }

  modifyServiceLists() {
    this.modifiedServiceLists = this.serviceLists.map((element) => {
      if(element.enableAppointment) {
          return {
                label: element.displayName,
                value: element._id,
              };
      }
    }).filter(element => element !== undefined);
  }

  async initServiceData() {
    if (this.selectedServiceId) {
      await this.selectService(this.selectedServiceId);
    }
  }

 getServicecategoryList() {
  const branchId = this.dataService.currentBranch$.branchId;
    this.appointmentService.getServiceCategories(branchId).subscribe(
       (res) => {
        if(res) {
          this.categoryLists = res.data;
        }
      },
      (error) => {
        console.log(error)
      }
    )
 }  

 async serviceList() {
    const branchId = this.dataService.currentBranch$.branchId;
    const date = new Date();
    this.setDateLabel(date);
    this.getServicecategoryList();
    this.getAppointmentSetting();
    this.subscriptions.add(
      this.appointmentService.getServiceList(branchId).subscribe(
        async (res) => {
          if(res) {
            this.serviceLists = res.data;
            if(this.serviceLists) {
              this.modifyServiceLists();
              /* this.getUniversalTag()     */ 
            }
          }
        },
        (error) => {
          console.log(error)
        }
    )
    )
  }

  async getServiceData() {
    this.branchId = this.dataService.currentBranch$.branchId
    if (
      this.selectedServiceId &&
      this.selectedServiceId !== "Please Select Service"
    ) {
      this.httpService
        .get$(`services/${this.branchId}/${this.selectedServiceId}`)
        .toPromise()
        .then(
          (data) => {
            this.serviceData = data.data;
            this.enabledFieldLists = this.serviceData?.queueForm
              ? this.serviceData.queueForm.fields
                .filter((element) => element.enabled)
                .map((element, i) => {
                  const validatorArr = [];
                  element.required
                    ? validatorArr.push(this.requiredValidators)
                    : null;
                  element.fieldName == "name"
                    ? validatorArr.push(this.noNumberValidator)
                    : null; // added validator for name field 
                  // element.required && //remove for optional mobileNo
                  ["mobileNo", "mobile-number"].includes(element.fieldType)
                    ? validatorArr.push(this.mobileValidators)
                    : null;
                  return {
                    ...element,
                    label: element.label,
                    value: this.queue ? this.queue.fields[i]?.value : "",
                    validators: validatorArr,
                  };
                })
              : null;
            this.validFields = this.enabledFieldLists
              ? this.enabledFieldLists.map((element, i) => {
                return {
                  ...element,
                  i: i,
                  valid: !element.required
                    ? true
                    : element.value
                      ? true
                      : false,
                  value: element?.value ? element?.value : "",
                  fieldType: element?.fieldType,
                };
              })
              : null;
            this.isConfirmDisabled = this.validFields
              ? this.validFields.filter((element) => !element.valid).length !==
              0
              : false;
            if (
              this.serviceData &&
              this.serviceData.enabledFeatures.includes("auto-tag")
            ) {
              this.serviceData.tags.forEach((t) => {
                if (!this.tagLists.includes(t)) {
                  this.tagLists.push(t);
                }
              });
            }

          },
          (error) => {
            console.log("error", error);
          }
        );
      
      this.isSelected = true;
    }
  }

  
  async patchValue() {
    const { fields, ...queue } = this.queue;
    this.queue = {
      ...queue,
      fields: fields.map((element) =>
        ["mobileNo", "mobile-number"].includes(element.fieldName)
          ? {
            ...element,
            value: this.genericService.patchMobile(element.value),
          }
          : element
      ),
    };
    this.tagLists = this.queue.tags;
  }

  changeInput(index, $event) {
    const isToggleSwitch = typeof $event === 'boolean';

    if ($event.valid) {
      this.validFields = this.validFields.map((element, i) =>
        i === index
          ? {
            ...element,
            valid: true,
            value: $event.value,
          }
          : {
            ...element,
            valid: element.valid,
            value: element.value,
          }
      );
    } else {
      this.validFields = this.validFields.map((element, i) =>
        i === index
          ? {
            ...element,
            valid: false,
            value: $event.value,
          }
          : {
            ...element,
            valid: element.valid,
            value: element.value,
          }
      );
    }

    if (isToggleSwitch) {
      this.validFields = this.validFields.map((element, i) =>
        i === index
          ? {
            ...element,
            valid: true,
            value: $event,
          }
          : {
            ...element,
            valid: element.valid,
            value: element.value,
          }
      );
    }

    const valid = this.validFields.filter((element) => element.valid);
    this.isConfirmDisabled = valid.length !== this.enabledFieldLists.length;
    this.isDirty = true;
  }

  public async appointmentDetails(serviceId) {
    const branchId = this.dataService.currentBranch$.branchId;
    this.subscriptions.add(
      this.appointmentService.getAppointmentCategoryDetails(branchId, serviceId).subscribe(
        async (res) => {
          if(res) {
            this.operatingDays = res.data.operatingDays;
            this.getCurrentDate();
          }
        },
        (error) => {
          console.log(error)
        }
      )
    )
  }

  /* get the appointment time slot details */
  public appointmentTimeSlotDetails(gmtMiliseconds) {
    const body = {
      branchId: this.dataService.currentBranch$.branchId,
      serviceId: this.selectedServiceId,
      time: gmtMiliseconds,
    }
    this.subscriptions.add(
      this.appointmentService.getAppointmentTimeSlotData(body).subscribe(
        (res) => {
          if(res) {
            this.timeSlotData = res.data.timeslot
          }
        },
        (error) => {
          console.log(error)
        }
      )
    )
  }

  setDateLabel(date: Date) {

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    const formattedDate = date.toLocaleDateString('en-US', options);

    const dateObject = new Date(date);
    this.selectedDateLabel = formattedDate;

    const selectedDay = this.datePipe.transform(this.selectedDateLabel , 'EEEE');

    const dayValue = this.getAppointmentDay(selectedDay)

    const gmtTime = this.convertToGMT(dateObject);

    const gmtTimeMilis = gmtTime.getTime();

    this.gmtMiliseconds = gmtTimeMilis  + this.timeMili();
    this.getTimeSlot(date.getDay());
    this.isConfirmDisabled = true;
  } 

  selectService($event) {
    this.serviceData = null;
    this.selectedServiceId = $event;
  /*   this.modifiedCategoryLists = [];
    this.modifiedCategoryLists = this.checkCategory();  */
    if (!this.modifiedCategoryLists || this.modifiedCategoryLists.length <= 0) {
      this.isActiveService = false;
    } else {
      this.isConfirmDisabled = true;
      this.isSelected = false;
    }
    this.isDirty = true; 

    for(let service of this.modifiedServiceLists ) {
      if(this.selectedServiceId === service.value) {
         this.selectedServiceId = service.value;
         this.isActiveService = true;
         this.appointmentDetails(this.selectedServiceId)
      }
   }
    this.isActiveTimeSlot = false;
  }

  public getTimeSlot(dayValue) {
    this.selectedTimeSlotValue = null;
    let appointmentTime: any = [];
    if(this.operatingDays) { 
      let timeSlotsData = this.operatingDays.filter(data => data.day === dayValue);
      for(let timeSlot of timeSlotsData) {
            timeSlot.timeslot.forEach( data => {
             appointmentTime.push(this.getTimeslotData(data));   
        })
      }

      this.timeslots = appointmentTime; 
      this.isConfirmDisabled = false;

   }
 }

  getTimeslotData(value):ITimeSlots {
    const date = new Date(value.time + this.timeEightHoursMili()); 
    let utcHours = date.getUTCHours()
    let utcMinutes = date.getUTCMinutes().toString().padStart(2, "0");
    let twelveClockHour:string;
    let appointmentTimeslot: string;
    let leadTimeValue: number = 0;
    const currentGMTTime = new Date();
    // Convert GMT time to UTC
    const currentUTCTime = new Date(currentGMTTime.toUTCString());
    // Get the milliseconds since the Unix epoch for the UTC time
    const millisecondsSinceEpoch = (currentUTCTime.getTime() + this.timeMili()) - this.gmtMiliseconds; 

    if(this.settingsLeadTime.type === SETTING_LEAD_TIME.MINUTES) {
      leadTimeValue = this.settingsLeadTime.value * 60 * 1000
    } 

    if(this.settingsLeadTime.type === SETTING_LEAD_TIME.HOURS) {
      leadTimeValue = this.settingsLeadTime.value * ( 60 * 60 * 1000);
    }


    if((millisecondsSinceEpoch + leadTimeValue)  > (value.time + this.timeEightHoursMili())) {
      value.disabled = true;
    }else{
      value.disabled = false;
    }
    
    utcHours >= 12 ? twelveClockHour = "PM" : twelveClockHour = "AM"
    
    if(utcHours > 12) {
      utcHours = utcHours - 12;
    } 

    appointmentTimeslot = utcHours+":"+ utcMinutes+" "+ twelveClockHour;
    return ({label: appointmentTimeslot, value: appointmentTimeslot, slots: value.slots, time: value.time, disabled: value.disabled});  
  
  }
  
  /* get number day of selected date */
  getAppointmentDay(day: string):number {
    let dayNumber: number;
    switch (day) {
      case 'Sunday': dayNumber = 0; 
          break; 
      case 'Monday': dayNumber = 1; 
          break;
      case 'Tuesday': dayNumber = 2; 
          break; 
      case 'Wednesday': dayNumber = 3; 
          break;
      case 'Thursday': dayNumber = 4; 
          break;
      case 'Friday': dayNumber = 5; 
          break;
      case 'Saturday': dayNumber = 6; 
          break;
      default: 
          break;          
    }
    return dayNumber;
  }

  getCurrentDate(): void {
    const selectedDay = this.datePipe.transform(this.selectedDateLabel , 'EEEE');
    const dayValue = this.getAppointmentDay(selectedDay)
    this.getTimeSlot(dayValue)
  }

  convertToGMT(localTime: Date) {
    localTime.setHours(0,0,0,0);
    const utcMilliseconds = Date.UTC(
      localTime.getUTCFullYear(),
      localTime.getUTCMonth(),
      localTime.getUTCDate(),
      localTime.getUTCHours(),
      localTime.getUTCMinutes(),
      localTime.getUTCSeconds(),
      localTime.getUTCMilliseconds()
    );
    
    return new Date(utcMilliseconds);
  }

   selectedTimeSlot($data) {
    if($data != 'Select Time Slot') {
    let timeValue: number;
    this.timeslots.forEach(data => {
	 
      if(data.label === $data) {
          if(data.slots >= 0) {
            timeValue = data.time
        } else {
            timeValue = data.time
        }

        this.selectedTimeSlotValue = timeValue;
        this.inputForm.controls['timeslot'].setValue(this.selectedTimeSlotValue);
      }
    })

    this.selectedTimeSlotValue = timeValue;
    this.printTimeSlotData = true;
    this.isActiveTimeSlot = true;
    this.selectedTimeSlotData = this.getTimeSlotData();
    this.getServiceData();
/*     this.printTimeSlotLeft = this.selectedTimeSlotData.slotsLeft; */
  }
} 

getAppointmentSetting() {
  const branchId = this.dataService.currentBranch$.branchId;
  this.subscriptions.add(
    this.appointmentSettingService.getSettings(branchId).subscribe(
      (res) => {
        if(res) {
            this.settingsLeadTime = res.data.leadTime;
            this.appointmentScheduleRange = this.getAppointmentScheduleRange(res.data.scheduleRange);
            this.settingFeaturesEnable = res.data.featuresEnabled;
        }
      },
      (error) => {
        console.log(error)
      }
    )
 );
}

getAppointmentScheduleRange(appointmentScheduleRange) {
  const currentDate = new Date();
  const calculatedDate = new Date()
  let appointmeRange: number;
  if(appointmentScheduleRange.type === 'weeks' ) {
    appointmeRange = calculatedDate.setDate(currentDate.getDate() + (appointmentScheduleRange.value * 7)); 
  }

  if(appointmentScheduleRange.type === 'months') {
    appointmeRange = calculatedDate.setMonth(currentDate.getMonth() + appointmentScheduleRange.value); 
  }

  return appointmeRange;
}

getTimeSlotData(): ITimeSlotData {
  for(let timeslot of this.timeSlotData ) {
    if(timeslot.time == this.selectedTimeSlotValue) {
       return timeslot;
    }
  }
}

select() {
  this.isSelectClicked = !this.isSelectClicked;
  this.isFocused = !this.isFocused;
}

@HostListener("document:click")
  deselect() {
    if(!this.isSelectClicked || this.isButtonClicked) {
      this.isFocused = false;
  }
  
  this.isButtonClicked = false;
  this.isSelectClicked = false;
}

  selectDateDropdown() {
    const initialState = {
      title: 'Select Appointment Schedule',
      hasMinDate: true,
      hasMaxDate: true,
      maxDate: new Date(this.appointmentScheduleRange),
      datePickerType: 'single',
    }

    this.modalRefDatePicker = this.modalService.show(DatePickerModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false
    })

    this.modalRefDatePicker.content.selectedDateRange.subscribe(
      (data) => {
        if (data) {
          if (data == 'cancel') {
            //
          } else {
            this.setDateLabel(data);
          }
        }
      },
      (err) => {
        return false
      }
    )
  }

  removeTag($event) {
    this.isChanged = true;
    const tagIndex = this.tagLists.indexOf(!$event.value ? $event : $event.value);
    this.tagLists.splice(tagIndex, 1);
  }

  addTag($event) {
    this.isChanged = true;
    this.tagLists.push($event);
  }

   initValidators() {
    this.requiredValidators = {
      type: "required",
      value: Validators.required,
      errormsg: "This field is required",
    };
    this.mobileValidators = {
      // type: "minlength",
      // value: Validators.minLength(12),
      // errormsg: "Minimum length is 12",
      type: "pattern",
      value: Validators.pattern(/^9[0-9]{2}[-]?[0-9]{3}[-]?[0-9]{4}$/),
      errormsg: "Invalid mobile number format",
      //enhance regex for pattern
    };
    this.noNumberValidator = {
      type: "pattern",
      value: Validators.pattern(/^[A-Za-z- ñÑ.]*$/),
      errormsg: "Invalid name format",
      //enhance regex for pattern (name)
    }
  }

/*   checkCategory() {
    this.categoryLists = this.categoryLists.filter(x => !x.subCategoryId);
    const serviceCategories = this.categoryLists.filter(
      (s) =>
        s.assignedServices.includes(this.selectedServiceId) &&
        (!s.parentCategoryId || s.parentCategoryId)
    );

    let categoryChoices = [];
    if (serviceCategories) {
      serviceCategories.forEach((c1) => {
        let categoryLabel: any;
        if (c1.parentCategoryId) {
          const categoryLevel1 = this.categoryLists.find(
            (c) => c._id === c1.parentCategoryId
          );
          if (!categoryLevel1) {
            return [];
          }
          if (categoryLevel1.parentCategoryId) {
            const categoryLevel2 = this.categoryLists.find(
              (c) => c._id === categoryLevel1.parentCategoryId
            );
            if (!categoryLevel2) {
              return [];
            }
            categoryLabel = {
              label:
                categoryLevel2.displayName +
                " > " +
                categoryLevel1.displayName +
                " > " +
                c1.displayName,
              value: c1._id,
            };
          } else {
            categoryLabel = {
              label: (categoryLabel =
                categoryLevel1.displayName + " > " + c1.displayName),
              value: c1._id,
            };
          }
        } else {
          categoryLabel = {
            label: (categoryLabel = c1.displayName),
            value: c1._id,
          };
        }

        categoryChoices.unshift(categoryLabel);
      });

      return categoryChoices;
    }
  } */

 selectServiceCategory($event) {
    this.selectedServiceCategoryId = $event;
    this.isDirty = true;
  }

  confirm($event) {
    this.isSubmitting = true;
    this.buttonClicked = true;
    this.validFields.map(data => 
    {
      if(data.fieldName === "priority") {
        data.value = data.value === true ? true : false;
      }
      delete data._id
      return data;
    });
    
    const body = {
        branchId: this.dataService.currentBranch$.branchId,
        parentCategoryId: 'da599e49-9554-4c15-a44f-bbd94b7f9eca',
        serviceId: this.selectedServiceId,
        contactNo:  this.inputForm.value.contactNo
        ? this.inputForm.value.contactNo.replaceAll("-", "")
        : "",
        tags: this.tagLists,
        customerRemarks: '',
        selectedTimeslot: this.selectedTimeSlotValue,
        timeslot: this.selectedTimeSlotValue + this.gmtMiliseconds ,
        visitorDetails: this.validFields,
       
    }
    
    this.subscriptions.add(
      this.appointmentService.addAppointment(body).subscribe(
        (res) => {
          if(res) {
            this.successEvent.emit('add')
            this.isSubmitting = false;
            this.buttonClicked = false;
            this.modalRef.hide();
            this.toastr.success(`New appointment added - ${res.data?.referenceNo}`, "Success!",{
              toastClass: "ngx-toastr toast-success-custom",
            });
          }
        },
        (error) => {
          console.log(error)
          let resultError = 
              !this.settingFeaturesEnable.includes(APPOINTMENT_SETTINGS.ENABLE_APPOINTMENT) ? "Appointment features are disabled. Please contact Sales Support to enable this feature." 
              : "Error encountered upon submitting ";
          this.toastr.error(resultError, "Error");       
          this.isSubmitting = false;  
          this.buttonClicked = false;

        }
      )
    ) 
  }

  closeModal(): void {
    this.modalRef.hide();
  }

  getUniversalTag() {
    this.httpService
        .get$(`queues/universal-tag-set/${this.branchId}`)
        .toPromise()
        .then(
          (data) => {
            const dataFound =  data.result.filter(tag => !tag.deleted);
            this.universalTags = dataFound;
          },
          (error) => {
            console.log("error", error);
          }
        );
  }

  async ngOnInit() {
    await this.serviceList();
    await this.initServiceData();
    await this.initValidators(); 
  } 
}
