// time helper

import * as moment from "moment";

export function convertMillisecondsToTime(milliseconds: number) {
  let formattedTime: string = "";
  const totalSeconds = Math.floor(milliseconds / 1000);
  const days = Math.floor(totalSeconds / 86400);
  const hours = Math.floor((totalSeconds % 86400) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  if (milliseconds == 0) {
    return (formattedTime = "0");
  }

  if (days > 0) {
    if (hours > 0) {
      formattedTime = `${days}:${hours.toString().padStart(2, "0")} days`;
    } else {
      formattedTime = `${days} ` + `${days > 1 ? "days" : "day"}`;
    }
    return formattedTime;
  }

  if (hours > 0) {
    if (minutes > 0) {
      formattedTime = `${hours}:${minutes.toString().padStart(2, "0")} hrs`;
    } else {
      formattedTime = `${hours} ` + `${hours > 1 ? "hrs" : "hr"}`;
    }
    return formattedTime;
  }

  if (minutes > 0) {
    if (seconds > 0) {
      formattedTime = `${minutes}:${seconds.toString().padStart(2, "0")} mins`;
    } else {
      formattedTime = `${minutes} ` + `${minutes > 1 ? "mins" : "min"}`;
    }
    return formattedTime;
  }

  if (seconds > 0) {
    formattedTime = `${seconds}` + " " + `${seconds > 1 ? "secs" : "sec"}`;
    return formattedTime;
  }
}

export function getDuration(milliseconds: number) {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const duration = `${hours.toString().padStart(2, '0')}:${(minutes % 60).toString().padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;

  return duration;
}

export function getDuration2(milliseconds: number) {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const duration = `${hours.toString().padStart(2, '0')}:${(minutes % 60).toString().padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;

  return {
    duration,
    isOneHourOrMore: hours >= 1
  };
}

export function getTimeAgo(milliseconds) {
  if (milliseconds) {
    const y = new Date(milliseconds).getFullYear();
    const m = new Date(milliseconds).getMonth();
    const d = new Date(milliseconds).getDate();
    const h = new Date(milliseconds).getHours();
    const mm = new Date(milliseconds).getMinutes();
    const s = new Date(milliseconds).getSeconds();
    const time = moment([y, m, d, h, mm, s]).fromNow();

    return time === "a day" ? "1 day" : time;
  }
}