import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-date-picker-modal",
  templateUrl: "./date-picker-modal.component.html",
  styleUrls: ["./date-picker-modal.component.scss"],
})
export class DatePickerModalComponent implements OnInit {
  bsValue = new Date();
  bsSelectedValue: Date;
  bsRangeValue: Date[];
  hasMinDate: boolean;
  hasMaxDate: boolean;
  minDate: any;
  maxDate: any;
/*   public maxDate: Date = new Date(); */
  public bsConfig: Partial<BsDatepickerConfig>;
  @Input() customReport: boolean = false;
  @Output() selectedDateRange = new EventEmitter();

  datePickerType = 'range'
  type = 'start';

  constructor(
    public bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    if (this.hasMinDate) {
      this.minDate = new Date();
    } else {
      this.minDate = new Date('01/01/2020');
    }

    if (!this.hasMaxDate) {
      this.maxDate = new Date();
    }

    if (this.customReport) {
      this.minDate.setMonth(this.minDate.getMonth() - 3);
      this.minDate.setDate(this.minDate.getDate() + 1); // add 1 day to minDate to exclude today
      this.bsConfig = Object.assign({}, {
        minDate: this.minDate,
        maxDate: this.maxDate,
        showWeekNumbers: false, 
        daysDisabled:[]
      });
    }
  }

  selectDate(ev) {
    this.bsSelectedValue = ev;
  }

  selectDateRange(ev) {
    this.bsRangeValue = ev;
  }

  clearFilter() {
    this.bsRangeValue = [];
  }

  cancel() {
    this.bsModalRef.hide();
  }

  cancelCustomReport() {
    this.selectedDateRange.emit('cancel');
    this.bsModalRef.hide();
  }

  applyFilter(ev) {
    if (this.customReport) {
      if (ev == 'confirm') {
        this.selectedDateRange.emit(this.bsRangeValue);
        this.bsModalRef.hide();
      }
    } else {
      if(this.datePickerType === 'range'){
        this.selectedDateRange.emit(this.bsRangeValue);
      } else {
        this.selectedDateRange.emit(this.bsSelectedValue);
      }
      this.bsModalRef.hide();
    }
  }
}
