import {
  Component,
  OnInit,
  OnChanges,
  Input,
  OnDestroy,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { defaultAvatar } from "src/app/core/services/global";
import {
  MonitoringDataTypes,
  IAvatarData,
  IMonitoringCardData,
} from "src/app/modules/monitoring/interfaces/monitoring.interface";
import { MonitoringService } from "src/app/modules/monitoring/services/monitoring.service";
import { convertMillisecondsToTime } from "src/app/shared/helpers/time.helpers";
import { calculateQueuePercentage } from "src/app/shared/helpers/percentage.helpers";
import { DatePipe } from "@angular/common";
import { Subject, forkJoin, throwError } from "rxjs";
import { catchError, takeUntil } from "rxjs/operators";
import { defaultWeeklyLabel } from "src/app/modules/monitoring/constants/monitoring.constants";

const defaultMonitoringCardData: IMonitoringCardData = {
  leftDetails: {},
  rightDetails: {},
};

@Component({
  selector: "app-branch-performance-tab-page",
  templateUrl: "./branch-performance-tab-page.component.html",
  styleUrls: ["./branch-performance-tab-page.component.scss"],
  providers: [DatePipe],
})
export class BranchPerformanceTabPageComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() branchId: string = "";
  @Input() selectedValue: string = "";
  @Input() selectedLabel: string = "";
  @Input() customDate1: string = "";
  @Input() customDate2: string = "";
  @Input() selectedServiceId: string = "";
  defaultAvatar: string = defaultAvatar;
  branchPerformanceSubscription: any;
  isLoading: boolean = false;
  queueData: {
    totalQueues: IMonitoringCardData;
    avgWaitingTime: IMonitoringCardData;
    avgServingTime: IMonitoringCardData;
    queueLength: IMonitoringCardData;
    peakHour: IMonitoringCardData;
    service: IMonitoringCardData;
    staff: IMonitoringCardData;
  } = {
    totalQueues: { ...defaultMonitoringCardData },
    avgWaitingTime: { ...defaultMonitoringCardData },
    avgServingTime: { ...defaultMonitoringCardData },
    queueLength: { ...defaultMonitoringCardData },
    peakHour: { ...defaultMonitoringCardData },
    service: { ...defaultMonitoringCardData },
    staff: { ...defaultMonitoringCardData },
  };
  totalQueuesChartData: {} = {};
  totalQueuesChartOptions: any = {};
  totalQueuesLabel: string[] = [];
  totalQueuesData: number[] = [];
  waitTimeChartData: {} = {};
  waitTimeChartOptions: {} = {};
  dateToday = new Date();
  public currentDateAndTime: string;
  private branchPerformanceSubjectAPI$ = new Subject<void>();
  dateLabel: string = "";
  currentMonth: string = "";
  isSingleDate: boolean = false;

  constructor(
    private monitoringService: MonitoringService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef
  ) {
    this.currentDateAndTime = this.datePipe.transform(
      this.dateToday,
      "MMMM dd, yyyy — hh:00 a"
    );
    this.currentMonth = this.getCurrentMonth();

    this.initializeChart();
    this.supplyCardDetails();
  }

  getCurrentMonth(): string {
    const currentDate = new Date();
    const currentMonth = this.datePipe
      .transform(currentDate, "MMMM")
      .toUpperCase();
    return currentMonth;
  }

  initializeChart() {
    this.totalQueuesChartData = {};
    this.totalQueuesChartOptions = {
      legend: {
        display: false,
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            gridLines: { display: false },
            ticks: {
              fontSize: 12,
              fontColor: "#191444",
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              fontSize: 12,
              fontColor: "#191444",
              min: 0,
              stepSize: 5,
              suggestedMax: 60,
            },
          },
        ],
      },
    };
    this.waitTimeChartData = {};
    this.waitTimeChartOptions = {
      elements: {
        point: {
          borderWidth: 0,
          radius: 2,
          backgroundColor: "rgba(0,0,0,0)",
        },
      },
      scales: {
        beginAtZero: false,
        // offset: true,
        xAxes: [
          {
            gridLines: { display: false },
            ticks: {
              fontSize: 12,
              fontColor: "#191444",
              beginAtZero: true,
              // min: 0,
            },
          },
        ],
        yAxes: [
          {
            // scaleLabel: {
            //   display: true,
            //   labelString: 'Time (minutes)'
            // },
            ticks: {
              fontSize: 12,
              fontColor: "#191444",
              // min: 0,
              stepSize: 5,
              suggestedMax: 55,
              // callback: (label, index, labels) => {
              //   return label + " min(s)";
              // },
            },
          },
        ],
      },
      title: {
        display: true,
        position: "left",
        fontFamily: "Source Sans Pro",
        fontWeight: 400,
        fontSize: 12,
        fontColor: "#191444",
        text: "Time (minutes)",
      },
      legend: { display: false },
      responsive: true,
      maintainAspectRatio: false,
    };
  }

  supplyCardDetails() {
    this.queueData.totalQueues.leftDetails = {
      ...this.queueData.totalQueues.leftDetails,
      value: 0,
      percentage: null,
      counts: { today: 0, yesterday: 0 },
    };

    this.queueData.avgWaitingTime.leftDetails = {
      ...this.queueData.avgWaitingTime.leftDetails,
      value: 0,
      formattedValue: 0,
      percentage: null,
      counts: { today: 0, yesterday: 0 },
    };

    this.queueData.avgServingTime.leftDetails = {
      ...this.queueData.avgServingTime.leftDetails,
      value: 0,
      formattedValue: 0,
      percentage: null,
      counts: { today: 0, yesterday: 0 },
    };

    this.queueData.queueLength.leftDetails = {
      ...this.queueData.queueLength.leftDetails,
      value: 0,
    };

    this.queueData.peakHour.leftDetails = {
      ...this.queueData.peakHour.leftDetails,
      value: "-",
    };

    this.queueData.service.leftDetails = {
      ...this.queueData.service.leftDetails,
      value: "-",
      alternativeValue: 0,
    };

    (this.queueData.staff.leftDetails = {
      ...this.queueData.staff.leftDetails,
      value: "-",
      alternativeValue: 0,
    }),
      ((this.queueData.staff.rightDetails as IAvatarData).avatarUrl = null);

    this.queueData.totalQueues.rightDetails = {
      isSvg: false,
      style: "ic_people",
      size: 24,
      color: "#FE7401",
      bgColor: "#FFF2E7",
      addClass: "",
    };
    this.queueData.avgWaitingTime.rightDetails = {
      isSvg: false,
      style: "ic_clock",
      size: 24,
      color: "#FE7401",
      bgColor: "#FFF2E7",
      addClass: "",
    };
    this.queueData.avgServingTime.rightDetails = {
      isSvg: false,
      style: "ic_timer",
      size: 24,
      color: "#104A19",
      bgColor: "#EFFBF1",
      addClass: "",
    };
    this.queueData.queueLength.rightDetails = {
      isSvg: false,
      style: "ic_person",
      size: 24,
      color: "#034D6C",
      bgColor: "#CDEEFF",
      addClass: "",
    };
    this.queueData.peakHour.rightDetails = {
      isSvg: false,
      style: "ic_trending-up",
      size: 28,
      color: "#630500",
      bgColor: "#FFCDCD",
      addClass: "mt-3 ml-2",
    };
    this.queueData.service.rightDetails = {
      isSvg: true,
      style: "ic_work",
      size: 24,
      color: "#886D00",
      bgColor: "#FFF3CD",
      addClass: "",
    };
    this.queueData.staff.rightDetails = { avatarUrl: null };
  }

  ngOnInit(): void {
    this.branchPerformanceData();
  }

  branchPerformanceData() {
    this.isLoading = true;

    this.isSingleDate =
      (this.selectedValue === "custom" && 
        ((this.customDate1 && this.customDate2) &&
        this.customDate1 === this.customDate2));

    const dateFilter =
      this.selectedValue === "custom"
        ? {
            startDate: new Date(this.customDate1).setHours(0, 0, 0),
            endDate: new Date(this.customDate2).setHours(23, 59, 59),
          }
        : this.selectedValue;

    const datatypes: MonitoringDataTypes = {
      branchId: this.branchId,
      dateFilter: dateFilter,
      selectedServiceId: this.selectedServiceId,
    };

    const catchErrorAndHandleLoading = (error: any) => {
      console.log("Error:", error);
      this.isLoading = false;
      return throwError(error);
    };

    forkJoin([
      this.monitoringService.getBranchPerformanceData(datatypes, "chart", this.isSingleDate).pipe(
        catchError(catchErrorAndHandleLoading)
      ),
      this.monitoringService.getBranchPerformanceData(datatypes).pipe(
        catchError(catchErrorAndHandleLoading)
      )
    ]).subscribe(
      ([chartData, performanceData]) => {
        this.setChartData(
          chartData.data.totalQueuesGraph.totalCountsGraph,
          chartData.data.totalWaitingTime.waitingTime,
          chartData.data.totalServingTime.servingTime
        );
        this.setQueueDetails(performanceData.data);
      },
      catchErrorAndHandleLoading,
      () => {
        this.isLoading = false;
      }
    );
  }

  setQueueDetails(data: any) {
    this.queueData.totalQueues.leftDetails = {
      ...this.queueData.totalQueues.leftDetails,
      value: data.totalQueues.current,
      percentage: calculateQueuePercentage(
        data.totalQueues.current,
        data.totalQueues.previous
      ),
      counts: {
        today: data.totalQueues.current,
        yesterday: data.totalQueues.previous,
      },
    };

    this.queueData.avgWaitingTime.leftDetails = {
      ...this.queueData.avgWaitingTime.leftDetails,
      value: data.avgWaitingTime.current,
      formattedValue: convertMillisecondsToTime(data.avgWaitingTime.current),
      percentage: calculateQueuePercentage(
        data.avgWaitingTime.current,
        data.avgWaitingTime.previous
      ),
      counts: {
        today: data.avgWaitingTime.current,
        yesterday: data.avgWaitingTime.previous,
      },
    };

    this.queueData.avgServingTime.leftDetails = {
      ...this.queueData.avgServingTime.leftDetails,
      value: data.avgServingTime.current,
      formattedValue: convertMillisecondsToTime(data.avgServingTime.current),
      percentage: calculateQueuePercentage(
        data.avgServingTime.current,
        data.avgServingTime.previous
      ),
      counts: {
        today: data.avgServingTime.current,
        yesterday: data.avgServingTime.previous,
      },
    };

    this.queueData.queueLength.leftDetails = {
      ...this.queueData.queueLength.leftDetails,
      value: data.queueLength,
    };

    this.queueData.peakHour.leftDetails = {
      ...this.queueData.peakHour.leftDetails,
      value:
        // data.peakHour && data.peakHour.length > 0
        // ? this.formatPeakHour(data.peakHour[0].hour)
        data.peakHour ? data.peakHour : "-",
    };

    this.queueData.service.leftDetails = {
      ...this.queueData.service.leftDetails,
      value:
        data.mostQueuedService && data.mostQueuedService.serviceName
          ? data.mostQueuedService.serviceName
          : "-",
      alternativeValue: data.mostQueuedService
        ? data.mostQueuedService.totalCounts
        : 0,
    };

    (this.queueData.staff.leftDetails = {
      ...this.queueData.staff.leftDetails,
      value:
        data.staffMostQueuesCompleted && data.staffMostQueuesCompleted.staffName
          ? data.staffMostQueuesCompleted.staffName
          : "-",
      alternativeValue: data.staffMostQueuesCompleted
        ? data.staffMostQueuesCompleted.totalCounts
        : 0,
    }),
      ((this.queueData.staff.rightDetails as IAvatarData).avatarUrl =
        data.staffMostQueuesCompleted
          ? data.staffMostQueuesCompleted.avatarUrl
          : null);

    this.setDateLabel();
    this.cdr.detectChanges();
    // this.isLoading = false;
  }

  setChartData(
    totalQueues: Array<any>,
    waitingTime: Array<any>,
    servingTime: Array<any>
  ) {
    this.totalQueuesChartData = {
      labels: totalQueues ? this.loopData(totalQueues, 0) : [],
      datasets: [
        {
          label: "Total Queues",
          backgroundColor: "#1AB2FF",
          hoverBackgroundColor: "#CDEEFF",
          data: totalQueues ? this.loopData(totalQueues, 1) : [],
          barPercentage: 0.1,
          barThickness: 8,
        },
      ],
    };

    if (this.selectedValue === "this-month" || (this.selectedValue === "custom" && !this.isSingleDate)) {
      this.totalQueuesChartOptions.tooltips = {
        callbacks: {
          label: (tooltipItem, data) => {
            if (tooltipItem) {
              tooltipItem.xLabel = "";
              const index = tooltipItem.index;
              const value = tooltipItem.value;
              const date = this.selectedValue === "this-month" ? totalQueues[index].date : totalQueues[index + 1].date;
              const label = `Total Queues for ${this.datePipe.transform(date, "MMMM d, yyyy")}: ${value}`;
              return label;
            }
          },
        },
      };
    } else {
      this.totalQueuesChartOptions.tooltips = {};
    }

    //Wait Time Today Line Graph
    this.waitTimeChartData = {
      labels: waitingTime ? this.loopData(waitingTime, 0) : [],
      datasets: [
        {
          fill: true,
          borderWidth: 1.5,
          borderColor: "#E5B800",
          // backgroundColor: "#FFF3CD",
          backgroundColor: "rgba(255,209,26,0.5)",
          tension: 0.4,
          data: servingTime ? this.loopData(servingTime, 3) : [], //ave. serving time
          pointStyle: "dash",
        },
        {
          fill: true,
          borderWidth: 1.5,
          borderColor: "#258336",
          // backgroundColor: "#81DF92",
          backgroundColor: "rgba(129,223,146,0.5)",
          tension: 0.4,
          data: waitingTime ? this.loopData(waitingTime, 2) : [], //ave. waiting time
          pointStyle: "dash",
        },
      ],
    };
  }

  loopData(data: any, label: number) {
    let newData = [];
    data.forEach((element, index) => {
      switch (label) {
        case 0: //label
          if (
            this.selectedValue == "today" ||
            this.selectedValue == "yesterday"
          ) {
            // newData.push(this.formatTime2(element.time));
            newData.push(element.time);
          } else if (this.selectedValue == "this-week") {
            newData = defaultWeeklyLabel;
          } else if (this.selectedValue == "this-month") {
            newData.push(index + 1);
          } else if (this.selectedValue == "custom") {
            if (this.isSingleDate) {
              newData.push(element.time);
            } else {
              let idx: number = element.date.lastIndexOf(",");
              let date1 = element.date.substring(idx, idx - 3);
              let date2 = date1.replace(/\D/g, "");
              newData.push(date2.trim());
            }
          }
          break;
        case 1: //total queues
          let totalQueues: number = 0;
          totalQueues =
            element.completed +
            element.cancelled +
            element.onGoing +
            element.removed;
          newData.push(totalQueues);
          break;
        case 2: //ave. waiting time
          newData.push(this.convertMillisToMins(element.averageWaitingTime));
          break;
        case 3: //ave. serving time
          newData.push(this.convertMillisToMins(element.averageServingTime));
          break;
        default:
          break;
      }
    });

    if (newData.length > 0) {
      if (label == 0 && this.selectedValue == "custom" && !this.isSingleDate) {
        newData.shift();
      }
      if (label == 1) {
        if (this.selectedValue == "custom" && !this.isSingleDate) {
          newData.shift();
        }
        //  else if (this.selectedValue == "this-week") {
        //   let fillWeekly = newData.concat(
        //     Array.from({ length: 7 - newData.length }).fill(0)
        //   );
        //   newData = fillWeekly.slice(1).concat(fillWeekly[0]);
        // }
      }
    }

    return newData;
  }

  convertMillisToMins(milliseconds: number) {
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const seconds = Math.floor((milliseconds / 1000) % 60);
    return (minutes + seconds / 60).toFixed(2);
  }

  setMonthDays(): string[] {
    const currentYear = this.dateToday.getFullYear();
    const currentMonth = this.dateToday.getMonth() + 1;
    const numberOfDays = new Date(currentYear, currentMonth, 0).getDate();
    return Array.from({ length: numberOfDays }, (_, i) => (i + 1).toString());
  }

  formatPeakHour(peakHour: string): string {
    let formattedPeakHour = this.formatTimeRange(peakHour);
    return formattedPeakHour;
  }

  formatTimeRange(timeRange) {
    const [start, end] = timeRange.split(" - ");
    const formattedStart = this.formatTime(start);
    const formattedEnd = this.formatTime(end);
    return `${formattedStart} - ${formattedEnd}`;
  }

  formatTime(time: string): string {
    const [hour, period] = time?.split(/(?<=\d)(AM|PM)/);
    return `${hour}:00 ${period}`.toUpperCase();
  }

  formatTime2(time: string): string {
    const [hour, period] = time?.split(/(?<=\d)(am|pm)/);
    return `${hour}:00 ${period}`.toUpperCase();
  }

  setDateLabel() {
    switch (this.selectedValue) {
      case "today":
        this.dateLabel = "yesterday";
        break;
      case "yesterday":
        this.dateLabel = "two days ago";
        break;
      case "this-week":
        this.dateLabel = "last week";
        break;
      case "this-month":
        this.dateLabel = "last month";
        break;
      default:
        this.dateLabel = "";
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const branchIdChange = changes.branchId;
    const selectedValueChange = changes.selectedValue;
    const customDate1Change = changes.customDate1;
    const customDate2Change = changes.customDate2;
    const selectedServiceIdChange = changes.selectedServiceId;
  
    if (
      (branchIdChange && this.isValueChanged(branchIdChange.previousValue, branchIdChange.currentValue)) ||
      (selectedValueChange && this.isValueChanged(selectedValueChange.previousValue, selectedValueChange.currentValue)) ||
      (customDate1Change && this.isValueChanged(customDate1Change.previousValue, customDate1Change.currentValue)) ||
      (customDate2Change && this.isValueChanged(customDate2Change.previousValue, customDate2Change.currentValue)) ||
      (selectedServiceIdChange && this.isValueChanged(selectedServiceIdChange.previousValue, selectedServiceIdChange.currentValue))
    ) {
      this.branchPerformanceData();
    }
  }
  
  private isValueChanged(previousValue: any, currentValue: any): boolean {
    return previousValue !== undefined && previousValue !== currentValue;
  }

  ngOnDestroy(): void {
    this.branchPerformanceSubjectAPI$.next();
    this.branchPerformanceSubjectAPI$.complete();
  }
}
