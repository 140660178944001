import { Component, OnInit, HostListener, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { BranchManagementService } from '../../services/branch-management.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ToastrService } from "ngx-toastr";
import * as _ from 'lodash';

@Component({
  selector: 'app-set-operation-hours-modal',
  templateUrl: './set-operation-hours-modal.component.html',
  styleUrls: ['./set-operation-hours-modal.component.scss']
})
export class SetOperationHoursModalComponent implements OnInit {
  isLoading = false;
  isConfirmDisabled: boolean = false;
  branchId: any;
  hoursLength = 1;
  dropdownList = [
    { label: "8:00 AM",  value: this.getMilliseconds(8, 0),  disabled: false, index: 8 },  
    { label: "9:00 AM",  value: this.getMilliseconds(9, 0),  disabled: false, index: 9 },
    { label: "10:00 AM", value: this.getMilliseconds(10, 0), disabled: false, index: 10 },
    { label: "11:00 AM", value: this.getMilliseconds(11, 0), disabled: false, index: 11 },
    { label: "12:00 PM", value: this.getMilliseconds(12, 0), disabled: false, index: 12 },
    { label: "1:00 PM",  value: this.getMilliseconds(13, 0), disabled: false, index: 13 },
    { label: "2:00 PM",  value: this.getMilliseconds(14, 0), disabled: false, index: 14 },
    { label: "3:00 PM",  value: this.getMilliseconds(15, 0), disabled: false, index: 15 },
    { label: "4:00 PM",  value: this.getMilliseconds(16, 0), disabled: false, index: 16 },
    { label: "5:00 PM",  value: this.getMilliseconds(17, 0), disabled: false, index: 17 },
    { label: "6:00 PM",  value: this.getMilliseconds(18, 0), disabled: false, index: 18 },
    { label: "7:00 PM",  value: this.getMilliseconds(19, 0), disabled: false, index: 19 },
    { label: "8:00 PM",  value: this.getMilliseconds(20, 0), disabled: false, index: 20 },
    { label: "9:00 PM",  value: this.getMilliseconds(21, 0), disabled: false, index: 21 },
    { label: "10:00 PM", value: this.getMilliseconds(22, 0), disabled: false, index: 22 },
    { label: "11:00 PM", value: this.getMilliseconds(23, 0), disabled: false, index: 23 },
    { label: "12:00 AM", value: this.getMilliseconds(24, 0), disabled: false, index: 24 },
    { label: "1:00 AM",  value: this.getMilliseconds(1, 0),  disabled: false, index: 1 }, 
    { label: "2:00 AM",  value: this.getMilliseconds(2, 0),  disabled: false, index: 2 },
    { label: "3:00 AM",  value: this.getMilliseconds(3, 0),  disabled: false, index: 3 },
    { label: "4:00 AM",  value: this.getMilliseconds(4, 0),  disabled: false, index: 4 },
    { label: "5:00 AM",  value: this.getMilliseconds(5, 0),  disabled: false, index: 5 },
    { label: "6:00 AM",  value: this.getMilliseconds(6, 0),  disabled: false, index: 6 },
    { label: "7:00 AM",  value: this.getMilliseconds(7, 0),  disabled: false, index: 7 },
  ];
  @Output() successEvent = new EventEmitter();
  selectedDays = [
    false, 
    true, 
    true, 
    true, 
    true, 
    true, 
    false
  ];
  disableAdd = false;
  defaultOpening: 8;  //8:00 AM
  defaultClosing: 17; //5:00 PM
  hoursData: any;
  operationHours = {
    operationDays: [
      {
        "day": 0,
        "operationHours": []
      },
      {
        "day": 1,
        "operationHours": []
      },
      {
        "day": 2,
        "operationHours": []
      },
      {
        "day": 3,
        "operationHours": []
      },
      {
        "day": 4,
        "operationHours": []
      },
      {
        "day": 5,
        "operationHours": []
      },
      {
        "day": 6,
        "operationHours": []
      },
    ]
  };

  public hoursForm: FormGroup;
  constructor(
    public modalRef: BsModalRef,
    private branchManagementService :BranchManagementService,
    private _fb: FormBuilder,
    private toastr: ToastrService) {
      let dropdownListCopy = _.cloneDeep(this.dropdownList);
      for (let index = this.defaultOpening; index <= this.defaultClosing; index++) {
        const hourIndex = dropdownListCopy.findIndex(x => x.index === index);
        dropdownListCopy[hourIndex].disabled = true;
      }
      this.hoursForm = this._fb.group({
        businessHours: this._fb.array([this.addHoursGroup(dropdownListCopy)])
      });
  }

  get hoursArray() : FormArray {
    return this.hoursForm.get("businessHours") as FormArray
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.setInitialValues();
  }

  setInitialValues() {
    this.hoursArray.controls[0].get('openingIndex').setValue('8'); //8:00 AM
    this.hoursArray.controls[0].get('closingIndex').setValue('17'); //5:00 PM
    let hourList = _.cloneDeep(this.dropdownList);
    const hourIndex = hourList.findIndex(x => x.index === parseInt(this.hoursArray.controls[0].get('openingIndex').value));
    hourList[hourIndex].disabled = true;
    this.hoursArray.controls[0].get('closingOptions').setValue(hourList);
    this.isLoading = false;
  }

  getMilliseconds(hour?: number, minutes?: number) {
    var result = ((((60 * 1000) * 60) * hour ?? 1) + (((60 * 1000)) * minutes ?? 1));
    return result;
  }

  selectDay(idx) {
    this.selectedDays[idx] = !this.selectedDays[idx];
  }

  addHoursGroup(hourList): FormGroup {
    return this._fb.group({
      openingOptions: [hourList],
      closingOptions: [],
      openingIndex: '',
      closingIndex: ''
    });
  }

  //Add Fields
  addHours(): void {
    let opening0 = parseInt(this.hoursArray.controls[0].get('openingIndex').value);
    let closing0 = parseInt(this.hoursArray.controls[0].get('closingIndex').value);
    this.disableAdd = true;
    const hourList = _.cloneDeep(this.dropdownList);
    if(this.hoursArray.value.length == 1){
      if(closing0 < opening0){
        for (let index = opening0; index <= 24; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        for (let index1 = 1; index1 <= closing0; index1++) {
          let hourIndex = hourList.findIndex(x => x.index === index1);
          hourList[hourIndex].disabled = true;
        }
      } else {
        for (let index = opening0; index <= closing0; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
      }
      this.hoursArray.push(this.addHoursGroup(hourList));
      this.hoursLength += 1;
    } else if(this.hoursArray.value.length == 2){
      let closing1 = parseInt(this.hoursArray.controls[1].get('closingIndex').value);
      if(closing1 < opening0){
        for (let index = opening0; index <= 24; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        for (let index1 = 1; index1 <= closing1; index1++) {
          let hourIndex = hourList.findIndex(x => x.index === index1);
          hourList[hourIndex].disabled = true;
        }
      } else {
        for (let index = opening0; index <= closing1; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
      }
      this.hoursArray.push(this.addHoursGroup(hourList));
      this.hoursLength += 1;
    }
  }
 
  //Remove Fields
  removeHours(index: number): void {
    let opening0 = parseInt(this.hoursArray.controls[0].get('openingIndex').value);
    let closing0 = parseInt(this.hoursArray.controls[0].get('closingIndex').value);
    this.hoursArray.removeAt(index);
    this.hoursLength -= 1;
    if(index == 0){
      this.hoursArray.controls[index].get('openingIndex').setValue('');
      this.hoursArray.controls[index].get('openingOptions').setValue(this.dropdownList);
      this.hoursArray.controls[index].get('closingIndex').setValue('');
      this.hoursArray.controls[index].get('closingOptions').setValue([]);
      if(this.hoursArray.value.length == 2){
        this.removeHours(1);
      }
    } else if(index == 1){
      let closingOptions0 = this.hoursArray.controls[0].get('closingOptions').value
      if(this.hoursArray.controls[0].get('closingIndex').value != ''){
        this.disableAdd = false;
      }
      if(this.hoursArray.value.length == 2){
        this.hoursArray.controls[index].get('openingIndex').setValue('');
        this.hoursArray.controls[index].get('openingOptions').setValue(this.dropdownList);
        this.hoursArray.controls[index].get('closingIndex').setValue('');
        this.hoursArray.controls[index].get('closingOptions').setValue([]);
        if(this.hoursArray.controls[index].get('openingIndex').value == ''){
          let hourList = _.cloneDeep(closingOptions0);
          for (let index = opening0; index <= closing0; index++) {
            const hourIndex = hourList.findIndex(x => x.index === index);
            hourList[hourIndex].disabled = true;
          }
          this.hoursArray.controls[index].get('openingOptions').setValue(hourList);
        }
      }
    } else if(index == 2){
      if(this.hoursArray.value[1].closingIndex != ''){
        this.disableAdd = false;
      }
    }
  }

  onOpeningTimeChange(index, value){
    value = parseInt(value);
    let opening0 = parseInt(this.hoursArray.controls[0].get('openingIndex').value);
    let openingOptions0 = this.hoursArray.controls[0].get('openingOptions').value;
    if(index == 0){
      this.hoursArray.controls[index].get('closingOptions').setValue([]);
      this.hoursArray.controls[index].get('closingIndex').setValue('');
      if(this.hoursArray.value.length == 2){
        this.removeHours(1);
        this.disableAdd = true;
      } else {
        this.removeHours(1);
        this.removeHours(1);
        this.disableAdd = true;
      }
      let hourList = _.cloneDeep(openingOptions0);
      const i = hourList.findIndex(x => x.index === value);
      hourList[i].disabled = true;
      this.hoursArray.controls[index].get('closingOptions').setValue(hourList);
    } else if(index == 1){
      let opening1 = parseInt(this.hoursArray.controls[index].get('openingIndex').value);
      let openingOptions1 = this.hoursArray.controls[index].get('openingOptions').value;
      this.hoursArray.controls[index].get('closingOptions').setValue([]);
      this.hoursArray.controls[index].get('closingIndex').setValue('');
      if(this.hoursArray.value.length == 3){
        this.removeHours(2);
      }
      if(value < parseInt(this.hoursArray.value[0].openingIndex)){
        let hourList = _.cloneDeep(openingOptions1);
        for (let index = opening0; index <= 24; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        for (let index = 1; index <= opening1; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        this.hoursArray.controls[index].get('closingOptions').setValue(hourList);
      } else {
        let hourList = _.cloneDeep(openingOptions0);
        for (let index = opening0; index <= value; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        this.hoursArray.controls[index].get('closingOptions').setValue(hourList);
      }
    } else {
      let opening2 = parseInt(this.hoursArray.controls[2].get('openingIndex').value);
      let openingOptions2 = this.hoursArray.controls[2].get('openingOptions').value;
      if(value < opening0){
        let hourList = _.cloneDeep(openingOptions2);
        for (let index = opening0; index <= 24; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        for (let index = 1; index <= opening2; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        this.hoursArray.controls[index].get('closingOptions').setValue(hourList);
      } else {
        let hourList = _.cloneDeep(openingOptions0);
        for (let index = opening0; index <= value; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        this.hoursArray.controls[index].get('closingOptions').setValue(hourList);
      }
    }
  }

  onClosingTimeChange(index){
    let opening0 = parseInt(this.hoursArray.controls[0].get('openingIndex').value);
    let closing0 = parseInt(this.hoursArray.controls[0].get('closingIndex').value);
    this.disableAdd = false;
    if(index == 0){
      if(this.hoursArray.value.length == 2){
        this.removeHours(1);
      } else if(this.hoursArray.value.length == 3){
        this.removeHours(1);
        this.removeHours(1);
      }
      if(opening0 == (closing0 +1)){
        this.disableAdd = true;
      }
    } else if(index == 1){
      let closing1 = parseInt(this.hoursArray.controls[index].get('closingIndex').value);
      if(this.hoursArray.value.length == 3){
        this.removeHours(2);
      }
      if(opening0 == closing1 +1){
        this.disableAdd = true;
      }
    } else {
      this.disableAdd = true;
    }
  }

  checkHours() {
    const index = this.hoursArray.value.findIndex(el => el.openingIndex === '' || el.closingIndex === '');
    if(!this.selectedDays.includes(true)){
      return true;
    } else if(index > -1){
      return true;
    }
    return false;
  }

  onSubmit() {
    this.isLoading = true;
    const hoursObjCopy = this.hoursArray.value;
    const newArr = [];
    for(var j=0;j<hoursObjCopy.length;j++){
      newArr.push({
        openingTime: this.getMilliseconds(hoursObjCopy[j].openingIndex, 0), 
        closingTime: this.getMilliseconds(hoursObjCopy[j].closingIndex, 0)
      });
    }
    for(var i=0;i<this.selectedDays.length;i++){
      if(this.selectedDays[i] === true){
        this.operationHours.operationDays[i].operationHours = newArr;
      }
    }
    this.branchManagementService.setOperationHours(this.branchId, this.operationHours).
      subscribe((res) => {
        console.log(res);
        this.successEvent.emit(res);
        this.modalRef.hide();
        this.toastr.success("Operation hour(s) has been updated", "Success!");
      },
      (error) => {
        this.isLoading = false;
        console.log(error);
        let errMsg = error.error.errors[0].msg;
        this.toastr.error(errMsg, "Error!");
      }
    );
  }
}
