import { Component, HostListener, OnInit, Output, EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpService } from 'src/app/core/http/http.service';
import { DataService } from 'src/app/core/services/data.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { SubscriptionLimitModalComponent } from 'src/app/modules/billing/components/subscription-limit-modal/subscription-limit-modal.component';
import { SUBSCRIPTION_PLAN } from 'src/app/shared/const/plan';

@Component({
  selector: 'app-service-window-add-form',
  templateUrl: './service-window-add-form.component.html',
  styleUrls: ['./service-window-add-form.component.scss']
})
export class ServiceWindowAddFormComponent extends ModalService implements OnInit {
  title: string;
  subTitle: string;
  inputText: string;
  placeholder: string;
  validators: Array<any>;
  isConfirmDisabled: boolean;
  serviceName: string;
  branchId: string;
  type: string
  featureLimits = SUBSCRIPTION_PLAN.professional

  constructor(
    bsModalRef: BsModalRef,
    httpService: HttpService,
    dataService: DataService,
    toastr: ToastrService,
    authService: AuthService,
    private modalService: BsModalService
  ) {
    super(bsModalRef, httpService, dataService, toastr, authService)
  }

  changeInput($event) {
    this.serviceName = $event ? $event.value : null;
    this.isConfirmDisabled = $event.valid ? false : true;
  }

  @HostListener('document:keydown.enter')
  confirm($event) {
    if (!this.isConfirmDisabled) {
      const formBody = { displayName: this.serviceName};
      const param = this.title === 'Add Service' ? `services/${this.branchId}` : `services/windows/${this.branchId}`;
      this.httpService.post$(param, formBody).subscribe(
        data => {
          console.log('success', data);
          this.bsModalRef.hide();
          this.toastr.success('Changes has been saved', 'Success!');
          this.dataService.publishDataChange$({
            action: this.title,
            data: data
          });
        }, error => {
          this.bsModalRef.hide();
          if(error.error.errors[0].msg){
            if(error.error.errors[0].statusCode === 30002) {
              this.showLimitModal(error.error.errors[0].limit);
            }else {
              this.toastr.error(error.error.errors[0].msg, 'Error!');
            }
          } 

          console.log('error', error.error.errors[0].msg);
        }
      )
    }    
  }

  showLimitModal(limit: number = 1): void{
    // const slots = `${this.title === 'Add Service' ? this.featureLimits.featureLimits.service : this.featureLimits.featureLimits.window} ${this.title === 'Add Service' ? 'service' : 'windows'}`
    const slots = `${limit} ${this.title === 'Add Service' ? 'service' : 'window'} ${limit > 1 ? 'slots' : 'slot'}`
    const initialState = {
      title: "Your business is doing so great",
      subTitle1: `that you’ve reached the maximum number of ${this.title === 'Add Service' ? 'services' : 'windows'} on your branch!`,
      // subTitle2: `You have <b>${slots}</b> slots on your current plan`,
      subTitle2: `You have <b>${slots}</b> on your current plan`,
      subTitle3: `Upgrade your plan if you wish to add more ${this.title === 'Add Service' ? 'services' : 'windows'} 👍`
    };
    this.bsModalRef = this.modalService.show(SubscriptionLimitModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  ngOnInit(): void {
    this.isConfirmDisabled = true;
    this.validators = [{
      type: 'required',
      value: Validators.required,
      errormsg: 'This field is required'
    }];
  }

}
