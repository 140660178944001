import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { BranchManagementService } from '../../services/branch-management.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

@Component({
  selector: 'app-edit-operation-hours',
  templateUrl: './edit-operation-hours.component.html',
  styleUrls: ['./edit-operation-hours.component.scss']
})
export class EditOperationHoursComponent implements OnInit {
  isLoading = false;
  isConfirmDisabled: boolean = false;
  branchId: any;
  hoursLength = 0;
  dropdownList = [
    { label: "8:00 AM",  value: this.getMilliseconds(8, 0),  disabled: false, index: 8 },  
    { label: "9:00 AM",  value: this.getMilliseconds(9, 0),  disabled: false, index: 9 },
    { label: "10:00 AM", value: this.getMilliseconds(10, 0), disabled: false, index: 10 },
    { label: "11:00 AM", value: this.getMilliseconds(11, 0), disabled: false, index: 11 },
    { label: "12:00 PM", value: this.getMilliseconds(12, 0), disabled: false, index: 12 },
    { label: "1:00 PM",  value: this.getMilliseconds(13, 0), disabled: false, index: 13 },
    { label: "2:00 PM",  value: this.getMilliseconds(14, 0), disabled: false, index: 14 },
    { label: "3:00 PM",  value: this.getMilliseconds(15, 0), disabled: false, index: 15 },
    { label: "4:00 PM",  value: this.getMilliseconds(16, 0), disabled: false, index: 16 },
    { label: "5:00 PM",  value: this.getMilliseconds(17, 0), disabled: false, index: 17 },
    { label: "6:00 PM",  value: this.getMilliseconds(18, 0), disabled: false, index: 18 },
    { label: "7:00 PM",  value: this.getMilliseconds(19, 0), disabled: false, index: 19 },
    { label: "8:00 PM",  value: this.getMilliseconds(20, 0), disabled: false, index: 20 },
    { label: "9:00 PM",  value: this.getMilliseconds(21, 0), disabled: false, index: 21 },
    { label: "10:00 PM", value: this.getMilliseconds(22, 0), disabled: false, index: 22 },
    { label: "11:00 PM", value: this.getMilliseconds(23, 0), disabled: false, index: 23 },
    { label: "12:00 AM", value: this.getMilliseconds(24, 0), disabled: false, index: 24 },
    { label: "1:00 AM",  value: this.getMilliseconds(1, 0),  disabled: false, index: 1 }, 
    { label: "2:00 AM",  value: this.getMilliseconds(2, 0),  disabled: false, index: 2 },
    { label: "3:00 AM",  value: this.getMilliseconds(3, 0),  disabled: false, index: 3 },
    { label: "4:00 AM",  value: this.getMilliseconds(4, 0),  disabled: false, index: 4 },
    { label: "5:00 AM",  value: this.getMilliseconds(5, 0),  disabled: false, index: 5 },
    { label: "6:00 AM",  value: this.getMilliseconds(6, 0),  disabled: false, index: 6 },
    { label: "7:00 AM",  value: this.getMilliseconds(7, 0),  disabled: false, index: 7 },
  ];
  dropdownListCopy = [];
  dayList = [
    {label: "Monday", value: 1},
    {label: "Tuesday", value: 2},
    {label: "Wednesday", value: 3},
    {label: "Thursday", value: 4},
    {label: "Friday", value: 5},
    {label: "Saturday", value: 6},
    {label: "Sunday", value: 0},
  ];
  disableAdd = false;
  defaultOpening: 8;  //8:00 AM
  defaultClosing: 17; //5:00 PM
  midnightIndex: any;
  public hoursForm: FormGroup;
  day: any;
  dayName: any;
  operationHoursToday: any;
  operationHoursYesterday: any;
  operationHoursTomorrow: any;
  @Output() successEvent = new EventEmitter();
  operationHoursWeek: any;
  hasPrevDaySchedule = false;
  prevDayLastOpeningIndex = null;
  prevDayClosingIndex = null;
  nextDayOpeningIndex = null;
  passedMidnight = false;
  isFocused: boolean;

  constructor(
    public modalRef: BsModalRef,
    private branchManagementService :BranchManagementService,
    private _fb: FormBuilder,
    private toastr: ToastrService
    ) {
    this.hoursForm = this._fb.group({
      businessHours: this._fb.array([])
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    const prevDayIdx = this.day == 0 ? 6 : (this.day-1);
    const nextDayIdx = this.day == 6 ? 0 : (this.day+1);
    this.midnightIndex = this.dropdownList.findIndex(x => x.index === 24);
    this.branchManagementService.getOperationDays(this.branchId).subscribe((res) => {
      if(res){
        this.operationHoursWeek = res.data;
        this.operationHoursToday = this.operationHoursWeek[this.day].operationHours;
        this.operationHoursYesterday = this.operationHoursWeek[prevDayIdx].operationHours;
        this.operationHoursTomorrow = this.operationHoursWeek[nextDayIdx].operationHours;
        if(this.operationHoursYesterday.length > 0){
          this.prevDayClosingIndex = this.getHourIndex(this.operationHoursYesterday[this.operationHoursYesterday.length - 1].closingTime);
          this.prevDayLastOpeningIndex = this.getHourIndex(this.operationHoursYesterday[this.operationHoursYesterday.length - 1].openingTime);
        }
        this.passedMidnight = this.prevDayLastOpeningIndex > this.prevDayClosingIndex ? true : false;
        if(this.operationHoursYesterday.length == 1 && this.prevDayLastOpeningIndex == 24){
          this.passedMidnight = false;
        }
        if(this.operationHoursTomorrow.length > 0){
          this.nextDayOpeningIndex = this.getHourIndex(this.operationHoursTomorrow[0].openingTime);
        }
        this.hoursLength = this.operationHoursToday.length;
        this.dropdownListCopy = _.cloneDeep(this.dropdownList);
        if(this.hoursLength > 0){
          this.patchTimeRange(this.operationHoursToday);
        } else {
          this.hoursLength = 0;
          if(this.prevDayClosingIndex != null){
            if(this.passedMidnight){
              for (let index = 1; index <= this.prevDayClosingIndex; index++) {
                let hourIndex = this.dropdownListCopy.findIndex(x => x.index === index);
                this.dropdownListCopy[hourIndex].disabled = true;
              }
              this.dropdownListCopy[this.midnightIndex].disabled = true; // include 12:00 AM
            }
            if(this.prevDayClosingIndex == 24){
              this.dropdownListCopy[this.midnightIndex].disabled = true; // include 12:00 AM
            }
          }
          console.log(this.nextDayOpeningIndex);
          if(this.nextDayOpeningIndex != null && this.nextDayOpeningIndex == 24){
            this.dropdownListCopy[this.midnightIndex].disabled = true; // include 12:00 AM
            // for (let index = this.nextDayOpeningIndex; index <= 24; index++) {
            //   let hourIndex = this.dropdownListCopy.findIndex(x => x.index === index);
            //   this.dropdownListCopy[hourIndex].disabled = true;
            // }
          }
          this.addHoursClosed(this.dropdownListCopy);
        }
      }
    });
    
    var dayObj = this.dayList.filter(x => x.value === this.day);
    this.dayName = dayObj[0].label;
    this.isLoading = false;
  }

  patchTimeRange(data) {
    data.forEach((element, i) => {
      let hourList = _.cloneDeep(this.dropdownList);
      console.log(this.prevDayLastOpeningIndex, this.prevDayClosingIndex);
      console.log(this.passedMidnight);
      if(this.prevDayClosingIndex != null){
        if(this.passedMidnight){
          for (let index = 1; index <= this.prevDayClosingIndex; index++) {
            let hourIndex = hourList.findIndex(x => x.index === index);
            hourList[hourIndex].disabled = true;
          }
          hourList[this.midnightIndex].disabled = true; // include 12:00 AM
        }
        if(this.prevDayClosingIndex == 24){
          hourList[this.midnightIndex].disabled = true; // include 12:00 AM
        }
      }
      console.log(hourList);
      this.hoursArray.push(
        this._fb.group({
          openingIndex: this.getHourIndex(element.openingTime),
          closingIndex: this.getHourIndex(element.closingTime),
          openingOptions: [(this.prevDayClosingIndex != null && this.passedMidnight) ? hourList : this.dropdownList],
          closingOptions: [],
        })
      );
      this.setInitialTimeOptions(i, parseInt(this.hoursArray.value[i].openingIndex));
    });
  }

  getMilliseconds(hour?: number, minutes?: number) {
    var result = ((((60 * 1000) * 60) * hour ?? 1) + (((60 * 1000)) * minutes ?? 1));
    return result;
  }
  
  getHourIndex(milliseconds?: number){
    var result = milliseconds / 3600000;
    return result;
  }

  addHoursGroup(hourList): FormGroup {
    return this._fb.group({
      openingOptions: [hourList],
      closingOptions: [],
      openingIndex: '',
      closingIndex: ''
    });
  }

  //Add Fields
  addHoursClosed(data: any): void {
    let hourList = _.cloneDeep(this.dropdownList);
    if(data){
      hourList = _.cloneDeep(this.dropdownListCopy);
    }
    for (let arrayIndex = 0; arrayIndex < this.hoursLength; arrayIndex++) {
      const opening = this.hoursArray.controls[arrayIndex].get('openingIndex').value;
      const closing = this.hoursArray.controls[arrayIndex].get('closingIndex').value;

      for (let index = parseInt(opening); index <= parseInt(closing); index++) {
        const hourIndex = hourList.findIndex(x => x.index === index);
        hourList[hourIndex].disabled = true;
      }
    }
    this.hoursArray.push(this.addHoursGroup(hourList));
    this.hoursLength += 1;
  }

  //Add Fields
  addHours(): void {
    let opening0 = parseInt(this.hoursArray.controls[0].get('openingIndex').value);
    let closing0 = parseInt(this.hoursArray.controls[0].get('closingIndex').value);
    this.disableAdd = true;
    const hourList = _.cloneDeep(this.dropdownList);
    if(this.hoursArray.value.length == 1){
      if(closing0 < opening0){
        for (let index = opening0; index <= 24; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        for (let index1 = 1; index1 <= closing0; index1++) {
          let hourIndex = hourList.findIndex(x => x.index === index1);
          hourList[hourIndex].disabled = true;
        }
      } else {
        for (let index = opening0; index <= closing0; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
      }
      this.hoursArray.push(this.addHoursGroup(hourList));
      this.hoursLength += 1;
    } else if(this.hoursArray.value.length == 2){
      let closing1 = parseInt(this.hoursArray.controls[1].get('closingIndex').value);
      if(closing1 < opening0){
        for (let index = opening0; index <= 24; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        for (let index1 = 1; index1 <= closing1; index1++) {
          let hourIndex = hourList.findIndex(x => x.index === index1);
          hourList[hourIndex].disabled = true;
        }
      } else {
        for (let index = opening0; index <= closing1; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
      }
      this.hoursArray.push(this.addHoursGroup(hourList));
      this.hoursLength += 1;
    }
  }
 
  //Remove Fields
  removeHours(index: number): void {
    let opening0 = parseInt(this.hoursArray.controls[0].get('openingIndex').value);
    let closing0 = parseInt(this.hoursArray.controls[0].get('closingIndex').value);
    this.hoursArray.removeAt(index);
    this.hoursLength -= 1;
    if(index == 0){
      this.hoursArray.controls[index].get('openingIndex').setValue('');
      if(this.prevDayClosingIndex != null){
        if(this.passedMidnight){
          for (let index = 1; index <= this.prevDayClosingIndex; index++) {
            let hourIndex = this.dropdownListCopy.findIndex(x => x.index === index);
            this.dropdownListCopy[hourIndex].disabled = true;
          }
          this.dropdownListCopy[this.midnightIndex].disabled = true; // include 12:00 AM
        }
        if(this.prevDayClosingIndex == 24){
          this.dropdownListCopy[this.midnightIndex].disabled = true; // include 12:00 AM
        }
        this.hoursArray.controls[index].get('openingOptions').setValue(this.dropdownListCopy);
      } else {
        this.hoursArray.controls[index].get('openingOptions').setValue(this.dropdownList);
      }
      this.hoursArray.controls[index].get('closingIndex').setValue('');
      this.hoursArray.controls[index].get('closingOptions').setValue([]);
      if(this.hoursArray.value.length == 2){
        this.removeHours(1);
      }
    } else if(index == 1){
      let closingOptions0 = this.hoursArray.controls[0].get('closingOptions').value
      if(this.hoursArray.controls[0].get('closingIndex').value != ''){
        this.disableAdd = false;
      }
      if(this.hoursArray.value.length == 2){
        this.hoursArray.controls[index].get('openingIndex').setValue('');
        this.hoursArray.controls[index].get('openingOptions').setValue(this.dropdownList);
        this.hoursArray.controls[index].get('closingIndex').setValue('');
        this.hoursArray.controls[index].get('closingOptions').setValue([]);
        if(this.hoursArray.controls[index].get('openingIndex').value == ''){
          let hourList = _.cloneDeep(closingOptions0);
          for (let index = opening0; index <= closing0; index++) {
            const hourIndex = hourList.findIndex(x => x.index === index);
            hourList[hourIndex].disabled = true;
          }
          this.hoursArray.controls[index].get('openingOptions').setValue(hourList);
        }
      }
    } else if(index == 2){
      if(this.hoursArray.value[1].closingIndex != ''){
        this.disableAdd = false;
      }
    }
  }

  onOpeningTimeChange(index, value){
    value = parseInt(value);
    let opening0 = parseInt(this.hoursArray.controls[0].get('openingIndex').value);
    // let openingOptions0 = this.hoursArray.controls[0].get('openingOptions').value;
    let openingOptions0 = this.dropdownList;
    if(index == 0){
      this.hoursArray.controls[index].get('closingOptions').setValue([]);
      this.hoursArray.controls[index].get('closingIndex').setValue('');
      if(this.hoursArray.value.length == 2){
        this.removeHours(1);
        this.disableAdd = true;
      } else {
        this.removeHours(1);
        this.removeHours(1);
        this.disableAdd = true;
      }
      let hourList = _.cloneDeep(openingOptions0);
      const i = hourList.findIndex(x => x.index === value);
      hourList[i].disabled = true;
      // if(this.nextDayOpeningIndex != null){
      //   for (let index1 = this.nextDayOpeningIndex; index1 <= 24; index1++) {
      //     let hourIndex = hourList.findIndex(x => x.index === index1);
      //     hourList[hourIndex].disabled = true;
      //   }
      // }
      this.hoursArray.controls[index].get('closingOptions').setValue(hourList);
    } else if(index == 1){
      let opening1 = parseInt(this.hoursArray.controls[index].get('openingIndex').value);
      let openingOptions1 = this.hoursArray.controls[index].get('openingOptions').value;
      this.hoursArray.controls[index].get('closingOptions').setValue([]);
      this.hoursArray.controls[index].get('closingIndex').setValue('');
      if(this.hoursArray.value.length == 3){
        this.removeHours(2);
      }
      if(value < parseInt(this.hoursArray.value[0].openingIndex)){
        let hourList = _.cloneDeep(openingOptions1);
        for (let index = opening0; index <= 24; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        for (let index = 1; index <= opening1; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        this.hoursArray.controls[index].get('closingOptions').setValue(hourList);
      } else {
        let hourList = _.cloneDeep(openingOptions0);
        for (let index = opening0; index <= value; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        this.hoursArray.controls[index].get('closingOptions').setValue(hourList);
      }
    } else {
      let opening2 = parseInt(this.hoursArray.controls[2].get('openingIndex').value);
      let openingOptions2 = this.hoursArray.controls[2].get('openingOptions').value;
      if(value < opening0){
        let hourList = _.cloneDeep(openingOptions2);
        for (let index = opening0; index <= 24; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        for (let index = 1; index <= opening2; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        this.hoursArray.controls[index].get('closingOptions').setValue(hourList);
      } else {
        let hourList = _.cloneDeep(openingOptions0);
        for (let index = opening0; index <= value; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        this.hoursArray.controls[index].get('closingOptions').setValue(hourList);
      }
    }
  }

  onClosingTimeChange(index){
    let opening0 = parseInt(this.hoursArray.controls[0].get('openingIndex').value);
    let closing0 = parseInt(this.hoursArray.controls[0].get('closingIndex').value);
    this.disableAdd = false;
    if(index == 0){
      if(this.hoursArray.value.length == 2){
        this.removeHours(1);
      } else if(this.hoursArray.value.length == 3){
        this.removeHours(1);
        this.removeHours(1);
      }
      if(opening0 == (closing0 +1)){
        this.disableAdd = true;
      }
    } else if(index == 1){
      let closing1 = parseInt(this.hoursArray.controls[index].get('closingIndex').value);
      if(this.hoursArray.value.length == 3){
        this.removeHours(2);
      }
      if(opening0 == closing1 +1){
        this.disableAdd = true;
      }
    } else {
      this.disableAdd = true;
    }
  }

  setInitialTimeOptions(index, value){
    let opening0 = parseInt(this.hoursArray.controls[0].get('openingIndex').value);
    let closing0 = parseInt(this.hoursArray.controls[0].get('closingIndex').value);
    // let openingOptions0 = _.cloneDeep(this.hoursArray.controls[0].get('openingOptions').value);
    let openingOptions0 = this.dropdownList;
    let hourList = _.cloneDeep(this.dropdownList);
    if(index == 0){
      if(this.prevDayClosingIndex != null){
        if(this.passedMidnight){
          for (let index = 1; index <= this.prevDayClosingIndex; index++) {
            let hourIndex = this.dropdownListCopy.findIndex(x => x.index === index);
            this.dropdownListCopy[hourIndex].disabled = true;
          }
          this.dropdownListCopy[this.midnightIndex].disabled = true; // include 12:00 AM
        }
        if(this.prevDayClosingIndex == 24){
          this.dropdownListCopy[this.midnightIndex].disabled = true; // include 12:00 AM
        }
        this.hoursArray.controls[index].get('openingOptions').setValue(this.dropdownListCopy);
      } else {
        this.hoursArray.controls[index].get('openingOptions').setValue(this.dropdownList);
      }
      const i = hourList.findIndex(x => x.index === opening0);
      hourList[i].disabled = true;
      this.hoursArray.controls[index].get('closingOptions').setValue(hourList);
    } else if(index == 1){
      if(closing0 < opening0){
        for (let index = opening0; index <= 24; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        for (let index1 = 1; index1 <= closing0; index1++) {
          let hourIndex = hourList.findIndex(x => x.index === index1);
          hourList[hourIndex].disabled = true;
        }
      } else {
        for (let index = opening0; index <= closing0; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
      }
      this.hoursArray.controls[index].get('openingOptions').setValue(hourList);

      let opening1 = parseInt(this.hoursArray.controls[index].get('openingIndex').value);
      let openingOptions1 = this.hoursArray.controls[index].get('openingOptions').value;
      if(value < parseInt(this.hoursArray.value[0].openingIndex)){
        let hourList = _.cloneDeep(openingOptions1);
        for (let index = opening0; index <= 24; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        for (let index = 1; index <= opening1; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        this.hoursArray.controls[index].get('closingOptions').setValue(hourList);
      } else {
        let hourList = _.cloneDeep(openingOptions0);
        for (let index = opening0; index <= value; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        this.hoursArray.controls[index].get('closingOptions').setValue(hourList);
      }
    } else {
      let closing1 = parseInt(this.hoursArray.controls[1].get('closingIndex').value);
      if(closing1 < opening0){
        for (let index = opening0; index <= 24; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        for (let index1 = 1; index1 <= closing1; index1++) {
          let hourIndex = hourList.findIndex(x => x.index === index1);
          hourList[hourIndex].disabled = true;
        }
      } else {
        for (let index = opening0; index <= closing1; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
      }
      this.hoursArray.controls[index].get('openingOptions').setValue(hourList);

      let opening2 = parseInt(this.hoursArray.controls[2].get('openingIndex').value);
      let openingOptions2 = this.hoursArray.controls[2].get('openingOptions').value;
      if(value < opening0){
        let hourList = _.cloneDeep(openingOptions2);
        for (let index = opening0; index <= 24; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        for (let index = 1; index <= opening2; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        this.hoursArray.controls[index].get('closingOptions').setValue(hourList);
      } else {
        let hourList = _.cloneDeep(openingOptions0);
        for (let index = opening0; index <= value; index++) {
          let hourIndex = hourList.findIndex(x => x.index === index);
          hourList[hourIndex].disabled = true;
        }
        this.hoursArray.controls[index].get('closingOptions').setValue(hourList);
      }
    }
  }

  get hoursArray() : FormArray {
    return this.hoursForm.get("businessHours") as FormArray
  }

  checkHours() {
    if(!this.isLoading){
      const index = this.hoursArray.value.findIndex(el => el.openingIndex === '' || el.closingIndex === '');
      if(this.hoursArray.value.length > 1 && index > -1){
        return true;
      }
      if(this.hoursArray.value.length > 0){
        if(this.hoursArray.value[0].openingIndex != '' && this.hoursArray.value[0].closingIndex == ''){
          return true;
        }
        if(this.hoursArray.value[0].openingIndex == '' && this.hoursArray.value[0].closingIndex != ''){
          return true;
        }
      }
      return false;
    }
  }

  onSubmit() {
    this.isLoading = true;
    const hoursObjCopy = this.hoursArray.value;
    let newArr = [];
    for(var j=0;j<hoursObjCopy.length;j++){
      newArr.push({
        openingTime: this.getMilliseconds(hoursObjCopy[j].openingIndex, 0), 
        closingTime: this.getMilliseconds(hoursObjCopy[j].closingIndex, 0)
      });
    }
    if(newArr.length == 1){
      if(newArr[0].openingTime == 0 || newArr[0].closingTime == 0){
        newArr = [];
      }
    }
    const data = 
    {
      "operationDays":
      [
        {
          "day": this.day,
          "operationHours": newArr
        },
      ]
    };
    this.branchManagementService.setOperationHoursByDay(this.branchId, data).
      subscribe((res) => {
        console.log(res);
        this.successEvent.emit(res);
        this.modalRef.hide();
        this.toastr.success(`Operation hour(s) for ${this.dayName} has been updated`, "Success!");
      },
      (error) => {
        this.isLoading = false;
        console.log(error);
        let errMsg = error.error.errors;
        this.toastr.error(errMsg, "Error!");
      }
    );
  }
}
