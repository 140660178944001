import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { defaultAvatar } from 'src/app/core/services/global';

@Component({
  selector: 'app-avatar-upload',
  templateUrl: './avatar-upload.component.html',
  styleUrls: ['./avatar-upload.component.scss']
})
export class AvatarUploadComponent implements OnInit, OnChanges {
  @Input() avatarImage: string;
  @Input() externalData: any;
  @Output() changedAvatar = new EventEmitter();
  defaultAvatar: string = defaultAvatar;
  imageUrl: any;

  constructor() { }

  onAvatarChange($event) {  
    if ($event[0]) {
      const reader = new FileReader();
      reader.readAsDataURL($event[0]);
      reader.onload = (event) => {
        this.imageUrl = event.target.result;
      }
      this.changedAvatar.emit($event[0]);
    }
  }

  checkError($event) {
    this.imageUrl = this.defaultAvatar;
  }

  ngOnChanges(): void {
    this.imageUrl = this.avatarImage;
  }

  ngOnInit(): void {
  }

}
