import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { RouterModule } from "@angular/router";
import { LoggedinLayoutComponent } from "./layouts/loggedin-layout/loggedin-layout.component";
import { ContentBlankComponent } from "./layouts/content-blank/content-blank.component";
import { NavbarComponent } from "./headers/navbar/navbar.component";
import { SidebarComponent } from "./headers/sidebar/sidebar.component";
import { InputSelectOverlayComponent } from "./form-components/input-select-overlay/input-select-overlay.component";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ContentWithHeaderComponent } from "./layouts/content-with-header/content-with-header.component";
import { SideListComponent } from "./form-components/side-list/side-list.component";
import { InputEditComponent } from "./form-components/input-edit/input-edit.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToggleSwitchComponent } from "./form-components/toggle-switch/toggle-switch.component";
import { DetailEditComponent } from "./form-components/detail-edit/detail-edit.component";
import { AvatarUploadComponent } from "./form-components/avatar-upload/avatar-upload.component";
import { TagInputModule } from "ngx-chips";
import { TagListComponent } from "./form-components/tag-list/tag-list.component";
import { InputPlainComponent } from "./form-components/input-plain/input-plain.component";
import { ButtonCancelConfirmComponent } from "./form-components/button-cancel-confirm/button-cancel-confirm.component";
import { ModalWarningImageComponent } from "./components/modal-warning-image/modal-warning-image.component";
import { ModalWarningInputComponent } from "./components/modal-warning-input/modal-warning-input.component";
import { RadioListComponent } from "./form-components/radio-list/radio-list.component";
import { ModalGenericComponent } from "./components/modal-generic/modal-generic.component";
import { CheckboxListComponent } from "./form-components/checkbox-list/checkbox-list.component";
import { InputNumberComponent } from "./form-components/input-number/input-number.component";
import { ToggleCheckboxListComponent } from "./form-components/toggle-checkbox-list/toggle-checkbox-list.component";
import { IconComponent } from "./components/icon/icon.component";
import { TabFilterSearchComponent } from "./form-components/tab-filter-search/tab-filter-search.component";
import { ModalFilterComponent } from "./components/modal-filter/modal-filter.component";
import { InputSelectComponent } from "./form-components/input-select/input-select.component";
import { TimePipe } from "./pipes/time.pipe";
import { TimelineComponent } from "./components/timeline/timeline.component";
import { TimeAgoPipe } from "time-ago-pipe";
import { MobileMaskDirective } from "./directives/mobile-mask.directive";
import { ButtonCardsComponent } from "./form-components/button-cards/button-cards.component";
import { EmptyStateComponent } from "./components/empty-state/empty-state.component";
import { ModalConfirmComponent } from "./components/modal-confirm/modal-confirm.component";
import { InputActiveDirective } from "./directives/input-active.directive";
import { MobileTransformPipe } from "./pipes/mobile-transform.pipe";
import { SvgIconsComponent } from "./components/svg-icons/svg-icons.component";
import { DatePickerModalComponent } from "./components/date-picker-modal/date-picker-modal.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { SearchDataPipe } from "./pipes/search-data.pipe";
import { SortPipe } from "./pipes/sort.pipe";
import { TimeagoModule } from "ngx-timeago";
import { ModalResetPasswordComponent } from "./components/modal-reset-password/modal-reset-password.component";
import { UppercaseInputDirective } from "./directives/uppercase-input.directive";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CheckboxListV2Component } from './form-components/checkbox-list-v2/checkbox-list-v2.component';
import { CheckboxListV3Component } from './form-components/checkbox-list-v3/checkbox-list-v3.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { FileDropComponent } from './form-components/file-drop/file-drop.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { AccordionCustomComponent, AccordionItemComponent } from "./components/accordion/index";
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NotificationsComponent } from "../modules/notifications/notifications.component";
import { ViewNotificationComponent } from "../modules/notifications/components/view-notification/view-notification.component";
import { notificationReducer } from "../modules/notifications/store/reducers/notifications";
import { StoreModule } from "@ngrx/store";
import { InputIconComponent } from './form-components/input-icon/input-icon.component';
import { InputTextareaComponent } from './form-components/input-textarea/input-textarea.component';
@NgModule({
  declarations: [
    NotificationsComponent,
    ViewNotificationComponent,
    AuthLayoutComponent,
    LoggedinLayoutComponent,
    ContentBlankComponent,
    NavbarComponent,
    SidebarComponent,
    InputSelectOverlayComponent,
    ContentWithHeaderComponent,
    SideListComponent,
    InputEditComponent,
    ToggleSwitchComponent,
    DetailEditComponent,
    AvatarUploadComponent,
    TagListComponent,
    InputPlainComponent,
    ButtonCancelConfirmComponent,
    ModalWarningImageComponent,
    ModalWarningInputComponent,
    RadioListComponent,
    ModalGenericComponent,
    CheckboxListComponent,
    CheckboxListV2Component,
    CheckboxListV3Component,
    InputNumberComponent,
    ToggleCheckboxListComponent,
    IconComponent,
    TabFilterSearchComponent,
    ModalFilterComponent,
    InputSelectComponent,
    TimePipe,
    // TimeAgoPipe,
    TimelineComponent,
    MobileMaskDirective,
    ButtonCardsComponent,
    EmptyStateComponent,
    ModalConfirmComponent,
    InputActiveDirective,
    MobileTransformPipe,
    SvgIconsComponent,
    DatePickerModalComponent,
    SearchDataPipe,
    SortPipe,
    ModalResetPasswordComponent,
    UppercaseInputDirective,
    BreadcrumbsComponent,
    FileDropComponent,
    DatePickerComponent,
    AccordionCustomComponent,
    AccordionItemComponent,
    SafeHtmlPipe,
    InputIconComponent,
    InputTextareaComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    TagInputModule,
    TimeagoModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    StoreModule.forFeature('notificationState', notificationReducer),
  ],
  exports: [
    InputSelectOverlayComponent,
    SideListComponent,
    InputEditComponent,
    ToggleSwitchComponent,
    DetailEditComponent,
    AvatarUploadComponent,
    TagListComponent,
    InputPlainComponent,
    ButtonCancelConfirmComponent,
    ModalWarningImageComponent,
    ModalWarningInputComponent,
    RadioListComponent,
    ModalGenericComponent,
    CheckboxListComponent,
    CheckboxListV2Component,
    CheckboxListV3Component,
    InputNumberComponent,
    ToggleCheckboxListComponent,
    IconComponent,
    TabFilterSearchComponent,
    ModalFilterComponent,
    InputSelectComponent,
    TimePipe,
    // TimeAgoPipe,
    TimelineComponent,
    ButtonCardsComponent,
    InputActiveDirective,
    EmptyStateComponent,
    MobileMaskDirective,
    MobileTransformPipe,
    SvgIconsComponent,
    DatePickerModalComponent,
    SearchDataPipe,
    SortPipe,
    TimeagoModule,
    BreadcrumbsComponent,
    FileDropComponent,
    DatePickerComponent,
    AccordionCustomComponent,
    AccordionItemComponent,
    InputIconComponent,
    InputTextareaComponent
  ],
  entryComponents: [
    ModalWarningImageComponent,
    ModalWarningInputComponent,
    ModalGenericComponent,
    ModalFilterComponent,
    ModalConfirmComponent,
    DatePickerModalComponent,
    ModalResetPasswordComponent,
  ],
  providers: [
    TimePipe,
    // TimeAgoPipe
  ],
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
