import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-side-list',
  templateUrl: './side-list.component.html',
  styleUrls: ['./side-list.component.scss']
})
export class SideListComponent implements OnInit, OnChanges {
  @Input() lists: Array<any>;
  @Input() newLists: Array<any>;
  @Input() selectedIndex: number;
  @Output() selectedButton = new EventEmitter();
  activeIndex: any;

  constructor() { }

  select(i) {
    this.activeIndex = i;
    this.selectedButton.emit(i);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.activeIndex = this.selectedIndex;
  }

  ngOnInit(): void {
    this.activeIndex = 0;
  }

}
