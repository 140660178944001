export const SUBSCRIPTION_PLAN = {
  trial: {
    featureLimits: {
      branch: 1,
      service: 6,
      window: 5,
      user: 0,
      queue: 9000,
    },
  },
  starter: {
    featureLimits: {
      branch: 1,
      service: 2,
      window: 1,
      user: 1,
      queue: 3000,
    },
  },
  business: {
    featureLimits: {
      branch: 1,
      service: 5,
      window: 3,
      user: 4,
      queue: 3000,
    },
  },
  professional: {
    featureLimits: {
      branch: 1,
      service: 6,
      window: 5,
      user: 0,
      queue: 9000,
    },
    free: {
      sms: 1500,
    },
  },
  enterprise: {
    featureLimits: {
      branch: 1,
      service: 8,
      window: 6,
      user: 0,
      queue: 25000,
    },
    free: {
      sms: 2000,
    },
  },
};
