import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-branch-success-modal',
  templateUrl: './add-branch-success-modal.component.html',
  styleUrls: ['./add-branch-success-modal.component.scss']
})
export class AddBranchSuccessModalComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef, private router: Router) { }

  branchData: any;

  ngOnInit(): void {
  }

  addTeam(){
    this.bsModalRef.hide();
    this.router.navigate(["/team-management"], {
      queryParams: { type: "invite" },
    });
  }

  skipForNow() {
    this.router.navigate(["/branch-management/", this.branchData._id]);
    this.bsModalRef.hide();
  }
}
