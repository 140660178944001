import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ModalFilterComponent } from '../../components/modal-filter/modal-filter.component';

@Component({
  selector: 'app-tab-filter-search',
  templateUrl: './tab-filter-search.component.html',
  styleUrls: ['./tab-filter-search.component.scss']
})
export class TabFilterSearchComponent implements OnInit {
  @Input() tabLists: Array<any>;
  @Input() selectedQueueStatus: string;
  @Input() selectedSortFilter: any;
  @Input() filterParamArray: Array<any>;
  @Input() filterParam: Array<any>;
  @Input() isDisabledFilterTabs: boolean;
  @Input() maxLength: number;
  @Input() placeholder: string = '';
  @Input() hideFilter: boolean = false;
  @Input() hideSearchText: boolean = false;
  @Output() selectedTab = new EventEmitter();
  @Output() searchedString = new EventEmitter();
  activeTab: number;
  searchMode: boolean;
  searchString: string;
  searchTerm$ = new Subject<string>();
  subscriptions = new Subscription();

  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef
  ) { }

  selectTab(i) {
    this.activeTab = i;
    this.selectedTab.emit(this.tabLists[i].value);
  }

  openFilterModal() {
    const initialState = {
      title: this.selectedQueueStatus === 'pending' ? 'Filter - Ongoing Queues' : 'Filter - Current Serving Queues',
      selectedSortFilter: this.selectedSortFilter,
      filterParamArray: this.filterParamArray,
      filterParam: this.filterParam,
    }
    this.modalRef = this.modalService.show(ModalFilterComponent, {
      initialState: initialState,
      class: 'modal-dialog-centered',
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  search() {
    this.searchMode = true;
  }

  clearSearch() {
    this.searchString = null;
    this.searchMode = false;
    this.searchTerm$.next('');
  }

  debounceSearch(terms: Observable<string>) {
    return terms
    .pipe(
      debounceTime(500),
      distinctUntilChanged()
    );
  }

  checkSearchString() {
    this.subscriptions.add(this.debounceSearch(this.searchTerm$).subscribe((res) => {
      this.searchedString.emit(res);
    }));
  }

  ngOnInit(): void {
    this.activeTab = 1;
    this.checkSearchString();
  }

}
