import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpService } from 'src/app/core/http/http.service';
import { DataService } from 'src/app/core/services/data.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { ServiceWindowService } from '../../services/service-window.service';

@Component({
  selector: 'app-service-smart-form',
  templateUrl: './service-smart-form.component.html',
  styleUrls: ['./service-smart-form.component.scss']
})
export class ServiceSmartFormComponent extends ModalService implements OnInit {
  fieldLists: Array<any>;
  formValue: Array<any>;
  serviceId: string;
  branchId: string;

  constructor(
    bsModalRef: BsModalRef,
    httpService: HttpService,
    dataService: DataService,
    toastr: ToastrService,
    authService: AuthService,
    private serviceWindowService: ServiceWindowService
  ) {
    super(bsModalRef, httpService, dataService, toastr, authService)
  }

  changeItem($event) {
    console.log($event);
    this.formValue = $event.formValue.itemArray;
    this.isConfirmDisabled = $event.isConfirmDisabled;
  }

  confirm($event) {
    const param = `services/${this.branchId}/${this.serviceId}`;
    const bodyForm = {
      queueForm: {
        fields: this.formValue
      }
    }
    console.log('bodyForm', bodyForm);
    this.serviceWindowService.submitPatchForm(param, bodyForm).then(data => {
      console.log('smart form', data);
      this.toastr.success('Changes has been saved', 'Success!');
      this.dataService.publishDataChange$('smart form');
      this.bsModalRef.hide();
    });
  }

  ngOnInit(): void {
    console.log('fieldLists', this.fieldLists);
    this.isConfirmDisabled = true;
  }

}
