import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "src/app/core/services/data.service";
import { BranchManagementService } from "src/app/modules/branch-management/services/branch-management.service";
import { AppointmentSettingsService } from "src/app/modules/appointment/services/appointment-settings.service"; 
import { Subscription } from "rxjs";

var misc: any = {
  sidebar_mini_active: true,
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  isBottom?: boolean;
  staffOnly?: boolean;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}
//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "home",
    isCollapsed: false,
    isBottom: false,
    staffOnly: true,
  },
  {
    path: "/queue-workspace",
    title: "Queue Workspace",
    type: "link",
    icontype: "queue-workspace",
    isCollapsed: false,
    isBottom: false,
    staffOnly: true,
  },
  ,
  {
    path: "/appointment",
    title: "Appointment",
    type: "link",
    icontype: "ic_appointment",
    isCollapsed: false,
    isBottom: false,
    staffOnly: false,
  },
  {
    path: "/monitoring",
    title: "Monitoring",
    type: "link",
    icontype: "monitoring",
    isCollapsed: false,
    isBottom: false,
  },
  {
    path: "/reports",
    title: "Reports",
    type: "link",
    icontype: "report",
    isCollapsed: false,
    isBottom: false,
    // staffOnly: true,
  },
  // {
  //   path: "/window",
  //   title: "Window",
  //   type: "link",
  //   icontype: "window",
  //   isCollapsed: false,
  //   isBottom: false,
  // },
  // {
  //   path: "/service",
  //   title: "Service",
  //   type: "link",
  //   icontype: "service",
  //   isCollapsed: false,
  //   isBottom: false,
  // },
  {
    path: "/branch-management",
    title: "Branches",
    type: "link",
    icontype: "branches",
    isCollapsed: false,
    isBottom: true,
    staffOnly: false,
  },
  {
    path: "/team-management",
    title: "Teams",
    type: "link",
    icontype: "teams",
    isCollapsed: false,
    isBottom: true,
    staffOnly: false,
  },
  {
    path: "/apps",
    title: "Apps",
    type: "link",
    icontype: "apps",
    isCollapsed: false,
    isBottom: true,
    staffOnly: false,
  },
  {
    path: "/support",
    title: "Support",
    type: "link",
    icontype: "support",
    isCollapsed: false,
    isBottom: true,
    staffOnly: true,
  },
  {
    path: "/settings",
    title: "Settings",
    type: "link",
    icontype: "setting",
    isCollapsed: false,
    isBottom: true,
    staffOnly: false,
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public menuItems: any[];
  public menuItemsBottom: any[];
  public isCollapsed = true;
  settingFeaturesEnable: any;
  enableAppointmentForStaff: any;
  accountData = this.dataService.accountData$;
  branchData = this.dataService.currentBranch$;
  subscriptions = new Subscription();
  menuLoading: boolean = false;

  constructor(private router: Router, private dataService: DataService, private branchService: BranchManagementService, private appointmentSettingService: AppointmentSettingsService) {}

  getAppointmentSettings() {
    this.appointmentSettingService.getSettings(this.branchData.branchId).subscribe(
      (res) => {
        if(res) {
          this.settingFeaturesEnable = res.data.featuresEnabled.includes('enable-appointment') ? null : 'Appointment';
          this.enableAppointmentForStaff = res.data.featuresEnabled.includes('enable-appointment') ? 'Appointment' : null;
          if(this.branchData){
            if (this.branchData.roleLevel === "staff") {
                this.menuItems = ROUTES.filter((menuItem) => {

                if(this.enableAppointmentForStaff === menuItem.title) {
                      menuItem.staffOnly = true;
                }
                
                 return !menuItem.isBottom && menuItem.staffOnly 
              });
              this.menuItemsBottom = ROUTES.filter((menuItem) => menuItem.isBottom && menuItem.staffOnly);
            } else {
              this.menuItems = ROUTES.filter((menuItem) => !menuItem.isBottom && menuItem.title != this.settingFeaturesEnable);
              this.menuItemsBottom = ROUTES.filter((menuItem) => menuItem.isBottom);
            }
          }
        }
        
        this.menuLoading = false;
      },
      (error) => {
        console.log(error)
        
        this.menuLoading = false;
      }
    )
  }

  ngOnInit() {
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe((res) => {
        if (res) {
          this.menuLoading = true;
          this.branchData = res;
          this.getAppointmentSettings();
        }
      })
    )
    
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
  }
  onMouseEnterSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  }
  minimizeSidebar() {
    const sidenavToggler =
      document.getElementsByClassName("sidenav-toggler")[0];
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("g-sidenav-pinned")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("g-sidenav-pinned");
      body.classList.add("g-sidenav-hidden");
      sidenavToggler.classList.remove("active");
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add("g-sidenav-pinned");
      body.classList.remove("g-sidenav-hidden");
      sidenavToggler.classList.add("active");
      misc.sidebar_mini_active = true;
    }
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
