import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { DataService } from "src/app/core/services/data.service";

@Component({
  selector: "app-queue-main-bottom",
  templateUrl: "./queue-main-bottom.component.html",
  styleUrls: ["./queue-main-bottom.component.scss"],
})
export class QueueMainBottomComponent implements OnInit {
  @Input() selectedQueueStatus: string;
  @Input() queueSettings: string;
  @Input() isServingDisabled: boolean;

  @Input() queue: any;
  @Input() serviceLists: any;
  @Output() emittedAction = new EventEmitter();

  @Input() disableAll: boolean;

  notifying = false;
  counter: number;
  mobileNumber: any;
  isSingleServing = false;

  constructor(
    public dataService: DataService
  ) {}

  viewMore() {}

  emitAction($event) {
    if ($event === "notify") {
      this.notifyQueue();
    } else {
      this.emittedAction.emit($event);
    }
  }

  ngOnInit(): void {
    const mobile = this.queue.fields.find(f => f.fieldName.includes('mobile'));
    if(mobile){
      this.mobileNumber = mobile.value;
    }

    if(this.selectedQueueStatus === 'served'){
      this.isSingleServing = false;
        const s = this.serviceLists.find(
          (x) => x._id === this.queue.serviceId
        );
        if (s && s.queueType === "single-serving") {
          this.isSingleServing = true;
        }
    }
  }

  notifyQueue() {
    this.notifying = true;
    this.startCountdown(5);
    this.emittedAction.emit("notify");
  }

  startCountdown(seconds) {
    this.counter = seconds;
    const interval = setInterval(() => {
      this.counter--;
      if (this.counter <= 0) {
        clearInterval(interval);
        this.notifying = false;
      }
    }, 1000);
  }
}
