import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { EditSpecialScheduleComponent } from '../edit-special-schedule/edit-special-schedule.component';
import { BranchManagementService } from '../../services/branch-management.service';
import { TeamManagementService } from 'src/app/modules/team-management/services/team-management.service';
import { defaultAvatar } from 'src/app/core/services/global';
import * as moment from 'moment';
import { ToastrService } from "ngx-toastr";
import { RemoveSpecialSheduleComponent } from '../remove-special-shedule/remove-special-shedule.component';

@Component({
  selector: 'app-view-special-schedule',
  templateUrl: './view-special-schedule.component.html',
  styleUrls: ['./view-special-schedule.component.scss']
})
export class ViewSpecialScheduleComponent implements OnInit {
  branchId: any;
  scheduleId: any;
  specialSchedule: any;
  accountList: any;
  defaultAvatar: string = defaultAvatar;
  today = new Date();
  specialScheduleStatus = '';
  statusTextColor = '';
  userAccount: any;
  @Output() successEvent = new EventEmitter();
  isLoading = false;

  constructor(
    public modalRef: BsModalRef,
    public modalRef2: BsModalRef,
    private modalService: BsModalService,
    private branchService: BranchManagementService,
    private teamService: TeamManagementService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.branchService.getSpecialScheduleById(this.branchId, this.scheduleId).subscribe((res) => {
      console.log(res);
      this.specialSchedule = res.data;
      this.setStatusValue(this.specialSchedule);
      this.getAccounts(this.specialSchedule.authorId);
      this.isLoading = false;
    });
  }


  getAccount(id){
    this.userAccount = this.accountList?.find(x => x._id === id);
    console.log(this.userAccount);
  }

  getAccounts(authorId) {
    this.teamService.getTeamList("", this.branchId).subscribe((res) => {
      this.accountList = res.data;
      this.getAccount(authorId)
    });
  }

  setStatusValue(data) {
    var startDate = moment(data.startDate).format('L');
    var endDate = moment(data.endDate).format('L');
    var today = moment(this.today).format('L');

    if(moment(today).isSame(startDate) || moment(today).isSame(endDate) || moment(today).isBetween(startDate, endDate)){
      this.specialScheduleStatus = 'Today';
      this.statusTextColor = '#FE7401';
    } else 
    if(moment(today).isAfter(startDate) && moment(today).isAfter(endDate)){
      this.specialScheduleStatus = 'Done';
      this.statusTextColor = '#B80A00';
    } else {
      this.specialScheduleStatus = 'Upcoming';
      this.statusTextColor = '#E5B800';
    }
  }

  removeSchedule(){
    this.modalRef.hide();
    const initialState = {
      displayName: this.specialSchedule.displayName
    };
    this.modalRef2 = this.modalService.show(RemoveSpecialSheduleComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false
    });
    this.modalRef2.content.successEvent.subscribe(
      (data) => {
        console.log(data);
        if(data != 'cancel'){
          this.branchService.removeSpecialSchedule(this.branchId, this.scheduleId).subscribe(
            (res) => {
              this.successEvent.emit(res)
              this.modalRef.hide();
              this.toastr.success("Special schedule has been removed", "Success!");
              this.ngOnInit();
            },
            (error) => {
              console.log(error);
              let errMsg = error.error.errors[0].msg;
              this.toastr.error(errMsg, "Error!");
            }
          );
        } else {
          const initialState = {
            branchId: this.branchId,
            scheduleId: this.scheduleId
          };
          this.modalRef = this.modalService.show(ViewSpecialScheduleComponent, {
            initialState: initialState,
            class: "modal-dialog-centered",
            ignoreBackdropClick: true,
            keyboard: false
          });
        }
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }

  editSchedule(){
    const initialState = {
      branchId: this.branchId,
      data: this.specialSchedule,
      title: 'Edit Special Schedule',
      subTitle: 'Operation hours during a specific date or public holidays'
    };
    this.modalRef2 = this.modalService.show(EditSpecialScheduleComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false
    });
    this.modalRef2.content.successEvent.subscribe(
      (data) => {
        console.log(data);
        this.ngOnInit();
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }

}
