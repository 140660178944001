import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  apiUrl = environment.urlAPI;

  constructor(private http: HttpClient) { }
  
  private get httpHeader(): any {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return {
      headers,
    };
  }

  getBillingDetails(businessId): Observable<any>{
    const url = `${this.apiUrl}/billing-settings/${businessId}?include_features_counts=1&include_customer_payment_method=1`;
    return this.http.get<any>(url, this.httpHeader);
  }

  updateBillingDetails(businessId, data): Observable<any>{
    const url = `${this.apiUrl}/billing-settings/${businessId}`;
    return this.http.patch(url, data).pipe(map((response) => response));
  }

  createCustomerPortal(businessId, data): Observable<any>{
    const url = `${this.apiUrl}/billing/customer-portal/${businessId}`;
    return this.http.post(url, data).pipe(map((response) => response));
  }

  getInvoiceList(businessId): Observable<any>{
    const url = `${this.apiUrl}/billing/invoice/${businessId}/list`;
    return this.http.get<any>(url, this.httpHeader);
  }

  cancelSubscription(businessId): Observable<any>{
    const url = `${this.apiUrl}/billing/subscription/${businessId}/cancel`;
    return this.http.patch(url, {}).pipe(map((response) => response));
  }
}
