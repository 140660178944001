import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { AuthService } from "src/app/core/auth/auth.service";

@Component({
  selector: "app-forgot-password-page",
  templateUrl: "./forgot-password-page.component.html",
  styleUrls: ["./forgot-password-page.component.scss"],
})
export class ForgotPasswordPageComponent implements OnInit {
  email: string;
  passwordForm = this.fb.group({
    email: [
      "",
      [Validators.required, Validators.pattern(/^\S+@\S+\.[a-zA-z]+$/)],
    ],
  });

  isFormSubmitted = false;
  isSubmitting = false;

  get f() {
    return this.passwordForm.controls;
  }

  constructor(private fb: FormBuilder, private authService: AuthService) {}

  ngOnInit(): void {}

  submitForm(form) {
    this.isSubmitting = true;

    this.authService
      .forgotPassword(form.value)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.isSubmitting = false;
          this.isFormSubmitted = true;
        },
        (error) => {
          this.isSubmitting = false;
          console.log("error", error);
        }
      );
  }
}
