import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion-custom',
  templateUrl: './accordion-custom.component.html',
  styleUrls: ['./accordion-custom.component.scss']
})
export class AccordionCustomComponent implements OnInit {
  @Input() accordionData: any;

  constructor() { }

  ngOnInit(): void {
    console.log(this.accordionData)
  }

}
