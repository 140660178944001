import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { INotificationTabList, INotificationBase } from './interfaces';
import { getTimeAgo } from 'src/app/shared/helpers/index';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ViewNotificationComponent } from './components/view-notification/view-notification.component';
import { Store } from '@ngrx/store';
import { setHasNotification, markAllAsRead, open } from './store/actions/notifications';
import { Subscription } from 'rxjs';
import { NotificationsService } from './services/notifications.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { TeamManagementService } from '../team-management/services/team-management.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})

export class NotificationsComponent implements OnInit, OnDestroy {
  @Input() branchId: string;
  @Input() accountId: string;
  isLoading: boolean = false;
  subscriptions = new Subscription();
  tabLists: Array<INotificationTabList> = [
    // { value: "queueing", label: "Queueing" },
    { value: "announcements", label: "Announcements" },
  ];
  activeTab: number = 0;
  announcements: INotificationBase[] = [];
  noticationsLists: INotificationBase[] = [];
  newNotifications: INotificationBase[] = [];
  previousNotifications: INotificationBase[] = [];
  isMarkAllAsRead: boolean = false;

  constructor(
    private notificationService: NotificationsService,
    private teamManagementService: TeamManagementService,
    private bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private store: Store<{check:boolean}>,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.prepareAnnouncements();
  }
  
  prepareAnnouncements() {
    this.subscriptions.add(
      this.teamManagementService
        .getProfileData(this.accountId, this.branchId)
        .pipe(
          map(res => res.data),
          tap(account => {
            if (account && account.announcements && account.announcements.length > 0) {
              this.announcements = account.announcements;
            }
          }),
          switchMap(() => this.notificationService.getAnnouncementsByBranchId('sent')),
          map(res => res.data)
        )
        .subscribe(announcements => {
          if (announcements) {
            this.noticationsLists = announcements.map(announcementData => ({
              _id: announcementData._id,
              type: announcementData.type,
              title: announcementData.title,
              message: announcementData.message,
              sanitizedMessage: this.removeAllHtmlTags(announcementData.message),
              sendAt: announcementData.sendAt,
              createdAt: announcementData.createdAt
            }));

            this.matchAnnouncements(this.announcements);
          }
        })
    );
  }

  removeAllHtmlTags(inputString) {
    return inputString.replace(/<[^>]*>([^<]*)<\/[^>]*>/g, '$1');
  }

  matchAnnouncements(announcements: INotificationBase[]) {
    if (announcements && announcements.length > 0) {
      announcements.forEach(announcement => {
        const matchAnnouncement = this.noticationsLists.find(item => item._id === announcement.announcementId);
        
        if (matchAnnouncement) {
          if (!announcement.hasRead) {
            this.newNotifications.push(matchAnnouncement);
          } else {
            this.previousNotifications.push(matchAnnouncement);
          }
        }
      });

      function removeDuplicatesAndSort(notifications: INotificationBase[]) {
        const uniqueNotifications = Array.from(new Set(notifications.map(item => item._id)))
          .map(uniqueId => notifications.find(item => item._id === uniqueId));
      
        return uniqueNotifications.sort((a, b) => b.createdAt - a.createdAt);
      }

      this.newNotifications = removeDuplicatesAndSort(this.newNotifications);
      this.previousNotifications = removeDuplicatesAndSort(this.previousNotifications);

      if (this.newNotifications?.length === 0) {
        this.store.dispatch(setHasNotification({hasNotification: false}));
      }
    } else {
      this.noticationsLists.length = 0;
    }

    this.isLoading = false;
  }

  chooseTab(index: number) {
    this.activeTab = index;
  }

  getTimeAgo(milliseconds): string {
    return getTimeAgo(milliseconds);
  }

  viewNotification(notification: INotificationBase, hasRead: boolean) {
    if (!hasRead) {
      const index = this.newNotifications.findIndex(x => x._id === notification._id)
      if (index > -1) {
        this.previousNotifications.unshift(this.newNotifications[index]);
        this.newNotifications.splice(index, 1);
      }
    }

    const initialState = {
      data: notification,
      hasRead: hasRead,
    }
    this.bsModalRef = this.bsModalService.show(ViewNotificationComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false
    })
  }

  markAllAsRead() {
    this.store.dispatch(markAllAsRead({isMarkAllAsRead: true}))
    this.newNotifications.sort((a, b) => a.createdAt - b.createdAt);
    this.newNotifications.forEach(notification => {
      this.previousNotifications.unshift(notification);
    });
    
    const allAnnouncementIds = this.newNotifications.map((notification => notification._id));

    const payload = {
      announcementIds: allAnnouncementIds
    }

    this.newNotifications = [];

    this.subscriptions.add(
      this.notificationService
      .updateAnnouncementHasRead(payload)
      .subscribe(
        (res) => {
          if (res) {
            this.store.dispatch(setHasNotification({hasNotification: false}))
            this.toastr.success('All announcements have been marked as read.', 'Success!');
          }
        },
        (error) => {
          console.log(error);
          this.toastr.error('Error on marking all announcemnent as read', 'Error!');
        }
      )
    );
  }

  closeNotifications() {
    this.store.dispatch(open({isOpen: false}));
    this.store.dispatch(markAllAsRead({isMarkAllAsRead: false}));
    if (this.newNotifications && this.newNotifications.length > 0) {
      this.store.dispatch(setHasNotification({hasNotification: true}))
    }
  }

  ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
  }
}
