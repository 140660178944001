import { DatePipe } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DataService } from "src/app/core/services/data.service";
import { environment } from "src/environments/environment";
import { FreeTrialExpiredComponent } from "../../billing/components/free-trial-expired/free-trial-expired.component";

@Injectable({
  providedIn: "root",
})
export class BusinessService {
  apiUrl = environment.urlAPI;
  storedSubscription = this.dataService.getSubscriptionPlan();

  constructor(
    private http: HttpClient,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    public datePipe: DatePipe,
    public dataService: DataService
  ) {
    this.storedSubscription = this.dataService.getSubscriptionPlan();
  }

  private get httpHeader(): any {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return {
      headers,
    };
  }

  public getIndustries(): Observable<any> {
    const url = `${this.apiUrl}/industries`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getIndustryTypes(industryId: string): Observable<any> {
    const url = `${this.apiUrl}/business/industry-types/${industryId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public createBusiness(data): Observable<any> {
    const url = `${this.apiUrl}/business`;
    return this.http.post(url, data).pipe(map((response) => response));
  }

  public checkIfIsExpired(){

  }
  public checkSubscriptionExpiry(roleLevel, islogin, subscriptionPlan?, businessId?) {
    let expired = false;
    const subscribed = subscriptionPlan.plan?.toLowerCase() !== "trial";
    const daysRemaining = this.getDaysRemaining(
      subscriptionPlan.startDate,
      subscriptionPlan.endDate,
      subscriptionPlan.cancelled,
    );

    // console.log(subscriptionPlan);

    const daysLabel =
      daysRemaining.daysConsumed < 1
        ? `${daysRemaining.consumedHours} hour/s`
        : `${daysRemaining.daysConsumed} day/s`;

    if (
      daysRemaining.daysConsumed <= 3 &&
      daysRemaining.daysConsumed > 0 &&
      islogin && (subscriptionPlan.cancelled || !subscribed)
    ) {
      this.modalRef.hide();
      setTimeout(() => {
        this.showExpiredModal(
          "remaining",
          subscribed,
          daysLabel,
          new Date(subscriptionPlan.endDate),
          roleLevel,
          subscriptionPlan.cancelled,
          businessId,
          subscriptionPlan.plan,
        );
      });

    } else if (daysRemaining.consumedHours <= 0) {
      this.modalRef.hide();
      setTimeout(() => {
        this.showExpiredModal(
          "expired",
          subscribed,
          daysLabel,
          new Date(subscriptionPlan.endDate),
          roleLevel,
          subscriptionPlan.cancelled,
          businessId,
          subscriptionPlan.plan,
        );
      });

      expired = true;
    }

    return expired;
  }

  public showExpiredModal(
    type,
    subscribed,
    daysRemaining?,
    endDate?,
    roleLevel?,
    cancelled?,
    businessId?,
    plan?
  ) {
    let title = "";
    let subTitle1 = "";
    let subTitle2 = "";
    let subTitle3 = "Please contact your manager for more information.";

    if (subscribed) {
      if (type === "remaining") {
        title = `Your subscription is about to expire in ${daysRemaining}!`;
        subTitle1 = `The subscription period will expire on <b>${this.datePipe.transform(
          endDate,
          "MMMM dd, yyyy"
        )}.</b>`;
        subTitle2 = `Reactivate your subscription to continue giving the best customer experience!`;
      } else {
        title = `Oops! Your subscription has expired`;
        subTitle1 = `The subscription ended on <b>${this.datePipe.transform(
          endDate,
          "MMMM dd, yyyy"
        )}.</b>`;
        subTitle2 = `Reactivate your subscription to continue giving the best customer experience!`;
      }
    } else {
      if (type === "remaining") {
        title = `Your free trial is about to expire in ${daysRemaining}!`;
        subTitle1 = `The free trial period will expire on <b>${this.datePipe.transform(
          endDate,
          "MMMM dd, yyyy"
        )}.</b>`;
        subTitle2 = `Upgrade your plan to get the best out of Kyoo’s queueing services.`;
      } else {
        title = `Oops! Your free trial has expired`;
        subTitle1 = `You have now reached you 10-day free trial period.`;
        subTitle2 = `Continue giving the best customer experience by upgrading your plan!`;
      }
    }

    const initialState = {
      title,
      subTitle1,
      subTitle2,
      type,
      daysRemaining,
      trial: !subscribed,
      roleLevel,
      cancelled,
      businessId,
      plan
    };
    this.modalRef = this.modalService.show(FreeTrialExpiredComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  public getDaysRemaining(start, end, cancelled) {
    const startSub = new Date(start);
    const endSub = new Date(end);

    const addAllowanceTime = cancelled ? 0 : 5;

    const subscriptionMinutes = moment(endSub).diff(startSub, "minutes");

    const dateNow = new Date();
    const startDate = startSub;

    const timeConsumedMinutes = moment(dateNow).diff(startDate, "minutes");

    const timeConsumed = (subscriptionMinutes + addAllowanceTime) - timeConsumedMinutes;

    const consumedHours = Math.ceil(timeConsumed/60)
    
    const daysConsumed = consumedHours / 24;

    return { daysConsumed, consumedHours };
  }
}
