import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/core/http/http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceWindowService {
  apiUrl = environment.urlAPI;

  constructor(
    private http: HttpClient,
    public httpService: HttpService,
    public toastr: ToastrService
  ) { }

  private get httpHeader(): any {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return {
      headers,
    };
  }

  addDeleteFeature(dataArray, feature, value) {
    let featuresArray = [];
    featuresArray = dataArray.filter(element => element !== feature);
    if (value) {
      featuresArray.push(feature);
    }
    return featuresArray;
  }

  submitPatchForm(param, bodyForm) {
    return this.httpService.patch$(param, bodyForm).toPromise();
  }



  // Services
  //for soft deletion of service
  public deleteService(branchId: string, serviceId: string): Observable<any> {
    const url = `${this.apiUrl}/services/${branchId}/${serviceId}`;
    return this.http.delete(url).pipe(map((response) => response));
  }

  //window 
  //for soft deletion of window
  public deleteWindow(branchId: string, windowId: string): Observable<any> {
    const url = `${this.apiUrl}/services/windows/${branchId}/${windowId}`
    return this.http.delete(url).pipe(map((response) => response));
  }

}
