import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomReportsResponse } from '../interfaces/custom-reports';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  apiUrl = environment.urlAPI;

  private get httpHeader(): any {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return {
      headers,
    };
  }

  constructor(private http: HttpClient) { }

  public getQueueCounts(params, branchId): Observable<any> {
    const url = `${this.apiUrl}/queues/${branchId}/reports/counts?${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getAvgWaitingTime(params, branchId): Observable<any> {
    const url = `${this.apiUrl}/queues/${branchId}/reports/avg-waiting-time?${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getAvgServingTime(params, branchId): Observable<any> {
    const url = `${this.apiUrl}/queues/${branchId}/reports/avg-serving-time?${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getCustomerArrival(params, branchId): Observable<any> {
    const url = `${this.apiUrl}/queues/${branchId}/reports/customer-arrival?${params}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getCustomReportContents(branchId: string): Observable<any> {
    const url = `${this.apiUrl}/custom-reports/${branchId}/contents`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public getCustomReportsLists(branchId: string): Observable<any> {
    const url = `${this.apiUrl}/custom-reports/${branchId}/list`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public customReports(action: string, branchId: string, data?: any, reportId?: string): Observable<CustomReportsResponse> {
    let url: string = `${this.apiUrl}/custom-reports/${branchId}`;

    const options = {
      headers: this.httpHeader,
      responseType: 'json' as const
    };

    switch (action) {
      case 'POST':
        return this.http.post<CustomReportsResponse>(url, data, options);
      case 'PATCH':
        url += `/${reportId}`;
        return this.http.patch<CustomReportsResponse>(url, data, options);
      case 'DELETE':
        url += `/${reportId}`;
        return this.http.delete<CustomReportsResponse>(url, options);
      default: // for GET
      url += `/list`;
        return this.http.get<CustomReportsResponse>(url, options);
    }
  }

  public addCustomReport(branchId: string, data: any): Observable<any> {
    const url = `${this.apiUrl}/custom-reports/${branchId}`;
    return this.http.post(url, data).pipe(map((response) => response));
  }

  public getCustomReportDetails(branchId: string, reportId: string): Observable<any> {
    const url = `${this.apiUrl}/custom-reports/${branchId}/${reportId}`;
    return this.http.get<any>(url, this.httpHeader);
  }

  public updateCustomReport(branchId: string, reportId: string, data: any): Observable<any> {
    const url = `${this.apiUrl}/custom-reports/${branchId}/${reportId}`;
    return this.http.patch(url, data).pipe(map((response) => response));
  }

  public deleteCustomReport(branchId: string, reportId: string): Observable<any> {
    const url = `${this.apiUrl}/custom-reports/${branchId}/${reportId}`;
    return this.http.delete(url).pipe(map((response) => response));
  }
}
