import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { INotificationBase } from '../../interfaces';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationsService } from '../../services/notifications.service';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { setHasNotification } from '../../store/actions/notifications';

@Component({
  selector: 'app-view-notification',
  templateUrl: './view-notification.component.html',
  styleUrls: ['./view-notification.component.scss']
})
export class ViewNotificationComponent implements OnInit {
  title: string = 'View Notification';
  data: INotificationBase;
  hasRead: boolean = false;
  isFirstLoggedIn: boolean = false;

  constructor(
    private bsModalRef: BsModalRef,
    private notificationService: NotificationsService,
    private store: Store<{check:boolean}>,
  ) { }

  ngOnInit(): void {

  }

  closeModal() {
    this.bsModalRef.hide();
    if (!this.hasRead) {
      const payload = {
        announcementIds: [this.data._id]
      }
      
      this.notificationService
        .updateAnnouncementHasRead(payload)
        .pipe(
          map(res => res.data),
          tap(data => {
            if (data && data.announcements && data.announcements.length > 0) {
              const announcementData = data.announcements;
              const unreadAnnouncements = announcementData.filter(announcement => !announcement.hasRead);

              if (unreadAnnouncements && unreadAnnouncements.length === 0) {
                this.store.dispatch(setHasNotification({hasNotification: false}))
              }
            }
          })
        )
        .subscribe()
    }
  }

}
