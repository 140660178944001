import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { TitleCasePipe } from '@angular/common';
import { ServiceCategoryService } from '../../services/service-category.service';

@Component({
  selector: 'app-edit-service-category-name',
  templateUrl: './edit-service-category-name.component.html',
  styleUrls: ['./edit-service-category-name.component.scss'],
  providers: [TitleCasePipe]
})
export class EditServiceCategoryNameComponent implements OnInit {
  branchId: any;
  title: any;
  categoryId: any;
  labelName = "Category Name";
  placeholder = "Enter Category Name";
  categoryName = "";
  errMsg = "Please enter category name";
  cancelText = "Cancel";
  confirmText = "Save Changes";
  isChanged = false;
  isTouched = false;
  isSubmitting = false;
  @Output() successEvent = new EventEmitter;

  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    private toastr: ToastrService,
    private titleCasePipe: TitleCasePipe,
    private categoryService: ServiceCategoryService
  ) { }

  ngOnInit(): void {
    this.getCategoryName();
  }

  getCategoryName() {
    this.categoryService.getServiceCategoryById(this.branchId, this.categoryId).subscribe((res) => {
      this.categoryName = res.categoryName;
    })
  }

  changeName(e) {
    if(this.categoryName != e.trim){
      this.isChanged = true;
    } else {
      this.isChanged = false;
    }
  }

  editCategory() {
    this.isSubmitting = true;
    const name = this.titleCasePipe.transform(this.categoryName);
    if(this.categoryName != name){
      this.isChanged = true;
    }
    if(this.isChanged){
      this.modalRef.hide();
      this.successEvent.emit(name.trim());
    }
  }

  cancel() {
    this.modalRef.hide();
    this.successEvent.emit('cancel');
  }

  touchTextField() {
    this.isTouched = true;
  }
}
