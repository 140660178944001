
import { Component, EventEmitter, HostListener, OnInit, Output, OnDestroy } from '@angular/core'
import { HttpParams } from '@angular/common/http'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { HttpService } from 'src/app/core/http/http.service'
import { DatePickerModalComponent } from 'src/app/shared/components/date-picker-modal/date-picker-modal.component'
import * as ExcelJS from "exceljs/dist/exceljs.min.js"
import * as fs from "file-saver"
import { ToastrService } from "ngx-toastr"
import { TitleCasePipe, DatePipe } from '@angular/common'
import { Subscription } from 'rxjs'
import { DataService } from 'src/app/core/services/data.service'
import { TeamManagementService } from 'src/app/modules/team-management/services/team-management.service'
import { QueueHistoryService } from 'src/app/modules/queue-history/services/queue-history.service'
import { TimePipe } from 'src/app/shared/pipes/time.pipe'
import * as moment from 'moment'


@Component({
  selector: 'app-custom-report-filter-modal',
  templateUrl: './custom-report-filter-modal.component.html',
  styleUrls: ['./custom-report-filter-modal.component.scss'],
  providers: [TitleCasePipe, DatePipe]
})
export class CustomReportFilterModalComponent implements OnInit, OnDestroy {
  accountData = this.dataService.accountData$
  isDownloading: boolean = false
  reportId: string = ''
  title: string
  subtitle: string
  description: string
  selectedDateFilter = "today"
  selectedDateFilterLabel = "Today"
  customDate1: any
  customDate2: any
  @Output() generate = new EventEmitter()
  subscriptions = new Subscription()
  isFocused = false
  isSelectClicked: boolean
  isButtonClicked: boolean
  selectedValue = "today"
  selectedLabel = "Today"
  selectedWindows: any
  selectedStatus: any
  branchId: string
  reportType: string
  accountList: Array<any> = []
  serviceWindowLists: any
  filterType: string = ''
  sourceFilter: string[] = []
  clear: boolean = false
  customErrMsg: string = ''
  columnLabels: string[] = []
  columnValues: any[] = []
  newReportData: any[] = []
  branchSite: string = 'branch-site'
  kiosk: string = 'kiosk'
  visitorNameLabel: string = 'Visitor (Self-service)'
  dateFilter = [
    {
      value: "today",
      label: "Today",
    },
    {
      value: "yesterday",
      label: "Yesterday",
    },
    {
      value: "this-week",
      label: "This Week",
    },
    {
      value: "this-month",
      label: "This Month",
    },
    // {
    //   value: "this-year",
    //   label: "This Year",
    // },
    {
      value: "custom",
      label: "Custom",
    },
  ]
  statusFilter = [
    {
      value: "completed",
      label: "Completed",
    },
    {
      value: "cancelled",
      label: "Cancelled",
    },
    {
      value: "removed",
      label: "Removed",
    }
    // {
    //   value: "auto-completed",
    //   label: "Auto-Completed",
    // },
    // {
    //   value: "auto-removed",
    //   label: "Auto-Removed",
    // }
  ]

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRef2: BsModalRef,
    private modalService: BsModalService,
    private httpService: HttpService,
    private titleCasePipe: TitleCasePipe,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private dataService: DataService,
    private teamService: TeamManagementService,
    private queueHistoryService: QueueHistoryService
  ) { }

  ngOnInit(): void {
    this.getAccounts();
    if (this.filterType === 'service') {
      this.getServiceLists()
    } else {
      this.getWindowList()
    }
  }

  getWindowList() {
    this.subscriptions.add(
      this.httpService
        .get$(`services/windows/${this.branchId}?limit=999`)
        .subscribe((data) => {
          this.serviceWindowLists = data.data.map((element) => {
            return {
              label: element.displayName,
              value: element._id,
            }
          })
        }, (error) => {
          console.log('error on fetching window lists', error)
        })
    )
  }

  getServiceLists() {
    this.subscriptions.add(
      this.httpService
        .get$(`services/${this.branchId}?limit=999`)
        .subscribe((data) => {
          this.serviceWindowLists = data.data.map((element) => {
            return {
              label: element.displayName,
              value: element._id
            }
          })
        }, (error) => {
          console.log('error on fetching service lists', error)
        })
    )
  }

  clearFilter() {
    this.clear = !this.clear;
    this.select();
    this.deselect();
    this.selectDropdown({ value: "today", label: "Today" })
    this.customErrMsg = '';
  }

  generateReport() {
    this.isDownloading = true
    let params = new HttpParams()
    params = params.append("filterBy", this.selectedValue)

    if (this.selectedValue === "custom") {
      params = params.append("startDate", new Date(this.customDate1).getTime().toString())
      if (this.customDate1 == this.customDate2) {
        let endDate = new Date(this.customDate2).setHours(23, 59, 59);
        params = params.append("endDate", new Date(endDate).getTime().toString())
      } else {
        params = params.append("endDate", new Date(this.customDate2).getTime().toString())
      }
    }
    if (this.selectedWindows && this.selectedWindows.length > 0) {
      let windowIds = []
      this.selectedWindows.forEach(x => {
        windowIds.push(x.value)
      })
      params = params.append(`${this.filterType}sId`, windowIds.join(','))
    } else {
      params = params.append(`${this.filterType}sId`, 'show-all')
    }

    if (this.selectedStatus && this.selectedStatus.length > 0) {
      let statuses = []
      this.selectedStatus.forEach(x => {
        statuses.push(x.value)
      })
      params = params.append('statuses', statuses.join(','))
    } else {
      params = params.append('statuses', 'show-all')
    }

    if (this.sourceFilter && (this.sourceFilter.length > 0 && this.sourceFilter.length != 3)) {
      let sources = []
      this.sourceFilter.forEach(x => {
        sources.push(x)
      })
      params = params.append('sources', sources.join(','))
    } else if (this.sourceFilter.length == 3) {
      params = params.append('sources', 'show-all')
    } else {
      params = params.append('sources', 'show-all')
    }

    this.getCustomReportData(params)
    // this.generate.emit(params)
    // this.bsModalRef.hide()
  }

  datePeriod: any
  getDatePeriod(data) {
    const curr = new Date() // get current date
    const first = curr.getDate() - curr.getDay() // First day is the day of the month - the day of the week
    const firstday = new Date(curr.setDate(first)).toUTCString()

    let date: any

    switch (data.filterBy) {
      case "today":
        this.datePeriod = this.datePipe.transform(new Date(), "MMMM dd, yyyy")
        break
      case "yesterday":
        date = new Date().setDate(new Date().getDate() - 1)
        this.datePeriod = this.datePipe.transform(date, "MMMM dd, yyyy")
        break
      case "this-week":
        date = new Date(firstday).setDate(new Date(firstday).getDate())
        const date2 = new Date(firstday).setDate(
          new Date(firstday).getDate() + 6
        )
        this.datePeriod =
          this.datePipe.transform(date, "MMMM dd, yyyy") +
          " - " +
          this.datePipe.transform(date2, "MMMM dd, yyyy")
        break
      case "this-month":
        this.datePeriod = this.datePipe.transform(curr, "MMMM yyyy")
        break
      case "this-year":
        this.datePeriod = this.datePipe.transform(curr, "yyyy")
        break
      case "custom":
        const start = new Date(parseInt(data.startDate))
        const end = new Date(parseInt(data.endDate))
        this.datePeriod =
          this.datePipe.transform(start, "MMMM dd, yyyy") +
          " - " +
          this.datePipe.transform(end, "MMMM dd, yyyy")
        break
    }
  }

  getCustomReportData(params: HttpParams) {
    const paramsObject = params.keys().reduce((object, key) => {
      object[key] = params.get(key);
      return object;
    }, {});
    this.getDatePeriod(paramsObject);

    this.subscriptions.add(
      this.queueHistoryService
        .getCustomReportsQueueHistoryDownload(params, this.branchId, this.reportId)
        .subscribe(async res => {
          if (res && res.data) {
            this.newReportData = []
            const reportData = await this.removeProperties(res.data)
            if (reportData && reportData.data) {

              this.newReportData = Array(reportData.data.length).fill({}).map(() => [...this.columnValues])

              const { authorId, completedBy, completedAt, removedBy, removedAt } = {
                authorId: 'authorId',
                completedBy: 'completedBy',
                completedAt: 'completedAt',
                removedBy: 'removedBy',
                removedAt: 'removedAt',
              };

              this.newReportData = this.newReportData.map((object, index) => {
                const status = reportData.data[index].status;

                const updatedObject = this.columnValues.map((column) => {
                  const propName = column.value;
                  const dataValue = reportData.data[index][propName];
                  return { value: propName, data: dataValue };
                });

                const authorIdObject = updatedObject.find((item) => item.value === authorId);

                if (authorIdObject) {
                  if (reportData.data[index].source == this.kiosk || reportData.data[index].source == this.branchSite) {
                    authorIdObject.data = this.visitorNameLabel;
                  }
                }

                const removedByObject = updatedObject.find((item) => item.value === removedBy);
                const removedAtObject = updatedObject.find((item) => item.value === removedAt);
                const completedByObject = updatedObject.find((item) => item.value === completedBy);
                const completedAtObject = updatedObject.find((item) => item.value === completedAt);

                if (status === 'removed' || status === 'auto-removed') {
                  if (removedByObject) {
                    removedByObject.data = reportData.data[index][completedBy];
                  }

                  if (removedAtObject) {
                    removedAtObject.data = reportData.data[index][completedAt];
                  }

                  if (completedByObject) {
                    completedByObject.data = undefined;
                  }

                  if (completedAtObject) {
                    completedAtObject.data = undefined;
                  }
                } else if (status === 'completed' || status === 'auto-completed') {
                  if (completedByObject) {
                    completedByObject.data = reportData.data[index][completedBy];
                  }

                  if (completedAtObject) {
                    completedAtObject.data = reportData.data[index][completedAt];
                  }
                } else if (status === 'cancelled') {
                  if (completedByObject) {
                    completedByObject.data = undefined;
                  }

                  if (completedAtObject) {
                    completedAtObject.data = undefined;
                  }

                  if (removedByObject) {
                    if (reportData.data[index].source == this.branchSite || reportData.data[index].source == this.kiosk) {
                      removedByObject.data = this.visitorNameLabel;
                    }
                  }

                  if (removedAtObject) {
                    removedAtObject.data = reportData.data[index][completedAt];
                  }
                }

                return updatedObject;
              });

              this.downloadCustomReport(this.newReportData)
            }
          }
        }, (error) => {
          console.log(error)
          this.toastr.error('Error on fetching download report data', 'Info!')
          this.isDownloading = false
        })
    )
  }

  async removeProperties(obj: any) {
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        this.removeProperties(obj[key]); // Recursively call the function for nested objects
      } else if (key === 'procedureQueueHistoryId' || key === 'procedureQueueId') {
        delete obj[key]; // Delete the properties
      }
    }
    return obj
  }

  downloadCustomReport(reportData: any[]) {
    const title = this.titleCasePipe.transform(this.title);
    // PAL REQUEST
    let labels = []
    for (let label of this.columnLabels) {
      if (label === 'Tags') {
        labels.push('Transactions')
      } else {
        labels.push(label);
      }
    }
    const header = labels;
    // Create workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Custom Report - " + title);
    // Add Row and formatting
    const titleRow = worksheet.addRow(["CUSTOM REPORT - " + title]);
    titleRow.font = { name: "Arial", family: 4, size: 14 };
    if (this.description && this.description != '') {
      worksheet.addRow([this.description]);
    }
    worksheet.addRow([
      "Date & Time Generated: " +
      this.datePipe.transform(new Date(), "EEEE, MMMM dd, yyyy hh:mm a"),
    ]);
    worksheet.addRow([
      `Generated By: ${this.accountData.data.firstName} ${this.accountData.data.lastName}`,
    ]);
    worksheet.addRow(["Period Covered: " + this.datePeriod]);
    // Blank Row
    worksheet.addRow([]);
    // Add Header Row
    const headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.font = { name: "Arial", bold: true };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });
    // Center align all columns except the first column
    worksheet.columns.forEach((column, index) => {
      if (index > 0) {
        column.alignment = { horizontal: "center" };
      }
    });
    // Set cell values for each object in the array
    reportData?.forEach((data: any) => {
      const rowData = [];
      data?.forEach((element: any) => {
        let cellData = element.data;
        if (element.value === 'waitingTime') {
          const idx = data.findIndex(obj => obj?.value === 'createdAt');
          cellData = this.formatData(element.value, element.data, (idx > -1 && (data[idx]?.data) ? data[idx]?.data : 0));
        } else {
          cellData = this.formatData(element.value, element.data);
        }
        rowData.push(cellData);
      });
      worksheet.addRow(rowData);
    });
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      fs.saveAs(blob, `${title}_${this.datePipe.transform(new Date(), "MMddyy")}.xlsx`);
    });
    this.bsModalRef.hide()
    // this.isDownloading = false;
  }

  formatData(content: string, data: any, createdAt?: number) {
    let formattedData = undefined
    if (content == 'name' || content == 'visitors' || content == 'queueNo' ||
      content == 'serviceName' || content == 'windowName' || content == 'procedureQueueName') {
      formattedData = (data && typeof data == 'string') ? data : (data ? data : '')
    } else if (content == 'mobileNo') {
      formattedData = data ? '+63' + data : ''
    } else if (content == 'priority') {
      formattedData = (data == 'true' || data == true) ? 'YES' : 'NO'
    } else if (content == 'authorId' || content == 'servedBy' || content == 'completedBy' || content == 'removedBy') {
      formattedData = (data && data != this.visitorNameLabel) ? this.getAccountName(data) : data
    } else if (content == 'createdAt' || content == 'bookedAt' || content == 'servedAt' || content == 'completedAt' || content == 'removedAt') {
      formattedData = (data && data > 0) ? moment(new Date(data)).format("MM-DD-yyyy - HH:mm") : ''
    } else if (content == 'waitingTime') {
      formattedData = (data && data > 0) ? this.millisecondsToTime(data) : ((createdAt != 0 && createdAt > 0) ? this.getTimeAgo2(createdAt) : '')
    } else if (content == 'servingTime') {
      formattedData = (data && data > 0) ? this.millisecondsToTime(data) : ''
    } else if (content == 'source') {
      if (data && data != '') {
        if (data == 'business-portal') {
          formattedData = 'Business Portal (Manual)'
        } else if (data == this.kiosk) {
          formattedData = 'Kiosk'
        } else if (data == this.branchSite) {
          formattedData = 'Branch Site (Online)'
        }
      }
    } else if (content == 'tags' || content === 'transactions') {
      formattedData = (data && data.length > 0) ? data.join(', ') : ''
    } else {
      formattedData = data || '';
    }
    return formattedData
  }

  getAccounts() {
    this.teamService.getTeamList("", this.branchId).subscribe((res) => {
      this.accountList = res.data;
    }, (error) => {
      console.log('error on fetching account lists', error)
    });
  }

  getAccountName(accountId: string) {
    let name = "";
    if (accountId && this.accountList) {
      const account = this.accountList.find((x) => x._id === accountId);
      if (account) {
        name = account.firstName + " " + account.lastName;
      }
    }

    return name != '' ? this.titleCasePipe.transform(name) : '';
  }

  millisecondsToTime(milliseconds: number) {
    let seconds = Math.floor((milliseconds / 1000) % 60);
    let minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    let hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
    let days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));

    let time = '';

    if (days == 1) { time += days + ' day '; }
    if (days > 1) { time += days + ' days '; }
    if (hours == 1) { time += hours + ' hour '; }
    if (hours > 1) { time += hours + ' hours '; }
    if (minutes == 1) { time += minutes + ' minute '; }
    if (minutes > 1) { time += minutes + ' minutes '; }
    if (seconds == 1) { time += seconds + ' second '; }
    if (seconds > 1) { time += seconds + ' seconds '; }
    if (time === '') { time = ''; }

    return time;
  }

  getTimeAgo(data: number) {
    if (data) {
      const timePipe = new TimePipe();
      return timePipe.transform(data);
    }
  }

  getTimeAgo2(data: number) {
    if (data) {
      const y = new Date(data).getFullYear();
      const m = new Date(data).getMonth();
      const d = new Date(data).getDate();
      const h = new Date(data).getHours();
      const mm = new Date(data).getMinutes();
      const s = new Date(data).getSeconds();
      const time = moment([y, m, d, h, mm, s]).fromNow(true);

      return time === 'a day' ? '1 day' : time;
    }
  }

  selectFilter(ev) {
    this.selectedWindows = ev
  }

  selectStatus(ev) {
    this.selectedStatus = ev
  }

  selectCustomDateFilter() {
    const initialState = {
      hasMinDate: false,
      datePickerType: 'range',
      customReport: true
    }
    this.bsModalRef2 = this.modalService.show(DatePickerModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md2",
      ignoreBackdropClick: true,
      keyboard: false
    })

    this.bsModalRef2.content.selectedDateRange.subscribe(
      (data) => {
        if (data) {
          if (data == 'cancel') {
            this.customErrMsg = ''
          } else {
            if (data[0] && data[1]) {
              this.selectedValue = 'custom'
              this.customDate1 = (data[0] as Date).toLocaleDateString("en-US")
              this.customDate2 = (data[1] as Date).toLocaleDateString("en-US")
              this.selectedLabel = this.customDate1 + " - " + this.customDate2
              this.selectedDateFilterLabel = this.customDate1 + " - " + this.customDate2

              const startDate = new Date(this.customDate1)
              const endDate = new Date(this.customDate2)

              const threeMonthsLater = new Date(startDate.getTime()) // Create a new date object with the same time as the start date
              threeMonthsLater.setMonth(startDate.getMonth() + 3) // Add three months to the new date object

              if (endDate > threeMonthsLater) {
                // Do something if the selected dates are invalid
                this.customErrMsg = 'Selected dates must not be more than 3 months'
              } else {
                // Do something if the selected dates are valid
                this.customErrMsg = ''
              }
            } else {
              this.customErrMsg = ''
            }
          }
        }
      },
      (err) => {
        return false
      }
    )
  }

  select() {
    this.isSelectClicked = !this.isSelectClicked
    this.isFocused = !this.isFocused
  }

  @HostListener("document:click")
  deselect() {
    if (!this.isSelectClicked || this.isButtonClicked) {
      this.isFocused = false
    }
    this.isButtonClicked = false
    this.isSelectClicked = false
  }

  selectDropdown(list) {
    if (list.value === "custom") {
      this.selectCustomDateFilter()
    } else {
      this.selectedValue = list.value
      this.selectedLabel = list.label
    }
    this.isButtonClicked = true
    if (!this.isFocused) {
      this.isButtonClicked = false
    }
  }

  cancel() {
    this.generate.emit(
      { action: 'cancel', selected: this.filterType }
    )
    this.bsModalRef.hide()
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
