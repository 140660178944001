import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { EditProceduresModalComponent } from "../edit-procedures-modal/edit-procedures-modal.component";
import { DataService } from "src/app/core/services/data.service";

@Component({
  selector: "app-select-next-procedure-modal",
  templateUrl: "./select-next-procedure-modal.component.html",
  styleUrls: ["./select-next-procedure-modal.component.scss"],
})
export class SelectNextProcedureModalComponent implements OnInit {
  @Output() successEvent = new EventEmitter();
  @Input() branchId: any;
  @Input() queue: any;
  @Input() procedureTemplate: any;
  @Input() allServiceLists: any;
  @Input() assignAnother: boolean;

  @Input() procedures: any;
  @Input() procedureQueue: any;
  nextRecommended: any;

  dropdownList: any;
  procedureServiceList: any;
  radioForm = this.fb.group({
    inputRadio: [""],
  });

  hideRadioButtons: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    private dataService: DataService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.setProcedureList();

    // for TMC and PAL
    if (this.dataService.accountData$ && ([
      "a597d9a2-c2dd-40e7-b05f-47114be30f12", //??
      "97295305-232c-4753-9ef2-811653c564c9", // local
      "0bb706ca-6f00-42a1-af36-431770829e90", //dev TMC
      "a94bf1a5-5677-425e-87ba-a0ae9078ae22", //staging TMC
      "e42367dc-f0b0-4e6e-8a4b-819cf9d50262", //demo TMC
      "820a404e-cc6c-42b2-ae15-634a136850fa", //demo PAL
    ].includes(this.dataService.accountData$.data.businessId))) {
      if (this.queue) {
        // if (this.procedureQueue && this.procedureQueue.state === "on-progress") {
        if (this.procedureQueue) {
          // if queue has an assigned procedure
          if (this.procedureQueue.procedureType === "flexible") {
            // proceed with recommended service if flexible
            setTimeout(() => {
              this.proceed();
            });
          }
        }
        // for TMC
        // else {
        //   this.editProcedure();
        // }
      }
    }
  }

  setProcedureList() {
    if (!this.procedureQueue) {
      this.procedureQueue = this.queue.procedureQueue;
    }

    if (this.procedureQueue.procedureType === 'step-by-step') {
      this.hideRadioButtons = true;
    }

    if (!this.procedures) {
      this.procedures = this.queue.procedureQueue.procedures;
    }
    this.procedureServiceList = [];

    this.procedures.forEach((p) => {
      const service = this.allServiceLists.find((x) => x._id === p.serviceId);
      if (service) {
        if (this.assignAnother) {
          this.procedureServiceList.push({
            ...p,
            _id: p.serviceId,
            displayName: service.displayName,
            counts: service.counts,
            disabled:
              p.serviceId === this.queue.serviceId,
          });
        } else {
          this.procedureServiceList.push({
            ...p,
            displayName: service.displayName,
            counts: service.counts,
            disabled:
              (p.state !== "pending" || p.serviceId === this.queue.serviceId),
          });
        }
      }
    });

    // if(!this.assignAnother){
    //   this.nextRecommended = this.procedureServiceList.find((x) => !x.disabled);
    //   if (this.nextRecommended) {
    //     this.selectProcedure(this.nextRecommended._id);
    //   }
    // }

    if (this.procedureQueue.procedureType === "flexible") {
      const availableServices = this.procedureServiceList.filter((x) => !x.disabled);
      this.nextRecommended = availableServices.reduce((a, b) => {
        return (b.counts.pending < a.counts.pending) ? b : a;
      })

      if (this.nextRecommended) {
        this.selectProcedure(this.nextRecommended._id);
      }
    } else {
      this.nextRecommended = this.procedureServiceList.find((x) => !x.disabled);
      if (this.nextRecommended) {
        this.selectProcedure(this.nextRecommended._id);
      }
    }
  }

  selectProcedure(id) {
    this.radioForm.controls.inputRadio.setValue(id);
  }

  complete() {
    this.bsModalRef.hide();
    this.successEvent.emit("completeQueue");
  }

  editProcedure() {
    const initialState = {
      branchId: this.branchId,
      inQueue: true,
      queue: this.queue,
      serviceLists: this.allServiceLists,
      procedureTemplateData: this.procedureTemplate,
    };

    this.modalRef = this.modalService.show(EditProceduresModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-xl my-0",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        console.log(data);
        this.queue.procedureQueue = data.data;
        this.procedureQueue = this.queue.procedureQueue;
        this.procedures = this.queue.procedureQueue.procedures;
        this.setProcedureList();
        // this.updateProcedureQueue(data);
      },
      (err) => {
        return false;
      }
    );
  }

  proceed() {
    this.bsModalRef.hide();
    this.successEvent.emit(this.radioForm.controls.inputRadio.value);
  }
}
