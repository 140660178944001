import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { SMART_FORM_NAMES } from '../../enum/smart_form';
import { AppointmentService } from '../../services/appointment.service';
import { Subscription } from 'rxjs';
import { AddAppointmentModalComponent } from '../add-appointment-modal/add-appointment-modal.component';
import { EditAppointmentModalComponent } from '../edit-appointment-modal/edit-appointment-modal.component';
import { defaultAvatar } from "src/app/core/services/global";
import { time } from 'console';

@Component({
  selector: 'app-appointment-history-detail-modal',
  templateUrl: './appointment-history-detail-modal.component.html',
  styleUrls: ['./appointment-history-detail-modal.component.scss']
})
export class AppointmentHistoryDetailModalComponent implements OnInit {
  @Input() title: string;
  @Input() data: any;
  bookDate: string = '';
  completedDate: string = '';
  timeline: Array<any> = [];
  serviceName: string;
  subscriptions = new Subscription();
  defaultAvatar = defaultAvatar;

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private appointmentService: AppointmentService,
    private datePipe: DatePipe
  ) { }

/*   private formatDateWithourHours(dateCreated: number): string {
    const date = new Date(dateCreated);
    const formattedDate = this.datePipe.transform(date, 'EEE, MM/dd/yyyy');
    return formattedDate;
  } */

  private formatDate(dateCreated: number): string {
    dateCreated ? dateCreated : 0;
    const date = new Date(dateCreated);
    const formattedDate = this.datePipe.transform(date, 'EEE, MM/dd/yyyy - h:mm a');
    return formattedDate;
  }

  bookAgain() {
    this.modalRef.hide();
    
    const initialState = {
      title: "Book Appointment Again",
      isBookingAgain: true,
      data: this.data
    }

    this.modalRef = this.modalService.show(EditAppointmentModalComponent,{
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  closeModal() {
    this.modalRef.hide();
  }

  ngOnInit(): void {
    if(this.data?.bookTime) {
        this.bookDate = this.formatDate(this.data?.bookTime);
    }
    this.timeline = this.data.timeline.sort((a,b) => a.createdAt - b.createdAt);
    this.completedDate = this.formatDate(this.data?.createdAt);
    this.appointmentService.getAppointmentCategoryDetails(this.data.branchId, this.data.serviceId)
      .subscribe((res)=>{
        this.serviceName = res.data.displayName;
      })
  }
}

