import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-cost-breakdown',
  templateUrl: './cost-breakdown.component.html',
  styleUrls: ['./cost-breakdown.component.scss']
})
export class CostBreakdownComponent implements OnInit {
  title: any;
  subTitle: any;
  costBreakdownData: any = [
    {
      costName: 'Starter Plan',
      costDescription: 'Billed Monthly',
      costAmount: 5000
    },
    {
      costName: '(1) User Account',
      costDescription: '₱ 325.00 x 1 user account',
      costAmount: 325
    },
    {
      costName: '(2) Branches',
      costDescription: '₱ 799.00 x 2 branches',
      costAmount: 1598
    }
  ];
  totalCost = 0;

  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    for(var i=0;i<this.costBreakdownData.length;i++){
      this.totalCost = this.totalCost + this.costBreakdownData[i].costAmount;
    }
  }

}
