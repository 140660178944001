import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { BsModalRef } from "ngx-bootstrap/modal"

@Component({
  selector: 'app-delete-custom-report-modal',
  templateUrl: './delete-custom-report-modal.component.html',
  styleUrls: ['./delete-custom-report-modal.component.scss']
})
export class DeleteCustomReportModalComponent implements OnInit {
  isSubmitting: boolean = false
  reportId: string = ''
  reportName: string = ''
  branchId: string = ''
  @Output() successEvent = new EventEmitter()

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {

  }

  deleteCustomReport() {
    this.successEvent.emit('delete');
    this.bsModalRef.hide();
  }
}
