import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QueueOngoingComponent } from "./queue-workspace/components/queue-ongoing/queue-ongoing.component";
import { SharedModule } from "../shared/shared.module";
import { QueueWorkspacePageComponent } from "./queue-workspace/pages/queue-workspace-page/queue-workspace-page.component";
import { QueueSingleComponent } from "./queue-workspace/components/queue-single/queue-single.component";
import { CoreModule } from "../core/core.module";
import { ServicePageComponent } from "./service-window/pages/service-page/service-page.component";
import { WindowPageComponent } from "./service-window/pages/window-page/window-page.component";
import { ServiceWindowAddFormComponent } from "./service-window/components/service-window-add-form/service-window-add-form.component";
import { BsModalRef, ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { ServiceSmartFormComponent } from "./service-window/components/service-smart-form/service-smart-form.component";
import { ServiceAssignWindowsFormComponent } from "./service-window/components/service-assign-windows-form/service-assign-windows-form.component";
import { WindowAssignStaffFormComponent } from "./service-window/components/window-assign-staff-form/window-assign-staff-form.component";
import { QueueMainComponent } from "./queue-workspace/components/queue-main/queue-main.component";
import { QueueMainBottomComponent } from "./queue-workspace/components/queue-main-bottom/queue-main-bottom.component";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { QueueWindowSelectPageComponent } from "./queue-workspace/pages/queue-window-select-page/queue-window-select-page.component";
import { NotFoundPageComponent } from "./others/not-found-page/not-found-page.component";
import { QueueAddFormComponent } from "./queue-workspace/components/queue-add-form/queue-add-form.component";
import { QueueWindowLeaveModalComponent } from "./queue-workspace/components/queue-window-leave-modal/queue-window-leave-modal.component";
import { QueueTransferWindowModalComponent } from "./queue-workspace/components/queue-transfer-window-modal/queue-transfer-window-modal.component";
import { QueueTransferServiceModalComponent } from "./queue-workspace/components/queue-transfer-service-modal/queue-transfer-service-modal.component";
import { TeamPageComponent } from "./team-management/pages/team-page/team-page.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { AddAccountModalComponent } from "./team-management/components/add-account-modal/add-account-modal.component";
import { EditAccountModalComponent } from "./team-management/components/edit-account-modal/edit-account-modal.component";
import { ProfilePageComponent } from "./team-management/pages/profile-page/profile-page.component";
import { ResetPasswordModalComponent } from "./team-management/components/reset-password-modal/reset-password-modal.component";
import { RemoveAccountModalComponent } from "./team-management/components/remove-account-modal/remove-account-modal.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ClipboardModule } from "ngx-clipboard";
import { BranchesPageComponent } from "./branch-management/pages/branches-page/branches-page.component";
import { BranchDetailsPageComponent } from "./branch-management/pages/branch-details-page/branch-details-page.component";
import { EditBranchModalComponent } from "./branch-management/component/edit-branch-modal/edit-branch-modal.component";
import { DeleteBranchModalComponent } from "./branch-management/component/delete-branch-modal/delete-branch-modal.component";
import { AgmCoreModule } from "@agm/core";
import { DashboardPageComponent } from "./dashboard/pages/dashboard-page/dashboard-page.component";
import { UserDashboardComponent } from "./dashboard/component/user-dashboard/user-dashboard.component";
import { OwnerDashboardComponent } from "./dashboard/component/owner-dashboard/owner-dashboard.component";
import { QueueHistoryPageComponent } from "./queue-history/pages/queue-history-page/queue-history-page.component";
import { ReportsPageComponent } from "./reports/pages/reports-page/reports-page.component";
import { SettingsPageComponent } from "./settings/pages/settings-page/settings-page.component";
import { SupportPageComponent } from "./support/pages/support-page/support-page.component";
import { QueueDetailsModalComponent } from "./queue-history/component/queue-details-modal/queue-details-modal.component";
import { PopoverModule } from "ngx-bootstrap/popover";
import { RegisterPageComponent } from "./auth/pages/register-page/register-page.component";
import { ForgotPasswordPageComponent } from "./auth/pages/forgot-password-page/forgot-password-page.component";
import { BusinessSetupPageComponent } from "./auth/pages/business-setup-page/business-setup-page.component";
import { NewPasswordPageComponent } from "./auth/pages/new-password-page/new-password-page.component";
import { AddBranchModalComponent } from "./branch-management/component/add-branch-modal/add-branch-modal.component";
import { AddBranchSuccessModalComponent } from "./branch-management/component/add-branch-success-modal/add-branch-success-modal.component";
import { BusinessSettingsComponent } from "./settings/component/business-settings/business-settings.component";
import { BillingSettingsComponent } from "./settings/component/billing-settings/billing-settings.component";
import { QueueSettingsComponent } from "./settings/component/queue-settings/queue-settings.component";
import { SmsSettingsComponent } from "./settings/component/sms-settings/sms-settings.component";
import { EventNotificationSettingsComponent } from "./settings/component/event-notification-settings/event-notification-settings.component";
import { BranchSetupPageComponent } from "./branch-management/pages/branch-setup-page/branch-setup-page.component";
import { QRCodeModule } from "angularx-qrcode";
import { MoveQueueModalComponent } from "./queue-workspace/components/move-queue-modal/move-queue-modal.component";
import { MoveQueueServiceModalComponent } from "./queue-workspace/components/move-queue-service-modal/move-queue-service-modal.component";
import { EditBranchNoteModalComponent } from "./branch-management/component/edit-branch-note-modal/edit-branch-note-modal.component";
import { SelectViewModalComponent } from "./reports/component/select-view-modal/select-view-modal.component";
import { QueueCountFilterModalComponent } from "./reports/component/queue-count-filter-modal/queue-count-filter-modal.component";
import { ReportFilterModalComponent } from "./reports/component/report-filter-modal/report-filter-modal.component";
import { BranchTvSetupPageComponent } from "./branch-management/pages/branch-tv-setup-page/branch-tv-setup-page.component";
import { EditAddressModalComponent } from "./branch-management/component/edit-address-modal/edit-address-modal.component";
import { CodeLoginModalComponent } from "./branch-management/component/code-login-modal/code-login-modal.component";
import { ViewTvDetailsModalComponent } from "./branch-management/component/view-tv-details-modal/view-tv-details-modal.component";
import { DeleteTvModalComponent } from "./branch-management/component/delete-tv-modal/delete-tv-modal.component";
import { EditTvModalComponent } from "./branch-management/component/edit-tv-modal/edit-tv-modal.component";
import { QueueLimitModalComponent } from "./queue-workspace/components/queue-limit-modal/queue-limit-modal.component";
import { BranchOperationHoursComponent } from "./branch-management/pages/branch-operation-hours/branch-operation-hours.component";
import { SetOperationHoursModalComponent } from "./branch-management/component/set-operation-hours-modal/set-operation-hours-modal.component";
import { AddSpecialScheduleComponent } from "./branch-management/component/add-special-schedule/add-special-schedule.component";
import { EditOperationHoursComponent } from "./branch-management/component/edit-operation-hours/edit-operation-hours.component";
import { ViewSpecialScheduleComponent } from "./branch-management/component/view-special-schedule/view-special-schedule.component";
import { EditSpecialScheduleComponent } from "./branch-management/component/edit-special-schedule/edit-special-schedule.component";
import { RemoveSpecialSheduleComponent } from "./branch-management/component/remove-special-shedule/remove-special-shedule.component";
import { BillingPageComponent } from "./billing/pages/billing-page/billing-page.component";
import { FreeTrialExpiredComponent } from "./billing/components/free-trial-expired/free-trial-expired.component";
import { CostBreakdownComponent } from "./billing/components/cost-breakdown/cost-breakdown.component";
import { EditPaymentMethodComponent } from "./settings/components/edit-payment-method/edit-payment-method.component";
import { EditBillingAddressComponent } from "./settings/components/edit-billing-address/edit-billing-address.component";
import { EditBillingContactComponent } from "./settings/components/edit-billing-contact/edit-billing-contact.component";
import { EditBillingCurrencyComponent } from "./settings/components/edit-billing-currency/edit-billing-currency.component";

import { ServiceProcedurePageComponent } from "./service-procedure/pages/service-procedure-page/service-procedure-page.component";
import { AddEditDetailsModalComponent } from "./service-procedure/component/add-edit-details-modal/add-edit-details-modal.component";
import { ProcedureListDetailsPageComponent } from "./service-procedure/pages/procedure-list-details-page/procedure-list-details-page.component";
import { DragulaModule } from "ng2-dragula";
import { ChartModule } from 'primeng/chart';
import { SubscriptionPageComponent } from "./billing/pages/subscription-page/subscription-page.component";
import { PaymentModalComponent } from "./billing/components/payment-modal/payment-modal.component";
import { CancelSubscriptionModalComponent } from "./billing/components/cancel-subscription-modal/cancel-subscription-modal.component";
import { SubscriptionLimitModalComponent } from "./billing/components/subscription-limit-modal/subscription-limit-modal.component";
import { MultipleLoginModalComponent } from "./team-management/components/multiple-login-modal/multiple-login-modal.component";
import { CustomFormFieldsComponent } from "./settings/component/custom-form-fields/custom-form-fields.component";
import { AssignProcedureModalComponent } from "./service-procedure/component/assign-procedure-modal/assign-procedure-modal.component";
import { CompleteQueueModalComponent } from "./service-procedure/component/complete-queue-modal/complete-queue-modal.component";
import { SelectNextProcedureModalComponent } from "./service-procedure/component/select-next-procedure-modal/select-next-procedure-modal.component";
import { EditProceduresModalComponent } from "./service-procedure/component/edit-procedures-modal/edit-procedures-modal.component";
import { AppsPageComponent } from "./apps/apps-page/apps-page.component";
import { ServiceCategoryPageComponent } from "./service-category/pages/service-category-page/service-category-page.component";
import { AddServiceCategoryComponent } from "./service-category-v2/components/add-service-category/add-service-category.component";
import { ViewServiceCategoryComponent } from "./service-category/components/view-service-category/view-service-category.component";
import { EditServiceCategoryNameComponent } from "./service-category/components/edit-service-category-name/edit-service-category-name.component";
import { ServiceCategoryManageServicesComponent } from "./service-category/pages/service-category-manage-services/service-category-manage-services.component";
import { NoAttendingStaffModalComponent } from "./queue-workspace/components/no-attending-staff-modal/no-attending-staff-modal.component";
import { ServiceCategoryPageComponentV2 } from "./service-category-v2/pages/service-category-page/service-category-page.component";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { ViewCustomReportComponentModal } from "./reports/component/view-custom-report-modal/view-custom-report-modal.component";
import { MonitoringPageComponent } from './monitoring/pages/monitoring-page/monitoring-page.component';
import { MonitoringBranchPageComponent } from './monitoring/pages/monitoring-branch-page/monitoring-branch-page.component';
import { BranchPerformanceTabPageComponent } from './monitoring/pages/monitoring-branch-page/tab-components/branch-performance-tab-page/branch-performance-tab-page.component';
import { VisitorVolumesTabPageComponent } from './monitoring/pages/monitoring-branch-page/tab-components/visitor-volumes-tab-page/visitor-volumes-tab-page.component';
import { TeamPerformanceTabPageComponent } from './monitoring/pages/monitoring-branch-page/tab-components/team-performance-tab-page/team-performance-tab-page.component';
import { SupportHelpCenterPageComponent } from "./support/pages/support-page-components/support-help-center-page/support-help-center-page.component";
import { SupportHelpCenterComponent } from "./support/components/support-help-center/support-help-center.component";
import { SupportHelpCenterSubQuickSetupComponent } from "./support/components/support-help-center/support-help-center-sub-components/support-help-center-sub-quick-setup/support-help-center-sub-quick-setup.component";
import { SupportHelpCenterSubFaqComponent } from "./support/components/support-help-center/support-help-center-sub-components/support-help-center-sub-faq/support-help-center-sub-faq.component";
import { SupportHelpCenterSubTVDisplayComponent } from "./support/components/support-help-center/support-help-center-sub-components/support-help-center-sub-tv-display/support-help-center-sub-tv-display.component";
import { SupportHelpCenterSubSMSComponent } from "./support/components/support-help-center/support-help-center-sub-components/support-help-center-sub-sms/support-help-center-sub-sms.component";
import { SupportHelpCenterSubBranchComponent } from "./support/components/support-help-center/support-help-center-sub-components/support-help-center-sub-branch/support-help-center-sub-branch.component";
import { SupportHelpCenterSubReportsComponent } from "./support/components/support-help-center/support-help-center-sub-components/support-help-center-sub-reports/support-help-center-sub-reports.component";
import { SupportUserManualPageComponent } from "./support/pages/support-page-components/support-user-manual-page/support-user-manual-page.component";
import { SupportOnboardingPageComponent } from "./support/pages/support-page-components/support-onboarding-page/support-onboarding-page.component";
import { SupportReportIssueModalComponent } from "./support/components/support-report-issue-modal/support-report-issue-modal.component";
import { SupportConnectSalesModalComponent } from "./support/components/support-connect-sales-modal/support-connect-sales-modal.component";
import { SupportRequestFeatureModalComponent } from "./support/components/support-request-feature-modal/support-request-feature-modal.component";
import { AssignServiceModalComponent } from './service-category-v2/components/assign-service-modal/assign-service-modal.component';
import { DeleteModalComponent } from './service-category-v2/components/delete-modal/delete-modal.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CustomReportsPageComponent } from './reports/pages/custom-reports-page/custom-reports-page.component';
import { DeleteCustomReportModalComponent } from './reports/component/delete-custom-report-modal/delete-custom-report-modal.component';
import { CustomReportFilterModalComponent } from './reports/component/custom-report-filter-modal/custom-report-filter-modal.component';
import { QueueHistoryFilterComponent } from './reports/component/queue-history-filter/queue-history-filter.component';
import { FeedbackSettingsComponent } from "./settings/component/feedback-settings/feedback-settings.component";
import { FeedbackDashboardComponent } from './feedback-dashboard/feedback-dashboard.component';
import { TagInputModule } from "ngx-chips";
import { FeedbackDetailsComponent } from './feedback-dashboard/components/feedback-details/feedback-details.component';
import { WebAdminLandingPageComponent } from './auth/pages/web-admin-landing-page/web-admin-landing-page.component';
import { DeleteProcedureModalComponent } from './service-procedure/component/delete-procedure-modal/delete-procedure-modal.component';
import { DeleteWindowModalComponent } from './service-window/components/delete-window-modal-component/delete-window-modal.component';
import { DeleteServiceModalComponent } from './service-window/components/delete-service-modal/delete-service-modal.component';
import { UniversalTagsComponent } from './settings/component/universal-tags/universal-tags.component';
import { ScheduleAppointmentPageComponent } from './appointment/pages/schedule-appointment-page/schedule-appointment-page.component';
import { TodaysAppointmentComponent } from "./appointment/pages/schedule-appointment-page/tab-components/todays-appointment/todays-appointment.component";
import { UpcommingAppointmentsComponent } from './appointment/pages/schedule-appointment-page/tab-components/upcomming-appointments/upcomming-appointments.component';
import { ForApprovalComponent } from './appointment/pages/schedule-appointment-page/tab-components/for-approval/for-approval.component';
import { CalendarViewComponent } from './appointment/pages/schedule-appointment-page/tab-components/calendar-view/calendar-view.component';
import { AppointmentModalComponent } from './appointment/component/appointment-modal/appointment-modal.component';
import { AddQueueModalComponent } from './appointment/component/add-queue-modal/add-queue-modal.component';
import { RemoveAppointmentModalComponent } from './appointment/component/remove-appointment-modal/remove-appointment-modal.component';
import { AppointmentDetailsModalComponent } from './appointment/component/appointment-details-modal/appointment-details-modal.component';
import { HistoryAppointmentPageComponent } from './appointment/pages/history-appointment-page/history-appointment-page.component';
import { EditAppointmentModalComponent } from './appointment/component/edit-appointment-modal/edit-appointment-modal.component';
import { AddAppointmentModalComponent } from './appointment/component/add-appointment-modal/add-appointment-modal.component';
import { AppointmentHistoryDetailModalComponent } from './appointment/component/appointment-history-detail-modal/appointment-history-detail-modal.component';
import { ProceedNextProcedureModalComponent } from './service-procedure/component/proceed-next-procedure-modal/proceed-next-procedure-modal.component';
import { AppointmentSettingsComponent } from './appointment/component/appointment-settings/appointment-settings.component';
import { AppointmentSettingsPageComponent } from './appointment/pages/appointment-settings-page/appointment-settings-page.component';
import { AppointmentServiceSettingsComponent } from './appointment/component/appointment-service-settings/appointment-service-settings.component';
import { AppointmentEmailPageComponent } from './appointment/pages/appointment-email-page/appointment-email-page.component';
import { MaxVisitorsModalComponent } from './queue-workspace/components/max-visitors-modal/max-visitors-modal.component';
import { TransactionsModalComponent } from './queue-workspace/components/transactions-modal/transactions-modal.component';



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};
@NgModule({
  declarations: [
    QueueWorkspacePageComponent,
    QueueOngoingComponent,
    QueueSingleComponent,
    ServicePageComponent,
    WindowPageComponent,
    ServiceWindowAddFormComponent,
    ServiceSmartFormComponent,
    ServiceAssignWindowsFormComponent,
    WindowAssignStaffFormComponent,
    QueueMainComponent,
    QueueMainBottomComponent,
    QueueWindowSelectPageComponent,
    NotFoundPageComponent,
    QueueAddFormComponent,
    QueueWindowLeaveModalComponent,
    QueueTransferWindowModalComponent,
    QueueTransferServiceModalComponent,
    TeamPageComponent,
    AddAccountModalComponent,
    EditAccountModalComponent,
    ProfilePageComponent,
    ResetPasswordModalComponent,
    RemoveAccountModalComponent,
    BranchesPageComponent,
    BranchDetailsPageComponent,
    EditBranchModalComponent,
    DeleteBranchModalComponent,
    DashboardPageComponent,
    UserDashboardComponent,
    OwnerDashboardComponent,
    QueueHistoryPageComponent,
    ReportsPageComponent,
    SettingsPageComponent,
    SupportPageComponent,
    QueueDetailsModalComponent,
    RegisterPageComponent,
    ForgotPasswordPageComponent,
    NewPasswordPageComponent,
    AddBranchModalComponent,
    AddBranchSuccessModalComponent,
    BusinessSetupPageComponent,
    BusinessSettingsComponent,
    BillingSettingsComponent,
    QueueSettingsComponent,
    SmsSettingsComponent,
    EventNotificationSettingsComponent,
    BranchSetupPageComponent,
    MoveQueueModalComponent,
    MoveQueueServiceModalComponent,
    EditBranchNoteModalComponent,
    SelectViewModalComponent,
    QueueCountFilterModalComponent,
    ReportFilterModalComponent,
    BranchTvSetupPageComponent,
    EditAddressModalComponent,
    CodeLoginModalComponent,
    ViewTvDetailsModalComponent,
    DeleteTvModalComponent,
    EditTvModalComponent,
    QueueLimitModalComponent,
    BranchOperationHoursComponent,
    SetOperationHoursModalComponent,
    AddSpecialScheduleComponent,
    EditOperationHoursComponent,
    ViewSpecialScheduleComponent,
    EditSpecialScheduleComponent,
    RemoveSpecialSheduleComponent,
    BillingPageComponent,
    FreeTrialExpiredComponent,
    CostBreakdownComponent,
    EditPaymentMethodComponent,
    EditBillingAddressComponent,
    EditBillingContactComponent,
    EditBillingCurrencyComponent,
    ServiceProcedurePageComponent,
    AddEditDetailsModalComponent,
    ProcedureListDetailsPageComponent,
    SubscriptionPageComponent,
    PaymentModalComponent,
    CancelSubscriptionModalComponent,
    SubscriptionLimitModalComponent,
    MultipleLoginModalComponent,
    CustomFormFieldsComponent,
    AssignProcedureModalComponent,
    CompleteQueueModalComponent,
    SelectNextProcedureModalComponent,
    EditProceduresModalComponent,
    AppsPageComponent,
    ServiceCategoryPageComponent,
    ServiceCategoryPageComponentV2,
    AddServiceCategoryComponent,
    ViewServiceCategoryComponent,
    EditServiceCategoryNameComponent,
    ServiceCategoryManageServicesComponent,
    NoAttendingStaffModalComponent,
    AssignServiceModalComponent,
    DeleteModalComponent,
    FeedbackSettingsComponent,
    CustomReportsPageComponent,
    DeleteCustomReportModalComponent,
    ViewCustomReportComponentModal,
    CustomReportFilterModalComponent,
    QueueHistoryFilterComponent,
    MonitoringPageComponent,
    MonitoringBranchPageComponent,
    BranchPerformanceTabPageComponent,
    VisitorVolumesTabPageComponent,
    TeamPerformanceTabPageComponent,
    WebAdminLandingPageComponent,
    SupportHelpCenterPageComponent,
    SupportHelpCenterComponent,
    SupportHelpCenterSubQuickSetupComponent,
    SupportHelpCenterSubFaqComponent,
    SupportHelpCenterSubTVDisplayComponent,
    SupportHelpCenterSubSMSComponent,
    SupportHelpCenterSubBranchComponent,
    SupportHelpCenterSubReportsComponent,
    SupportUserManualPageComponent,
    SupportOnboardingPageComponent,
    SupportReportIssueModalComponent,
    SupportConnectSalesModalComponent,
    SupportRequestFeatureModalComponent,
    FeedbackSettingsComponent,
    FeedbackDashboardComponent,
    FeedbackDetailsComponent,
    WebAdminLandingPageComponent,
    DeleteProcedureModalComponent,
    DeleteWindowModalComponent,
    DeleteServiceModalComponent,
    UniversalTagsComponent,
    ScheduleAppointmentPageComponent,
    TodaysAppointmentComponent,
    UpcommingAppointmentsComponent,
    ForApprovalComponent,
    CalendarViewComponent,
    AppointmentModalComponent,
    AddQueueModalComponent,
    RemoveAppointmentModalComponent,
    AppointmentDetailsModalComponent,
    HistoryAppointmentPageComponent,
    EditAppointmentModalComponent,
    AddAppointmentModalComponent,
    AppointmentHistoryDetailModalComponent,
    AppointmentSettingsComponent,
    AppointmentSettingsPageComponent,
    AppointmentServiceSettingsComponent,
    ProceedNextProcedureModalComponent,
    AppointmentEmailPageComponent,
    MaxVisitorsModalComponent,
    TransactionsModalComponent,
  
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    DragulaModule.forRoot(),
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ClipboardModule,
    QRCodeModule,
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyCr_FeK4yZu_RpzVACy8FpP-NiOOQds670'
      apiKey: "AIzaSyCEcYYZ5FPrsIiD42WN5wXBzts_CNUj_OA",
      libraries: ["places"],
    }),
    TagInputModule,
    ChartModule
  ],
  exports: [
    QueueWorkspacePageComponent,
    QueueOngoingComponent,
    QueueSingleComponent,
    ServicePageComponent,
    WindowPageComponent,
    QueueMainComponent,
    QueueMainBottomComponent,
    BillingPageComponent,
    SubscriptionPageComponent,
    ChartModule
  ],
  providers: [
    BsModalRef,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  entryComponents: [
    ServiceWindowAddFormComponent,
    ServiceSmartFormComponent,
    ServiceAssignWindowsFormComponent,
    WindowAssignStaffFormComponent,
    QueueAddFormComponent,
    QueueWindowLeaveModalComponent,
    QueueTransferWindowModalComponent,
    QueueTransferServiceModalComponent,
    AddAccountModalComponent,
    EditAccountModalComponent,
    ResetPasswordModalComponent,
    RemoveAccountModalComponent,
    EditBranchModalComponent,
    DeleteBranchModalComponent,
    AddBranchModalComponent,
    AddBranchSuccessModalComponent,
    EditBranchNoteModalComponent,
    SelectViewModalComponent,
    ReportFilterModalComponent,
    QueueCountFilterModalComponent,
    BranchTvSetupPageComponent,
    EditAddressModalComponent,
    CodeLoginModalComponent,
    ViewTvDetailsModalComponent,
    DeleteTvModalComponent,
    EditTvModalComponent,
    SetOperationHoursModalComponent,
    AddSpecialScheduleComponent,
    EditOperationHoursComponent,
    ViewSpecialScheduleComponent,
    EditSpecialScheduleComponent,
  ],
})
export class ModulesModule { }
