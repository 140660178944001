import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { ServiceCategoryServiceV2 } from "../../services/service-category.service";

@Component({
  selector: "app-delete-modal",
  templateUrl: "./delete-modal.component.html",
  styleUrls: ["./delete-modal.component.scss"],
})
export class DeleteModalComponent implements OnInit {
  title: string;
  label: string;
  categoryData: any;
  type: string;
  branchId: string;
  serviceId: string;
  isSubmitting = false;

  @Output() successEvent = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private categoryService: ServiceCategoryServiceV2,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  confirm() {
    this.isSubmitting = true;
    if (this.type === "Service") {
      const services = this.categoryData.assignedServices;

      const index = services.indexOf(this.serviceId);
      if (index > -1) {
        services.splice(index, 1);
      }

      const body = {
        displayName: this.categoryData.displayName,
        assignedServices: services,
      };

      this.categoryService
        .updateServiceCategory(this.branchId, this.categoryData._id, body)
        .subscribe(
          (res) => {
            console.log(res);
            this.isSubmitting = false;
            this.bsModalRef.hide();
            this.successEvent.emit(res.data);
            this.toastr.error(`${this.label} has been removed`, "Removed!");
          },
          (error) => {
            console.log(error);
            this.isSubmitting = false;
            let errMsg = "Error on managing services";
            this.toastr.error(errMsg, "Error!");
          }
        );
    } else {
      this.categoryService
        .removeServiceCategory(this.branchId, this.categoryData._id)
        .subscribe(
          (data) => {
            this.isSubmitting = false;
            this.bsModalRef.hide();
            this.successEvent.emit("");
            this.toastr.error(`${this.label} has been removed`, "Removed!");
          },
          (error) => {
            this.isSubmitting = false;
            console.log("error", error);
          }
        );
    }
  }
}
