import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";

/* interface model */
import { HelpCenterCategories } from "../../../interfaces/support.interface";

@Component({
  selector: "app-support-help-center-page",
  templateUrl: "./support-help-center-page.component.html",
  styleUrls: ["./support-help-center-page.component.scss"],
})



export class SupportHelpCenterPageComponent implements OnInit {
  public categoriesId;
  helpCenterCategories: string = "";
  helpCenterCategoriesName: string = "";
  displayHelpCategories: boolean = true;
  displayHelpCenterComponent: boolean = true;
  selectedCategory: number;

  pageDetails = [
      { type: "main", name: "Support", routerLink: ["/support"] },
      { type: "current", name: "Help Center", routerLink: null },
  ];

  categories: Array<HelpCenterCategories> = [
    {
      id: 0,
      category: "FAQ",
      title: "FAQ",
      description: "Frequently asked question",
    },
         {
      id: 1,
      category: "tv-display-and-kiosk",
      title: "Troubleshooting",
      description: "How to setup and configure your Kyoo Devices",
    }, 
/*     {
      id: 2,
      category: "quick-setup",
      title: "Quick Setup",
      description: "How to set up Kyoo",
    }, */

/*   {
      id: 3,
      category: "branch",
      title: "Branch",
      description: "Configure your branch settings for your maximum benefit",
    }, */
/*  {
      id: 4,
      category: "reports",
      title: "Reports",
      description:
        "Lorem ipsum dolor sit amet consectetur. In ut id neque habitasse elementum.",
    }, */
/*     {
      id: 5,
      category: "sms-or-text-messaging",
      title: "SMS / Text Messaging",
      description:
        "Lorem ipsum dolor sit amet consectetur. Auctor ut condimentum semper mi.",
    }, */
  ];

  constructor(private route: ActivatedRoute) {}

  helpCenterBtn(): void {
    this.displayHelpCategories = true;
    this.helpCenterCategories = "";
  }


  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = params.get("category");
      this.categoriesId = id;

      for (let i = 0; this.categories.length > i; i++) {
        if (this.categories[i].category === this.categoriesId) {
          this.displayHelpCategories = false;
          this.displayHelpCenterComponent = false;
          this.helpCenterCategories = this.categories[i].category;
          this.helpCenterCategoriesName = this.categories[i].title;
          this.pageDetails[1] = { type: "main", name: "Help Center", routerLink: ['/support/help-center'] }
          this.pageDetails[2] = { type: "current", name: this.categories[i].title, routerLink: null }
        }
      }
    });
  }
}
