import { Component, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { AuthLayoutComponent } from "./shared/layouts/auth-layout/auth-layout.component";
import { LoggedinLayoutComponent } from "./shared/layouts/loggedin-layout/loggedin-layout.component";
import { WebAdminLandingPageComponent } from "./modules/auth/pages/web-admin-landing-page/web-admin-landing-page.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: "dashboard",
    pathMatch: "full"
  },
  {
    path: 'web-admin',
    component: WebAdminLandingPageComponent
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./shared/layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule)
    }]
  },
  {
    path: '',
    component: LoggedinLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./shared/layouts/loggedin-layout/loggedin-layout.module').then(m => m.LoggedinLayoutModule)
    }]
  }, {
    path: '**',
    redirectTo: 'not-found'
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: []
})
export class AppRoutingModule { }
