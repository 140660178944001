import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { DatePickerModalComponent } from "../date-picker-modal/date-picker-modal.component";

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

  @Output() selectedDate = new EventEmitter<string | any[]>();

  dateFilter = [
    {
      value: "today",
      label: "Today"
    },
    {
      value: "yesterday",
      label: "Yesterday"
    },
    {
      value: "this-week",
      label: "This Week"
    },
    {
      value: "this-month",
      label: "This Month"
    },
    {
      value: "this-year",
      label: "This Year"
    },
    {
      value: "custom",
      label: "Custom"
    }
  ]

  selectedLabel = "Today";
  selectedValue = "today";
  isFocused: boolean = false;
  isSelectClicked: boolean = false;

  constructor(private bsModalRef: BsModalRef,
    private bsModalService: BsModalService) { }

  ngOnInit(): void {
    this.selectedDate.emit(this.selectedValue);
  }

  select() {
    this.isSelectClicked = !this.isSelectClicked;
    this.isFocused = !this.isFocused;
  }

  selectDropdown(list) {

    if (list.value == "custom") {
      this.selectCustomDateFilter();
    } else {
      this.selectedValue = list.value;
      this.selectedLabel = list.label;
      this.selectedDate.emit(this.selectedValue);

    }

    this.isFocused = !this.isFocused;

  }

  selectCustomDateFilter() {
    const initialState = {
      hasMinDate: false,
      datePickerType: "range",
    };
    this.bsModalRef = this.bsModalService.show(DatePickerModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md2",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.bsModalRef.content.selectedDateRange.subscribe(
      (data) => {
        if (data) {
          console.log(data)

          // to select a single date, the user must double click his/her desired date to get data[0] & data[1]
          if (data[0] && data[1]) {
            const customDate1 = (data[0] as Date).toLocaleDateString("en-US"); //start
            const customDate2 = (data[1] as Date).toLocaleDateString("en-US"); //end
            this.selectedValue = data;
            this.selectedLabel = customDate1 + " - " + customDate2;
            //emit date data and coerce it to number
            this.selectedDate.emit([+data[0], +data[1]]);
          }

        }
      },
      (err) => {
        return false;
      }
    );
  }
}
