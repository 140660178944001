import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/core/http/http.service';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/core/services/data.service';

interface BranchFeedbackSettings {
  featuresEnabled: string[];
  createdAt: number;
  enabled: boolean;
  expiredAt: number;
  branchId: string;
  updatedAt: number;
}

@Component({
  selector: 'app-apps-page',
  templateUrl: './apps-page.component.html',
  styleUrls: ['./apps-page.component.scss']
})
export class AppsPageComponent implements OnInit {

  environmentType: any | string = environment.env;

  branchFeedbackSettings: BranchFeedbackSettings | null = null;

  constructor(private router: Router,
    private dataService: DataService,
    private httpService: HttpService) {

  }

  ngOnInit(): void {
    console.log(this.dataService.currentBranch$)
    console.log(this.dataService.currentBranch$.branchId)
    this.checkFeedbackSettings(this.dataService.currentBranch$.branchId);
    console.log("navigate to apps page")
  }

  goToServiceProcedure() {
    this.router.navigate(['apps/service-procedure']);
  }

  goToServiceCategory() {
    this.router.navigate(['apps/service-category']);
  }

  checkFeedbackSettings(branchId: string) {
    this.httpService.get$(`feedback/${branchId}/branch-settings`).subscribe((res: any) => {
      this.branchFeedbackSettings = res.result;
      console.log(res)
    })
  }

}
