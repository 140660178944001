import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-assign-procedure-modal",
  templateUrl: "./assign-procedure-modal.component.html",
  styleUrls: ["./assign-procedure-modal.component.scss"],
})
export class AssignProcedureModalComponent implements OnInit {
  @Input() title: any;
  @Input() subTitle: any;
  @Input() procedureTemplates: any;
  dropdownList: any;
  procedureServiceList: any;
  allServiceLists: any;
  selectedTemplate: any;

  selectedServiceId: string;
  selectedProcedureType: string = '';

  radioForm = this.fb.group({
    inputRadio: [""],
  });

  @Output() successEvent = new EventEmitter();

  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.dropdownList = this.procedureTemplates.map((element) => {
      return {
        label: element.displayName,
        value: element._id,
      };
    });

    if(this.dropdownList.length === 1) {
      this.selectedTemplate = this.procedureTemplates.find(
        (x) => x._id === this.dropdownList[0].value
      );

      this.select(this.selectedTemplate._id)
    }
  }

  select(ev) {
    if (ev !== "Select Template") {
      const selectedTemplate = this.procedureTemplates.find(
        (x) => x._id === ev
      );
      if (selectedTemplate) {
        this.selectedTemplate = selectedTemplate;

        this.procedureServiceList = [];

        this.selectedTemplate.procedures.forEach((p) => {
          const service = this.allServiceLists.find(
            (x) => x._id === p.serviceId
          );
          if (service) {
            this.procedureServiceList.push({
              ...p,
              displayName: service.displayName,
            });
          }
        });
      }
    }
  }

  change(id) {
    this.selectedServiceId = id;
  }

  getServiceName(id) {
    let name = "";
    if (id) {
      const service = this.allServiceLists.find((x) => x._id === id);
      if (service) {
        name = service.displayName;
      }
    }

    return name;
  }

  proceed() {
    this.bsModalRef.hide();
    this.successEvent.emit(this.selectedTemplate);
  }

  complete() {
    this.bsModalRef.hide();
    this.successEvent.emit("completeQueue");
  }

  createNewProcedure() {}
}
