import { HttpParams } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import List from "list.js";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { AuthService } from "src/app/core/auth/auth.service";
import { DataService } from "src/app/core/services/data.service";
import { defaultAvatar } from "src/app/core/services/global";
import { BranchManagementService } from "src/app/modules/branch-management/services/branch-management.service";
import { AddAccountModalComponent } from "../../components/add-account-modal/add-account-modal.component";
import { EditAccountModalComponent } from "../../components/edit-account-modal/edit-account-modal.component";
import { RemoveAccountModalComponent } from "../../components/remove-account-modal/remove-account-modal.component";
import { ResetPasswordModalComponent } from "../../components/reset-password-modal/reset-password-modal.component";
import { TeamManagementService } from "../../services/team-management.service";

@Component({
  selector: "app-team-page",
  templateUrl: "./team-page.component.html",
  styleUrls: ["./team-page.component.scss"],
})
export class TeamPageComponent implements OnInit, OnDestroy {
  teamList: any;

  defaultAvatar: string = defaultAvatar;
  searchString: string;
  searchSubject: Subject<string> = new Subject();
  accountData = this.dataService.accountData$;
  subscriptions = new Subscription();
  branchId: string;
  branchData = this.dataService.currentBranch$;

  selectedRole = [];

  constructor(
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    private router: Router,
    private teamService: TeamManagementService,
    private authService: AuthService,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe(async (res) => {
        await this.handleBranchChange(res);
        await this.getAccounts();

        this.activatedRoute.queryParams.subscribe(async (params) => {
          if (params && params.type === "setup") {
            await this.addAccount();
            this.authService.updateIntroLevel({ level: 2 }).subscribe((res) => {
              this.dataService.publishIntroLevel$(
                res.result.completedIntroductionLevels
              );
            });
          } else if (params && params.type === "invite") {
            await this.addAccount();
          }
          // console.log(params);
        });

        console.log("curr branch", this.dataService.currentBranch$?.branchId);
      })
    );

    this.searchSubject
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((searchTextValue) => {
        console.log("search string", searchTextValue);
        this.itemsPerPage = 10;
        if (searchTextValue) {
          this.getAccounts();
        } else {
          this.getAccounts();
        }
      });
  }

  async handleBranchChange(res: any): Promise<void> {
    this.branchId = res ? res._id : this.dataService.currentBranch$.branchId;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public searchTeam() {
    this.searchSubject.next(this.searchString);
  }

  items = 100;
  pageChange(page) {
    this.selectedPage = page.page;
    console.log(this.selectedPage);
    this.getAccounts();
  }

  itemsPerPage = 10;
  selectedPage = 1;
  entriesChange(a) {
    console.log(a);
    this.itemsPerPage = a;
    this.getAccounts();
  }

  get params() {
    let params = new HttpParams();
    params = params.append("limit", this.itemsPerPage.toString());
    const offset = this.selectedPage * this.itemsPerPage - this.itemsPerPage;
    params = params.append("offset", offset.toString());
    if (this.searchString) {
      params = params.append("search", this.searchString);
    }
    if (this.selectedRole.length > 0) {
      params = params.append("roleLevel", this.selectedRole.join(","));
    }
    return params;
  }

  async getAccounts() {
    this.subscriptions.add(
      this.teamService
        .getTeamList(this.params, this.branchId)
        .subscribe((res) => {
          this.teamList = res;
          console.log("TEAM LIST", res);
        })
    );
  }

  async addAccount() {
    const initialState = {
      branchId: this.branchId,
      branchData: this.branchData,
    };
    this.modalRef = this.modalService.show(AddAccountModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        console.log("add account", data);
        this.getAccounts();
      },
      (err) => {
        console.log("add account", err);
        return false;
      }
    );
  }

  viewProfile(id) {
    this.router.navigate(["/team-management/", id]);
  }

  removeAccount(data) {
    const initialState = {
      title: "Remove Account",
      profileData: data,
      branchId: this.branchId,
    };
    this.modalRef = this.modalService.show(RemoveAccountModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        console.log("delete account", data);
        this.getAccounts();
      },
      (err) => {
        console.log("delete account", err);
        return false;
      }
    );
  }

  resetPassword(data) {
    const initialState = {
      title: "Reset Password",
      profileData: data,
    };
    this.modalRef = this.modalService.show(ResetPasswordModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  editProfile(data) {
    const initialState = {
      title: "Edit Profile",
      profileData: data,
    };
    this.modalRef = this.modalService.show(EditAccountModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        console.log("edit account", data);
        this.getAccounts();
      },
      (err) => {
        console.log("edit account", err);
        return false;
      }
    );
  }

  selectAllRoleFilter(isChecked) {
    const usersCheckbox = document.getElementsByName("role-checkbox") as any;
    this.selectedRole = [];

    if (isChecked) {
      this.selectedRole.push("branch-manager");
      this.selectedRole.push("staff");
    }

    usersCheckbox.forEach((element) => {
      element.checked = isChecked;
    });

    this.getAccounts();
    console.log(this.selectedRole);
  }

  selectRoleFilter(id) {
    if (this.selectedRole.includes(id)) {
      const index = this.selectedRole.indexOf(id);
      if (index > -1) {
        this.selectedRole.splice(index, 1);
      }
    } else {
      this.selectedRole.push(id);
    }

    this.getAccounts();
    console.log(this.selectedRole);
  }

  clearRoleFilter() {
    this.selectedRole = [];
    const usersCheckbox = document.getElementsByName("role-checkbox") as any;
    const allUsersCheckbox = document.getElementsByName(
      "all-role-checkbox"
    ) as any;
    allUsersCheckbox[0].checked = false;
    usersCheckbox.forEach((element) => {
      element.checked = false;
    });
    this.getAccounts();
  }
}
