import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ToastrModule } from "ngx-toastr";
import { TagInputModule } from "ngx-chips";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { AppComponent } from "./app.component";

import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { ModulesModule } from "./modules/modules.module";

import { DatePipe } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { notificationReducer } from "./modules/notifications/store/reducers/notifications";




@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
      closeButton: true,
    }),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    TagInputModule,
    BrowserModule,
    ModulesModule,
    SharedModule.forRoot(),
    StoreModule.forRoot(
      { 
        notificationState: notificationReducer,
      },
    ),
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule { }
