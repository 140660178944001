import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentSettingsService {

  apiUrl = environment.urlAPI;

  constructor(private http: HttpClient) { }

  // public getServicesList(branchId: any): Observable<any> {
  //   const url = `${this.publicUrl}/services/${branchId}`;
  //   return this.http.get<any>(url);
  // }
  public getServicesList(branchId: any): Observable<any> {
    const url = `${this.apiUrl}/services/${branchId}/filter-list`;
    return this.http.get<any>(url);
  }
  public updateService(branchId: any, serviceId: any, data: any): Observable<any> {
    const url = `${this.apiUrl}/services/${branchId}/${serviceId}`;
    return this.http.patch(url, data).pipe((response)=>response);
  }
  public getOperationHours(branchId): Observable<any> {
    const url = `${this.apiUrl}/branches/${branchId}/operation-days`;
    return this.http.get<any>(url);
  }
  public getSettings(branchId): Observable<any>{
    const url = `${this.apiUrl}/appointment/${branchId}/settings`;
    return this.http.get<any>(url);
  }
  public updateSettings(branchId, settingsId, data: any): Observable<any>{
    const url = `${this.apiUrl}/appointment/${branchId}/settings/${settingsId}`;
    return this.http.patch(url, data).pipe((response)=>response);
  }
}
