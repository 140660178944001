import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/core/auth/auth.service";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { ModalConfirmComponent } from "src/app/shared/components/modal-confirm/modal-confirm.component";
import { ModalWarningImageComponent } from "src/app/shared/components/modal-warning-image/modal-warning-image.component";
import { AssignProcedureModalComponent } from "../../service-procedure/component/assign-procedure-modal/assign-procedure-modal.component";
import { CompleteQueueModalComponent } from "../../service-procedure/component/complete-queue-modal/complete-queue-modal.component";
import { EditProceduresModalComponent } from "../../service-procedure/component/edit-procedures-modal/edit-procedures-modal.component";
import { SelectNextProcedureModalComponent } from "../../service-procedure/component/select-next-procedure-modal/select-next-procedure-modal.component";
import { MoveQueueModalComponent } from "../components/move-queue-modal/move-queue-modal.component";
import { MoveQueueServiceModalComponent } from "../components/move-queue-service-modal/move-queue-service-modal.component";
import { NoAttendingStaffModalComponent } from "../components/no-attending-staff-modal/no-attending-staff-modal.component";
import { QueueTransferServiceModalComponent } from "../components/queue-transfer-service-modal/queue-transfer-service-modal.component";
import { QueueTransferWindowModalComponent } from "../components/queue-transfer-window-modal/queue-transfer-window-modal.component";
import { ProceedNextProcedureModalComponent } from "../../service-procedure/component/proceed-next-procedure-modal/proceed-next-procedure-modal.component";

@Injectable({
  providedIn: "root",
})
export class QueueWorkspaceService {
  subscriptions = new Subscription();
  accountId: string = this.authService.accountId$;
  accountData = this.dataService.accountData$;
  currentWindow: string = this.dataService.currentWindow$;
  assignedWindowLists: Array<any> = this.dataService.assignedWindowLists$;
  accountPromise: Promise<string>;
  selectedServiceId: string;
  selectedQueueId: string;
  selectedSortFilter: any;
  activeQueue: any;
  windowData: any;
  serviceData: any;
  isQueueListsInit: boolean;
  branchId = this.dataService.currentBranch$.branchId;
  queueSettings: any;
  AllServiceLists: any;
  serviceLists: any[] = [];
  procedureTemplates: any;
  windowList: any;
  allButtonsDisabled = false;

  constructor(
    public authService: AuthService,
    public dataService: DataService,
    public httpService: HttpService,
    public toastr: ToastrService,
    public router: Router,
    public modalService: BsModalService,
    public modalRef: BsModalRef
  ) { }

  async getAccountDetails() {
    this.currentWindow = this.accountData?.data?.attendingWindow;
    this.dataService.currentWindow$ = this.currentWindow;
    this.assignedWindowLists = this.accountData?.data?.assignedWindows;
    this.dataService.assignedWindowLists$ = this.assignedWindowLists;
  }

  // Actions

  // Serve Queue from Ongoing Queue
  serveQueue() {
    const bodyForm = {
      windowId: this.currentWindow,
    };
    this.httpService
      .patch$(`queues/${this.branchId}/${this.selectedQueueId}/serve`, bodyForm)
      .subscribe(
        (data) => {
          // console.log("serve queue", data);
          const queueNo = data.data.queueNo;
          this.toastr.success(`${queueNo} is being served`, "Served!", {
            toastClass: "ngx-toastr toast-success-alt",
            positionClass: 'toast-bottom-right-custom'
          });
          this.dataService.publishDataChange$({
            action: "served",
            queue: data?.data?._id,
          });
          this.isQueueListsInit = true;
          // console.log("servedddd");
        },
        (error) => {
          if (
            error.error.errors.msg &&
            error.error.errors.msg.includes("queue limit has been reached")
          ) {
            // Open Modal Limit
            const limit = error.error.errors.msg.match(/\d+/)[0];
            this.openModalLimit(limit);
          } else {
            console.log(error.error.errors);
            if (error.error.errors[0]) {
              this.toastr.error(error.error.errors[0].msg, "Failed!", {
                positionClass: 'toast-bottom-right-custom'
              });
            } else {
              this.toastr.error("Queue has not been served", "Failed!", {
                positionClass: 'toast-bottom-right-custom'
              });
            }
          }
        }
      );
  }

  // Open Modal Limit
  openModalLimit(limit) {
    const initialState = {
      title: "Sorry, but you can’t serve queue today",
      subTitle: `The maximum queues that can be served on this service is <b>${limit} per day</b>`,
      confirmClass: "btn-primary-a2",
      caption: `Queue limit is based on this service’s settings.
      Please contact your manager for more information.`,
      mainButtonText: "Okay, got it!",
    };
    this.modalRef = this.modalService.show(ModalWarningImageComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  confirmDoneQueue(bodyForm) {
    this.allButtonsDisabled = true;

    this.httpService
      .patch$(
        `queues/${this.branchId}/${this.selectedQueueId}/complete`,
        bodyForm
      )
      .subscribe(
        (data) => {
          console.log("complete queue", data);
          const queueNo = data.data.queueNo;
          this.toastr.success(`${queueNo} has been completed`, "Done!", {
            positionClass: 'toast-bottom-right-custom'
          });
          this.dataService.publishDataChange$({
            action: "completed",
            queue: data?.data?._id,
          });
          this.isQueueListsInit = true;
          this.allButtonsDisabled = false;
        },
        (error) => {
          this.allButtonsDisabled = false;
          this.toastr.error("Queue has not been completed", "Failed!", {
            positionClass: 'toast-bottom-right-custom'
          });
        }
      );
  }

  moveQueueService() {
    const availableServices = this.AllServiceLists.filter(
      (x) => x._id !== this.activeQueue.serviceId
    );
    console.log(availableServices);

    const initialState = {
      title: "Queue to Another Service",
      subTitle: `Select a service where to add queue number <b>${this.activeQueue?.queueNo}</b>.`,
      serviceLists: availableServices,
      enableRemarks: this.queueSettings?.featuresEnabled.includes(
        "remarks-on-move-queue"
      ),
      requireRemarks: this.queueSettings?.featuresEnabled.includes(
        "require-all-remarks-fields"
      ),
    };

    this.modalRef = this.modalService.show(MoveQueueServiceModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.submitEvent.subscribe(
      (body) => {
        if (body) {
          console.log(body);
          this.allButtonsDisabled = true;

          this.httpService
            .patch$(
              `queues/${this.branchId}/${this.selectedQueueId}/move-next`,
              body
            )
            .subscribe(
              (res) => {
                console.log("move next queue", res);
                // const queueNo = data.data.newQueueHistoryData.queueNo;
                const serviceName = this.AllServiceLists.find(
                  (x) => x._id === body.destinationServiceId
                );
                this.toastr.success(
                  `${this.activeQueue?.queueNo} has been added to ${serviceName ? serviceName.displayName : "another service"
                  }`,
                  "Done!", {
                  positionClass: 'toast-bottom-right-custom'
                }
                );
                this.dataService.publishDataChange$({
                  action: "completed",
                  queue: res?.data?._id,
                });
                this.allButtonsDisabled = false;
                this.isQueueListsInit = true;
              },
              (error) => {
                this.allButtonsDisabled = false;
                this.toastr.error("Queue has not been moved", "Failed!", {
                  positionClass: 'toast-bottom-right-custom'
                });
              }
            );
        }
      },
      (err) => {
        console.log("", err);
        return false;
      }
    );
  }
  // Done Queue from Current Serving
  doneQueue(completeQueue?) {
    const bodyForm = {
      windowId: this.currentWindow,
    };

    const serviceName = this.AllServiceLists.find(
      (s) => s._id === this.activeQueue.serviceId
    );
    if (
      this.queueSettings?.featuresEnabled.includes("move-queue") &&
      !completeQueue
    ) {
      const initialState = {
        title: "What's the next step for this queue?",
        subTitle: `Please select an option below before we mark <b>${this.activeQueue?.queueNo
          }</b> as done on <b>${serviceName ? serviceName.displayName : ""}</b>.`,
      };

      this.modalRef = this.modalService.show(MoveQueueModalComponent, {
        initialState: initialState,
        class: "modal-dialog-centered modal-md",
        ignoreBackdropClick: true,
        keyboard: false,
      });

      this.modalRef.content.actionEvent.subscribe(
        (data) => {
          if (data === "complete") {
            this.confirmDoneQueue(bodyForm);
          } else {
            this.moveQueueService();
          }
        },
        (err) => {
          console.log("", err);
          return false;
        }
      );
    } else {
      this.confirmDoneQueue(bodyForm);
    }
  }

  processProcedureWithOneTemplate(procedureTemplate) {
    const currentService = this.AllServiceLists.find(
      (s) => s._id === this.activeQueue.serviceId
    );
    const nextService = this.AllServiceLists.find(
      (s) => s._id === procedureTemplate.procedures[0].serviceId
    );
    const initialState = {
      title: `${this.activeQueue.queueNo} is done`,
      subtitle: `This queue is done on <b>${currentService.displayName}</b>, do you want to proceed to <b>${nextService.displayName}</b>?<br/>
        Or you can complete this queue now.`,
    };
    this.modalRef = this.modalService.show(ProceedNextProcedureModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.actionEvent.subscribe((data) => {
      if (data === "complete") {
        this.doneQueue(true);
      }
      else if (data === "proceed") {
        this.assignProcedureToQueue(procedureTemplate);
      }
    })
  }

  assignProcedureToQueue(data, assignAnother?) {
    const bodyForm = {
      procedureType: data.procedureType,
      procedureTemplateId: data._id,
      queueId: this.activeQueue._id,
      modules: data.modules,
      procedures: data.procedures,
    };

    if (assignAnother) {
      const newBodyForm = {
        procedures: data.procedures,
      };
      this.allButtonsDisabled = true;

      this.httpService
        .patch$(
          `queues/procedure-queues/${this.branchId}/${this.activeQueue.procedureQueueId}/procedures`,
          newBodyForm
        )
        .subscribe(
          (data) => {
            console.log("update procedure queue", data);
            if (data.data.procedureType === "flexible") {
              this.activeQueue.procedureQueue = data.data;
              this.selectNextProcedure();
            }
            // for TMC
            // else if (data.data.procedureType === "step-by-step") {
            //   this.selectNextProcedure(data.procedures, data, true, bodyForm);
            // }
            else {
              this.proceedToNextProcedure(data.data);
            }
            this.allButtonsDisabled = true;
          },
          (error) => {
            this.allButtonsDisabled = true;

            this.toastr.error("Queue has not been completed", "Failed!", {
              positionClass: 'toast-bottom-right-custom'
            });
          }
        );
    } else {
      this.httpService
        .post$(`queues/procedure-queues/${this.branchId}`, bodyForm)
        .subscribe(
          (data) => {
            console.log("assign procedure queue", data);
            if (data.data.procedureType === "flexible") {
              this.activeQueue.procedureQueue = data.data;
              this.activeQueue.procedureQueueId = data.data._id;
              this.selectNextProcedure();
            } else {
              this.activeQueue.procedureQueue = data.data;
              const nextProcedure =
                this.activeQueue.procedureQueue.procedures[0];

              if (nextProcedure && nextProcedure.optional) {
                const procedures = [];
                const nextProcedures =
                  this.activeQueue.procedureQueue.procedures.filter(
                    (p) => p.serviceId !== this.activeQueue.serviceId
                  );

                for (let index = 0; index < nextProcedures.length; index++) {
                  if (nextProcedures[index].optional) {
                    procedures.push(nextProcedures[index]);
                  } else {
                    procedures.push(nextProcedures[index]);
                    index = nextProcedures.length;
                  }
                }

                this.selectNextProcedure(procedures);
                console.log(procedures);
              } else {
                this.proceedToNextProcedure(this.activeQueue.procedureQueue);
              }
            }
          },
          (error) => {
            this.toastr.error("Queue has not been completed", "Failed!", {
              positionClass: 'toast-bottom-right-custom'
            });
          }
        );
    }
  }

  selectNextProcedure(
    procedures?,
    procedureTemplateData?,
    assignAnother?,
    bodyForm?
  ) {
    const procedureTemplate = this.procedureTemplates.find((x) =>
      procedureTemplateData
        ? x._id === procedureTemplateData._id
        : x._id === this.activeQueue.procedureQueue.procedureTemplateId
    );
    const initialState = {
      branchId: this.branchId,
      queue: this.activeQueue,
      allServiceLists: this.AllServiceLists,
      procedureTemplate: procedureTemplate,
      procedureQueue: assignAnother ? procedureTemplate : null,
      procedures: procedures,
      assignAnother: assignAnother,
    };

    this.modalRef = this.modalService.show(SelectNextProcedureModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-xl my-0",
      ignoreBackdropClick: true,
      keyboard: false,
      animated: false
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if (assignAnother) {
          this.completeQueueProcedure(
            bodyForm,
            true,
            procedureTemplateData,
            data
          );
        } else if (data === "completeQueue") {
          this.completeProcedureQueueNowAlert();
        } else {
          const service = initialState.queue.procedureQueue.procedures.find(
            (x) => x._id === data
          );
          if (service) {
            this.proceedToNextProcedure(null, false, data, service?.serviceId, initialState.queue);
          }
        }
        this.allButtonsDisabled = false;
      },
      (err) => {
        this.allButtonsDisabled = false;
        return false;
      }
    );
  }

  completeProcedureQueueNowAlert(completeQueue?) {
    const initialState = {
      queue: this.activeQueue,
    };

    this.modalRef = this.modalService.show(CompleteQueueModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if ((data && !this.activeQueue.procedureQueue) || completeQueue) {
          this.doneQueue(true);
        } else {
          this.proceedToNextProcedure(null, true);
        }
      },
      (err) => {
        return false;
      }
    );
  }

  proceedToNextProcedure(data, completeQueue?, procedureId?, serviceId?, activeQueue?) {
    let bodyForm: any;
    let serviceName: any;

    let aQueue = activeQueue || this.activeQueue;

    const nextProcedure = data
      ? data.procedures.find(
        (p) =>
          p.serviceId !== aQueue.serviceId && p.state === "pending"
      )
      : null;

    if ((nextProcedure && !completeQueue) || procedureId) {
      if (procedureId) {
        bodyForm = {
          nextProcedureId: procedureId,
        };

        serviceName = this.AllServiceLists.find((s) => s._id === serviceId);
      } else {
        bodyForm = {
          nextProcedureId: nextProcedure._id,
        };

        serviceName = this.AllServiceLists.find(
          (s) => s._id === nextProcedure.serviceId
        );
      }

      this.httpService
        .patch$(
          `queues/${this.branchId}/${aQueue._id}/next-procedure`,
          bodyForm
        )
        .subscribe(
          (data) => {
            console.log("next procedure queue", data);
            this.toastr.success(
              `${aQueue.queueNo} has been completed and added to ${serviceName ? serviceName.displayName : ""
              }`,
              "Queue Complete!", {
              positionClass: 'toast-bottom-right-custom'
            }
            );
            this.dataService.publishDataChange$({
              action: "completed",
              queue: data?.data?._id,
            });
            this.isQueueListsInit = true;
            this.allButtonsDisabled = false;
          },
          (error) => {
            this.allButtonsDisabled = false;
            this.toastr.error("Queue has not been completed", "Failed!", {
              positionClass: 'toast-bottom-right-custom'
            });
          }
        );
    } else {
      // uncomment this to enable assign another procedure feature
      // const assignAnotherProcedure =
      //   this.activeQueue.procedureQueue.modules.includes(
      //     "assign-new-procedure"
      //   );
      // if (assignAnotherProcedure) {
      //   const procedureTemplate = this.procedureTemplates.filter((s) =>
      //     s.originServices.includes(this.activeQueue.serviceId)
      //   );
      //   if (procedureTemplate && procedureTemplate.length > 0) {
      //     this.assignProcedure(procedureTemplate, assignAnotherProcedure);
      //   } else {
      //     this.completeQueueProcedure(bodyForm);
      //   }
      // } else {
      //   this.completeQueueProcedure(bodyForm);
      // }

      if (data && data.modules.includes("assign-new-procedure")) {
        const procedureTemplates = this.procedureTemplates.filter((template) => { return template.originServices.includes(aQueue.serviceId) });
        if (procedureTemplates.length > 0) {
          this.assignProcedure(procedureTemplates, true, bodyForm);
        } else {
          this.completeQueueProcedure(bodyForm);
        }
      } else {
        this.completeQueueProcedure(bodyForm);
      }
    }
  }

  assignProcedure(procedureTemplates, assignAnother?, bodyForm?) {
    let title = "Assign to Procedure";
    let subTitle = "Please select where the queue will be added next.";
    if (assignAnother) {
      title = "Assign Another Procedure";
      subTitle = `Queue <b>${this.activeQueue.queueNo}</b> has been completed <b>${this.activeQueue.procedureQueue.procedureTemplate.displayName}</b>. <br>Select next procedure below.`;
    }
    const initialState = {
      title: title,
      subTitle: subTitle,
      procedureTemplates: procedureTemplates,
      allServiceLists: this.AllServiceLists,
    };

    this.modalRef = this.modalService.show(AssignProcedureModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-xl my-0",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if (data === "completeQueue") {
          if (assignAnother) {
            this.doneQueue(true);
          } else {
            this.completeProcedureQueueNowAlert(true);
          }
        } else if (assignAnother) {
          if (data.procedureType === "flexible") {
            this.assignProcedureToQueue(data, false);
            // this.selectNextProcedure(data.procedures, data, true, bodyForm);
          }
          // for TMC
          // else if (data.procedureType === "step-by-step") {
          //   this.assignProcedureToQueue(data, false);
          // }
          else {
            this.completeQueueProcedure(bodyForm, true, data);
          }
        } else {
          this.assignProcedureToQueue(data, assignAnother);
        }
      },
      (err) => {
        return false;
      }
    );
  }

  completeQueueProcedure(
    bodyForm,
    assignNewProcedure?,
    procedureData?,
    nextProcedureId?
  ) {
    this.allButtonsDisabled = true;

    this.httpService
      .patch$(
        `queues/${this.branchId}/${this.activeQueue._id}/complete-procedure`,
        bodyForm
      )
      .subscribe(
        (data) => {
          if (assignNewProcedure) {
            this.assignAnotherProcedure(procedureData, nextProcedureId);
          } else {
            console.log("complete procedure queue", data);
            this.toastr.success(
              `${this.activeQueue.queueNo} has been completed`,
              "Done!", {
              positionClass: 'toast-bottom-right-custom'
            }
            );
            this.dataService.publishDataChange$({
              action: "completed",
              queue: data?.data?._id,
            });
            this.isQueueListsInit = true;
          }
          this.allButtonsDisabled = false;
        },
        (error) => {
          this.allButtonsDisabled = false;
          this.toastr.error("Queue has not been completed", "Failed!", {
            positionClass: 'toast-bottom-right-custom'
          });
        }
      );
  }

  assignAnotherProcedure(procedureData, nextProcedureId?) {
    const bodyForm = {
      procedureType: procedureData.procedureType,
      procedureTemplateId: procedureData._id,
      queueId: this.activeQueue._id,
      modules: procedureData.modules,
      procedures: procedureData.procedures,
      destinationServiceId: nextProcedureId,
    };

    this.httpService
      .post$(
        `queues/${this.branchId}/assign-new-procedure/${this.activeQueue.generalKyooId}`,
        bodyForm
      )
      .subscribe(
        (data) => {
          console.log("add another procedure", data);
          this.toastr.success(
            `${this.activeQueue.queueNo} has been completed`,
            "Success!", {
            positionClass: 'toast-bottom-right-custom'
          }
          );
          this.dataService.publishDataChange$({
            action: "completed",
            queue: data?.data?._id,
          });
          this.isQueueListsInit = true;
          this.allButtonsDisabled = false;
        },
        (error) => {
          this.allButtonsDisabled = false;
          this.toastr.error("Queue has not been completed", "Failed!", {
            positionClass: 'toast-bottom-right-custom'
          });
        }
      );
  }

  editProcedureQueue() {
    const procedureTemplate = this.procedureTemplates.find(
      (x) => x._id === this.activeQueue.procedureQueue.procedureTemplateId
    );
    const initialState = {
      branchId: this.branchId,
      queue: this.activeQueue,
      serviceLists: this.AllServiceLists,
      procedureTemplateData: procedureTemplate,
    };

    this.modalRef = this.modalService.show(EditProceduresModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-xl m-0",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  // Other Actions
  removeQueue() {
    const initialState = {
      title: "Remove this queue?",
      subTitle: `Are you sure you want to remove <b>${this.activeQueue?.queueNo}</b>? This queue will be marked as “no show”.`,
      confirmClass: "btn-danger",
      caption: "Visitor will be notified via SMS if mobile number is provided.",
      confirmAction: "confirmRemoveQueue",
      enableRemarks: this.queueSettings?.featuresEnabled.includes(
        "remarks-on-remove-queue"
      ),
      requireRemarks: this.queueSettings?.featuresEnabled.includes(
        "require-all-remarks-fields"
      ),
    };
    this.modalRef = this.modalService.show(ModalConfirmComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  confirmRemoveQueue(remarks) {
    const bodyForm = {
      windowId: this.currentWindow,
      remarks: remarks,
    };
    this.httpService
      .patch$(
        `queues/${this.branchId}/${this.selectedQueueId}/remove`,
        bodyForm
      )
      .subscribe(
        (data) => {
          console.log("remove queue", data);
          const queueNo = data.data.queueNo;
          this.toastr.error(`${queueNo} has been removed`, "Removed!", {
            positionClass: 'toast-bottom-right-custom'
          });
          this.dataService.publishDataChange$({
            action: "removed",
            queue: data?.data?._id,
          });
          this.isQueueListsInit = true;
        },
        (error) => {
          this.toastr.error("Queue has not been removed", "Failed!", {
            positionClass: 'toast-bottom-right-custom'
          });
        }
      );
  }

  returnQueue() {
    const initialState = {
      title: "Return this queue?",
      subTitle: `Are you sure you want to return <b>${this.activeQueue?.queueNo}</b> back to the ongoing queue list?`,
      confirmClass: "btn-primary-a2",
      caption: "Visitor will be notified via SMS if mobile number is provided.",
      confirmAction: "confirmReturnQueue",
      enableRemarks: this.queueSettings?.featuresEnabled.includes(
        "remarks-on-return-queue"
      ),
      requireRemarks: this.queueSettings?.featuresEnabled.includes(
        "require-all-remarks-fields"
      ),
    };
    this.modalRef = this.modalService.show(ModalConfirmComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  confirmReturnQueue(remarks) {
    const bodyForm = {
      windowId: this.currentWindow,
      remarks: remarks,
    };
    this.httpService
      .patch$(
        `queues/${this.branchId}/${this.selectedQueueId}/return`,
        bodyForm
      )
      .subscribe(
        (data) => {
          console.log("return queue", data);
          const queueNo = data.data.queueNo;
          this.toastr.warning(
            `${queueNo} has been returned back to the ongoing queue list`,
            "Return!", {
            positionClass: 'toast-bottom-right-custom'
          }
          );
          this.dataService.publishDataChange$({
            action: "returned",
            queue: data?.data?._id,
          });
        },
        (error) => {
          this.toastr.error("Queue has not been skipped", "Failed!", {
            positionClass: 'toast-bottom-right-custom'
          });
        }
      );
  }

  skipQueue() {
    const initialState = {
      title: "Skip this queue?",
      subTitle: `Are you sure you want to skip <b>${this.activeQueue?.queueNo}</b>? You can still complete this queue once the visitor is available.`,
      confirmClass: "btn-primary-a2",
      caption: "Visitor will be notified via SMS if mobile number is provided.",
      confirmAction: "confirmSkipQueue",
      enableRemarks: this.queueSettings?.featuresEnabled.includes(
        "remarks-on-skip-queue"
      ),
      requireRemarks: this.queueSettings?.featuresEnabled.includes(
        "require-all-remarks-fields"
      ),
    };

    console.log("Skip Queue: ", initialState)
    this.modalRef = this.modalService.show(ModalConfirmComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  confirmSkipQueue(remarks) {
    const bodyForm = {
      windowId: this.currentWindow,
      remarks: remarks,
    };
    this.httpService
      .patch$(`queues/${this.branchId}/${this.selectedQueueId}/skip`, bodyForm)
      .subscribe(
        (data) => {
          console.log("skip queue", data);
          const queueNo = data.data.queueNo;
          this.toastr.warning(`${queueNo} has been skipped`, "Skipped!", {
            positionClass: 'toast-bottom-right-custom'
          });
          this.dataService.publishDataChange$({
            action: "skip",
            queue: data?.data?._id,
          });
        },
        (error) => {
          this.toastr.error("Queue has not been skipped", "Failed!", {
            positionClass: 'toast-bottom-right-custom'
          });
        }
      );
  }

  holdQueue() {
    const initialState = {
      title: "Hold this queue?",
      subTitle: `Are you sure you want to hold <b>${this.activeQueue?.queueNo}</b>? The waiting time for this queue will paused until it is resumed.`,
      confirmClass: "btn-primary-a2",
      caption: "Visitor will be notified via SMS if mobile number is provided.",
      confirmAction: "confirmHoldQueue",
      enableRemarks: this.queueSettings?.featuresEnabled.includes(
        "remarks-on-hold-queue"
      ),
      requireRemarks: this.queueSettings?.featuresEnabled.includes(
        "require-all-remarks-fields"
      ),
    };
    this.modalRef = this.modalService.show(ModalConfirmComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  confirmHoldQueue(remarks) {
    const bodyForm = {
      windowId: this.currentWindow,
      remarks: remarks,
    };
    this.httpService
      .patch$(`queues/${this.branchId}/${this.selectedQueueId}/hold`, bodyForm)
      .subscribe(
        (data) => {
          console.log("hold queue", data);
          const queueNo = data.data.queueNo;
          this.toastr.warning(`${queueNo} is being on hold`, "On Hold!", {
            positionClass: 'toast-bottom-right-custom'
          });
          this.dataService.publishDataChange$({
            action: "hold",
            queue: data?.data?._id,
          });
        },
        (error) => {
          this.toastr.error("Queue has not been held", "Failed!", {
            positionClass: 'toast-bottom-right-custom'
          });
        }
      );
  }

  resumeQueue() {
    const bodyForm = {
      windowId: this.currentWindow,
    };
    this.httpService
      .patch$(
        `queues/${this.branchId}/${this.selectedQueueId}/resume`,
        bodyForm
      )
      .subscribe(
        (data) => {
          console.log("resume queue", data);
          const queueNo = data.data.queueNo;
          this.toastr.success(`${queueNo} has been resumed`, "Resumed!", {
            positionClass: 'toast-bottom-right-custom'
          });
          this.dataService.publishDataChange$({
            action: "resume",
            queue: data?.data?._id,
          });
        },
        (error) => {
          this.toastr.error("Queue has not been resumed", "Failed!", {
            positionClass: 'toast-bottom-right-custom'
          });
        }
      );
  }

  transferServiceQueue() {
    const initialState = {
      title: "Transfer to Another Service",
      subTitle: `Please select where to transfer <b>${this.activeQueue?.queueNo}</b>? Transfered queues will be moved on the ongoing queue list of the selected service.`,
      confirmClass: "btn-danger",
      caption: "Visitor will be notified via SMS if mobile number is provided.",
      confirmAction: "confirmRemoveQueue",
      currentWindow: this.currentWindow,
      selectedQueueId: this.selectedQueueId,
      currentServiceId: this.activeQueue?.serviceId,
      branchId: this.branchId,
      enableRemarks: this.queueSettings?.featuresEnabled.includes(
        "remarks-on-transfer-queue"
      ),
      requireRemarks: this.queueSettings?.featuresEnabled.includes(
        "require-all-remarks-fields"
      ),
    };
    this.modalRef = this.modalService.show(QueueTransferServiceModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  transferWindowQueue() {
    const availableWindows = this.windowList.filter(
      (x) => !x.suspended && x.attendingEmployee && x._id !== this.currentWindow
    );
    console.log(availableWindows);
    if (availableWindows && availableWindows.length <= 0) {
      const initialState = {
        title: "Transfer to Another Window",
      };
      this.modalRef = this.modalService.show(NoAttendingStaffModalComponent, {
        initialState: initialState,
        class: "modal-dialog-centered",
        ignoreBackdropClick: true,
        keyboard: false,
      });
    } else {
      const initialState = {
        title: "Transfer to Another Window",
        subTitle: `Please select where to transfer <b>${this.activeQueue?.queueNo}</b>? Transfered queues will be moved on the current serving list of the selected window.`,
        caption:
          "Note: Please advise the visitor regarding the window transfer.",
        allWindows: this.windowList,
        currentWindow: this.currentWindow,
        selectedQueue: this.activeQueue,
        selectedQueueId: this.selectedQueueId,
        branchId: this.branchId,
        type: "single",
        enableRemarks: this.queueSettings?.featuresEnabled.includes(
          "remarks-on-transfer-queue"
        ),
        requireRemarks: this.queueSettings?.featuresEnabled.includes(
          "require-all-remarks-fields"
        ),
      };
      this.modalRef = this.modalService.show(
        QueueTransferWindowModalComponent,
        {
          initialState: initialState,
          class: "modal-dialog-centered",
          ignoreBackdropClick: true,
          keyboard: false,
        }
      );
    }
  }

  notifyQueue() {
    this.httpService
      .patch$(`queues/${this.branchId}/${this.selectedQueueId}/notify`, "")
      .subscribe(
        (data) => {
          this.toastr.success(
            `${this.activeQueue?.queueNo} has been notified`,
            "Notified!", {
            positionClass: 'toast-bottom-right-custom'
          }
          );
          console.log("notify queue", data);
          // for TMC
          if (this.dataService.accountData$ && ([
            "a597d9a2-c2dd-40e7-b05f-47114be30f12", //??
            "0bb706ca-6f00-42a1-af36-431770829e90", //dev
            "a94bf1a5-5677-425e-87ba-a0ae9078ae22", //staging
            "e42367dc-f0b0-4e6e-8a4b-819cf9d50262"  //demo
          ].includes(this.dataService.accountData$.data.businessId))) {
            if (data && data.data.notifiedCount === 2) {
              this.confirmSkipQueue("Notified twice");
            }
          }
        },
        (error) => {
          this.toastr.error(`${error.error.errors.msg}`, "Notification Error!", {
            positionClass: 'toast-bottom-right-custom'
          });
          console.log("notify queue", error);
        }
      );
  }
}
