import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AuthService } from "src/app/core/auth/auth.service";
import { DataService } from "src/app/core/services/data.service";
import { BusinessService } from "src/app/modules/auth/services/business.service";
import { BranchManagementService } from "src/app/modules/branch-management/services/branch-management.service";
import { PaymentModalComponent } from "../../components/payment-modal/payment-modal.component";
import { BillingService } from "../../services/billing.service";
import { PaymentService } from "../../services/payment.service";

@Component({
  selector: "app-subscription-page",
  templateUrl: "./subscription-page.component.html",
  styleUrls: ["./subscription-page.component.scss"],
})
export class SubscriptionPageComponent implements OnInit {
  stripe: any;
  selectedBranch = this.branchService.getSelectedBranch();
  subscription = {
    professional: {
      id: "",
      tier: "Professional",
      description:
        "Advance queueing processes and capabilities for companies. Unlimited users.",
      price: [
        { id: "", value: "55", interval: "month" },
        { id: "", value: "660", interval: "month" },
      ],
      features: [
        "Free 1500 SMS monthly",
        "Procedural Queueing",
        "Team Management",
        "Track your team KPI",
        "Online team onboarding",
        "Dedicated account manager",
        "Custom subdomain and server*",
      ],
    },
    enterprise: {
      id: "",
      tier: "Professional",
      description:
        "Additional control and support for organizations. Custom pricing for your company.",
      price: "Flexible",
      features: [
        "Free 2000 SMS monthly",
        "SMS Masking*",
        "Onsite training (PH 🇵🇭  Only)",
        "Priority in support",
        "Flexible licensing program",
        "Custom subdomain and server*",
        "Custom reports*",
        "Custom TV display templates*",
        "Starts with 6 windows",
        "Starts with 8 services",
        "25,000+ transactions per month",
      ],
    },
    trial: {
      id: "",
      tier: "Free Trial",
      description:
        "All the digital queueing essentials you need. Try it for free!",
      price: "10 Days",
      features: [
        "Online / Virtual queueing",
        "TV display access",
        "SMS Notification*",
        "Reports",
        "Team management",
        "Manager and Staff Dashboard",
      ],
    },
  };
  accountId = this.authService.accountId$;
  isYearly = false;

  subscriptionPlans: any;
  isSubmitting = false;
  isLoading = false;
  subscriptionData = this.dataService.getSubscriptionPlan();
  storedSubscription = this.dataService.getSubscriptionPlan();
  daysRemaining = "";
  subscribed = false;

  subscribeButton = true;

  constructor(
    private branchService: BranchManagementService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private paymentService: PaymentService,
    private dataService: DataService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private billingService: BillingService,
    private businessService: BusinessService
  ) {}

  async ngOnInit(): Promise<void> {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params && params.type === "new") {
        this.subscribe(params.productId);
      } else {
        this.getProductList();
      }
    });

    this.getNumberOfDays();
  }

  subscriptionPlan() {
    this.isSubmitting = true;
    this.billingService
      .createCustomerPortal(this.selectedBranch.businessId, {})
      .subscribe(
        (data) => {
          window.location.href = data.data.url;
        },
        (error) => {
          this.isSubmitting = false;
          console.log(error);
        }
      );
  }

  checkOut(data) {
    this.isSubmitting = true;
    const price = data.price.find(
      (x) => x.interval === (this.isYearly ? "year" : "month")
    ).id;

    const body = {
      priceId: price,
      successUrl: `subscription?type=new&productId=${price}`,
      cancelUrl: `subscription`,
    };

    this.paymentService
      .createCheckOutSession(this.selectedBranch.businessId, body)
      .subscribe(
        (data) => {
          window.location.href = data.data.url;
        },
        (error) => {
          this.isSubmitting = false;
          console.log(error);
        }
      );
  }

  toggle(value) {
    this.isYearly = value === "yearly";
  }

  getProductList() {
    this.isLoading = true;
    this.paymentService.getPriceList().subscribe(
      (res) => {
        if (res && res.data) {
          const professional = res.data.find(
            (x) => x.name === "Professional" || x.name === "professional"
          );
          const monthly = professional.pricingList.findIndex(
            (x) => x.recurring.interval === "month"
          );
          const yearly = professional.pricingList.findIndex(
            (x) => x.recurring.interval === "year"
          );

          const daily = professional.pricingList.findIndex(
            (x) => x.recurring.interval === "day"
          );

          let plan = {
            id: professional.id,
            tier: professional.name,
            description: professional.description,
            price: [
              {
                id: professional.pricingList[monthly]?.id,
                value: professional.pricingList[
                  monthly
                ]?.unit_amount_decimal.slice(0, -2),
                interval:
                  professional.pricingList[monthly]?.recurring?.interval,
              },
              {
                id: professional.pricingList[yearly]?.id,
                value: professional.pricingList[
                  yearly
                ]?.unit_amount_decimal.slice(0, -2),
                interval: professional.pricingList[yearly]?.recurring?.interval,
              },
              {
                id: professional.pricingList[daily]?.id,
                value: professional.pricingList[
                  daily
                ]?.unit_amount_decimal.slice(0, -2),
                interval: professional.pricingList[daily]?.recurring?.interval,
              },
            ],
            features: professional.metadata.feature_list
              ? professional.metadata.feature_list.split(";")
              : [],
          };

          this.subscription.professional = plan;

          this.isYearly = this.storedSubscription.interval === "year";

          const daysRemaining = this.businessService.getDaysRemaining(
            this.storedSubscription.startDate,
            this.storedSubscription.endDate,
            this.storedSubscription.cancelled,
          );

          if (Math.round(daysRemaining.consumedHours) <= 0) {
            this.subscribeButton = true;
          } else if (Math.round(daysRemaining.consumedHours) > 0 && this.storedSubscription.plan?.toLowerCase() !== 'trial') {
            this.subscribeButton = false;
          }

          this.isLoading = false;
        }
      },
      (error) => {
        this.isLoading = false;
        console.log("PRODUCT LIST ERROR", error);
      }
    );
  }

  subscribe(id) {
    const initialState = {
      title: "Payment",
      productId: id,
    };

    this.modalRef = this.modalService.show(PaymentModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-xl m-0",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        // console.log("payment", data);
      },
      (err) => {
        console.log("payment", err);
        return false;
      }
    );
  }

  contactSales() {
    window.open("https://info.kyoo.com/contact", "blank");
  }

  getNumberOfDays() {
    this.subscribed = this.storedSubscription.plan?.toLowerCase() !== "trial";

    const daysRemaining = this.businessService.getDaysRemaining(
      this.storedSubscription.startDate,
      this.storedSubscription.endDate,
      this.storedSubscription.cancelled,
      );
    if (Math.round(daysRemaining.consumedHours) <= 0) {
      this.daysRemaining = "Expired";
    } else {
      this.daysRemaining =
        Math.round(daysRemaining.daysConsumed) < 1
          ? `${Math.round(daysRemaining.consumedHours)} hour/s remaining`
          : `${Math.round(daysRemaining.daysConsumed)} day/s remaining`;
    }
  }
}
