import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-edit-branch-note-modal',
  templateUrl: './edit-branch-note-modal.component.html',
  styleUrls: ['./edit-branch-note-modal.component.scss']
})
export class EditBranchNoteModalComponent implements OnInit {

  title: string;
  branchNote: string;

  @Output() successEvent = new EventEmitter();

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  confirm(){
    this.successEvent.emit(this.branchNote);
    this.bsModalRef.hide();
  }

}
