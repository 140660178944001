import { Component, OnInit, HostListener, Output, EventEmitter } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { BranchManagementService } from "../../services/branch-management.service";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { DatePickerModalComponent } from "src/app/shared/components/date-picker-modal/date-picker-modal.component";
import { ToastrService } from "ngx-toastr";
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: "app-add-special-schedule",
  templateUrl: "./add-special-schedule.component.html",
  styleUrls: ["./add-special-schedule.component.scss"],
})
export class AddSpecialScheduleComponent implements OnInit {
  isLoading = false;
  isConfirmDisabled: boolean = true;
  branchId: any;
  title: any;
  subTitle: any;
  hoursLength = 1;
  dropdownList = [
    { label: "7:00 AM",  value: this.getMilliseconds(7, 0),  disabled: false, index: 7 },  //0
    { label: "8:00 AM",  value: this.getMilliseconds(8, 0),  disabled: false, index: 8 },  //1
    { label: "9:00 AM",  value: this.getMilliseconds(9, 0),  disabled: false, index: 9 },  //2
    { label: "10:00 AM", value: this.getMilliseconds(10, 0), disabled: false, index: 10 }, //3
    { label: "11:00 AM", value: this.getMilliseconds(11, 0), disabled: false, index: 11 }, //4
    { label: "12:00 PM", value: this.getMilliseconds(12, 0), disabled: false, index: 12 }, //5
    { label: "1:00 PM",  value: this.getMilliseconds(13, 0), disabled: false, index: 13 }, //6
    { label: "2:00 PM",  value: this.getMilliseconds(14, 0), disabled: false, index: 14 }, //7
    { label: "3:00 PM",  value: this.getMilliseconds(15, 0), disabled: false, index: 15 }, //8
    { label: "4:00 PM",  value: this.getMilliseconds(16, 0), disabled: false, index: 16 }, //9
    { label: "5:00 PM",  value: this.getMilliseconds(17, 0), disabled: false, index: 17 }, //10
    { label: "6:00 PM",  value: this.getMilliseconds(18, 0), disabled: false, index: 18 }, //11
    { label: "7:00 PM",  value: this.getMilliseconds(19, 0), disabled: false, index: 19 }, //12
    { label: "8:00 PM",  value: this.getMilliseconds(20, 0), disabled: false, index: 20 }, //13
    { label: "9:00 PM",  value: this.getMilliseconds(21, 0), disabled: false, index: 21 }, //14
    { label: "10:00 PM", value: this.getMilliseconds(22, 0), disabled: false, index: 22 }, //15
    { label: "11:00 PM", value: this.getMilliseconds(23, 0), disabled: false, index: 23 }, //16
    { label: "12:00 AM", value: this.getMilliseconds(24, 0), disabled: false, index: 24 }, //17
    { label: "1:00 AM",  value: this.getMilliseconds(1, 0),  disabled: false, index: 1 },  //18
    { label: "2:00 AM",  value: this.getMilliseconds(2, 0),  disabled: false, index: 2 },  //19
    { label: "3:00 AM",  value: this.getMilliseconds(3, 0),  disabled: false, index: 3 },  //20
    { label: "4:00 AM",  value: this.getMilliseconds(4, 0),  disabled: false, index: 4 },  //21
    { label: "5:00 AM",  value: this.getMilliseconds(5, 0),  disabled: false, index: 5 },  //22
    { label: "6:00 AM",  value: this.getMilliseconds(6, 0),  disabled: false, index: 6 },  //23
  ];
  selectedValue: string;
  isSelectClicked: boolean;
  isButtonClicked: boolean;
  isFocused: boolean;
  startDateLabel = "7/1/2022";
  endDateLabel = "7/1/2022";
  today = new Date();
  startDate: any;
  endDate: any;
  isDateRange = false;
  @Output() successEvent = new EventEmitter();
  formValid = true;

  public specialScheduleForm = this.fb.group({
    name: ["", [Validators.required]],
    // startDate: [moment(this.today).format('L'), [Validators.required]],
    // endDate: [moment(this.today).format('L')],
    description: [""],
    businessHours: this.fb.array([this.addHoursGroup(this.dropdownList)])
  });

  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    public modalRef2: BsModalRef,
    private branchManagementService: BranchManagementService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.startDate = moment(this.today).format('L');
    this.endDate = moment(this.today).format('L');
    console.log(this.specialScheduleForm.value.businessHours);
    this.checkHours();
    this.isLoading = false;
  }

  addDateRange() {
    this.isDateRange = !this.isDateRange;
  }

  addHoursGroup(hourList): FormGroup {
    return this.fb.group({
      openingOptions: [hourList],
      closingOptions: [],
      openingIndex: '',
      closingIndex: ''
    });
  }

  //Add Fields
  addHours(): void {
    const hourList = _.cloneDeep(this.dropdownList);

    for (let arrayIndex = 0; arrayIndex < this.hoursLength; arrayIndex++) {
      const opening = this.hoursArray.controls[arrayIndex].get('openingIndex').value;
      const closing = this.hoursArray.controls[arrayIndex].get('closingIndex').value;

      for (let index = parseInt(opening); index <= parseInt(closing); index++) {
        const hourIndex = hourList.findIndex(x => x.index === index);
        hourList[hourIndex].disabled = true;
      }
    }

    this.hoursArray.push(this.addHoursGroup(hourList));
    this.hoursLength += 1;
  }

  //Remove Fields
  removeHours(index: number): void {
    this.hoursArray.removeAt(index);
    this.hoursLength -= 1;
  }

  onOpeningTimeChange(index, value){
    console.log(index, value)
    
    const hourList = _.cloneDeep(this.hoursArray.controls[index].get('openingOptions').value)
    const i = hourList.findIndex(x => x.index === parseInt(value));

    hourList[i].disabled = true;

    this.hoursArray.controls[index].get('closingOptions').setValue(hourList);
    console.log(this.dropdownList)
  }

  get hoursArray(): FormArray {
    return this.specialScheduleForm.get("businessHours") as FormArray;
  }

  checkHours() {
    const index = this.hoursArray.value.findIndex(el => el.openingIndex === '' || el.closingIndex === '');
    if(index > -1){
      return true;
    } 
    if(!(moment(this.startDate).isSame(this.endDate))){
      if(moment(this.startDate).isAfter(this.endDate)){
        return true;
      }
    } 
    return false;
  }

  onSubmit() {
    this.isLoading = true;
    const formValue = this.specialScheduleForm.value;
    const newArr = [];
    for(var j=0;j<formValue.businessHours.length;j++){
      newArr.push({
        openingTime: this.getMilliseconds(formValue.businessHours[j].openingIndex, 0), 
        closingTime: this.getMilliseconds(formValue.businessHours[j].closingIndex, 0)
      });
    }
    const body = {
      day: 0,
      displayName: formValue.name,
      startDate: moment.utc(this.startDate).valueOf(),
      endDate: this.isDateRange ? moment.utc(this.endDate).valueOf() : moment.utc(this.endDate).valueOf(),
      operationHours: newArr,
      description: formValue.description,
    };
    console.log(body);
    this.isLoading = true;
    this.branchManagementService
      .addSpecialSchedule(this.branchId, body)
      .subscribe(
        (res) => {
          console.log(res);
          this.successEvent.emit(res)
          this.modalRef.hide();
          this.toastr.success("Special schedule has been added", "Success!");
        },
        (error) => {
          console.log(error);
          let errMsg = error.error.errors[0].msg;
          this.toastr.error(errMsg, "Error!");
          this.isLoading = false;
        }
      );
  }

  getMilliseconds(hour?: number, minutes?: number) {
    var result = (60 * 1000 * 60 * hour ?? 1) + (60 * 1000 * minutes ?? 1);
    // console.log(result);
    return result;
  }

  select() {
    this.isSelectClicked = !this.isSelectClicked;
    this.isFocused = !this.isFocused;
  }

  @HostListener("document:click")
  deselect() {
    if (!this.isSelectClicked || this.isButtonClicked) {
      this.isFocused = false;
    }
    this.isButtonClicked = false;
    this.isSelectClicked = false;
  }

  get f() {
    return this.specialScheduleForm.controls;
  }

  confirm() {}

  cancel() {}

  selectDate(type) {
    const initialState = {
      title: "Select start date",
      datePickerType: "single",
      type: type,
      hasMinDate: true
    };
    this.modalRef2 = this.modalService.show(DatePickerModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef2.content.selectedDateRange.subscribe(
      (data) => {
        if (type === "start") {
          this.startDateLabel = (data as Date).toLocaleDateString("en-US");
          this.startDate = moment(data).format('L');
        } else {
          this.endDateLabel = (data as Date).toLocaleDateString("en-US");
          this.endDate = moment(data).format('L');
        }
        console.log(this.specialScheduleForm.value);
      },
      (err) => {
        return false;
      }
    );
  }
}
