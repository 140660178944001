import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpService } from 'src/app/core/http/http.service';
import { DataService } from 'src/app/core/services/data.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { ServiceWindowService } from '../../services/service-window.service';

@Component({
  selector: 'app-service-assign-windows-form',
  templateUrl: './service-assign-windows-form.component.html',
  styleUrls: ['./service-assign-windows-form.component.scss']
})
export class ServiceAssignWindowsFormComponent extends ModalService implements OnInit {
  serviceData: any;
  serviceId: string;
  windowLists: Array<any>;
  assignedWindowStringLists: Array<string>;
  assignedWindowLists: Array<any>;
  assignedWindowIds: Array<any>;
  allWindows: Array<any>;
  isConfirmDisabled: boolean;
  isAllowAllWindow: boolean;
  newWindowLists: Array<any>;
  confirmText: string;
  cancelText: string;
  featureBodyForm: any;
  branchId: string;
  isChanged: boolean = false;
  isSubmitting: boolean = false;
  activeQueue: number = 0;
  disableSwitch: boolean = false;

  constructor(
    bsModalRef: BsModalRef,
    httpService: HttpService,
    dataService: DataService,
    toastr: ToastrService,
    authService: AuthService,
    private serviceWindowService: ServiceWindowService,
    private cdr: ChangeDetectorRef
  ) {
    super(bsModalRef, httpService, dataService, toastr, authService)
  }

  getWindowList() {
    this.httpService.get$(`services/windows/${this.branchId}?limit=999`).subscribe(
      data => {
        this.windowLists = data.data.map(element => {
          return {
            label: element.displayName,
            _id: element._id,
            disabled: element.suspended ? true : this.checkWindowDisable(element._id)
          }
        });

        this.assignedWindowLists = this.windowLists.filter(element => this.assignedWindowStringLists.includes(element._id));
        this.newWindowLists = this.assignedWindowLists;
        this.disableSwitch = this.windowLists.some(obj => obj.disabled === true);
      }
    )
  }

  checkWindowDisable(windowId: string) {
    if (this.assignedWindowIds.includes(windowId) && this.getActiveCountValue(windowId)) {
      return true;
    } else {
      return false;
    }
  }

  getActiveCountValue(windowId: string): boolean {
    if (windowId) {
      const window = this.allWindows?.find(w => w?._id === windowId);
      if (window) {
        return window?.currentServing > 0 ? true : false;
      }
    }
  }

  changeCheckbox($event) {
    if ($event?.value !== undefined) {
      this.isChanged = true;
      const checked = $event.checkedArray;
      this.newWindowLists = this.windowLists.filter((element, index) => checked.includes(index));
      this.checkDisableConfirm();
      this.cdr.detectChanges();
    }
  }

  allowAllWindow($event) {
    this.isAllowAllWindow = $event;
    this.checkDisableConfirm();
    const feature = 'allowed-all-window-to-serve-queue';
    const dataArray = this.serviceData.enabledFeatures;
    const value = $event;
    const featuresArray = this.serviceWindowService.addDeleteFeature(dataArray, feature, value);
    this.featureBodyForm = { enabledFeatures: featuresArray };
    this.cdr.detectChanges();
  }

  confirm($event) {
    if ($event) {
      this.isSubmitting = true;
      const param = `services/${this.branchId}/${this.serviceId}`;
      const bodyForm = {
        ...this.featureBodyForm,
        assignedWindows: this.newWindowLists.map(element => element._id)
      }
      this.serviceWindowService.submitPatchForm(param, bodyForm).then(data => {
        this.dataService.publishDataChange$({
          action: "assigned windows",
          data: data.data.assignedWindows,
        });
        this.bsModalRef.hide();
      });
    }
  }

  checkDisableConfirm() {
    this.isConfirmDisabled = this.newWindowLists.length === 0 && !this.isAllowAllWindow ? true : false;
  }

  ngOnInit(): void {
    this.isSubmitting = false;
    this.isConfirmDisabled = true;
    this.isAllowAllWindow = this.serviceData.enabledFeatures.includes('allowed-all-window-to-serve-queue');
    this.getWindowList();
    this.assignedWindowIds = this.serviceData.assignedWindows.map(element => {
      return element._id;
    });
  }

}
