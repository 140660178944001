import { Component, OnInit } from "@angular/core";
import { OnboardingCategory } from "../../../interfaces/support.interface";
@Component({
  selector: "app-support-onboarding-page",
  templateUrl: "./support-onboarding-page.component.html",
  styleUrls: ["./support-onboarding-page.component.scss"],
})
export class SupportOnboardingPageComponent implements OnInit {
  helpCenterCategories: string = "";
  displayHelpCategories: boolean = true;
  pageDetails = [
    { type: "main", name: "Support", routerLink: ["/support"] },
    { type: "current", name: "Onboarding", routerLink: null },
];
  onBoardingCategory: Array<OnboardingCategory> = [
    {
      id: 0,
      imagesrc: './assets/img/icons/cards/setup-account.png',
      title: 'Setup your Account',
      description: 'Register for a Kyoo Business portal account',
      link: "https://www.youtube.com/watch?si=tpGvkV613SjmrtHt&v=APmLmIqGbFc&feature=youtu.be"
    },
    {
      id: 1,
      imagesrc: './assets/img/icons/cards/how-to-invite.png',
      title: 'Add Teams',
      description: 'Invite team members who will help you manage queues',
      link: "https://www.youtube.com/watch?si=DoqFR_yX4BfDDoSm&v=vXlOpszxYik&feature=youtu.be"
    },
    {
      id: 2,
      imagesrc: './assets/img/icons/cards/configure-visitor.png',
      title: 'Setup Services and Windows',
      description: 'Discover how to add and manage services and windows',
      link: "https://www.youtube.com/watch?si=DpOTIpEZlICHpJ-x&v=tA8k4xWjN3Q&feature=youtu.be"
    },
    {
      id: 3,
      imagesrc: './assets/img/icons/cards/setup-branch.png',
      title: 'Setup Branch Site',
      description: 'Find out how you can allow your customers to queue online',
      link: "https://www.youtube.com/watch?si=z57nuHjYLo-I1dUW&v=NjmQF99qXww&feature=youtu.be"
    },
    {
      id: 4,
      imagesrc: './assets/img/icons/cards/setup-tv-display.png',
      title: 'Setup TV Display',
      description: 'Connect your TV desplay to your Business Portal',
      link: "https://www.youtube.com/watch?si=7H6AzB1zEs2DoPY-&v=k1YsRN5DBmY&feature=youtu.be"
    },
    {
      id: 5,
      imagesrc: './assets/img/icons/cards/setup-branch.png',
      title: 'Setup Store Branches',
      description: 'Learn how to add and manage new branches',
      link: "https://www.youtube.com/watch?si=GA4ZF-p7gV_UYwkH&v=InORTbpQCMw&feature=youtu.be"
    }
  ]

  constructor() {}

  helpCenterBtn(): void {
    this.displayHelpCategories = true;
    this.helpCenterCategories = "";
  }

  selectCategory(id) {
     
  }

  ngOnInit(): void {
    this.onBoardingCategory;
  }

}
