
import { Component, EventEmitter, HostListener, OnInit, Output, OnDestroy } from '@angular/core'
import { HttpParams } from '@angular/common/http'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { DatePickerModalComponent } from 'src/app/shared/components/date-picker-modal/date-picker-modal.component'
import { TitleCasePipe, DatePipe } from '@angular/common'
import { Subscription } from 'rxjs'
import { DataService } from 'src/app/core/services/data.service'
import { HttpService } from 'src/app/core/http/http.service'
import { QueueHistoryService } from 'src/app/modules/queue-history/services/queue-history.service'
import { TeamManagementService } from 'src/app/modules/team-management/services/team-management.service'
import { TimePipe } from 'src/app/shared/pipes/time.pipe'
import * as moment from 'moment'
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";

@Component({
  selector: 'app-queue-history-filter',
  templateUrl: './queue-history-filter.component.html',
  styleUrls: ['./queue-history-filter.component.scss'],
  providers: [TitleCasePipe, DatePipe]
})
export class QueueHistoryFilterComponent implements OnInit {
  accountData = this.dataService.accountData$
  isLoading: boolean = false;
  isDownloading: boolean = false
  reportId: string = ''
  title: string
  subtitle: string
  description: string
  selectedDateFilter = "today"
  selectedDateFilterLabel = "Today"
  customDate1: any
  customDate2: any
  @Output() generate = new EventEmitter()
  subscriptions = new Subscription()
  isFocused = false
  isSelectClicked: boolean
  isButtonClicked: boolean
  selectedValue = "today"
  selectedLabel = "Today"
  selectedWindows: any
  selectedStatus: any
  branchId: string
  reportType: string
  accountList: Array<any> = [];
  serviceLists: Array<any> = [];
  serviceWindowLists: any
  filterType: string = ''
  sourceFilter: string[] = []
  clear: boolean = false
  customErrMsg: string = ''
  columnLabels: string[] = []
  columnValues: any[] = []
  newReportData: any[] = []
  branchSite: string = 'branch-site'
  branchSiteLabel: string = 'Branch Site (Online)'
  dateFilter = [
    {
      value: "today",
      label: "Today",
    },
    {
      value: "yesterday",
      label: "Yesterday",
    },
    {
      value: "this-week",
      label: "This Week",
    },
    {
      value: "this-month",
      label: "This Month",
    },
    // {
    //   value: "this-year",
    //   label: "This Year",
    // },
    {
      value: "custom",
      label: "Custom",
    },
  ]

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRef2: BsModalRef,
    private modalService: BsModalService,
    private titleCasePipe: TitleCasePipe,
    private dataService: DataService,
    private httpService: HttpService,
    private teamService: TeamManagementService,
    private queueHistoryService: QueueHistoryService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.getAccounts();
    this.getServiceLists();
    this.isLoading = true;
  }


  clearFilter(){
    this.clear = !this.clear;
    this.select();
    this.deselect();
    this.selectDropdown({value: "today", label: "Today"})
    this.customErrMsg = '';
  }

  getAccounts() {
    this.subscriptions.add(
      this.teamService.getTeamList("", this.branchId).subscribe((res) => {
        this.accountList = res.data;
      }, (error) => {
        console.log('error on fetching account lists', error)
        this.isLoading = false;
      })
    );
  }

  getServiceLists() {
    this.subscriptions.add(
      this.httpService
      .get$(`services/${this.branchId}?limit=999`)
      .subscribe((data) => {
        this.serviceLists = data.data.map((element) => {
          return {
            label: element.displayName,
            id: element._id
          };
        });
      }, (error) => {
        console.log('error on fetching service lists', error)
        this.isLoading = false;
      })
    );
  }

  getServiceName(serviceId: string) {
    let name = "";
    if (serviceId && this.serviceLists) {
      const s = this.serviceLists.find((x) => x.id === serviceId);
      if (s) {
        name = s.label;
      }
    }

    return name;
  }

  getAccountName(accountId: string) {
    let name = "";
    if (accountId && this.accountList) {
      const account = this.accountList.find((x) => x._id === accountId);
      if (account) {
        name = account.firstName + " " + account.lastName;
      }
    }

    return name != '' ? this.titleCasePipe.transform(name) : '';
  }

  generateReport() {
    this.isDownloading = true;
    let params = new HttpParams()
    params = params.append("filterBy", this.selectedValue)

    if (this.selectedValue === "custom") {
      params = params.append("startDate", new Date(this.customDate1).getTime().toString())
      if (this.customDate1 == this.customDate2) {
        let endDate = new Date(this.customDate2).setHours(23, 59, 59);
        params = params.append("endDate", new Date(endDate).getTime().toString())
      } else {
        params = params.append("endDate", new Date(this.customDate2).getTime().toString())
      }
    }

    this.getQueueHistoryData(params)
  }

  getQueueHistoryData(params: HttpParams) {
    this.subscriptions.add(
      this.queueHistoryService.getQueueHistoryReportsDownload(params, this.branchId).subscribe(res => {
        if (res && res.data) {
          this.exportQueueHistoryCSV(res.data);
        }
      }, (error) => {
        console.log('error on fetching queue history data', error)
        this.isDownloading = false;
      })
    );
  }

  exportQueueHistoryCSV(list: any) {
    const arrayData = list.map((element) => {
      return {
        queueNo: element.queueNo,
        servedBy: this.getAccountName(element.servedBy),
        serviceName: element.serviceName ? element.serviceName : this.getServiceName(element.serviceId),
        dateAndTimeQueued: moment(new Date(element.createdAt)).format("MM-DD-yyyy - HH:mm"),
        formattedWaitingTime: element.waitingTime ? this.millisecondsToTime(element.waitingTime) : this.getTimeAgo2(element.createdAt),
        formattedServingTime: element.servingTime ? this.millisecondsToTime(element.servingTime) : '',
        formattedStatus: this.titleCasePipe.transform(element.status),
      };
    });

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: [
        "Queue Number",
        "Served By",
        "Service",
        "Date and Time Queued",
        "Waiting Time",
        "Serving Time",
        "Status",
      ],
    };
    // tslint:disable-next-line: no-unused-expression
    new AngularCsv(arrayData, "Queue History", options);
    this.bsModalRef.hide()
  }

  select() {
    this.isSelectClicked = !this.isSelectClicked
    this.isFocused = !this.isFocused
  }

  @HostListener("document:click")
  deselect() {
    if (!this.isSelectClicked || this.isButtonClicked) {
      this.isFocused = false
    }
    this.isButtonClicked = false
    this.isSelectClicked = false
  }

  selectCustomDateFilter() {
    const initialState = {
      hasMinDate: false,
      datePickerType: 'range',
      customReport: true
    }
    this.bsModalRef2 = this.modalService.show(DatePickerModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered modal-md2",
      ignoreBackdropClick: true,
      keyboard: false
    })

    this.bsModalRef2.content.selectedDateRange.subscribe(
      (data) => {
        if (data) {
          if (data == 'cancel') {
            this.customErrMsg = ''
          } else {
            if (data[0] && data[1]) {
              this.selectedValue = 'custom'
              this.customDate1 = (data[0] as Date).toLocaleDateString("en-US")
              this.customDate2 = (data[1] as Date).toLocaleDateString("en-US")
              this.selectedLabel = this.customDate1 + " - " + this.customDate2
              this.selectedDateFilterLabel = this.customDate1 + " - " + this.customDate2

              const startDate = new Date(this.customDate1)
              const endDate = new Date(this.customDate2)

              const threeMonthsLater = new Date(startDate.getTime()) // Create a new date object with the same time as the start date
              threeMonthsLater.setMonth(startDate.getMonth() + 3) // Add three months to the new date object

              if (endDate > threeMonthsLater) {
                // Do something if the selected dates are invalid
                this.customErrMsg = 'Selected dates must not be more than 3 months'
              } else {
                // Do something if the selected dates are valid
                this.customErrMsg = ''
              }
            } else {
              this.customErrMsg = ''
            }
          }
        }
      },
      (err) => {
        return false
      }
    )
  }

  selectDropdown(list) {
    if (list.value === "custom") {
      this.selectCustomDateFilter()
    } else {
      this.selectedValue = list.value
      this.selectedLabel = list.label
    }
    this.isButtonClicked = true
    if (!this.isFocused) {
      this.isButtonClicked = false
    }
  }

  millisecondsToTime(milliseconds: number) {
    let seconds = Math.floor((milliseconds / 1000) % 60);
    let minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    let hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
    let days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
  
    let time = '';

    if (days == 1) { time += days + ' day '; }
    if (days > 1) { time += days + ' days '; }
    if (hours == 1) { time += hours + ' hour '; }
    if (hours > 1) { time += hours + ' hours '; }
    if (minutes == 1) { time += minutes + ' minute '; }
    if (minutes > 1) { time += minutes + ' minutes '; }
    if (seconds == 1) { time += seconds + ' second '; }
    if (seconds > 1) { time += seconds + ' seconds '; }
    if (time === '') { time = ''; }
  
    return time;
  }

  getTimeAgo(data: number) {
    if (data) {
      const timePipe = new TimePipe();
      return timePipe.transform(data);
    }
  }

  getTimeAgo2(data: number){
    if(data){
      const y = new Date(data).getFullYear();
      const m = new Date(data).getMonth();
      const d = new Date(data).getDate();
      const h = new Date(data).getHours();
      const mm = new Date(data).getMinutes();
      const s = new Date(data).getSeconds();
      const time = moment([y, m, d, h, mm, s]).fromNow(true);

      return time === 'a day' ? '1 day' : time;
    }
  }

  cancel() {
    this.generate.emit(
      {action: 'cancel', selected: this.filterType}
    )
    this.bsModalRef.hide()
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
