import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

interface CheckboxList {
  label: string;
  checked: boolean;
}

@Component({
  selector: 'app-checkbox-list-v3',
  templateUrl: './checkbox-list-v3.component.html',
  styleUrls: ['./checkbox-list-v3.component.scss']
})
export class CheckboxListV3Component implements OnInit  {
  @Input() checkboxListTitle: string = '';
  @Input() checkboxListDetails: Array<any> = [];
  @Input() checkboxListKey: string = '';
  @Input() searchString: string|null
  @Output() changedCheckboxLists = new EventEmitter();
  searchSubject: Subject<string> = new Subject();
  contentsForm: FormGroup;
  selectAllContents: boolean = false;
  filteredCheckbox: Array<any>= [];
  filteredCheckboxLists: Array<any> = [];
  @ViewChildren('customCheckbox', { read: ElementRef }) customCheckboxes: ElementRef[];

  constructor(
    private formBuilder: FormBuilder
  ) { 
    this.contentsForm = this.formBuilder.group({
      contents: this.formBuilder.array([])
    });
  }

  ngOnInit(): void {
    if (this.checkboxListDetails) {
      this.checkboxListDetails = this.checkboxListDetails.map((element) => {
        return {
          label: element.label,
          checked: element.checked
        };
      }),
      this.initForm(this.checkboxListDetails)
    }
    // Initialize filteredCheckboxLists with checkboxListDetails
    this.filteredCheckboxLists = this.checkboxListDetails;
    this.searchSubject
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe((searchTextValue) => {
        // consume the subject value
        this.filterCheckboxLists(searchTextValue);
    });
  }

  filterCheckboxLists(searchText: string) {
    this.filteredCheckboxLists = this.checkboxListDetails.filter((item) =>
      item.label.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  public searchContent() {
    this.searchSubject.next(this.searchString);
  }

  get contents(): FormArray {
    return this.contentsForm.get('contents') as FormArray
  }

  initForm(contents: Array<CheckboxList>): void {
    const contentControls = contents.map(content => this.formBuilder.group(content))

    this.contentsForm = this.formBuilder.group({
      contents: this.formBuilder.array(contentControls)
    });
  }

  selectAll($event): void {
    for (var i=0; i<this.contents.controls.length; ++i) {
      const checkboxGroup = this.contents.controls[i] as FormGroup
      checkboxGroup.controls.checked.setValue( $event ? true : false )
    }
    this.emitData();
  }

  onChange(checked: boolean, index: number): void {
    const checkboxGroup = this.contents.controls[index] as FormGroup
    checkboxGroup.controls.checked.setValue(checked)
    this.selectAllContents = this.allItemsChecked
    this.emitData()
  }

  get allItemsChecked(): boolean {
    for (let i = 0; i < this.contents.controls.length; ++i) {
      if (!this.contents.controls[i].value.checked) {
        return false
      }
    }
    return true
  }

  get indeterminate(): boolean {
    let hasOneItemChecked: boolean = false;
    for (let i = 0; i < this.contents.controls.length; ++i) {
      if (this.contents.controls[i].value.checked) {
        hasOneItemChecked = true
        break
      }
    }
    return hasOneItemChecked
  }

  filterCheckbox() {
    this.filteredCheckbox = this.contents.controls
      .map((element, index) => element.value.checked ? index : null)
      .filter(element => element !== null)
  }

  clearFilter(selectAll: boolean): void {
    this.selectAllContents = selectAll
    this.selectAll(selectAll)
  }

  emitData() {
    this.filterCheckbox()
    this.changedCheckboxLists.emit({
      checkedArray: this.filteredCheckbox,
      key: this.checkboxListKey
    })
  }
}
