import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataService } from 'src/app/core/services/data.service';
import { HttpService } from 'src/app/core/http/http.service';
import { AppointmentService } from 'src/app/modules/appointment/services/appointment.service';
import { DatePipe } from '@angular/common';
import { debounceTime, distinctUntilChanged, finalize, map, takeUntil, tap, catchError} from 'rxjs/operators';
import { AppointmentModalComponent } from 'src/app/modules/appointment/component/appointment-modal/appointment-modal.component';
import { AppointmentDetailsModalComponent } from 'src/app/modules/appointment/component/appointment-details-modal/appointment-details-modal.component';
import { EditAppointmentModalComponent } from 'src/app/modules/appointment/component/edit-appointment-modal/edit-appointment-modal.component';
import { RemoveAppointmentModalComponent } from 'src/app/modules/appointment/component/remove-appointment-modal/remove-appointment-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IFilterDataTimeslot, IFilterData, IAppointmentPage, ICheckboxList, ICheckboxData } from 'src/app/modules/appointment/interface/appointment.interface';
import { SMART_FORM_NAMES, APPOINTMENT_SETTINGS } from 'src/app/modules/appointment/enum/smart_form';
import { Subject, Subscription, throwError } from 'rxjs';
import { ToastrService } from "ngx-toastr";
import { AppointmentSettingsService } from 'src/app/modules/appointment/services/appointment-settings.service';
import { AddQueueModalComponent } from 'src/app/modules/appointment/component/add-queue-modal/add-queue-modal.component'; 
@Component({
  selector: 'app-todays-appointment',
  templateUrl: './todays-appointment.component.html',
  styleUrls: ['./todays-appointment.component.scss']
})
export class TodaysAppointmentComponent implements OnInit, OnDestroy {
  @ViewChild('completeButton') completeButton: ElementRef;
  @Input() isViewingFromEmail: boolean;
  @Input() emailAppointmentData: any;
  @Input() receivedData: string = '';
  @Output() isViewed: EventEmitter<boolean> = new EventEmitter();
  @Output() todayAppointment = new EventEmitter<string>();
  @ViewChild("serviceDropdownRef", { static: false })
  serviceDropdown: ElementRef<HTMLDivElement>;
  @ViewChild("statusDropdownRef", { static: false })
  dropdownsVisible: { [key: string]: boolean } = {};
  statusDropdown: ElementRef<HTMLDivElement>;
  appointmentLists: any = [] ;
  itemsPerPage = 10;
  currentPage = 1;
  noOfEntries: number[] = [10, 20, 30];
  searchString: string | null;
  selectedServices: number = 0;
  selectedStatuses: number = 0;
  countLateAppointment: number = 0;
  countTodayAppointment: Array<any> = [];
  selectedService: Array<any> = [];
  selectedStatus: Array<any> = [];
  servicesList: Array<any> = [];
  statusList: Array<any> = [];
  settingFeaturesEnable: any;
  selectedTimeslotLabel: string = 'Show all timeslot';
  serviceCheckboxLists: Array<ICheckboxList> = [];
  statusCheckboxLists: Array<ICheckboxList> = [];
  settingsLateTime: number = 0;
  selectedTimeslotValue: number;
  todayAppointmentList: IAppointmentPage;
  upcommingCount: number;
  completedCount: number;
  noShowCount: number;
  isLoading: boolean = false;
  viewAppointmentModal: boolean = true;
  isFocused: boolean = false;
  isSelectClicked: boolean = false;
  isButtonClicked: boolean = false;
  isSubmitting: boolean = false;
  isDisableCompleteBtn: boolean = false;
  noOfPages: number[] = [];
  appointmentName: string;
  appointmentMobileNo: string;
  updatedTimeString: string;
  branchId: string = this.dataService.currentBranch$.branchId;
  searchSubject: Subject<string> = new Subject();
  filterSubject: Subject<any> = new Subject();
  subscriptions = new Subscription();
  statusLists = [
    { value: "ongoing", label: "ONGOING" },
    { value: "ongoing-arrived", label: "ONGOING(ARRIVED)" },
    { value: "late", label: "ONGOING(LATE)" },
    { value: "arrived-late", label: "ONGOING(ARRIVED LATE)" },
  ];
  timeslotFilter: Array<any> = [];
  endTime: Date;
  endTimeMilliseconds: number;
  ms8Hours = 28800000;
  private appointmentListSubjectAPI$ = new Subject<void>();
  constructor(
    private httpService: HttpService,
    private dataService: DataService,
    private appointmentService: AppointmentService,
    private appointmentSettingService: AppointmentSettingsService,
    private modalRef: BsModalRef,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private formBuild: FormBuilder,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private toastr: ToastrService
  ) { 
    this.searchSubject
    .pipe(debounceTime(300), distinctUntilChanged())
    .subscribe((searchValue) => {
        this.readAppointmentList();
      }
    )
  }

  ngOnInit() {
    this.dataService.branchChange$.subscribe((res) => { 
      if(res){
        this.branchId = res.branchId ?? this.branchId;
        this.readAddedAppointment();
        this.appointmentCounts();
        this.getServiceLists();
        this.setStatusData(this.statusLists);
        this.readSearchAppointment();
        this.readViewEmail();
        this.getSettingsData();
      }
    })
  }

  readAddedAppointment() {
    this.subscriptions.add(
      this.appointmentService.modalEvent.subscribe(async (isSuccessful: boolean) => {
        if (isSuccessful) {
          // Perform logic in the child component when the modal event is successful
          this.getSettingsData(); 
          this.appointmentCounts();
          this.getServiceLists();
        }
      })
    );
  }

  readSearchAppointment() {
      this.searchSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchValue) => {
        this.getSettingsData();

        this.filterSubject
          .pipe(debounceTime(300), distinctUntilChanged())
          .subscribe((filterValue) => {
            this.getSettingsData();
          }); 
      });
    }

    readViewEmail() {
      if(this.isViewingFromEmail && this.emailAppointmentData){
        this.isViewed.emit(true);
        this.viewAppointment(this.emailAppointmentData);
      }
    }

    searchAppointment() {
      this.searchSubject.next(this.searchString);
    }

    timeMili(): number {
      const hours = 0;
      const millisecondsInHour = 60 * 60 * 1000;
      return hours * millisecondsInHour;
    }

   readAppointmentList() {
    this.isLoading = true;
    const todayParams = 'dateFilter=today'+'&'+this.appointmentTodayParams;
    this.subscriptions.add(
      this.appointmentService.getAppointmentList(this.branchId,todayParams)
      .pipe(
        takeUntil(this.appointmentListSubjectAPI$),
        map((res) => res),
        tap((data) => {
          this.appointmentListLogic(data);
        }),
        catchError((error) => {
          console.log("error on fetching queue history lists", error);
          return throwError(error);
        }),
        finalize(() => {
          //
        })
      )
      .subscribe()
    )
  }

  appointmentListLogic(appointmentList) {
    let dataAppointment = [];
    appointmentList.data.forEach( appointment => {
     const settingTimeLate = !this.settingsLateTime || isNaN(this.settingsLateTime) ? 0 : this.settingsLateTime; 
     const date = new Date((appointment.timeslot+this.timeMili()) - settingTimeLate);
     const hours = date.getHours();
     const minutes = date.getMinutes();
     const ampm = hours >= 12 ? 'PM' : 'AM';

     // Convert hours to 12-hour format
     const formattedHours = hours % 12 || 12;

     // Use Angular's date pipe to format minutes
     const formattedMinutes = ('0' + minutes).slice(-2);

     let formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`; 

     appointment.status = appointment.status === 'UPCOMING' || appointment.status === 'ONGOING'  ? 'ONGOING' : 'LATE';

     appointment.formattedTimeslot = formattedTime; 

     for (const smartForm of appointment.visitorDetails) {
         switch (smartForm.fieldName) {
           case SMART_FORM_NAMES.NAME:
             appointment.firstname = smartForm.value;
             break;
           case SMART_FORM_NAMES.MOBILENO:
             appointment.mobileNumber = smartForm.value;
             break;
           case SMART_FORM_NAMES.PRIORITY:
             appointment.priority = smartForm.value;
             break;
           case SMART_FORM_NAMES.VISITORS:
             appointment.visitors = smartForm.value;
             break;
   
           default:
             break;
         }
       }  
     
     dataAppointment.push(appointment);   
   }); 


   this.appointmentLists = dataAppointment; 
   this.todayAppointmentList = appointmentList;
   const pages = appointmentList.pages;

   if (pages == 0) {
     this.noOfPages.push(1);
   } else {
     this.noOfPages = Array.from({ length: pages }, (_, i) => i + 1);
   }
   this.isLoading = false;
  }

  readTodayTimeslotList() {
    const httpQueryParams = `dateFilter=today`;
    this.subscriptions.add(
      this.appointmentService.getAppointmentListData(this.branchId, httpQueryParams)
      .pipe(
        takeUntil(this.appointmentListSubjectAPI$),
        map((res) => res),
        tap((data) => {
          this.todayTimeslotLogic(data);
        }),
        catchError((error) => {
          console.log("error on fetching queue history lists", error);
          return throwError(error);
        }),
        finalize(() => {
          //
        })
      )
      .subscribe()
    )
  }

  todayTimeslotLogic(appointmentListStatus) {
    let dataAppointment = [];
    let lateAppointment = [];
    
    appointmentListStatus.data.forEach( appointment => {
      const settingTimeLate = !this.settingsLateTime || isNaN(this.settingsLateTime) ? 0 : this.settingsLateTime; 
      const date = new Date((appointment.timeslot+this.timeMili()) - settingTimeLate);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;

      // Use Angular's date pipe to format minutes
      const formattedMinutes = ('0' + minutes).slice(-2);

      let formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`; 

      appointment.formattedTimeslot = formattedTime; 

      if(appointment.status === 'LATE') {
        lateAppointment.push(appointment._id)
      } 
       
      dataAppointment.push(appointment);   
      }); 

      this.getTimeslotFilter(dataAppointment);
      this.countLateAppointment = lateAppointment.length;
      this.countTodayAppointment = dataAppointment;
  }

  getSettingsData() {
     this.subscriptions.add(
      this.appointmentSettingService.getSettings(this.branchId).subscribe(
        (res) => {
          if(res) {
            this.settingFeaturesEnable = res.data.featuresEnabled;
            this.settingsLateTime = res.data.lateAppointmentTimeRange.milliseconds ? res.data.lateAppointmentTimeRange.milliseconds : 0 ;
            this.readAppointmentList();
            this.readTodayTimeslotList(); 
          }
        },
        (error) => {
          console.log(error)
        }
      )
    );
  }
  
  getTimeslotFilter(appointmentList) {
    const appointmentListFilter = appointmentList.map(appointment => {
      return {
          label: appointment.formattedTimeslot,
          value: appointment.timeslot  
      }
    })
    this.removeDuplicates(appointmentListFilter);
  }

  selectTimeslotDropdown(appointmentData) {
    this.selectedTimeslotValue = appointmentData.value;
    this.selectedTimeslotLabel = appointmentData.label
    const dataSelected = this.selectedTimeslotValue ? this.selectedTimeslotValue - this.settingsLateTime : this.selectedTimeslotValue
    this.filterSubject.next(dataSelected);
    this.isFocused = false;
    this.readAppointmentList();
  }

  removeDuplicates(appointmentListFilter): void {
    const uniqueAppointmentTime = appointmentListFilter.filter(
      (value, index, self) =>
        self.findIndex((item) => item.value === value.value && item.label === value.label) === index
    );
    
    this.timeslotFilter = this.sortArrayByTime(uniqueAppointmentTime); 
    this.timeslotFilter.push({ value: null, label: "Show all timeslot"}); 
  }

  sortArrayByTime(array: any[]): any[] {
    return array.sort((a, b) => {
      const timeA = new Date("2000-01-01 " + a.value);
      const timeB = new Date("2000-01-01 " + b.value);
  
      return timeA.getTime() - timeB.getTime();
    });
  }

  get appointmentTodayParams() {
    let params = new HttpParams();
    params = params.append("limit", this.itemsPerPage.toString());
    const offset = this.currentPage * this.itemsPerPage - this.itemsPerPage;
    params = params.append("offset", offset.toString());

    if (this.searchString) {
      params = params.append("search", this.searchString);
    }
    if (this.selectedTimeslotValue) {
      params = params.append("timeslot", this.selectedTimeslotValue.toString());
    }
    if (this.selectedStatus.length > 0) {
      params = params.append("status", this.selectedStatus.join(","));
    }
    if (this.selectedService.length > 0) {
      params = params.append("serviceId", this.selectedService.join(","));
    }

    return params;
  }


  getServiceLists() {
    this.subscriptions.add(
      this.httpService
        .get$(`services/${this.branchId}?limit=999`)
        .pipe(
          takeUntil(this.appointmentListSubjectAPI$),
          map((res) => res.data), // for extraction of data
          tap((data) => {
            this.servicesList = data.map((element) => ({
              _id: element._id,
              name: element.displayName,
            }));
            this.setServiceData(this.servicesList);
          }),
          catchError((error) => {
            console.log("Error on fetching branch details", error);
            return throwError(error);
          }),
          finalize(() => {
          })
        )
        .subscribe()
    );
  }

  setServiceData(serviceData: any) {
    const serviceLists = serviceData.map((element) => {
      return {
        value: element._id,
        label: element.name,
      };
    });
    this.serviceCheckboxLists = this.addCheckedProperty(serviceLists);
  }

  setStatusData(statusData: any) {
    this.statusCheckboxLists = this.addCheckedProperty(statusData);
  }

  addCheckedProperty(contents: Array<ICheckboxList>) {
    if (contents) {
      const newContents = contents.map((content) => ({
        ...content,
        checked: false, // for checkbox
      }));
      return newContents;
    }
  }

 public changeCheckboxList($event: ICheckboxData) {
    if ($event) {
      const key = $event?.key;
      const selected = $event.checkedArray;
      switch (key) {
        case "service":
          this.selectedServices = selected.length;
          this.selectedService = [];
          if (this.selectedServices > 0) {
            selected.forEach((element) => {
              this.selectedService.push(this.servicesList[element]._id);
            });
          }
          this.filterSubject.next(this.selectedServices);
          break;
        case "status":
          this.selectedStatuses = selected.length;
          this.selectedStatus = [];
          if (this.selectedStatuses > 0) {
            selected.forEach((element) => {
              this.selectedStatus.push(this.statusLists[element].value);
            });
          }
          this.filterSubject.next(this.selectedStatus);
          break;
        default:
          break;
      }
      this.readAppointmentList();
    }
  }
  
  public appointmentCounts() {
    const selectedDate = 'today';
    this.subscriptions.add(
      this.appointmentService.getAppointmentCounts(this.branchId,selectedDate).subscribe(
        (res) => {
          if(res) {
            this.upcommingCount = res.data.approved;
            this.completedCount = res.data.completed;
            this.noShowCount = (res.data.noShow + res.data.canceledByStaff);
          }
        },
        (error) => {
          console.log(error)
        }
      )
    )
  }

  ngAfterViewInit(): void {
    if (this.serviceDropdown) {
      this.serviceDropdown.nativeElement;
    }
    if (this.statusDropdown) {
      this.statusDropdown.nativeElement;
    }
  }

  toggleDropdown(dropdownId: string): void {
    // Close all other dropdowns before opening the current dropdown
    Object.keys(this.dropdownsVisible).forEach((id) => {
      if (id !== dropdownId) {
        this.closeDropdown(id);
      }
    });

    if (this.isDropdownVisible(dropdownId)) {
      this.closeDropdown(dropdownId);
    } else {
      this.openDropdown(dropdownId);
    }
    this.isFocused = false;
    this.isButtonClicked = false;
    this.isSelectClicked = false;
  }

  isDropdownVisible(dropdownId: string): boolean {
    return this.dropdownsVisible[dropdownId] ?? false; // Return the visibility state for the given dropdown ID
  }

  openDropdown(dropdownId: string): void {
    // Close all other dropdowns before opening the current dropdown
    Object.keys(this.dropdownsVisible).forEach((id) => {
      if (id !== dropdownId) {
        this.closeDropdown(id);
      }
    });

    this.dropdownsVisible[dropdownId] = true;
  }

  closeDropdown(dropdownId: string): void {
    this.dropdownsVisible[dropdownId] = false; // Set the visibility state for the given dropdown ID to false
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent): void {
    const clickedElement = event.target as HTMLElement;
    const dropdownDiv = this.elementRef.nativeElement.querySelector(".date-dropdown");
    if (!dropdownDiv?.contains(clickedElement)) {
      if (this.isFocused === true) {
        this.isFocused = false;
      }
    }
    for (const dropdownId in this.dropdownsVisible) {
      if (this.dropdownsVisible.hasOwnProperty(dropdownId)) {
        const dropdownRef = this.elementRef.nativeElement.querySelector(
          `#${dropdownId}`
        );
        const dropdownMenuRef = this.elementRef.nativeElement.querySelector(
          `#${dropdownId}-menu`
        );
        if (dropdownRef && dropdownRef.contains(clickedElement)) {
          return;
        }
        if (dropdownMenuRef && dropdownMenuRef.contains(clickedElement)) {
          return;
        }
      }
    }
    this.dropdownsVisible = {};
  }
  
  public viewAppointment(appointmentData) {
    if(!this.viewAppointmentModal) {
      /* change view appointment modal to true */
      this.viewAppointmentModal = true;
    } else {
    const initialState = {
      title: "View Appointment Details",
      data: appointmentData,
      isDisableCompleteBtn: this.isDisableCompleteBtn,
      settingsFeaturesEnable: this.settingFeaturesEnable
    }

    this.modalRef =  this.modalService.show(AppointmentDetailsModalComponent,{
        initialState: initialState,
        class: "modal-dialog-centered modal-md",
        ignoreBackdropClick: true,
        keyboard: false,
    });

    
    this.modalRef.content.successEvent.subscribe(
      (data) => {

        if(data === 'delete-detail-view'){
           this.readAppointmentList();
           this.readTodayTimeslotList();
           this.appointmentCounts();
        } 

        if(data === 'edit-detail-modal') {
          this.readAppointmentList();
          this.appointmentCounts();
        }

        if(data === 'move-history') {
          this.readAppointmentList();
          this.readTodayTimeslotList();
          this.appointmentCounts();
        }

        if(data === 'appointment-modal') {
          this.appointmentModal(appointmentData);
        }
      },
      (err) => {
        console.log("edit account", err);
        return false;
      }
    );
  }
}
  
  public complete(appointmentData): void {
    /* change view appointment modal to false */
    this.viewAppointmentModal = false;
    if(this.settingFeaturesEnable.includes(APPOINTMENT_SETTINGS.SYNC_TO_QUEUE_LIST)){
      const prio = appointmentData.visitorDetails.find(field => field.fieldName === "priority")?.value
      const bodyForm = {
        fields: appointmentData.visitorDetails.map((element) => {
          return {
            ...element,
            value: ["mobileNo", "mobile-number"].includes(element?.fieldType)
              ? element?.value === null || element?.value?.length < 12
                ? null
                : element?.value.replaceAll("-", "")
              : element?.value,
          };
        }),
        priority: prio ?? false,
        tags: appointmentData.tagLists,
        serviceId: appointmentData.serviceId,
        serviceCategoryId: null,
      };
      const fullname = this.dataService.currentBranch$.name; 
      const accountId = this.dataService.currentBranch$.ownerId;
      const roleLevel = this.dataService.currentBranch$.roleLevel;
      const avatarUrl = this.dataService.currentBranch$.avatarUrl;
      
      const requestForm = {
        status: 'completed',
        completedBy: {fullname: fullname, accountId: accountId, roleLevel: roleLevel, avatarUrl: avatarUrl },
        remarks: "",
        queueGroupId: ""
      }
      
      this.appointmentService.moveAppointment(this.branchId,requestForm, appointmentData._id ).subscribe(
        (res) => {
          this.modalRef.hide();
          this.toastr.success("Appointment completed", "Success!");
          this.getSettingsData();
          this.appointmentCounts();
          
        },
        (error) => {
          console.log(error)
          this.toastr.error("Error encountered upon submitting ", "Error!");
        }
      )
    
  } else {

     this.appointmentModal(appointmentData)
    }  

 }

 appointmentModal(appointmentData) {
  const settingTimeLate = !this.settingsLateTime || isNaN(this.settingsLateTime) ? 0 : this.settingsLateTime; 
  appointmentData.timeslot = (appointmentData.timeslot+this.timeMili()) - settingTimeLate;
  
  const initialState = {
    title: "Before we complete...",
    data: appointmentData,
  }

  this.modalRef =  this.modalService.show(AppointmentModalComponent,{
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
  })

  this.modalRef.content.successEvent.subscribe(
    (data) => {
      if(data === 'move-history'){
        this.readAppointmentList();
        this.readTodayTimeslotList();
        this.appointmentCounts();
      } 

      if(data === 'add-queue') {
        this.modalRef.hide()
        const initialState = {
          title: 'Add to Queue',
          subTitle: 'Please fill out the additional details for this queue',
          data: appointmentData
        }
    
        this.bsModalRef = this.modalService.show(AddQueueModalComponent,{
          initialState: initialState,
          class: "modal-dialog-centered modal-sm",
          ignoreBackdropClick: true,
          keyboard: false,
        })

        this.bsModalRef.content.successEvent.subscribe(
          (appointmentdata) => { 

            if(appointmentdata === 'add-queue-sucessfully'){
              this.readAppointmentList();
              this.readTodayTimeslotList();
              this.appointmentCounts();
            } 

            if(appointmentdata === 'add-queue-close') {
              this.completeButton.nativeElement.click();
            }

          }
        )
      }
    },
    (err) => {
      console.log("edit account", err);
      return false;
    }); 
  }



  edit(appointmentData) {
    /* change view appointment modal to false */
    this.viewAppointmentModal = false;

    const initialState = {
      title: "Edit Appointment",
      data: appointmentData
    }

    this.modalRef = this.modalService.show(EditAppointmentModalComponent,{
      initialState: initialState,
      class: "modal-dialog-centered modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    });

     this.modalRef.content.successEvent.subscribe(
      (data) => {
      
        if(data === 'edit-modal'){
          this.readAppointmentList();
          this.readTodayTimeslotList();
          this.appointmentCounts();   
        }
    
    }
      ,
      (err) => {
        return false;
      }
    ); 
  }

  delete(appointmentData) {
    /* change view appointment modal to false */
    this.viewAppointmentModal = false;
    
    const initialState = {
      title: "Cancel appointment?",
      data: appointmentData
    }

    this.modalRef = this.modalService.show(RemoveAppointmentModalComponent,{
      initialState: initialState,
      class: "modal-dialog-centered modal-md",
      ignoreBackdropClick: true,
      keyboard: false,
    });

    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if(data === 'deleted-move-history'){
          this.readAppointmentList();
          this.readTodayTimeslotList();  
           this.appointmentCounts();
        }
      },
      (err) => {
        return false;
      }
    );
  }

  selectedTimeslotFilter() {
    this.isSelectClicked = !this.isSelectClicked;
    this.isFocused = !this.isFocused;
  }

  public pageChanged($event) {
    this.currentPage = $event.page;
    this.filterSubject.next(this.currentPage);
    this.readAppointmentList();
    this.readTodayTimeslotList(); 
  }

  jumpToPage(page: string) {
    this.currentPage = parseInt(page);
    this.filterSubject.next(this.currentPage);
    this.readAppointmentList();
    this.readTodayTimeslotList(); 
    this.cdr.detectChanges();
  }

  getNumberArray(num: number, offset: number = 0): number[] {
    if (num > 0) {
        return Array(num).fill(0).map((x, i) => i + offset);
    } else {
        return [1]
    }
}

public entriesChanged(page) {
  this.itemsPerPage = page;
  this.filterSubject.next(this.itemsPerPage);
  this.readAppointmentList();
  this.readTodayTimeslotList(); 
}


getFormattedTime(milliseconds: number){
    let hours = Math.floor(milliseconds/(1000 * 60 * 60));
    let minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const ampm = `${hours < 12 ? "AM" : "PM"}`;
    if(hours>12){
        hours = hours - 12;
    }
    if(minutes < 10){
        return `${hours}:0${minutes} ${ampm}`;
    }
    return `${hours}:${minutes} ${ampm}`;
  }

  ngOnDestroy(): void {
    this.appointmentListSubjectAPI$.next();
    this.appointmentListSubjectAPI$.complete();
    this.subscriptions.unsubscribe();
  }
}
