import { isNull } from '@angular/compiler/src/output/output_ast';
import { Component, Input, Output, OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { element } from 'protractor';

@Component({
  selector: 'app-input-icon',
  templateUrl: './input-icon.component.html',
  styleUrls: ['./input-icon.component.scss']
})
export class InputIconComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() value: string;
  @Input() validators: Array<any>;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() maxLength: string | number;  
  @Input() inputGroupIcon: string;
  @Input() isDisabled: boolean;
  @Input() min: string | number;
  @Input() max: string | number;
  @Output() changedInput = new EventEmitter();
  inputEditForm: FormGroup;
  errorMsg: string;

  inputFocus: any;

  constructor(
    private fb: FormBuilder
  ) { }

  initForm(){
    this.inputEditForm = this.fb.group({
      inputName: [this.value, this.validators ? this.validators.map(element=>element.value): null]
    });

    this.placeholder = this.placeholder ? this.placeholder : 'Enter a Value';
  }

  get f(){
    return this.inputEditForm.controls;
  }
  get inputNumber() { return Number(this.f['inputName'].value)}
  get inputNumberAsString() {return this.f['inputName'].value}

  change(){
    if(this.type === "number"){
      if (this.inputNumber > +this.max) {
        this.f['inputName'].setValue(+this.max);
      }
    }
    this.changedInput.emit({
      value: this.f['inputName'].value,
      valid: this.f['inputName'].valid
    });
    const errors = this.f['inputName'].errors;
    if(this.f['inputName'].errors){
      const keys = Object.keys(errors).toString();
      const find = this.validators.find(element=>element.type.includes(keys));
      this.errorMsg = find.errorMsg;
    }
  }
    
  minus() {
    this.f['inputName'].setValue(this.inputNumber - 1);
    this.blur();
  }

  add() {
    this.f['inputName'].setValue(this.inputNumber + 1);
  }

  blur(){
    if(this.type === "number"){
      if (this.inputNumber < +this.min) {
        this.f['inputName'].setValue(+this.min);
      }
    }
    this.inputFocus = undefined;
    this.change();
  }

  ngOnInit(): void {
    this.initForm();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.value){
      if(this.inputEditForm){
        this.inputEditForm.patchValue({
          inputName: this.value
        })
      }
    }
  }

}
