import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/core/auth/auth.service";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { BusinessService } from "src/app/modules/auth/services/business.service";
import { BranchManagementService } from "src/app/modules/branch-management/services/branch-management.service";
import { TeamManagementService } from "src/app/modules/team-management/services/team-management.service";
import { QueueWorkspaceService } from "../../services/queue-workspace.service";

@Component({
  selector: "app-queue-window-select-page",
  templateUrl: "./queue-window-select-page.component.html",
  styleUrls: ["./queue-window-select-page.component.scss"],
})
export class QueueWindowSelectPageComponent extends QueueWorkspaceService
  implements OnInit, OnDestroy {
  welcomeText: string;
  selectedWindow: string;
  accountData: any;
  accountList: any;
  assignedBranches = this.authService.assignedBranches$;
  branchId: string;
  serviceLists: any = [];

  constructor(
    public authService: AuthService,
    public dataService: DataService,
    public branchService: BranchManagementService,
    public businessService: BusinessService,
    public httpService: HttpService,
    public toastr: ToastrService,
    public router: Router,
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    private teamService: TeamManagementService,
  ) {
    super(
      authService,
      dataService,
      httpService,
      toastr,
      router,
      modalService,
      modalRef,
    );
  }

  async checkCurrentWindow() {
    const selectedBranch = this.branchService.getSelectedBranch();
    const subscriptionPlan = this.dataService.getSubscriptionPlan();
    let isExpired = false;
    if (selectedBranch && subscriptionPlan) {
      isExpired = this.businessService.checkSubscriptionExpiry(
        selectedBranch.roleLevel,
        false,
        subscriptionPlan,
      );
    }

    if (!isExpired) {
      if (this.currentWindow) {
        await this.router.navigate([
          `/queue-workspace/main/${this.currentWindow}`,
        ]);
      } else {
        await this.getAssignedWindowLists(selectedBranch);
      }
    }
  }

  async assignWelcomeText() {
    const today = new Date();
    const curHr = today.getHours();
    const name = this.accountData.data.firstName;
    const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
    if (curHr < 12) {
      this.welcomeText = `Good morning, ${capitalizedName}! ☕`;
    } else if (curHr < 18) {
      this.welcomeText = `Good afternoon, ${capitalizedName}!`;
    } else {
      this.welcomeText = `Good evening, ${capitalizedName}!`;
    }
  }

  async getAssignedWindowLists(selectedBranch) {
    await this.httpService
      .get$(`accounts/${this.branchId}/${this.accountId}`)
      .toPromise()
      .then(async (data) => {
        // Current Window and Assigned Windows
        this.accountData = data;
        this.dataService.currentWindow$ = data.data.attendingWindow;
        this.dataService.assignedWindowLists$ = data.data.assignedWindows;
        this.currentWindow = data.data.attendingWindow;
        this.assignedWindowLists = data.data.assignedWindows;
        if (this.assignedWindowLists) {
          await this.getReassignedWindowLists(selectedBranch);
        }
      });
  }

  async getReassignedWindowLists(selectedBranch) {
    this.assignedWindowLists = this.assignedWindowLists.map((window, i) => {
      const services = [];

      for (const service of window.assignedServices) {
        for (const serviceWindows of service.assignedWindows) {
          if (serviceWindows.windowId === window._id) {
            services.push(service.displayName);
          }
        }
      }

      return {
        label: window.displayName,
        value: window._id,
        caption: services.join(", "),
        imageUrl: window.attendingEmployee
          ? this.getAccountAvatar(window.attendingEmployee)
          : null,
        rightLabel: this.getEmployeesName(window.attendingEmployees),

        isDisabled: window.suspended,
      };
    });
    this.selectedWindow = this.assignedWindowLists.find(
      (window) => !window.isDisabled,
    )?.value;
  }

  selectWindow($event) {
    this.selectedWindow = $event;
  }

  submitWindow() {
    const bodyForm = {
      accountId: this.accountId,
    };
    this.httpService
      .patch$(
        `services/windows/${this.branchId}/${this.selectedWindow}/attending-staff/enter`,
        bodyForm,
      )
      .subscribe(
        (data) => {
          this.dataService.accountData$.data.attendingWindow =
            this.selectedWindow;
          this.accountData.data.attendingWindow = this.selectedWindow;
          this.dataService.currentWindow$ = this.selectedWindow;
          this.currentWindow = this.selectedWindow;
          this.router.navigate([
            `/queue-workspace/main/${this.selectedWindow}`,
          ]);
        },
        (error) => {
          console.log("error", error);
          if (error.error.message && error.status === 400) {
            this.toastr.error(error.error.message);
          }
        },
      );
  }

  notifyManager() {}

  async getAccounts() {
    this.subscriptions.add(
      this.teamService.getTeamList("", this.branchId).subscribe((res) => {
        this.accountList = res.data;
      }),
    );
  }

  getEmployeesName(ids: string[]): string {
    let names = [];

    if (ids && ids.length > 0 && this.accountList) {
      for (const id of ids) {
        const a = this.accountList.find((x) => x._id === id);
        if (a) {
          names.push(a.firstName + " " + a.lastName);
        }
      }
    }
    return names.toString();
  }

  getAccountAvatar(id) {
    let avatar = "";
    if (id && this.accountList) {
      const a = this.accountList.find((x) => x._id === id);
      if (a) {
        avatar = a.avatarUrl;
      }
    }

    return avatar;
  }

  async ngOnInit() {
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe(async (res) => {
        await this.handleBranchChange(res);
        await this.getAccounts();
        await this.getAccountDetails();
        await this.getServiceLists();
        await this.checkCurrentWindow();
        await this.assignWelcomeText();
      }),
    );
  }

  async handleBranchChange(res: any): Promise<void> {
    this.branchId = res ? res._id : this.dataService.currentBranch$.branchId;
  }

  async getServiceLists() {
    this.subscriptions.add(
      this.httpService.get$(`services/${this.branchId}?limit=999`).subscribe(
        (data) => {
          this.serviceLists = data.data.map((element) => {
            return {
              label: element.displayName,
              value: element._id,
              suspended: element.suspended,
            };
          });
        },
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
