import { Component, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { concat, merge, Subscription } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { AddServiceCategoryComponent } from "../../components/add-service-category/add-service-category.component";
import { AssignServiceModalComponent } from "../../components/assign-service-modal/assign-service-modal.component";
import { ServiceCategoryServiceV2 } from "../../services/service-category.service";
import * as _ from "lodash";
import { DeleteModalComponent } from "../../components/delete-modal/delete-modal.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-service-category-page",
  templateUrl: "./service-category-page.component.html",
  styleUrls: ["./service-category-page.component.scss"],
})
export class ServiceCategoryPageComponentV2 implements OnInit, OnDestroy {
  allServiceLists = [];
  uncategorizedServices: any = [];
  subscriptions = new Subscription();
  branchId: string = '';
  categoryListsOriginal: any;
  categoryListsMapped: any;

  constructor(
    private categoryService: ServiceCategoryServiceV2,
    private dataService: DataService,
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe((res) => {
        console.log(res)
          this.branchId = res
            ? res._id
            : this.dataService.currentBranch$?.branchId;
          this.getServiceLists();
        // }
      })
    );

    // this.subscriptions.add(
    //   this.dataService.branchChange$.subscribe((res) => {
    //     this.branchId = res
    //       ? res._id
    //       : this.dataService.currentBranch$?.branchId;
    //   })
    // );

    // this.getServiceLists();
    // this.getCategoryList();
  }

  getServiceLists() {
    this.categoryService.getServiceList(this.branchId).subscribe((data) => {
      this.allServiceLists = data.data;
      const services = data.data.map((s) => {
        return {
          ...s,
          uncategorized: true,
        };
      });
      if(this.allServiceLists && this.allServiceLists.length > 0) {
        this.getCategoryList();
      }
    });
  }

  public getCategoryList() {
    this.categoryService.getServiceCategories(this.branchId).subscribe(
      (res) => {
        this.categoryListsOriginal = res.data;

        let assignedServiceIds = [];

        // this.categoryListsOriginal.forEach((c) => {
        //   assignedServiceIds = assignedServiceIds.concat(c.assignedServices);
        // });
        
        const categoryClone = _.cloneDeep(this.categoryListsMapped);
        this.categoryListsMapped = res.data
          .filter((x) => !x.parentCategoryId)
          .map((s) => {
            return {
              ...s,
              expanded: true,
            };
          });
        this.categoryListsMapped.forEach((parent, index) => {
          // this.categoryListsMapped[index].expanded = categoryClone
          //   ? categoryClone[index].expanded
          //   : false;

          this.categoryListsMapped[index].subCategory1 =
            this.categoryListsOriginal
              .filter((x) => x.parentCategoryId === parent._id)
              .map((s) => {
                return {
                  ...s,
                  expanded: true,
                };
              })
              .sort((a, b) => {
                if (a.createdAt > b.createdAt) return 1;
                if (b.createdAt > a.createdAt) return -1;
              });

          if (this.categoryListsMapped[index].subCategory1) {
            this.categoryListsMapped[index].subCategory1.forEach(
              (parent, index2) => {
                // this.categoryListsMapped[index].subCategory1[index2].expanded =
                //   categoryClone
                //     ? categoryClone[index].subCategory1[index2].expanded
                //     : false;
                this.categoryListsMapped[index].subCategory1[
                  index2
                ].subCategory2 = this.categoryListsOriginal
                  .filter((x) => x.parentCategoryId === parent._id)
                  .map((s) => {
                    return {
                      ...s,
                      expanded: true,
                    };
                  })
                  .sort((a, b) => {
                    if (a.createdAt > b.createdAt) return 1;
                    if (b.createdAt > a.createdAt) return -1;
                  });

                // this.categoryListsMapped[index].subCategory1[
                //   index2
                // ].subCategory2.forEach((sub, index3) => {
                //   this.categoryListsMapped[index].subCategory1[
                //     index2
                //   ].subCategory2[index3].expanded = categoryClone
                //     ? categoryClone[index].subCategory1[index2].subCategory2[index3].expanded
                //     : false;
                // });
              }
            );
          }
        });

        this.categoryListsMapped.forEach(c => {
          assignedServiceIds = assignedServiceIds.concat(c.assignedServices);
          if(c.subCategory1){
            c.subCategory1.forEach(c1 => {
              assignedServiceIds = assignedServiceIds.concat(c1.assignedServices);
              if(c1.subCategory2){
                c1.subCategory2.forEach(c2 => {
                  assignedServiceIds = assignedServiceIds.concat(c2.assignedServices);
                });
              }
            });
          }
        });

        this.uncategorizedServices = this.allServiceLists.filter(
          (s) => !assignedServiceIds.includes(s._id)
        );
        this.uncategorizedServices = [...new Set(this.uncategorizedServices)]
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  public addCategory() {
    const initialState = {
      branchId: this.branchId,
      serviceLists: this.allServiceLists.filter(x => (!x.suspended && !x.enabledFeatures.includes("hide-kiosk-and-site"))),
      title: "New Category",
      type: "add",
    };
    this.modalRef = this.modalService.show(AddServiceCategoryComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if (data) {
          this.getCategoryList();
        }
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }

  assignServices(categoryId, categoryName, assignedServices) {
    const initialState = {
      branchId: this.branchId,
      assignedServices: assignedServices,
      serviceLists: this.allServiceLists.filter(x => (!x.suspended && !x.enabledFeatures.includes("hide-kiosk-and-site"))),
      categoryId: categoryId,
      categoryName: categoryName,
      title: "Add Services",
      subTitle: `Add service/s under <b>${categoryName}</b>`,
    };
    this.modalRef = this.modalService.show(AssignServiceModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if (data) {
          this.getCategoryList();
        }
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }

  newSubcategory(parentCategoryId) {
    const initialState = {
      branchId: this.branchId,
      serviceLists: this.allServiceLists.filter(x => (!x.suspended && !x.enabledFeatures.includes("hide-kiosk-and-site"))),
      title: "New Subcategory",
      labelName: "Subcategory Name",
      type: "add",
      placeholder: "Enter Subcategory Name",
      errMsg: "Please enter subcategory name",
      parentCategoryId: parentCategoryId,
    };
    this.modalRef = this.modalService.show(AddServiceCategoryComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if (data) {
          this.getCategoryList();
        }
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }

  getServiceName(id) {
    let name = "";
    if (id && this.allServiceLists) {
      const service = this.allServiceLists.find((x) => x._id === id);
      if (service) {
        name = service.displayName;
      }
    }
    return name;
  }

  hideShowSubCategory(catIndex, subCategoryId, level, expanded, subCat1Index) {
    if (level === 1) {
      this.categoryListsMapped[catIndex].subCategory1.find(
        (x) => x._id === subCategoryId
      ).expanded = !expanded;
    } else {
      this.categoryListsMapped[catIndex].subCategory1[
        subCat1Index
      ].subCategory2.find((x) => x._id === subCategoryId).expanded = !expanded;
    }
  }

  hideShowCategory(catIndex, expanded) {
    this.categoryListsMapped[catIndex].expanded = !expanded;
  }

   isEmptySubCategory1(data: any) {
    if (((data?.assignedServices && data?.assignedServices?.length > 0) || (data?.subCategory2 && data?.subCategory2?.length > 0))) {
      return false;
    }
    return true;                                              
  }

  isEmptySubCategory2(data: any) {
    if ((data?.assignedServices && data?.assignedServices?.length > 0)) {
      return false;
    }
    return true;
  }

  public editCategory(categoryDetails, catIndex, subCatIndex, level) {
    const initialState = {
      branchId: this.branchId,
      serviceLists: this.allServiceLists.filter(x => (!x.suspended && !x.enabledFeatures.includes("hide-kiosk-and-site"))),
      categoryDetails: categoryDetails,
      title: "Edit Category",
      type: "edit",
    };
    this.modalRef = this.modalService.show(AddServiceCategoryComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.modalRef.content.successEvent.subscribe(
      (data) => {
        if (data) {
          this.getCategoryList();
          // if (level === 1) {
          //   this.categoryListsMapped[catIndex].subCategory1.find(
          //     (x) => x._id === data._id
          //   ).displayName = data.displayName;
          // } else if (level === 2) {
          //   this.categoryListsMapped[catIndex].subCategory1[
          //     subCatIndex
          //   ].subCategory2.find((x) => x._id === data._id).displayName =
          //     data.displayName;
          // } else {
          //   this.categoryListsMapped.find(
          //     (x) => x._id === data._id
          //   ).displayName = data.displayName;
          // }
        }
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }

  delete(categoryData, type, serviceId, label, catIndex, subCatIndex, level) {
    const initialState = {
      title: `Remove ${type}`,
      label: label,
      categoryData: categoryData,
      serviceId: serviceId,
      type: type,
      branchId: this.branchId,
    };
    this.modalRef = this.modalService.show(DeleteModalComponent, {
      initialState: initialState,
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.modalRef.content.successEvent.subscribe(
      (data) => {
        this.getCategoryList();

        // if (level === 1) {
        //   const index = this.categoryListsMapped[
        //     catIndex
        //   ].subCategory1.findIndex((x) => x._id === data._id);

        //   this.categoryListsMapped[catIndex].subCategory1.splice(index, 1);
        // } else if (level === 2) {
        //   const index = this.categoryListsMapped[catIndex].subCategory1[
        //     subCatIndex
        //   ].subCategory2.findIndex((x) => x._id === data._id);

        //   this.categoryListsMapped[catIndex].subCategory1[
        //     subCatIndex
        //   ].subCategory2.splice(index, 1);
        // } else {
        //   const index = this.categoryListsMapped.findIndex(
        //     (x) => x._id === data._id
        //   );
        //   this.categoryListsMapped.splice(index, 1);
        // }
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe(); // Unsubscribe from all subscriptions
  }
}
