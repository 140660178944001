// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const baseURL = "http://localhost:5051";
const baseURL = "https://devapi.kyoo.com";

export const environment = {
  production: false,
  urlAPI: `${baseURL}/api/v4/business-portal`,
  urlTVAPI: `${baseURL}/api/v4/tv-app`,
  socketAPI: baseURL,
  socketPath: "/api/v4/socket.io",
  branchSiteUrl: "https://dev-www.kyoo.com/site",
  env: "dev",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
