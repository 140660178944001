import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DragulaService } from "ng2-dragula";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { HttpService } from "src/app/core/http/http.service";
import { DataService } from "src/app/core/services/data.service";
import { ProcedureService } from "src/app/modules/service-procedure/services/procedure.service";
import { ServiceCategoryService } from "../../services/service-category.service";

@Component({
  selector: "app-service-category-manage-services",
  templateUrl: "./service-category-manage-services.component.html",
  styleUrls: ["./service-category-manage-services.component.scss"],
})
export class ServiceCategoryManageServicesComponent implements OnInit {
  isLoading = false;
  branchId: string;
  procedureTemplateId: string;
  serviceLists: any;
  subscriptions = new Subscription();
  isSubmitting = false;
  assignedServices = [];
  type: string;
  categoryId: any;
  categoryDetails: any;
  isChanged = false;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private dataService: DataService,
    private dragulaService: DragulaService,
    private activatedRoute: ActivatedRoute,
    private procedureService: ProcedureService,
    private toastr: ToastrService,
    private categoryService: ServiceCategoryService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.assignedServices = [];
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params && params.type) {
        this.type = params.type;
      }
    });

    this.categoryId =
      this.activatedRoute.snapshot.paramMap.get("serviceCategoryId");
    this.subscriptions.add(
      this.dataService.branchChange$.subscribe((res) => {
        this.branchId = res
          ? res._id
          : this.dataService.currentBranch$?.branchId;
        this.getServiceLists(this.branchId);
        this.checkDrag();
      })
    );

    this.isLoading = false;
  }

  checkDrag() {
    this.subscriptions.add(
      this.dragulaService
        .dropModel("categoryModel")
        .subscribe(({ targetModel }) => {
          this.assignedServices = targetModel
            .map((element, i) => {
              return {
                ...element,
                index: i,
              };
            })
            .sort((a, b) => a.index - b.index);
          this.isChanged = true;
          console.log("assigned services", this.assignedServices);
        })
    );
  }

  getCategoryDetails(branchId, categoryId) {
    this.subscriptions.add(
      this.categoryService
        .getServiceCategoryById(branchId, categoryId)
        .subscribe((res) => {
          this.categoryDetails = res.data;
          this.assignedServices = [];
          res.data.assignedServices.forEach((s) => {
            const serviceIdx = this.serviceLists.findIndex((x) => x._id == s);
            this.serviceLists[serviceIdx].selected = true;
            this.assignedServices.push({
              _id: s,
              displayName: this.getServiceName(s),
            });
          });
        })
    );
  }

  public getServiceName(id) {
    if (id && this.serviceLists) {
      const s = this.serviceLists.find((x) => x._id === id);
      if (s) {
        return s.displayName;
      }
    }
  }

  public getServiceLists(branchId) {
    this.subscriptions.add(
      this.httpService
        .get$(`services/${branchId}?limit=999`)
        .subscribe((data) => {
          this.serviceLists = data.data;
          if (this.serviceLists.length > 0) {
            for (var i = 0; i < this.serviceLists.length; i++) {
              this.serviceLists[i].selected = false;
            }
          }
          this.getCategoryDetails(this.branchId, this.categoryId);
        })
    );
  }

  select(service) {
    this.isChanged = true;
    const serviceIdx = this.serviceLists.findIndex((x) => x._id == service._id);
    this.serviceLists[serviceIdx].selected = true;
    this.assignedServices.push({
      _id: service._id,
      displayName: service.displayName,
    });
  }

  confirm() {
    const serviceIds = this.assignedServices.map((x) => {
      return x._id;
    });
    const body = {
      subCategoryId: "",
      assignedServices: serviceIds,
      activated: this.categoryDetails.activated,
      displayName: this.categoryDetails.displayName,
    };

    if(this.type === 'add'){
      body.activated = true;
    }

    this.categoryService
      .updateServiceCategory(this.branchId, this.categoryId, body)
      .subscribe(
        (res) => {
          console.log(res);
          this.toastr.success(`Category has been updated`, "Success!");
          this.isChanged = false;
          if(this.type === 'add'){
            this.router.navigate([`/apps/service-category`]);
          }
        },
        (error) => {
          console.log(error);
          this.isSubmitting = false;
          let errMsg = "Error on managing services";
          this.toastr.error(errMsg, "Error!");
        }
      );
  }

  cancel() {
    this.router.navigate(["/apps/service-category"]);
  }

  remove(i, id) {
    this.isChanged = true;
    const serviceIdx = this.serviceLists.findIndex((x) => x._id == id);
    this.assignedServices.splice(i, 1);
    this.serviceLists[serviceIdx].selected = false;
  }
}
