import { Component, OnInit} from "@angular/core";

import { HelpCenterAccordionValue } from "src/app/modules/support/interfaces/support.interface";

@Component({
  selector: "app-support-help-center-sub-faq",
  templateUrl: "./support-help-center-sub-faq.component.html",
  styleUrls: ["./support-help-center-sub-faq.component.scss"],
})
export class SupportHelpCenterSubFaqComponent implements OnInit {

  system: HelpCenterAccordionValue = {
    title: "System",
    items: [
      {
        label: `Can we use Kyoo Cloud Offline?`,
        content: `
            <p class="content-paragrap">
              Unfortunately, Kyoo requires an internet connection to function fully. Kyoo is a cloud-based software. 
              It is designed to work seamlessly online to provide the best customer queueing experience.
            </p>
            `
      },
      {
        label: `Can we host Kyoo locally (on-premise)?`,
        content: `
            <p class="content-paragrap">
             Sorry, Kyoo Cloud can't be hosted locally or on-premise. Our cloud infrastructure offers optimal performance, scalability,
             and maintenance. We're constantly improving to provide the best experience for our users.
            </p>

            <p class="content-paragrap">
              However, we are currently working on developing an on-premise version of Kyoo.
              So follow us on social media for the latest news on our services:
            </p>

            <ul class="mt-2">
              <li><a href="https://www.facebook.com/" target="_blank">Facebook</a></li>
              <li><a href="https://www.linkedin.com/" target="_blank">LinkedIn</a></li>
              <li><a href="https://www.youtube.com/" target="_blank">YouTube</a></li>
            </ul>
            `
      },
      {
        label: `Do you have remote/virtual queueing?`,
        content: `
          <p>
            Yes! Visitors can remotely queue in advance to avoid physical lines and receive updates via SMS or Web Queue Slip. ⚡️ 
          </p>

          <p>
            To set up your virtual queueing:
          </p>

          <ol>
            <li>Go to the  <a href="/branch-management" target="_blank" >Branches</a>  page. Then, select the branch you want to set up.</li> 
            <li>Click the “Setup Branch Site” button in the lower right corner.</li>
            <li>A QR code and URL for your Branch Site will be provided. You can start virtual queuing online once you set up your branch site!</li>
            <ul style="list-style-type: disc !important;">
              <li>
                Visitors/customers queue virtually using the branch site’s mobile devices or desktop browsers. Visitors/customers can access your Branch Site by scanning the QR code or visiting the URL provided.
              </li>
              <li>
                Download and print the provided QR code and put it on your store/business. You can also use it on your social media or printable promotional materials.
              </li>
            </ul>
          </ol>
        `
      }, 
      {
        label:'Do you have open API Integration?',
        content: `
          <p>
            Yes, we do offer open API integration. 😎 
          </p>

          <p>
            To learn more, please <a href="https://info.kyoo.com/contact-us/" target="_blank">contact our sales team</a>. 
          </p>
        `
      }, 
      {
        label: 'Is Kyoo customizable to my specific business needs?',
        content: `
          <p>
            Yes, Kyoo offers customization options to meet specific business needs. You may customize the branding, 
            integrate other programs, or create a custom process fitted for your business. 
          </p>

          <p>
            To learn more, please <a href="https://info.kyoo.com/contact-us/" target="_blank">contact our sales team</a>. 
          </p>
        `
      },
      {
        label: 'Do you offer appointment or reservation bookings?',
        content: `
        <p>
          Our appointment/reservation feature is currently under development. 
          So follow us on social media for the latest news on our services:
        </p>

        <ul class="content-paragraph mt-4">
          <li><a href="https://www.facebook.com/" target="_blank">Facebook</a></li>
          <li><a href="https://www.linkedin.com/" target="_blank">LinkedIn</a></li>
          <li><a href="https://www.youtube.com/" target="_blank">YouTube</a></li>
        </ul>
        `
      },
      {
        label: 'Do you have any Hardware to offer?',
        content: `
        <p>
          Yes! We have a range of hardware solutions specifically 
          designed to enhance your queueing management experience.
        </p>

        <p>
          To view the hardware we offer and its capabilities 
          <a href="https://info.kyoo.com/self-service-kiosk/" target="_blank"> click here!</a> 
        </p>
        `
      },
      {
        label: 'Do you require a printer?',
        content: `
        <p>
          Kyoo <span class="bold">does not require</span> any printer to function fully. 
          You can add or manage your queues with or without printing the queue ticket.
        </p>

        <p>
          No printer? No problem. Kyoo can provide these alternative solutions 
        </p>
      
        <ul class="mt-4">
          <li>
            Via SMS: If you enable the SMS feature, your visitors can receive an SMS or Text message 
            containing their queue number and reference ID.
          </li>
          <li>
            Via Web Slip: Queues automatically generate an online/virtual web slip. 
            There, they can monitor their status and see their queue details.
          </li>
          <li>
            Or call your visitors in person. Call them by their names, queue numbers, or anything. Kyoo can add any customer data field you need.
          </li>
        </ul>

        <p>
          Additionally, we offer comprehensive hardware packages. We offer 
          hardware with built-in printing capabilities, enhancing your visitor queueing experience. 
        </p>

        <p>
          To view the hardware we offer and its capabilities <a href="https://info.kyoo.com/self-service-kiosk/" target="_blank"> click here!</a> 
        </p>
        `
      }, {
        label: 'Do you have a mobile app?',
        content: `
        <p>
          We don't have an app for the visitors/customers since most won't 
          even bother downloading an app to queue.
        </p>

        <p>
          However,<span class="bold">we have an app for our business users</span> (you and your team). 
          The Kyoo Business App (or the <span class="bold">”Kyoo Biz App”</span> ) is a portable version of your Queue Workspace. 
          You and your team can now access Kyoo on your tablet or mobile devices! 😎
        </p>

        <p>
          Currently, the Biz App is only available on Android OS.
        </p>
        
        <p>
          We also offer a handheld device (with printer) pre-installed with Kyoo Biz App. View our
          <a href="https://info.kyoo.com/self-service-kiosk/" target="_blank">hardware list page</a>, or <a href="https://info.kyoo.com/contact-us/" target="_blank">contact our sales team</a> 
          for more inquiries.
        </p>
        `
      }
    ]
  }
  
  systemRequirements: HelpCenterAccordionValue = {
    title: "What are your software requirements?",
    items: [
      {
        label: 'Kyoo Business Portal',
        content: `
          <p>
            The Kyoo Business Portal is a web-based application. You can run it on any modern web browser. 
            We suggest keeping your web browser up-to-date with the latest version available to ensure optimal performance.
          </p>

          <ul class="mt-4" style="list-style-type:disc">
            <li>
              Hardware
              <ul style="list-style-type: circle;">
                <li>
                    We recommend using a <span class="bold">desktop</span> or a <span class="bold">laptop</span> (Windows/MacOS/Linux) when accessing the business portal.
                </li>
                <li>
                    If you're browsing the web on a tablet, we suggest using a tablet with a screen size of 10 inches or larger. 
                    The business portal is not designed for small devices such as mobile phones. However, 
                    the <span class="bold">Kyoo Biz App</span> is specifically created for mobile phones and tablet devices. 
                </li>
              </ul>
            </li>
            <li>
              Internet Speed
              <ul style="list-style-type: circle;">
                <li>
                  At least 5 Mbps internet connection speed. To test your internet speed, try these sites:
                </li> 
                <ul style="list-style-type: square;">
                    <li><a href="https://fast.com/" target="_blank">https://fast.com/</a></li>
                    <li><a href="https://www.speedtest.net/"  target="_blank">https://www.speedtest.net/</a></li>
                </ul>
              </ul>
            </li>
          </ul>
        `
      },
      {
        label: 'Kyoo Biz App',
        content: `
          <p>
            To ensure our Biz App’s proper functionality,
            we kindly request that your Android device run on OS version 8 or higher, 
            with a display size of at least 5.5 inches.
          </p>
        `
      },
      {
        label: 'Kyoo TV Display',
        content: `
          <p>
            Any TV with standard HD resolutions and an HDMI input for an Android TV Box will work perfectly.
          </p>
        ` 
      }
    ] 
  }


  queueing: HelpCenterAccordionValue = {
    title: 'Queueing',
    items: [
      {
        label: 'Can I transfer queues to another service or window?',
        content: `
          <ul>
            <li>Yes</li>
          </ul>
        `
      },
      {
        label: 'How can I call or serve the next queue?',
        content: `
        <ul>
          <li>
            To call or assist the next queue, you can follow these steps:
            <ul style="list-style-type: circle;">
                <li>
                  <span class="bold">Access the Queue Card Number:</span> 
                  Open the Business portal and head to the Queue Workspace within the Ongoing lists. 
                  Look for the relevant queue card number and click on it.
                </li>
                <li>
                  <span class="bold">Initiate Service:</span> 
                  On the right side of the screen, locate and click the "Serve Queue" button.
                </li>
                <li>
                  <span class="bold">Notify the Visitor:</span> 
                  The visitor will receive a notification through the TV display or an SMS.
                </li>
                <li>
                  <span class="bold">Assist the Visitor:</span> 
                  Now, you can assist visitors with their needs or inquiries.
                </li>
            </ul>
          </li>
      </ul>
      
      <p>
        This method ensures a smooth flow of serving customers and provides a convenient way to keep you and the visitor informed throughout the process.
      </p>
        `
      },
      {
        label: 'Where are the queue numbers displayed?',
        content: `
          <ul>
            <li>You can usually spot the queue numbers being shown on the TV screens above or monitors placed in the specific waiting area.</li>
          </ul>
        `
      },
      {
        label: 'Does Kyoo allow “priority queues”?',
        content: `
          <ul>
          <li>Yes</li>
         </ul>
        `
      },
      {
        label: 'Will my customers/visitors be notified?',
        content: `
         <p>
          Yes! Regarding notifications, your users will receive them through two convenient channels: via SMS and our Digital Signage feature (Kyoo TV Display).
        </p>
        `
      }
    ]
  }

  team: any = {
    title: "Team",
    items: [
      {
        label: 'Do I have to require my staff to have an active email?',
        content: `
        <ul>
            <li>
              Yes! Implementing an active email requirement for your staff in the queueing management system could be beneficial. 
              Email can help facilitate communication, share important updates, and keep everyone informed.
            </li>
        </ul>
        `
      }, 
      {
        label: 'Can I deactivate inactive staff accounts?',
        content: `
        <ul>
            <li>
              Currently, we don't have a feature to deactivate inactive staff accounts.
              However, if the need arises, you do have the option to delete a particular staff account.
            </li>
        </ul>
        `
      }
    ]
  }

  constructor() {}

  


  ngOnInit(): void {}
}
